(function () {
    'use strict';
    angular.module('modules.page_fix.services').factory('PageFixService', PageFixService);

    PageFixService.$inject = [
        'gettextCatalog',
        'ng2MonModalService',
        'ng2PageFixRepoService',
        'uibModalOpenerService',
        'PAGE_FIX_FIXABLE_TYPES',
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        'ng2ErrorHandler',
        'ng2TrapFocusService',
    ];
    /* @ngInject*/
    function PageFixService (
        gettextCatalog,
        ng2MonModalService,
        ng2PageFixRepoService,
        uibModalOpenerService,
        PAGE_FIX_FIXABLE_TYPES,
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        ng2ErrorHandler,
        ng2TrapFocusService,
    ) {
        return {
            add: add,
            update: update,
            remove: remove,
            removeAll: removeAll,
            openCustom: openCustom,
        };

        // ///////////////

        function update (type, ngModel, subType, pageFix, cb) {
            ng2TrapFocusService.pauseLast();
            if (!ngModel.id) {
                ng2ErrorHandler.standardMsg('Update PageFix could not detect id', {
                    type: type,
                    model: ngModel,
                    subType: subType,
                    PageFix: pageFix,
                });
                return;
            }
            uibModalOpenerService
                .open({
                    size: 'lg',
                    backdrop: 'static',
                    component: getPageFixFormComponent(type, ngModel.id),
                    windowClass: type === PAGE_FIX_FIXABLE_TYPES.accessibility_check ? 'page-fix-accessibility-form' : '',
                    resolve: {
                        model: function () {
                            return ngModel;
                        },
                        type: function () {
                            return type;
                        },
                        subType: function () {
                            return subType;
                        },
                        pageFix: function () {
                            return pageFix;
                        },
                    },
                })
                .result.then(
                    function (value) {
                        if (typeof cb === 'function') {
                            return cb(value);
                        }
                    },
                    function () {
                        if (typeof cb === 'function') {
                            return cb();
                        }
                    },
                );
        }

        function remove (pageFix) {
            switch (pageFix.normalizeFixableType()) {
                case PAGE_FIX_FIXABLE_TYPES.misspelling:
                    return removeSpell(pageFix);

                case PAGE_FIX_FIXABLE_TYPES.accessibility_source_code:
                case PAGE_FIX_FIXABLE_TYPES.accessibility_check:
                    return removeAccessibility(pageFix);

                case PAGE_FIX_FIXABLE_TYPES.link:
                    return removeBroken(pageFix);
                default:
                    return removeCustom(pageFix);
            }
        }

        function add (type, ngModel, typeId, subType, cb) {
            ng2TrapFocusService.pauseLast();
            if (!(typeId || ngModel.id)) {
                ng2ErrorHandler.standardMsg('Create PageFix could not detect id', {
                    type: type,
                    model: ngModel,
                    subType: subType,
                    typeId: typeId,
                });
                return;
            }
            uibModalOpenerService
                .open({
                    size: 'lg',
                    component: getPageFixFormComponent(type, typeId || ngModel.id),
                    windowClass: type === PAGE_FIX_FIXABLE_TYPES.accessibility_check ? 'page-fix-accessibility-form' : '',
                    resolve: {
                        model: function () {
                            return ngModel;
                        },
                        type: function () {
                            return type;
                        },
                        subType: function () {
                            return subType;
                        },
                        typeId: function () {
                            return typeId;
                        },
                    },
                })
                .result.then(
                    function (value) {
                        if (typeof cb === 'function') {
                            return cb(value);
                        }
                    },
                    function () {
                        if (typeof cb === 'function') {
                            return cb();
                        }
                    },
                );
        }

        // ///////////////

        function removeAccessibility (pageFix) {
            var scope = {
                deleteTag: '<code>',
                deleteTagEnd: '</code>',
                newline: '<br/>',
            };
            var msg = gettextCatalog.getString(
                'You are about to remove PageCorrect for this check <br> Please Type {{deleteTag}}Delete{{deleteTagEnd}} to confirm this action',
                scope,
            );
            return ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }

                return ng2PageFixRepoService.destroy(pageFix.id);
            }, angular.noop);
        }

        function removeBroken (pageFix) {
            var type = gettextCatalog.getString('link');
            if (pageFix.change.attribute === 'image') {
                type = gettextCatalog.getString('image');
            }
            var scope = {
                deleteTag: '<code>',
                deleteTagEnd: '</code>',
                newline: '<br/>',
                type: type,
            };
            var msg = gettextCatalog.getString(
                'You are about to remove PageCorrect for this {{type}} <br> Please Type {{deleteTag}}Delete{{deleteTagEnd}} to confirm this action',
                scope,
            );
            return ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }

                return ng2PageFixRepoService.destroy(pageFix.id);
            }, angular.noop);
        }

        function removeSpell (pageFix) {
            var scope = {
                deleteTag: '<code>',
                deleteTagEnd: '</code>',
                newline: '<br/>',
            };
            var msg = gettextCatalog.getString(
                'You are about to remove PageCorrect for this misspelling <br> Please Type {{deleteTag}}Delete{{deleteTagEnd}} to confirm this action',
                scope,
            );
            return ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }

                return ng2PageFixRepoService.destroy(pageFix.id);
            }, angular.noop);
        }

        function removeCustom (pageFix) {
            var msg = gettextCatalog.getString(
                'Are you sure you want to remove Custom PageCorrect? <br> Please type {{deleteTag}}Delete{{deleteTagEnd}} to confirm this action',
                {
                    deleteTag: '<code>',
                    deleteTagEnd: '</code>',
                    newline: '<br>',
                },
            );
            return ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }

                return ng2PageFixRepoService.destroy(pageFix.id);
            }, angular.noop);
        }

        function openCustom (pageFix, cb) {
            uibModalOpenerService
                .open({
                    size: 'lg',
                    component: 'pageFixFormCustom',
                    resolve: {
                        pageFix: function () {
                            return pageFix;
                        },
                    },
                })
                .result.then(function (model) {
                    if (typeof cb === 'function') {
                        cb(model);
                    }
                }, angular.noop);
        }

        function removeAll (pageFix) {
            var msg = gettextCatalog.getString(
                'Are you sure you want to remove <code>all</code> {{type}} PageCorrects? <br> Please type <code>Delete</code> to confirm this action',
                {
                    codeTag: '<code>',
                    codeTagEnd: '</code>',
                    newline: '<br>',
                    type: getPageFixApiType(pageFix.fixable_type),
                },
            );

            return ng2MonModalService.prompt(msg).then(function (result) {
                if ('delete' !== result.toLowerCase().trim()) {
                    return;
                }

                return ng2PageFixRepoService.deleteAll(pageFix);
            }, angular.noop);
        }

        function getPageFixApiType (type) {
            switch (type) {
                case PAGE_FIX_FIXABLE_TYPES.spelling_error:
                    return 'Spelling';
                case PAGE_FIX_FIXABLE_TYPES.accessibility_source_code:
                    return 'Accessibility Source Code';
                case PAGE_FIX_FIXABLE_TYPES.accessibility_check:
                    return 'Accessibility Check';
                case PAGE_FIX_FIXABLE_TYPES.link:
                    return 'Link';
                case PAGE_FIX_FIXABLE_TYPES.custom:
                    return 'Custom';
                default:
                    return '';
            }
        }

        function getPageFixFormComponent (type, id) {
            switch (type) {
                case PAGE_FIX_FIXABLE_TYPES.misspelling:
                    return 'pageFixFormMisspelling';
                case PAGE_FIX_FIXABLE_TYPES.link:
                    return 'pageFixFormBrokenLink';
                case PAGE_FIX_FIXABLE_TYPES.accessibility_source_code:
                    return 'pageFixFormEditAltText';
                case PAGE_FIX_FIXABLE_TYPES.accessibility_check:
                    return getAccessibilityCheckComponent(id);
            }
        }

        function getAccessibilityCheckComponent (id) {
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[id];
            if (config) {
                return config.formComponent;
            }
        }
    }
})();
