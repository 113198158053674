// The upgraded pipe version is src/client/app/pipe/has-access/has-access.pipe.ts
// Please make any changes in both files

(function () {
    'use strict';

    angular.module('filters.monsido').filter('hasAccess', hasAccess);

    hasAccess.$inject = ['MON_MODULES', 'ng2SessionService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function hasAccess (MON_MODULES, ng2SessionService, ng2ActiveFeatureService) {
        return access;

        // //////////

        function access (user, module) {
            // If user is not set, no access should be granted, until a valid user exists
            if (!angular.isObject(user)) {
                return false;
            }

            var canAccessModule = true;

            switch (module) {
                case MON_MODULES.accessibility:
                    canAccessModule = customerHasAccessToAccessibility();
                    break;
                case MON_MODULES.data_privacy:
                    canAccessModule = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.data_privacy);
                    break;
                case MON_MODULES.cookie_banner:
                    canAccessModule = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.cookie_banner);
                    break;
                case MON_MODULES.pagecorrect:
                    canAccessModule = customerHasAccessToPageCorrect();
                    break;
            }

            // Users without any modules is allowed access to all modules in the migration period
            if (user && user.settings?.modules?.length > 0) {
                return canAccessModule && user.settings.modules.indexOf(module) === -1;
            }

            return canAccessModule;
        }

        function customerHasAccessToAccessibility () {
            var customer = ng2SessionService.customer;
            return Boolean(!customer || customer.plan_traits.accessibility);
        }

        function customerHasAccessToPageCorrect () {
            var customer = ng2SessionService.customer;
            return Boolean(!customer || customer.plan_traits.page_fix);
        }
    }
})();
