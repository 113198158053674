(function () {
    'use strict';

    angular.module('modules.accessibility').component('accResponsibilities', {
        templateUrl: 'app/modules/accessibility/components/responsibilities/responsibilities.html',
        controller: AccresponsibilitiesController,
        controllerAs: 'vm',
        bindings: {
            responsibilities: '<',
            icon: '<?',
        },
    });

    AccresponsibilitiesController.$inject = [];
    /* @ngInject */
    function AccresponsibilitiesController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.showComponent = vm.responsibilities.length;
            vm.responsibilitiesString = vm.responsibilities.sort().join(', ');
            vm.icon = vm.icon || false;
        }
    }
})();
