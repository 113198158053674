(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name e2eAttributeEncoding
     * @description encode strings with encodeURIComponent
     */

    angular.module('filters.monsido').filter('e2eAttributeEncoding', E2eAttributesFilter);

    E2eAttributesFilter.$inject = ['$window'];
    /* @ngInject */
    function E2eAttributesFilter ($window) {
        return encode;

        // //////////

        /**
         * @memberOf e2eAttributeEncoding
         * @param {String} input - String to decode
         * @return {String}
         */
        function encode (input) {
            var encoded = $window.encodeURIComponent(input).toLowerCase();
            return encoded;
        }
    }
})();
