(function () {
    'use strict';

    angular.module('core.config').config([
        '$logProvider',
        function ($logProvider) {
            $logProvider.debugEnabled(false);
        },
    ]);
})();
