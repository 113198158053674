import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.global.users').component('pagesUsers', {
        templateUrl: 'app/pages/global/users/users.html',
        controller: UsersController,
        controllerAs: 'vm',
    });

    UsersController.$inject = ['UserRepo', '$controller', 'ng2MonEventsService', 'gettextCatalog', 'FORM_CONSTANT'];
    /* @ngInject */
    function UsersController (UserRepo, $controller, ng2MonEventsService, gettextCatalog, FORM_CONSTANT) {
        var vm = this;
        vm.getPage = getPage;
        vm.addUser = addUser;
        vm.$onInit = activate;
        vm.onFiltersChanges = onFiltersChanges;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.users = [];
            vm.currentSort = {
                by: 'first_name',
                direction: 'asc',
            };
            vm.search = null;
            vm.selectedRoles = [];
            vm.filters.roles = [];
            vm.availableFilters.roles = [
                {
                    id: true,
                    name: 'Admin',
                },
                {
                    id: false,
                    name: 'Normal',
                },
            ];
            vm.exports = [
                {
                    name: gettextCatalog.getString('User Export'),
                    data: {
                        category: 'users_with_domain_access',
                    },
                },
            ];
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }
            if (vm.selectedRoles.length > 0) {
                params.admin = vm.selectedRoles[0].id;
            }
            vm.promise = UserRepo.getAll(params).then(function (data) {
                vm.users = data;
            }, angular.noop);
        }

        function addUser (user) {
            user = user || {};
            var params = {
                body: FORM_CONSTANT.FORM_USER,
                size: 'md',
                data: { user: user },
            };

            const callback = function (user) {
                if (user) {
                    getPage();
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            if (changes.changes) {
                changes = changes.changes;
            }
            if (changes.roles.length === 1) {
                vm.selectedRoles = changes.roles;
            } else if (changes.roles.length > 1) {
                vm.selectedRoles.splice(0, 1);
            } else {
                vm.selectedRoles = [];
            }
            vm.getPage();
        }
    }
})();
