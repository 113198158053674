(function () {
    'use strict';
    angular.module('pages.global.changesHistory').service('historyPrepareService', HistoryPrepareService);

    HistoryPrepareService.$inject = ['$filter', 'complianceService', 'ng2ActiveFeatureService', 'ng2MonUtilsService', 'ng2HistoryHelperService'];

    /* @ngInject*/
    function HistoryPrepareService ($filter, complianceService, ng2ActiveFeatureService, ng2MonUtilsService, ng2HistoryHelperService) {
        var percentageParam = '0.[00]%';
        var seoIssuesStack = ['seo_errors_alerts_count', 'seo_errors_warnings_count', 'seo_errors_infos_count'];
        var qaIssuesStack = ['dead_links_count', 'dead_images_count', 'spelling_errors_confirmed_count'];
        var accessibilityIssuesStack = ['accessiblity_issues_count'];
        var MON_NUMBER = 'monNumber';
        var seoOpportunityOptions = [
            {
                attr: 'seo_errors_alerts_count',
                color: 'text-danger',
            },
            {
                attr: 'seo_errors_warnings_count',
                color: 'text-warning',
            },
            {
                attr: 'seo_errors_infos_count',
                color: 'text-info',
            },
            {
                attr: 'seo_errors_technical_count',
                color: 'text-primary',
            },
        ];

        return {
            accessibility: prepareAccessibilityHistory,
            qa: prepareQAHistory,
            seo: prepareSEOHistory,
        };

        function prepareAccessibilityHistory (history) {
            var result = [];
            var len = history.length;
            var story;
            var entry;
            var complianceAr;
            var allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
            var moduleAndType = 'accessibility';

            for (var i = 0; i < len; i += 1) {
                story = history[i];
                complianceAr = complianceService.accessibilityHistoryCompliance(story);
                entry = getEntry(story, history, complianceAr, accessibilityIssuesStack, allowVerticalAverage, moduleAndType, moduleAndType);

                entry.pages_with_accessibility_errors_count = story.pages_with_accessibility_errors_count;
                entry.accessibility_checks = story.accessibility_checks || {};
                entry.accessiblity_issues_count = $filter(MON_NUMBER)(story.accessiblity_issues_count);

                result.push(entry);
            }
            return result;
        }

        function prepareQAHistory (history) {
            var result = [];
            var len = history.length;
            var story;
            var entry;
            var complianceAr;
            var allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');

            // for is faster than map
            for (var i = 0; i < len; i += 1) {
                story = history[i];
                complianceAr = complianceService.qaHistoryCompliance(story);
                entry = getEntry(story, history, complianceAr, qaIssuesStack, allowVerticalAverage, 'qa');

                entry.documents_count = $filter(MON_NUMBER)(story.documents_count);
                entry.dead_links_count = $filter(MON_NUMBER)(story.dead_links_count);
                entry.dead_images_count = $filter(MON_NUMBER)(story.dead_images_count);
                entry.spelling_errors_confirmed_count = $filter(MON_NUMBER)(story.spelling_errors_confirmed_count);

                result.push(entry);
            }

            return result;
        }

        function prepareSEOHistory (history) {
            var result = [];
            var len = history.length;
            var story;
            var entry;
            var complianceAr;
            var allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');

            for (var i = 0; i < len; i += 1) {
                story = history[i];
                complianceAr = complianceService.seoHistoryCompliance(story);
                entry = getEntry(story, history, complianceAr, seoIssuesStack, allowVerticalAverage, 'seo');

                entry.documents_count = $filter(MON_NUMBER)(story.documents_count);
                entry.doughnutTotalCount = entry.totalCount;
                entry.opportunities = {};
                seoOpportunityOptions.forEach(function (opportunity) {
                    entry.opportunities[opportunity.attr] = $filter(MON_NUMBER)(story[opportunity.attr]);
                });

                result.push(entry);
            }

            return result;
        }

        // PRIVATE

        function getEntry (story, history, complianceAr, issuesStack, allowVerticalAverage, module, type) {
            var countAndError = getCountAndError(complianceAr, 100, null);

            var entry = {
                id: story.id,
                post_processing_done_at: story.post_processing_done_at,
                date: story.date,
                page_count: $filter(MON_NUMBER)(story.page_count),
                error: countAndError.error,
                totalCount: countAndError.totalCount,
                changePercentageValue: $filter(MON_NUMBER)(
                    ng2HistoryHelperService.getChangePercentageValue(story.id, history, issuesStack, type) / 100,
                    percentageParam,
                ),
                hasCompliance: countAndError.error !== null,
                changeValueColorClass: getChangeValueColorClass(story, history, issuesStack, type),
                changeValueIconClass: getChangeValueIconClass(story, history, issuesStack, type),
                compliancePercentage: ng2MonUtilsService.withinHundred(1 - countAndError.error / countAndError.totalCount) * 100,
            };

            if (allowVerticalAverage && story.vertical_scores && entry.hasCompliance) {
                var comparisonToAverage = Math.round(entry.compliancePercentage - story.vertical_scores[module]) / 100;

                entry.comparisonToAverage = $filter(MON_NUMBER)(comparisonToAverage, percentageParam);
                entry.comparisonToAverageStyle = ng2MonUtilsService.getTextStyleByNumberValue(comparisonToAverage);
            }

            return entry;
        }

        function getCountAndError (complianceAr, totalCount, error) {
            var result = {
                totalCount: totalCount,
                error: error,
            };

            if (Array.isArray(complianceAr)) {
                if (complianceService.hasLegacyCompliance()) {
                    result.totalCount = complianceAr[0] + complianceAr[1];
                }
                result.error = complianceAr[1];
            }
            return result;
        }

        function getChangeValueColorClass (story, history, issuesStack, type) {
            const value = ng2HistoryHelperService.getChangeValue(story.id, history, issuesStack, type);
            return ng2HistoryHelperService.getChangeColorClass(value);
        }

        function getChangeValueIconClass (story, history, issuesStack, type) {
            const value = ng2HistoryHelperService.getChangeValue(story.id, history, issuesStack, type);
            return ng2HistoryHelperService.getChangeIconClass(value);
        }
    }
})();
