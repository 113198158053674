import { AccessibilityFastTrackModel } from '@client/app/models/accessibility/fast-track.model';

(function () {
    'use strict';

    angular.module('services.api').factory('AccessibilityFastTrackRepo', AccessibilityFastTrackRepo);

    AccessibilityFastTrackRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'AccessibilityCheckModel'];
    /* @ngInject */
    function AccessibilityFastTrackRepo (ng2ApiService, ng2SessionService, AccessibilityCheckModel) {
        return {
            getAll: getAll,
            getAllChecks: getAllChecks,
            updateCheck: updateCheck,
            getAllPages: getAllPages,
            get: get,
            closeCaption: closeCaption,
            undoAccessibilityCheck: undoAccessibilityCheck,
            markCheckAsFixed: markCheckAsFixed,
        };

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/fast_track', params, config).then(
                toFastTrackModels,
            );
        }

        function get (id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/accessibility/fast_track/' + id, params, config).then(
                toFastTrackModel,
            );
        }

        function getAllChecks (id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/fast_track/' + id + '/checks',
                params,
                config,
            ).then(toModels);
        }

        function updateCheck (acc_id, check, params) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/fast_track/' + acc_id + '/checks/' + check.id,
                check,
                params,
            ).then(toModel);
        }

        function getAllPages (acc_id, check_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/fast_track/' + acc_id + '/checks/' + check_id + '/pages',
                params,
                config,
            );
        }

        function closeCaption (acc_id, data, params, config) {
            config = config || {};
            data = data || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.post(
                'domains/' + ng2SessionService.getDomainId(params) + '/accessibility/fast_track/' + acc_id + '/closed_caption',
                data,
                params,
                config,
            );
        }

        function undoAccessibilityCheck (domainId, accessibilitySourceCodeId, accessibilityCheckId, params) {
            params = params || {};
            return ng2ApiService.patch(
                'domains/' + domainId + '/accessibility/fast_track/' + accessibilitySourceCodeId + '/checks/' + accessibilityCheckId,
                params,
            );
        }

        function markCheckAsFixed (domain_id, source_code_id, check_id, params, headers) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.delete(
                'domains/' + domain_id + '/accessibility/fast_track/' + source_code_id + '/checks/' + check_id + '/pages',
                params,
                headers,
            );
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new AccessibilityCheckModel(model);
        }

        function toFastTrackModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toFastTrackModel(models[i]);
            }
            return models;
        }

        function toFastTrackModel (model) {
            return new AccessibilityFastTrackModel(model);
        }
    }
})();
