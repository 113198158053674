(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTableSeo', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/seo/seo.html',
        controller: SeoController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
            domainGroup: '<',
        },
    });

    SeoController.$inject = ['ng2ActiveFeatureService', 'exporter', 'historyPrepareService'];
    /* @ngInject */
    function SeoController (ng2ActiveFeatureService, exporter, historyPrepareService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.$onChanges = update;

        vm.historyOutput = [];
        vm.allowVerticalAverage = false;

        function activate () {
            exporter.connectToCSVExporter('seo', function () {
                return vm.historyOutput;
            });
        }

        function onDestroy () {
            exporter.disconnectFromCSVExporter('seo');
        }

        function update (changes) {
            vm.issuesStack = ['seo_errors_alerts_count', 'seo_errors_warnings_count', 'seo_errors_infos_count'];
            getSeoOpportunityOptions();

            if (changes.history) {
                vm.allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
                vm.historyOutput = historyPrepareService.seo(vm.history);
            }
        }

        function getSeoOpportunityOptions () {
            vm.seoOpportunityOptions = [
                {
                    attr: 'seo_errors_alerts_count',
                    color: 'text-danger',
                },
                {
                    attr: 'seo_errors_warnings_count',
                    color: 'text-warning',
                },
                {
                    attr: 'seo_errors_infos_count',
                    color: 'text-info',
                },
                {
                    attr: 'seo_errors_technical_count',
                    color: 'text-primary',
                },
            ];
        }
    }
})();
