(function () {
    'use strict';

    angular.module('filters.monsido').filter('monSetMyTimezone', setMyTimezoneController);

    setMyTimezoneController.$inject = [];
    /* @ngInject */
    function setMyTimezoneController () {
        return setMyTimezone;

        // //////////

        function setMyTimezone (time) {
            if (!(time instanceof Date)) {
                time = new Date(time);
            }
            var myTimezoneOffset = new Date().getTimezoneOffset() / 60; // Local timezone offset
                var timeInMilliSeconds = time.getTime(); // Time in milliseconds
                var timezoneOffset = time.getTimezoneOffset() / 60; // Server's timezone offset
                var differenceInMilliseconds = (myTimezoneOffset - timezoneOffset) * (1000 * 60 * 60); // Difference in hours

            return new Date(timeInMilliSeconds - differenceInMilliseconds); // Return date stamp
        }
    }
})();
