import { UserToOutputModel } from './user-to-output.model';

(function () {
    'use strict';

    angular.module('app.forms').component('formDomainUsers', {
        templateUrl: 'app/forms/domain/steps/users/user.html',
        controller: FormDomainUsersController,
        controllerAs: 'vm',
        bindings: {
            domain: '=',
            users: '<',
        },
    });

    FormDomainUsersController.$inject = ['Lodash'];
    /* @ngInject */
    function FormDomainUsersController (Lodash) {
        const vm = this;
        vm.$onInit = activate;
        vm.toggleVisible = toggleVisible;
        vm.toggleSendReport = toggleSendReport;
        vm.updatePageNumber = updatePageNumber;
        vm.updateItemsPerPage = updateItemsPerPage;
        vm.massVisibleChange = massVisibleChange;
        vm.massSendReportChange = massSendReportChange;
        vm.getDomainUserId = getDomainUserId;
        vm.isVisible = isVisible;
        vm.hasSendReport = hasSendReport;
        vm.usersOutput = [];
        vm.usersPerPage = 10;
        vm.currentPage = 1;

        // private
        vm.allUsers = [];

        function activate () {
            vm.domain.domain_users = vm.domain.domain_users || [];
            const users = copyCollection(vm.users);
            addKnownDomainUsers(users);

            for (let i = 0; i < users.length; i += 1) {
                const userToOutput = new UserToOutputModel(users[i]);
                userToOutput.name = userToOutput.getName();
                userToOutput.isVisible = isVisible(userToOutput);
                userToOutput.hasSendReport = hasSendReport(userToOutput);
                users[i] = userToOutput;
            }

            vm.allUsers = copyCollection(users);
            vm.usersOutput = getUsersOutputByPage();
        }

        function updatePageNumber (number) {
            vm.currentPage = number;
            vm.usersOutput = getUsersOutputByPage();
        }

        function updateItemsPerPage (number) {
            vm.usersPerPage = number;
            vm.currentPage = 1;
            vm.usersOutput = getUsersOutputByPage();
        }

        function toggleVisible (user) {
            const index = getDomainUserId(user);
            user.isVisible = !user.isVisible;
            const domainUser = vm.domain.domain_users[index];
            domainUser.visible = !domainUser.visible;
            if (!user.isVisible) {
                user.hasSendReport = false;
                domainUser.send_report = false;
            }
        }

        function toggleSendReport (user) {
            const index = getDomainUserId(user);
            user.hasSendReport = !user.hasSendReport;
            vm.domain.domain_users[index].send_report = !vm.domain.domain_users[index].send_report;
        }

        function massVisibleChange (e) {
            const visible = e.target.checked;
            vm.allUsers.forEach(user => {
                if (!user.customer_admin) {
                    user.isVisible = visible;
                }
                if (!user.isVisible) {
                    user.hasSendReport = false;
                }
            });
            vm.domain.domain_users.forEach(user => {
                if (!user.customer_admin) {
                    user.visible = visible;
                }
                if (!user.visible) {
                    user.send_report = false;
                }
            });
            vm.usersOutput = getUsersOutputByPage();
        }

        function massSendReportChange (e) {
            const send = e.target.checked;
            vm.allUsers.forEach(user => {
                if (user.isVisible) {
                    user.hasSendReport = send;
                }
            });
            vm.domain.domain_users.forEach(user => {
                if (user.visible) {
                    user.send_report = send;
                }
            });
            vm.usersOutput = getUsersOutputByPage();
        }

        // not private because of unit tests
        function getDomainUserId (user) {
            const index = Lodash.findIndex(vm.domain.domain_users, function (domainUser) {
                return domainUser.user_id === user.id;
            });

            if (index > -1) {
                return index;
            }

            setDomainUser(user);
            return vm.domain.domain_users.length - 1;
        }

        function isVisible (user) {
            const index = getDomainUserId(user);
            return user.customer_admin || Boolean(vm.domain.domain_users[index].visible);
        }

        function hasSendReport (user) {
            const index = getDomainUserId(user);
            return isVisible(user) && Boolean(vm.domain.domain_users[index].send_report);
        }

        // private

        function getUsersOutputByPage () {
            return copyCollection(vm.allUsers, (vm.currentPage - 1) * vm.usersPerPage, vm.usersPerPage * vm.currentPage);
        }

        function setDomainUser (user) {
            vm.domain.domain_users.push({
                user_id: user.id,
                visible: Boolean(user.customer_admin),
                send_report: false,
            });
        }

        function copyCollection (collection, start = 0, end) {
            const newCollection = collection.slice(start, end);
            newCollection.total = collection.total || 0;
            newCollection.perPage = vm.usersPerPage;
            newCollection.currentPage = vm.currentPage;
            return newCollection;
        }

        function addKnownDomainUsers (users) {
            users.forEach(user => {
                const index = Lodash.findIndex(vm.domain.domain_users, function (domainUser) {
                    return domainUser.user_id === user.id;
                });

                if (index === -1) {
                    setDomainUser(user);
                }
            });
        }
    }
})();
