(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuStatistics', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', '$filter'];
    function SideMenuController (gettextCatalog, $filter) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'SUMMARY']),
                    href: 'base.customer.domain.statistics.summary',
                },
                {
                    title: gettextCatalog.getString('Visitors'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS']),
                    href: 'base.customer.domain.statistics.visitors.summary',
                    activeRoutes: [
                        'base.customer.domain.statistics.visitors.summary',
                        'base.customer.domain.statistics.visitors.locations',
                        'base.customer.domain.statistics.visitors.locations.index',
                        'base.customer.domain.statistics.visitors.locations.regions',
                        'base.customer.domain.statistics.visitors.locations.cities',
                        'base.customer.domain.statistics.visitors.language',
                        'base.customer.domain.statistics.visitors.organizations',
                        'base.customer.domain.statistics.visitors.devices',
                        'base.customer.domain.statistics.visitors.browsers',
                        'base.customer.domain.statistics.visitors.os',
                        'base.customer.domain.statistics.visitors.resolutions',
                        'base.customer.domain.statistics.visitors.log',
                        'base.customer.domain.statistics.visitors.summary',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Visitors Summary'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_SUMMARY']),
                            href: 'base.customer.domain.statistics.visitors.summary',
                        },
                        {
                            title: gettextCatalog.getString('Locations'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_LOCATIONS']),
                            href: 'base.customer.domain.statistics.visitors.locations.index',
                            activeRoutes: [
                                'base.customer.domain.statistics.visitors.locations.index',
                                'base.customer.domain.statistics.visitors.locations.regions',
                                'base.customer.domain.statistics.visitors.locations.cities',
                            ],
                        },
                        {
                            title: gettextCatalog.getString('Language'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_LANGUAGE']),
                            href: 'base.customer.domain.statistics.visitors.language',
                        },
                        {
                            title: gettextCatalog.getString('Organizations'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_ORGANIZATIONS']),
                            href: 'base.customer.domain.statistics.visitors.organizations',
                        },
                        {
                            title: gettextCatalog.getString('Devices'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_DEVICES']),
                            href: 'base.customer.domain.statistics.visitors.devices',
                        },
                        {
                            title: gettextCatalog.getString('Browsers'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_BROWSERS']),
                            href: 'base.customer.domain.statistics.visitors.browsers',
                        },
                        {
                            title: gettextCatalog.getString('Operating Systems'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_OS']),
                            href: 'base.customer.domain.statistics.visitors.os',
                        },
                        {
                            title: gettextCatalog.getString('Screen Resolutions'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'VISITORS_SCREEN']),
                            href: 'base.customer.domain.statistics.visitors.resolutions',
                        },
                    ],
                },
                {
                    title: gettextCatalog.getString('Content'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT']),
                    href: 'base.customer.domain.statistics.pages',
                    activeRoutes: [
                        'base.customer.domain.statistics.pages.mostPopular',
                        'base.customer.domain.statistics.pages.mostDownloads',
                        'base.customer.domain.statistics.pages.leastPopular',
                        'base.customer.domain.statistics.pages.withoutVisits',
                        'base.customer.domain.statistics.pages.documentsWithoutDownloads',
                        'base.customer.domain.statistics.pages.siteSearch',
                        'base.customer.domain.statistics.pages.events',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Most Popular Pages'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_MOST_PAGES']),
                            href: 'base.customer.domain.statistics.pages.mostPopular',
                        },
                        {
                            title: gettextCatalog.getString('Least Popular Pages'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_LEAST_PAGES']),
                            href: 'base.customer.domain.statistics.pages.leastPopular',
                        },
                        {
                            title: gettextCatalog.getString('Most Downloads'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_MOST_DOWNLOADS']),
                            href: 'base.customer.domain.statistics.pages.mostDownloads',
                        },
                        {
                            title: gettextCatalog.getString('Pages without Visits'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_WITHOUT_VISITS']),
                            href: 'base.customer.domain.statistics.pages.withoutVisits',
                        },
                        {
                            title: gettextCatalog.getString('Docs without downloads'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'DOCUMENTS_WITHOUT_DOWNLOADS']),
                            href: 'base.customer.domain.statistics.pages.documentsWithoutDownloads',
                        },
                        {
                            title: gettextCatalog.getString('Events'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'EVENTS']),
                            href: 'base.customer.domain.statistics.pages.events',
                            eventName: 'statistics-content-events',
                        },
                        {
                            title: gettextCatalog.getString('Site Search'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'SITE_SEARCH']),
                            href: 'base.customer.domain.statistics.pages.siteSearch',
                            eventName: 'statistics-content-sitesearch',
                        },
                    ],
                },
                {
                    title: gettextCatalog.getString('Traffic Sources'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'SOURCES']),
                    href: 'base.customer.domain.statistics.sources.summary',
                    activeRoutes: [
                        'base.customer.domain.statistics.sources.summary',
                        'base.customer.domain.statistics.sources.searchEngines',
                        'base.customer.domain.statistics.sources.social',
                        'base.customer.domain.statistics.sources.domains',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Traffic Sources Summary'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'SOURCES_SUMMARY']),
                            href: 'base.customer.domain.statistics.sources.summary',
                        },
                        {
                            title: gettextCatalog.getString('Search Engines'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'SOURCES_SEARCH_ENGINES']),
                            href: 'base.customer.domain.statistics.sources.searchEngines',
                        },
                        {
                            title: gettextCatalog.getString('Social Networks'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'SOURCES_SOCIAL']),
                            href: 'base.customer.domain.statistics.sources.social',
                        },
                        {
                            title: gettextCatalog.getString('External Referring Domains'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'SOURCES_REFERRERS']),
                            href: 'base.customer.domain.statistics.sources.domains',
                        },
                    ],
                },
                {
                    title: gettextCatalog.getString('Entry and Exit Pages'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_ENTRY_EXIT']),
                    href: 'base.customer.domain.statistics.entryExit.entry',
                    activeRoutes: [
                        'base.customer.domain.statistics.entryExit.entry',
                        'base.customer.domain.statistics.entryExit.exit',
                        'base.customer.domain.statistics.entryExit.bounceRates',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Entry Pages'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_ENTRY']),
                            href: 'base.customer.domain.statistics.entryExit.entry',
                        },
                        {
                            title: gettextCatalog.getString('Exit Pages'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_EXIT']),
                            href: 'base.customer.domain.statistics.entryExit.exit',
                        },
                        {
                            title: gettextCatalog.getString('Bounce Rates'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'STATISTICS', 'CONTENT_BOUNCE_RATE']),
                            href: 'base.customer.domain.statistics.entryExit.bounceRates',
                        },
                    ],
                },
            ];
        }
    }
})();
