(function () {
    'use strict';

    angular.module('modules.business-intelligence').run(setup);

    setup.$inject = ['ng2MonEventsService', 'biService', 'businessIntelligenceApi', 'ng2OauthService', 'activeFeatures'];
    /* @ngInject */
    function setup (ng2MonEventsService, biService, Api, oauthService, features) {
        if (features.bi_tracking) {
            ng2MonEventsService.addListener('beforeLoadUser', function () {
                Api.config.headers.Authorization = oauthService.getTokenHeaderValue();
            });

            ng2MonEventsService.addListener('newAccessToken', () => {
                Api.config.headers.Authorization = oauthService.getTokenHeaderValue();
            });

            ng2MonEventsService.addListener('logout', function () {
                delete Api.config.headers.Authorization;
            });

            ng2MonEventsService.addListener('httpSlow', function (params) {
                biService.log(params);
            });
        }
    }
})();
