(function () {
    'use strict';

    angular.module('modules.spotlight').component('monSpotlight', {
        controller: SpotlightController,
        controllerAs: 'vm',
        templateUrl: 'app/modules/spotlight/components/spotlight/spotlight.html',
    });

    SpotlightController.$inject = ['hotkeys', '$scope', 'gettextCatalog'];
    /* @ngInject */
    function SpotlightController (hotkeys, $scope, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.toggleSpotlight = toggleSpotlight;
        vm.closeSpotlight = closeSpotlight;

        // //////////

        function activate () {
            vm.showSpotlight = false;
            vm.tooltip =
                '<div>' +
                gettextCatalog.getString('To toggle Quick Search:') +
                '<span class="keyboard-shortcut inline-block ml-1">ctrl</span> + <span class="keyboard-shortcut inline-block mr-1">space</span></div>';
            setupHotkeys();
        }

        function setupHotkeys () {
            hotkeys
                .bindTo($scope)
                .add({
                    combo: 'ctrl+space',
                    description: gettextCatalog.getString('Toggle spotlight search'),
                    callback: toggleSpotlight,
                })
                .add({
                    combo: 'esc',
                    description: gettextCatalog.getString('Close spotlight'),
                    callback: closeSpotlight,
                });
        }

        function toggleSpotlight () {
            vm.showSpotlight = !vm.showSpotlight;
        }

        function closeSpotlight () {
            vm.showSpotlight = false;
        }
    }
})();
