(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardPdf', {
        templateUrl: 'app/pages/accessibility/dashboard/pdf/pdf.html',
        controller: AccessibilityDashboardPdfController,
        controllerAs: 'vm',
        bindings: {},
    });

    AccessibilityDashboardPdfController.$inject = ['PDFService', 'ng2LinksRepoService', '$q'];
    /* @ngInject */
    function AccessibilityDashboardPdfController (PDFService, ng2LinksRepoService, $q) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.internal = {
                total: 0,
                reviewed: 0,
                unreviewed: 0,
            };

            vm.external = {
                total: 0,
                reviewed: 0,
                unreviewed: 0,
            };
            vm.loading = true;
            var promises = [];
            promises.push(getInternal(true));
            promises.push(getInternal(false));
            promises.push(getExternal(true));
            promises.push(getExternal(false));
            vm.progress = $q.all(promises).then(function (values) {
                vm.internal.reviewed =
                    values[1].total === '0' && values[0].total === '0' ? 1 : parseInt(values[0].total);
                vm.internal.unreviewed = parseInt(values[1].total);
                vm.internal.total = vm.internal.unreviewed + vm.internal.reviewed;

                vm.external.reviewed =
                    values[2].total === '0' && values[3].total === '0' ? 1 : parseInt(values[2].total);
                vm.external.unreviewed = parseInt(values[3].total);
                vm.external.total = vm.external.unreviewed + vm.external.reviewed;
                vm.loading = false;
            }, angular.noop);
        }

        function getInternal (review) {
            var params = {
                type: 'pdf',
                page_size: 1,
                page: 1,
                reviewed: review,
            };

            return PDFService.get(params);
        }

        function getExternal (review) {
            var params = {
                status: 'active',
                relation: 'external',
                type: 'pdf',
                page_size: 1,
                page: 1,
                accessibility_reviewed: review,
            };

            return ng2LinksRepoService.getAll(params);
        }
    }
})();
