(function () {
    'use strict';

    angular.module('app.components').component('textOverflowFix', {
        transclude: true,
        template: '<div class="text-overflow-container"><div class="text-overflow-item" ng-transclude></div></div>',
        controller: TextOverflowFixController,
        controllerAs: 'vm',
    });

    TextOverflowFixController.$inject = [];

    function TextOverflowFixController () {
        var vm = this;
    }
})();
