(function () {
    'use strict';

    angular.module('app.forms').component('formDomainExcludeIp', {
        templateUrl: 'app/forms/domain-exclude-ip/domain-exclude-ip.html',
        controller: DomainExcludeIpGuideController,
        controllerAs: 'vm',
        bindings: {
            domainId: '<',
            monDialogId: '@',
        },
    });

    DomainExcludeIpGuideController.$inject = ['ng2DomainRepoService', 'ng2DomainService', 'monDialog'];
    /* @ngInject */
    function DomainExcludeIpGuideController (ng2DomainRepoService, ng2DomainService, monDialog) {
        var vm = this;
        vm.$onInit = activate;
        vm.save = save;

        function activate () {
            vm.onSave = false;
            vm.ipAddresses = [];
            vm.progress = ng2DomainRepoService.getExcludedIps(vm.domainId).then((data) => {
                if (data) {
                    const excludedIps = data.excluded_ips;
                    vm.ipAddresses = excludedIps.map((ip) => {
                        return { text: ip };
                    });
                }
            }, angular.noop);
        }

        function close (data) {
            monDialog.close(vm.monDialogId, data);
        }

        function save () {
            vm.onSave = true;
            var params = {
                excluded_ips: vm.ipAddresses
                    .map(function (ip) {
                        return ip.text;
                    }),
            };

            ng2DomainService
                .update(vm.domainId, params)
                .then(function (data) {
                    close(data);
                }, angular.noop)
                .finally(function () {
                    vm.onSave = false;
                });
        }
    }
})();
