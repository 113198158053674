(function () {
    'use strict';
    angular.module('pages.inventory.dashboard').component('inventoryLinksCount', {
        templateUrl: 'app/pages/inventory/dashboard/links-count/links-count.html',
        controller: InventoryLinksCountController,
        controllerAs: 'vm',
        bindings: {
            notificationList: '<',
            panelHeaderTitle: '@?',
            panelHeaderSubtitle: '@?',
        },
    });

    InventoryLinksCountController.$inject = ['$window', '$timeout'];

    function InventoryLinksCountController ($window, $timeout) {
        var vm = this;
        vm.screenWidth = $window.innerWidth;

        $window.addEventListener('resize', setWindowSize);

        function setWindowSize () {
            vm.screenWidth = $window.innerWidth;
            $timeout();
        }
    }
})();
