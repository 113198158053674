import { cloneDeep } from 'lodash';

export interface AuditLogModelInterface {
    id: number;
    domain: unknown | null;
    author: string;
    target_uri: string;
    created_at: string;
    action: string;
    data: {
        identifier: string;
        changes: Record<string, string[]>
    };
    module: string;
    apiTarget?: string;
    apiTargetId?: number;
}

export class AuditLogModel implements AuditLogModelInterface {

    id: number;
    domain: unknown | null;
    author: string;
    target_uri: string;
    created_at: string;
    action: string;
    data: {
        identifier: string;
        changes: Record<string, string[]>
    };
    module: string;
    apiTarget?: string;
    apiTargetId?: number;

    constructor (data: AuditLogModel) {
        this.id = data.id;
        this.domain = cloneDeep(data.domain);
        this.author = data.author;
        this.target_uri = data.target_uri;
        this.created_at = data.created_at;
        this.action = data.action;
        this.data = cloneDeep(data.data);
        this.module = data.module;

        if (this.target_uri) {
            const sourceCodeIdString = this.target_uri.split(':');
            this.apiTarget = sourceCodeIdString[0];
            this.apiTargetId = +sourceCodeIdString[1];
        }
    }

}
