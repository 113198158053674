(function () {
    'use strict';

    angular.module('pages.inventory.content.pages').component('inventoryContentPages', {
        templateUrl: 'app/pages/inventory/content/pages/pages.html',
        controller: InventoryContentPagesController,
        controllerAs: 'vm',
        bindings: {
            apiTypes: '<',
            selectedTabs: '@?',
            headline: '<',
            icon: '@',
            monExport: '@',
        },
    });

    InventoryContentPagesController.$inject = ['ng2PageRepoService', '$controller', 'gettextCatalog', '$filter'];

    function InventoryContentPagesController (ng2PageRepoService, $controller, gettextCatalog, $filter) {
        var vm = this;

        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.issues = [];
            vm.pages = [];
            vm.search = null;
            vm.activeTab = null;
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            vm.subheaderTranslation = '';
            setupTabs();
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                type: vm.activeTab,
                'types[]': vm.activeTab === null ? vm.apiTypes : null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            params = updateParamsWithIssuesFilters(params);
            params = vm.updateParamsWithFilters(params);

            vm.promise = ng2PageRepoService.getAll(params).then(function (data) {
                vm.pages = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.pages.total, '0,0'),
                '1 content',
                '{{$count}} content',
                {},
            );
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            if (changes.contains) {
                vm.issues = changes.contains;
            } else {
                vm.issues = [];
            }
            getPage();
        }

        function updateParamsWithIssuesFilters (params) {
            for (var i = 0; i < vm.issues.length; i++) {
                params[vm.issues[i].id] = true;
            }
            return params;
        }

        function setupTabs () {
            var tabs = [{ name: 'All', faIcon: 'fas fa-copy', value: null }];

            if (vm.selectedTabs === 'documents') {
                tabs = tabs.concat([
                    {
                        name: gettextCatalog.getString('PDF Documents'),
                        value: 'pdf',
                    },
                    {
                        name: gettextCatalog.getString('Other Documents'),
                        value: 'document',
                    },
                ]);
            }

            vm.tabs = tabs;
        }
    }
})();
