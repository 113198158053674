(function () {
    'use strict';
    /**
     * @memberof modules.source-code-excludes
     * @ngdoc factory
     * @name SourceCodeExcludesService
     * @description Source Code Excludes Service
     */
    angular
        .module('modules.source-code-excludes')
        .factory('SourceCodeExcludesService', SourceCodeExcludesService);

    SourceCodeExcludesService.$inject = [
        'gettextCatalog',
        'SCE_MODULES',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject*/
    function SourceCodeExcludesService (
        gettextCatalog,
        SCE_MODULES,
        ng2ActiveFeatureService,
    ) {
        return {
            getTabs: getTabs,
            getDefaultTab: getDefaultTab,
            isAccessibility: isAccessibility,
        };

        function getTabs () {
            return [
                {
                    name: gettextCatalog.getString('Accessibility'),
                    value: SCE_MODULES.accessibility,
                    hasAccess: ng2ActiveFeatureService.isFeatureActive(
                        'admin_accessibility',
                    ),
                },
                {
                    name: gettextCatalog.getString('Misspellings'),
                    value: SCE_MODULES.misspelling,
                    hasAccess:
                        ng2ActiveFeatureService.isFeatureActive(
                            'admin_source_code_excludes',
                        ) &&
                        ng2ActiveFeatureService.isFeatureActive('qa_spellcheck'),
                },
                {
                    name: gettextCatalog.getString('Readability'),
                    value: SCE_MODULES.readability,
                    hasAccess:
                        ng2ActiveFeatureService.isFeatureActive(
                            'admin_source_code_excludes',
                        ) &&
                        ng2ActiveFeatureService.isFeatureActive(
                            'admin_readability',
                        ),
                },
            ].filter(function (module) {
                return module.hasAccess;
            });
        }

        function getDefaultTab () {
            var tabs = getTabs();
            return tabs[0].value;
        }

        function isAccessibility (module) {
            return SCE_MODULES.accessibility === module;
        }
    }
})();
