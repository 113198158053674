(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityGuidelinesQualWeb', {
        templateUrl: 'app/pages/accessibility/guidelines/acc-standards/qualweb/qualweb.html',
        controller: AccessibilityGuidelinesQualWebController,
        controllerAs: 'vm',
        bindings: {
            guidelines: '<',
            refreshPage: '&',
            progress: '<',
        },
    });

    AccessibilityGuidelinesQualWebController.$inject = [];

    function AccessibilityGuidelinesQualWebController () {}
})();
