(function () {
    'use strict';
    angular.module('modules.light.pages').component('lightAccount', {
        templateUrl: 'app/modules/light/pages/account/account.html',
        controller: LightAccountComponent,
        controllerAs: 'vm',
    });

    LightAccountComponent.$inject = ['ng2SessionService', 'CustomerService', 'ng2CoreAgreementService'];
    /* @ngInject */
    function LightAccountComponent (ng2SessionService, CustomerService, coreAgreementService) {
        var vm = this;

        vm.$onInit = activate;
        vm.editCustomer = editCustomer;

        function activate () {
            vm.collection = [];
            vm.customer = null;
            setupCustomer();
        }

        function editCustomer (customer) {
            CustomerService.editCustomer(customer, function (customer) {
                vm.customer = customer;
            });
        }

        // PROTECTED

        function setupCustomer () {
            vm.customer = ng2SessionService.customer;
            if (!vm.customer) {
                coreAgreementService.getAccount().then(function (customer) {
                    vm.customer = customer;
                });
            }
        }
    }
})();
