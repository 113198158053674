(function () {
    'use strict';
    angular.module('monsido', ['environment', 'app.core', 'modules', 'plugins', 'constants']).config(config);

    config.$inject = ['$urlServiceProvider'];

    function config ($urlServiceProvider) {
        $urlServiceProvider.deferIntercept();
    }
})();
