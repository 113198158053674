import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { AccessibilityIssueComponent } from './accessibility-issue/accessibility-issue.component';
import { MonIssueModule } from '@monsido/modules/issue/issue.module';
import { AngularSharedComponentsModule, PromptModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MonAccHelpCenterBtnComponent } from './mon-help-center-btn/mon-help-center-btn.component';
import { TablePolicyTypeComponent } from '@monsido/modules/table-policy-type/table-policy-type.component';
import { PageDetailsModule } from '@monsido/modules/page-details/page-details.module';
import { SharedModule } from '@monsido/ng2/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        MonIssueModule,
        AngularSharedComponentsModule,
        NgbTooltipModule,
        TablePolicyTypeComponent,
        PageDetailsModule,
        PromptModule,
        SharedModule,
    ],
    exports: [],
    declarations: [
        AccessibilityIssueComponent, MonAccHelpCenterBtnComponent,
    ],
    providers: [],
    schemas: [NO_ERRORS_SCHEMA],
})
export class AccessibilityComponentsModule {}
