(function() {
    "use strict";

    angular.module("modules.translations.run").run([
        "translation",
        "devMode",
        "gettextCatalog",
        "ng2CoreLoginService",
        function(translation, devMode, gettextCatalog, ng2CoreLoginService) {
            if (!ng2CoreLoginService.isLoggedIn()) {
                translation.setLanguage("en");
            }

            gettextCatalog.debug = devMode;
        },
    ]);
})();
