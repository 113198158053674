(function () {
    'use strict';

    angular.module('modules.light.components').component('sideMenuLight', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', '$filter', 'ng2SessionService', 'ng2ActiveFeatureService'];
    function SideMenuController (gettextCatalog, $filter, ng2SessionService, ng2ActiveFeatureService) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Domains'),
                    icon: $filter('monIcons')(['LIGHT', 'DOMAINS']),
                    href: 'base.customer.light.domains',
                },
                {
                    title: gettextCatalog.getString('Users'),
                    icon: $filter('monIcons')(['LIGHT', 'USERS']),
                    href: 'base.customer.light.users',
                },
                {
                    title: gettextCatalog.getString('Account Settings'),
                    icon: $filter('monIcons')(['LIGHT', 'ACCOUNT_SETTINGS']),
                    href: 'base.customer.light.account',
                },
            ];
        }
    }
})();
