(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScanLoginTypesCustomMultistep', {
        templateUrl: 'app/forms/domain/steps/scan/login-types/custom-multistep/custom-multistep.html',
        controller: DomainScanLoginTypesCustomMultistepController,
        controllerAs: 'vm',
        bindings: {
            login: '=',
        },
    });

    DomainScanLoginTypesCustomMultistepController.$inject = [];
    /* @ngInject */
    function DomainScanLoginTypesCustomMultistepController () {
        var vm = this;
        vm.$onInit = activate;
        vm.addStep = addStep;
        vm.removeStep = removeStep;

        function activate () {
            vm.login = vm.login || {};
            if (!isObject(vm.login.custom_multistep)) {
                vm.login.custom_multistep = {
                    steps: [''],
                };
            }
        }

        function addStep () {
            vm.login.custom_multistep.steps.push('');
        }

        function removeStep (index) {
            vm.login.custom_multistep.steps.splice(index, 1);
        }

        // PROTECTED

        function isObject (val) {
            return val !== null && typeof val === 'object';
        }
    }
})();
