(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsOrganizations', {
        templateUrl: 'app/pages/statistics/visitors/organizations/organizations.html',
        controller: StatisticsVisitorsController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsController.$inject = [
        'ng2StatsRepoService',
        'API_CONSTANTS',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        '$filter',
        'ng2SessionService',
        'dateRangeService',
        'ng2DateTimeService',
    ];
    /* @ngInject */
    function StatisticsVisitorsController (
        ng2StatsRepoService,
        API_CONSTANTS,
        $stateParams,
        ParamService,
        gettextCatalog,
        $filter,
        ng2SessionService,
        dateRangeService,
        ng2DateTimeService,
    ) {
        const vm = this;

        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.totalVisits = 0;
        vm.pageSize = 100;
        vm.loading = false;
        vm.updateDateRange = updateDateRange;
        vm.average = average;
        vm.$onInit = activate;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.chartType = 'bar-chart';
            vm.date = dateRangeService.setRangeDates();
            updateDateRange();
            getPage();

            vm.doughnutLabels = [];
            vm.doughnutData = [];
            vm.barData = [];
            vm.chartColors = [];
            vm.barOverride = [
                {
                    borderWidth: 0,
                    borderColor: '#50a6fb',
                    backgroundColor: '#50a6fb',
                },
            ];
            vm.pieOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    onHover: (element) => {
                        element.target.style.cursor = 'pointer';
                    },
                    onLeave: (element) => {
                        element.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    callbacks: {
                        title: () => gettextCatalog.getString('Visits'),
                        label: (tooltipItem, data) => $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]),
                    },
                },
            };
            vm.barOptions = {
                legend: {
                    display: false,
                    position: 'bottom',
                    fullWidth: true,
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: (value) => $filter('numeraljs')(value, '0,0'),
                                min: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        label: (tooltipItem, data) =>
                            $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]) + ' ' + gettextCatalog.getString('Visits'),
                    },
                },
            };
        }

        function getPage () {
            const params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format('YYYY-MM-DD'),
                to: vm.date.endDate?.format('YYYY-MM-DD'),
            };
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export visitors organization'),
                    name: gettextCatalog.getString('Visitors organization export'),
                    data: {
                        category: 'visitors_organizations',
                        category_ref: vm.date.startDate?.format('YYYY-MM-DD') + ',' + vm.date.endDate?.format('YYYY-MM-DD'),
                    },
                },
            ];
            let others = 0;
            vm.loading = true;
            vm.promise = ng2StatsRepoService.getOrganizations(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce((total, page) => total + parseInt(page.visits), 0);
                    vm.doughnutLabels = [];
                    vm.doughnutData = [];
                    for (let i = 0; i < vm.pages.length; i++) {
                        if (i < 5) {
                            vm.doughnutLabels.push(vm.pages[i].label);
                            vm.doughnutData.push(vm.pages[i].visits);
                            vm.chartColors.push('#50a6fb');
                        } else {
                            others += parseInt(vm.pages[i].visits);
                        }
                    }
                    if (vm.pages.length > 5) {
                        vm.doughnutLabels.push(gettextCatalog.getString('Others'));
                        vm.doughnutData.push(others);
                        vm.chartColors.push('#50a6fb');
                    }

                    vm.barData = [vm.doughnutData];
                }, angular.noop)
                .finally(() => {
                    vm.loading = false;
                });
        }

        function pageChange (page) {
            vm.page = page;
            getPage();
        }

        function perPageChange (pageSize) {
            vm.pageSize = pageSize;
            getPage();
        }

        function updateDateRange () {
            if (vm.date.startDate || vm.date.endDate) {

                ParamService.setParams({
                    from: ng2DateTimeService.format(vm.date.startDate, 'YYYY-MM-DD'),
                    to: ng2DateTimeService.format(vm.date.endDate, 'YYYY-MM-DD'),
                });
                getPage();
            }
        }

        function average (array, prop) {
            let sum = 0;
            if (!angular.isArray(array) || array.length === 0) {
                return 0;
            }
            angular.forEach(array, (item) => {
                sum += parseInt(item[prop]);
            });
            return sum / array.length;
        }
    }
})();
