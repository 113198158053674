<div class="mon-action-menu-wrapper">
    <mon-button-next
        #menuButton
        [monAriaHasPopup]="'true'"
        [monAriaControls]="menuId"
        [monPressed]="pressed"
        [id]="buttonId"
        type="button"
        monRightIcon="faChevronDown"
        (click)="menu.toggle()">
        <ng-content></ng-content>
    </mon-button-next>
    <mon-action-menu-panel
        #menu
        [monId]="menuId"
        (hide)="onHide()"
        (show)="onShow()"
        [monActionPanelItems]="monActionMenuItems"
        [monProjectedTemplate]="templateRef"
        [monLabelledBy]="buttonId"
        [attr.class]="monActionMenuClass">
    </mon-action-menu-panel>
</div>
