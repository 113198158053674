(function () {
    'use strict';

    angular.module('pages.global.policy').component('globalPolicyDialogsTargets', {
        templateUrl: 'app/pages/global/policy/dialogs/targets/targets.html',
        controller: DomainsController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
            monDialogId: '@?',
        },
    });

    DomainsController.$inject = [
        '$state',
        'ng2MonEventsService',
        'monDialog',
        'ng2TotalDomainsService',
        'ng2MonUIRouterTransitionService',
        'ng2DomainsService',
        'gettextCatalog',
    ];
    /* @ngInject */
    function DomainsController (
        $state,
        ng2MonEventsService,
        monDialog,
        ng2TotalDomainsService,
        ng2MonUIRouterTransitionService,
        ng2DomainsService,
        gettextCatalog,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToDomain = goToDomain;
        vm.targetDomainList = [];
        function activate () {
            vm.loading = true;
            vm.cPolicy = angular.copy(vm.policy);
            vm.maxCount = vm.cPolicy.targets.reduce(function (num, domain) {
                return Math.max(num, domain.matches_count);
            }, 0);
            vm.cPolicy.targets = vm.cPolicy.targets.sort(function (a, b) {
                return a.matches_count > b.matches_count ? -1 : a.matches_count < b.matches_count ? 1 : 0;
            });
            getDomains();
            vm.exports = [
                {
                    data: {
                        category: 'urls_with_policy_across_domains',
                        category_ref: vm.policy.id,
                        isGlobalExport: true,
                    },
                },
            ];
        }

        function getDomains () {
            ng2TotalDomainsService.domainsEssential.subscribe(function (domains) {
                vm.domains = ng2DomainsService.flattenEssentialDomainData(domains);
                vm.targetDomainList = getDomainsFromTarget(vm.cPolicy.targets);
                vm.loading = false;
            });
        }

        function goToDomain (id, type, groupId) {
            var params = {
                domainId: id,
                policy_id: vm.policy.id,
                search: vm.policy.name,
            };
            if (type === 'DomainGroup') {
                params.domainId = groupId;
                params.domain_group = id;
            }
            monDialog.close(vm.monDialogId);

            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.policy.policies', params).then(function () {
                    ng2MonEventsService.run('changed-domain');
                }, angular.noop),
            );
        }

        function getDomainsFromTarget (domainTargets) {
            return domainTargets.map((target) => {
                const currentDomain = vm.domains.find((domain) => domain.type === target.type && domain.id === target.id);
                if (!currentDomain) {
                    target.url = gettextCatalog.getString('Loading...');
                    return target;
                }
                if (currentDomain.type === 'DomainGroup') {
                    const parentDomain = vm.domains.find((domain) => domain.id === currentDomain.domainId);
                    target.url = parentDomain.url;
                    target.domainId = currentDomain.domainId;
                } else {
                    target.url = currentDomain.url;
                }
                return target;
            });
        }
    }
})();
