(function () {
    'use strict';

    angular.module('modules.roles').factory('RoleSectionsApiService', RoleSectionsApiService);

    RoleSectionsApiService.$inject = ['RolesDemoDataService'];

    /* @ngInject */
    function RoleSectionsApiService (RolesDemoDataService) {
        return {
            getAll: getAll,
            getUserRoleSections: getUserRoleSections,
        };

        // /////////////

        function getAll (params) {
            if (true) {
                return RolesDemoDataService.getData('role_sections', params);
            } else {
                // Add endpoint repo here....
            }
        }

        function getUserRoleSections (userId, params) {
            if (true) {
                return RolesDemoDataService.getUserRoleSections(userId, params);
            } else {
                // Add endpoint repo here....
            }
        }
    }
})();
