(function () {
    'use strict';

    angular.module('pages.domain').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.dashboard',
                config: {
                    url: '?page_id&target&sub_target',
                    template: '<domain-dashboard></domain-dashboard>',
                    data: { anonymous_access: false, title: gettextCatalog.getString('Acquia Optimize - Dashboard') },
                },
            },
        ];
    }
})();
