import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';

@Pipe({
    name: 'monRole',
})
export class RolePipe implements PipeTransform {
    constructor (private ng2SessionService: SessionService) {}

    transform (roles: string[]): boolean {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < roles.length; i++) {
            if (this.ng2SessionService.roles.indexOf(roles[i]) !== -1) {
                return true;
            }
        }
        return false;
    }
}
