import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Page } from '@monsido/modules/page-details/models';
import { RollbarErrorHandlerService } from 'ng2/external/rollbar/rollbar-error-handler.service';
import { DataPrivacyContentInterface } from '../interfaces/data-privacy-content.interface';
import { DataPrivacyInfoTypeInterface } from '../interfaces/data-privacy-info-type.interface';
import { DataPrivacyIgnoreInfoTypeForContentParams, DataPrivacyViolationStatus } from '../interfaces/data-privacy-issue-repo.interface';
import { DataPrivacyViolation } from '../interfaces/data-privacy-violation.interface';
import { stringifyRequestParams } from 'ng2/shared/utils';

@Injectable()
export class DataPrivacyIssueRepoService {
    constructor (
        private client: ApiClient,
        private sessionService: SessionService,
        private rollbar: RollbarErrorHandlerService,
    ) {}

    async getViolation (pageId: number, violationId: number): Promise<DataPrivacyViolation | undefined> {
        const domainId = this.sessionService.getDomainId({});

        if (!domainId) {
            this.rollbar.warn('getViolation - No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(
            `domains/${domainId}/pages/${pageId}/data_protection/violations/${violationId}`);
    }

    async getContent (dataPrivacyContentId: number): Promise<DataPrivacyContentInterface | undefined> {
        const domainId = this.sessionService.getDomainId({});

        if (!domainId) {
            this.rollbar.warn('getContent - No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(
            `domains/${domainId}/data_protection/fast_track/${dataPrivacyContentId}`);
    }

    async getInfoTypesFromContent (dataPrivacyContentId: number): Promise<DataPrivacyInfoTypeInterface[] | undefined> {
        const domainId = this.sessionService.getDomainId({});

        if (!domainId) {
            this.rollbar.warn('getInfoTypesFromContent - No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(
            `domains/${domainId}/data_protection/fast_track/${dataPrivacyContentId}/info_types`);
    }

    async getViolationsFromContent (dataPrivacyContentId: number): Promise<DataPrivacyViolation[] | undefined> {
        const domainId = this.sessionService.getDomainId({});

        if (!domainId) {
            this.rollbar.warn('getViolationsFromContent - No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(
            `domains/${domainId}/data_protection/fast_track/${dataPrivacyContentId}/violations`);
    }

    async ignoreInfoTypeForContent (
        dataPrivacyContentId: number,
        infoTypeId: number,
        params: DataPrivacyIgnoreInfoTypeForContentParams,
    ): Promise<void> {
        const domainId = this.sessionService.getDomainId({});

        if (!domainId) {
            this.rollbar.warn('ignoreInfoTypeForContent - No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.patchPromise(
            `domains/${domainId}/data_protection/fast_track/${dataPrivacyContentId}/info_types/${infoTypeId}`,
            { params },
        );
    }

    async markPageViolationAsFixed (pageId: number, issueId: number): Promise<void> {
        return this.updatePageViolationStatus(pageId, issueId, DataPrivacyViolationStatus.fixed);
    }

    async markPageViolationAsIgnored (pageId: number, issueId: number): Promise<void> {
        return this.updatePageViolationStatus(pageId, issueId, DataPrivacyViolationStatus.ignored);
    }

    async markPageViolationAsActive (pageId: number, issueId: number): Promise<void> {
        return this.updatePageViolationStatus(pageId, issueId, DataPrivacyViolationStatus.active);
    }

    async markDomainViolationAsFixed (issueId: number): Promise<void> {
        return this.updateDomainViolationStatus(issueId, DataPrivacyViolationStatus.fixed);
    }

    async markDomainViolationAsIgnored (issueId: number): Promise<void> {
        return this.updateDomainViolationStatus(issueId, DataPrivacyViolationStatus.ignored);
    }

    async markDomainViolationAsActive (issueId: number): Promise<void> {
        return this.updateDomainViolationStatus(issueId, DataPrivacyViolationStatus.active);
    }

    async getDataPrivacyIssuePages (params: Record<string, string | number | boolean>): Promise<Page[] | undefined> {
        const domainId = this.sessionService.getDomainId();

        if (!domainId) {
            this.rollbar.warn('getDataPrivacyIssuePages - No domain id was found!');
            return Promise.resolve(undefined);
        }
        params = params || {};
        return this.client.getPromise(`domains/${domainId}/pages`, { params: {
            ...stringifyRequestParams(params),
        } });
    }

    private async updatePageViolationStatus (pageId: number, issueId: number, status: DataPrivacyViolationStatus): Promise<void> {
        const domainId = this.sessionService.getDomainId({});

        if (!domainId) {
            this.rollbar.warn('updatePageViolationStatus - No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.patchPromise(
            `domains/${domainId}/pages/${pageId}/data_protection/violations/${issueId}`,
            { status },
        );
    }

    private async updateDomainViolationStatus (issueId: number, status: DataPrivacyViolationStatus): Promise<void> {
        const domainId = this.sessionService.getDomainId({});

        if (!domainId) {
            this.rollbar.warn('updateDomainViolationStatus - No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.patchPromise(
            `domains/${domainId}/data_protection/violations/${issueId}`,
            { status },
        );
    }
}
