import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.admin.shortcuts').component('adminShortcuts', {
        templateUrl: 'app/pages/admin/shortcuts/shortcuts.html',
        controller: LabelsController,
        controllerAs: 'vm',
    });

    LabelsController.$inject = [
        'ng2MonEventsService',
        '$controller',
        'gettextCatalog',
        'ng2MonModalService',
        'ShortcutService',
        'ng2ShortcutsRepoService',
        'FORM_CONSTANT',
    ];
    /* @ngInject */
    function LabelsController (
        ng2MonEventsService,
        $controller,
        gettextCatalog,
        ng2MonModalService,
        ShortcutService,
        ng2ShortcutsRepoService,
        FORM_CONSTANT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.openForm = openForm;
        vm.deleteShortcut = deleteShortcut;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = '';
            vm.promise = null;
            getPage();
        }

        function getPage () {
            var params = {
                search: vm.search || null,
                private: false,
                page: vm.page,
                page_size: vm.pageSize,
            };

            vm.promise = ng2ShortcutsRepoService.getAll(params).then(function (shortcuts) {
                vm.shortcuts = shortcuts;
                updateDropdowns();
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const shortcut of vm.shortcuts) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Edit Shortcut'),
                        leftIcon: 'faEdit',
                        action: ()=>openForm(shortcut),
                    },
                    {
                        label: gettextCatalog.getString('Delete Shortcut'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>deleteShortcut(shortcut),
                    },
                ];

                shortcut.dropdown = dropdown;
            }
        }

        function openForm (shortcut) {
            var params = {
                body: FORM_CONSTANT.FORM_SHORTCUT,
                data: {
                    shortcut: shortcut,
                },
            };
            const callback = function (shortcut) {
                if (shortcut) {
                    ShortcutService.getShortcuts();
                    getPage();
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function deleteShortcut (shortcut) {
            var params = {
                shortcutName: '<strong>' + shortcut.title + '</strong>',
            };
            var translation = gettextCatalog.getString(
                'Are you sure you wan to delete the shortcut {{ shortcutName }}',
                params,
            );
            ng2MonModalService.confirm(translation).then(function () {
                ng2ShortcutsRepoService.destroy(shortcut.id).then(function () {
                    ShortcutService.getShortcuts();
                    getPage();
                }, angular.noop);
            }, angular.noop);
        }
    }
})();
