import { Component, Input } from '@angular/core';

@Component({
    selector: 'mon-button-next',
    templateUrl: './button-next.component.html',
    styleUrls: ['./button-next.component.scss'],
})
class ButtonNextComponent {
    @Input() monType = 'button';
    @Input() monRole = '';
    @Input() monAriaLabel?: string;
    @Input() monAriaLabeledBy?: string;
    @Input() monAriaDescribedBy?: string;
    @Input() monAriaHasPopup?: string;
    @Input() monAriaControls?: string;
    @Input() monPressed?: boolean;
    @Input() monLeftIcon?: string;
    @Input() monSpinLeftIcon?: boolean;
    @Input() monRightIcon?: string;
    @Input() monSpinRightIcon?: boolean;
    @Input() monCustomButtonImage?: string;
    @Input() monCustomButtonImageClass?: string;
    @Input() monDisabled? = false;
    @Input() monTooltip?: string;
}

export { ButtonNextComponent as MonButtonNextComponent };
