import { Injectable } from '@angular/core';
import { AccessibilityCheckType } from 'app/models/types/accessibility-check.type';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityChecksMapService {

    private checksMap: Set<number> = new Set();
    private updatedAccessibilityCheck: Set<number> = new Set();
    private deletedPageFix: Set<number> = new Set();

    constructor () { }

    addAccessibilityCheck (check: AccessibilityCheckType): void {
        this.checksMap.add(check.id);
    }

    hasDirtyAccessibilityCheck (check: AccessibilityCheckType): boolean {
        return this.checksMap.has(check.id);
    }

    clearAccessibilityCheck (checkId: number): void {
        this.checksMap.delete(checkId);
    }

    addDeletedPageFix (pageFix: AccessibilityCheckType): void {
        this.deletedPageFix.add(pageFix.id);
    }

    hasDeletedPageFix (pageFix: AccessibilityCheckType): boolean {
        return this.deletedPageFix.has(pageFix.id);
    }

    removeUpdatedAccessibilityCheck (check: AccessibilityCheckType): void {
        this.updatedAccessibilityCheck.delete(check.id);
    }

    addUpdatedAccessibilityCheck (check: AccessibilityCheckType): void {
        this.updatedAccessibilityCheck.add(check.id);
    }

    hasAccessibilityCheckUpdated (check: AccessibilityCheckType): boolean {
        return this.updatedAccessibilityCheck.has(check.id);
    }
}
