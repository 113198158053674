(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormNewWindowOptions', {
        templateUrl: 'app/modules/page-fix/forms/new-window/options/options.html',
        controller: PageFixFormSkipToContentOptionsController,
        controllerAs: 'vm',
        bindings: {
            monPageFix: '<?',
            monPageFixId: '<',
            monPageFixSelector: '<',
            monType: '<',
            monSelected: '<',
            monOrder: '<',
        },
    });

    PageFixFormSkipToContentOptionsController.$inject = [
        'PAGE_FIX_SELECTOR_QUERIES',
        'PAGE_FIX_FIXABLE_TYPES',
        'PAGE_FIX_CHANGE_TYPES',
        'ng2MonEventsService',
        '$element',
        'PAGE_FIX_EVENT',
    ];
    /* @ngInject */
    function PageFixFormSkipToContentOptionsController (
        PAGE_FIX_SELECTOR_QUERIES,
        PAGE_FIX_FIXABLE_TYPES,
        PAGE_FIX_CHANGE_TYPES,
        ng2MonEventsService,
        $element,
        PAGE_FIX_EVENT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.resetText = resetText;

        function activate () {
            vm.requestPreparePayloadId = 0;
            vm.newWindowText = 'Do you want to leave this website?';
            ng2MonEventsService.run(PAGE_FIX_EVENT.REGISTER_VIEW, $element[0], {
                type: vm.monType,
                order: vm.monOrder,
                selected: vm.monSelected,
            });

            if (vm.monPageFix) {
                vm.newWindowText = vm.monPageFix.change.value;
            }
            vm.saving = false;
            setupListeners();
        }

        function onDestroy () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.UNREGISTER_VIEW, $element[0]);
            ng2MonEventsService.remove(vm.requestPreparePayloadId);
        }

        function resetText () {
            vm.newWindowText = 'Do you want to leave this website?';
        }

        function setupListeners () {
            vm.requestPreparePayloadId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.REQUEST_PREPARE_PAYLOAD, function () {
                var change = {
                    attribute: 'a',
                    check_id: vm.monPageFixId,
                    value: vm.newWindowText,
                };
                var payload;
                if (vm.pageFix) {
                    payload = { change: change };
                } else {
                    payload = {
                        change: change,
                        selector: PAGE_FIX_SELECTOR_QUERIES.query_selector + ';' + vm.monPageFixSelector,
                        fixable_type: PAGE_FIX_FIXABLE_TYPES.accessibility_check,
                        fixable_id: vm.monPageFixId,
                        change_type: PAGE_FIX_CHANGE_TYPES.element + ':a',
                    };
                }
                ng2MonEventsService.run(PAGE_FIX_EVENT.REQUEST_SUBMIT, payload);
            });
        }
    }
})();
