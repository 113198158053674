(function () {
    'use strict';

    angular.module('services.api').factory('AccessibilityChecksRepo', AccessibilityChecksRepo);

    AccessibilityChecksRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'AccessibilityCheckModel'];
    /* @ngInject */
    function AccessibilityChecksRepo (ng2ApiService, ng2SessionService, AccessibilityCheckModel) {
        var service = {
            getAll,
            getHelp,
            getAllPages,
            update,
        };

        return service;

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};

            const domainId = ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                `domains/${domainId}/accessibility/checks`,
                params,
                config,
            ).then(toModels);
        }

        function update (checkId, payload, params) {
            const domainId = ng2SessionService.getDomainId(params);

            return ng2ApiService.patch(
                `domains/${domainId}/accessibility/checks/${checkId}`,
                payload,
            ).then(toModel);
        }

        function getHelp (checkId, params, config) {
            config = config || {};
            params = params || {};

            const domainId = ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                `domains/${domainId}/accessibility/checks/${checkId}/help`,
                params,
                config,
            );
        }

        function getAllPages (checkId, params, config) {
            config = config || {};
            params = params || {};

            const domainId = ng2SessionService.getDomainId(params);
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                `domains/${domainId}/accessibility/checks/${checkId}/pages`,
                params,
                config,
            );
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new AccessibilityCheckModel(model);
        }
    }
})();
