import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import checksDemoData from './checks-demo-data.json';
import checkErrorsDemoData from './checks-errors-demo-data.json';

export type DemoCheckType = {
    id: number;
    name: string;
    errors_count: number;
};

export type DemoCheckErrorType = {
    id: number;
    result_type: string;
    ignored_reviewed: boolean;
    clarity_accessibility_check_id: number;
    page_id: number;
    page_number: number;
    element_tree_path: string;
    reason: string;
    clarity_comments: string;
    comments: string[];
    created_at: string;
    updated_at: string;
    latest_check_at: string;
};

@Injectable({
    providedIn: 'root',
})
export class DemoPageClarityRepoService {

    private checks: DemoCheckType[] = checksDemoData;
    private checkErrors: DemoCheckErrorType[] = checkErrorsDemoData;

    getAllChecks (documentId: number): Promise<DemoCheckType[]> {
        return this.getData<DemoCheckType[]>('getAllChecks').then((checks) => {
            return checks.map((check) => {
                check.errors_count = this.checkErrors.reduce((num, error) => {
                    if (error.page_id === documentId && error.clarity_accessibility_check_id === check.id) {
                        num++;
                    }
                    return num;
                }, 0);
                return check;
            });
        });
    }

    getCheckErrors (documentId: number, checkId: number): Promise<DemoCheckErrorType[]> {
        return this.getData<DemoCheckErrorType[]>('getCheckErrors').then((errors) => {
            return errors.filter((error) => {
                return error.page_id === documentId && error.clarity_accessibility_check_id === checkId;
            });
        });
    }

    updateCheckError (params): Promise<DemoCheckErrorType | undefined> {
        return this.getData<DemoCheckErrorType[]>('updateCheckError').then((errors) => {
            return errors.find((error) => {
                return error.id === params.error_id;
            });
        });
    }


    private getData <T = DemoCheckType[] | DemoCheckErrorType[]> (fnName: string): Promise<T> {
        return new Promise((resolve) => {
            const timer = Math.round(1000 * Math.random());
            setTimeout(() => {
                switch (fnName) {
                    case 'getAllChecks':
                        resolve(cloneDeep(this.checks) as T);
                        break;
                    case 'getCheckErrors':
                        resolve(cloneDeep(this.checkErrors) as T);
                        break;
                    case 'updateCheckError':
                        resolve(cloneDeep(this.checkErrors) as T);
                        break;
                    default:
                        resolve([] as T);
                }
            }, timer);
        });
    }
}
