(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyElement', {
        templateUrl: 'app/forms/global-policy/steps/rules/boxes/element/element.html',
        controller: PolicySettingsController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
            elements: '<',
        },
    });

    PolicySettingsController.$inject = ['ng2MonEventsService'];
    /* @ngInject */
    function PolicySettingsController (ng2MonEventsService) {
        var vm = this;
        var keysToConfirm = [' ', 'Enter'];
        var keysToCancel = ['Tab', 'Escape'];
        vm.$onDestroy = onDestroy;
        vm.enterAccessibleDNDRuleMenu = enterAccessibleDNDRuleMenu;

        vm.hoveredRule = false;
        vm.mouseOver = mouseOver;
        vm.mouseOut = mouseOut;
        vm.mouseDown = mouseDown;
        vm.tooltipIsOpen = {};

        function mouseOver () {
            vm.hoveredRule = true;
            ng2MonEventsService.run('dropZoneHoverTrue', { hoveredRule: vm.hoveredRule });
        }

        function mouseOut () {
            vm.hoveredRule = false;
            ng2MonEventsService.run('dropZoneHoverFalse', { hoveredRule: vm.hoveredRule });
        }

        function mouseDown () {
            window.addEventListener('drag', mouseDragListener);
            window.addEventListener('mouseup', mouseUpListener);
        }

        function onDestroy () {
            window.removeEventListener('click', closeAllDNDsrMenus);
            window.removeEventListener('drag', mouseDragListener);
        }

        function enterAccessibleDNDRuleMenu (e, index) {
            var dndMenuWrapper;
            if (e && e.key && keysToConfirm.indexOf(e.key) > -1) {
                e.preventDefault();
                e.stopImmediatePropagation();

                dndMenuWrapper = document.getElementById('rule-sr-dnd-menu-wrapper-' + index);
                if (dndMenuWrapper) {
                    e.target.setAttribute('aria-expanded', 'true');
                    vm.tooltipIsOpen[index] = true;
                    dndMenuWrapper.classList.add('open');
                    dndMenuWrapper.removeEventListener('keydown', handleArrowKeysOnRule);
                    dndMenuWrapper.addEventListener('keydown', handleArrowKeysOnRule);
                    window.removeEventListener('click', closeAllDNDsrMenus);
                    window.addEventListener('click', closeAllDNDsrMenus);
                    setMenuitemFocused(dndMenuWrapper, 0);
                }
            }
        }

        function handleArrowKeysOnRule (e) {
            var dndMenuWrapper = angular.element(e.target).closest('.accessibleDNDRuleMenu')[0];

            if (!dndMenuWrapper) {
                return;
            }

            e.preventDefault();
            e.stopImmediatePropagation();

            if (!e || !e.key || keysToCancel.indexOf(e.key) > -1) {
                setMenuitemFocused(dndMenuWrapper, 0);
                closeDNDMenu(dndMenuWrapper);
                return;
            }

            if (e.key === 'ArrowLeft') {
                setMenuitemFocused(dndMenuWrapper, 0);
                ng2MonEventsService.run('addDNDRuleCandidate', undefined);
            } else if (e.key === 'ArrowRight') {
                setMenuitemFocused(dndMenuWrapper, 1);
                var dndRuleCandidate = vm.elements[Number(dndMenuWrapper.getAttribute('data-index'))];
                if (dndRuleCandidate) {
                    ng2MonEventsService.run('addDNDRuleCandidate', dndRuleCandidate);
                }
            } else if (keysToConfirm.indexOf(e.key) > -1) {
                confirmKeyboardSelection(dndMenuWrapper);
            }
        }

        function confirmKeyboardSelection (dndMenuWrapper) {
            var selectedOption = dndMenuWrapper.querySelector('[data-focused="true"]');
            var selectedIndex = Array.prototype.slice.call(dndMenuWrapper.querySelectorAll('li[role="menuitem"]')).indexOf(selectedOption);
            if (selectedIndex === 1) {
                ng2MonEventsService.run('confirmDNDRuleCandidate');
            }
            closeDNDMenu(dndMenuWrapper);
        }

        function closeDNDMenu (dndMenuWrapper) {
            var menuButton;
            var index;
            if (!dndMenuWrapper) {
                return;
            }
            index = Number(dndMenuWrapper.getAttribute('data-index'));
            vm.tooltipIsOpen[index] = false;

            dndMenuWrapper.removeEventListener('keydown', handleArrowKeysOnRule);
            dndMenuWrapper.classList.remove('open');
            menuButton = dndMenuWrapper.parentElement.querySelector('button[aria-haspopup="menu"]');
            if (menuButton) {
                menuButton.removeAttribute('aria-expanded');
                menuButton.focus();
            }
            ng2MonEventsService.run('addDNDRuleCandidate', undefined);
        }

        function setMenuitemFocused (dndMenuWrapper, index) {
            var items;
            var itemToFocus;

            if (dndMenuWrapper) {
                items = dndMenuWrapper.querySelectorAll('li[role="menuitem"]') || [];
                itemToFocus = items[index];
                if (itemToFocus) {
                    Array.prototype.forEach.call(items, function (item) {
                        item.removeAttribute('data-focused');
                    });
                    itemToFocus.focus();
                    itemToFocus.setAttribute('data-focused', 'true');
                }
            }
        }

        function closeAllDNDsrMenus () {
            Array.prototype.forEach.call(document.querySelectorAll('.accessibleDNDRuleMenu.open'), function (dndMenuWrapper) {
                setMenuitemFocused(dndMenuWrapper, 0);
                closeDNDMenu(dndMenuWrapper);
            });
            window.removeEventListener('click', closeAllDNDsrMenus);
        }

        function mouseDragListener () {
            ng2MonEventsService.run('scrollToDropzone');
            window.removeEventListener('drag', mouseDragListener);
        }

        function mouseUpListener () {
            window.removeEventListener('drag', mouseDragListener);
            window.removeEventListener('mouseup', mouseUpListener);
        }
    }
})();
