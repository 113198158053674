(function () {
    'use strict';

    angular.module('modules.light.forms').component('formLiteUserDomains', {
        templateUrl: 'app/modules/light/forms/user/domains/domains.html',
        controller: UserDomainController,
        controllerAs: 'vm',
        bindings: {
            user: '=',
            domains: '<',
        },
    });

    UserDomainController.$inject = [];
    /* @ngInject */
    function UserDomainController () {
        var vm = this;
        vm.$onInit = activate;
        vm.domainIsVisible = domainIsVisible;
        vm.domainGroupIsVisible = domainGroupIsVisible;
        vm.setVisible = setVisible;
        vm.setDomain = setDomain;

        function activate () {
            vm.user.domain_users = vm.user.domain_users || [];
            vm.user.domain_group_members = vm.user.domain_group_members || [];
            vm.domainGroups = [];
        }

        function setDomain (domain, userParam, domainParam) {
            var index = getIndexOfUserDomain(domain, userParam, domainParam);
            if (index === -1) {
                var params = {
                    visible: false,
                    send_report: false,
                };
                params[domainParam] = domain.id;
                vm.user[userParam].push(params);
            }
        }

        function domainIsVisible (domain) {
            return isVisible(vm.user.domain_users, domain, 'domain_id');
        }

        function domainGroupIsVisible (domain) {
            return isVisible(vm.user.domain_group_members, domain, 'domain_group_id');
        }

        function isVisible (domains, domain, attr) {
            if (Boolean(vm.user.customer_admin) === false) {
                for (var i = 0; i < domains.length; i++) {
                    if (domains[i][attr] === domain.id) {
                        return domains[i].visible;
                    }
                }
            }
            return Boolean(vm.user.customer_admin);
        }

        function setVisible (domain, userParam, domainParam) {
            var index = getIndexOfUserDomain(domain, userParam, domainParam);
            if (index === -1) {
                setDomain(domain, userParam, domainParam);
                index = vm.user[userParam].length - 1;
            }
            vm.user[userParam][index].visible = !isVisible(vm.user[userParam], domain, domainParam);
        }

        function getIndexOfUserDomain (domain, userParam, domainParam) {
            for (var i = 0; i < vm.user[userParam].length; i++) {
                if (vm.user[userParam][i][domainParam] === domain.id) {
                    return i;
                }
            }

            return -1;
        }
    }
})();
