import { NgModule } from '@angular/core';
import { TranslatePipe } from '../pipe/translate.pipe';
import { CommonModule } from '@angular/common';
import { TranslateService } from 'app/services/translate/translate.service';
import { TranslateDirective } from '../directives/translate.directive';

@NgModule({
    imports: [CommonModule],
    exports: [TranslatePipe, TranslateDirective],
    declarations: [TranslatePipe, TranslateDirective],
    providers: [
        TranslateService,
    ],
})
export class TranslateModule {}
