import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';

    angular.module('app.components.dialog').component('scanDetailsDialog', {
        templateUrl: 'app/components/dialogs/scan-details/scan-details.html',
        controller: ScanDetailsController,
        controllerAs: 'vm',
        bindings: {
            domainObservable: '<',
            monDialogId: '@?',
        },
    });

    ScanDetailsController.$inject = ['ng2CrawlsRepoService', 'ng2DateTimeService', '$interval', 'CRAWL_STATE'];
    /* @ngInject */
    function ScanDetailsController (ng2CrawlsRepoService, ng2DateTimeService, $interval, CRAWL_STATE) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = destroyTimer;
        vm.timeDiff = timeDiff;

        function activate () {
            vm.isDone = false;
            vm.POSTPROCESSING = CRAWL_STATE.POSTPROCESSING;
            vm.COOKIE_SCAN = CRAWL_STATE.COOKIE_SCAN;
            subscribeDomain();
            getScan().finally(function () {
                startAutoReload();
            });
        }

        function subscribeDomain () {
            vm.domainSubscription = vm.domainObservable.subscribe(function (domain) {
                vm.domain = domain;
                vm.isDone = !domain || !domain.crawl_status;
            });
        }

        function getScan () {
            return ng2CrawlsRepoService.getScanDetails({}, contextTokens.NO_GLOBAL).then(
                function (data) {
                    vm.crawl = data;
                },
                function (response) {
                    if (parseInt(response.status) === 404) {
                        vm.isDone = true;
                        destroyTimer();
                    }
                },
            );
        }

        function startAutoReload () {
            vm.timerPromise = $interval(function () {
                getScan();
            }, 13000);
        }

        function destroyTimer () {
            if (typeof vm.timerPromise === 'object') {
                $interval.cancel(vm.timerPromise);
            }
            vm.timerPromise = null;
            if (vm.domainSubscription && typeof vm.domainSubscription.unsubscribe === 'function') {
                vm.domainSubscription.unsubscribe();
            }
        }

        function timeDiff (start) {
            return ng2DateTimeService.timeDiffString(undefined, start);
        }
    }
})();
