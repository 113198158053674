import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('app.components.pageDetails').component('pageDetailsBrokenlinksTable', {
        templateUrl: 'app/components/pagedetails/details/sections/qa/subsections/broken/table/table.html',
        controller: BrokenComponent,
        controllerAs: 'vm',
        bindings: {
            selected: '=',
            items: '<',
            onPageChange: '&',
            onPerPageChange: '&',
            type: '<',
            progress: '<',
            getPage: '&', // Required
            refreshPage: '&',
            page: '<',
            moduleName: '<',
        },
    });

    BrokenComponent.$inject = ['ng2ActiveFeatureService', '$location', 'ng2MonEventsService', 'ng2DirectNavigationService'];

    function BrokenComponent (ng2ActiveFeatureService, $location, ng2MonEventsService, ng2DirectNavigationService) {
        var vm = this;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.updatePage = updatePage;
        vm.allAreNotIgnored = allAreNotIgnored;
        vm.$onInit = activate;
        vm.openIssueModal = openIssueModal;

        function activate () {
            vm.showAutofix = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_links');
            ng2MonEventsService.addListener(MON_EVENTS.RELOAD_QA_BROKEN_PAGE_DETAILS, () => {
                updatePage();
            });
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ size: size });
        }
        function updatePage () {
            vm.refreshPage();
            vm.getPage();
        }

        function allAreNotIgnored () {
            return !vm.items.find(function (missppelling) {
                return missppelling.type === 'ignored';
            });
        }

        function openIssueModal (link) {
            ng2DirectNavigationService.navigateToIssueOverlay({
                issueOverlayIssueId: link.link_id,
                issueOverlayIssueType: link.link.type === 'image' ? 'qaBrokenImage' : 'qaBrokenLink',
                issueOverlayPageId: vm.page.id,
                issueOverlayPageLinkId: link.id,
            });
        }

    }
})();
