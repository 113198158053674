(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionStatistics', {
        templateUrl: 'app/components/pagedetails/details/sections/statistics/statistics.html',
        controller: SectionStatisticsController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            active: '<',
        },
    });

    SectionStatisticsController.$inject = ['ParamService', 'dateRangeService', 'DATE_FORMAT'];
    /* @ngInject */
    function SectionStatisticsController (ParamService, dateRangeService, DATE_FORMAT) {
        var vm = this;

        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;

        function activate () {
            vm.active = vm.active || 'summary';
            vm.page = vm.page || {};
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;
            vm.datePickerOptions = {
                maxDate: moment(),
            };
        }

        function updateDateRange () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
            });
        }
    }
})();
