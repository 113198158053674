import { Injectable } from '@angular/core';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { PageLinkModel, PageLinkType } from '@client/app/models/pages/page-link.model';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';

export type PagePageLinksGetAllRequestParams = BaseRepoRequestType & {
    type?: string,
    page_type?: string,
    status_code?: string,
    page_fixed?: boolean,
    status?: string,

}

export type PagePageLinksUpdateRequestParams = {
    group_id?: number,
}

@Injectable({
    providedIn: 'root',
})
export class PagePageLinksRepoService extends BaseRepo {
    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
    ) {
        super();
    }

    async getAll (pageId: number, params: PagePageLinksGetAllRequestParams = {}): Promise<MonTableCollection<PageLinkModel>> {
        params = this.setGroupId(params);
        return this.apiService
            .get<MonTableCollection<PageLinkType>>(`domains/${this.getDomainId()}/pages/${pageId}/page-links`, params)
            .then(res => this.toModels(res));
    }

    async get (pageId: number, pageLinkId: number, params: PagePageLinksGetAllRequestParams = {}): Promise<PageLinkModel> {
        params = this.setGroupId(params);
        return this.apiService
            .get<PageLinkType>(`domains/${this.getDomainId()}/pages/${pageId}/page-links/${pageLinkId}`, params)
            .then(res => this.toModel(res));
    }

    async update (pageId: number, pageLinkId: number, pageLink: Partial<PageLinkType>, params: PagePageLinksUpdateRequestParams = {}): Promise<PageLinkModel> {
        params = this.setGroupId(params);
        return this.apiService
            .patch<PageLinkType>(`domains/${this.getDomainId()}/pages/${pageId}/page-links/${pageLinkId}`, pageLink, params)
            .then(res => this.toModel(res));
    }

    private toModels (models: MonTableCollection<PageLinkType>): MonTableCollection<PageLinkModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models as MonTableCollection<PageLinkModel>;
    }

    private toModel (model: PageLinkType): PageLinkModel {
        return new PageLinkModel(model);
    }
}
