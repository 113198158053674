import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { MonModalService } from '@client/app/modules/modals/services/mon-modal/mon-modal.service';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { SupportRequestRepoService } from '@client/app/services/api/support-request-repo/support-request-repo.service';

// AI generated
@Injectable({
    providedIn: 'root',
})
export class PagePerformanceService {
    constructor (
        private sessionService: SessionService,
        private monEventsService: MonEventService,
        private monModalService: MonModalService,
        private translateService: TranslateService,
        private supportRequestRepoService: SupportRequestRepoService,
    ) {}

    requestMorePages (msg): Promise<void> {
        return this.requestPerformanceSupport(msg);
    }
    requestMoreProfiles (msg): Promise<void> {
        return this.requestPerformanceSupport(msg);
    }

    getMaxPageCount (): number {
        if (this.sessionService.customer !== null && !isNaN(this.sessionService.customer.plan_traits.performance_tracking_max_pages)) {
            return this.sessionService.customer.plan_traits.performance_tracking_max_pages;
        } else {
            return 0;
        }
    }

    getMaxProfileCount (): number {
        if (this.sessionService.customer !== null && !isNaN(this.sessionService.customer.plan_traits.performance_tracking_max_profiles)) {
            return this.sessionService.customer.plan_traits.performance_tracking_max_profiles;
        } else {
            return 0;
        }
    }

    async getReachedMaximumProfileMessageDialog (): Promise<void> {
        return new Promise(resolve => {
            const text = this.translateService.getString(
                'You have reached the maximum number of Performance profiles in your current plan.<br/><br/>' +
        // eslint-disable-next-line max-len
        'To inquire about pricing options for additional profiles, please press the button "Request quote for more profiles" and someone on our team will be in touch.',
            );
            this.monEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-shown' });
            this.monModalService.customDialog({
                title: '',
                message: text,
                buttons: [
                    {
                        label: this.translateService.getString('Cancel'),
                        className: 'btn btn-default btn-secondary',
                        callback: (): void => {
                            this.monEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-rejected' });
                        },
                    },
                    {
                        label: this.translateService.getString('Request quote for more profiles'),
                        className: 'btn btn-mon-primary',
                        callback: (): void => {
                            resolve();
                        },
                    },
                ],
            },'')
                .catch(() => {});
        });
    }

    private requestPerformanceSupport (msg: string): Promise<void> {
        const params = {
            code: 'performance',
            message: msg,
        };
        return this.supportRequestRepoService.create(params);
    }
}
// END AI generated
