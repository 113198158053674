(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsResolutions', {
        templateUrl: 'app/pages/statistics/visitors/resolutions/resolutions.html',
        controller: StatisticsVisitorsController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsController.$inject = [
        'ng2StatsRepoService',
        '$stateParams',
        'gettextCatalog',
        '$filter',
        '$q',
        'ng2SessionService',
        'dateRangeService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsVisitorsController (
        ng2StatsRepoService,
        $stateParams,
        gettextCatalog,
        $filter,
        $q,
        ng2SessionService,
        dateRangeService,
        DATE_FORMAT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.updateDateRange = updateDateRange;
        vm.average = average;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.chartType = 'bubble-chart';
            vm.totalVisits = 0;
            vm.pageSize = 100;
            vm.page = 1;
            vm.summary = {};
            vm.pages = [];
            vm.doughnutLabels = [];
            vm.doughnutData = [];
            vm.bubbleLabels = [];
            vm.bubbleData = [];
            vm.bubbleColors = [];
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;

            setupChartOptions();
            updateDateRange();
        }

        function getPage () {
            var params = {
                limit: vm.pageSize,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format('YYYY-MM-DD'),
                to: vm.date.endDate?.format('YYYY-MM-DD'),
            };
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export visitors resolutions'),
                    name: gettextCatalog.getString('Visitors resolutions export'),
                    data: {
                        category: 'visitors_resolutions',
                        category_ref: vm.date.startDate?.format('YYYY-MM-DD') + ',' + vm.date.endDate?.format('YYYY-MM-DD'),
                    },
                },
            ];
            vm.promise = ng2StatsRepoService.getResolutions(params).then(function (pages) {
                vm.pages = pages;
                vm.totalVisits = pages.reduce(function (total, page) {
                    return total + parseInt(page.visits);
                }, 0);
                var maxVisits = pages.reduce(function (a, b) {
                    return Math.max(a, parseInt(b.visits));
                }, 0);
                var stepSize = Math.ceil(maxVisits / 4);
                var limitPages = pages.filter(function (page, index) {
                    return index < 5;
                });
                vm.bubbleColors = pages.map(function (page) {
                    if (page.visits <= stepSize) {
                        return '#88cfeb';
                    } else if (stepSize < page.visits && page.visits <= 2 * stepSize) {
                        return '#3da3e9';
                    } else if (page.visits >= 2 * stepSize && page.visits < 3 * stepSize) {
                        return '#89cdfd';
                    } else if (page.visits >= 3 * stepSize) {
                        return '#3669d3';
                    }
                });
                vm.bubbleData = pages.map(function (page) {
                    return {
                        x: parseInt(page.label.substring(0, page.label.indexOf('x'))),
                        y: parseInt(page.label.substring(page.label.indexOf('x') + 1, page.label.length)),
                        r: setRadius(page.visits, maxVisits),
                    };
                });
                vm.bubbleLabels = pages.map(function (page) {
                    return page.label;
                });
                vm.doughnutLabels = limitPages.map(function (page) {
                    return page.label;
                });
                vm.doughnutData = limitPages.map(function (page) {
                    return parseInt(page.visits);
                });

                if (vm.pages.length > 5) {
                    vm.doughnutLabels.push(gettextCatalog.getString('Others'));
                    vm.doughnutData.push(
                        vm.pages.reduce(function (num, page, index) {
                            if (index < 5) {
                                num += parseInt(page.visits);
                            }
                            return num;
                        }),
                        0,
                    );
                }
            }, angular.noop);
            return vm.promise;
        }

        function setupChartOptions () {
            vm.doughnutOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    onHover: function (element) {
                        element.target.style.cursor = 'pointer';
                    },
                    onLeave: function (element) {
                        element.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    callbacks: {
                        title: function () {
                            return gettextCatalog.getString('Visits');
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };

            vm.bubbleSeries = [gettextCatalog.getString('1st'), gettextCatalog.getString('2nd'), gettextCatalog.getString('3rd')];
            vm.bubbleOptions = {
                pointStyle: 'rect',
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: function (val) {
                                    return val + 'px';
                                },
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: function (val) {
                                    return val + 'px';
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'point',
                    callbacks: {
                        label: function (tooltipItem) {
                            return (
                                vm.bubbleLabels[tooltipItem.index] +
                                ': ' +
                                $filter('numeraljs', '0,0')(vm.pages[tooltipItem.index].visits) +
                                ' ' +
                                gettextCatalog.getString('visits')
                            );
                        },
                    },
                },
            };
        }

        function pageChange (page) {
            vm.page = page;
            vm.loading = true;
            getPage().finally(function () {
                vm.loading = false;
            });
        }

        function perPageChange (pageSize) {
            vm.pageSize = pageSize;
            vm.loading = true;
            getPage().finally(function () {
                vm.loading = false;
            });
        }

        function updateDateRange () {
            vm.loading = true;
            if (vm.date.startDate || vm.date.endDate) {
                var promises = [getPage(), getSummary()];
                $q.all(promises).finally(function () {
                    vm.loading = false;
                });
            }
        }

        function average (array, prop) {
            var sum = 0;
            if (!angular.isArray(array) || array.length === 0) {
                return 0;
            }
            angular.forEach(array, function (item) {
                sum += parseInt(item[prop]);
            });
            return sum / array.length;
        }

        function getSummary () {
            var params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format('YYYY-MM-DD'),
                to: vm.date.endDate?.format('YYYY-MM-DD'),
                type: 'total',
            };
            return ng2StatsRepoService.getSummary(params).then(function (data) {
                vm.summary = data;
            }, angular.noop);
        }

        function setRadius (val, max) {
            val = parseInt(val);
            if (val > 0) {
                var maxRadiusOnChartElements = 50; // Pixel values
                var minRadiusOnChartElements = 5;
                if (val === max) {
                    return maxRadiusOnChartElements;
                } else {
                    var res = (val * maxRadiusOnChartElements) / max;
                    return res < minRadiusOnChartElements ? minRadiusOnChartElements : res;
                }
            }
            return 0.00001;
        }
    }
})();
