(function () {
    'use strict';

    angular.module('models').factory('Comment', Comment);

    Comment.$inject = ['userService', 'User'];

    function Comment (userService, User) {
        function Model (attributes) {
            angular.extend(this, attributes);
            getUser(this);
        }

        return Model;

        // /////////

        function getUser (comment) {
            var id = comment.user_id || comment.created_by.id;
            userService.get(id).then(function (user) {
                comment.user = new User(user);
            }, angular.noop);
        }
    }
})();
