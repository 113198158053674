(function () {
    'use strict';

    angular.module('modules.page_fix.components').component('pageFixChangesetCustom', {
        templateUrl: 'app/modules/page-fix/components/changeset/spelling/spelling.html',
        controller: AutofixChangesetCustomController,
        controllerAs: 'vm',
        bindings: {
            data: '<',
        },
    });

    AutofixChangesetCustomController.$inject = [];
    /* @ngInject */
    function AutofixChangesetCustomController () {
        var vm = this;
    }
})();
