import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('app.components.pages').component('brokenTable', {
        templateUrl: 'app/components/pages/broken/broken.html',
        controller: BrokenComponent,
        controllerAs: 'vm',
        bindings: {
            selected: '=',
            items: '<',
            onPageChange: '&',
            onPerPageChange: '&',
            onSortPage: '&',
            currentSort: '<',
            type: '<',
            progress: '<',
            getPage: '&', // Required
            labels: '<',
            selectedLabels: '<',
            getLabels: '&',
        },
    });

    BrokenComponent.$inject = [
        'ng2SessionService',
        'monDialog',
        'ng2ActiveFeatureService',
        'gettextCatalog',
        'linkService',
        'ng2MonEventsService',
    ];

    function BrokenComponent (
        ng2SessionService,
        monDialog,
        ng2ActiveFeatureService,
        gettextCatalog,
        linkService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.openPages = openPages;
        vm.$onInit = activate;
        vm.$onChanges = update;
        vm.updateLinksLabel = updateLinksLabel;

        function activate () {
            vm.showDocuments = ng2SessionService.domain.scan.scan_documents || false;
            vm.showAutofix = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_links');
        }

        function update () {
            setNoResultMessage();
        }

        function setNoResultMessage () {
            switch (vm.type) {
                case 'dead':
                    vm.noResultMessage = gettextCatalog.getString('Good job! No issues were found');
                    break;
                case 'ignored':
                    vm.noResultMessage = gettextCatalog.getString('No Ignored Broken Links');
                    break;
                case 'fixed':
                    vm.noResultMessage = gettextCatalog.getString('No Broken Links marked as Fixed');
                    break;
                default:
                    vm.noResultMessage = gettextCatalog.getString('Good job! No issues were found');
                    break;
            }
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ size: size });
        }

        function openPages (link, type) {
            var params = {
                body: 'linkContent',
                size: 'md',
                data: { link: link, type: type },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function updateLinksLabel (link) {
            var params = {
                id: link.id,
                labels: link.labels.map(function (label) {
                    return label.id;
                }),
            };
            linkService.update(params).then(angular.noop, angular.noop);
        }
    }
})();
