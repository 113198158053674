export enum ChangeTypeEnum {
    NEW_VERSION = 'new_version',
    BY_USER = 'by_user',
    NEW_VERSION_AND_BY_USER = 'new_version_and_by_user',
}

export type CrawlType = {
    post_processing_done_at: string;
    accessibility_guideline: string;
    accessibility_errors_only: boolean;
};

export type AccessibilityVersionChangeType = {
    title: string;
    infoLink: string;
    time: string;
    changeType: ChangeTypeEnum;
};

export type AccessibilityGuidelineVersion = {
    title: string;
}
