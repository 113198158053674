(function () {
    'use strict';

    angular.module('modules.languages').filter('translateLanguage', TranslateLanguageController);

    TranslateLanguageController.$inject = ['LanguagesService'];
    /* @ngInject */
    function TranslateLanguageController (LanguagesService) {
        return result;

        // //////////

        /**
         *
         * @param locale - localization registered in the Backend
         */
        function result (locale) {
            return LanguagesService.getLanguageName(locale) || '';
        }
    }
})();
