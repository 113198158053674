(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboard', {
        templateUrl: 'app/pages/accessibility/accessibility.html',
        controller: AccessibilityDashboardController,
        controllerAs: 'vm',
    });

    AccessibilityDashboardController.$inject = ['ng2SessionService', 'gettextCatalog', '$filter'];
    /* @ngInject */
    function AccessibilityDashboardController (ng2SessionService, gettextCatalog, $filter) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.pdfscan = ng2SessionService.domain.scan.scan_documents;
            vm.level = '';

            switch (true) {
                case $filter('accessibilityHasGuideline')('qualweb'):
                    vm.level = 'QUALWEB';
                    break;
                case $filter('accessibilityHasGuideline')('wcag2'):
                    vm.level = 'WCAG 2.0';
                    break;
                case $filter('accessibilityHasGuideline')('wcag21'):
                    vm.level = 'WCAG 2.1';
                    break;
                case $filter('accessibilityHasGuideline')('wcag22'):
                    vm.level = 'WCAG 2.2';
                    break;
            }
            vm.subHeader = gettextCatalog.getString(
                'Test your website’s compliance levels ({{level}}) and fix issues that are making it difficult for people with disabilities to use your website',
                {
                    level: vm.level,
                },
            );
        }
    }
})();
