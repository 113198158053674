(function () {
    'use strict';

    angular.module('base.product-updates').component('productUpdates', {
        templateUrl: 'app/pages/base/product-updates/product-updates.html',
        controller: ProductUpdatesController,
        controllerAs: 'vm',
    });

    ProductUpdatesController.$inject = [
        '$controller',
        'SupportProductUpdateRepo',
        'MODULES_COLORS_AND_ICONS',
        'gettextCatalog',
    ];
    /* @ngInject */
    function ProductUpdatesController ($controller, SupportProductUpdateRepo, MODULES_COLORS_AND_ICONS, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onSelectedModulesChange = onSelectedModulesChange;
        vm.onSelectedCategoryChange = onSelectedCategoryChange;
        vm.getModuleIcon = getModuleIcon;
        vm.getModuleColor = getModuleColor;
        vm.onSearch = onSearch;
        vm.getCategoryName = getCategoryName;
        vm.getModuleTooltip = getModuleTooltip;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.updates = [];
            vm.selectedModules = [];
            vm.selectedCategory = null;
            vm.search = null;
            vm.promise = null;
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                category: vm.selectedCategory,
                sort_by: 'publish_date',
                sort_dir: 'desc',
            };

            if (angular.isArray(vm.selectedModules) && vm.selectedModules.length > 0) {
                params['modules[]'] = vm.selectedModules;
            }

            vm.promise = SupportProductUpdateRepo.getAll(params).then(function (data) {
                vm.updates = data;
            }, angular.noop);
        }

        function onSelectedModulesChange (modules) {
            vm.selectedModules = modules;
            getPage();
        }

        function onSelectedCategoryChange (category) {
            vm.selectedCategory = category;
            getPage();
        }

        function getModuleIcon (mod) {
            var value = MODULES_COLORS_AND_ICONS[mod];
            if (value) {
                return value.icon;
            }
        }

        function getModuleColor (mod) {
            var value = MODULES_COLORS_AND_ICONS[mod];
            if (value) {
                return value.color;
            }
        }

        function getCategoryName (update) {
            switch (update.category) {
                case 'change':
                    return gettextCatalog.getString('Update');
                default:
                    return gettextCatalog.getString('New feature');
            }
        }

        function getModuleTooltip (module) {
            switch (module) {
                case 'qa':
                    return gettextCatalog.getString('Quality Assurance');
                case 'accessibility':
                    return gettextCatalog.getString('Accessibility');
                case 'seo':
                    return gettextCatalog.getString('Search Engine Optimization (SEO)');
                case 'uptime':
                    return gettextCatalog.getString('Heartbeat');
                case 'statistics':
                    return gettextCatalog.getString('Statistics');
                case 'policy':
                    return gettextCatalog.getString('Policies');
                case 'inventory':
                    return gettextCatalog.getString('Inventory');
                case 'settings':
                    return gettextCatalog.getString('Settings');
                default:
                    return gettextCatalog.getString('Info not available');
            }
        }

        function onSearch (search) {
            vm.search = search;
            vm.getPage();
        }
    }
})();
