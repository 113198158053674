import { cloneDeep } from 'lodash';

export type CookieBannerSettingsType = {
    enabled: boolean,
    direction: string,
    privacyRegulation: string,
    cookie_policy_url: string,
    optOutUrl: string,
    cookies_overview: string[],
    per_category_consent: boolean,
    hasOverlay: boolean,
    explicit_reject_option: boolean,
    enable_iab_and_consent_fw: boolean,
    support_google_consent_mode: boolean,
    icon_picture_url: string,
    icon_shape: string,
    included_languages: string[],
    defaultLanguage: string,
    theme: {
        buttonColor: string,
        buttonTextColor: string,
    },
}

export class FormDomainScriptCookieBannerModel {
    private canSave = true;
    readonly settings: CookieBannerSettingsType;

    constructor (model: Partial<CookieBannerSettingsType>, defaultSettings: CookieBannerSettingsType) {
        this.settings = cloneDeep(defaultSettings);
        for (const key of Object.keys(model)) {
            this.settings[key] = cloneDeep(model[key]);
        }
    }

    setSettings (newSettings: Partial<CookieBannerSettingsType> | null): void {
        if (newSettings !== null) {
            for (const key of Object.keys(newSettings)) {
                this.settings[key] = cloneDeep(newSettings[key]);
            }
        }
    }

    canSaveModel (): boolean {
        return Boolean(this.canSave);
    }
}
