<form *ngIf="report" #scheduleReportForm="ngForm" (ngSubmit)="scheduleReportForm.valid && sendReport()" >
    <div class="mon-dialog-header">
        <div class="outer-t-smurf">
            <mon-page-header header="{{'Schedule Report' | translate}}"
                             panelLeftClass="col-48 outer-t-mini">
            </mon-page-header>
        </div>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card panel-monsido-card outer-t outer-r outer-l">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-3">
                    <div>
                        <h3 class="outer-t-none">{{report.template.title}}</h3>
                        <div class="label ruler-info font-size-1-1 outer-b-medium inline-block">{{report.template.name | translateReport}}</div>
                        <h4 class="card-header card-title">{{'Select a domain and recipients' | translate}}</h4>
                    </div>
                    <div>
                        <mon-table-search
                                (getPage)="onDomainSearch($event)"
                                [model]="domainSearchTerm"
                                [minSearchLength]="3"
                        >
                        </mon-table-search>
                    </div>
                </div>
                <mon-table-container [collection]="domains"
                                     (pageChangeEvent)="onDomainsPageChange($event)"
                                     (perPageChange)="onDomainsPageSizeChange($event)"
                                     [loading]="loading"
                >
                    <div class="table-container-body mb-5">
                        <div *ngFor="let domain of domains; index as i">
                            <div class="domain">
                                <input type="checkbox"
                                       [checked]="domainList[domain.id] === 'Domain'"
                                       (change)="onChange('Domain', domain.id)"
                                >
                                <div class="url-title">
                                    <mon-url-title [title]="domain.title" [url]="domain.url" [active]="false"></mon-url-title>
                                </div>
                                <ng-container *ngIf="domain.users">
                                    <div class="users">
                                       <span
                                               *ngFor="let user of domain.users"
                                               [ngbTooltip]="user | getUserName"
                                               placement="top"
                                               [container]="'body'">
                                               <ngx-avatar [name]="user | getUserName"
                                                           size="20"
                                                           [textSizeRatio]="2"
                                                           placement="top">
                                               </ngx-avatar>
                                       </span>
                                        <ng-template #tooltipText>
                                            <div [innerHTML]="getUsersName(domain.users)"></div>
                                        </ng-template>
                                        <span *ngIf="domain.users.length > maxIcons"
                                              [ngbTooltip]="tooltipText"
                                              [container]="'body'"
                                              placement="left"
                                              class="text-grey">
                                               + {{ domain.users.length - maxIcons }} {{'more' | translate}}
                                           </span>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="domain.domain_groups">
                                <div class="domain-group" *ngFor="let domainGroup of domain.domain_groups | orderBy: 'title'; trackBy:domainGroupById">
                                    <input type="checkbox" (change)="onChange('DomainGroup', domainGroup.id)" [checked]="domainList[domainGroup.id] === 'DomainGroup'">
                                    <mon-icon [size]="'2x'" [styles]="{color: '#b3b3b4'}" [icon]="['DOMAIN_GROUPS', 'ICON']"></mon-icon>
                                    <a class="inner-t-mini primary-text-grey inline-block">{{domainGroup.title}}</a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </mon-table-container>
            </div>
        </div>
    </div>
    <div class="mon-dialog-footer">
        <div class="row">
            <div class="col-md-48 text-right">
                <button [disabled]="saving"
                     class="btn btn-border-radius btn-secondary"
                     type="submit">
                 <span *ngIf="!saving">
                     {{'Save' | translate}}
                 </span>
                    <span aria-hidden="true" *ngIf="saving">
                        <mon-icon [spin]="true" [icon]="['SPINNER', 'ICON']"></mon-icon>
                    </span>
                </button>
            </div>
        </div>
    </div>
</form>
