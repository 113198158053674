import { ChartFactory } from './base-chart/chart-factory';
(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name SEOLineChartService
     * @description
     * Create the necessary objects to shape the SEO line chart
     */
    angular.module('blocks.service').factory('SEOLineChartService', SEOLineChartService);

    SEOLineChartService.$inject = ['gettextCatalog', 'LineChart', 'ChartsDataHelper'];

    function SEOLineChartService (gettextCatalog, LineChart, ChartsDataHelper) {
        let seoLineChartService;
        function init () {
            seoLineChartService = new ChartFactory(new LineChart());
            seoLineChartService.setOptions([gettextCatalog.getString('Issues'), gettextCatalog.getString('Pages')]);
            seoLineChartService.setColors(['#A94442', '#F0AD4E', '#31708F', '#727980', '#ff9d4e', '#a9dad6']);
            seoLineChartService.setSeries([
                gettextCatalog.getString('High'),
                gettextCatalog.getString('Medium'),
                gettextCatalog.getString('Low'),
                gettextCatalog.getString('Technical'),
                gettextCatalog.getString('Pages with issues'),
                gettextCatalog.getString('Scanned pages'),
            ]);
            seoLineChartService.setOverrides([4, 2]);
            seoLineChartService.setData([
                'seo_errors_alerts_count',
                'seo_errors_warnings_count',
                'seo_errors_infos_count',
                'seo_errors_technical_count',
                'pages_with_seo_errors_count',
                'page_count'],
            new ChartsDataHelper('time'));
            return seoLineChartService;
        }
        return init;
    }
})();
