(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('pageDetailsSectionQaIgnoredSpell', {
        templateUrl: 'app/components/pagedetails/details/sections/qa/subsections/ignored-spell/ignored-spell.html',
        controller: IgnoredSpellingErrorsController,
        controllerAs: 'vm',
        bindings: {
            activePage: '<',
            type: '@',
            headline: '@?',
            refreshPage: '&',
        },
    });

    IgnoredSpellingErrorsController.$inject = [
        'ng2SpellingRepoService',
        '$controller',
        'ng2MonModalService',
        'gettextCatalog',
        'spellService',
        'ng2TrapFocusService',
    ];
    /* @ngInject */
    function IgnoredSpellingErrorsController (ng2SpellingRepoService, $controller, ng2MonModalService, gettextCatalog, spellService, ng2TrapFocusService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.confirmRemoveIgnoredMisspelling = confirmRemoveIgnoredMisspelling;
        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = '';
            vm.misspellings = [];
            getPage();
        }
        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
            };
            vm.promise = ng2SpellingRepoService.getAllIgnoredPageSpellingErrors(vm.activePage.id, params).then(function (misspellings) {
                vm.misspellings = misspellings;
            }, angular.noop);
        }

        function confirmRemoveIgnoredMisspelling (misspelling) {
            var params = {
                title: gettextCatalog.getString('Are you sure you want to unignore this misspelling?'),
                message: gettextCatalog.getString('The misspelling will first show up after a new scan'),
                buttons: [
                    {
                        label: gettextCatalog.getString('Cancel'),
                        className: 'btn btn-secondary',
                        callback: function () {
                            ng2TrapFocusService.unPauseLast();
                        },
                        value: 'cancel',
                    },
                    {
                        label: gettextCatalog.getString('Ok'),
                        className: 'btn btn-primary',
                        callback: function () {
                            removeIgnoredMisspelling(misspelling);
                            ng2TrapFocusService.unPauseLast();
                        },
                        value: 'success',
                    },
                ],
            };
            ng2MonModalService.customDialog(params);
        }

        function removeIgnoredMisspelling (misspelling) {
            spellService.destroyIgnoredPageSpellingError(vm.activePage.id, misspelling.id).then(function () {
                getPage();
            }, angular.noop);
        }
    }
})();
