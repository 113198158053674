import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.qa').component('importantHighlights', {
        templateUrl: 'app/pages/qa/summary/important-highlights/important-highlights.html',
        controller: ImportantHighlightsController,
        controllerAs: 'vm',
        bindings: {
            progress: '<',
            data: '<',
            spellingErrors: '<',
            brokenLink: '<',
            brokenImage: '<',
        },
    });

    ImportantHighlightsController.$inject = [
        'ng2MonEventsService',
        'QAImportantHighlightsService',
        '$state',
        'ng2ActiveFeatureService',
        'ng2MonUIRouterTransitionService',
    ];

    function ImportantHighlightsController (
        ng2MonEventsService,
        QAImportantHighlightsService,
        $state,
        ng2ActiveFeatureService,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.openDialog = openDialog;
        vm.goTo = goTo;
        vm.showSpelling = ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck');
        vm.showBrokenLink = QAImportantHighlightsService.showBrokenLink;
        vm.showBrokenImage = QAImportantHighlightsService.showBrokenImage;

        function activate () {
            vm.brokenLinkText = QAImportantHighlightsService.getBrokenLinkText();
            vm.brokenImageText = QAImportantHighlightsService.getBrokenImageText();
            vm.brokenLinkType = QAImportantHighlightsService.getLinkSearchType();
        }

        function openDialog (target, size, attrs) {
            if (size.constructor === Object) {
                attrs = size;
                size = 'fw';
            }

            var params = {
                body: target,
                size: size,
                data: attrs,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function goTo (state) {
            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go(state),
            );
        }
    }
})();
