(function () {
    'use strict';
    angular.module('app.components').component('spellingClassification', {
        transclude: true,
        templateUrl: 'app/components/classification/classification.html',
        controller: TableContainerComponent,
        controllerAs: 'vm',
        bindings: {
            type: '<',
            word: '@',
        },
    });

    TableContainerComponent.$inject = [];
    /* @ngInject */
    function TableContainerComponent () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            if (vm.type === 'potential') {
                vm.classification = 'text-potential-misspelling';
            }
            if (vm.type === 'confirmed') {
                vm.classification = 'text-confirmed-misspelling';
            }
        }
    }
})();
