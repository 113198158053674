(function () {
    'use strict';

    angular.module('modules.data_privacy', [
        'modules.data_privacy.constants',
        'modules.data_privacy.components',
        'modules.data_privacy.services',
        'modules.data_privacy.forms',
        'modules.data_privacy.pages',
        'modules.data_privacy.dialogs',
    ]);
})();
