(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationTooManyPageLinks', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/too-many-page-links/too-many-page-links.html',
        controller: SeoTooManyPageLinksController,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTooManyPageLinksController.$inject = [];
    /* @ngInject */
    function SeoTooManyPageLinksController () {
        var vm = this;
        vm.count = count;

        function count (content) {
            if (!Array.isArray(content) && !isNaN(parseFloat(content)) && isFinite(content)) {
                return content;
            }

            return 0;
        }
    }
})();
