import { Domain } from '@monsido/ng2/modules/models/api/domain';

(function () {
    'use strict';

    angular.module('app.forms').component('formLiteDomain', {
        templateUrl: 'app/forms/lite-domain/lite-domain.html',
        controller: LiteDomainController,
        controllerAs: 'vm',
        bindings: {
            domain: '<?',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    LiteDomainController.$inject = [
        'monDialog',
        'UserRepo',
        'ng2DomainRepoService',
        '$q',
        '$timeout',
        'ng2DomainService',
        '$deepmerge',
        'activeFeatures',
        'Lodash',
        'ng2CoreDomainService',
        'moment',
    ];

    /* @ngInject */
    function LiteDomainController (
        monDialog,
        UserRepo,
        ng2DomainRepoService,
        $q,
        $timeout,
        ng2DomainService,
        $deepmerge,
        activeFeatures,
        Lodash,
        ng2CoreDomainService,
        moment,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.getDomain = getDomain;
        vm.getUsers = getUsers;

        function activate () {
            var promises = [];
            vm.isSaving = false;
            vm.progress = false;
            vm.users = [];
            vm.loading = true;
            vm.domain = new Domain(vm.domain) || {};
            vm.originalDomain = {};
            vm.showFilters = activeFeatures.table_filters;
            promises.push(getUsers());
            if (typeof vm.domain.id === 'number') {
                promises.push(getDomain());
            }
            vm.progress = $q
                .all(promises)
                .then(function (values) {
                    vm.users = values[0];
                    if (values[1] !== null && typeof values[1] === 'object') {
                        vm.domain = values[1];
                        vm.originalDomain = $deepmerge({}, vm.domain);
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });

            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.domainForm });
                }
            });
        }

        function getDomain () {
            return ng2DomainRepoService.get(vm.domain.id);
        }

        function getUsers () {
            return UserRepo.getAll({ page_size: 0 });
        }

        function submit () {
            var promise;
            var domain = $deepmerge({}, vm.domain);
            vm.isSaving = true;
            domain.scan.time = moment(vm.domain.scan.time)
                .subtract(moment().utcOffset(), 'minutes')
                .format('HH:mm:ss');
            domain.scan.login = ng2CoreDomainService.setupScanLogin(domain.scan.login);
            domain.domain_users = setDomainUsersParams(domain);
            if (Array.isArray(domain.labels)) {
                domain.labels = domain.labels.map(function (label) {
                    return label.id;
                });
            }

            if (domain.id) {
                promise = ng2DomainService.update(domain.id, domain);
            } else {
                promise = ng2DomainService.create(domain);
            }

            promise
                .then(function (response) {
                    if (vm.domainForm) {
                        vm.domainForm.$setPristine();
                    }
                    if (response) {
                        close();
                    }
                }, angular.noop)
                .finally(function () {
                    vm.isSaving = false;
                });
        }

        function setDomainUsersParams (domain) {
            return domain.domain_users.map(function (domainUser) {
                var index = Lodash.findIndex(vm.users, function (user) {
                    return user.id === domainUser.user_id;
                });
                if (index > -1) {
                    domainUser.send_report = false;
                }
                return domainUser;
            });
        }

        function close () {
            monDialog.close(vm.monDialogId, vm.domain);
        }
    }
})();
