import { Component, OnInit } from '@angular/core';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { Domain, DomainType } from '@monsido/modules/models/api/domain';
import { Report } from '@monsido/modules/models/api/report';
import { DomainRepoService } from 'app/services/api/domain-repo/domain-repo.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { ReportCenterService } from '@client/app/blocks/service/report-center/report-center.service';
import { MeService } from '@client/app/services/entities/me/me.service';

@Component({
    selector: 'mon-send-me-report-form',
    templateUrl: './send-me-report-form.component.html',
    styleUrls: ['./send-me-report-form.component.scss'],
})
export class SendMeReportFormComponent implements OnInit {
    domainType = DomainType;
    saving: boolean = false;
    search: string = '';
    domains: MonTableCollection<Domain> = [];
    report: Report | null = null;
    open: boolean = true;
    targetDomain: {id: number; type: DomainType} | null = null;
    loading: boolean = false;

    domainsPage = 1;
    domainsPageSize = 10;
    domainSearchTerm = '';

    constructor (
        private domainRepo: DomainRepoService,
        private meService: MeService,
        private reportCenterService: ReportCenterService,
        private activeDialog: ActiveDialog,
    ) {}

    async ngOnInit (): Promise<void> {
        await this.getDomains();
    }

    async onDomainSearch (searchTerm: string): Promise<void> {
        this.domainSearchTerm = searchTerm;
        this.domainsPage = 1;
        await this.getDomains();
    }

    async onDomainsPageChange (page: number): Promise<void> {
        this.domainsPage = page;
        await this.getDomains();
    }

    async onDomainsPageSizeChange (pageSize: number): Promise<void> {
        this.domainsPage = 1;
        this.domainsPageSize = pageSize;
        await this.getDomains();
    }

    private async getDomains (): Promise<void> {
        try {
            this.loading = true;
            const domainsCollection = await this.domainRepo.getAll({
                page_size: this.domainsPageSize,
                page: this.domainsPage,
                search: this.domainSearchTerm,
            });
            const { total, currentPage, perPage } = domainsCollection;

            if (Array.isArray(domainsCollection)) {
                if (this.report) {
                    this.domains = this.reportCenterService.filterDomainsByReport(this.report, domainsCollection);
                    this.domains.total = total;
                    this.domains.currentPage = currentPage;
                    this.domains.perPage = perPage;
                }
            }
        } finally {
            this.loading = false;
        }
    }

    save (): void {
        if (this.targetDomain) {
            this.saving = true;

            this.meService.createReporting({
                template_id: this.report?.template.id || -1,
                target_type: this.targetDomain.type,
                target_id: this.targetDomain.id,
            })
                .then(this.activeDialog.close)
                .finally(() => {
                    this.saving = false;
                });
        }
    }

}
