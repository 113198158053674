(function () {
    'use strict';

    angular.module('blocks.base').controller('BaseLabelsController', BasePagesController);

    BasePagesController.$inject = ['vm', 'ng2LabelRepo', 'pageService', 'activeFeatures', 'ng2SessionService'];
    /* @ngInject */

    /**
     * Contains shared function between components with Acquia Optimize pages
     *
     * @memberof blocks.base
     * @ngdoc controller
     * @author dal
     * @name BasePagesController
     * @param       {vm}                vm                      The component to extend
     * @param       {ng2LabelRepo}         ng2LabelRepo               The ng2LabelRepo
     * @param       {pageService}  pageService        The pageService
     * @param       {activeFeatures}            activeFeatures  List of active features
     * @param       {ng2SessionService}            ng2SessionService  User information service
     * @constructor
     */
    function BasePagesController (vm, ng2LabelRepo, pageService, activeFeatures, ng2SessionService) {
        vm.getLabels = getLabels;
        vm.updatePageLabels = updatePageLabels;
        vm.updateFilters = updateFilters;

        activate();

        function activate () {
            vm.labels = [];
            vm.showLabels = activeFeatures.labels;

            vm.isFiltersOpen = false;
            vm.selectedLabels = [];
            vm.showFilters = activeFeatures.table_filters;
            vm.filters = { labels: [] };
            vm.availableFilters = {
                labels: [],
            };

            vm.getLabels();
        }

        function getLabels () {
            var params = {
                page_size: 1000,
            };

            vm.labelsPromise = ng2LabelRepo.getLabels(params).then(function (data) {
                vm.labels = data || [];
                vm.availableFilters.labels = data || [];
            }, angular.noop);
        }

        function updatePageLabels (label) {
            var params = {};
            params.labels = label.labels.map(function (label) {
                return label.id;
            });

            pageService.update(label, params).then(angular.noop, angular.noop);
        }

        function updateFilters (changes) {
            if (changes.changes) {
                changes = changes.changes;
            }

            if (changes.labels) {
                vm.selectedLabels = changes.labels.map(function (label) {
                    return label.id;
                });
            } else {
                vm.selectedLabels = [];
            }
        }
    }
})();
