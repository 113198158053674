(function () {
    'use strict';

    angular.module('filters.monsido').filter('hasDomainAccess', hasDomainAccess);

    hasDomainAccess.$inject = ['ng2SessionService'];
    /* @ngInject */
    function hasDomainAccess (ng2SessionService) {
        return access;

        // //////////

        function access (domain) {
            if (!ng2SessionService.me || (angular.isObject(domain) && !domain.id)) {
                return false;
            }
            if (ng2SessionService.hasRole('customer_admin')) {
                return true;
            }

            var hasAccess = false;
            if (ng2SessionService.me && ng2SessionService.agreement && Array.isArray(ng2SessionService.agreement.domain_users)) {
                for (var i = 0; i < ng2SessionService.agreement.domain_users.length; i++) {
                    if (ng2SessionService.agreement.domain_users[i].domain_id === domain.id) {
                        if (ng2SessionService.agreement.domain_users[i].visible === true) {
                            hasAccess = true;
                        }
                    }
                }
            }
            return hasAccess;
        }
    }
})();
