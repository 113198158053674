(function () {
    'use strict';

    angular.module('app.forms', [
        'plugins.lodash',
        'core.templates',
        'core.constant',
        'app.components.colorPicker',
        'ui.bootstrap.tooltip',
        'ui.bootstrap.timepicker',
        'app.core',
        'dndLists',
        'ngTagsInput',
        'filters.labels',
        'modules.globalPolicy',
    ]);
})();
