(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name AccessibilityDoughnutChartService
     * @description
     * Create the necessary objects to shape the Accessibility doughnut chart
     */
    angular.module('blocks.service').factory('AccessibilityDoughnutChartService', AccessibilityDoughnutChartService);

    AccessibilityDoughnutChartService.$inject = ['$filter', 'gettextCatalog', 'DoughnutChart', 'complianceService','ng2LegendCursorService'];

    function AccessibilityDoughnutChartService ($filter, gettextCatalog, DoughnutChart, complianceService,ng2LegendCursorService) {
        var vm = this;

        /**
         * @memberOf AccessibilityDoughnutChartService
         * @description Initialize the AccessibilityDoughnutChartService factory
         */
        function init (summary, accessibilityChecks) {
            vm.accessibilityChecks = accessibilityChecks;
            vm.doughnutChartSettings = new DoughnutChart();
            vm.summary = summary;

            return {
                getOptions: getOptions,
                getData: getDataFromResultType,
                getLabels: getLabels,
                getColors: getColors,
                getPercentageFromResultType: getPercentageFromResultType,
            };
        }

        return init;

        /**
         * @memberOf AccessibilityDoughnutChartService
         * @description Return the options object of the accessibility doughnut
         * @return {Object}
         */
        function getOptions (resultType) {
            const score = getPercentageFromResultType(resultType, null) / 100;
            const percentage = $filter('monNumber')(score, '0.[00] %');

            vm.doughnutChartSettings.setTooltipCallbacks({
                label: function (tooltipItem, data) {
                    const pageCount = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return (
                        $filter('translateWithPlural')('Page', pageCount, 'Pages') +
                        ' ' +
                        $filter('numeraljs', '0,0')(pageCount)
                    );
                },
            });
            vm.doughnutChartSettings.setCenterText(
                percentage,
                gettextCatalog.getString('Accessibility'),
                gettextCatalog.getString('Compliance'),
            );
            vm.doughnutChartSettings.setTotalFontSize(170);
            vm.doughnutChartSettings.setSubFontSize(170);
            vm.doughnutChartSettings.setCutOutPercentage(80);
            vm.doughnutChartSettings.setTooltipSettings({ enabled: false });
            vm.doughnutChartSettings.setHoverSettings({ mode: null });
            vm.doughnutChartSettings.options = ng2LegendCursorService.setHoverState(vm.doughnutChartSettings.options);
            return vm.doughnutChartSettings.options;
        }

        /**
         * @memberOf AccessibilityDoughnutChartService
         * @description Return the labels of the accessibility doughnut
         * @return {Array}
         */
        function getLabels () {
            return [gettextCatalog.getString('Pages'), gettextCatalog.getString('Pages')];
        }

        /**
         * @memberOf AccessibilityDoughnutChartService
         * @description Return the colors of the accessibility doughnut
         * @return {Array}
         */
        function getColors () {
            return ['#4dc0bd', '#dcdcdc'];
        }

        /**
         * @memberOf AccessibilityDoughnutChartService
         * @param resultType
         * @param pageCountAttr
         * @returns {*}
         */
        function getDataFromResultType (resultType, pageCountAttr) {
            return complianceService.accessibilityCompliance(
                resultType,
                pageCountAttr,
                vm.accessibilityChecks,
                vm.summary,
            );
        }

        /**
         * @memberOf AccessibilityDoughnutChartService
         * @param resultType
         * @param pageCountAttr
         * @returns {*|number}
         */
        function getPercentageFromResultType (resultType, pageCountAttr) {
            return complianceService.accessibilityCompliancePercentageFromResultType(
                resultType,
                pageCountAttr,
                vm.accessibilityChecks,
                vm.summary,
            );
        }
    }
})();
