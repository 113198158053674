export const INFOTYPE_ICON = {
    TECHNICAL: ' fas fa-microchip',
    GENERAL_PERSONAL_DATA: ' fas fa-user-shield',
    BANKING_DATA: ' fas fa-university',
    OTHER: ' fas fa-cog',
    SENSITIVE_PERSONAL_DATA: ' fas fa-pray',
    HEALTHCARE: ' fas fa-briefcase-medical',
    NATIONAL_ID: ' fas fa-id-card',
    DRIVING_PERMIT: ' fas fa-car',
    TAXPAYER_ID: ' fas fa-credit-card',
    PRODUCT_IDENTIFIER: ' fas fa-box-alt',
    PERSONAL_TECHNICAL: ' fas fa-key',
    VEHICLE_IDENTIFIER: ' fas fa-car-bus',
};
