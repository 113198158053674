(function () {
    'use strict';

    angular.module('modules.tools').component('colorContrast', {
        templateUrl: 'app/modules/tools/color-contrast-tool/color-contrast.html',
        controller: ColorContrastController,
        controllerAs: 'vm',
    });

    ColorContrastController.$inject = ['ColorService'];

    function ColorContrastController (ColorService) {
        var vm = this;
        vm.$onInit = activate;
        vm.foregroundUpdate = foregroundUpdate;
        vm.foregroundUpdateRgb = foregroundUpdateRgb;
        vm.backgroundUpdate = backgroundUpdate;
        vm.backgroundUpdateRgb = backgroundUpdateRgb;

        function activate () {
            vm.foregroundColor = '#0000ff';
            vm.foregroundRgb = ColorService.hexToRgb(vm.foregroundColor);

            vm.backgroundColor = '#ffffff';
            vm.backgroundRgb = ColorService.hexToRgb(vm.backgroundColor);

            update();
        }

        function foregroundUpdate () {
            vm.foregroundRgb = ColorService.hexToRgb(vm.foregroundColor);
            update();
        }

        function foregroundUpdateRgb () {
            vm.foregroundColor = ColorService.rgbToHex(vm.foregroundRgb);
            update();
        }

        function backgroundUpdate () {
            vm.backgroundRgb = ColorService.hexToRgb(vm.backgroundColor);
            update();
        }

        function backgroundUpdateRgb () {
            vm.backgroundColor = ColorService.rgbToHex(vm.backgroundRgb);
            update();
        }

        function update () {
            vm.colorStyle = {
                color: vm.foregroundColor,
                'background-color': vm.backgroundColor,
            };

            vm.uiColorStyle = {
                color: vm.foregroundColor,
                'border-color': vm.foregroundColor,
                'background-color': vm.backgroundColor,
            };

            vm.ratio = ColorService.contrast(vm.foregroundRgb, vm.backgroundRgb);

            // check normal text
            vm.checkNormalAA = ColorService.ratioCheck(vm.ratio, 4.5);
            vm.checkNormalAAA = ColorService.ratioCheck(vm.ratio, 7);

            // check large text
            vm.checkLargeAA = ColorService.ratioCheck(vm.ratio, 3);
            vm.checkLargeAAA = ColorService.ratioCheck(vm.ratio, 7);

            // check ui elements
            vm.checkUiAA = ColorService.ratioCheck(vm.ratio, 3);
        }
    }
})();
