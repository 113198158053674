(function () {
    'use strict';
    angular.module('blocks.service').factory('complianceService', complianceService);

    complianceService.$inject = ['ng2SessionService', 'complianceAccessibilityService', 'complianceAccessibilityLegacyService', 'Lodash'];
    /* @ngInject*/
    function complianceService (ng2SessionService, complianceAccessibilityService, complianceAccessibilityLegacyService, Lodash) {
        return {
            qaHistoryCompliance: qaHistoryCompliance,
            accessibilityHistoryCompliance: accessibilityHistoryCompliance,
            seoHistoryCompliance: seoHistoryCompliance,
            policyHistoryCompliance: policyHistoryCompliance,
            accessibilityCompliance: accessibilityCompliance,
            accessibilityCompliancePercentageFromResultType: accessibilityCompliancePercentageFromResultType,
            seoCompliance: seoCompliance,
            seoCompliancePercentage: seoCompliancePercentage,
            policyCompliance: policyCompliance,
            policyCompliancePercentage: policyCompliancePercentage,
            hasLegacyCompliance: hasLegacyCompliance,
            hasLegacyComplianceOnChecks: hasLegacyComplianceOnChecks,
        };

        function qaHistoryCompliance (history) {
            if (hasLegacyCompliance()) {
                return [
                    history.page_count + history.documents_count - history.pages_with_notifications_count,
                    history.pages_with_notifications_count,
                ];
            } else {
                if (history.compliance_percentage && history.compliance_percentage.qa_pages) {
                    return [history.compliance_percentage.qa_pages, 100 - history.compliance_percentage.qa_pages];
                }

                return false;
            }
        }

        function accessibilityHistoryCompliance (history) {
            if (hasLegacyCompliance()) {
                return [history.page_count - history.pages_with_accessibility_errors_count, history.pages_with_accessibility_errors_count];
            } else {
                if (history.compliance_percentage && history.compliance_percentage.accessibility_pages) {
                    return [
                        history.compliance_percentage.accessibility_pages.error_and_warning_and_review,
                        100 - history.compliance_percentage.accessibility_pages.error_and_warning_and_review,
                    ];
                }

                return false;
            }
        }

        function seoHistoryCompliance (history) {
            if (hasLegacyCompliance()) {
                return [history.page_count - history.pages_with_seo_errors_count, history.pages_with_seo_errors_count];
            } else {
                if (history.compliance_percentage && history.compliance_percentage.seo_issues) {
                    return [history.compliance_percentage.seo_issues, 100 - history.compliance_percentage.seo_issues];
                }

                return false;
            }
        }

        function policyHistoryCompliance (history) {
            if (hasLegacyCompliance()) {
                return [
                    history.page_count + history.documents_count - history.pages_with_policy_matches_count || 0,
                    history.pages_with_policy_matches_count || 0,
                ];
            } else {
                if (history.compliance_percentage && history.compliance_percentage.policies) {
                    return [history.compliance_percentage.policies, 100 - history.compliance_percentage.policies];
                }

                return false;
            }
        }

        function accessibilityCompliance (resultType, pageCountAttr, accessibilityChecks, summary) {
            if (hasLegacyCompliance()) {
                return complianceAccessibilityLegacyService.getDataFromResultType(resultType, summary);
            }
            var compliance = complianceAccessibilityService.getPercentageFromResultType(
                resultType,
                pageCountAttr,
                accessibilityChecks,
                summary,
            );
            return [compliance, 100 - compliance];
        }

        function policyCompliance (summary, policies, contentWithErrorsCount, pagesCount, linksCount) {
            var percentage = policyCompliancePercentage(summary, policies, contentWithErrorsCount, pagesCount, linksCount);
            var contentCount = (summary.page_count || 0) + (summary.documents_count || 0);

            if (hasLegacyCompliance()) {
                // If errors for some reason, is higher than the actual existing amount of entities (pages and documents),
                // the error count, should be the same as total known entities
                if (contentCount < contentWithErrorsCount) {
                    contentWithErrorsCount = contentCount;
                }

                return [contentCount - contentWithErrorsCount, contentWithErrorsCount];
            } else {
                return [percentage, 100 - percentage];
            }
        }

        function policyCompliancePercentage (summary, policies, contentWithErrorsCount, pagesCount, linksCount) {
            var score;
            if (hasLegacyCompliance()) {
                var contentCount = (summary.page_count || 0) + (summary.documents_count || 0);
                score = ((contentCount - contentWithErrorsCount) / contentCount) * 100;
            } else {
                var contentWithErrors = policies.reduce(function (num, policy) {
                    if (policy.subject === 'link') {
                        return num + (parseInt(policy.matches_count) / linksCount) * 100;
                    } else {
                        return num + (parseInt(policy.matches_count) / pagesCount) * 100;
                    }
                }, 0);
                score = 100 - contentWithErrors / policies.length;
            }

            if (isNaN(score)) {
                return 0;
            }

            return score;
        }

        function accessibilityCompliancePercentageFromResultType (resultTypes, pageCountAttr, accessibilityChecks, summary) {
            var score;
            if (hasLegacyCompliance()) {
                score = 100 - complianceAccessibilityLegacyService.getPercentageFromResultType(resultTypes, summary);
            } else {
                score = complianceAccessibilityService.getPercentageFromResultType(
                    resultTypes,
                    pageCountAttr,
                    accessibilityChecks,
                    summary,
                );
            }

            if (isNaN(score)) {
                return 0;
            }

            return score;
        }

        function seoCompliance (summary, checkpoints, history) {
            var percentage = seoCompliancePercentage(summary, checkpoints, history);
            return [percentage, 100 - percentage];
        }

        function seoCompliancePercentage (summary, checkpoints, history) {
            // Check if the account is setup to use legacy compliance Calculations
            if (hasLegacyCompliance()) {
                var latestHistory = getLatestHistory(history);
                var totalErrors = summary.pages_with_seo_errors;
                var totalPages = 0;
                // summary page count, can no longer be used for legacy compliance
                if (latestHistory) {
                    totalPages = latestHistory.page_count;
                }
                return ((totalPages - totalErrors) / totalPages) * 100;
            }
            return summary.compliance_percentage;
        }

        function hasLegacyCompliance () {
            if (ng2SessionService.customer === null) {
                return false;
            }
            return Boolean(ng2SessionService.customer.settings.legacy_compliance);
        }

        function hasLegacyComplianceOnChecks () {
            if (ng2SessionService.customer === null) {
                return false;
            }
            return Boolean(ng2SessionService.customer.settings.legacy_check_compliance);
        }

        function getLatestHistory (history) {
            return Lodash.first(Lodash.orderBy(history, ['id'], ['desc']));
        }
    }
})();
