(function () {
    'use strict';

    angular.module('pages.seo').component('seoSummary', {
        templateUrl: 'app/pages/seo/summary/summary.html',
        controller: SeoSummaryController,
        controllerAs: 'vm',
    });

    SeoSummaryController.$inject = [
        'gettextCatalog',
        'ng2CrawlsRepoService',
        'API_CONSTANTS',
        '$filter',
        'ng2SeoRepoService',
        '$q',
        'SEOLineChartService',
        'ng2SessionService',
        'complianceService',
        'ng2PageRepoService',
        '$deepmerge',
        'ChangesHistoryService',
        'ng2ActiveFeatureService',
        'ng2ScoresService',
        'ng2DemoHasAccessService',
        'ng2PropertiesSumService',
    ];

    /* @ngInject */
    function SeoSummaryController (
        gettextCatalog,
        ng2CrawlsRepoService,
        API_CONSTANTS,
        $filter,
        ng2SeoRepoService,
        $q,
        SEOLineChartService,
        ng2SessionService,
        complianceService,
        ng2PageRepoService,
        $deepmerge,
        ChangesHistoryService,
        ng2ActiveFeatureService,
        ng2ScoresService,
        ng2DemoHasAccessService,
        ng2PropertiesSumService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.hasAllPageCounters = hasAllPageCounters;
        vm.getHistoryCenterParameters = getHistoryCenterParameters;

        function activate () {
            vm.showHistory =
                ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.loading = true;
            vm.lineChartLabels = [];
            vm.lineChartData = [];
            vm.lineChartSeries = [];
            vm.chartNotificationsList = [];
            vm.seoClassifications = API_CONSTANTS.SEO.CLASSIFICATION;
            vm.domain = ng2SessionService.domain;
            vm.totalPages = 0;
            vm.pageAlerts = 0;
            vm.pageWarnings = 0;
            vm.pageInfos = 0;
            vm.pageTechnicals = 0;
            vm.allowVerticalAverage = false;
            var promises = [
                getSummary(),
                getCrawls(),
                getSEOCheckpoints(),
                getPages({ seo_issue_alerts: true }),
                getPages({ seo_issue_warnings: true }),
                getPages({ seo_issue_infos: true }),
                getPages({ seo_issue_technicals: true }),
            ];

            vm.promise = $q
                .all(promises)
                .then(function (values) {
                    vm.totalPages = parseInt(values[0].pages_count);
                    vm.pageAlerts = parseInt(values[3].total);
                    vm.pageWarnings = parseInt(values[4].total);
                    vm.pageInfos = parseInt(values[5].total);
                    vm.pageTechnicals = parseInt(values[6].total);

                    setupChartNotifications(values[0], values[1]);
                    setupDoughnutChart(values[0], values[2], values[1]);
                    setupLineChart(values[1].reverse());
                    var isDemoAccount = ng2DemoHasAccessService.isDemoAccount();
                    if (isDemoAccount) {
                        vm.allowVerticalAverage = true;
                        setVerticalData(ng2ScoresService.getSEODemoData());
                    } else {
                        vm.allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
                        if (vm.allowVerticalAverage) {
                            ng2ScoresService.getAverageData('seo').then(setVerticalData);
                        }
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getSummary () {
            return ng2SeoRepoService.getSummary();
        }

        function getPages (seoParams) {
            var params = {
                page: 1,
                page_size: 1,
            };

            return ng2PageRepoService.getAll($deepmerge(seoParams, params));
        }

        function getSEOCheckpoints () {
            var params = {
                page_size: 0,
            };
            return ng2SeoRepoService.getAll(params);
        }

        function getCrawls () {
            return ng2CrawlsRepoService.getAll();
        }

        function setupDoughnutChart (summary, checkpoints, history) {
            var percentage = complianceService.seoCompliancePercentage(
                summary,
                checkpoints,
                history,
            );
            vm.doughnutData = complianceService.seoCompliance(
                summary,
                checkpoints,
                history,
            );
            vm.doughnutLabels = ['', ''];
            vm.doughnutColors = ['#4dc0bd', '#dcdcdc'];
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: $filter('monNumber')(
                        percentage / 100,
                        '0.[00] %',
                    ),
                    totalLabel: gettextCatalog.getString('SEO'),
                    subLabel: gettextCatalog.getString('Compliance'),
                    subFontSize: 170,
                    totalFontSize: 170,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return $filter(
                                'numeraljs',
                                '0,0',
                            )(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
                hover: {
                    mode: null,
                },
            };
        }

        function setupLineChart (crawls) {
            var lineChartService = new SEOLineChartService();
            vm.lineColors = lineChartService.getColors();
            vm.lineChartSeries = lineChartService.getSeries();
            vm.lineOptions = lineChartService.getOption();
            vm.lineOverride = lineChartService.getOverride();
            vm.lineChartLabels = lineChartService.getLabels(crawls);
            vm.lineChartData = lineChartService.getData(crawls);
        }

        function setupChartNotifications (summary, crawls) {
            summary = typeof summary === 'object' ? summary : {};
            var crawlAttrs = [
                'seo_errors_alerts_count',
                'seo_errors_warnings_count',
                'seo_errors_infos_count',
            ];
            var summaryIssues = ng2PropertiesSumService.sum(
                summary,
                [
                    'seo_errors_alerts_count',
                    'seo_errors_warnings_count',
                    'seo_errors_infos_count',
                ],
            );
            var historyIssues = ng2PropertiesSumService.sum(
                crawls[crawls.length - 1],
                crawlAttrs,
            );
            var historyPageIssues = ng2PropertiesSumService.sum(
                crawls[0],
                ['pages_with_seo_errors_count'],
            );
            var summaryPageErrors = ng2PropertiesSumService.sum(
                summary,
                ['pages_with_seo_errors'],
            );

            vm.chartCardsList = [
                {
                    title: gettextCatalog.getString('SEO opportunities found'),
                    tooltipTitle:
                        'Contains high, medium and low opportunities for all pages',
                    issues: summaryIssues,
                    change:
                        crawls.length > 1 ? summaryIssues - historyIssues : 0,
                    changePercentage: getPercentageChange(
                        summaryIssues,
                        historyIssues,
                    ),
                },
                {
                    title: gettextCatalog.getString(
                        'Pages with SEO opportunities',
                    ),
                    issues: summaryPageErrors,
                    change:
                        crawls.length > 1
                            ? summaryPageErrors - historyPageIssues
                            : 0,
                    changePercentage: getPercentageChange(
                        summaryPageErrors,
                        historyPageIssues,
                    ),
                },
            ];
        }

        function getPercentageChange (a, b) {
            var difference = Math.abs(a - b);
            var average = (a + b) / 2;

            if (difference >= average) {
                return 100;
            }
            return (difference / average) * 100;
        }

        function hasAllPageCounters () {
            return (
                isNumber(vm.totalPages) &&
                isNumber(vm.pageAlerts) &&
                isNumber(vm.pageWarnings) &&
                isNumber(vm.pageInfos) &&
                isNumber(vm.pageTechnicals)
            );
        }

        function isNumber (val) {
            return typeof val === 'number';
        }

        function getHistoryCenterParameters (module) {
            return ChangesHistoryService.getHistoryCenterParameters(module);
        }

        function setVerticalData (data) {
            vm.verticalData = {
                verticalAverage: data.score,
                averageTitle: ng2ScoresService.getDefaultTitle(),
                tooltipText:
                    ng2ScoresService.getDefaultTooltip() + ' ' + ng2ScoresService.getVerticalName(data.name),
            };
        }
    }
})();
