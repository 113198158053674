export const ROLES_ROLE_SECTIONS = {
    qa: 'qa',
    misspellings: 'qa_misspellings',
    accessibility: 'accessibility',
    seo: 'seo',
    statistics: 'statistics',
    heartbeat: 'heartbeat',
    policies: 'policies',
    pagecorrect: 'pagecorrect',
    inventory: 'inventory',
    dataprivacy: 'dataprivacy',
    page_performance: 'page_performance',
    advanced_analytics: 'advanced_analytics',
} as const;
