(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceLocationService
     * @description Service to get locations based on env
     */
    angular
        .module('modules.page-performance.page-details')
        .service('PagePerformanceLocationService', PagePerformanceLocationService);

    PagePerformanceLocationService.$inject = ['PAGE_PERFORMANCE_LOCATIONS', 'performance'];
    /* @ngInject*/
    function PagePerformanceLocationService (PAGE_PERFORMANCE_LOCATIONS, performance) {
        return {
            getLocations: getLocations,
        };

        /**
         * @memberOf PagePerformanceLocationService
         * @desc Get services on based on env
         */
        function getLocations () {
            return Object.keys(PAGE_PERFORMANCE_LOCATIONS)
                .filter(function (x) {
                    return performance.locations.indexOf(x) >= 0;
                })
                .map(function (x) {
                    return {
                        city: PAGE_PERFORMANCE_LOCATIONS[x].city,
                        country: PAGE_PERFORMANCE_LOCATIONS[x].country,
                        country_code: PAGE_PERFORMANCE_LOCATIONS[x].country_code,
                        key: x,
                    };
                });
        }
    }
})();
