(function () {
    'use strict';

    angular.module('pages.admin', [
        'pages.admin.domain',
        'pages.admin.user',
        'pages.admin.customer_info',
        // "pages.admin.account_settings",
        'pages.admin.auditlog',
        'pages.admin.source-code-excludes',
        'pages.admin.labels',
        'pages.admin.shortcuts',
        'pages.admin.api-users',
    ]);
})();
