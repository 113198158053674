(function () {
    'use strict';

    angular.module('filters.monsido').filter('uptimeIncidientTime', UptimeIncidientTimeContorller);

    UptimeIncidientTimeContorller.$inject = ['$filter', 'moment'];
    function UptimeIncidientTimeContorller ($filter, moment) {
        return result;

        // //////////

        function result (date) {
            const localeData = moment.localeData();
            const formats = {
                sameElse: localeData.longDateFormat('L') + ' ' + localeData.longDateFormat('LT'),
                // sameElse: "DD/MM/YYYY hh:mm a"
            };

            return $filter('amCalendar')(date, moment(), formats);
        }
    }
})();
