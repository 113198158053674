(function () {
    'use strict';
    angular.module('filters.monsido').filter('addUrlProtocol', AddUrlProtocol);

    AddUrlProtocol.$inject = [];
    /* @ngInject */

    /**
     * Add a protocol (default is HTTP) to an URL if it's missing
     *
     * @memberof filters.monsido
     * @ngdoc filter
     * @name addUrlProtocol
     */
    function AddUrlProtocol () {
        return handle;

        // //////////

        function handle (url, protocol) {
            url = url || '';
            url = url.trim();
            protocol = protocol || 'http';

            if (!url.match(/:\/\//)) {
                url = protocol + '://' + url;
            }

            return url;
        }
    }
})();
