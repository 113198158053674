
<div class="panel-header-wrapper">
    <mon-panel-header
            faIcon="{{['REPORT_CENTER', 'SCHEDULED'] | monIcons}} text-link"
            pHeader="{{ headerText }}"
            panelLeftClass="col-30"
            panelRightClass="col-10 inner-none"
            hideHr="true">
    </mon-panel-header>
    <button class="btn btn-no-style text-link py-3"
          *ngIf="reports.length > 3"
          (click)="showMore()" translate>
        Show more
    </button>
</div>
<div class="table-wrapper">
    <table class="table" *ngIf="reports && reports.length > 0">
        <caption class="sr-only">{{ headerText }}</caption>
        <tbody>
            <ng-container *ngFor="let report of enhancedReports; let i = index">
                <tr *ngIf="i < 3">
                    <td class="col-8 text-v-middle pl-4" attr.aria-label="{{'Image' | translate}}">
                        <img src="/images/report-center/report-icon1.jpg" alt="{{ 'Report' | translate }}" class="report-img">
                    </td>
                    <td class="col-34 text-v-middle">
                        <p class="font-size-1-3 mb-0 mt-1 text-link text-v-middle">
                            {{report.template.title}}
                        </p>
                        <div class="text-grey" >
                            <span class="inline-block text-secondary-4 mb-1">{{report.template.name | translateReport }}</span><br/>
                            <ng-container *ngFor="let target of report.targets; let j = index">
                                <span class="outer-r-smurf target-title" *ngIf="j < maxTargets">
                                    {{target.title}}
                                    <strong *ngIf="j < (maxTargets - 1) && j < report.targets.length -1">&#8226;</strong>
                                </span>
                            </ng-container>
                            <span *ngIf="report.targets.length > maxTargets">
                                + {{ '[[targets]] more' | translate:undefined:{ targets: report.targets.length - maxTargets} }}
                            </span>
                            <div *ngIf="admin" class="mt-1 mb-2">
                                <mon-show-more-avatars [users]="report.users!"></mon-show-more-avatars>
                            </div>
                        </div>
                    </td>
                    <td class="col-6 text-v-middle text-right">
                        <button class="btn-no-style btn to-click" (click)="editReport(report)">
                            <mon-icon size="lg" monIconDirect="faCog" class="text-link"></mon-icon>
                            <span class="sr-only" translate>Edit Report</span>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div *ngIf="!reports || reports.length == 0">
        <p class="text-grey report-center-suggestion py-6 text-center" translate>
            Schedule supplemental reports to be delivered with each scan
        </p>
    </div>
</div>
