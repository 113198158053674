(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogRuleStandard', {
        templateUrl: 'app/forms/global-policy/dialogs/rule/blocks/standard/standard.html',
        controller: StandardController,
        controllerAs: 'vm',
        bindings: {
            cRule: '=',
            monDialogId: '<',
            policyRuleForm: '=',
        },
    });

    StandardController.$inject = ['PolicyFormHelper', 'gettextCatalog', 'monDialog', 'ng2SessionService'];
    /* @ngInject */
    function StandardController (PolicyFormHelper, gettextCatalog, monDialog, ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.save = save;
        vm.chooseType = chooseOperator;
        vm.selectAllSubEntries = selectAllSubEntries;
        vm.checkSelectedSubEntries = checkSelectedSubEntries;
        vm.getOperatorHelpBlock = getOperatorHelpBlock;
        vm.isSelectorValid = isSelectorValid;
        vm.addFilterType = addFilterType;
        vm.removeFilterType = removeFilterType;
        vm.isFormValid = isFormValid;

        function activate () {
            vm.isSudo = ng2SessionService.isSudo;
            vm.areAllSubEntriesSelected = false;

            if (vm.cRule.type === 'page_html') {
                setupCssFilterTypeOptions();
                vm.cssFilters = [];
                if (vm.cRule.css_filters) {
                    if (Array.isArray(vm.cRule.css_filters.limit)) {
                        vm.cRule.css_filters.limit.forEach(function (element) {
                            vm.cssFilters.push({
                                name: 'limit',
                                value: element,
                            });
                        });
                    }

                    if (Array.isArray(vm.cRule.css_filters.exclude)) {
                        vm.cRule.css_filters.exclude.forEach(function (element) {
                            vm.cssFilters.push({
                                name: 'exclude',
                                value: element,
                            });
                        });
                    }
                }
                if (vm.cssFilters.length === 0) {
                    addFilterType();
                }
            }

            PolicyFormHelper.setRuleType(vm.cRule.type);
            setupInverseOptions();
            setupTypeOptions();
            setupCaseSensitiveOptions();
            setupRuleSubEntries();
            setupMultilineOptions();
            if (!vm.cRule.name) {
                chooseOperator(vm.typeOptions.length > 0 ? vm.typeOptions[0] : {});
            } else {
                chooseOperator(
                    vm.typeOptions.find(function (type) {
                        return type.type === vm.cRule.operator;
                    }),
                );
            }
        }

        function isSelectorValid (value) {
            try {
                document.querySelector(value);
            } catch (error) {
                return false;
            }
            return true;
        }

        function isFormValid () {
            if (!vm.policyRuleForm.$valid) {
                return false;
            }

            if (vm.selectedOption.type === 'css_selector' && vm.cRule.query && !vm.isSelectorValid(vm.cRule.query)) {
                return false;
            }

            if (vm.cRule.type === 'page_html') {
                for (var i = 0; i < vm.cssFilters.length; i += 1) {
                    if (!vm.isSelectorValid(vm.cssFilters[i].value)) {
                        return false;
                    }
                }
            }

            return true;
        }

        function setupCssFilterTypeOptions () {
            vm.filterTypeOptions = [
                {
                    value: 'limit',
                    name: gettextCatalog.getString('Limit search'),
                },
                {
                    value: 'exclude',
                    name: gettextCatalog.getString('Exclude'),
                },
            ];
        }

        function addFilterType () {
            vm.cssFilters.push({ name: 'limit' });
        }

        function removeFilterType (index) {
            vm.cssFilters.splice(index, 1);
        }

        function setupInverseOptions () {
            vm.inverseOptions = [
                {
                    name: gettextCatalog.getString('Containing'),
                    value: false,
                },
                {
                    name: gettextCatalog.getString('Not containing'),
                    value: true,
                },
            ];
        }

        function setupCaseSensitiveOptions () {
            vm.caseSensitiveOptions = [
                {
                    name: gettextCatalog.getString('Not case sensitive'),
                    value: false,
                },
                {
                    name: gettextCatalog.getString('Case sensitive'),
                    value: true,
                },
            ];
        }

        function setupMultilineOptions () {
            vm.multilineOptions = [
                {
                    name: gettextCatalog.getString('Single line'),
                    value: false,
                },
                {
                    name: gettextCatalog.getString('Multiline'),
                    value: true,
                },
            ];
        }

        function setupTypeOptions () {
            vm.typeOptions = PolicyFormHelper.getOptionsByRuleType();
            vm.dropdownOptions = PolicyFormHelper.getMenuDropdownOptionsByRuleType(vm.chooseType);
        }

        function setupRuleSubEntries () {
            vm.subEntry = PolicyFormHelper.getRuleSubEntries();
            checkSelectedSubEntries();
        }

        function chooseOperator (option) {
            vm.selectedOption = option;
            vm.cRule.operator = option.type;
            if (vm.cRule.operator === 'conforms_with') {
                vm.cRule.inverse = false;
            }
            if (vm.cRule.type === 'page_html') {
                if (!vm.cRule.css_filters) {
                    vm.cRule.css_filters = {
                        limit: [],
                        exclude: [],
                    };
                }
            }
        }

        function selectAllSubEntries () {
            if (vm.cRule.sub_entry.length === vm.subEntry.options.length) {
                vm.cRule.sub_entry = [];
                vm.areAllSubEntriesSelected = false;
            } else {
                vm.cRule.sub_entry = vm.subEntry.options.map(function (entry) {
                    return entry.value;
                });
                vm.areAllSubEntriesSelected = true;
            }
        }

        function checkSelectedSubEntries () {
            vm.areAllSubEntriesSelected = vm.subEntry.options.length > 0 && vm.subEntry.options.length === vm.cRule.sub_entry.length;
        }

        function getOperatorHelpBlock () {
            return PolicyFormHelper.getSubjectTypeHelpBlock(vm.cRule.operator);
        }

        function save () {
            var newRule = angular.copy(vm.cRule);
            if (newRule.operator !== 'regex') {
                newRule.multiline = false;
            }
            if (vm.cRule.type === 'page_html') {
                newRule.css_filters.limit = [];
                newRule.css_filters.exclude = [];

                vm.cssFilters.forEach(function (element) {
                    if (element.name === 'limit' && element.value !== undefined) {
                        newRule.css_filters.limit.push(element.value);
                    } else if (element.value !== undefined) {
                        newRule.css_filters.exclude.push(element.value);
                    }
                });
            }

            if (angular.isArray(newRule.sub_entry)) {
                newRule.sub_entry = newRule.sub_entry.join(',');
            }
            if (vm.policyRuleForm) {
                vm.policyRuleForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, newRule);
        }
    }
})();
