(function () {
    'use strict';
    angular.module('modules.accessibility').component('accDocClarityRemediationBtn', {
        templateUrl: 'app/modules/accessibility/document/clarity/remediation/btn.html',
        controller: AccessibilityDocumentClarityRemediationBtnController,
        controllerAs: 'vm',
        bindings: {
            pdf: '<',
            afterResolve: '&?',
            afterError: '&?',
        },
    });

    AccessibilityDocumentClarityRemediationBtnController.$inject = [
        'ng2SessionService',
        'AccClarityRemediationService',
        'ng2MonEventsService',
    ];

    /* @ngInject */
    function AccessibilityDocumentClarityRemediationBtnController (
        ng2SessionService,
        AccClarityRemediationService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.hasAccess = hasAccess;
        vm.confirmation = confirmation;

        function hasAccess () {
            return Boolean(ng2SessionService.customer && ng2SessionService.customer.plan_traits.clarity_pdf_remediation);
        }

        function confirmation () {
            if (vm.pdf.clarity_remediated) {
                return;
            }
            ng2MonEventsService.run('remediationBtnClick', { url: vm.pdf.url, title: vm.pdf.title });
            AccClarityRemediationService.createRemediationDialog(()=>{
                AccClarityRemediationService.createRemediation(vm.pdf).then(
                    function (data) {
                        ng2MonEventsService.run('actionTrigger', { action: 'accessibility-remediation-confirm' });
                        if (typeof vm.afterResolve === 'function') {
                            vm.afterResolve(data);
                        }
                    },
                    function (resp) {
                        if (typeof vm.afterError === 'function') {
                            vm.afterError(resp);
                        }
                    },
                );
            });
        }
    }
})();
