(function () {
    'use strict';

    angular.module('filters.monsido').filter('isBeforeDate', IsBeforeDateFilter);

    IsBeforeDateFilter.$inject = ['ng2DateTimeService'];

    /* @ngInject */
    function IsBeforeDateFilter (ng2DateTimeService) {
        return isBeforeDate;

        // //////////

        function isBeforeDate (start, end) {
            ng2DateTimeService.isAfter(start, end);
        }
    }
})();
