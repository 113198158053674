(function () {
    'use strict';

    angular.module('modules.extension').component('extensionGuidePanel', {
        templateUrl: 'app/modules/extension/components/extension-guide-panel/extension-guide-panel.html',
        controller: ExtensionGuidePanelController,
        controllerAs: 'vm',
    });

    ExtensionGuidePanelController.$inject = ['gettextCatalog', 'ng2ExtensionService'];
    /* @ngInject */
    function ExtensionGuidePanelController (gettextCatalog, ng2ExtensionService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.currentExtension = ng2ExtensionService.getCurrentExtensionInfo();
        }
    }
})();
