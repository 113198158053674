export class PageFixModelHelper {
    static getSelectorValue (target: string, attrs?: string[]): string {
        let res2: string[] | null = null;
        let query: string;
        let tempQ: string = '';
        let i;
        if (typeof attrs !== 'undefined' && !Array.isArray(attrs)) {
            attrs = [attrs];
        }
        const res1 = target.match(/<([\w-]+)(.[^>]*)>/);
        if (!res1) {
            return target;
        }
        if (res1.length > 2) {
            res2 = res1[2].match(/([\w-]*=(["'])(?:(?!\2).)*?\2)/g);
        }
        query = res1[1];
        if (res2 !== null) {
            for (i = 0; i < res2.length; ++i) {
                if (this.isInAttrs(res2[i], attrs)) {
                    tempQ += '[' + res2[i] + ']';
                }
            }
        }
        if (tempQ) {
            query += tempQ;
        }
        return query;
    }

    private static isInAttrs (selector: string, attrs?: string[]): boolean {
        if (!attrs) {
            return true;
        }
        for (const attr of attrs) {
            if (selector.startsWith(attr)) {
                return true;
            }
        }
        return false;
    }
}
