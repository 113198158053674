import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceProfileObsService
     * @description Page Performance Profile Observable Service
     */
    angular
        .module('modules.page-performance')
        .service('PagePerformanceProfileObsService', PagePerformanceProfileObsService);

    PagePerformanceProfileObsService.$inject = ['$deepmerge', 'Lodash', 'rx', 'PagePerformanceProfile', '$interval'];

    /* @ngInject*/
    function PagePerformanceProfileObsService ($deepmerge, Lodash, rx, PagePerformanceProfile, $interval) {
        var intervalKey = null;
        var service = {
            profiles: null,
            profilesObs: new rx.BehaviorSubject([]),
            disposeProfiles: disposeProfiles,
            startLiveReload: startLiveReload,
            updateProfile: updateProfile,
            addProfile: addProfile,
            removeProfile: removeProfile,
        };

        return service;

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Start live reload of the Profiles endpoint
         */
        function startLiveReload () {
            disposeProfiles();
            setupWatchers();
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Reset the Profiles collection back to null
         */
        function disposeProfiles () {
            $interval.cancel(intervalKey);
            intervalKey = null;
            setProfiles([]);
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Add Profile to the profiles stack
         */
        function addProfile (profile) {
            if (!isRunning() || !Array.isArray(service.profiles)) {
                return profile;
            }
            service.profiles.push(profile);
            setProfiles(service.profiles);
            return profile;
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Removes a Profile from the Profiles collection
         */
        function removeProfile (profile) {
            if (!isRunning() || !Array.isArray(service.profiles)) {
                return;
            }
            var index = Lodash.findIndex(service.profiles, { id: profile.id });
            if (index > -1) {
                service.profiles.splice(index, 1);
                setProfiles(service.profiles);
            }
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Updates an already existing Profile
         */
        function updateProfile (profile) {
            if (!isRunning() || !Array.isArray(service.profiles)) {
                return profile;
            }
            var index = Lodash.findIndex(service.profiles, { id: profile.id });
            if (index > -1) {
                service.profiles[index] = $deepmerge(service.profiles[index], profile);
                setProfiles(service.profiles);
            }
            return profile;
        }

        // PROTECTED

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Setup live reload Profiles
         */
        function setupWatchers () {
            // Do not attempt to overwrite a running interval task
            if (isRunning()) {
                return;
            }

            startInterval();
            getProfiles().then(setProfiles, angular.noop);
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Set the new Profiles collection
         * @param profiles - Profiles Collection
         */
        function setProfiles (profiles) {
            service.profiles = profiles;
            service.profilesObs.onNext(profiles);
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Call the Profiles Endpoint
         */
        function getProfiles () {
            var params = {
                page: 1,
                page_size: 0,
            };
            return PagePerformanceProfile.getAll(params, contextTokens.NO_GLOBAL);
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Setup interval recursion
         */
        function startInterval () {
            intervalKey = $interval(function () {
                getProfiles().then(setProfiles, angular.noop);
            }, 9000000); // 15 minutes
        }

        /**
         * @memberOf PagePerformanceProfileObsService
         * @desc Check if an interval recursion is running
         */
        function isRunning () {
            return intervalKey !== null;
        }
    }
})();
