(function () {
    'use strict';

    angular.module('filters.monsido').filter('responsetimePercentage', responsetime);

    responsetime.$inject = ['RESPONSE_TIME'];
    /* @ngInject */
    function responsetime (RESPONSE_TIME) {
        return responsetime;

        // //////////

        function responsetime (responsetime) {
            if (parseFloat(responsetime) >= RESPONSE_TIME.PERCENTAGE.OK.FROM) {
                return RESPONSE_TIME.PERCENTAGE.OK.COLORCLASS;
            }

            if (parseFloat(responsetime) >= RESPONSE_TIME.PERCENTAGE.WARNING.FROM) {
                return RESPONSE_TIME.PERCENTAGE.WARNING.COLORCLASS;
            }
            return RESPONSE_TIME.PERCENTAGE.ERROR.COLORCLASS;
        }
    }
})();
