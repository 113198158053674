(function () {
    'use strict';

    angular.module('directives.monsido').directive('autoFocus', AutoFocus);

    AutoFocus.$inject = ['$timeout'];

    /* @ngInject */
    function AutoFocus ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                attrs.$observe('autoFocus', function (val) {
                    if (val === 'focus') {
                        $timeout(function () {
                            element[0].focus();
                        });
                    }
                });
            },
        };
    }
})();
