import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RollbarErrorHandlerService } from 'ng2/external/rollbar/rollbar-error-handler.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class RollbarErrorInterceptor implements HttpInterceptor {
    constructor (private rollbar: RollbarErrorHandlerService) { }
    intercept (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 502:
                        this.rollbar.critical('Got a 502', error);
                        break;
                }
                return throwError(error);
            }),
        );
    }
}
