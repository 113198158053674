(function () {
    'use strict';

    angular.module('app.components').component('quickHelpComplianceStatus', {
        templateUrl: 'app/components/quick-help-compliance-status/quick-help-compliance-status.html',
        controller: QuickHelpComplianceStatusController,
        controllerAs: 'vm',
        bindings: {
            type: '@',
            totalPagesCount: '<',
            totalPageIssues: '<',
            data: '<',
        },
    });

    QuickHelpComplianceStatusController.$inject = ['$filter', 'gettextCatalog'];
    /* @ngInject */
    function QuickHelpComplianceStatusController ($filter, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPagesDone = getPagesDone;
        vm.getCompliancePercentage = getCompliancePercentage;

        function activate () {
            vm.title =
                vm.type === 'policy' ? gettextCatalog.getString('Policy note') : gettextCatalog.getString('Quick help');
        }

        function getPagesDone () {
            if (isNaN(vm.totalPagesCount) || isNaN(vm.totalPageIssues) || vm.totalPagesCount < vm.totalPageIssues) {
                return 0;
            }
            return vm.totalPagesCount - vm.totalPageIssues;
        }

        function getCompliancePercentage () {
            var pagesInCompliance = getPagesDone();
            var compliance = pagesInCompliance / vm.totalPagesCount;
            if (compliance > 0.99 && pagesInCompliance < vm.totalPagesCount) {
                compliance = 0.99;
            } else if (compliance < 0.1 && pagesInCompliance) {
                compliance = 0.01;
            }
            return compliance;
        }
    }
})();
