(function () {
    "use strict";

    angular.module("modules.translations", [
        "angularMoment",
        "gettext",
        "core.constant",
        "modules.translations.services",
        "modules.translations.run",
    ]);
})();
