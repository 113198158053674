(function () {
    'use strict';

    angular.module('app.forms').component('formDomainGroupsEdit', {
        templateUrl: 'app/forms/domain/steps/domain_groups/_form.html',
        controller: DomainGroupsEditController,
        controllerAs: 'vm',
        bindings: {
            domainGroup: '=',
            domain: '<',
            users: '<',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    DomainGroupsEditController.$inject = [
        'ng2DomainGroupRepoService',
        'gettextCatalog',
        'monDialog',
        'UserRepo',
        '$timeout',
        'ng2MonEventsService',
        'domainGroupService',
    ];

    /* @ngInject */
    function DomainGroupsEditController (
        ng2DomainGroupRepoService,
        gettextCatalog,
        monDialog,
        UserRepo,
        $timeout,
        ng2MonEventsService,
        domainGroupService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;
        vm.save = save;

        function activate () {
            vm.saving = false;
            vm.title = gettextCatalog.getString('Edit: ') + vm.domainGroup.title;
            vm.users = [];

            ng2DomainGroupRepoService.get(vm.domain.id, vm.domainGroup.id).then(function (data) {
                vm.domainGroup = data;
            }, angular.noop);

            UserRepo.getAll({ page_size: 0 }).then(function (users) {
                vm.users = users;
            }, angular.noop);

            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.groupForm });
                }
            });
        }

        function close (data) {
            if (vm.groupForm) {
                vm.groupForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, data || vm.domainGroup);
        }

        function save () {
            vm.saving = true;
            vm.domainGroup.rules = domainGroupService.cleanupRules(vm.domainGroup.rules);
            domainGroupService
                .update(vm.domain, vm.domainGroup)
                .then(function (data) {
                    close(data);
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }
    }
})();
