(function () {
    'use strict';

    angular.module('app.forms').component('formUserBasic', {
        templateUrl: 'app/forms/user/steps/basic/basic.html',
        controller: UserController,
        controllerAs: 'vm',
        bindings: {
            user: '=',
        },
    });

    UserController.$inject = ['ng2UserLocaleService'];
    /* @ngInject */
    function UserController (ng2UserLocaleService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            // This is not the correct place for langauges. But we only have two languages so it's a easy fix!
            vm.localeOptions = ng2UserLocaleService.getLocaleOptions();
            vm.user.locale = vm.user.locale || 'en';
        }
    }
})();
