(function () {
    'use strict';

    angular.module('pages.admin.auditlog').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.admin.auditlog',
                config: {
                    url: '/log',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Audit Log') },
                },
            },
            {
                state: 'base.customer.admin.auditlog.index',
                config: {
                    url: '?page&search&page_size&module&domain_id&user_id&from&to',
                    template: '<admin-auditlog></admin-auditlog>',
                    data: {},
                },
            },
        ];
    }
})();
