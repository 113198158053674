(function () {
    'use strict';
    angular.module('modules.accessibility').component('fastTrackDialogsPages', {
        templateUrl: 'app/modules/accessibility/dialogs/fast-track-pages/fast-track-pages.html',
        controller: FastTrackDialogsPagesController,
        controllerAs: 'vm',
        bindings: {
            accessibilitySourceCodeId: '<',
            check: '<',
        },
    });

    FastTrackDialogsPagesController.$inject = ['AccessibilityFastTrackRepo', '$controller', 'ng2ActiveFeatureService'];

    function FastTrackDialogsPagesController (AccessibilityFastTrackRepo, $controller, ng2ActiveFeatureService) {
        var vm = this;

        vm.$onInit = activate;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.search = '';
            vm.currentSort = { by: 'hits', direction: 'desc' };
            vm.tabs = [];
            getPage();
            vm.getLabels();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            vm.promise = AccessibilityFastTrackRepo.getAllPages(vm.accessibilitySourceCodeId, vm.check.id, params).then(
                function (data) {
                    vm.pages = data;
                },
                angular.noop,
            );
        }
    }
})();
