import { CHART_COLORS_CONSTANT } from 'app/constants/chart-colors';

export const DEFAULT_CHART_SETTINGS = {
    CHART: {
        HEIGHT: 340,
        CUTOUT_PERCENTAGE: 70,
        COLORS: CHART_COLORS_CONSTANT,
    },
    TOOLTIP_SETTINGS: {
        intersect: false,
        mode: 'x-axis',
        callbacks: {
            label: (tooltipItem, data): string => {
                const result = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
                const nf = new Intl.NumberFormat('en-US');
                const formattedNumber = nf.format(result);
                return data.datasets[tooltipItem.datasetIndex].label + ': ' + formattedNumber;
            },
            title: (tooltipItem): string => {
                const date = new Date(tooltipItem[0].xLabel);
                const dateOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
                return date.toLocaleDateString('en-US', dateOptions);
            },
        },
    },
    LEGEND_SETTINGS_OVERRIDE: {
        display: true,
        position: 'bottom',
        labels: {
            fontSize: 11,
            boxWidth: 10,
        },
    },
    SCALE_SETTINGS: {
        yAxes: [
            {
                id: 'y-axis-0',
                position: 'left',
                type: 'linear',
                ticks: {
                    fontSize: 11,
                    min: 0,
                    beginAtZero: true,
                    suggestedMax: 5,
                    maxTicksLimit: 5,
                    callback: (value: number): string => { 
                        const nf = new Intl.NumberFormat('en-US');
                        return nf.format(value);
                    },
                },
                gridLines: {
                    drawBorder: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: '',
                    fontSize: 11,
                },
            },
            {
                id: 'y-axis-1',
                type: 'linear',
                position: 'right',
                ticks: {
                    fontSize: 11,
                    suggestedMax: 5,
                    maxTicksLimit: 5,
                    min: 0,
                    beginAtZero: true,
                    callback: (value: number): string => {
                        const nf = new Intl.NumberFormat('en-US');
                        return nf.format(value);
                    },
                },
                gridLines: {
                    drawBorder: false,
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: '',
                    fontSize: 11,
                },
            },
        ],
        xAxes: [
            {
                id: 'x-axis-0',
                type: 'time',
                gridLines: {
                    drawBorder: false,
                    display: false,
                    offsetGridLines: true,
                },
                ticks: {
                    fontSize: 11,
                    maxTicksLimit: 30,
                },
                time: {
                    minUnit: 'day',
                    displayFormats: {
                    // Since the scans always only appear once or twice a week, there's no need for any other date formats to be displayed
                        millisecond: 'MMM D',
                        second: 'MMM D',
                        minute: 'MMM D',
                        hour: 'MMM D',
                        day: 'MMM D',
                        week: 'MMM D',
                        month: 'MMM D',
                        quarter: 'MMM D',
                        year: 'MMM D',
                    },
                },
            },
        ],
    },
    OVERRIDES: {
        y0: {
            yAxisID: 'y-axis-0',
            xAxisID: 'x-axis-0',
            type: 'line',
            fill: false,
            lineTension: 0,
            radius: 0,
            borderWidth: 1,
            pointRadius: 3,
            pointHitRadius: 34,
        },
        y1: {
            yAxisID: 'y-axis-1',
            xAxisID: 'x-axis-0',
            type: 'line',
            fill: true,
            lineTension: 0,
            radius: 0,
            borderWidth: 1,
            borderDash: [5, 5],
            pointRadius: 3,
            pointHitRadius: 34,
        },
    },
};
