(function () {
    'use strict';

    angular.module('pages.domain').component('pageDetailsDashboardChartsStatistics', {
        templateUrl: 'app/components/pagedetails/details/sections/dashboard/charts/statistics/statistics.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            callback: '&',
        },
    });

    DashboardController.$inject = ['pageService', '$q', '$filter', 'gettextCatalog', '$deepmerge', 'ng2DateTimeService'];
    /* @ngInject */
    function DashboardController (pageService, $q, $filter, gettextCatalog, $deepmerge, ng2DateTimeService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.date = {
                endDate: ng2DateTimeService.endOf(ng2DateTimeService.substractDuration(undefined, 1, 'day'), 'day'),
                startDate: ng2DateTimeService.substractDuration(undefined, 7, 'day'),
            };
            setupLineChart();
        }

        function setupLineChart () {
            var params = {
                from: ng2DateTimeService.format(vm.date.startDate, 'YYYY-MM-DD'),
                to: ng2DateTimeService.format(vm.date.endDate, 'YYYY-MM-DD'),
            };
            var promises = [
                pageService.getStatVisitors(vm.page.id, $deepmerge(params, { type: 'referring' })),
                pageService.getStatVisitors(vm.page.id, $deepmerge(params, { type: 'direct' })),
                pageService.getStatVisitors(vm.page.id, $deepmerge(params, { type: 'paid' })),
                pageService.getStatVisitors(vm.page.id, $deepmerge(params, { type: 'organic' })),
                pageService.getStatVisitors(vm.page.id, $deepmerge(params, { type: 'social' })),
            ];
            vm.loading = true;
            vm.lineChartSeries = [gettextCatalog.getString('Visits')];
            vm.lineChartData = [[]];
            vm.lineChartColors = ['#50a6fb'];
            vm.lineChartLabels = [];
            vm.lineChartOverride = [
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderColor: '#50a6fb',
                },
            ];

            vm.lineChartOptions = {
                tooltips: {
                    mode: 'label',
                    intersect: false,
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        },
                        label: function (tooltipItem, data) {
                            return (
                                ' ' +
                                gettextCatalog.getString('Visits') +
                                ': ' +
                                $filter('numeraljs', '0,0')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                            );
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value, index) {
                                    if (vm.type === 'avgVisitTime') {
                                        return $filter('numeraljs')(value, '00:00:00');
                                    } else if (vm.type === 'bounceRate') {
                                        return parseInt(value) + '%';
                                    }
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: function (value) {
                                    if (value) {
                                        return ng2DateTimeService.format(value, 'MMM DD', 'll');
                                    }
                                    return '';
                                },
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            };

            $q.all(promises)
                .then(function (data) {
                    vm.referring = data[0];
                    vm.direct = data[1];
                    vm.paid = data[2];
                    vm.organic = data[3];
                    vm.social = data[4];

                    for (var k = 0; k < vm.referring.length; k++) {
                        vm.lineChartLabels.push($filter('userDate')(vm.referring[k].date, 'll'));
                        vm.lineChartData[0].push(
                            getVisits(vm.referring[k]) +
                                getVisits(vm.organic[k]) +
                                getVisits(vm.direct[k]) +
                                getVisits(vm.paid[k]) +
                                getVisits(vm.social[k]),
                        );
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getVisits (statistic) {
            if (typeof statistic === 'undefined') {
                return 0;
            }

            return Number(statistic.visits || 0);
        }
    }
})();
