import { Injectable } from '@angular/core';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

@Injectable()
export class MonRunLoadingService {
    constructor (private ng2MonEventsService: MonEventService, private monLoading: MonLoadingService) {}
    initializeApp (): Promise<unknown> {
        return new Promise<unknown>((resolve: (value?: unknown) => void) => {
            this.ng2MonEventsService.addListener('beforeLogin', (index) => {
                this.monLoading.show();
                return index;
            });

            this.ng2MonEventsService.addListener('finishLogin', (index) => {
                this.monLoading.hide();
                return index;
            });

            this.ng2MonEventsService.addListener('beforeCustomer', (index) => {
                this.monLoading.show();
                return index;
            });

            this.ng2MonEventsService.addListener('finishCustomer', (index) => {
                this.monLoading.hide();
                return index;
            });

            this.ng2MonEventsService.addListener('beforeDomain', (index) => {
                this.monLoading.show();
                return index;
            });

            this.ng2MonEventsService.addListener('finishDomain', (index) => {
                this.monLoading.hide();
                return index;
            });
            return resolve();
        });
    }
}
