import { Pipe, PipeTransform } from '@angular/core';
import { TranslateReportPipe } from '@monsido/modules/report-center/pipes/translate-report/translate-report.pipe';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Pipe({
    name: 'reportCenterIcon',
})
export class ReportCenterIconPipe implements PipeTransform {

    constructor (
        private translateReportPipe: TranslateReportPipe,
        private translateService: TranslateService,
    ) {}

    transform (value: string): string {
        switch (this.translateReportPipe.transform(value)) {
            case this.translateService.getString('Heartbeat'):
                return 'faHeartbeat';
            case this.translateService.getString('Quality Assurance'):
                return 'faCheckSquare';
            case this.translateService.getString('Accessibility'):
                return 'faUniversalAccess';
            case this.translateService.getString('Inventory'):
                return 'faBooks';
            case this.translateService.getString('SEO'):
                return 'faChartLine';
            case this.translateService.getString('Heatmaps'):
                return 'faFire';
            case this.translateService.getString('Statistics'):
                return 'faChartPie';
            case this.translateService.getString('Policy'):
                return 'faGavel';
            case this.translateService.getString('Data Privacy'):
                return 'faLock';
            default:
                return 'faGlobe';
        }
    }


}
