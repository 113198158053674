(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityGuidelinesWcag20Table', {
        templateUrl: 'app/pages/accessibility/guidelines/acc-standards/wcag-2-0/table/table.html',
        controller: AccessibilityGuidelinesAccWCAGTableController,
        controllerAs: 'vm',
        bindings: {
            guidelines: '<',
            refreshPage: '&',
        },
    });

    AccessibilityGuidelinesAccWCAGTableController.$inject = ['ng2MonEventsService', 'MON_EVENTS'];

    function AccessibilityGuidelinesAccWCAGTableController (ng2MonEventsService, MON_EVENTS) {
        var vm = this;
        vm.collect = collect;
        vm.openChecks = openChecks;
        vm.groupClass = groupClass;

        function collect (guideline, attr) {
            return guideline.subgroups.reduce(function (a, b) {
                return a + b[attr];
            }, 0);
        }

        function openChecks (group, subGroup, classification) {
            var params = {
                body: 'guidelinesDialogsChecks',
                size: 'lg',
                data: {
                    subgroupId: subGroup.id,
                    accessibilityGroupId: group.id,
                    classification: classification,
                },
            };

            const options = {
                params,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options, function () {
                // vm.refreshPage();
            });
        }

        function groupClass (state) {
            return {
                'fa-chevron-right': state === false,
                'fa-chevron-down': state === true,
            };
        }
    }
})();
