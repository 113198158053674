(function () {
    'use strict';

    angular.module('pages.uptimes').service('FormUptimeService', FormUptimeService);

    FormUptimeService.$inject = ['gettextCatalog', '$deepmerge', 'ng2SessionService', 'Lodash'];

    /* @ngInject*/
    function FormUptimeService (gettextCatalog, $deepmerge, ng2SessionService, Lodash) {
        var vm = this;
        vm.intervalOptions = [
            {
                name: gettextCatalog.getString('1 minute'),
                value: 60,
            },
            {
                name: gettextCatalog.getString('5 minutes'),
                value: 300,
            },
        ];

        return {
            getIntervalOptions: getIntervalOptions,
        };

        function getIntervalOptions () {
            if (hasCustomer()) {
                var customerIntervals = ng2SessionService.customer.plan_traits.uptime_intervals;
                if (Array.isArray(customerIntervals)) {
                    if (customerIntervals.length === 1) {
                        return $deepmerge(
                            [],
                            Lodash.filter(vm.intervalOptions, function (interval) {
                                return customerIntervals.indexOf(interval.value) > -1;
                            }),
                        );
                    } else if (customerIntervals.length > 1) {
                        return $deepmerge([], vm.intervalOptions);
                    }
                }
            }

            return [];
        }

        // PROTECTED

        function hasCustomer () {
            return Boolean(ng2SessionService.customer);
        }
    }
})();
