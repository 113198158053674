import moment from 'moment';
import { CrawlModelType } from './crawl-model.type';

type FileType = { internal: number, external: number };

type AccessibilityType = { A: number, AA?: number, AAA?: number };

export class CrawlModel {
    id: number;
    date: string;
    js_rendering_service: 'puppeteer_service' | 'browser_service';
    page_count: number;
    dead_count: number | null;
    documents_count: number;
    links_count: number;
    images_count: number;
    files: {
        IMAGE?: number,
        SPREADSHEET?: number,
        DOCUMENT?: number,
        PDF?: number
    };
    file_counts: { pdf: FileType };
    dead_links_count: number;
    dead_images_count: number;
    pages_with_errors_count: number;
    pages_with_dead_links_count: number;
    pages_with_dead_images_count: number;
    pages_with_notifications_count: number;
    policy_with_violations_count: number;
    policy_with_search_hits_count: number;
    policy_with_required_count: number;
    pages_with_policy_violations_count: number;
    pages_with_policy_searches_count: number;
    pages_with_policy_required_count: number;
    pages_with_policy_matches_count: number;
    spelling_errors_count: number;
    spelling_errors_confirmed_count: number;
    spelling_errors_potential_count: number;
    pages_with_spelling_errors_count: number;
    pages_with_spelling_errors_confirmed_count: number;
    pages_with_spelling_errors_potential_count: number;
    accessibility_guideline: string;
    accessibility_issues: {
        error: number,
        error_and_warning: number,
        error_and_warning_and_review: number
    } | null;
    accessibility_checks: {
        A?: number,
        AA?: number,
        AAA?: number,
        error: number,
        warning: number,
        review: number
    } | null;
    accessiblity_issues_count: number;
    accessibility_reviewed_documents: { pdf: FileType };
    accessibility_pages_by_result_type_and_abbr: {
        error: AccessibilityType,
        error_and_warning: AccessibilityType;
        error_and_warning_and_review: AccessibilityType;
    } | null;
    accessibility_checks_by_result_type_and_abbr: {
        error: AccessibilityType,
        warning: AccessibilityType,
        review: AccessibilityType
    } | null;
    accessibility_guideline_id: number | null;
    accessibility_errors_only: boolean;
    seo_errors_alerts_count: number;
    seo_errors_infos_count: number;
    seo_errors_technical_count: number;
    seo_errors_warnings_count: number;
    pages_with_seo_errors_count: number;
    pages_with_seo_errors_alerts_count: number;
    pages_with_seo_errors_info_count: number;
    pages_with_seo_errors_technical_count: number;
    pages_with_seo_errors_warnings_count: number;
    pages_with_accessibility_errors_count: number;
    readability_test: 'flesch_kincaid_re' | 'lix' | null;
    readability_distribution: Record<string, number> | null;
    performance_version: string;
    performance_page_count: number;
    performance_profile_count: number;
    performance_first_meaningful_paint_average: number | null;
    performance_first_contentful_paint_average: number | null;
    performance_largest_contentful_paint_average: number | null;
    performance_speed_index_average: number | null;
    performance_total_blocking_time_average: number | null;
    performance_cumulative_layout_shift_average: number | null;
    performance_time_to_interactive_average: number | null;
    performance_score_average: number | null;
    post_processing_done_at: string;
    compliance_percentage: {
        policies: number,
        qa_pages: number,
        seo_issues: number,
        accessibility_pages: {
            error: number,
            error_and_warning: number,
            error_and_warning_and_review: number }
    };
    data_protection_pages_with_violations_count: number;
    data_protection_total_page_violations_count: number;
    data_protection_active_page_violations_count: number;
    data_protection_ignored_page_violations_count: number;
    data_protection_fixed_page_violations_count: number;
    data_protection_high_priority_page_violations_count: number;
    data_protection_medium_priority_page_violations_count: number;
    data_protection_low_priority_page_violations_count: number;
    searches_count: number;
    search_errors_count: number;
    searches_with_normal_violations_count: number;
    searches_with_unwanted_violations_count: number;
    total_searches_count: number;
    pages_with_searches_count: number;
    pages_with_search_errors_count: number;
    queued_at?: string;
    vertical_scores?: {
        qa: number;
        seo: number;
        accessibility: number;
    };

    constructor (attributes: CrawlModelType) {
        this.id = attributes.id;
        this.js_rendering_service = attributes.js_rendering_service;
        this.page_count = attributes.page_count;
        this.dead_count = attributes.dead_count;
        this.documents_count = attributes.documents_count;
        this.links_count = attributes.links_count;
        this.images_count = attributes.images_count;

        this.files = attributes.files;

        this.file_counts = {
            pdf: {
                internal: attributes.file_counts.pdf.internal,
                external: attributes.file_counts.pdf.external,
            },
        };
        this.dead_links_count = attributes.dead_links_count;
        this.dead_images_count = attributes.dead_images_count;
        this.pages_with_errors_count = attributes.pages_with_errors_count;
        this.pages_with_dead_links_count = attributes.pages_with_dead_links_count;
        this.pages_with_dead_images_count = attributes.pages_with_dead_images_count;
        this.pages_with_notifications_count = attributes.pages_with_notifications_count;
        this.policy_with_violations_count = attributes.policy_with_violations_count;
        this.policy_with_search_hits_count = attributes.policy_with_search_hits_count;
        this.policy_with_required_count = attributes.policy_with_required_count;
        this.pages_with_policy_violations_count = attributes.pages_with_policy_violations_count;
        this.pages_with_policy_searches_count = attributes.pages_with_policy_searches_count;
        this.pages_with_policy_required_count = attributes.pages_with_policy_required_count;
        this.pages_with_policy_matches_count = attributes.pages_with_policy_matches_count;
        this.spelling_errors_count = attributes.spelling_errors_count;
        this.spelling_errors_confirmed_count = attributes.spelling_errors_confirmed_count;
        this.spelling_errors_potential_count = attributes.spelling_errors_potential_count;
        this.pages_with_spelling_errors_count = attributes.pages_with_spelling_errors_count;
        this.pages_with_spelling_errors_confirmed_count = attributes.pages_with_spelling_errors_confirmed_count;
        this.pages_with_spelling_errors_potential_count = attributes.pages_with_spelling_errors_potential_count;
        this.post_processing_done_at = attributes.post_processing_done_at || '';
        this.accessibility_guideline = attributes.accessibility_guideline;
        this.accessibility_errors_only = attributes.accessibility_errors_only;
        if (attributes.accessibility_issues) {
            this.accessibility_issues = {
                error: attributes.accessibility_issues.error,
                error_and_warning: attributes.accessibility_issues.error_and_warning,
                error_and_warning_and_review: attributes.accessibility_issues.error_and_warning_and_review,
            };
        } else {
            this.accessibility_issues = null;
        }
        if (attributes.accessibility_checks) {
            this.accessibility_checks = {
                error: attributes.accessibility_checks.error,
                warning: attributes.accessibility_checks.warning,
                review: attributes.accessibility_checks.review,
            };
            if (attributes.accessibility_checks.A != null) {
                this.accessibility_checks.A = attributes.accessibility_checks.A;
            }
            if (attributes.accessibility_checks.AA != null) {
                this.accessibility_checks.AA = attributes.accessibility_checks.AA;
            }
            if (attributes.accessibility_checks.AAA != null) {
                this.accessibility_checks.AAA = attributes.accessibility_checks.AAA;
            }
        } else {
            this.accessibility_checks = null;
        }
        this.accessiblity_issues_count = attributes.accessiblity_issues_count;
        this.accessibility_reviewed_documents = {
            pdf: {
                internal: attributes.accessibility_reviewed_documents.pdf.internal,
                external: attributes.accessibility_reviewed_documents.pdf.external,
            },
        };
        if (attributes.accessibility_pages_by_result_type_and_abbr) {
            this.accessibility_pages_by_result_type_and_abbr = {
                error: {
                    A: attributes.accessibility_pages_by_result_type_and_abbr.error.A,
                    AA: attributes.accessibility_pages_by_result_type_and_abbr.error.AA,
                    AAA: attributes.accessibility_pages_by_result_type_and_abbr.error.AAA,
                },
                error_and_warning: {
                    A: attributes.accessibility_pages_by_result_type_and_abbr.error_and_warning.A,
                    AA: attributes.accessibility_pages_by_result_type_and_abbr.error_and_warning.AA,
                    AAA: attributes.accessibility_pages_by_result_type_and_abbr.error_and_warning.AAA,
                },
                error_and_warning_and_review: {
                    A: attributes.accessibility_pages_by_result_type_and_abbr.error_and_warning_and_review.A,
                    AA: attributes.accessibility_pages_by_result_type_and_abbr.error_and_warning_and_review.AA,
                    AAA: attributes.accessibility_pages_by_result_type_and_abbr.error_and_warning_and_review.AAA,
                },
            };
        } else {
            this.accessibility_pages_by_result_type_and_abbr = null;
        }
        if (attributes.accessibility_checks_by_result_type_and_abbr) {
            this.accessibility_checks_by_result_type_and_abbr = {
                error: {
                    A: attributes.accessibility_checks_by_result_type_and_abbr.error.A,
                    AA: attributes.accessibility_checks_by_result_type_and_abbr.error.AA,
                    AAA: attributes.accessibility_checks_by_result_type_and_abbr.error.AAA,
                },
                warning: {
                    A: attributes.accessibility_checks_by_result_type_and_abbr.warning.A,
                    AA: attributes.accessibility_checks_by_result_type_and_abbr.warning.AA,
                    AAA: attributes.accessibility_checks_by_result_type_and_abbr.warning.AAA,
                },
                review: {
                    A: attributes.accessibility_checks_by_result_type_and_abbr.review.A,
                    AA: attributes.accessibility_checks_by_result_type_and_abbr.review.AA,
                    AAA: attributes.accessibility_checks_by_result_type_and_abbr.review.AAA,
                },
            };
        } else {
            this.accessibility_checks_by_result_type_and_abbr = null;
        }
        this.accessibility_guideline_id = attributes.accessibility_guideline_id;
        this.seo_errors_alerts_count = attributes.seo_errors_alerts_count;
        this.seo_errors_infos_count = attributes.seo_errors_infos_count;
        this.seo_errors_technical_count = attributes.seo_errors_technical_count;
        this.seo_errors_warnings_count = attributes.seo_errors_warnings_count;
        this.pages_with_seo_errors_count = attributes.pages_with_seo_errors_count;
        this.pages_with_seo_errors_alerts_count = attributes.pages_with_seo_errors_alerts_count;
        this.pages_with_seo_errors_info_count = attributes.pages_with_seo_errors_info_count;
        this.pages_with_seo_errors_technical_count = attributes.pages_with_seo_errors_technical_count;
        this.pages_with_seo_errors_warnings_count = attributes.pages_with_seo_errors_warnings_count;
        this.pages_with_accessibility_errors_count = attributes.pages_with_accessibility_errors_count;
        this.readability_test = attributes.readability_test;

        this.readability_distribution = attributes.readability_distribution;

        this.performance_version = attributes.performance_version;
        this.performance_page_count = attributes.performance_page_count;
        this.performance_profile_count = attributes.performance_profile_count;
        this.performance_first_meaningful_paint_average = attributes.performance_first_meaningful_paint_average;
        this.performance_first_contentful_paint_average = attributes.performance_first_contentful_paint_average;
        this.performance_largest_contentful_paint_average = attributes.performance_largest_contentful_paint_average;
        this.performance_speed_index_average = attributes.performance_speed_index_average;
        this.performance_total_blocking_time_average = attributes.performance_total_blocking_time_average;
        this.performance_cumulative_layout_shift_average = attributes.performance_cumulative_layout_shift_average;
        this.performance_time_to_interactive_average = attributes.performance_time_to_interactive_average;
        this.performance_score_average = attributes.performance_score_average;
        this.compliance_percentage = {
            policies: attributes.compliance_percentage.policies,
            qa_pages: attributes.compliance_percentage.qa_pages,
            seo_issues: attributes.compliance_percentage.seo_issues,
            accessibility_pages: {
                error: attributes.compliance_percentage.accessibility_pages.error,
                error_and_warning: attributes.compliance_percentage.accessibility_pages.error_and_warning,
                error_and_warning_and_review: attributes.compliance_percentage.accessibility_pages.error_and_warning_and_review,
            },
        };
        this.data_protection_pages_with_violations_count = attributes.data_protection_pages_with_violations_count;
        this.data_protection_total_page_violations_count = attributes.data_protection_total_page_violations_count;
        this.data_protection_active_page_violations_count = attributes.data_protection_active_page_violations_count;
        this.data_protection_ignored_page_violations_count = attributes.data_protection_ignored_page_violations_count;
        this.data_protection_fixed_page_violations_count = attributes.data_protection_fixed_page_violations_count;
        this.data_protection_high_priority_page_violations_count = attributes.data_protection_high_priority_page_violations_count;
        this.data_protection_medium_priority_page_violations_count = attributes.data_protection_medium_priority_page_violations_count;
        this.data_protection_low_priority_page_violations_count = attributes.data_protection_low_priority_page_violations_count;
        this.searches_count = attributes.searches_count;
        this.search_errors_count = attributes.search_errors_count;
        this.searches_with_normal_violations_count = attributes.searches_with_normal_violations_count;
        this.searches_with_unwanted_violations_count = attributes.searches_with_unwanted_violations_count;
        this.total_searches_count = attributes.total_searches_count;
        this.pages_with_searches_count = attributes.pages_with_searches_count;
        this.pages_with_search_errors_count = attributes.pages_with_search_errors_count;

        this.date = moment(attributes.date + 'T00:00:00+00:00', moment.ISO_8601).format();
        if (attributes.queued_at) {
            this.queued_at = moment(attributes.queued_at, moment.ISO_8601).toISOString();
        }

        if (attributes.vertical_scores) {
            this.vertical_scores = {
                ...attributes.vertical_scores,
            };
        }
    }
}
