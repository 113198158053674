import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.export').component('csvExportCompoundDialog', {
        templateUrl: 'app/modules/export/components/dialog/dialog-compound.html',
        controller: MultipleExportsController,
        controllerAs: 'vm',
        bindings: {
            exports: '<',
            monDialogId: '@',
        },
    });

    MultipleExportsController.$inject = [
        'ng2ExportService',
        'ng2SessionService',
        'monDialog',
        '$location',
        'moment',
        'ng2MonEventsService',
        'DATE_FORMAT',
        'API_REQUEST_DATE_FORMAT',
    ];
    /* @ngInject */
    function MultipleExportsController (
        ng2ExportService,
        ng2SessionService,
        monDialog,
        $location,
        moment,
        ng2MonEventsService,
        DATE_FORMAT,
        API_REQUEST_DATE_FORMAT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.download = download;
        vm.goToExports = goToExports;

        function activate () {
            vm.onDownload = false;
            vm.downloadDone = false;
            vm.dateFormat = 'YYYY-MM-DD';
            vm.date = {
                startDate: moment().subtract(3, 'months'),
                endDate: moment(),
            };
            vm.dateFormat = DATE_FORMAT;
            vm.datepickerOptions = { opens: 'center' };
            loadLocations();
        }

        function loadLocations () {
            var params = $location.search();
            if (angular.isDefined(params.from)) {
                vm.date.startDate = moment(params.from, vm.dateFormat);
            }
            if (angular.isDefined(params.to)) {
                vm.date.endDate = moment(params.to, vm.dateFormat);
            }
        }

        function goToExports () {
            var params = {
                body: 'csvExportsOverlay',
            };
            monDialog.close(vm.monDialogId);
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function download (exportParams) {
            var params = {
                recipients: [ng2SessionService.me.email],
            };

            angular.forEach(exportParams, function (value, key) {
                params[key] = value;
            });

            vm.onDownload = true;
            params.category_ref = vm.date.startDate.format(API_REQUEST_DATE_FORMAT) + ',' + vm.date.endDate.format(API_REQUEST_DATE_FORMAT);
            ng2ExportService.create(params)
                .toPromise()
                .then(function () {
                    vm.onDownload = false;
                    vm.downloadDone = true;
                }, angular.noop);
        }
    }
})();
