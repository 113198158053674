import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { SessionService } from '@monsido/core/session/session.service';
import { ImageMetaData } from '../../inventory/interfaces/image-metadata.interface';

@Injectable({
    providedIn: 'root',
})
export class MetaDataRepo {
    constructor (
        private client: ApiClient,
        private sessionService: SessionService,
    ) {}

    getImageMetada (linkId: number | null): Promise<ImageMetaData[]> {
        if (this.sessionService.domain?.id) {
            return this.client.getPromise(`domains/${this.sessionService.domain.id}/links/${linkId}/metadata`);
        }

        throw (new Error('No metadata id present'));
    }

}
