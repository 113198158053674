export const COOKIE_FIELDS = {
    id: 'id',
    name: 'name',
    domain: 'domain',
    source: 'source',
    expires: 'expires',
    platform: 'platform',
    type: 'type',
    description: 'description',
    expirations: 'expirations',
    descriptions: 'descriptions',
} as const;
