(function () {
    'use strict';

    angular.module('app.components').component('policyInformation', {
        templateUrl: 'app/components/policy-information/policy-information.html',
        controller: TablePolicyInformationController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
        },
    });

    TablePolicyInformationController.$inject = ['gettextCatalog', 'PolicyHelper', 'ng2SessionService', '$filter'];
    /* @ngInject */
    function TablePolicyInformationController (gettextCatalog, PolicyHelper, ng2SessionService, $filter) {
        var vm = this;
        vm.$onInit = activate;
        vm.outsideClick = outsideClick;
        vm.popoverClick = popoverClick;
        vm.getSubjectTranslation = getSubjectTranslation;
        vm.getDomainTargetScanDate = getDomainTargetScanDate;
        vm.isDomainActive = isDomainActive;
        vm.getLastRunMessage = getLastRunMessage;

        function activate () {
            vm.popoverOpen = false;
            vm.notesPopupOpen = false;
        }

        function getSubjectTranslation () {
            switch (vm.policy.subject) {
                case 'page':
                    return gettextCatalog.getString('Search in: Everything');
                case 'page_html':
                    return gettextCatalog.getString('Search in: Only HTML pages');
                case 'page_document':
                    return gettextCatalog.getString('Search in: Documents');
                case 'link':
                    return gettextCatalog.getString('Search in: Links');
            }
        }

        function popoverClick () {
            vm.popoverOpen = !vm.popoverOpen;
        }

        function outsideClick () {
            vm.popoverOpen = false;
        }

        function isDomainActive () {
            return ng2SessionService.domain !== null;
        }

        function getDomainTargetScanDate () {
            var target = PolicyHelper.getTargetDomain(vm.policy.targets);
            if (target && target.latest_run_at) {
                return $filter('userDate')(target.latest_run_at, 'lll');
            } else {
                return gettextCatalog.getString('Never');
            }
        }

        function getLastRunMessage () {
            return gettextCatalog.getString('Last run {{date}}', {
                date: getDomainTargetScanDate(),
            });
        }
    }
})();
