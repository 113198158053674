(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogRuleReadability', {
        templateUrl: 'app/forms/global-policy/dialogs/rule/blocks/readability/readability.html',
        controller: ReadabilityController,
        controllerAs: 'vm',
        bindings: {
            cRule: '=',
            monDialogId: '<',
            policyRuleForm: '=',
        },
    });

    ReadabilityController.$inject = ['PolicyFormHelper', 'gettextCatalog', 'monDialog'];

    /* @ngInject */
    function ReadabilityController (PolicyFormHelper, gettextCatalog, monDialog) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;

        function activate () {
            vm.cRule.inverse = false;
            PolicyFormHelper.setRuleType(vm.cRule.type);
            setupTypeOptions();
            setupQueryOptions();
        }

        function setupTypeOptions () {
            vm.typeOptions = PolicyFormHelper.getOptionsByRuleType();
            vm.cRule.operator = vm.cRule.operator || vm.typeOptions[1].type;
        }

        function setupQueryOptions () {
            vm.queryOptions = [
                {
                    value: 1,
                    translation: gettextCatalog.getString('Below 5th grade'),
                },
                {
                    value: 5,
                    translation: gettextCatalog.getString('5th grade'),
                },
                {
                    value: 6,
                    translation: gettextCatalog.getString('6th grade'),
                },
                {
                    value: 7,
                    translation: gettextCatalog.getString('7th grade'),
                },
                {
                    value: 8,
                    translation: gettextCatalog.getString('8th to 9th grade'),
                },
                {
                    value: 10,
                    translation: gettextCatalog.getString('10th to 12th grade'),
                },
                {
                    value: 15,
                    translation: gettextCatalog.getString('College'),
                },
                {
                    value: 17,
                    translation: gettextCatalog.getString('College graduate'),
                },
            ];

            if (!vm.cRule.query) {
                vm.cRule.query = vm.queryOptions[3].value;
            } else {
                vm.cRule.query = parseInt(vm.cRule.query);
            }
        }

        function close () {
            var newRule = angular.copy(vm.cRule);
            newRule.sub_entry = newRule.sub_entry.join(',');
            if (vm.policyRuleForm) {
                vm.policyRuleForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, newRule);
        }
    }
})();
