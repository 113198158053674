(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardSummaryPerformance', {
        templateUrl: 'app/pages/global/changes-history/dashboard/summary/performance/performance.html',
        controller: PerformanceController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
        },
    });

    PerformanceController.$inject = ['$filter', 'PagePerformanceVersionService', 'gettextCatalog'];
    /* @ngInject */
    function PerformanceController ($filter, PagePerformanceVersionService, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = update;
        vm.getValue = getValue;
        vm.latestEntry = undefined;

        function activate () {
            updateLatestEntry();
            vm.score = getValue();
            vm.showV2Metrics = false;
            vm.showFMP = false;
            vm.metrics = [];
            setShownMetrics();
        }

        function update () {
            updateLatestEntry();
            vm.score = getValue();
            setShownMetrics();
        }

        function updateLatestEntry () {
            vm.latestEntry = undefined;
            if (!vm.history) {
                return;
            }

            const filtredHistory = vm.history.filter(item=>Boolean(item['performance_version']));

            if (!filtredHistory || filtredHistory.length === 0) {
                return;
            }

            vm.latestEntry = filtredHistory[filtredHistory.length - 1];
        }

        function getValue () {
            var result = {
                performance_first_contentful_paint_average: null,
                performance_largest_contentful_paint_average: null,
                performance_first_meaningful_paint_average: null,
                performance_time_to_interactive_average: null,
                performance_speed_index_average: null,
                performance_total_blocking_time_average: null,
                performance_cumulative_layout_shift_average: null,
                performance_score_average: null,
                performance_page_count: null,
            };

            if (!vm.latestEntry) {
                return result;
            }

            result.performance_first_contentful_paint_average = getFieldValue(vm.latestEntry, 'performance_first_contentful_paint_average');
            result.performance_largest_contentful_paint_average = getFieldValue(vm.latestEntry, 'performance_largest_contentful_paint_average');
            result.performance_first_meaningful_paint_average = getFieldValue(vm.latestEntry, 'performance_first_meaningful_paint_average');
            result.performance_time_to_interactive_average = getFieldValue(vm.latestEntry, 'performance_time_to_interactive_average');
            result.performance_speed_index_average = getFieldValue(vm.latestEntry, 'performance_speed_index_average');
            result.performance_total_blocking_time_average = getFieldValue(vm.latestEntry, 'performance_total_blocking_time_average');
            result.performance_cumulative_layout_shift_average = getFieldValue(vm.latestEntry, 'performance_cumulative_layout_shift_average');
            result.performance_score_average = getFieldValue(vm.latestEntry, 'performance_score_average');
            result.performance_page_count = getFieldValue(vm.latestEntry, 'performance_page_count');

            return result;
        }

        function getFieldValue (object, field) {
            var value = object[field];

            if (value === undefined) {
                return null;
            }
            switch (field) {
                case 'performance_score_average':
                    return $filter('monNumber')(value * 100, '0,0');
                default:
                    return value;
            }
        }

        function setShownMetrics () {
            if (!vm.latestEntry) {
                return;
            }

            vm.metrics = PagePerformanceVersionService.getVersionInfo(vm.latestEntry.performance_version).metrics.map(metric => {
                return {
                    title: metric.label,
                    unit: metric.unit,
                    data: vm.score[`performance_${metric.id}_average`],
                    rulerClass: metric.rulerClass,
                };
            });
        }
    }
})();
