(function () {
    'use strict';

    angular.module('modules.terms').run([
        'ng2MonEventsService',
        'uibModalOpenerService',
        'terms',
        'activeFeatures',
        function (ng2MonEventsService, uibModalOpenerService, terms, activeFeatures) {

            const afterLoadUserPromise = new Promise(resolve => {
                const canceller = ng2MonEventsService.addListener('afterLoadUser', function (user, isSudo) {
                    resolve([user, isSudo]);
                    ng2MonEventsService.remove(canceller);
                });
            });

            afterLoadUserPromise.then((afterLoadUserResult) => {
                const [user, isSudo] = afterLoadUserResult;
                if (
                    activeFeatures.accept_terms &&
                    !isSudo &&
                    (!(user && user.privacy_policy && user.privacy_policy.accepted_at) ||
                      (user && user.privacy_policy && user.privacy_policy.version.toString() !== terms.version.toString())
                    )
                ) {
                    uibModalOpenerService.open({
                        windowClass: 'privacy-modal-open',
                        component: 'privacyDialog',
                        keyboard: false,
                        backdrop: 'static',
                    }).result.then(angular.noop, angular.noop);
                }
            });
        },
    ]);
})();
