(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScanInternalUrls', {
        templateUrl: 'app/forms/domain/steps/scan/internal-urls/internal-urls.html',
        controller: DomainScanInternalUrlsController,
        controllerAs: 'vm',
        bindings: {
            internalUrls: '=',
        },
    });

    DomainScanInternalUrlsController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function DomainScanInternalUrlsController (gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.addUrl = addUrl;
        vm.removeUrl = removeUrl;

        function activate () {
            vm.internalUrls = vm.internalUrls || [];
            if (vm.internalUrls.length === 0) {
                addUrl();
            }
            setupOperators();
        }

        function setupOperators () {
            vm.operators = [
                {
                    value: 'contains',
                    name: gettextCatalog.getString('Contains'),
                },
                {
                    value: 'starts_with',
                    name: gettextCatalog.getString('Starts with'),
                },
                {
                    value: 'regex',
                    name: gettextCatalog.getString('Regex'),
                },
            ];
        }

        function addUrl () {
            vm.internalUrls.push({ operator: 'starts_with' });
        }

        function removeUrl (index) {
            vm.internalUrls.splice(index, 1);
        }
    }
})();
