import type { ReportInterface, Target } from '@monsido/modules/models/api/interfaces/report.interface';
import { Template } from '@monsido/modules/models/api/interfaces/report.interface';
import { User } from '@monsido/modules/models/api/user';
import { UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';

export class Report implements ReportInterface {
    constructor (report: ReportInterface) {
        this.id = report.id;
        this.template = report.template;
        this.targets = this.setupTargets(report);
        this.schedule = report.schedule;
    }

    id: number;
    template: Template;
    targets: Target[];
    schedule: string;

    setupTargets (model: ReportInterface): Target[] {
        if (model !== null && typeof model === 'object' && Array.isArray(model.targets)) {
            return model.targets.map((target) => {
                target.users = target.users ? this.setupUsers(target.users) : [];
                return target;
            });
        }

        return model.targets;
    }

    setupUsers (users: UserInterface[]): User[] {
        if (Array.isArray(users)) {
            return users.map(user => new User(user));
        }

        return [];
    }

}
