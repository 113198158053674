(function () {
    'use strict';

    angular.module('filters.monsido').filter('adaMode', adaModeFilterController);

    adaModeFilterController.$inject = ['ng2SessionService'];
    /* @ngInject */
    function adaModeFilterController (ng2SessionService) {
        return adaMode;

        // //////////

        function adaMode () {
            return ng2SessionService.adaMode();
        }
    }
})();
