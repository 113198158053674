import { Component, ContentChild, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActionMenuItemType } from '../action-menu-panel/action-menu-panel.type';
import { MonActionMenuPanelComponent } from '../action-menu-panel/action-menu-panel.component';
import { generateRandomString } from 'ng2/shared/utils';
import { TemplateReferenceDirective } from '../action-menu-panel/template-reference.directive';

@Component({
    selector: 'mon-action-menu',
    templateUrl: './action-menu.component.html',
    styleUrls: ['./action-menu.component.scss'],
})
class ActionMenuComponent {
    @Input() monActionMenuItems: ActionMenuItemType[] = [];
    @Input() monActionMenuClass = 'menu-panel-right-align';

    @ViewChild('menu') menu!: MonActionMenuPanelComponent;
    @ViewChild('menuButton', { read: ElementRef }) menuButton!: ElementRef<HTMLButtonElement>;
    @ContentChild(TemplateReferenceDirective, { read: TemplateRef }) templateRef?: TemplateRef<TemplateReferenceDirective>;

    buttonId = `mon-action-menu-button${generateRandomString()}`;
    menuId = `mon-action-menu-${generateRandomString()}`;
    pressed = false;

    constructor () {}

    onHide (): void {
        this.setFocusOnButton();
        this.pressed = false;
    }

    onShow (): void {
        this.setFocusOnMenu();
        this.pressed = true;
    }

    private setFocusOnMenu (): void {
        setTimeout(() => {
            this.menu.setFocus();
        });
    }

    private setFocusOnButton (): void {
        this.menuButton.nativeElement.querySelector('button')?.focus();
    }

}

export { ActionMenuComponent as MonActionMenuComponent };
