import { Injectable } from '@angular/core';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import { Domain } from '@monsido/modules/models/api/domain';

@Injectable({
    providedIn: 'root',
})
export class FormDomainScriptGuidePageAssistService {
    constructor (
        private activeFeatureService: ActiveFeatureService,
    ) {}

    getAccessToFeature (domain: Domain): boolean {
        return Boolean(
            domain?.settings?.scripts?.page_assist &&
            this.activeFeatureService.isFeatureActive('page_assist', domain),
        );
    }
}
