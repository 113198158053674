(function () {
    "use strict";

    angular.module("modules.translations.run").run([
        "ng2MonEventsService",
        "translation",
        "$window",
        'ng2DateTimeService',
        function (ng2MonEventsService, translation, $window, ng2DateTimeService) {
            ng2MonEventsService.addListener("afterLoadUser", function (user) {
                translation.setDateLocale($window.navigator.language);
                translation.setLanguage(user.locale);
                ng2DateTimeService.setLocale(user.locale);
            });

            ng2MonEventsService.addListener("logout", function () {
                translation.setLanguage("en");
            });
        },
    ]);
})();
