import { Component, Input, OnInit } from '@angular/core';
import { MetaDataRepo } from '@monsido/ng2/modules/endpoints/api/meta-data.repo';
import { ImageMetaData } from '@monsido/ng2/modules/inventory/interfaces/image-metadata.interface';
import { TranslateService } from '@client/app/services/translate/translate.service';


@Component({
    selector: 'mon-img-metadata-overlay-component',
    templateUrl: './img-metadata-overlay.component.html',
    styleUrls: ['./img-metadata-overlay.component.scss'],
})
export class ImgMetadataOverlayComponent implements OnInit {
    @Input() linkUrl: string | null = null;
    @Input() linkId: number | null = null;
    imageMetaData: ImageMetaData[] = [];
    title: string = this.translateService.getString('Image details');

    constructor (
        private translateService: TranslateService,
        private metaDataRepo: MetaDataRepo,
    ) {}

    ngOnInit (): void {
        this.getMetadata();
    }

    getMetadata (): void {
        this.metaDataRepo.getImageMetada(this.linkId).then((imageMetaData: ImageMetaData[]) => {
            this.imageMetaData = imageMetaData.sort((a, b) => (a.key > b.key) ? 1 : -1);
        });
    }
}

export { ImgMetadataOverlayComponent as MonImgMetadataOverlayComponent };
