(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name spellService
     * @description Service to handle Spell requests
     */
    angular.module('services.entities').factory('spellService', spellService);

    spellService.$inject = ['ng2SpellingRepoService'];

    /* @ngInject*/
    function spellService (ng2SpellingRepoService) {
        return {
            confirmWord: confirmWord,
            destroyIgnoredPageSpellingError: destroyIgnoredPageSpellingError,
            destroyPageSpellingError: destroyPageSpellingError,
            destroySpellingError: destroySpellingError,
            destroyWord: destroyWord,
            updatePageIgnoreSpellingError: updatePageIgnoreSpellingError,
        };

        function confirmWord (wordId, params) {
            return ng2SpellingRepoService.confirm(wordId, params);
        }

        function updatePageIgnoreSpellingError (pageId, errorId) {
            return ng2SpellingRepoService.updatePageIgnoreSpellingError(pageId, errorId);
        }

        function destroyPageSpellingError (page_id, error_id) {
            return ng2SpellingRepoService.destroyPageSpellingError(page_id, error_id);
        }

        function destroySpellingError (error_id) {
            return ng2SpellingRepoService.destroySpellingError(error_id);
        }

        function destroyIgnoredPageSpellingError (page_id, spelling_id) {
            return ng2SpellingRepoService.destroyIgnoredPageSpellingError(page_id, spelling_id);
        }

        function destroyWord (wordId) {
            return ng2SpellingRepoService.destroyWord(wordId);
        }
    }
})();
