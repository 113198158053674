<ng-container *ngIf="!issueNotFound">
    <div class="pl-5 mon-dialog-header">
        <mon-page-header [header]="title" [subHeader]="subHeader" [panelLeftClass]="'pl-8'">
            <div class="d-flex justify-content-end pr-5">
                <ng-content select="[slot=issue-action-button]"></ng-content>
                <mon-copy [target]="currentUrl" [buttonName]="'Copy URL'"></mon-copy>
            </div>
        </mon-page-header>
    </div>
    <div
        class="notification"
        [ngClass]="{
            'fixed': status === 'fixed',
            'reviewed': status === 'reviewed',
            'in-dictionary': status === 'in-dictionary',
            'ignored': status === 'ignored',
            'with-reason': reason
        }"
        *ngIf="statusOptions.show">
        <div>
            <mon-icon [icon]="statusOptions.icon" class="mr-1" ></mon-icon>
            <span class="notification-text">{{ statusOptions.text }}</span>
        </div>
        <div *ngIf="reason" class="notification-reason"><strong translate>Reason:&nbsp;</strong>{{ reason }}</div>
    </div>
    <div class="mon-dialog-body pt-1 common-issue-header-border" [ngClass]="{ 'with-indicator': statusOptions.show , 'with-reason': reason }">
        <div class="card panel-monsido-card mt-4 mx-4">
            <div class="card-body">
                <!-- Slot "overview" -->
                <ng-content select="[slot=issue-overview]"></ng-content>
            </div>
        </div>

        <div class="card panel-monsido-card mt-4 mx-4">
            <div class="card-body">
                <!-- Slot "content" -->
                <ng-content select="[slot=issue-content]"></ng-content>
            </div>
        </div>

        <div class="card panel-monsido-card mt-4 mx-4">
            <div class="card-body">
                <!-- Slot "documents" -->
                <ng-content select="[slot=issue-documents]"></ng-content>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="issueNotFound">
    <div class="pl-5 mon-dialog-header">
        <mon-page-header [header]="issueNotFoundTitle" [panelLeftClass]="'pl-8'"></mon-page-header>
    </div>
    <div class="mon-dialog-body pt-1 common-issue-header-border issue-not-found-dialog-body">
        <div class="mt-4 mx-4 text-center">
            <img src="/images/monsido-cozy.png" [alt]="'A cozy room with sofa, table and laptops that have Acquia Optimize text' | translate" class="issue-not-found-image mb-8">
            <p class="issue-not-found-text" translate>Sorry, this page was not found. The issue must have been resolved.</p>
        </div>
    </div>
</ng-container>
