if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (search, position) {
        if (typeof search !== 'string') {
            return false;
        }
        var pos = Number(position);
        if (isNaN(pos) || pos < 0) {
            pos = 0;
        }
        return this.slice(pos, search.length) === search;
    };
}
