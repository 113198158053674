export const MON_MODULES = {
    policies: 'policies',
    qa: 'qa',
    accessibility: 'accessibility',
    seo: 'seo',
    uptime: 'uptime',
    inventory: 'inventory',
    statistics: 'statistics',
    content_pages: 'content_pages',
    pagecorrect: 'pagecorrect',
    performance: 'performance',
    data_privacy: 'dataprivacy',
    cookie_banner: 'cookie_banner',
    page_fix: 'page_fix',
} as const;

