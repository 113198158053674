(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuSeo', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', '$filter'];
    function SideMenuController (gettextCatalog, $filter) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'SEO', 'SUMMARY']),
                    href: 'base.customer.domain.seo.summary',
                },
                {
                    title: gettextCatalog.getString('Pages with Opportunities'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'SEO', 'PAGES']),
                    href: 'base.customer.domain.seo.pages',
                },
                {
                    title: gettextCatalog.getString('SEO Checkpoints'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'SEO', 'CHECKPOINTS']),
                    href: 'base.customer.domain.seo.issues',
                },
            ];
        }
    }
})();
