export const PAGE_PERFORMANCE_CATEGORY_GROUP = {
    METRICS: 'metrics',
    LOAD_OPPORTUNITIES: 'load-opportunities',
    DIAGNOSTICS: 'diagnostics',
    BUDGETS: 'budgets',
    SEO_MOBILE: 'seo-mobile',
    SEO_CONTENT: 'seo-content',
    SEO_CRAWL: 'seo-crawl',
    PWA_FAST_RELIABLE: 'pwa-fast-reliable',
    PWA_INSTALLABLE: 'pwa-installable',
    PWA_OPTIMIZED: 'pwa-optimized',
} as const;
