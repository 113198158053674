import { DomainInterface } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { EssentialDomainGroupDataModel } from './essential-domain-group-data.model';

export class EssentialDomainDataModel {
    id: number;
    title: string;
    url: string;
    domainGroups: EssentialDomainGroupDataModel[] = [];
    features: {
        readabilityTest?: string;
        dataProtection?: boolean;
        accessibility?: string;
    } = {};
    scan: {
        scanDocuments?: boolean;
        login?: {
            type: string;
        }
    } = {};
    favorite: boolean = false;

    type?: string;

    constructor (data: DomainInterface) {
        this.id = data.id;
        this.title = data.title;
        this.url = data.url;
        this.favorite = data.favorite;

        if (Array.isArray(data.domain_groups)) {
            data.domain_groups.forEach(group => {
                this.domainGroups.push(new EssentialDomainGroupDataModel(group));
            });
        }

        if (data.features) {
            if (data.features.readability_test) {
                this.features.readabilityTest = data.features.readability_test;
            }
            if (data.features.data_protection) {
                this.features.dataProtection = data.features.data_protection;
            }
            if (data.features.accessibility) {
                this.features.accessibility = data.features.accessibility;
            }
        }

        if (data.scan) {
            if (data.scan.scan_documents) {
                this.scan.scanDocuments = data.scan.scan_documents;
            }
            if (data.scan.login?.type) {
                this.scan.login = {
                    type: data.scan.login.type,
                };
            }
        }

    }
}
