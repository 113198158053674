<div class="report-container" *ngIf="report; else emptyReport">
    <div class="title-block col-xl-22 pl-0 pr-0">
        <div class="icon">
                <mon-icon
                    class="text-link"
                    [size]="'lg'"
                    [monIconDirect]="report.template.name | reportCenterIcon"
                ></mon-icon>
        </div>
        <div class="title">
            {{ report.template.title | translateReportTitle }}
        </div>
    </div>
    <div class="col-xl-8 col-md-10">
        <mon-show-more-avatars *ngIf="isAdmin && report.users?.length" [users]="report.users!"></mon-show-more-avatars>
    </div>
    <div class="buttons-and-label col-xl-18">
        <div class="receiving-label">
            <div *ngIf="report.scheduled" class="text-link">
                {{ 'You are receiving this report' | translate }}
            </div>
        </div>
        <div class="buttons-block">
            <button
                class="btn btn-border-radius btn-default button emphasised-btn"
                *ngIf="!report.scheduled && report.template.schedulable"
                (click)="sendReport()"
                ngbTooltip="{{'Schedule this report' | translate}}"
                [attr.aria-label]="'Schedule this report' | translate"
                container="body"
            >
                <mon-icon [size]="'lg'" [icon]="['REPORT_CENTER', 'BUTTONS', 'SEND_REPORT']"></mon-icon>
            </button>
            <button
                *ngIf="report.template.schedulable && report.scheduled"
                class="btn btn-border-radius btn-default button"
                (click)="deleteReport()"
                ngbTooltip="{{'Unsubscribe from report' | translate}}"
                [attr.aria-label]="'Unsubscribe from report' | translate"
                container="body"
            >
                <mon-icon [size]="'lg'" class="text-link" [icon]="['REPORT_CENTER', 'BUTTONS', 'STOP_SENDING_REPORT']"></mon-icon>
            </button>
            <button
                (click)="openSendMeReport()"
                *ngIf="report.template.target_required"
                ngbTooltip="{{ 'Open report' | translate }}"
                [attr.aria-label]="'Open report' | translate"
                class="btn btn-border-radius btn-default button"
                container="body"
            >
                <mon-icon [size]="'lg'" class="text-link" [icon]="['REPORT_CENTER', 'BUTTONS', 'OPEN_REPORT']"></mon-icon>
            </button>
            <button
                *ngIf="!report.template.target_required"
                class="btn btn-border-radius btn-default button"
                (click)="sendMeReport()"
                ngbTooltip="{{ 'Receive report' | translate }}"
                [attr.aria-label]="'Receive report' | translate"
                container="body"
            >
                <mon-icon [size]="'lg'" class="text-link" [icon]="['REPORT_CENTER', 'BUTTONS', 'RECEIVE_REPORT']"></mon-icon>
            </button>
            <button
                *ngIf="report.template.schedulable && isAdmin"
                class="btn btn-border-radius btn-default button"
                (click)="openReportAdministration()"
                ngbTooltip="{{ 'Report administration' | translate }}"
                [attr.aria-label]="'Report administration' | translate"
                container="body"
            >
                <mon-icon [size]="'lg'" class="text-link" [icon]="['REPORT_CENTER', 'BUTTONS', 'REPORT_ADMINISTRATION']"></mon-icon>
            </button>
        </div>
    </div>
</div>
<ng-template #emptyReport>
    <div class="report-container" translate>
        <div class="title-block col-xl-22 col-md-16">
            <div class="title">
                Report is not available
            </div>
        </div>
    </div>
</ng-template>
