(function () {
    'use strict';
    angular.module('directives.monsido').directive('monValidateCssSelector', [
        function () {
            return {
                require: 'ngModel',
                link: function (scope, elm, attrs, ctrl) {
                    ctrl.$validators.cssSelector = function (modelValue, viewValue) {
                        if (ctrl.$isEmpty(modelValue)) {
                            // consider empty models to be valid
                            return true;
                        }

                        try {
                            document.querySelector(viewValue);
                            return true;
                        } catch (err) {
                            return false;
                        }
                    };
                },
            };
        },
    ]);
})();
