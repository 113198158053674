(function () {
    'use strict';

    angular.module('filters.monsido').filter('underscoreless', underscoreless);

    underscoreless.$inject = [];

    /* @ngInject */
    function underscoreless () {
        return function (input) {
            if (!input || typeof input !== 'string') {
                return input;
            }
            return input.replace(/_/g, ' ');
        };
    }
})();
