import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

interface RowInterface extends Record<string, unknown> {
    id: number;
}

@Injectable({
    providedIn: 'root',
})
export class MonNotificationsService {
    notifications = new BehaviorSubject<RowInterface[]>([]);
    notificationsFirst: Observable<unknown[]>;
    notificationPromise: number | null = null;
    callBack?: (params: Record<string, unknown>) => Promise<unknown>;
    params: Record<string, unknown> = {};
    doneList: Array<number | string> = [];
    prevNotifications: Array<RowInterface> = [];

    constructor () {
        this.notificationsFirst = this.notifications.pipe(first());
    }

    init (cb: (params: Record<string, unknown>) => Promise<unknown>, cbParams: Record<string, unknown>): void {
        this.callBack = cb;
        this.params = cbParams;
        this.getNotifications(true);
    }

    destroy (): void {
        this.cancelPromise();
        if (!this.notifications?.closed) {
            this.notifications.unsubscribe();
        }
    }

    getDoneCount (): number {
        return this.doneList.length;
    }

    reset (): void {
        this.cancelPromise();
        this.getNotifications(false);
    }

    resetDoneList (): void {
        this.doneList = [];
    }

    newDone (): boolean {
        let result = false;
        for (let i = 0; i < this.notifications.value.length; i++) {
            const row = this.notifications.value[i];
            let found = this.filter(row);
            if (found.length === 0) {
                result = true;
                this.addToDoneList(row);
            } else {
                found = this.filterStages(row);
                if (found.length) {
                    result = true;
                }
            }
        }
        return result;
    }

    isAnyRunning (): boolean {
        const result = this.notifications.value.filter((row) => {
            return row.stage !== 'done';
        });
        return !!result.length;
    }

    cancelPromise (): void {
        if (this.notificationPromise !== null) {
            clearInterval(this.notificationPromise);
            this.notificationPromise = null;
        }
    }

    private addToDoneList (row: RowInterface): void {
        if (!this.doneList[row.id]) {
            this.doneList.push(row.id);
        }
    }

    private filter (row: RowInterface): RowInterface[] {
        return this.prevNotifications.filter((prevRow) => {
            if (prevRow.id === row.id) {
                return prevRow;
            }
        });
    }

    private filterStages (row: RowInterface): RowInterface[] {
        return this.prevNotifications.filter((prevRow) => {
            if (prevRow.id === row.id && prevRow.stage !== row.stage && row.stage === 'done') {
                this.addToDoneList(prevRow);
                return prevRow;
            }
        });
    }

    private getNotifications (init = false): void {
        if (this.callBack) {
            this.callBack(this.params)
                .then((data: RowInterface[]) => {
                    if (init) {
                        this.prevNotifications = data;
                    } else {
                        this.prevNotifications = this.notifications.value;
                    }
                    this.notifications.next(data);
                }, () => undefined)
                .finally(() => {
                    this.cancelPromise();
                    this.notificationPromise = setInterval(() => {
                        this.getNotifications();
                    }, 30000) as unknown as number;
                });
        }
    }
}
