(function () {
    'use strict';

    angular.module('modules.roles').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.customer.admin.roles',
                config: {
                    url: '/roles',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                    resolve: {
                        activeFeature: activeFeature,
                    },
                },
            },
            {
                state: 'base.customer.admin.roles.index',
                config: {
                    url: '',
                    template: '<admin-roles></admin-roles>',
                },
            },
        ];
    }

    var activeFeature = [
        '$q',
        '$filter',
        '$state',
        'ng2MonUIRouterTransitionService',
        function ($q, $filter, $state, ng2MonUIRouterTransitionService) {
            var validate = $filter('activeFeature')('roles');
            if (!validate) {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard'),
                );
            }
            return $q.resolve(true);
        },
    ];
})();
