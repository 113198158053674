import { HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';

const NO_PARSE_SEARCH = new HttpContextToken<boolean>(() => false);
const NO_GLOBAL = new HttpContextToken<boolean>(() => false);
const NO_REDIRECT_ON_403 = new HttpContextToken<boolean>(() => false);

export const contextTokens = {
    NO_PARSE_SEARCH,
    NO_GLOBAL,
    NO_REDIRECT_ON_403,
} as const;

export type ContextTokensType = keyof typeof contextTokens;


@Injectable({
    providedIn: 'root',
})
export class RequestAuxiliaryService {

    constructor () { }

    setHttpContext (token: HttpContextToken<unknown>, context?: HttpContext): HttpContext | undefined {
        switch (token) {
            case NO_PARSE_SEARCH:
                return this.setNoParseSearch(context);
            case NO_GLOBAL:
                return this.setNoGlobal(context);
            case NO_REDIRECT_ON_403:
                return this.setNoRedirectOn403(context);
        }
    }

    hasNoGlobal (context: HttpContext): boolean {
        return context.get(NO_GLOBAL) === true;
    }

    hasNoParseSearch (context: HttpContext): boolean {
        return context.get(NO_PARSE_SEARCH) === true;
    }

    hasNoRedirectOn403 (context: HttpContext): boolean {
        return context.get(NO_REDIRECT_ON_403);
    }

    private setNoGlobal (context?: HttpContext): HttpContext {
        const currentContext = context ?? new HttpContext();
        return currentContext.set(NO_GLOBAL, true);
    }

    private setNoParseSearch (context?: HttpContext): HttpContext {
        const currentContext = context ?? new HttpContext();
        return currentContext.set(NO_PARSE_SEARCH, true);
    }

    private setNoRedirectOn403 (context?: HttpContext): HttpContext {
        const currentContext = context ?? new HttpContext();
        return currentContext.set(NO_REDIRECT_ON_403, true);
    }
}
