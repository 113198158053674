import { Ng2StateDeclaration, StateService } from '@uirouter/angular';
import { AdvancedAnalyticsDefaultComponent } from './advanced-analytics-default/advanced-analytics-default.component';
import { routesHelper } from '@client/app/services/routes-helper/routes.helper';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonUIRouterTransitionService } from '@monsido/ng2/services/ui-router-transition-service/ui-router-transition-service';

export const ADVANCED_ANALYTICS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.customer.domain.advanced_analytics',
        url: '/advanced-analytics',
        views: {
            '^.^.^.$default': {
                component: AdvancedAnalyticsDefaultComponent,
            },
        },
        resolve: [
            routesHelper.makeActiveFeatureResolver('advancedAnalyticsResolveFn', 'advanced_analytics'),
            {
                token: 'advancedAnalyticsUtlResolveFn',
                deps: [
                    SessionService,
                    MonUIRouterTransitionService,
                    StateService,
                ],
                resolveFn: async (sessionService: SessionService, transitionService: MonUIRouterTransitionService, state: StateService): Promise<void> => {
                    if (sessionService.domain?.features.advanced_analytics_url) {
                        const domainId = sessionService.domain?.id;
                        const customerId = sessionService.customer?.id;
                        await (new Promise((resolve) => {
                            transitionService.onTransitionFinished(() => {
                                state.go('base.customer.domain.dashboard', {
                                    domainId,
                                    customerId,
                                });
                                resolve(undefined);
                            });
                        }));
                    }
                },
            },
        ],
    },
];
