import { Injectable } from '@angular/core';

type ChartOptionType = { legend?: Record<string, unknown> };
type LegendEventType = { target: { style: { cursor: string }}};

@Injectable({
    providedIn: 'root',
})
export class LegendCursorService {
    constructor () { }

    setHoverState (options: ChartOptionType): ChartOptionType {
        const newOptions = options || {
            legend: {},
        };

        if (newOptions.legend) {
            newOptions.legend.onHover = (event: LegendEventType): void => {
                event.target.style.cursor = 'pointer';
            };

            newOptions.legend.onLeave = (event: LegendEventType): void => {
                event.target.style.cursor = 'default';
            };
        }
        return newOptions;
    }
}
