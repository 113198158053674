import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.policy').component('pagesPolicyDialogLinks', {
        templateUrl: 'app/pages/policy/dialogs/links/links.html',
        controller: PolicyController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
            pageCount: '<',
        },
    });

    PolicyController.$inject = ['ng2PolicyRepoService', '$controller', 'ng2MonEventsService', 'gettextCatalog'];
    /* @ngInject */
    function PolicyController (ng2PolicyRepoService, $controller, ng2MonEventsService, gettextCatalog) {
        var vm = this;

        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.openPagesDialog = openPagesDialog;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = null;
            vm.links = [];
            vm.currentSort = { by: 'html_pages_count', direction: 'desc' };
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'policy_links',
                        category_ref: vm.policy.id,
                    },
                },
                {
                    name: gettextCatalog.getString('Start advance export'),
                    data: {
                        category: 'advanced_policy_links',
                        category_ref: vm.policy.id,
                    },
                },
            ];
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search || null,
            };

            vm.progress = ng2PolicyRepoService.domainGetPolicyLinks(vm.policy.id, params).then(function (links) {
                vm.links = links;
            }, angular.noop);
        }

        function openPagesDialog (link) {
            var params = {
                body: 'pagesPolicyDialogLinksPages',
                size: 'md',
                data: { link: link, policy: vm.policy },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
