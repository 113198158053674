(function () {
    'use strict';

    angular.module('app.forms').component('formCustomerInfo', {
        templateUrl: 'app/forms/customer-info/customer-info.html',
        controller: EditController,
        controllerAs: 'vm',
        bindings: {
            customer: '<',
            monDialogId: '@?',
            useLegacy: '<?',
        },
    });

    EditController.$inject = ['monDialog', 'accountService'];
    /* @ngInject */
    function EditController (monDialog, accountService) {
        var vm = this;

        vm.submit = submit;

        function submit () {
            vm.saving = true;
            accountService
                .update(vm.customer)
                .then(function (data) {
                    vm.customer = data;
                    monDialog.close(vm.monDialogId, data);
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }
    }
})();
