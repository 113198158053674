import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

export type SummaryRepoParamsType = RequestParamsType & BaseRepoRequestType;

@Injectable({
    providedIn: 'root',
})
export class DataPrivacySummaryRepo extends BaseRepo {
    constructor ( private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    getSummary (domainId: number, params: SummaryRepoParamsType = {}): Promise<unknown> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${domainId}/data_protection/summary`, params);
    }
}
