(function () {
    'use strict';
    angular.module('pages.policy').component('pagesPolicyDialogLinksPages', {
        templateUrl: 'app/pages/policy/dialogs/links/pages/pages.html',
        controller: PolicyDialogLinksPagesController,
        controllerAs: 'vm',
        bindings: {
            link: '<',
            policy: '<',
        },
    });

    PolicyDialogLinksPagesController.$inject = [
        'ng2LinkPagesRepoService',
        '$controller',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'gettextCatalog',
        'ng2CheckForDuplicatesService',
        'ng2ActiveFeatureService',
    ];

    function PolicyDialogLinksPagesController (
        ng2LinkPagesRepoService,
        $controller,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        gettextCatalog,
        ng2CheckForDuplicatesService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.onFiltersChanged = onFiltersChanged;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.search = null;
            vm.isFiltersOpen = false;
            vm.subHeader = '<a ng-href="' + vm.link.url + '" target="_blank" aria-describedby="linkOpensInNewTab">' + vm.link.url + '</a>';
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'pages_with_specific_link',
                        category_ref: vm.link.id,
                    },
                },
            ];
            getPage();
            vm.getLabels();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                link_id: vm.link.id,
                search: vm.search || null,
            };

            params = vm.updateParamsWithFilters(params);

            vm.progress = ng2LinkPagesRepoService.get(params).then(function (data) {
                ng2CheckForDuplicatesService.checkForDuplicatePages(data);
                vm.pages = data;
            }, angular.noop);
        }

        function onFiltersChanged (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
