(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceDiagnosticsService
     * @description Page Performance Diagnostics Service
     */
    angular
        .module('modules.page-performance.page-details')
        .service('PagePerformanceDiagnosticsService', PagePerformanceDiagnosticsService);

    PagePerformanceDiagnosticsService.$inject = [
        'PAGE_PERFORMANCE_CATEGORY',
        'PagePerformancePageDetailsService',
    ];

    /* @ngInject*/
    function PagePerformanceDiagnosticsService (
        PAGE_PERFORMANCE_CATEGORY,
        PagePerformancePageDetailsService,
    ) {
        return {
            getAudits: getAudits,
        };

        /**
         * @memberOf PagePerformanceDiagnosticsService
         * @desc Get Audits Collection
         * @return {Array}
         */
        function getAudits () {
            const report = getReport();
            if (report) {
                const auditRefIds = PagePerformancePageDetailsService.getUnassignedAuditIds(report.categories[PAGE_PERFORMANCE_CATEGORY.PERFORMANCE].auditRefs);

                return Object.values(report.audits).filter((audit) => {
                    return audit.details?.type !== 'opportunity' && auditRefIds.indexOf(audit.id) > -1;
                });
            }

            return [];
        }

        /**
         * @memberOf PagePerformanceDiagnosticsService
         * @desc Get the entire provided Report of a Page
         */
        function getReport () {
            return PagePerformancePageDetailsService.getReport();
        }
    }
})();
