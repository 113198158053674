(function () {
    'use strict';

    function PrivacyFastTrackInfoTypeModel (attributes) {
        angular.extend(this, attributes);
    }

    angular.module('modules.data_privacy.services.api').service('DataPrivacyFastTrackRepo', DataPrivacyFastTrackRepo);

    DataPrivacyFastTrackRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'ng2PrivacyFastTrackModelFactoryService'];
    /* @ngInject */
    function DataPrivacyFastTrackRepo (ng2ApiService, ng2SessionService, ng2PrivacyFastTrackModelFactoryService) {
        return {
            getAll: getAll,
            get: get,
            getInfoTypes: getInfoTypes,
            updateInfoType: updateInfoType,
        };

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }

            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/data_protection/fast_track', params, config).then(toModels);
        }

        function get (dpContentId, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }

            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/data_protection/fast_track/' + dpContentId,
                params,
                config,
            ).then(toModel);
        }

        function getInfoTypes (dpContentId, params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }

            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/data_protection/fast_track/' + dpContentId + '/info_types',
                params,
                config,
            ).then(infoTypesToModels);
        }

        function updateInfoType (dpContentId, infoType, params, config) {
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }

            return ng2ApiService.patch(
                'domains/' +
                    ng2SessionService.getDomainId(params) +
                    '/data_protection/fast_track/' +
                    dpContentId +
                    '/info_types/' +
                    infoType.id,
                infoType,
                params,
                config,
            ).then(toModel);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }

            return models;
        }

        function toModel (model) {
            return ng2PrivacyFastTrackModelFactoryService.getModel(model);
        }

        function infoTypesToModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = infoTypesToModel(models[i]);
            }

            return models;
        }

        function infoTypesToModel (model) {
            return new PrivacyFastTrackInfoTypeModel(model);
        }

    }
})();
