(function () {
    'use strict';

    angular.module('modules.cookie_banner', [
        'modules.cookie_banner.components',
        'modules.cookie_banner.services',
        'modules.cookie_banner.forms',
        'modules.cookie_banner.pages',
    ]);
})();
