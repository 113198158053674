import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconsModule, MonEventTriggerModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import ExportDialogComponent from './export-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MonEventTriggerModule,
        IconsModule,
    ],
    exports: [ExportDialogComponent],
    declarations: [ExportDialogComponent],
})
export class ExportDialogModule {}
