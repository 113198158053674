(function () {
    'use strict';

    angular.module('filters.monsido').filter('activeRegion', activeRegion);

    activeRegion.$inject = ['ActiveRegionService'];
    /* @ngInject */
    function activeRegion (ActiveRegionService) {
        return filter;

        // //////////

        function filter (region) {
            return ActiveRegionService.isInRegion(region);
        }
    }
})();
