import { NgModule } from '@angular/core';
import { OauthModule } from '@monsido/oauth/oauth.module';
import { SessionService } from '@monsido/core/session/session.service';
import { CoreAgreementService } from '@monsido/core/session/services/agreement/agreement.service';

@NgModule({
    imports: [OauthModule],
    exports: [],
    declarations: [],
    providers: [SessionService, CoreAgreementService],
})
export class SessionModule {}
