(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionPolicies', {
        templateUrl: 'app/components/pagedetails/details/sections/policies/policies.html',
        controller: SectionPoliciesController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            active: '<',
            refreshPage: '&',
        },
    });

    SectionPoliciesController.$inject = ['ng2PagePolicyRepoService', 'ng2PolicyRepoService', '$q', '$timeout', '$scope'];
    /* @ngInject */
    function SectionPoliciesController (ng2PagePolicyRepoService, ng2PolicyRepoService, $q, $timeout, $scope) {
        var vm = this;
        vm.matchLimit = 100;

        vm.$onInit = activate;
        vm.$onDestroy = destroyTimer;
        vm.selectPolicy = selectPolicy;
        vm.getPage = getPage;
        vm.onTabChange = onTabChange;
        vm.refreshPolicies = refreshPolicies;
        vm.onFilterChange = onFilterChange;

        function activate () {
            vm.policyMode = null;
            vm.policyScheduled = null;
            vm.policy = vm.active || null;
            refreshPolicies();
            setupWatchers();
            vm.timerPromise = true;
        }

        function setupWatchers () {
            $scope.$on('$destroy', function () {
                // $onDestroy is not triggered when the dialog closes.
                destroyTimer();
            });
        }

        function refreshPolicies (refreshPage) {
            getPage();
            getPolicyCounts();
            if (typeof refreshPage === 'boolean' && refreshPage === true) {
                vm.refreshPage({ target: 'page-details-section-policies' });
            }
        }

        function getPage () {
            vm.progress = getCollectedPolicies();
        }

        function getPolicyCounts () {
            var promises = [];
            promises.push(getDomainPolicies(null, null));
            promises.push(getPagePolicies(null, null));

            $q.all(promises).then(function (values) {
                vm.pagePolicyCount = values[1].filter(function (policy) {
                    return !policy.ignored;
                }).length;
                var count = values[0];
                vm.domainPolicies = Array.isArray(count) ? count.length : 0;
            }, angular.noop);
        }

        function getCollectedPolicies () {
            var promises = [];
            promises.push(getDomainPolicies());
            promises.push(getPagePolicies());
            return $q.all(promises).then(function (values) {
                vm.policies = mergePageWithDomainPolicies(getUniquePolicies(values[1]), values[0]).sort(function (a, b) {
                    // Sort policies
                    if (a.ignored !== b.ignored) {
                        return a.ignored === true ? 1 : -1;
                    }
                    if (a.state === 'running' || b.state === 'running') {
                        if (a.state === 'running' && b.state !== 'running') {
                            return -1;
                        }

                        if (a.state !== 'running' && b.state === 'running') {
                            return 1;
                        }

                        return 0;
                    }
                    return a.matches_count > b.matches_count ? -1 : b.matches_count > a.matches_count ? 1 : 0;
                });

                selectPolicy(vm.policy);

                var autoReloadStarted = false;
                for (var i = 0; i < vm.policies.length; i++) {
                    if (vm.policies[i].state === 'running' && !autoReloadStarted && vm.timerPromise !== null) {
                        startAutoReload();
                        autoReloadStarted = true;
                    }
                }
                return values;
            }, angular.noop);
        }

        function getDomainPolicies (violation, scheduled) {
            var params = {
                page_size: 0,
                mode: violation !== undefined ? violation : vm.policyMode,
                scheduled: scheduled !== undefined ? scheduled : vm.policyScheduled,
            };
            return ng2PolicyRepoService.domainGetAll(params).then(function (policies) {
                return policies.map(function (policy) {
                    policy.matches_count = 0;
                    return policy;
                });
            });
        }

        function getPagePolicies (violation, scheduled) {
            var params = {
                page_size: 0,
                mode: violation !== undefined ? violation : vm.policyMode,
                scheduled: scheduled !== undefined ? scheduled : vm.policyScheduled,
            };

            return ng2PagePolicyRepoService.getAll(vm.page.id, params);
        }

        function getUniquePolicies (policies) {
            var policyIds = [];
            return policies.filter(function (policy) {
                if (policyIds.indexOf(policy.id) === -1) {
                    policyIds.push(policy.id);
                    return true;
                }
                return false;
            });
        }

        function mergePageWithDomainPolicies (pagePolicies, domainPolicies) {
            var policyIds = pagePolicies.map(function (policy) {
                return policy.id;
            });
            return pagePolicies.concat(
                domainPolicies.filter(function (policy) {
                    return policyIds.indexOf(policy.id) === -1;
                }),
            );
        }

        function selectPolicy (policy) {
            resetMatchLimit();
            if (vm.policies.length > 0) {
                if (policy) {
                    var foundPolicy = vm.policies.find(function (p) {
                        return p.id === policy.id;
                    });
                    vm.policy = foundPolicy ? foundPolicy : vm.policies[0];
                } else {
                    vm.policy = vm.policies[0];
                }
            }
        }

        function onTabChange (tab) {
            switch (tab) {
                case 'unwanted':
                    vm.policyMode = 'violation';
                    break;
                case 'search':
                    vm.policyMode = 'search';
                    break;
                case 'required':
                    vm.policyMode = 'required';
                    break;
                default:
                    vm.policyMode = null;
                    break;
            }
            getPage();
        }

        function onFilterChange (changes) {
            if (changes.scheduled && changes.scheduled.length > 0) {
                vm.policyScheduled = changes.scheduled[changes.scheduled.length - 1].value;
            } else {
                vm.policyScheduled = null;
            }
            getPage();
        }

        function destroyTimer () {
            if (typeof vm.timerPromise === 'object') {
                $timeout.cancel(vm.timerPromise);
            }
            vm.timerPromise = null;
        }

        function startAutoReload () {
            vm.timerPromise = $timeout(function () {
                getCollectedPolicies();
            }, 30000);
        }

        function resetMatchLimit () {
            vm.matchLimit = 100;
        }
    }
})();
