import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Observable } from 'rxjs';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { RequestOptionsType, RequestParamsType } from '@monsido/ng2/external/http/options';

type ExportRequestType = RequestParamsType & BaseRepoRequestType;

@Injectable({ providedIn: 'root' })
export class ExportService extends BaseRepo {

    constructor (private client: ApiClient, protected sessionService: SessionService) {
        super();
    }

    public create (data: unknown): Observable<unknown> {
        return this.client.postObservable('exports', data);
    }

    public get (exportId: number, params: ExportRequestType = {}): Observable<unknown> {
        params = this.setGroupId(params);
        const options: RequestOptionsType = {
            params,
        };
        return this.client.getObservable('exports/' + exportId, options);
    }

    public getExports (params: ExportRequestType = {}): Observable<unknown> {
        params = this.setGroupId(params);
        const options: RequestOptionsType = {
            params,
        };
        return this.client.getObservable('exports', options);
    }
}
