(function () {
    'use strict';

    angular.module('modules.cookie_banner.components').component('cookieAcceptanceRate', {
        templateUrl: 'app/modules/cookie-banner/components/cookie-acceptance-rate/cookie-acceptance-rate.html',
        controller: CookieTypeAcceptanceRate,
        controllerAs: 'vm',
        bindings: {
            percentage: '<',
            name: '@',
        },
    });

    CookieTypeAcceptanceRate.$inject = [];
    /* @ngInject */
    function CookieTypeAcceptanceRate () {
        var vm = this;
        vm.setNameForBarClass = setNameForBarClass;

        function setNameForBarClass (name) {
            return name.toLowerCase();
        }
    }
})();
