(function () {
    angular.module('core.config').config(config);

    config.$inject = ['envServiceProvider'];

    function config (envServiceProvider) {
        envServiceProvider.config({
            domains: {
                testing: ['localhost:8001'],
                development: ['localhost:3001'],
            },
        });
        envServiceProvider.check();
    }
})();
