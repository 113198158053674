import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ColorHelperService } from 'app/modules/tools/services/color-contrast/color-helper.service';
import { debounce } from 'lodash';
import { timer, Subscription } from 'rxjs';
import { skipUntil } from 'rxjs/operators';

import { PaOptionsInterface } from './pa-options.interface';
import { PaOptionsService } from './pa-options.service';
import { PaScriptSettingsOptionsInterface } from './pa-script-settings-options.interface';
import { PaScriptSettingsModel } from './pa-script-settings.model';
import { isDesignTouched, restoreDesignSettings } from './pa-script-settings.utils';
import { PAGE_ASSIST_SCRIPT_CONSTANT } from './pa-script.constant';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Component({
    selector: 'pa-script-settings',
    templateUrl: 'pa-script-settings.html',
    styleUrls: ['pa-script-settings.scss'],
})
export class PAScriptSettingsComponent implements OnDestroy {

    @Input() paSettings?: PaScriptSettingsOptionsInterface;

    @ViewChild('pageAssistSettingsForm', { static: true }) pageAssistSettingsForm?: NgForm;
    @ViewChild('page_assist_upload_logo_file') fileInput?: ElementRef<HTMLButtonElement>;
    @ViewChild('dialogBody') dialogBody?: ElementRef<HTMLElement>;

    calculateMainDarkRatio: () => void = () => {};

    options?: PaOptionsInterface;
    dataModel?: PaScriptSettingsOptionsInterface;
    PAGE_ASSIST_SCRIPT_CONSTANT = PAGE_ASSIST_SCRIPT_CONSTANT;

    mainColorRatio: number | null = null;
    mainDarkColorRatio: number | null = null;
    linkColorRatio: number | null = null;
    linkColorDarkRatio: number | null = null;
    buttonHoverColorRatio: number | null = null;
    buttonHoverDarkColorRatio: number | null = null;

    loadingLogo = false;
    isLogoURLValid = true;
    logoUrlCandidate = '';

    validAccessibilityStatementURL = true;

    switches: Record<string, boolean> = {};

    availableDefaultLanguages: Array<{ name: string, value: string }> = [];

    designTouched: boolean = false;

    highlightMessage: boolean = false;
    highlightUpload: boolean = false;
    highlightTitle: boolean = false;
    highlightText: boolean = false;

    private isDesignTouched: () => void = () => {};
    private updateGeneralSetting: () => void = () => {};
    private formChangesSubscription?: Subscription;
    private eventServiceSubscribeId: number = -1;

    constructor (
        private paOptionsService: PaOptionsService,
        private colorService: ColorHelperService,
        private translateService: TranslateService,
        private monPromptService: MonPromptService,
        private eventService: MonEventService,
    ) {

        this.isDesignTouched = debounce(() => {
            if (this.dataModel) {
                this.designTouched = isDesignTouched(this.dataModel);
            }
        }, 10);

        this.updateGeneralSetting = debounce(() => {
            this.eventService.run('updatePaSettings', this.dataModel);
        }, 10);

        // eslint-disable-next-line
        // @ts-ignore
        this.eventServiceSubscribeId = this.eventService.addListener('setupPaSettings', (options: PaScriptSettingsOptionsInterface) => {
            this.init(options);
        });
    }

    ngOnDestroy (): void {
        if (this.formChangesSubscription) {
            this.formChangesSubscription.unsubscribe();
        }
        if (this.eventServiceSubscribeId) {
            this.eventService.remove(this.eventServiceSubscribeId);
        }
    }

    updateMainColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.mainColor = value;
            this.updaitMainColorRatio();
        }
    }

    updateMainDarkColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.mainDarkColor = value;
            this.updaitMainDarkColorRatio();
        }
    }

    updateTextColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.textColor = value;
            this.updaitMainColorRatio();
        }
    }

    updateTextDarkColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.textDarkColor = value;
            this.updaitMainDarkColorRatio();
        }
    }

    updateLinkColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.linkColor = value;
            this.updaitLinkColorRatio();
        }
    }

    updateLinkColorDark (value: string): void {
        if (this.dataModel) {
            this.dataModel.linkColorDark = value;
            this.updateLinkColorDarkRatio();
        }
    }

    updateButtonHoverColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.buttonHoverColor = value;
            this.updateButtonHoverColorRatio();
        }
    }

    updateButtonHoverDarkColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.buttonHoverDarkColor = value;
            this.updateButtonHoverColorDarkRatio();
        }
    }

    updateMainButtonBorderColor (value: string): void {
        if (this.dataModel) {
            this.dataModel.mainButtonBorderColor = value;
        }
    }

    setSidePixels (): void {
        let coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.LEFTTOP;

        if (this.dataModel) {
            switch (this.dataModel.direction) {
                case PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.LEFT:
                    coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.LEFT;
                    break;
                case PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.TOPCENTER:
                    coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.CENTERTOP;
                    break;
                case PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.TOPRIGHT:
                    coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.RIGHTTOP;
                    break;
                case PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.BOTTOMLEFT:
                    coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.LEFTBOTTOM;
                    break;
                case PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.RIGHT:
                    coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.RIGHT;
                    break;
                case PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.BOTTOMCENTER:
                    coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.CENTERBOTTOM;
                    break;
                case PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.BOTTOMRIGHT:
                    coordinates = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.RIGHTBOTTOM;
                    break;

            }
        }

        this.setCoordinates(coordinates);
    }

    removeLogoImage (): void {
        if (this.dataModel) {
            this.dataModel.logo_picture_base64 = '';
            this.dataModel.logo_picture_url = '';
        }
        if (this.fileInput) {
            this.fileInput.nativeElement.value = '';
        }
    }

    confirmLogoUrl (): void {
        this.isLogoURLValid = this.isValidUrl(this.logoUrlCandidate);
        if (this.isLogoURLValid) {
            if (this.dataModel) {
                this.dataModel.logo_picture_url = this.logoUrlCandidate;
                this.dataModel.logo_picture_base64 = '';
            }
            this.isDesignTouched();
        }
    }

    uploadLogoFile (e: Event): void {
        const files = (e.target as HTMLInputElement).files;
        const file = files && files[0];

        if (!file) {
            return;
        }
        const mb = file.size / 1024 / 1024;
        if (mb > 1) {
            this.loadingLogo = false;
            this.monPromptService.alert(this.translateService.getString('File must be below 1MB'));
            return;
        }
        this.removeLogoImage();
        this.loadingLogo = true;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
            this.loadingLogo = false;
            if (this.dataModel) {
                this.dataModel.logo_picture_base64 = reader.result as string;
                this.dataModel.logo_picture_url = '';
            }
            this.logoUrlCandidate = '';
        };
    }

    onLogoPictureChange (): void {
        this.isLogoURLValid = !this.logoUrlCandidate || this.isValidUrl(this.logoUrlCandidate);
    }

    logoUploadBrowse (e: Event): void {
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
        (e.target as HTMLInputElement).focus();
    }

    isValidUrl (url): boolean {
        try {
            new URL(url);
        } catch {
            return false;
        }

        return true;
    }

    onIncludedLanguagesChange (): void {
        // Need timeout for Defaultlanguage select to update values
        setTimeout(() => {
            if (this.options && this.dataModel) {
                this.availableDefaultLanguages = this.options.languages.filter((language) => {
                    return this.dataModel?.included_languages.includes(language.value);
                });
            }
        });
    }

    trimCustomInfo (): void {
        let newValue = '';

        if (this.dataModel?.customInformationText) {
            newValue = this.dataModel.customInformationText;
        }

        setTimeout(() => {
            if (this.dataModel) {
                this.dataModel.customInformationText = newValue.replace(/\s+/g, ' ');
            }
        });
    }

    addLink (): void {
        if (this.dataModel) {
            this.dataModel.customInformationText = this.dataModel.customInformationText + '[INSERT_LINK_TEXT](INSERT_LINK_URL)';
        }
    }

    resetDesignProperties (): void {
        if (this.dataModel) {
            this.dataModel = restoreDesignSettings(this.dataModel);
        }
        this.logoUrlCandidate = '';
        this.updaitMainColorRatio();
        this.updaitMainDarkColorRatio();
        this.updaitLinkColorRatio();
        this.updateLinkColorDarkRatio();
        this.updateButtonHoverColorRatio();
        this.updateButtonHoverColorDarkRatio();
        this.designTouched = false;
    }

    submit (form: NgForm, e: Event): void {
        e.preventDefault();
        e.stopImmediatePropagation();

        if (form.valid) {
            this.eventService.run('closePaSettingsDialog');
        } else {
            const invalidInput = this.dialogBody?.nativeElement.querySelector('.ng-invalid');
            if (invalidInput) {
                if (invalidInput.getAttribute('type') === 'hidden') {
                    invalidInput.previousElementSibling?.scrollIntoView(true);
                } else {
                    invalidInput.scrollIntoView(true);
                }

            }
        }
    }

    private init (modelOptions: PaScriptSettingsOptionsInterface): void {
        modelOptions = modelOptions || {} as PaScriptSettingsOptionsInterface;
        this.dataModel = new PaScriptSettingsModel(modelOptions);
        this.options = this.paOptionsService.options;

        this.logoUrlCandidate = this.dataModel.logo_picture_url;

        this.updaitMainColorRatio();
        this.updaitMainDarkColorRatio();
        this.updaitLinkColorRatio();
        this.updateLinkColorDarkRatio();
        this.updateButtonHoverColorRatio();
        this.updateButtonHoverColorDarkRatio();
        this.onIncludedLanguagesChange();

        if (this.pageAssistSettingsForm) {
            this.formChangesSubscription = this.pageAssistSettingsForm.form.valueChanges.pipe(skipUntil(timer(0))).subscribe(() => {
                if (this.pageAssistSettingsForm) {
                    this.validateCustomFields(this.pageAssistSettingsForm.form.controls);
                    this.isDesignTouched();
                    this.eventService.run('paSettingsFormValidStatus', this.pageAssistSettingsForm.form.valid);
                    if (this.pageAssistSettingsForm.form.valid) {
                        this.updateGeneralSetting();
                    }
                }
            });
        }

        this.isDesignTouched();
    }

    private setCoordinates (coordinates: string): void {
        const [top, right, bottom, left] = coordinates.split(' ') || [];
        if (this.dataModel) {
            this.dataModel.pixelsOnTop = top || '0';
            this.dataModel.pixelsOnRight = right || '0';
            this.dataModel.pixelsOnBottom = bottom || '0';
            this.dataModel.pixelsOnLeft = left || '0';
        }
    }

    private updaitMainColorRatio (): void {
        if (this.dataModel) {
            this.mainColorRatio = this.colorService.hexContrast(this.dataModel.mainColor, this.dataModel.textColor);
        }
    }

    private updaitMainDarkColorRatio (): void {
        if (this.dataModel) {
            this.mainDarkColorRatio = this.colorService.hexContrast(this.dataModel.mainDarkColor, this.dataModel.textDarkColor);
        }
    }

    private updaitLinkColorRatio (): void {
        if (this.dataModel) {
            this.linkColorRatio = this.colorService.hexContrast(
                this.dataModel.linkColor,
                PAGE_ASSIST_SCRIPT_CONSTANT.LINK.LIGHT_BACKGROUND,
            );
        }
    }

    private updateLinkColorDarkRatio (): void {
        if (this.dataModel) {
            this.linkColorDarkRatio = this.colorService.hexContrast(
                this.dataModel.linkColorDark,
                PAGE_ASSIST_SCRIPT_CONSTANT.LINK.DARK_BACKGROUND,
            );
        }
    }

    private updateButtonHoverColorRatio (): void {
        if (this.dataModel) {
            this.buttonHoverColorRatio = this.colorService.hexContrast(
                this.dataModel.buttonHoverColor,
                PAGE_ASSIST_SCRIPT_CONSTANT.BUTTON_HOVER_BACKGROUND_COLOR.LIGHT,
            );
        }
    }

    private updateButtonHoverColorDarkRatio (): void {
        if (this.dataModel) {
            this.buttonHoverDarkColorRatio = this.colorService.hexContrast(
                this.dataModel.buttonHoverDarkColor,
                PAGE_ASSIST_SCRIPT_CONSTANT.BUTTON_HOVER_BACKGROUND_COLOR.DARK,
            );
        }
    }

    private validateCustomFields (controls: Record<string, AbstractControl>): void {
        this.toggleFieldValidationError('mainColor', controls, this.mainColorRatio ? this.mainColorRatio < 4 : true);
        this.toggleFieldValidationError('linkColor', controls, this.linkColorRatio ? this.linkColorRatio < 4 : true);
        this.toggleFieldValidationError('buttonHoverColor', controls, this.buttonHoverColorRatio ? this.buttonHoverColorRatio < 4 : true);
        this.toggleFieldValidationError('mainDarkColor', controls, this.mainDarkColorRatio ? this.mainDarkColorRatio < 4 : true);
        this.toggleFieldValidationError('linkColorDark', controls, this.linkColorDarkRatio ? this.linkColorDarkRatio < 4 : true);
        this.toggleFieldValidationError('buttonHoverDarkColor', controls,
            this.buttonHoverDarkColorRatio ? this.buttonHoverDarkColorRatio < 4 : true);
    }

    private toggleFieldValidationError (name: string, controls: Record<string, AbstractControl>, isTrue: boolean): void {
        if (controls[name]) {
            if (isTrue) {
                controls[name].setErrors({ 'incorrect': true });
            } else {
                controls[name].setErrors(null);
            }
        }
    }

}
