import { NgModule } from '@angular/core';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ActiveFeatureService],
})
export class ActiveFeatureModule {}
