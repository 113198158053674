import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule, LayoutModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ScheduledReportComponent } from './scheduled-report.component';
import { ShowMoreAvatarsModule } from '../show-more-avatars/show-more-avatars.module';
import { IconsModule } from '@layout/icons/icons.module';
import { ReportCenterPipesModule } from '../../pipes/report-center-pipes.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        ScheduledReportComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TableModule,
        IconsModule,
        LayoutModule,
        ReportCenterPipesModule,
        ShowMoreAvatarsModule,
    ],
    exports: [
        ScheduledReportComponent,
    ],
})
export class ScheduledReportModule {}
