import { Injectable } from '@angular/core';
import { default as demoPdfData } from './demo-pdf.data.json';

export type DemoPdfClaritySummaryType = {
    checks: number,
    pdfs: number,
    pdfs_with_issues: number,
    pdfs_with_issues_by_result_type: Record<string, unknown>,
    checks_with_issues: number,
    checks_with_issues_by_result_type: Record<string, unknown>,
    queued_count: number,
    tokens: number,
};

export type DemoPdfClarityCheckType = {
    id: number,
    name: string,
    errors_count: number,
};

@Injectable({
    providedIn: 'root',
})
export class DemoPdfClarityRepoService {

    private summary: DemoPdfClaritySummaryType = {
        checks: 45,
        pdfs: 1682,
        pdfs_with_issues: 0,
        pdfs_with_issues_by_result_type: {},
        checks_with_issues: 0,
        checks_with_issues_by_result_type: {},
        queued_count: 2,
        tokens: 0,
    };

    private checks: DemoPdfClarityCheckType[] = demoPdfData;

    constructor () {}

    getAllChecks (): Promise<DemoPdfClarityCheckType[]> {
        return this.getData('getAllChecks');
    }

    getSummary (): Promise<DemoPdfClaritySummaryType> {
        return this.getData('summary');
    }

    private getData (fnName: 'getAllChecks'): Promise<DemoPdfClarityCheckType[]>
    private getData (fnName: 'summary'): Promise<DemoPdfClaritySummaryType>
    private getData (fnName: 'getAllChecks' | 'summary'): Promise<DemoPdfClarityCheckType[] | DemoPdfClaritySummaryType> {
        const timer = Math.round(1000 * Math.random());

        return new Promise<DemoPdfClarityCheckType[] | DemoPdfClaritySummaryType>((resolve) => {
            setTimeout(() => {
                switch (fnName) {
                    case 'summary':
                        resolve({
                            ...this.summary,
                        } as DemoPdfClaritySummaryType);
                        break;
                    case 'getAllChecks':
                        resolve([
                            ...this.checks,
                        ]);
                        break;
                    default:
                        resolve([]);
                }
            }, timer);
        });


    }
}
