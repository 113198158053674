(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTableQa', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/qa/qa.html',
        controller: QAController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
        },
    });

    QAController.$inject = ['ng2ActiveFeatureService', 'exporter', 'historyPrepareService'];
    /* @ngInject */
    function QAController (ng2ActiveFeatureService, exporter, historyPrepareService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.$onDestroy = onDestroy;
        vm.historyOutput = [];
        vm.issuesStack = ['dead_links_count', 'dead_images_count', 'spelling_errors_confirmed_count'];
        vm.totalCount = 100;
        vm.allowVerticalAverage = false;

        function activate () {
            getQAErrorOptions();
            exporter.connectToCSVExporter('qa', function () {
                return vm.historyOutput;
            });
        }

        function onChanges (changes) {
            if (changes.history) {
                vm.allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
                vm.historyOutput = historyPrepareService.qa(vm.history);
            }
        }

        function onDestroy () {
            exporter.disconnectFromCSVExporter('qa');
        }

        function getQAErrorOptions () {
            vm.qaErrorOptions = [
                {
                    icon: 'fas fa-unlink',
                    attr: 'dead_links_count',
                    color: 'text-danger',
                },
                {
                    icon: 'fas fa-image',
                    attr: 'dead_images_count',
                    color: 'text-purple',
                },
                {
                    icon: 'fas fa-language',
                    attr: 'spelling_errors_confirmed_count',
                    color: 'text-warning',
                },
            ];
        }
    }
})();
