import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

export type NotificationType = {
    id: number;
    stage: string;
    action: string;
    target: string;
    target_id: number;
    scopes: {
        id: number;
        type: string;
    };
    payload: {
        link_url: string;
    }
    created_at: string;
    updated_at: string;
    done_at: string;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationRepoService {

    constructor (private apiService: ApiService) { }

    getAll (params: RequestParamsType): Promise<NotificationType[]> {
        if (!this.apiService) {
            return Promise.resolve([]);
        }
        return this.apiService.get('notifications', params);
    }
}
