(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuInventory', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', '$filter'];

    function SideMenuController (gettextCatalog, $filter) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'SUMMARY']),
                    href: 'base.customer.domain.inventory.dashboard',
                },
                {
                    title: gettextCatalog.getString('Content'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT']),
                    href: 'base.customer.domain.inventory.content.pages',
                    activeRoutes: [
                        'base.customer.domain.inventory.content.pages',
                        'base.customer.domain.inventory.content.documents',
                        'base.customer.domain.inventory.content.images',
                        'base.customer.domain.inventory.content.inlineimages',
                        'base.customer.domain.inventory.content.links',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('HTML Pages'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_HTML']),
                            href: 'base.customer.domain.inventory.content.pages',
                        },
                        {
                            title: gettextCatalog.getString('Documents'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_DOCUMENTS']),
                            href: 'base.customer.domain.inventory.content.documents',
                        },
                        //
                        {
                            title: gettextCatalog.getString('Images'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_IMAGES']),
                            href: 'base.customer.domain.inventory.content.images',
                        },
                        {
                            title: gettextCatalog.getString('Inline Images'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_INLINE_IMAGES']),
                            href: 'base.customer.domain.inventory.content.inlineimages',
                        },
                        {
                            title: gettextCatalog.getString('Links'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'CONTENT_LINKS']),
                            href: 'base.customer.domain.inventory.content.links',
                        },
                    ],
                },
                {
                    title: gettextCatalog.getString('Technical'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL']),
                    href: 'base.customer.domain.inventory.content.form',
                    activeRoutes: [
                        'base.customer.domain.inventory.content.form',
                        'base.customer.domain.inventory.content.headlink',
                        'base.customer.domain.inventory.content.iframe',
                        'base.customer.domain.inventory.content.frame',
                        'base.customer.domain.inventory.content.css',
                        'base.customer.domain.inventory.content.js',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Forms'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_FORM']),
                            href: 'base.customer.domain.inventory.content.form',
                        },
                        {
                            title: gettextCatalog.getString('Headlinks'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_HEADLINK']),
                            href: 'base.customer.domain.inventory.content.headlink',
                        },
                        {
                            title: gettextCatalog.getString('IFrames'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_IFRAME']),
                            href: 'base.customer.domain.inventory.content.iframe',
                        },
                        {
                            title: gettextCatalog.getString('Frames'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_FRAME']),
                            href: 'base.customer.domain.inventory.content.frame',
                        },
                        {
                            title: gettextCatalog.getString('CSS'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_CSS']),
                            href: 'base.customer.domain.inventory.content.css',
                        },
                        {
                            title: gettextCatalog.getString('JavaScript'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'TECHNICAL_JS']),
                            href: 'base.customer.domain.inventory.content.js',
                        },
                    ],
                },
                {
                    title: gettextCatalog.getString('Personal'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'PERSONAL']),
                    href: 'base.customer.domain.inventory.email',
                    activeRoutes: ['base.customer.domain.inventory.email'],
                    subs: [
                        {
                            title: gettextCatalog.getString('Email addresses'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'INVENTORY', 'PERSONAL_EMAIL']),
                            href: 'base.customer.domain.inventory.email',
                        },
                    ],
                },
            ];
        }
    }
})();
