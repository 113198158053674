(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.behavior').factory('behaviorService', behaviorService);

    behaviorService.$inject = ['intercomService'];
    /* @ngInject*/
    function behaviorService (intercomService) {
        return {
            userChangeAdmin: userChangeAdmin,
            addDomainGroup: addDomainGroup,
            updateDomainGroup: updateDomainGroup,
            openIntercom: openIntercom,
            addUser: addUser,
            deleteUser: deleteUser,
            createAdmin: createAdmin,
            addNewDomain: addNewDomain,
            addPolicy: addPolicy,
            updatePolicy: updatePolicy,
            openFasttrack: openFasttrack,
            fasttrackIgnore: fasttrackIgnore,
            fasttrackReview: fasttrackReview,
            remediationCreated: remediationCreated,
            remediationBtnClick: remediationBtnClick,
            remediationMultiBtnClick: remediationMultiBtnClick,
        };

        function updatePolicy (name) {
            intercomService.trackEvent('update-policy', { name: name });
        }

        function addPolicy (name) {
            intercomService.trackEvent('add-policy', { name: name });
        }

        function userChangeAdmin (email, customer_admin) {
            intercomService.trackEvent('update-user-admin', { email: email, customer_admin: customer_admin });
        }

        function updateDomainGroup (domain, group) {
            intercomService.trackEvent('update-domain-group', { domain: domain, group: group });
        }

        function addDomainGroup (domain, group) {
            intercomService.trackEvent('add-domain-group', { domain: domain, group: group });
        }

        function openIntercom () {
            intercomService.trackEvent('open-intercom-chat');
        }

        function addUser (email, customer_admin) {
            intercomService.trackEvent('add-user', { email: email, customer_admin: customer_admin });
        }

        function deleteUser (email) {
            intercomService.trackEvent('delete-user', { email: email });
        }

        function addNewDomain (url, title) {
            intercomService.trackEvent('add-domain', { url: url, title: title });
        }

        function createAdmin (email) {
            intercomService.trackEvent('add-admin', { email: email });
        }

        function openFasttrack () {
            intercomService.trackEvent('open-fasttrack');
        }

        function fasttrackIgnore (id) {
            intercomService.trackEvent('fasttrack-ignore', { check_id: id });
        }

        function fasttrackReview (id) {
            intercomService.trackEvent('fasttrack-review', { check_id: id });
        }

        function remediationBtnClick (url, title) {
            intercomService.trackEvent('remediation-button-click', { url: url, title: title });
        }

        function remediationCreated (url, title) {
            intercomService.trackEvent('remediation-created', { url: url, title: title });
        }

        function remediationMultiBtnClick (files) {
            intercomService.trackEvent('remediation-multi-button-click', { files: files });
        }
    }
})();
