(function () {
    'use strict';

    angular.module('models').factory('AccountModel', AccountModel);

    AccountModel.$inject = [];

    function AccountModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
            this.plan_traits.heatmaps_tracked_number_of_pages = this.plan_traits.heatmaps_tracked_number_of_pages || 10;
            this.country = this.country || 'US';
        }

        return Model;

        // /////////
    }
})();
