(function () {
    'use strict';

    angular.module('modules.data_privacy.components').component('pageDetailsDashboardChartsDataPrivacy', {
        templateUrl: 'app/modules/data-privacy/components/pagedetails/dashboard/chart/data-privacy.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onClickHandler: '&',
        },
    });

    DashboardController.$inject = ['DataPrivacyViolationRepo', '$filter', 'PAGE_DETAILS_SECTION_CONSTANT'];

    /* @ngInject */
    function DashboardController (DataPrivacyViolationRepo, $filter, PAGE_DETAILS_SECTION_CONSTANT) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.issues = {
                high: 0,
                medium: 0,
                low: 0,
            };
            vm.pageDetailsSectionPrivacy = PAGE_DETAILS_SECTION_CONSTANT.PRIVACY;
            setupDoughnutChart();
            getDataIssues();
        }

        function setupDoughnutChart () {
            var params = {
                page: 1,
                page_size: 0,
            };
            vm.doughnutLabels = ['', ''];
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            DataPrivacyViolationRepo.getAll(vm.page.id, params).then(function (data) {
                vm.totalDataIssues = data.total;
                vm.percentage = (vm.page.data_protection_violations_count / vm.totalDataIssues) * 100;
                vm.doughnutData = [
                    vm.totalDataIssues - vm.page.data_protection_violations_count,
                    vm.page.data_protection_violations_count,
                ];
                vm.doughnutOptions = {
                    centerText: {
                        percentageLabel: $filter('monNumber')((100 - vm.percentage) / 100, '0.[00] %'),
                    },
                    cutoutPercentage: 80,
                    tooltips: {
                        enabled: vm.totalDataIssues > 0,
                        callbacks: {
                            beforeLabel: function () {
                                return '';
                            },
                            label: function (tooltipItem, data) {
                                return $filter('numeraljs')(
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                    '0,0',
                                );
                            },
                        },
                    },
                };
            }, angular.noop);
        }

        function getDataIssues () {
            var params = {
                page: 1,
                page_size: 0,
                status: 'active',
            };
            vm.issues = {
                high: 0,
                medium: 0,
                low: 0,
            };
            DataPrivacyViolationRepo.getAll(vm.page.id, params).then(function (data) {
                data.forEach(function (entry) {
                    if (entry.info_type && entry.info_type.priority) {
                        vm.issues[entry.info_type.priority] += 1;
                    }
                });
            }, angular.noop);
        }
    }
})();
