(function () {
    'use strict';
    angular.module('filters.monsido').filter('fullUrl', FullUrlFilterController);

    FullUrlFilterController.$inject = [];
    /* @ngInject */

    /**
     * Transform a relative url into a an absoulte url
     * This is used for urls like /test
     *
     * @memberof filters.monsido
     * @ngdoc filter
     * @name fullUrl
     */
    function FullUrlFilterController () {
        return handle;

        // //////////

        function handle (url, domainUrl, pageUrl) {
            url = url || '';
            url = url.trim();

            if (domainUrl === null || angular.isUndefined(domainUrl) || url[0] !== '/') {
                if (url.match(/:\/\//)) {
                    return url;
                } else if (pageUrl === null || pageUrl === undefined) {
                    return url;
                } else {
                    var urlBase = pageUrl.substring(0, pageUrl.lastIndexOf('/'));
                    return urlBase + '/' + url;
                }
            }

            var hostRegex = new RegExp('^(https?://)([^/?#?]+)(?:[/?#]|$)', 'i');
            var matches = domainUrl.match(hostRegex);
            if (matches && matches.length >= 3) {
                url = matches[1] + matches[2] + url;
            }

            return url;
        }
    }
})();
