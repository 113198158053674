(function () {
    'use strict';

    angular.module('app.components.documentDetails').component('documentDetailsSectionAccessibilityInformation', {
        templateUrl: 'app/components/documentdetails/details/accessibility/information/information.html',
        controller: DocumentDetailsAccessibilityInformationController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
            errors: '<',
            onTabChange: '&',
            tab: '<',
            loading: '<',
            document: '<',
        },
    });

    DocumentDetailsAccessibilityInformationController.$inject = [
        'monDialog',
        'gettextCatalog',
        'AccessibilityClarityService',
    ];
    /* @ngInject */
    function DocumentDetailsAccessibilityInformationController (monDialog, gettextCatalog, AccessibilityClarityService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getIcon = getIcon;
        vm.getCheckIconContainer = getCheckIconContainer;
        vm.getCheckIcon = getCheckIcon;

        function activate () {
            vm.loadingErrors = false;
            setupTabs();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    value: null,
                },
                {
                    name: gettextCatalog.getString('Review'),
                    value: 'review',
                },
                {
                    name: gettextCatalog.getString('Warning'),
                    value: 'warning',
                },
                {
                    name: gettextCatalog.getString('Error'),
                    value: 'error',
                },
            ];
        }

        function getCheckIconContainer () {
            return AccessibilityClarityService.getDocumentIconContainer(vm.document, vm.check);
        }

        function getCheckIcon () {
            return AccessibilityClarityService.getDocumentIcon(vm.document, vm.check);
        }

        function getIcon (error) {
            switch (error.result_type) {
                case 'error':
                    return 'fas fa-bug';
                case 'review':
                    return 'fas fa-eye';
                case 'warning':
                    return 'fas fa-bell';
                default:
                    return '';
            }
        }
    }
})();
