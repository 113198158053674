import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.policy').component('pagesPolicy', {
        templateUrl: 'app/pages/policy/policy.html',
        controller: PolicyController,
        controllerAs: 'vm',
    });

    PolicyController.$inject = [
        'ng2PolicyRepoService',
        '$q',
        'ng2CrawlsRepoService',
        '$state',
        'ng2MonEventsService',
        'ng2SessionService',
        'ng2PageRepoService',
        'ng2LinksRepoService',
        'ChangesHistoryService',
        'FORM_CONSTANT',
        'ng2ActiveFeatureService',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject */
    function PolicyController (
        ng2PolicyRepoService,
        $q,
        ng2CrawlsRepoService,
        $state,
        ng2MonEventsService,
        ng2SessionService,
        ng2PageRepoService,
        ng2LinksRepoService,
        ChangesHistoryService,
        FORM_CONSTANT,
        ng2ActiveFeatureService,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.openPolicyForm = openPolicyForm;
        vm.getHistoryCenterParameters = getHistoryCenterParameters;

        function activate () {
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            var promises = [];
            promises.push(getPolicyPages());
            promises.push(getPages());
            promises.push(getLinks());
            promises.push(getPolicies());
            promises.push(getDomainHistory());

            vm.loading = true;
            vm.destroyed = false;
            vm.domain = ng2SessionService.domain;
            vm.policies = [];
            vm.progress = $q.all(promises).then(function ($values) {
                vm.loading = false;
                vm.pagesErrorsCount = parseInt($values[0].total);
                vm.pagesCount = parseInt($values[1].total);
                vm.linksCount = parseInt($values[2].total);
            }, angular.noop);
        }

        function onDestroy () {
            vm.destroyed = true;
        }

        function getPolicies () {
            var params = {
                page_size: 0,
            };

            return ng2PolicyRepoService.domainGetAll(params).then(function (policies) {
                vm.policies = policies.sort(function (a, b) {
                    return a.matches_count > b.matches_count ? -1 : a.matches_count < b.matches_count ? 1 : 0;
                });
            }, angular.noop);
        }

        function getPolicyPages () {
            var params = {
                page_size: 1,
                page: 1,
            };
            return ng2PolicyRepoService.domainGetPages(params);
        }

        function getPages () {
            var params = {
                page_size: 1,
                page: 1,
            };
            return ng2PageRepoService.getAll(params);
        }

        function getLinks () {
            var params = {
                page: 1,
                page_size: 1,
                page_fixed: false,
            };
            return ng2LinksRepoService.getAll(params);
        }

        function getDomainHistory () {
            var params = {
                page_size: 5,
            };

            return ng2CrawlsRepoService.getAll(params).then(function (data) {
                vm.history = data !== undefined ? data.reverse() : [];
            }, angular.noop);
        }

        function openPolicyForm () {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
            };
            const callback = function (policy) {
                if (angular.isObject(policy)) {
                    ng2MonUIRouterTransitionService.onTransitionFinished(
                        () => $state.go('base.customer.domain.policy.policies'),
                    );
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function getHistoryCenterParameters (module) {
            return ChangesHistoryService.getHistoryCenterParameters(module);
        }
    }
})();
