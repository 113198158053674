import { takeUntil } from 'rxjs/operators';
import { Subject, fromEventPattern } from 'rxjs';

(function () {
    'use strict';

    angular.module('app.layout').component('topmenu', {
        templateUrl: 'app/layout/menu/menu.html',
        controller: MenuController,
        controllerAs: 'vm',
    });

    MenuController.$inject = [
        '$state',
        '$rootScope',
        'ng2SessionService',
        '$window',
        '$filter',
        'ng2ActiveFeatureService',
        'hotkeys',
        'gettextCatalog',
        'ng2MonModalService',
        '$stateParams',
        'ng2MonEventsService',
        'MenuFormService',
        'ng2CoreLoginService',
        'ng2CoreAgreementService',
        'ng2DebugLogService',
        'FORM_CONSTANT',
        'helpCenterPath',
        'ng2TrapFocusService',
        'version',
        'ActiveRegionService',
        'monDialog',
        '$transitions',
    ];

    function MenuController (
        $state,
        $rootScope,
        ng2SessionService,
        $window,
        $filter,
        ng2ActiveFeatureService,
        hotkeys,
        gettextCatalog,
        ng2MonModalService,
        $stateParams,
        ng2MonEventsService,
        MenuFormService,
        ng2CoreLoginService,
        ng2CoreAgreementService,
        ng2DebugLogService,
        FORM_CONSTANT,
        helpCenterPath,
        ng2TrapFocusService,
        version,
        ActiveRegionService,
        monDialog,
        $transitions,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.signOut = signOut;
        vm.openProfile = openProfile;
        vm.isSubentryActive = isSubentryActive;
        vm.openSuggestionsAndIdeas = openSuggestionsAndIdeas;
        vm.openVersion = openVersion;
        vm.goToExports = goToExports;
        vm.openForm = openForm;
        vm.openCheatSheet = openCheatSheet;
        vm.getLogoUrl = getLogoUrl;
        vm.showSearch = showSearch;
        vm.getCustomer = getCustomer;
        vm.destroyed$ = new Subject();

        // ///////////////////////////////////////

        function activate () {
            vm.monListener = null;
            vm.hasCustomer = false;
            vm.isLightCustomer = false;
            vm.customerAdmin = false;
            vm.supportCenter = false;
            vm.shortcuts = false;
            vm.bookOnlineTraining = false;
            vm.suggestionsAndIdeas = false;
            vm.exportsOverview = false;
            vm.classicUI = false;
            vm.showSupport = false;
            vm.reportCenter = false;
            vm.adminHeatmaps = false;
            vm.velaro = false;
            vm.notifications = false;
            vm.showIntercom = false;
            vm.showZendesk = false;
            vm.showVelaro = false;
            vm.showBookTraning = false;
            vm.historyCenter = false;
            vm.policies = false;

            vm.historyCenterStateParams = {
                module: resolveModuleNameFromState($state.current.name),
            };

            if (ng2SessionService.domain) {
                vm.historyCenterStateParams.domain_id = ng2SessionService.domain.id;
            }
            if (ng2SessionService.domainGroup) {
                vm.historyCenterStateParams.group_id = ng2SessionService.domainGroup.id;
            }

            const transitionObs = fromEventPattern(function (handler) {
                $transitions.onSuccess({}, handler);
            });

            ng2SessionService.domainObs.pipe(takeUntil(vm.destroyed$)).subscribe((domain) => {
                if (domain) {
                    vm.historyCenterStateParams.domain_id = domain.id;
                } else {
                    delete vm.historyCenterStateParams.domain_id;
                }
                vm.domain = domain;
            });
            ng2SessionService.domainGroupObs.pipe(takeUntil(vm.destroyed$)).subscribe((domainGroup) => {
                if (domainGroup) {
                    vm.historyCenterStateParams.group_id = domainGroup.id;
                } else {
                    delete vm.historyCenterStateParams.group_id;
                }
            });
            transitionObs.pipe(takeUntil(vm.destroyed$)).subscribe(transitionResult => {

                const transition = transitionResult && transitionResult[0];
                if (transition) {
                    const state = transition.to();
                    vm.historyCenterStateParams.module = resolveModuleNameFromState(state.name);
                }
            });

            vm.bookTrainingUrl = '';
            subscribeAgreements();
            subscribeMe();
            subscribeCustomer();

            update();
            vm.monListener = ng2MonEventsService.addListener('setAgreementNavigationCompleted', update);
            vm.monListenerCustomer = ng2MonEventsService.addListener('finishCustomer', function (data) {
                update(data.customerId);
            });
            vm.isCustomTrainingLinkAnEmail = false;
        }

        function update (customerId) {
            vm.monsidoPath = ng2CoreLoginService.getApiPath();
            vm.domain = ng2SessionService.domain;
            vm.profile = ng2SessionService.me;
            setHelpCenterUrl();
            setuptemplateConditions(customerId);
        }

        function setHelpCenterUrl () {
            vm.helpCenterPath = helpCenterPath;
            const helpCenterLink = ng2SessionService?.me?.customer?.reseller?.settings?.help_center_link;
            if (helpCenterLink && typeof helpCenterLink === 'string' && helpCenterLink.includes('http')) {
                vm.helpCenterPath = helpCenterLink;
            }
        }

        function setBookTrainingUrl () {
            if (ActiveRegionService.isInRegion('US')) {
                vm.bookTrainingUrl = 'https://calendly.com/monsido-us-cx';
            } else if (ActiveRegionService.isInRegion('AU')) {
                vm.bookTrainingUrl = 'https://calendly.com/monsido-au-cx';
            } else {
                vm.bookTrainingUrl = 'https://calendly.com/d/4zz-3mp-vtb';
            }
            return vm.bookTrainingUrl;
        }

        function getCustomer () {
            return ng2SessionService.customer;
        }

        function openCheatSheet () {
            hotkeys.toggleCheatSheet();
        }

        function onDestroy () {
            if (vm.agreementsSubscribe) {
                vm.agreementsSubscribe.unsubscribe();
                vm.agreementsSubscribe = null;
            }

            if (vm.customerSubscribe) {
                vm.customerSubscribe.unsubscribe();
                vm.customerSubscribe = null;
            }

            ng2MonEventsService.remove(vm.monListenerCustomer);

            vm.destroyed$.next();
            vm.destroyed$.complete();
        }

        function subscribeAgreements () {
            ng2CoreAgreementService.initAgreements();

            vm.agreementsSubscribe = ng2CoreAgreementService.hasMultipleAgreements$.subscribe((value) => {
                vm.showAccountSwitch = value;
            });
        }

        function subscribeMe () {
            ng2SessionService.meObs
                .pipe(takeUntil(vm.destroyed$))
                .subscribe(function (user) {
                    vm.profile = user;

                    const hasCustomTrainingLink = user?.customer?.reseller?.settings?.training_request_link;

                    if (hasCustomTrainingLink && typeof hasCustomTrainingLink === 'string') {
                        vm.bookTrainingUrl = hasCustomTrainingLink;
                        if (vm.bookTrainingUrl.includes('@') && !vm.bookTrainingUrl.includes('http')) {
                            vm.isCustomTrainingLinkAnEmail = true;
                        }
                    } else {
                        setBookTrainingUrl();
                    }
                }, angular.noop);
        }

        function subscribeCustomer () {
            vm.customerSubscribe = ng2SessionService.customer$.subscribe((customer)=>{
                if (!customer) {
                    vm.hasCustomer = false;
                }
            });
        }

        function isSubentryActive (destination) {
            return $state.current.name.indexOf(destination) > -1;
        }

        function signOut () {
            ng2CoreLoginService.logout();
        }

        function goToExports () {
            var params = {
                body: 'csvExportsOverlay',
            };
            monDialog.create(params);
        }

        function openSuggestionsAndIdeas () {
            var params = {
                body: FORM_CONSTANT.FORM_FEEDBACK,
            };
            monDialog.create(params);
        }

        function openProfile () {
            var params = {
                body: FORM_CONSTANT.FORM_PROFILE,
                size: 'sm',
            };
            monDialog.create(params);
        }

        function openVersion () {
            var options = {
                message:
                    gettextCatalog.getString('Version {{version}}', { version: version }) +
                    '<br>' +
                    gettextCatalog.getString('Copyright 2020 Acquia Optimize. All rights reserved.'),
                title: gettextCatalog.getString('About Acquia Optimize'),
                className: 'version-box',
                buttons: [
                    {
                        label: gettextCatalog.getString('Export Logs'),
                        className: 'btn btn-secondary text-link',
                        callback: function () {
                            var logsString = JSON.stringify(ng2DebugLogService.logs || [], null, 2);
                            var exportLinkEl = $window.document.createElement('a');
                            var url = $window.URL.createObjectURL(new Blob([logsString], { type: 'octet/stream' }));
                            exportLinkEl.setAttribute('href', url);
                            exportLinkEl.setAttribute('download', 'logs-' + ng2SessionService.me.id + '.json');
                            exportLinkEl.click();
                            ng2TrapFocusService.unPauseLast();
                        },
                        value: 'exportLogs',
                    },
                    {
                        label: gettextCatalog.getString('Done'),
                        className: 'btn btn-primary',
                        callback: function () {
                            ng2TrapFocusService.unPauseLast();
                        },
                        value: 'success',
                    },
                ],
            };

            ng2MonModalService.customDialog(options).catch(() => {});
        }

        function openForm (module) {
            switch (module) {
                case 'domain':
                    MenuFormService.openDomain();
                    break;
                case 'user':
                    MenuFormService.openUser();
                    break;
                case 'global-policy':
                    MenuFormService.openGlobalPolicy();
                    break;
            }
        }

        function getLogoUrl () {
            if (ng2SessionService.accessibilityMode) {
                return '/images/optimize-logo-white.svg';
            }
            return '/images/optimize-logo.svg';
        }

        function showSearch () {
            var stateName = $state.current.name;
            return stateName !== 'base.dashboard';
        }

        /**
         * @desc Basically has correct customer selected...
         */
        function checkIfHasCustomer (customerId) {
            return Boolean(
                ng2SessionService.customer &&
                (ng2SessionService.customer.id === parseInt($stateParams.customerId) ||
                    ng2SessionService.customer.id === parseInt(customerId)),
            );
        }

        function setuptemplateConditions (customerId) {
            vm.hasCustomer = checkIfHasCustomer(customerId);
            vm.isLightCustomer = ng2SessionService.isLiteCustomer();
            vm.customerAdmin = ng2ActiveFeatureService.isFeatureActivePure('customer_admin');
            vm.supportCenter = ng2ActiveFeatureService.isFeatureActivePure('support_center');
            vm.shortcuts = ng2ActiveFeatureService.isFeatureActivePure('general_shortcuts');
            vm.suggestionsAndIdeas = ng2ActiveFeatureService.isFeatureActivePure('suggestions_and_ideas');
            vm.exportsOverview = ng2ActiveFeatureService.isFeatureActivePure('data_export');
            vm.classicUI = ng2ActiveFeatureService.isFeatureActivePure('classic_ui');
            vm.quickSearch = ng2ActiveFeatureService.isFeatureActivePure('quick_search');
            vm.adminHeatmaps = ng2ActiveFeatureService.isFeatureActivePure('admin_heatmaps');
            vm.velaro = ng2ActiveFeatureService.isFeatureActivePure('velaro');
            vm.notifications = ng2ActiveFeatureService.isFeatureActivePure('notifications');
            vm.showBookTraning = ng2ActiveFeatureService.isFeatureActivePure('general_book_training');
            vm.showIntercom = $filter('monConfigActive')('intercom');
            vm.showVelaro = $filter('monConfigActive')('velaro');
            vm.showZendesk = $filter('monConfigActive')('zendesk');
            vm.showSupport = ng2ActiveFeatureService.isFeatureActivePure('general_chat_support');
            vm.reportCenter = ng2ActiveFeatureService.isFeatureActivePure('report_center_all');
            vm.policies = ng2ActiveFeatureService.isFeatureActivePure('policies_all') &&
                !ng2SessionService.me.settings.modules.includes('policies');
            vm.historyCenter = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
        }

        function resolveModuleNameFromState (stateName) {
            stateName = stateName || '';
            if (stateName.startsWith('base.customer.domain.seo')) {
                return 'seo';
            }
            if (stateName.startsWith('base.customer.domain.qa.readability')) {
                return 'readability';
            }
            if (stateName.startsWith('base.customer.domain.qa')) {
                return 'qa';
            }
            if (stateName.startsWith('base.customer.domain.policy')) {
                return 'policy';
            }
            if (stateName.startsWith('base.customer.domain.accessibility')) {
                return 'accessibility';
            }
            if (stateName.startsWith('base.customer.domain.data_privacy')) {
                return 'data-privacy';
            }
            if (stateName.startsWith('base.customer.domain.page_performance')) {
                return 'performance';
            }

            return 'all';
        }
    }
})();
