/* It seems this file is not used */

(function () {
    'use strict';

    angular.module('app.layout').controller('SessionController', SessionController);

    SessionController.$inject = [
        'ng2CoreLoginService',
        '$state',
        '$rootScope',
        '$window',
        'ng2MonEventsService',
        'ng2MonUIRouterTransitionService',
    ];

    /* @ngInject */
    function SessionController (ng2CoreLoginService, $state, $rootScope, $window, ng2MonEventsService, ng2MonUIRouterTransitionService) {
        var vm = this;
        vm.signOut = signOut;

        // ///////////////

        function signOut () {
            ng2MonEventsService.run('logout').then(function () {
                ng2CoreLoginService.logout();
                var scope = $rootScope.$$childHead;
                while (scope) {
                    var nextScope = scope.$$nextSibling;
                    scope.$destroy();
                    scope = nextScope;
                }
                $window.onbeforeunload = null;
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('login.login'),
                );
            }, angular.noop);
        }
    }
})();
