import { Ng2StateDeclaration } from '@uirouter/angular';
import { BaseStateComponent } from '@layout/base-state.component';
import { AccountSettingsComponent } from '@monsido/pages/admin/account/component/account-settings.component';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';

export function checkActiveFeature (activeFeatureService, stateService): void {
    if (!activeFeatureService.isFeatureActive('account_settings')) {
        return stateService.transitionTo('base.dashboard');
    }
}

export const account: Ng2StateDeclaration = {
    name: 'base.customer.admin.account_settings',
    url: '/account/settings',
    abstract: true,
    component: BaseStateComponent,
    resolve: [
        { token: 'activeFeature', deps: [ActiveFeatureService], resolveFn: checkActiveFeature },
    ],
};

export const accountIndex: Ng2StateDeclaration = {
    name: 'base.customer.admin.account_settings.index',
    url: '',
    views: {
        '^.^.$default': {
            component: AccountSettingsComponent,
        },
    },
    dynamic: true,
};

export const ACCOUNT_STATES = [account, accountIndex];
