(function () {
    'use strict';

    angular.module('filters.monsido').filter('isPastDate', IsPastDateFilter);

    IsPastDateFilter.$inject = ['ng2DateTimeService'];

    /* @ngInject */
    function IsPastDateFilter (ng2DateTimeService) {
        return isPastDate;

        // //////////

        function isPastDate (start, end) {
            ng2DateTimeService.isBefore(start, end);
        }
    }
})();
