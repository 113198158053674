(function () {
    'use strict';
    angular.module('app.components').component('accessibilityCcBtn', {
        template:
            '<button type="button" ' +
            'class="btn btn-secondary"' +
            'ng-if="vm.check | accessibilityCloseCaption" ' +
            'ng-click="vm.confirmation()"' +
            'aria-label="{{ vm.title }}" ' +
            'title="{{ vm.title }}" ' +
            'translate>' +
            'Closed Caption' +
            '</button>',
        controller: AccessibilityCCLinkController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
            snippetId: '<',
            onCloseCaptionSuccess: '&?', // Returns caption
            onCloseCaptionError: '&?', // Returns response
            afterCloseCaption: '&?',
        },
    });

    AccessibilityCCLinkController.$inject = [
        'AccessibilityCCService',
        'ng2MonModalService',
        'gettextCatalog',
        'ng2TrapFocusService',
    ];

    /* @ngInject */
    function AccessibilityCCLinkController (AccessibilityCCService, ng2MonModalService, gettextCatalog, ng2TrapFocusService) {
        var vm = this;
        vm.confirmation = confirmation;
        vm.title = AccessibilityCCService.getTitle();

        function closeCaption () {
            AccessibilityCCService.closeCaption(
                vm.snippetId,
                vm.onCloseCaptionSuccess,
                vm.onCloseCaptionError,
                vm.afterCloseCaption,
            );
        }

        function confirmation () {
            var message = AccessibilityCCService.getConfirmationMessage();
            var options = {
                message: message,
                title: vm.title,
            };
            ng2MonModalService.confirm(options).then(function () {
                var msgOpts = {
                    message: AccessibilityCCService.getShortenedConfirmationMessage(),
                    title: '',
                    buttons: [
                        {
                            label: gettextCatalog.getString('Cancel'),
                            className: 'btn btn-secondary',
                            callback: function () {
                                ng2TrapFocusService.unPauseLast();
                            },
                            value: 'cancel',
                        },
                        {
                            label: gettextCatalog.getString('Confirm'),
                            className: 'btn btn-primary',
                            callback: function () {
                                closeCaption();
                                ng2TrapFocusService.unPauseLast();
                            },
                            value: 'success',
                        },
                    ],
                };
                ng2MonModalService.customDialog(msgOpts);
            }, angular.noop);
        }
    }
})();
