import { Component, OnInit } from '@angular/core';
import { AccountSettingsService } from '@monsido/pages/admin/account/component/account-settings.service';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';

@Component({
    selector: 'mon-admin-account-settings',
    templateUrl: 'account-settings.html',
})
export class AccountSettingsComponent implements OnInit {
    customer: CustomerInterface | null = null;
    isSeoActive: boolean = false;
    constructor (
        private accSettingService: AccountSettingsService,
        private activeFeatureService: ActiveFeatureService,
    ) {
        this.customer = this.accSettingService.getSessionCustomer();
    }

    async ngOnInit (): Promise<void> {
        this.isSeoActive = this.activeFeatureService.isFeatureActive('seo_all');
        await this.getPage(false);
    }

    async getPage (force: boolean): Promise<void> {
        if (this.customer === null || force) {
            const customer = await this.accSettingService.getCustomer();
            this.accSettingService.setSessionCustomer(customer);
            this.customer = customer;
        }
    }

    async updateCustomer (): Promise<void> {
        const data = {
            settings: {
                legacy_compliance: false,
                legacy_check_compliance: false,
                legacy_seo_compliance: false,
            },
        };

        if (this.customer?.settings) {
            data.settings.legacy_compliance = this.customer.settings.legacy_compliance;
            data.settings.legacy_check_compliance = this.customer.settings.legacy_check_compliance;
            data.settings.legacy_seo_compliance = this.customer.settings.legacy_seo_compliance;
        }

        try {
            this.customer = await this.accSettingService.updateCustomer(data);
            this.accSettingService.success(this.accSettingService.getString('Account settings updated'));
            this.accSettingService.setSessionCustomer(this.customer);
        } catch (e) {
            this.accSettingService.error(this.accSettingService.getString('Something went wrong'));
        }
    }
}
