import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { MonUIRouterTransitionService } from '@monsido/services/ui-router-transition-service/ui-router-transition-service';

import { StateService, UIRouterGlobals } from '@uirouter/angular';

@Injectable()
export class ParamService {
    constructor (
        private state: StateService,
        private uiRouterGlobals: UIRouterGlobals,
        private monUiRouterTransitionService: MonUIRouterTransitionService,
    ) {}

    setParams (params: Params): void {
        Object.keys(params).forEach((key) => {
            this.uiRouterGlobals.params[key] = params[key];
        });

        this.monUiRouterTransitionService.onTransitionFinished(() => {
            this.state.transitionTo(this.uiRouterGlobals.current, this.uiRouterGlobals.params, {
                reload: false,
                inherit: false,
            });
        });
        // this.uiRouterGlobals.params
    }

    getParams (): Record<string, string> {
        return this.uiRouterGlobals.params;
    }
}
