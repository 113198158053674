(function () {
    'use strict';

    angular.module('pages.accessibility', [
        'app.core',
        'ui.bootstrap.accordion',
        'angular-click-outside',
        'blocks.base',
        'modules.fastTrack',
    ]);
})();
