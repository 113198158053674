(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityPdfInternal', {
        templateUrl: 'app/pages/accessibility/pdf/internal/internal.html',
        controller: AccessibilityPdfController,
        controllerAs: 'vm',
    });

    AccessibilityPdfController.$inject = ['PDFService', '$controller', 'gettextCatalog'];

    function AccessibilityPdfController (PDFService, $controller, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.setReview = setReview;
        vm.tabChange = tabChange;
        vm.getNoResultMessage = getNoResultMessage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.status = false;
            vm.currentSort = { by: 'title', direction: 'desc' };
            vm.header = gettextCatalog.getString('Internal PDF Compliance');
            vm.search = '';
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'accessibility_pdfs',
                        category_ref: vm.status === true ? 'reviewed' : 'pending',
                    },
                },
            ];

            setupTabs();
            getPage();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Pending'),
                    value: false,
                },
                {
                    name: gettextCatalog.getString('Reviewed/Ok'),
                    value: true,
                },
            ];
        }

        function getPage () {
            var params = {
                type: 'pdf',
                page_size: vm.pageSize,
                page: vm.page,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                reviewed: vm.status,
                search: vm.search || null,
            };

            vm.progress = PDFService.get(params).then(function (pdfs) {
                vm.pdfs = pdfs;
            }, angular.noop);
        }

        function setReview (pdf) {
            var params = {
                reviewed: !pdf.reviewed,
                id: pdf.id,
            };

            PDFService.update(pdf, params).then(function () {
                getPage();
            }, angular.noop);
        }

        function tabChange (status) {
            vm.status = status;
            getPage();
        }

        function getNoResultMessage () {
            if (vm.status) {
                return gettextCatalog.getString('No PDF\'s reviewed');
            }
            return gettextCatalog.getString('No PDF\'s pending');
        }
    }
})();
