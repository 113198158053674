import { Injectable } from '@angular/core';
import { SnippetInterface } from '../../accessibility/interfaces/snippet-interface';
import { FastTrackSessionService } from '../services/session.service';

@Injectable({
    providedIn: 'root',
})
export class FastTrackTableService {
    sourceCodeProperty = '';
    complianceProperty = '';

    constructor (private fastTrackSessionService: FastTrackSessionService) {
        this.fastTrackSessionService.obsSnippetComplianceProperty.subscribe((property) => {
            this.complianceProperty = property;
        });
        this.fastTrackSessionService.obsSnippetSourceCodeProperty.subscribe((property) => {
            this.sourceCodeProperty = property;
        });
    }

    floorOrCeil (val: number): number {
        const num = val * 100;
        return (val > 99 ? Math.floor(num) : val < 1 ? Math.ceil(num) : Math.round(num)) / 100;
    }

    getSourceCode (snippet: SnippetInterface): string {
        return snippet[this.sourceCodeProperty];
    }

    getCompliance (snippet: SnippetInterface): number {
        return snippet[this.complianceProperty];
    }
}
