(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name accessibilityCloseCaption
     * @description Check if Accessibility check should show close caption
     */

    angular.module('filters.monsido').filter('accessibilityCloseCaption', AccessibilityCloseCaptionFilter);

    AccessibilityCloseCaptionFilter.$inject = ['AccessibilityCloseCaptionService'];

    /* @ngInject */
    function AccessibilityCloseCaptionFilter (AccessibilityCloseCaptionService) {
        return filter;

        /**
         * @param {object} check
         * @return {Boolean}
         */
        function filter (check) {
            check = check || {};
            return AccessibilityCloseCaptionService.showCloseCaption(check);
        }
    }
})();
