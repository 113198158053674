import { Component, Input } from '@angular/core';

export type TargetType = '_blank' | '_self' | '_top' | '_parent';

@Component({
    selector: 'mon-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
    @Input() monHref?: string;
    @Input() monTarget: TargetType = '_blank';
    @Input() monLinkText?: string;
    @Input() monReversePosition: boolean = false;
    @Input() monIconDirect: string = 'faExternalLinkAlt';
}
