import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.qa.nin').component('nin', {
        templateUrl: 'app/pages/qa/nin/nin.html',
        controller: NinController,
        controllerAs: 'vm',
    });

    NinController.$inject = [
        'NiNumbersRepo',
        'gettextCatalog',
        'ng2SessionService',
        '$stateParams',
        '$controller',
        'ninService',
        'ng2MonEventsService',
    ];

    function NinController (
        NiNumbersRepo,
        gettextCatalog,
        ng2SessionService,
        $stateParams,
        $controller,
        ninService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.openPages = openPages;
        vm.getPage = getPage;
        vm.update = update;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.showDocuments = ng2SessionService.domain.scan.scan_documents || false;
            vm.search = null;
            vm.niNumbers = [];
            setupTabs();
            getPage();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Potential'),
                    tooltip: gettextCatalog.getString(
                        'These numbers represent potential National Identification numbers. ' +
                        'This means that the format, date, and year are all in accordance with a valid National Identification number.',
                    ),
                    value: 'potential',
                },
                {
                    name: gettextCatalog.getString('Unlikely'),
                    tooltip: gettextCatalog.getString(
                        'These numbers have a format, date, and year which are in accordance with a National Identification number. ' +
                        'The numbers have been found somewhere on a page where National Identification numbers are not normally found. ' +
                        'Therefore it is likely that they aren\'t National Identification numbers.',
                    ),
                    value: 'unlikely',
                },
                {
                    name: gettextCatalog.getString('Ignored'),
                    value: 'ignored',
                },
            ];
            vm.activeTab = 'potential';
        }

        function getPage () {
            var params = {
                page: vm.page,
                search: vm.search,
                page_size: vm.pageSize,
                priority: vm.activeTab,
            };

            if (params.priority === 'ignored') {
                params.status = 'ignored';
                params.priority = null;
            } else {
                params.status = null;
            }

            vm.progress = NiNumbersRepo.get(params).then(function (data) {
                vm.niNumbers = data;
            }, angular.noop);
        }

        function openPages (nin, type) {
            var params = {
                body: 'ninPages',
                size: 'md',
                data: { nin: nin, type: type, status: vm.activeTab === 'ignored' ? 'ignored' : 'active' },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function update (nin, status, confirmMessage) {
            ng2MonModalService.confirm(confirmMessage).then(()=>{
                const params = {
                    status: status,
                };
                return ninService.update(nin.id, params).then(function () {
                    getPage();
                }, angular.noop);
            });
        }
    }
})();
