(function () {
    'use strict';

    angular.module('app.forms').component('formDomainGroupsMatchRule', {
        templateUrl: 'app/forms/domain/steps/domain_groups/steps/rules/match-rule/match-rule.html',
        controller: DomainGroupsMatchRuleController,
        controllerAs: 'vm',
        bindings: {
            matchOptions: '<',
            rule: '<',
            onConfirmRemove: '&',
            onExcludeChange: '&',
            labels: '<',
            key: '<',
        },
    });

    DomainGroupsMatchRuleController.$inject = ['gettextCatalog'];

    /* @ngInject */
    function DomainGroupsMatchRuleController (gettextCatalog) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.templates = {
                meta: 'app/forms/domain/steps/domain_groups/steps/rules/match-rule/templates/meta.html',
            };
            setupMatchOptions();
            setupMatchRules();
            setupMatchExcludes();
        }

        function setupMatchOptions () {
            vm.matchOptions = [
                {
                    value: 'title',
                    name: gettextCatalog.getString('title'),
                },
                {
                    value: 'url',
                    name: gettextCatalog.getString('path'),
                },
                {
                    value: 'full_url',
                    name: gettextCatalog.getString('url'),
                },
                {
                    value: 'metadata',
                    name: gettextCatalog.getString('metadata'),
                },
                {
                    value: 'content',
                    name: gettextCatalog.getString('content'),
                },
                {
                    value: 'labels',
                    name: gettextCatalog.getString('labels'),
                },
            ];
        }

        function setupMatchRules () {
            vm.matchRulesOptions = [
                {
                    value: 'contains',
                    name: gettextCatalog.getString('contains'),
                },
                {
                    value: 'starts_with',
                    name: gettextCatalog.getString('starts_with'),
                },
                {
                    value: 'ends_with',
                    name: gettextCatalog.getString('ends_with'),
                },
                {
                    value: 'is_exactly',
                    name: gettextCatalog.getString('is_exactly'),
                },
                {
                    value: 'regexp',
                    name: gettextCatalog.getString('match (regex)'),
                },
            ];
        }

        function setupMatchExcludes () {
            vm.matchExcludeOptions = [
                {
                    value: 'false',
                    name: gettextCatalog.getString('included'),
                },
                {
                    value: 'true',
                    name: gettextCatalog.getString('excluded'),
                },
            ];
        }

    }

})();
