import { Injectable } from '@angular/core';
import { ChangeTypeType, HistoryHelperService, HistoryType } from '@client/app/blocks/helpers/history.helper.service';

@Injectable({
    providedIn: 'root',
})
export class ChangesHistoryHelperService {

    constructor (
        private historyHelperService: HistoryHelperService,
    ) { }

    getChangeValueColorClass (id: number, history: HistoryType, issues: string[], type?: ChangeTypeType): Record<string, boolean> {
        const value = this.getChangeValue(id, history, issues, type);
        return this.historyHelperService.getChangeColorClass(value);
    }

    getChangeValueIconClass (id: number, history: HistoryType, issues: string[], type?: ChangeTypeType): Record<string, boolean> {
        const value = this.getChangeValue(id, history, issues, type);
        return this.historyHelperService.getChangeIconClass(value);
    }

    getChangeValue (id: number, history: HistoryType, issues: string[], type?: ChangeTypeType): number {
        return this.historyHelperService.getChangeValue(id, history, issues, type);
    }

    getChangePercentageValue (id: number, history: HistoryType, issues: string[], type?: ChangeTypeType): number {
        return this.historyHelperService.getChangePercentageValue(id, history, issues, type);
    }
}
