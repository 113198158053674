(function () {
    'use strict';
    angular.module('modules.cookie_banner.services').service('CookieBannerHelperService', CookieBannerHelperService);

    CookieBannerHelperService.$inject = ['ng2ActiveFeatureService', 'CookieCategoriesLocalizeService', 'ng2DemoHasAccessService'];
    /* @ngInject*/
    function CookieBannerHelperService (ng2ActiveFeatureService, CookieCategoriesLocalizeService, ng2DemoHasAccessService) {
        return {
            isAbleToSetupScript: isAbleToSetupScript,
            consentPayloadDecoder: consentPayloadDecoder,
            getCookiePreferenceLabel: getCookiePreferenceLabel,
        };

        // ///////////////

        function isAbleToSetupScript (domain) {
            return (
                ng2DemoHasAccessService.isDemoConsentManager() ||
                Boolean(domain && domain.settings.scripts.cookie_banner && ng2ActiveFeatureService.isFeatureActive('cookie_banner', domain))
            );
        }

        function consentPayloadDecoder (input) {
            try {
                var bytesLeft = input;
                var result = '';
                while (bytesLeft.length) {
                    var byte = bytesLeft.substr(0, 8);
                    bytesLeft = bytesLeft.substr(8);
                    result += String.fromCharCode(parseInt(byte, 2));
                }
                return JSON.parse(result);
            } catch (e) {
                return {};
            }
        }

        function getCookiePreferenceLabel (value) {
            var preference = CookieCategoriesLocalizeService[value];
            if (preference) {
                return preference;
            }
            return '';
        }
    }
})();
