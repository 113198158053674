import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

type LogPayloadType = HttpResponse<unknown> | HttpRequest<unknown> | HttpErrorResponse;
type LogType = { type: string; data?: LogPayloadType; };

@Injectable({
    providedIn: 'root',
})
export class DebugLogService {
    logs: LogType[] = [];

    add (originalPayload: LogPayloadType, type: string): void {
        const payload = cloneDeep(originalPayload);

        if (this.logs.length > 300) {
            this.logs.shift();
        }

        const log: LogType = {
            type: type || 'unknown type',
        };

        // avoid blocking UI
        setTimeout(
            (newLog) => {
                newLog.data = this.sanitizePayload(payload);
            },
            0,
            log,
        );

        this.logs.push(log);
    }

    private sanitizePayload <T> (result: T): T {
        if (!result) {
            return {} as T;
        }
        this.prepareForLog(result, ['Authorization', 'authorization']);
        return result;
    }

    private prepareForLog <T> (obj: T, keysToDelete: string[]): void {
        const objKeys = Object.keys(obj as Record<string, string>);
        const len = objKeys.length;
        let i = 0;
        let childObj;

        for (i; i < len; i += 1) {
            childObj = obj[objKeys[i]];
            if (childObj && typeof childObj === 'object') {
                if (childObj instanceof Map) {
                    childObj = Object.fromEntries(childObj);
                }
                obj[objKeys[i]] = childObj;
                this.prepareForLog(childObj, keysToDelete);
            }
        }

        keysToDelete.forEach(key => {
            if (obj[key] != null) {
                delete obj[key];
                return;
            }
        });
    }
}
