(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideFeaturesStatistics', {
        templateUrl: 'app/forms/domain-script-guide/features/statistics/statistics.html',
        controller: DomainScriptGuideFeaturesStatisticsV2Controller,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
        },
    });

    DomainScriptGuideFeaturesStatisticsV2Controller.$inject = ['FormDomainScriptGuideStatisticsService', '$timeout'];
    /* @ngInject */
    function DomainScriptGuideFeaturesStatisticsV2Controller (FormDomainScriptGuideStatisticsService, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.onChangeValue = onChangeValue;
        vm.removeExtension = removeExtension;
        vm.addExtension = addExtension;
        vm.onChangeDocumentExtension = onChangeDocumentExtension;
        vm.onChangeDocumentClass = onChangeDocumentClass;
        vm.onChangeCookielessTracking = onChangeCookielessTracking;
        vm.onChangeDocumentTracking = onChangeDocumentTracking;
        vm.onChangeDocumentIgnoreClass = onChangeDocumentIgnoreClass;

        function activate () {
            vm.show = FormDomainScriptGuideStatisticsService.hasAccessToFeature();
            vm.canUse = FormDomainScriptGuideStatisticsService.getValue();
            vm.documentExts = FormDomainScriptGuideStatisticsService.getDocumentExtensions();
            vm.documentClass = FormDomainScriptGuideStatisticsService.getDocumentClass();
            vm.cookielessTracking = FormDomainScriptGuideStatisticsService.getCookielessTracking();
            vm.documentTracking = FormDomainScriptGuideStatisticsService.getDocumentTracking();
            vm.documentIgnoreClass = FormDomainScriptGuideStatisticsService.getDocumentIgnoreClass();
            setupWatcher();
        }

        function onChangeValue () {
            $timeout(function () {
                setValue(vm.canUse);
            });
        }

        function removeExtension (index) {
            vm.documentExts.splice(index, 1);
            setDocumentExtensions(vm.documentExts);
        }

        function addExtension () {
            vm.documentExts.push('');
            setDocumentExtensions(vm.documentExts);
        }

        function onChangeDocumentExtension () {
            $timeout(function () {
                setDocumentExtensions(vm.documentExts);
            });
        }

        function onChangeDocumentClass () {
            $timeout(function () {
                setDocumentClass(vm.documentClass);
            });
        }

        function onChangeCookielessTracking () {
            $timeout(function () {
                setCookielessTracking(vm.cookielessTracking);
            });
        }

        function onChangeDocumentTracking () {
            $timeout(function () {
                setDocumentTracking(vm.documentTracking);
            });
        }

        function onChangeDocumentIgnoreClass () {
            $timeout(function () {
                setDocumentIgnoreClass(vm.documentIgnoreClass);
            });
        }

        // PROTECTED
        function setupWatcher () {
            FormDomainScriptGuideStatisticsService.settingChangeObs.subscribe(function () {
                vm.canUse = FormDomainScriptGuideStatisticsService.getValue();
                vm.documentExts = FormDomainScriptGuideStatisticsService.getDocumentExtensions();
                vm.documentClass = FormDomainScriptGuideStatisticsService.getDocumentClass();
                vm.cookielessTracking = FormDomainScriptGuideStatisticsService.getCookielessTracking();
                vm.documentTracking = FormDomainScriptGuideStatisticsService.getDocumentTracking();
                vm.documentIgnoreClass = FormDomainScriptGuideStatisticsService.getDocumentIgnoreClass();
            });
        }

        function setValue (value) {
            FormDomainScriptGuideStatisticsService.setValue(value, true);
        }

        function setDocumentExtensions (value) {
            FormDomainScriptGuideStatisticsService.setDocumentExtensions(value, true);
        }

        function setDocumentClass (value) {
            FormDomainScriptGuideStatisticsService.setDocumentClass(value, true);
        }

        function setCookielessTracking (value) {
            FormDomainScriptGuideStatisticsService.setCookielessTracking(value, true);
        }

        function setDocumentTracking (value) {
            FormDomainScriptGuideStatisticsService.setDocumentTracking(value, true);
        }

        function setDocumentIgnoreClass (value) {
            FormDomainScriptGuideStatisticsService.setDocumentIgnoreClass(value, true);
        }
    }
})();
