import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { Report } from '@monsido/modules/models/api/report';
import { ReportInterface } from '@monsido/modules/models/api/interfaces/report.interface';

@Injectable({
    providedIn: 'root',
})
export class ReportRepoService {
    constructor (private apiService: ApiService) {}

    async get (): Promise<MonTableCollection<Report>> {
        return this.apiService.get('reports/').then(this.toModels);
    }

    async update (id: number, params: Partial<ReportInterface>): Promise<Report> {
        return this.apiService.patch('reports/' + id, params).then(this.toModel);
    }

    async create (params: Partial<ReportInterface>): Promise<Report> {
        return this.apiService.post('reports/', params).then(this.toModel);
    }

    async remove (id: number): Promise<void> {
        return this.apiService.delete('reports/' + id);
    }

    private toModels (models: MonTableCollection<ReportInterface>): MonTableCollection<Report> {
        for (let i = 0; i < models.length; i++) {
            models[i] = new Report(models[i]);
        }
        return models as unknown as MonTableCollection<Report>;
    }

    private toModel (model: ReportInterface): Report {
        return new Report(model);
    }

}
