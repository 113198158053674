(function () {
    'use strict';

    angular.module('app.forms').component('formImportGroupRules', {
        templateUrl: 'app/forms/domain/steps/domain_groups/steps/rules/import/import-rules.html',
        controller: ImportRulesController,
        controllerAs: 'vm',
        bindings: {
            monDialogId: '@',
            ngModel: '<',
        },
    });

    ImportRulesController.$inject = ['monDialog', '$filter'];

    /* @ngInject */
    function ImportRulesController (monDialog, $filter) {
        var vm = this;
        vm.submit = submit;
        vm.$onInit = activate;
        vm.getSpinner = getSpinner;

        function activate () {
            vm.step = 1;
            vm.saving = false;
            vm.data = null;
            vm.selected = {};
        }

        function submit () {
            vm.saving = true;
            vm.data.data.shift();

            for (var i = 0; i < vm.data.data.length; i++) {
                if (vm.data.data[i].length === 4) {
                    vm.ngModel.rules.push({
                        match: vm.data.data[i][0],
                        match_rule: vm.data.data[i][1],
                        exclude_as_string: vm.data.data[i][3].toLowerCase(),
                        text: vm.data.data[i][2],
                    });
                }
            }

            close();
        }

        function close () {
            monDialog.close(vm.monDialogId, vm.ngModel);
        }

        function getSpinner () {
            if (vm.saving) {
                return 'fa-spin ' + $filter('monIcons')(['SPINNER', 'ICON']);
            }
            return '';
        }
    }
})();
