import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Pipe({
    name: 'translateReportTitle',
})
export class TranslateReportTitlePipe implements PipeTransform {
    constructor (private translateService: TranslateService) {}

    transform (title?: string): string {
        if (!title) {
            return '';
        }

        if (title.toLowerCase().indexOf('report') === -1) {
            return this.translateService.getString(`[[title]] report`, { title });
        }

        return this.translateService.getString('[[title]]', { title });
    }

}
