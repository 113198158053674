export const REPORT_CENTER_MODULES = {
    qa: 'qa',
    qa_summary: 'qa_summary',
    accessibility: 'accessibility',
    accessibility_summary: 'accessibility_summary',
    seo: 'seo',
    statistics: 'stats',
    heartbeat: 'uptime',
    heatmaps: 'heatmaps',
    policy: 'policy',
    other: 'other',
} as const;
