(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSummaryGeneral', {
        templateUrl: 'app/pages/statistics/summary/general/general.html',
        controller: StatisticsSummaryGeneralController,
        controllerAs: 'vm',
        bindings: {
            startDate: '<?',
            endDate: '<?',
            country: '<?',
            region: '<?',
            data: '=?',
            pageId: '<?',
            type: '@?',
            onClick: '&?',
            class: '@?',
            hidePageviews: '<?',
            useDataFromData: '<?',
        },
    });

    StatisticsSummaryGeneralController.$inject = ['ng2StatsRepoService', '$stateParams', 'pageService', 'gettextCatalog', 'ng2DateTimeService'];
    /* @ngInject */
    function StatisticsSummaryGeneralController (ng2StatsRepoService, $stateParams, pageService, gettextCatalog , ng2DateTimeService) {
        var vm = this;

        vm.$onInit = activate;
        vm.$onChanges = update;
        vm.onGeneralInfoClick = onGeneralInfoClick;

        function activate () {
            vm.hidePageviews = vm.hidePageviews || false;
            vm.pageId = vm.pageId || false;
            vm.totalVisitText = gettextCatalog.getString('Total visits');
            vm.totalVisitorsText = gettextCatalog.getString('Total visitors');
            if (vm.type) {
                if (vm.type === 'organic') {
                    vm.totalVisitText = gettextCatalog.getString('Total visits from Search Engines');
                    vm.totalVisitorsText = gettextCatalog.getString('Total visitors from Search Engines');
                } else {
                    vm.totalVisitText = gettextCatalog.getString('Total visits from Referrers');
                    vm.totalVisitorsText = gettextCatalog.getString('Total visitors from Referrers');
                }
            }
            vm.endDate = vm.endDate || ng2DateTimeService.endOf(undefined, 'day');
            vm.startDate = vm.startDate || ng2DateTimeService.substractDuration(undefined, 7, 'days');
            vm.currentType = 'visits';
            if (!vm.useDataFromData) {
                getSummary();
            }
        }

        function update () {
            if (!vm.useDataFromData) {
                getSummary();
            }
        }

        function getSummary () {
            const params = {
                domainId: $stateParams.domainId,
                from: ng2DateTimeService.format(vm.startDate, 'YYYY-MM-DD'),
                to: ng2DateTimeService.format(vm.endDate, 'YYYY-MM-DD'),
                type: 'total',
            };
            if (vm.country) {
                params.country = vm.country;
            }
            if (vm.region) {
                params.region = vm.region;
            }
            vm.loading = true;
            if (vm.pageId) {
                pageService
                    .getStatSummary(vm.pageId, params)
                    .then(function (data) {
                        vm.data = data;
                    }, angular.noop)
                    .finally(function () {
                        vm.loading = false;
                    });
            } else {
                if (vm.type) {
                    params.type = vm.type;
                    ng2StatsRepoService.getVisitors(params)
                        .then(function (data) {
                            let visitors = 0;
                            let visits = 0;
                            let avg_time = 0;
                            let bounce_rate = 0;
                            for (let i = 0; i < data.length; i++) {
                                visits += parseInt(data[i].visits);
                                visitors += parseInt(data[i].visitors);
                                avg_time += parseInt(data[i].avg_time);
                                bounce_rate += parseInt(data[i].bounce_rate);
                            }
                            vm.data = {
                                visits: visits,
                                visitors: visitors,
                                avg_time: avg_time,
                                bounce_rate: bounce_rate,
                            };
                        }, angular.noop)
                        .finally(function () {
                            vm.loading = false;
                        });
                } else {
                    ng2StatsRepoService.getSummary(params)
                        .then(function (data) {
                            vm.data = data;
                        }, angular.noop)
                        .finally(function () {
                            vm.loading = false;
                        });
                }
            }
        }

        function onGeneralInfoClick (type) {
            vm.currentType = type;
            if (isFunction(vm.onClick)) {
                vm.onClick({ type: type });
            }
        }

        function isFunction (func) {
            return typeof func === 'function';
        }
    }
})();
