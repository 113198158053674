if (!Object.values) {
    Object.values = function values (O) {
        return reduce(
            keys(O),
            function (v, k) {
                return concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []);
            },
            [],
        );
    };
}
