(function () {
    'use strict';

    angular.module('modules.roles').factory('RolesApiService', RolesApiService);

    RolesApiService.$inject = ['RolesDemoDataService'];

    /* @ngInject */
    function RolesApiService (RolesDemoDataService) {
        return {
            getAll: getAll,
        };

        // /////////////

        function getAll (params) {
            if (true) {
                return RolesDemoDataService.getData('roles', params);
            } else {
                // Add endpoint repo here....
            }
        }
    }
})();
