(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name FormPagePerformancePageService
     * @description Service for the Form page component
     */
    angular.module('modules.page-performance').factory('FormPagePerformancePageService', FormPagePerformancePageService);

    FormPagePerformancePageService.$inject = ['gettextCatalog', '$deepmerge', 'Lodash', 'ng2SessionService', 'ng2PagePerformanceService'];

    function FormPagePerformancePageService (gettextCatalog, $deepmerge, Lodash, ng2SessionService, ng2PagePerformanceService) {
        var vm = this;
        vm.frequencyOptions = [
            {
                name: gettextCatalog.getString('Four times a day'),
                value: 6,
            },
            {
                name: gettextCatalog.getString('Twice a day'),
                value: 12,
            },
            {
                name: gettextCatalog.getString('Once a day'),
                value: 24,
            },
            {
                name: gettextCatalog.getString('Once a week'),
                value: 168,
            },
        ];

        return {
            getFrequencyOptions: getFrequencyOptions,
            getDefaultFrequencyOption: getDefaultFrequencyOption,
            getMaxProfileCount: getMaxProfileCount,
        };

        /**
         * @memberOf FormPagePerformancePageService
         * @desc Get the Page Frequency Options
         */
        function getFrequencyOptions () {
            return $deepmerge([], getAvailableFrequencies());
        }

        /**
         * @memberOf FormPagePerformancePageService
         * @desc Get the default value for the Frequency options
         */
        function getDefaultFrequencyOption () {
            var frequencies = getAvailableFrequencies();
            if (frequencies.length === 0) {
                frequencies = vm.frequencyOptions;
            }
            return Lodash.last(frequencies).value;
        }

        /**
         * @memberOf FormPagePerformancePageService
         * @desc Get the number of maximum allowed profiles for this account
         */
        function getMaxProfileCount () {
            return ng2PagePerformanceService.getMaxProfileCount();
        }

        // PROTECTED

        /**
         * @memberOf FormPagePerformancePageService
         * @desc Get the allowed Frequencies from customer Plan trait
         * @return {null | Array<Number>}
         */
        function getCustomerFrequencies () {
            return ng2SessionService.customer && ng2SessionService.customer.plan_traits.performance_tracking_allowed_frequencies;
        }

        /**
         * @memberOf FormPagePerformancePageService
         * @desc Get the allowed Frequencies from customer Plan trait
         * @return {null | Array<Number>}
         */
        function getAvailableFrequencies () {
            var frequencies = getCustomerFrequencies();
            if (!frequencies) {
                return vm.frequencyOptions;
            }
            return Lodash.filter(vm.frequencyOptions, function (frequency) {
                return frequencies.indexOf(frequency.value) > -1;
            });
        }
    }
})();
