(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardAccessibility', {
        templateUrl: 'app/pages/domain/charts/accessibility/accessibility.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            historyData: '<',
        },
    });

    DashboardController.$inject = [
        'ng2AccessibilityRepoService',
        'AccessibilityChecksRepo',
        '$filter',
        '$q',
        'AccessibilityDoughnutChartService',
        'gettextCatalog',
        'ng2SessionService',
        'ChangesHistoryService',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function DashboardController (
        ng2AccessibilityRepoService,
        AccessibilityChecksRepo,
        $filter,
        $q,
        AccessibilityDoughnutChartService,
        gettextCatalog,
        ng2SessionService,
        ChangesHistoryService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getChecksCountByAbbreviation = getChecksCountByAbbreviation;
        vm.getHistoryCenterParameters = getHistoryCenterParameters;

        function activate () {
            vm.loading = true;
            vm.labels = ['', ''];
            vm.data = [];
            vm.colors = [];
            vm.showHistory = ng2ActiveFeatureService.isFeatureActivePure('history_center_all');
            vm.domain = ng2SessionService.domain;
            vm.accessibilityAbbreviations = [
                {
                    abbr: 'A',
                    guidelineId: 'QUALWEB-A',
                    iconClass: 'fa-mon-wcag-a',
                    tooltipTxt: gettextCatalog.getString('Level A Issues'),
                },
                {
                    abbr: 'AA',
                    guidelineId: 'QUALWEB-AA',
                    iconClass: 'fa-mon-wcag-aa',
                    tooltipTxt: gettextCatalog.getString('Level AA Issues'),
                },
                {
                    abbr: 'A',
                    guidelineId: 'WCAG2-A',
                    iconClass: 'fa-mon-wcag-a',
                    tooltipTxt: gettextCatalog.getString('Level A Issues'),
                },
                {
                    abbr: 'AA',
                    guidelineId: 'WCAG2-AA',
                    iconClass: 'fa-mon-wcag-aa',
                    tooltipTxt: gettextCatalog.getString('Level AA Issues'),
                },
                {
                    abbr: 'AAA',
                    guidelineId: 'WCAG2-AAA',
                    iconClass: 'fa-mon-wcag-aaa',
                    tooltipTxt: gettextCatalog.getString('Level AAA Issues'),
                },
                {
                    abbr: 'A',
                    guidelineId: 'WCAG21-A',
                    iconClass: 'fa-mon-wcag-a',
                    tooltipTxt: gettextCatalog.getString('Level A Issues'),
                },
                {
                    abbr: 'AA',
                    guidelineId: 'WCAG21-AA',
                    iconClass: 'fa-mon-wcag-aa',
                    tooltipTxt: gettextCatalog.getString('Level AA Issues'),
                },
                {
                    abbr: 'AAA',
                    guidelineId: 'WCAG21-AAA',
                    iconClass: 'fa-mon-wcag-aaa',
                    tooltipTxt: gettextCatalog.getString('Level AAA Issues'),
                },
            ];

            vm.onlySummaryIsAvailable = ng2ActiveFeatureService.isFeatureActive('accessibility_summary');
            var promises = [getSummary(), getChecks()];
            $q.all(promises).then(function (values) {
                vm.summary = values[0];
                vm.checks = values[1];
                setupLineDoughnutChart();
                vm.loading = false;
            }, angular.noop);
        }

        function getSummary () {
            return ng2AccessibilityRepoService.getSummary();
        }

        function getChecks () {
            return AccessibilityChecksRepo.getAll();
        }

        function setupLineDoughnutChart () {
            var service = new AccessibilityDoughnutChartService(vm.summary, vm.checks);
            var percentage = service.getPercentageFromResultType();
            vm.colors = service.getColors();
            vm.data = service.getData();
            vm.options = {
                centerText: {
                    percentageLabel: $filter('monNumber')(percentage / 100, '0.[00] %'),
                    totalLabel: gettextCatalog.getString('Overall compliance'),
                    subLabel: gettextCatalog.getString('achieved'),
                    totalFontSize: 150,
                    subFontSize: 150,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: false,
                    custom: function (tooltip) {
                        if (!tooltip) {
                            return;
                        }

                        if (tooltip.x < 5) {
                            tooltip.x = 5;
                        }
                    },
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return $filter('monNumber')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
                hover: {
                    mode: null,
                },
            };
        }

        function getChecksCountByAbbreviation (abbreviation) {
            var params = {
                only_errors: true,
                abbreviations: [abbreviation],
            };
            var checks = $filter('accessibilityChecksByResultTypes')(vm.checks, params);
            return checks.length;
        }

        function getHistoryCenterParameters (module) {
            return ChangesHistoryService.getHistoryCenterParameters(module);
        }
    }
})();
