export const ACCESSIBILITY_HELPER = {
    STANDARDS: {
        // Checks for each standard
        'WCAG2-A': 128,
        'WCAG2-AA': 26,
        'WCAG2-AAA': 14,
        'WCAG21-A': 134,
        'WCAG21-AA': 33,
        'WCAG21-AAA': 19,
        'WCAG22-A': 143,
        'WCAG22-AA': 47,
        'WCAG22-AAA': 18,
    },
    IMG_CHECKS: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        10,
        11,
        12,
        14,
        16,
        178,
        239,
        251,
        357,
        376,
        378,
        379,
        381,
    ],
};
