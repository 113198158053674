(function () {
    'use strict';
    /**
     * Check if the input matches the required email format for uptime notifications
     * The required format is multiple emails seperated by comma
     *
     * @memberof directives.monsido
     * @ngdoc directive
     * @name uptimeEmail
     *
     */
    angular.module('directives.monsido').directive('uptimeEmail', function () {
        var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

        return {
            require: '?ngModel',
            link: function (scope, elm, attrs, ctrl) {
                if (ctrl) {
                    ctrl.$validators.uptime_email = function (modelValue) {
                        if (ctrl.$isEmpty(modelValue)) {
                            return true;
                        }

                        var emails = modelValue.split(',');
                        emails = emails.map(function (email) {
                            if (email[0] == ' ') {
                                email = email.substring(1);
                            }

                            return email;
                        });

                        var result = emails.filter(function (email) {
                            return !EMAIL_REGEXP.test(email);
                        });

                        return result.length == 0;
                    };
                }
            },
        };
    });
})();
