import { Ng2StateDeclaration } from '@uirouter/angular';
import { StatisticsEventsComponent } from './events/statistics-events.component';
import { StatisticsSiteSearchComponent } from './site-search/statistics-site-search.component';
import moment from 'moment';

export const contentEvent: Ng2StateDeclaration = {
    name: 'base.customer.domain.statistics.pages.events',
    url: '/statistics/events?tab&from&to&page&limit&search&sortBy&sortDirection',
    component: StatisticsEventsComponent,
    params: {
        tab: {
            dynamic: true,
            value: '0',
        },
        sortBy: {
            dynamic: true,
            value: 'event_category',
        },
        sortDirection: {
            dynamic: true,
            value: 'desc',
        },
        from: {
            dynamic: true,
            value: moment().subtract(1, 'month')
                .format('YYYY-MM-DD'),
        },
        to: {
            dynamic: true,
            value: moment().format('YYYY-MM-DD'),
        },
        page: {
            dynamic: true,
            value: '1',
        },
        limit: {
            dynamic: true,
            value: '10',
        },
        search: {
            dynamic: true,
            value: '',
        },
    },
};

export const contentSiteSearch: Ng2StateDeclaration = {
    name: 'base.customer.domain.statistics.pages.siteSearch',
    url: '/statistics/site-search?from&to&page&limit&search&sortDirection&sortBy',
    component: StatisticsSiteSearchComponent,
    params: {
        sortDirection: {
            dynamic: true,
            value: 'desc',
        },
        sortBy: {
            dynamic: true,
            value: '',
        },
        from: {
            dynamic: true,
            value: moment().subtract(1, 'month')
                .format('YYYY-MM-DD'),
        },
        to: {
            dynamic: true,
            value: moment().format('YYYY-MM-DD'),
        },
        page: {
            dynamic: true,
            value: '1',
        },
        limit: {
            dynamic: true,
            value: '10',
        },
        search: {
            dynamic: true,
            value: '',
        },
    },
};

export const STATISTICS_CONTENT_STATES = [contentEvent, contentSiteSearch];
