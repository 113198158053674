import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerHasPlanPipe } from './customer-has-plan/customer-has-plan.pipe';
import { FullUrlPipe } from './full-url/full-url.pipe';

@NgModule({
    declarations: [CustomerHasPlanPipe, FullUrlPipe],
    imports: [
        CommonModule,
    ],
    exports: [CustomerHasPlanPipe, FullUrlPipe],
})
export class SharedPipesModule { }
