(function () {
    'use strict';

    angular.module('app.forms').component('formUser', {
        templateUrl: 'app/forms/user/user.html',
        controller: UserController,
        controllerAs: 'vm',
        bindings: {
            user: '<?',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    UserController.$inject = ['userService', '$q', 'ng2SessionService', 'monDialog', '$timeout', 'ng2CoreLoginService'];
    /* @ngInject */
    function UserController (userService, $q, ng2SessionService, monDialog, $timeout, ng2CoreLoginService) {
        var vm = this;
        vm.submit = submit;
        vm.$onInit = activate;
        vm.user = vm.user || {};

        function activate () {
            vm.user.customer_admin = vm.user.customer_admin || false;
            vm.user.send_welcome_email = vm.user.send_welcome_email || true;
            if (vm.user.id > 0) {
                vm.progress = userService.get(vm.user.id, true).then(function (result) {
                    if (vm.user) {
                        if (vm.user.id > 0) {
                            vm.user = result;
                        }
                    }
                }, angular.noop);
            }
            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.userForm });
                }
            });
        }

        function submit () {
            var user = angular.copy(vm.user);
            user.domain_users = setDomainVisibility('domain_users');
            user.domain_group_members = setDomainVisibility('domain_group_members');
            if (Array.isArray(user.labels)) {
                user.labels = user.labels.map(function (label) {
                    return label.id;
                });
            }
            vm.onSave = true;
            var promise;
            if (user.id === undefined || user.id === null) {
                promise = userService.create(user);
            } else {
                promise = userService.update(user);
            }

            promise.then(
                function (data) {
                    if (vm.userForm) {
                        vm.userForm.$setPristine();
                    }
                    close(data);
                    if (data && ng2SessionService.me) {
                        if (data.id === ng2SessionService.me.id) {
                            ng2CoreLoginService.loadUser();
                        }
                    }
                },
                function () {
                    vm.onSave = false;
                },
            );
        }

        function setDomainVisibility (domainParam) {
            return vm.user[domainParam].map(function (domain) {
                domain.visible = vm.user.customer_admin || domain.visible;
                domain.send_report = domain.visible && domain.send_report;
                return domain;
            });
        }

        function close (user) {
            monDialog.close(vm.monDialogId, user);
        }
    }
})();
