(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationMissingAlt', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/missing-alt/missing-alt.html',
        controller: SeoTranslationsMultipleH1Controller,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
            page: '<',
        },
    });

    SeoTranslationsMultipleH1Controller.$inject = ['ng2SessionService'];
    /* @ngInject */
    function SeoTranslationsMultipleH1Controller (ng2SessionService) {
        var vm = this;
        vm.count = count;
        vm.$onInit = activate;

        function activate () {
            vm.domainUrl = ng2SessionService.domain.url;
        }

        function count (content) {
            if (Array.isArray(content)) {
                return content.length;
            } else if (content === null || content === undefined) {
                return 0;
            }

            return 1;
        }
    }
})();
