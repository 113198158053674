import { Injectable } from '@angular/core';
import { CrawlModel } from '../domain-groups-repo/crawl.model';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { ApiService } from '../api-service/api.service';
import { DomainGroupRepoService, DomainGroupRequestType } from '../domain-groups-repo/domain-group-repo.service';
import { map } from 'rxjs/operators';
import { Scan } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { Observable, Subject } from 'rxjs';
import { CrawlModelType } from '../domain-groups-repo/crawl-model.type';
import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

type CrawlHistoryParamsType = {
    from?: string,
    to?: string,
    domain_id?: number,
    group_id?: number,
    module?: string,
    page_size?: number,
}

@Injectable({
    providedIn: 'root',
})
export class CrawlsRepoService {

    constructor (
        private sessionService: SessionService,
        private apiService: ApiService,
        private domainGroupRepoService: DomainGroupRepoService,
    ) { }

    getScanDetails (): Promise<Scan> {
        const params: { group_id?: number } = {};
        const groupId = this.sessionService.getGroupId();
        if (groupId != null) {
            params.group_id = groupId;
        }
        const domainId = this.sessionService.getDomainId();

        if (domainId != null) {
            return this.apiService.get('domains/' + domainId + '/crawls/running', params, {}, contextTokens.NO_GLOBAL);
        } else {
            return Promise.reject(new Error('Missing Domain Id'));
        }
    }

    getAll (params: CrawlHistoryParamsType = {}): Promise<CrawlModel[]> {
        if (this.sessionService.domainGroup !== null || !isNaN(params.group_id as number)) {
            return this.domainGroupRepoService.getHistory(params);
        }

        return this.getHistoryForDomain(params);
    }

    getAllObservable (params: DomainGroupRequestType = {}, canceler$: Subject<void>): Observable<CrawlModel[]> {
        if (this.sessionService.domainGroup !== null || !isNaN(params.group_id as number)) {
            return this.domainGroupRepoService.getHistoryObservable(params, canceler$);
        }

        return this.getHistoryForDomainObservable(params, canceler$);
    }

    private getHistoryForDomain (params: DomainGroupRequestType): Promise<CrawlModel[]> {
        const domainId = params.domain_id != null ? params.domain_id : this.sessionService.getDomainId();
        const route = `domains/${domainId}/crawls`;

        return this.apiService
            .get<CrawlModelType[]>(route, params)
            .then(this.toModels);
    }

    private getHistoryForDomainObservable (params: DomainGroupRequestType, canceler$: Subject<void>): Observable<CrawlModel[]> {
        const domainId = params.domain_id != null ? params.domain_id : this.sessionService.getDomainId();
        const route = `domains/${domainId}/crawls`;

        return this.apiService
            .getObservable<CrawlModelType[]>(route, params, {}, canceler$)
            .pipe(map(this.toModels));
    }

    private toModels (models: CrawlModelType[]): CrawlModel[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = new CrawlModel(models[i]);
        }

        return models;
    }
}
