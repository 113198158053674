(function () {
    'use strict';

    angular.module('modules.export').factory('exporterDataPrivacyParser', exporterDataPrivacyParser);

    exporterDataPrivacyParser.$inject = ['$filter', 'gettextCatalog'];
    /* @ngInject */
    function exporterDataPrivacyParser ($filter, gettextCatalog) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines () {
            var headlines = [
                gettextCatalog.getString('Crawled at'),
                gettextCatalog.getString('Pages Crawled'),
                gettextCatalog.getString('Domain compliance'),
                gettextCatalog.getString('High severity'),
                gettextCatalog.getString('Medium severity'),
                gettextCatalog.getString('Low severity'),
            ];

            return headlines;
        }

        function parse (data) {
            var csv = [];
            var entry = [];
            var iterateEntry;
            if (!data || data.length === 0) {
                return csv;
            }

            csv.push(getHeadlines());

            for (var i = 0; i < data.length; i++) {
                iterateEntry = data[i];
                entry = [];
                entry.push($filter('userDate')(iterateEntry.post_processing_done_at || iterateEntry.date, 'LL LT'));
                entry.push(iterateEntry.page_count);
                entry.push(
                    $filter('monNumber')(
                        iterateEntry.data_protection_pages_with_violations_count / iterateEntry.page_count,
                        '0.[00] %',
                    ),
                );
                entry.push(iterateEntry.data_protection_high_violations_count || 0);
                entry.push(iterateEntry.data_protection_medium_violations_count || 0);
                entry.push(iterateEntry.data_protection_low_violations_count || 0);

                csv.push(entry);
            }
            return csv;
        }
    }
})();
