(function () {
    'use strict';
    angular.module('modules.light.components').component('lightDropdownMenu', {
        templateUrl: 'app/modules/light/components/dropdown-menu/light-dropdown-menu.html',
        controller: lightDropdownMenuComponent,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            isTableView: '<',
        },
    });

    lightDropdownMenuComponent.$inject = [
        'ng2CoreDomainService',
        'ng2MonEventsService',
        'FORM_CONSTANT',
        'LITE_DOMAIN_EVENTS',
        'ng2ActiveFeatureService',
        'MON_MODULES',
    ];
    /* @ngInject */
    function lightDropdownMenuComponent (ng2CoreDomainService, monEventsService, FORM_CONSTANT, LITE_DOMAIN_EVENTS, ng2ActiveFeatureService, MON_MODULES) {
        var vm = this;
        vm.$onInit = activate;
        vm.pinLoading = false;
        vm.toggleDomainPinned = toggleDomainPinned;
        vm.updateDomain = updateDomain;
        vm.openScriptGuide = openScriptGuide;
        vm.deleteDomain = deleteDomain;

        function activate () {
            vm.isConsentManagerEnabled = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.cookie_banner, vm.domain);
            vm.isPageCorrectEnabled = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.page_fix, vm.domain);
        }

        function toggleDomainPinned (domain) {
            vm.pinLoading = true;
            ng2CoreDomainService
                .toggleDomainPinned(domain)
                .then(function (updatedDomain) {
                    monEventsService.run(LITE_DOMAIN_EVENTS.LITE_DOMAIN_UPDATED, updatedDomain);
                }, angular.noop)
                .finally(function () {
                    vm.pinLoading = false;
                });
        }

        function openScriptGuide (domain) {
            ng2CoreDomainService.openScriptGuide(domain, FORM_CONSTANT.FORM_DOMAIN_SCRIPT_GUIDE);
        }

        function updateDomain (domain) {
            ng2CoreDomainService.openDomainForm(domain, FORM_CONSTANT.LITE_DOMAIN_FORM).then(function (domain) {
                monEventsService.run(LITE_DOMAIN_EVENTS.LITE_DOMAIN_UPDATED, domain);
            });
        }

        function deleteDomain (domain) {
            return ng2CoreDomainService.deleteDomain(domain).then(function () {
                monEventsService.run(LITE_DOMAIN_EVENTS.LITE_DOMAINS_LIST_UPDATE_REQUIRED);
            }, angular.noop);
        }
    }
})();
