(function () {
    'use strict';

    angular.module('modules.heatmaps').component('heatmapsDashboardGrid', {
        templateUrl: 'app/modules/heatmaps/pages/dashboard/grid/grid.html',
        controller: HeatmapsDashboardController,
        controllerAs: 'vm',
        bindings: {
            rules: '<',
            getPage: '&',
            loading: '<',
            domains: '<',
        },
    });

    HeatmapsDashboardController.$inject = ['HeatmapApiService', 'ng2SessionService', 'HeatmapService'];

    /* @ngInject */
    function HeatmapsDashboardController (HeatmapApiService, ng2SessionService, HeatmapService) {
        var vm = this;
        vm.$onInit = activate;
        vm.removeRule = removeRule;
        vm.editRule = editRule;
        vm.getLeftOverRules = getLeftOverRules;
        vm.addRule = addRule;
        vm.onClosePageSearch = onClosePageSearch;

        function activate () {
            vm.showSearch = false;
            vm.selectedRule = null;
        }

        function editRule (rule) {
            vm.showSearch = true;
            vm.selectedRule = rule;
        }

        function addRule () {
            vm.showSearch = true;
            vm.selectedRule = null;
        }

        function removeRule (rule, index) {
            HeatmapService.confirmDelete(rule).then(function () {
                HeatmapApiService.destroy(rule.domain_id, rule.id).then(function () {
                    vm.rules.splice(index, 1);
                }, angular.noop);
            }, angular.noop);
        }

        function getLeftOverRules () {
            if (ng2SessionService.customer && ng2SessionService.customer.plan_traits.heatmaps_tracked_number_of_pages > vm.rules.length) {
                return new Array(ng2SessionService.customer.plan_traits.heatmaps_tracked_number_of_pages - vm.rules.length);
            }

            return new Array(0);
        }

        function onClosePageSearch (rule) {
            if (rule || (rule && vm.selectedRule)) {
                vm.getPage();
            }
            vm.showSearch = false;
            vm.selectedRule = null;
        }
    }
})();
