import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { AccessibilitySummaryEntityType } from '@client/app/modules/accessibility/interfaces/accessibility-summary.interface';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { Page } from '@monsido/modules/page-details/models';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type AccessibilityRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class AccessibilityRepoService extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    async getSummary (): Promise<AccessibilitySummaryEntityType> {
        const params = this.setGroupId();
        return this.apiService.get(`domains/${this.getDomainId()}/accessibility/summary`, params);
    }

    async getAllPages (params: AccessibilityRepoRequestType = {}): Promise<MonTableCollection<Page>> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId()}/accessibility/pages`, params);
    }
    async getPagesWithIgnoredChecks (params: AccessibilityRepoRequestType = {}): Promise<MonTableCollection<Page>> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId()}/accessibility/ignored-checks/pages`, params);
    }

}
