(function () {
    'use strict';

    angular.module('services.client').factory('Client', Client);

    Client.$inject = ['ng2HttpLegacyService', 'Lodash'];
    /* @ngInject */
    function Client (ng2HttpLegacyService, Lodash) {
        function Model (config) {
            this.config = config || {};
            this.config.url = this.config.url || '';
        }

        Model.prototype.get = callFrom('GET');
        Model.prototype.post = callFrom('POST');
        Model.prototype.patch = callFrom('PATCH');
        Model.prototype.put = callFrom('PUT');
        Model.prototype.delete = callFrom('DELETE');

        return Model;

        // //////////////

        function executeCallback (that, promise, customCallback) {
            if (customCallback) {
                promise = promise.then((data) => {
                    if (typeof customCallback === 'function') {
                        customCallback(data);
                    }
                    return data;
                });
            } else if (that.defaultSuccessCallback) {
                promise = promise.then((data) => {
                    if (typeof that.defaultSuccessCallback === 'function') {
                        that.defaultSuccessCallback(data);
                    }
                    return data;
                });
            }

            return promise;
        }

        function callWithData (that, method, url, body, params, customConfig, customCallback) {
            that.config = that.config || {};
            customConfig = customConfig || {};
            var config = Lodash.merge({}, that.config, customConfig);

            if (params) {
                config.params = params;
            }

            var promise = ng2HttpLegacyService.makeRequest(method, url, body, config);
            return executeCallback(that, promise, customCallback);
        }

        function callWithoutData (that, method, url, data, customConfig, customCallback) {
            that.config = that.config || {};
            customConfig = customConfig || {};
            var config = Lodash.merge({}, that.config, customConfig);

            if (data) {
                config.params = data;
            }

            var promise = ng2HttpLegacyService.makeRequest(method, url, undefined, config);
            return executeCallback(that, promise, customCallback);
        }

        function callFrom (method) {
            if (method === 'GET' || method === 'DELETE') {
                return function (url, data, config, customCallback) {
                    return callWithoutData(this, method, url, data, config, customCallback);
                };
            } else {
                return function (url, data, params, config, customCallback) {
                    return callWithData(this, method, url, data, params, config, customCallback);
                };
            }
        }
    }
})();
