import { Injectable } from '@angular/core';
import { MeRepoService } from '../../api/me-repo/me-repo.service';
import { UserInterface } from '@monsido/ng2/modules/models/api/interfaces/user.interface';
import { User } from '@monsido/ng2/modules/models/api/user';
import { UserLocaleService } from '@client/app/blocks/service/users/locale.service';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { LoginService } from '@client/app/core/services/login/login.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { ToastService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { TranslateService } from '../../translate/translate.service';
import { cloneDeep } from 'lodash';
import numeral from 'numeral';
import { MeReportingsRepoService, ReportingsCreateParams } from '../../api/me-reportings-repo/me-reportings-repo.service';
import { ReportInterface } from '@monsido/ng2/modules/models/api/interfaces/report.interface';
import { Report } from '@monsido/ng2/modules/models/api/report';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { DomainInterface } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { Reporting } from '@monsido/ng2/modules/models/api/reporting';
import { ReportingInterface } from '@monsido/ng2/modules/models/api/interfaces/reporting.interface';

@Injectable({
    providedIn: 'root',
})
export class MeService {

    constructor (
        private meRepoService: MeRepoService,
        private userLocaleService: UserLocaleService,
        private monEventService: MonEventService,
        private loginService: LoginService,
        private sessionService: SessionService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private meReportingsRepoService: MeReportingsRepoService,
    ) { }

    update (profile: UserInterface): Promise<User> {

        return this.meRepoService.update(profile).then((me) => {
            const locale = this.userLocaleService.getNumeralValue(me.locale);
            this.monEventService.run('numeraljsSetLocale', locale);
            numeral.locale(locale);
            this.loginService.setMe(me);
            this.toastService.success(this.translateService.getString('Profile updated'));
            // Set the updated settings to current agreement
            if (this.sessionService.agreement && me.settings) {
                this.sessionService.agreement.settings = cloneDeep(me.settings);
            }
            return me;
        });
    }

    createReport (params: Partial<ReportInterface>): Promise<Report> {
        return this.meRepoService.createReport(params);
    }

    updateReport (reportId: number, data: Partial<ReportInterface>): Promise<Report> {
        return this.meRepoService.updateReport(reportId, data);
    }

    deleteReport (reportId: number): Promise<void> {
        return this.meRepoService.deleteReport(reportId);
    }

    updateDomain (domainId: number, data: Partial<DomainInterface>): Promise<Domain> {
        return this.meRepoService.updateDomain(domainId, data);
    }

    createReporting (params: ReportingsCreateParams): Promise<Reporting> {
        return this.meReportingsRepoService.create(params);
    }

    updateReporting (id: number, data: Partial<ReportingInterface>): Promise<Reporting> {
        return this.meReportingsRepoService.update(id, data);
    }
}
