import { Component } from '@angular/core';
import { Reporting } from '@monsido/modules/models/api/reporting';
import { MonTableCollection } from 'ng2/models/table-collection.interface';

@Component({
    selector: 'mon-received-reports-container',
    templateUrl: './received-reports-container.component.html',
    styleUrls: ['./received-reports-container.component.scss'],
})
export class ReceivedReportsContainerComponent {
    search: string = '';
    receivedReports: MonTableCollection<Reporting> = [];
    loading = true;
    total: number = 0;

    constructor () {}

    onSearch (term: string): void {
        this.search = term;
    }

    setTotal (total: number): void {
        this.total = total;
    }
}
