(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormDomainSettings', {
        templateUrl: 'app/modules/page-fix/forms/domain-settings/domain-settings.html',
        controller: PageFixDomainSettingsController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            saveToAPI: '<',
            monDialogId: '@',
        },
    });

    PageFixDomainSettingsController.$inject = [
        'monDialog',
        'PAGE_CORRECT_DOMAIN_SETTINGS_MODULE',
        'Lodash',
        'ng2DomainService',
        'gettextCatalog',
        'ng2ToastService',
        '$deepmerge',
        '$timeout',
    ];

    /* @ngInject */
    function PageFixDomainSettingsController (
        monDialog,
        PAGE_CORRECT_DOMAIN_SETTINGS_MODULE,
        Lodash,
        ng2DomainService,
        gettextCatalog,
        toastService,
        $deepmerge,
        $timeout,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.close = close;
        vm.updateValid = updateValid;

        function activate () {
            vm.settings = {
                admin_only: !!vm.domain.settings.scripts.page_correct.admin_only,
                checks: {
                    spelling: {
                        name: 'SPELLING_ERROR',
                        value: Lodash.some(vm.domain.settings.scripts.page_correct.enabled_checks, function (check) {
                            return PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.SPELLING_ERROR === check;
                        }),
                    },
                    accessibility_source_code: {
                        name: 'ACCESSIBILITY_SOURCE_CODE',
                        value: Lodash.some(vm.domain.settings.scripts.page_correct.enabled_checks, function (check) {
                            return PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_SOURCE_CODE === check;
                        }),
                    },
                    accessibility_check: {
                        name: 'ACCESSIBILITY_CHECK',
                        value: Lodash.some(vm.domain.settings.scripts.page_correct.enabled_checks, function (check) {
                            return PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK === check;
                        }),
                    },
                    link: {
                        name: 'LINK',
                        value: Lodash.some(vm.domain.settings.scripts.page_correct.enabled_checks, function (check) {
                            return PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.LINK === check;
                        }),
                    },
                    custom: {
                        name: 'CUSTOM',
                        value: Lodash.some(vm.domain.settings.scripts.page_correct.enabled_checks, function (check) {
                            return PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.CUSTOM === check;
                        }),
                    },
                },
            };
            vm.saving = false;
            vm.isValid = true;
        }

        function submit () {
            vm.saving = true;
            var checks = Object.keys(vm.settings.checks)
                .map(function (checkKey) {
                    var check = vm.settings.checks[checkKey];
                    if (check.value) {
                        return PAGE_CORRECT_DOMAIN_SETTINGS_MODULE[check.name];
                    }
                })
                .filter(Boolean);
            var pageCorrectSettings = {
                admin_only: vm.settings.admin_only,
                enabled_checks: checks,
            };
            if (vm.saveToAPI) {
                ng2DomainService
                    .update(vm.domain.id, {
                        settings: {
                            scripts: {
                                page_correct: pageCorrectSettings,
                            },
                        },
                    })
                    .then(function () {
                        toastService.success(gettextCatalog.getString('PageCorrect Settings Updated'));
                        var pageCorrectSettings = $deepmerge({}, vm.domain.settings.scripts.page_correct);
                        pageCorrectSettings.admin_only = vm.settings.admin_only;
                        pageCorrectSettings.enabled_checks = checks;
                        close(pageCorrectSettings);
                    }, angular.noop)
                    .finally(function () {
                        vm.saving = false;
                    });
            } else {
                close(pageCorrectSettings);
            }
        }

        function updateValid () {
            $timeout(function () {
                vm.isValid = Object.keys(vm.settings.checks).some(function (checkKey) {
                    var check = vm.settings.checks[checkKey];
                    return check.value;
                });
            });
        }

        // PROTECTED

        function close (settings) {
            monDialog.close(vm.monDialogId, settings);
        }
    }
})();
