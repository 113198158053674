<mon-spinner [loading]="loading || loadingIssueData">
    <mon-issue [id]="issueId"
               [title]="issueTitle"
               [status]="issueStatus"
               [issueNotFound]="!loadingIssueData && !issueData"
               class="mon-qa-broken-link-issue-container">
        <div slot="issue-action-button" *ngIf="issueData">
            <button class="btn btn-border-radius btn-secondary mr-1"
                    ngbTooltip="{{'Inspect page with browser extension' | translate}}"
                    (click)="openInExtension(page)"
                    [container]="'body'"
                    *ngIf="issueStatus !== IssueStatusEnum.fixed">
                    <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
                    <span class="sr-only" translate>Open browser extension</span>
            </button>
            <mon-action-menu class="mr-1" [monActionMenuItems]="dropdownOptions">
                <span translate>Action</span>
            </mon-action-menu>
        </div>
        <div slot="issue-overview">
            <h3 class="font-weight-300 mt-3 mx-4" translate>Issue details</h3>
            <hr>
            <div class="row ml-1 mt-4">
                <div class="col-12">
                    <b translate>Element</b><br>
                    <span>{{ elementType }}</span>
                </div>
                <div class="col-12">
                    <b translate>Response code</b><br>
                    <span>{{ issueData?.status_code }}</span>
                </div>
                <div class="col-12">
                    <b translate>Date found</b><br>
                    <span>{{ issueData?.created_at }}</span>
                </div>
            </div>
            <div class="row ml-1 mr-4 mt-4" *ngIf="issueType === 'qaBrokenLink'">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <b translate>Snippet</b>
                        </div>
                    </div>
                    <div class="ml-0 issue-overview-text">
                        <div class="p-2 mt-1 bg-danger">
                            <span class="text-danger text-multiline">
                                {{ issueDataUrl }}
                            </span>
                            <button *ngIf="issueData && issueData.url.length > characterLimit" (click)="toggleCharacterLimit()" 
                                class="btn btn-border-radius btn-link">
                                <span *ngIf="issueData.url.length > issueDataUrl.length" attr.aria-label="{{ 'Show the full url snippet' | translate }}" translate>Show more</span>
                                <span *ngIf="!(issueData.url.length > issueDataUrl.length)" attr.aria-label="{{ 'Show less of the url snippet' | translate }}" translate>Show less</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ml-1 mr-4 mt-4" *ngIf="issueType === 'qaBrokenImage'">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <b translate>Image URL</b>
                        </div>
                    </div>
                    <div class="row ml-0 p-0 alert alert-danger">
                        <div class="broken-image-col p-2">
                            <div class="broken-image-wrapper text-center p-3">
                                <img src="/images/qa/broken-image.png" alt="">
                            </div>
                        </div>
                        <div class="col p-2">
                            {{issueData?.url}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ml-1 mr-4 mt-4 mb-6">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <b translate>Found on page</b>
                        </div>
                    </div>
                    <div class="ml-0">
                        <div class="row">
                            <div *ngIf="page" class="col-48">
                                <mon-url-title [title]="page.title" [url]="page.url" [active]="false"></mon-url-title>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="issue-content">
            <h3 class="font-weight-300 mt-3 mx-4">{{ contentTableName }}</h3>
            <hr>
            <div class="m-4">
                <mon-table-container [collection]="pages"
                                     [loading]="loadingPages"
                                     (pageChangeEvent)="onContentPageChange($event, getPagesParams)"
                                     (perPageChange)="onContentPerPageChange($event, getPagesParams)">
                    <div class="table-container-body">
                        <table class="table table-hover table-fixed table-with-labels">
                            <thead>
                            <tr>
                                <th
                                    monTableSort
                                    [monSortBy]="'title'"
                                    [monSortDirection]="getPagesParams.value.sort_dir || 'asc'"
                                    [monCurrentlySortedBy]="getPagesParams.value.sort_by || ''"
                                    (monOnSorted)="onSortContent($event, getPagesParams)">
                                    {{ 'Title and URL' | translate }}
                                </th>
                                <th class="col-6 text-right"
                                    monTableSort
                                    [monSortBy]="'priority_score'"
                                    [monSortDirection]="getPagesParams.value.sort_dir || 'asc'"
                                    [monCurrentlySortedBy]="getPagesParams.value.sort_by || ''"
                                    (monOnSorted)="onSortContent($event, getPagesParams)">
                                    {{ 'Priority' | translate }}
                                </th>
                                <th class="pl-5 col-7 text-right"
                                    monTableSort
                                    *ngIf="viewsColumIsAvailable"
                                    [monSortBy]="'hits'"
                                    [monSortDirection]="getPagesParams.value.sort_dir || 'asc'"
                                    [monCurrentlySortedBy]="getPagesParams.value.sort_by || ''"
                                    (monOnSorted)="onSortContent($event, getPagesParams)">
                                    {{ 'Views' | translate }}
                                </th>
                                <th class="col-20 col-lg-8 col-md-12 col-sm-16 col-xl-6">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let page of pages">
                                <td>
                                    <div class="text-ellipsis">
                                        <mon-url-title [title]="page.title" [url]="page.url" [active]="false"></mon-url-title>
                                    </div>
                                </td>
                                <td class="text-v-middle text-right">
                                    <mon-priority class="vcenter" [priority]="page.priority_score_as_text || 'low'"></mon-priority>
                                </td>
                                <td *ngIf="viewsColumIsAvailable" class="pl-5 text-v-middle text-right">
                                    <mon-visits [title]="page.title" [visits]="page.hits" [totalVisits]="pages.pageHits || 0"></mon-visits>
                                </td>
                                <td class="text-v-middle">
                                    <div class="d-flex align-items-center flex-wrap justify-content-end">
                                        <button class="btn btn-border-radius btn-secondary"
                                                ngbTooltip="{{'Open page details' | translate}}"
                                                (click)="goToPageDetails(page)"
                                                [container]="'body'">
                                            <mon-icon [icon]="['TABLES', 'PAGEDETAILS']"></mon-icon>
                                            <span class="sr-only" translate>Open page details</span>
                                        </button>
                                        <button class="btn btn-border-radius btn-secondary ml-1"
                                                ngbTooltip="{{'Inspect page with browser extension' | translate}}"
                                                (click)="openInExtension(page)"
                                                [container]="'body'">
                                            <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
                                            <span class="sr-only" translate>Open browser extension</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mon-table-container>
            </div>
        </div>
        <div slot="issue-documents">
            <h3 class="font-weight-300 mt-3 mx-4">{{ documentTableName }}</h3>
            <hr>
            <div class="m-4">
                <mon-table-container [collection]="documents"
                                    [loading]="loadingDocuments"
                                    (pageChangeEvent)="onContentPageChange($event, getDocumentsParams)"
                                    (perPageChange)="onContentPerPageChange($event, getDocumentsParams)">
                    <div class="table-container-body">
                        <table class="table table-hover table-fixed table-with-labels">
                            <thead>
                            <tr>
                                <th
                                    monTableSort
                                    [monSortBy]="'title'"
                                    [monSortDirection]="getDocumentsParams.value.sort_dir || 'asc'"
                                    [monCurrentlySortedBy]="getDocumentsParams.value.sort_by || ''"
                                    (monOnSorted)="onSortContent($event, getDocumentsParams)">
                                    {{ 'Title and URL' | translate }}
                                </th>
                                <th class="col-6 text-right"
                                    monTableSort
                                    *ngIf="viewsColumIsAvailable"
                                    [monSortBy]="'hits'"
                                    [monSortDirection]="getDocumentsParams.value.sort_dir || 'asc'"
                                    [monCurrentlySortedBy]="getDocumentsParams.value.sort_by || ''"
                                    (monOnSorted)="onSortContent($event, getDocumentsParams)">
                                    {{ 'Views' | translate }}
                                </th>
                                <th class="col-9">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let document of documents">
                                <td>
                                    <div class="text-ellipsis">
                                        <mon-url-title [title]="document.title" [url]="document.url" [active]="false"></mon-url-title>
                                    </div>
                                </td>
                                <td *ngIf="viewsColumIsAvailable" class="text-v-middle text-right">
                                    <mon-visits [title]="document.title" [visits]="document.hits" [totalVisits]="documents.pageHits || -1"></mon-visits>
                                </td>
                                <td class="text-v-middle">
                                    <div class="d-flex align-items-center flex-wrap justify-content-end">
                                        <button class="btn btn-border-radius btn-secondary"
                                                ngbTooltip="{{'Open document details' | translate}}"
                                                (click)="goToDocumentDetails(document)"
                                                [disabled]="openingDocumentDetails"
                                                [container]="'body'">
                                            <mon-icon [icon]="['TABLES', 'PAGEDETAILS']"></mon-icon>
                                            <span class="sr-only" translate>Open document details</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mon-table-container>
            </div>
        </div>
    </mon-issue>
</mon-spinner>
