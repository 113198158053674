(function () {
    'use strict';

    angular.module('app.pages', [
        'core.templates',
        'core.constant',
        'ui.bootstrap.tpls',
        'modules.modals',

        'cfp.hotkeys',
        'services.api',
        'plugins.lodash',
        'mon.helpers.param',

        'pages.pages',
        'pages.base',
        'pages.login',
        'pages.dashboard',
        'pages.admin',
        'pages.domain',
        'pages.qa',
        'pages.inventory',
        'pages.seo',
        'pages.accessibility',
        'pages.uptimes',
        'pages.statistics',
        'pages.global',
        'pages.policy',
        'pages.introduction',
    ]);
})();
