(function () {
    'use strict';

    angular.module('modules.rollbar').run([
        'ng2MonEventsService',
        'devMode',
        'Rollbar',
        function (ng2MonEventsService, devMode, Rollbar) {
            if (!devMode && !(window.location.hostname === 'localhost')) {
                ng2MonEventsService.addListener('setAgreement', (agreement) => {
                    const config = Rollbar.Rollbar.options;
                    config.payload.customer = {
                        id: agreement.account.id,
                        name: agreement.account.name,
                    };
                    Rollbar.configure(config);
                });

                ng2MonEventsService.addListener('logout', () => {
                    const config = Rollbar.Rollbar.options;
                    config.payload.person = null;
                    config.payload.customer = null;
                    Rollbar.configure(config);
                });

                ng2MonEventsService.addListener('afterLoadUser', (user) => {
                    const config = Rollbar.Rollbar.options;
                    config.payload.person = {
                        id: user.id,
                    };
                    Rollbar.configure(config);
                });

                ng2MonEventsService.addListener('newException', (errorMsg, severity) => {
                    switch (severity) {
                        case 'debug':
                            Rollbar.debug(errorMsg);
                            break;
                        case 'info':
                            Rollbar.info(errorMsg);
                            break;
                        case 'warning':
                            Rollbar.warning(errorMsg);
                            break;
                        case 'error':
                            Rollbar.error(errorMsg);
                            break;
                        case 'critical':
                            Rollbar.critical(errorMsg);
                            break;
                        default:
                            Rollbar.error(errorMsg);
                    }
                });
            }
        },
    ]);
})();
