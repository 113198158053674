(function () {
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixAccessibilityChecksLinks', {
        templateUrl: 'app/modules/page-fix/pages/links/links.html',
        controller: PageFixAccessibilityChecksLinksController,
        controllerAs: 'vm',
    });

    PageFixAccessibilityChecksLinksController.$inject = ['PAGE_FIX_ACCESSIBILITY_CHECK_TAB'];
    /* @ngInject */
    function PageFixAccessibilityChecksLinksController (PAGE_FIX_ACCESSIBILITY_CHECK_TAB) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.tab = PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS;
        }
    }
})();
