import { NgModule } from '@angular/core';
import { MonLegacyBrowserNotificationComponent } from './legacy-browser-notification.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    exports: [MonLegacyBrowserNotificationComponent],
    declarations: [MonLegacyBrowserNotificationComponent],
    providers: [],
})
export class MonLegacyBrowserModule {}
