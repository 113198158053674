import { Injectable } from '@angular/core';
import { DomainRepoGetParamsType, DomainRepoRescanParamsType, DomainRepoService } from '../../api/domain-repo/domain-repo.service';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { DomainInterface } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';

@Injectable({
    providedIn: 'root',
})
export class DomainService {
    constructor (
        private domainRepoService: DomainRepoService,
        private eventService: MonEventService,
    ) {}

    get (id: number, params?: DomainRepoGetParamsType): Promise<Domain> {
        return this.domainRepoService.get(id, params);
    }

    create (domain: unknown): Promise<Domain> {
        return this.domainRepoService.create(domain).then(createdDomain => {
            this.eventService.run('domainAdded', createdDomain);
            return createdDomain;
        });
    }

    update (id: number, params: Partial<DomainInterface>): Promise<Domain> {
        return this.domainRepoService.update(id, params).then(updatedDomain => {
            this.eventService.run('domainUpdated', updatedDomain);
            return updatedDomain;
        });
    }

    rescan (id: number, params?: DomainRepoRescanParamsType): Promise<void> {
        return this.domainRepoService.rescan(id, params);
    }

    destroy (id: number): Promise<void> {
        return this.domainRepoService.destroy(id);
    }

    stopCrawl (id: number): Promise<void> {
        return this.domainRepoService.stopCrawl(id);
    }
}
