(function () {
    'use strict';
    angular.module('app.components.pages').component('pagesTable', {
        templateUrl: 'app/components/pages/pages.html',
        controller: Pages,
        controllerAs: 'vm',
        bindings: {
            pages: '<',
            onPageChange: '&',
            onPerPageChange: '&',
            onPageSort: '&',
            progress: '<',
            showNotifications: '<?',
            tab: '<?',
            isFiltersOpen: '=?',
            onFiltersChanges: '&',
            currentSort: '<?',
            callBack: '&?',
            target: '<?',
            subTarget: '<?',
            ref: '@?',
        },
    });

    Pages.$inject = [
        'gettextCatalog',
        '$controller',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2ActiveFeatureService',
        'ng2LabelService',
    ];

    function Pages (
        gettextCatalog,
        $controller,
        ng2MonEventsService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2ActiveFeatureService,
        ng2LabelService,
    ) {
        var vm = this;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.pageTitle = pageTitle;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.createOrUpdateLabel = createOrUpdateLabel;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.currentSort = vm.currentSort || {
                by: '',
                direction: '',
            };
            vm.hideNotifications = vm.hideNotifications || [];
            vm.tab = vm.tab || '';
            vm.isFiltersOpen = vm.isFiltersOpen || false;
            vm.filters.contains = [];
            vm.hasAccessToStats = ng2ActiveFeatureService.isFeatureActive('statistics');
            vm.availableFilters.contains = [];
            if (ng2ActiveFeatureService.isFeatureActivePure('seo_all')) {
                vm.availableFilters.contains.push({
                    id: 'seo_issues',
                    notificationValue: 'seo',
                    name: gettextCatalog.getString('SEO Issues'),
                });
            }
            if (ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck')) {
                vm.availableFilters.contains.push({
                    id: 'confirmed_spelling_errors',
                    notificationValue: 'misspellings',
                    name: gettextCatalog.getString('Misspellings'),
                });
            }
            if (ng2ActiveFeatureService.isFeatureActivePure('qa_links')) {
                vm.availableFilters.contains.push({
                    id: 'broken_links',
                    notificationValue: 'dead-links',
                    name: gettextCatalog.getString('Broken Links'),
                });
            }
            if (ng2ActiveFeatureService.isFeatureActivePure('policies_all')) {
                vm.availableFilters.contains.push({
                    id: 'policy_errors',
                    notificationValue: 'policies',
                    name: gettextCatalog.getString('Policy errors'),
                });
            }
            if (ng2ActiveFeatureService.isFeatureActivePure('admin_data_privacy')) {
                vm.availableFilters.contains.push({
                    id: 'data_protection_violations',
                    notificationValue: 'data_protection_violations',
                    name: gettextCatalog.getString('Data Privacy violations'),
                });
            }

            if (Array.isArray(vm.showNotifications)) {
                vm.availableFilters.contains = vm.availableFilters.contains.filter(function (contain) {
                    return vm.showNotifications.indexOf(contain.notificationValue) > -1;
                });
            }
            vm.issueType = null;
            if (vm.target === 'page-details-section-seo') {
                vm.issueType = 'seo';
            } else if (vm.target === 'page-details-section-qa') {
                switch (vm.subTarget) {
                    case 'brokenimages':
                        vm.issueType = 'bimg';
                        break;
                    case 'brokenlinks':
                        vm.issueType = 'blnk';
                        break;
                    case 'misspellings':
                        vm.issueType = 'mspel';
                        break;
                    case 'potential':
                        vm.issueType = 'mspel';
                        break;
                }
            }
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ size: size });
        }

        function pageTitle (title) {
            return title || '(' + gettextCatalog.getString('No title found') + ')';
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }

        function createOrUpdateLabel (label) {
            ng2LabelService.createOrUpdateLabel(label);
        }
    }
})();
