import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateReportPipe } from './translate-report/translate-report.pipe';
import { ReportCenterIconPipe } from './report-center-icon/report-center-icon.pipe';
import {
    TranslateReportTitlePipe,
} from './translate-report-title/translate-report-title.pipe';

const allModules = [
    TranslateReportPipe,
    TranslateReportTitlePipe,
    ReportCenterIconPipe,
];

@NgModule({
    declarations: [...allModules],
    imports: [
        CommonModule,
    ],
    providers: [TranslateReportPipe],
    exports: [...allModules],
})
export class ReportCenterPipesModule {}
