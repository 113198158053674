(function () {
    'use strict';

    angular.module('app.components').component('stackUsers', {
        templateUrl: 'app/components/stack-users/stack-users.html',
        controller: StackUsersComponents,
        controllerAs: 'vm',
        bindings: {
            users: '<',
            size: '@?', // Size of avatars
        },
    });

    StackUsersComponents.$inject = [];

    function StackUsersComponents () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.size = vm.size || '18';
        }
    }
})();
