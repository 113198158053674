(function () {
    'use strict';

    angular.module('pages.admin.labels').component('labelBlockedByDomainGroups', {
        templateUrl: 'app/pages/admin/labels/modals/label-blocked-by-domain-groups-modal.html',
        controller: LabelBlockedByDomainGroupsController,
        controllerAs: 'vm',
        bindings: {
            label: '<',
            domains: '<',
            monDialogId: '@?',
        },
    });

    LabelBlockedByDomainGroupsController.$inject = ['monDialog', '$state', 'ng2MonUIRouterTransitionService'];
    /* @ngInject */
    function LabelBlockedByDomainGroupsController (monDialog, $state, ng2MonUIRouterTransitionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;
        vm.openDomainsSettings = openDomainsSettings;

        function activate () {}

        function close () {
            monDialog.close(vm.monDialogId);
        }

        function openDomainsSettings () {
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => {
                    close();
                    $state.go('base.customer.admin.domains.index');
                });
        }
    }
})();
