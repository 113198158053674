(function () {
    'use strict';
    angular.module('directives.monsido').directive('monSetBackgroundColor', [
        '$filter',
        function ($filter) {
            return {
                restrict: 'A',
                link: SetBackgroundColorLinkController,
                scope: {
                    color: '@',
                    count: '<',
                    maxCount: '<',
                    percentage: '=?',
                },
            };

            function SetBackgroundColorLinkController (scope, elem) {
                var color = toRgb(scope.color);
                var opacity = $filter('number')(scope.count / scope.maxCount, 2);
                scope.percentage = opacity;
                elem.attr('style', 'background-color: rgba(' + color + ', ' + opacity + ') !important; --bs-table-bg-state: transparent;');
            }

            function toRgb (color) {
                if (/^#[0-9A-F]{6}$/i.test(color)) {
                    var c = color.substring(1).split('');
                    if (c.length === 3) {
                        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
                    }
                    c = '0x' + c.join('');
                    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
                }

                return color;
            }
        },
    ]);
})();
