(function () {
    'use strict';

    angular.module('modules.source-code-excludes').component('formSourceCodeExcludeSCE', {
        templateUrl:
            'app/modules/source-code-excludes/form/source-code-exclude/modules/source-code-exclude/source-code-exclude.html',
        controller: FormSourceCodeExcludeController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
        },
    });

    FormSourceCodeExcludeController.$inject = ['$deepmerge', 'MON_CMS'];

    /* @ngInject */
    function FormSourceCodeExcludeController ($deepmerge, MON_CMS) {
        var vm = this;
        vm.$onInit = activate;
        vm.addRule = addRule;
        vm.removeRule = removeRule;

        function activate () {
            vm.pageSize = 10;
            vm.page = 1;
            vm.defaultAccessibilityChecksOptions = [];
            vm.cmses = $deepmerge([], MON_CMS);
            if (!Array.isArray(vm.ngModel.rules)) {
                vm.ngModel.rules = [];
            }

            if (typeof vm.ngModel.id !== 'number' && vm.ngModel.rules.length === 0) {
                addRule();
            }
        }

        function addRule () {
            vm.ngModel.rules.unshift({ operator: 'css', value: '' });
            vm.page = 1;
        }

        function removeRule (index) {
            vm.ngModel.rules.splice(vm.pageSize * (vm.page - 1) + index, 1);
        }
    }
})();
