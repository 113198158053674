import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { RequestAuxiliaryService } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class SearchInterceptor implements HttpInterceptor {
    constructor (private requestAuxiliaryService: RequestAuxiliaryService) {}
    intercept (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let data = req.clone();
        if (data.method === 'GET') {
            if (data.params.get('search') === undefined || data.params.get('search') === 'undefined') {
                data = data.clone({
                    params: data.params.delete('search'),
                });
                return next.handle(data);
            }

            if (data.params.get('search') === null || data.params.get('search')?.length === 0) {
                data = data.clone({
                    params: data.params.delete('search'),
                });
                return next.handle(data);
            }

            if (!this.requestAuxiliaryService.hasNoParseSearch(req.context)) {
                const search = data.params.get('search');
                data = data.clone({
                    params: data.params.delete('search'),
                });

                data = data.clone({
                    params: data.params.append('search', '%' + search + '%'),
                });
                return next.handle(data);
            }
        }
        return next.handle(data);
    }
}
