import { Pipe, PipeTransform } from '@angular/core';
import { TranslateReportService } from '../../services/translate-report/translate-report.service';

@Pipe({
    name: 'translateReport',
    pure: false,
})
export class TranslateReportPipe implements PipeTransform {
    constructor (
        private translateReportService: TranslateReportService,
    ) {}

    transform (value: string): string {
        return this.translateReportService.getTranslation(value);
    }
}
