import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ScrollableDropdownMenuItem, ScrollableDropdownOnSelectPayload, ScrollableDropdownOnClosePayload } from '@layout/scrollable-dropdown-menu/scrollable-dropdown-menu.interface';

export interface LanguageOption {
    name: string;
    value: string;
}

@Component({
    selector: 'mon-cookie-overview-language-tabs',
    templateUrl: './cookie-overview-language-tabs.html',
    styleUrls: ['./cookie-overview-language-tabs.scss'],
})
export class MonCookieOverviewLanguageTabs implements OnChanges {
    @Input() monLanguages: LanguageOption[] = [];
    @Input() monShowLanguages: LanguageOption[] = [];
    @Input()
    get monModel (): string {
        return this.modelValue;
    }

    set monModel (val: string) {
        this.modelValue = val;
        this.monModelChange.emit(this.modelValue);
    }

    @Output() monModelChange: EventEmitter<string> = new EventEmitter();
    @Output() monOnAddLanguage: EventEmitter<LanguageOption> = new EventEmitter();
    @Output() monOnRemoveLanguage: EventEmitter<LanguageOption> = new EventEmitter();

    @ViewChild('addButtonRef')
        addButtonRef?: ElementRef;

    modelValue: string = '';
    showLanguageOptionsList = false;
    languageOptionsList: LanguageOption[] = [];
    languageOptionsListView: ScrollableDropdownMenuItem[] = [];

    ngOnChanges (): void {
        this.languageOptionsList = [];
        const displayedLanguages: LanguageOption[] = this.monShowLanguages;

        for (let i = 0; i < this.monLanguages.length; i++) {
            const languageOption = this.monLanguages[i];
            if (!displayedLanguages.find(lang => lang.value === languageOption.value)) {
                this.languageOptionsList.push(languageOption);
            }
        }
        this.languageOptionsListView = this.languageOptionsList.map(
            langOption => {
                return {
                    icons: [],
                    text: langOption.name,
                    id: langOption.value,
                };
            },
        );
    }

    onTabClick (language: LanguageOption): void {
        this.monModel = language.value;
    }

    onRemoveClick (language: LanguageOption): void {
        this.monOnRemoveLanguage.emit(language);
    }

    onAddLanguage (selectedItem: ScrollableDropdownOnSelectPayload): void {
        const language = this.languageOptionsList.find(
            langOption => langOption.value === selectedItem.data.id,
        );
        this.monOnAddLanguage.emit(language);
        this.hideLanguagesList();
    }

    showLanguagesList ($event?: MouseEvent): void {
        this.showLanguageOptionsList = true;
        if ($event) {
            $event.stopImmediatePropagation();
        }
    }

    hideLanguagesList (payload?: ScrollableDropdownOnClosePayload): void {
        this.showLanguageOptionsList = false;

        if (payload?.recoverFocus) {
            this.addButtonRef?.nativeElement.focus();
        }
    }
}
