(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormWizardContainerFooterClose', {
        templateUrl: 'app/modules/page-fix/forms/wizard-container/footers/close/close.html',
        controller: PageFixFormWizardContainerFootersCloseController,
        controllerAs: 'vm',
        bindings: {
            close: '&',
        },
    });

    PageFixFormWizardContainerFootersCloseController.$inject = [];
    /* @ngInject */
    function PageFixFormWizardContainerFootersCloseController () {}
})();
