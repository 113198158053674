import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleDoughnutChartComponent } from './simple-doughnut-chart.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [SimpleDoughnutChartComponent],
    declarations: [SimpleDoughnutChartComponent],
})
export class SimpleDoughnutChartModule {}
