(function () {
    'use strict';

    angular.module('filters.monsido').filter('removeUnderscore', removeUnderscore);

    removeUnderscore.$inject = [];
    /* @ngInject */
    function removeUnderscore () {
        return filter;

        // //////////

        function filter (input) {
            if (input.indexOf('_') === -1) {
                return input;
            }
            return input.replace(/_/g, ' ');
        }
    }
})();
