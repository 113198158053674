import { SnippetInterface } from '@client/app/modules/accessibility/interfaces/snippet-interface';
import { PageFixModelHelper } from '@client/app/modules/page-fix/services/model-helper/page-fix-model-helper';

export class AccessibilityFastTrackModel {

    id: number;
    caused_issues: number;
    effect_on_compliance: number;
    source_code: string;
    created_at: string;

    constructor (data: SnippetInterface) {
        this.id = data.id || 0;
        this.caused_issues = data.caused_issues || 0;
        this.effect_on_compliance = data.effect_on_compliance || 0;
        this.source_code = data.source_code || '';
        this.created_at = data.created_at || '';
    }

    getPageFixSelector (): string {
        return 'q;' + PageFixModelHelper.getSelectorValue(this.source_code);
    }

}
