(function () {
    'use strict';
    /**
     * @memberof modules.fastTrack
     * @ngdoc service
     * @name FastTrackInformationService
     * @description Service to handle Fast Track Information Logical Operations
     */
    angular.module('modules.fastTrack').service('FastTrackInformationService', FastTrackInformationService);

    FastTrackInformationService.$inject = ['gettextCatalog', '$filter', 'ng2FastTrackSessionService'];
    /* @ngInject*/
    function FastTrackInformationService (gettextCatalog, $filter, ng2FastTrackSessionService) {
        var vm = this;
        vm.checks = null;
        vm.snippet = null;
        vm.excludedChecks = {};
        vm.snippetComplianceProperty = '';
        vm.snippetIssuesProperty = '';
        vm.snippetSourceCodeProperty = '';

        function init () {
            ng2FastTrackSessionService.obsSnippetComplianceProperty.subscribe(function (property) {
                vm.snippetComplianceProperty = property;
            });

            ng2FastTrackSessionService.obsSnippetIssuesProperty.subscribe(function (property) {
                vm.snippetIssuesProperty = property;
            });

            ng2FastTrackSessionService.obsSnippetSourceCodeProperty.subscribe(function (property) {
                vm.snippetSourceCodeProperty = property;
            });

            return {
                getSnippetCompliance: getSnippetCompliance,
                getSnippetIssues: getSnippetIssues,
                getSnippetSourceCode: getSnippetSourceCode,
                getAffectedPages: getAffectedPages,
            };
        }

        return init();

        /**
         * @memberOf FastTrackInformationService
         * @desc Get Snippet Compliance value
         * @param snippet - Fast Track Snippet
         */
        function getSnippetCompliance (snippet) {
            return snippet[vm.snippetComplianceProperty];
        }

        /**
         * @memberOf FastTrackInformationService
         * @desc Get Snippet Issues value
         * @param snippet - Fast Track Snippet
         */
        function getSnippetIssues (snippet) {
            return snippet[vm.snippetIssuesProperty];
        }

        /**
         * @memberOf FastTrackInformationService
         * @desc Get Snippet Source Code value
         * @param snippet - Fast Track Snippet
         */
        function getSnippetSourceCode (snippet) {
            return snippet[vm.snippetSourceCodeProperty];
        }

        /**
         * @memberOf FastTrackInformationService
         * @desc Get Affected Pages translation
         * @param count - Pages affected in Fast Track
         */
        function getAffectedPages (count) {
            count = count || 0;
            if (count < 10) {
                return gettextCatalog.getString('Less than 10');
            } else if (count < 50) {
                return gettextCatalog.getString('Less than 50');
            } else if (count < 100) {
                return gettextCatalog.getString('Less than 100');
            } else if (count < 1000) {
                return gettextCatalog.getString('More than {{count}}', {
                    count: $filter('numeraljs')(count / 1000, '0.0') + 'k',
                });
            } else {
                return gettextCatalog.getString('More than {{count}}', {
                    count: $filter('numeraljs')(count, '0a'),
                });
            }
        }

        // PROTECTED
    }
})();
