export const LOCALE_MAP = {
  ar: 'ar_AE',
  as: 'as_IN',
  az: 'az_Latn_AZ',
  be: 'be_BY',
  bg: 'bg',
  bn: 'bn_IN',
  bs: 'bs_Latn_BA',
  ca: 'ca_ES',
  cs: 'cs_CZ',
  cy: 'cy_GB',
  da: 'da_DK',
  de: 'de_DE',
  el: 'el_GR',
  en: 'en_US',
  es: 'es_ES',
  et: 'et_EE',
  eu: 'eu_ES',
  fa: 'fa_IR',
  fi: 'fi_FI',
  fr: 'fr_FR',
  ga: 'ga_IE',
  gd: 'gd_GB',
  gl: 'gl_ES',
  gu: 'gu_IN',
  ha: 'ha_Latn_NG',
  he: 'he_IL',
  hi: 'hi_IN',
  hr: 'hr_HR',
  hu: 'hu_HU',
  hy: 'hy_AM',
  id: 'id_ID',
  ig: 'ig_NG',
  is: 'is_IS',
  it: 'it_IT',
  ja: 'ja_JP',
  ka: 'ka_GE',
  kk: 'kk_KZ',
  km: 'km_KH',
  kn: 'kn_IN',
  ky: 'ky_KG',
  lb: 'lb_LU',
  lt: 'lt_LT',
  lv: 'lv_LV',
  mi: 'mi_NZ',
  mk: 'mk_MK',
  ml: 'ml_IN',
  mr: 'mr_IN',
  ms: 'ms_BN',
  mt: 'mt_MT',
  nb: 'nb_NO',
  ne: 'ne_NP',
  nl: 'nl_NL',
  nn: 'nn_NO',
  or: 'or_IN',
  pa: 'pa_IN',
  pl: 'pl_PL',
  ps: 'ps_AF',
  pt: 'pt_PT',
  rm: 'rm_CH',
  ro: 'ro_RO',
  ru: 'ru_RU',
  rw: 'rw_RW',
  si: 'si_LK',
  sk: 'sk_SK',
  sl: 'sl_SI',
  sr: 'sr_Cyrl_BA',
  sv: 'sv_SE',
  sw: 'sw_KE',
  sq: 'sq_AL',
  ta: 'ta_IN',
  te: 'te_IN',
  tl: 'tl_PH',
  tn: 'tn_BW',
  tr: 'tr_TR',
  tt: 'tt_RU',
  uk: 'uk_UA',
  ur: 'ur_IN',
  uz: 'uz_Latn_UZ',
  vi: 'vi_VN',
  wo: 'wo_SN',
  xh: 'xh_ZA',
  yo: 'yo_NG',
  zu: 'zu_ZA',
  zh: 'zh_CN',
};

export type LOCALE_MAP_TYPE = keyof typeof LOCALE_MAP;
