(function () {
    'use strict';

    angular.module('app.components').component('urlTitleDocumentDetails', {
        template:
            '<url-title title="vm.title" go-to="vm.openDocumentDetails()" active="vm.active" url="vm.url"></url-title>',
        controller: UrlTitleDocumentDetailsController,
        controllerAs: 'vm',
        bindings: {
            active: '<',
            target: '<',
            subTarget: '<?',
            document: '<',
            onClose: '&?',
            title: '<',
            url: '<',
        },
    });

    UrlTitleDocumentDetailsController.$inject = ['ComponentDetailsView'];

    function UrlTitleDocumentDetailsController (ComponentDetailsView) {
        var vm = this;
        vm.$onInit = activate;
        vm.openDocumentDetails = openDocumentDetails;

        function activate () {
            vm.subTarget = vm.subTarget || null;
        }

        function openDocumentDetails () {
            var params = {
                target: vm.target,
                subTarget: vm.subTarget,
            };

            ComponentDetailsView.goToDocumentDetails(vm.document, params, vm.onClose);
        }
    }
})();
