(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc service
     * @name PagePerformanceVersionService
     * @description Service that contains logical operations related to Performance/Lighthouse version used throughout the entire Performance Module
     */
    angular.module('modules.page-performance').service('PagePerformanceVersionService', PagePerformanceVersionService);

    PagePerformanceVersionService.$inject = ['PAGE_PERFORMANCE_VERSION', 'gettextCatalog', '$window'];

    /* @ngInject*/
    function PagePerformanceVersionService (PAGE_PERFORMANCE_VERSION, gettextCatalog, $window) {
        return {
            getVersionInfo: getVersionInfo,
            getEntriesWithVersionChanged: getEntriesWithVersionChanged,
            getSeriesByVersion: getSeriesByVersion,
        };

        /**
         * @memberOf PagePerformanceVersionService
         * @desc Get Performance version information such as label, link to a support article.
         * @param version - the name of version that was used in the performance scan
         */
        function getVersionInfo (version) {
            var versionInfo = PAGE_PERFORMANCE_VERSION[version];
            if (versionInfo) {
                return {
                    id: version,
                    label: gettextCatalog.getString('{{label}}', { label: versionInfo.label }),
                    link: versionInfo.link,
                    metrics: versionInfo.metrics,
                    avgMetrics: versionInfo.avgMetrics,
                };
            }
            return null;
        }

        function getEntriesWithVersionChanged (series, versionFieldName) {
            var result = [];
            var previousEntry;
            for (var i = 0; i < series.length; i++) {
                var entry = series[i];
                if (i > 0) {
                    previousEntry = series[i - 1];
                    if (entry[versionFieldName] !== previousEntry[versionFieldName]) {
                        result.push(entry);
                    }
                }
            }

            return result;
        }

        function getSeriesByVersion (inputSeries, versionFieldName) {
            var seriesByVersion = {};
            var versions = [];
            for (var i = 0; i < inputSeries.length; i++) {
                var entry = inputSeries[i];
                var version = entry[versionFieldName];
                if (!seriesByVersion[version]) {
                    seriesByVersion[version] = [];
                    versions.push(version);
                }
                seriesByVersion[version].push(entry);
            }
            return {
                series: seriesByVersion,
                versions: versions
                    .map(function (version) {
                        return getVersionInfo(version);
                    })
                    .reverse(),
            };
        }
    }
})();
