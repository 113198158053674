import { Injectable } from '@angular/core';
import { ConsentManagerOptionsInterface } from './consent-manager-options.interface';
import { CONSENT_MANAGER_CONSTANT } from './consent-manager.constant';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Injectable()
export class ConsentManagerOptionsService {

    options: ConsentManagerOptionsInterface = {} as ConsentManagerOptionsInterface;

    constructor (private translateService: TranslateService) {
        this.options.directions = [
            {
                name: this.translateService.getString('Top left'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.TOPLEFT,
            },
            {
                name: this.translateService.getString('Top center'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.TOPCENTER,
            },
            {
                name: this.translateService.getString('Top right'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.TOPRIGHT,
            },
            {
                name: this.translateService.getString('Left middle'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.LEFT,
            },
            {
                name: this.translateService.getString('Right middle'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.RIGHT,
            },
            {
                name: this.translateService.getString('Bottom left'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.BOTTOMLEFT,
            },
            {
                name: this.translateService.getString('Bottom center'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.BOTTOMCENTER,
            },
            {
                name: this.translateService.getString('Bottom right'),
                value: CONSENT_MANAGER_CONSTANT.DIRECTION.BOTTOMRIGHT,
            },
        ];

        this.options.icons = [
            {
                icon: 'fa-fw fa-2x fas fa-cookie',
                value: CONSENT_MANAGER_CONSTANT.ICON.COOKIE,
            },
            {
                icon: 'fa-fw fa-2x fas fa-cookie-bite',
                value: CONSENT_MANAGER_CONSTANT.ICON.COOKIE_BITE,
            },
            {
                icon: 'fa-fw fa-2x fas fa-badge',
                value: CONSENT_MANAGER_CONSTANT.ICON.BADGE,
            },
            {
                icon: 'fa-fw fa-2x fas fa-stroopwafel',
                value: CONSENT_MANAGER_CONSTANT.ICON.STROOPWAFEL,
            },
        ];

        this.options.iconShapes = [
            {
                name: this.translateService.getString('Circle'),
                value: CONSENT_MANAGER_CONSTANT.ICON_SHAPE.CIRCLE,
            },
            {
                name: this.translateService.getString('Rounded Box'),
                value: CONSENT_MANAGER_CONSTANT.ICON_SHAPE.ROUNDED_BOX,
            },
        ];

        this.options.languages = [
            {
                name: this.translateService.getString('English'),
                value: CONSENT_MANAGER_CONSTANT.LANGUAGE.EN_US,
            },
            {
                name: this.translateService.getString('Danish'),
                value: CONSENT_MANAGER_CONSTANT.LANGUAGE.DA_DK,
            },
            {
                name: this.translateService.getString('German (Germany)'),
                value: CONSENT_MANAGER_CONSTANT.LANGUAGE.DE_DE,
            },
            {
                name: this.translateService.getString('Norwegian Bokmål'),
                value: CONSENT_MANAGER_CONSTANT.LANGUAGE.NB_NO,
            },
            {
                name: this.translateService.getString('Dutch'),
                value: CONSENT_MANAGER_CONSTANT.LANGUAGE.NL_NL,
            },
            {
                name: this.translateService.getString('Spanish (Spain)'),
                value: CONSENT_MANAGER_CONSTANT.LANGUAGE.ES_ES,
            },

        ];
    }
}
