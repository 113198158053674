(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceAuditImpactService
     * @description Confirms details of a Lighthouse Report Audit
     */
    angular
        .module('modules.page-performance')
        .service('PagePerformanceAuditInfoService', PagePerformanceAuditInfoService);

    PagePerformanceAuditInfoService.$inject = [
        'PAGE_PERFORMANCE_AUDIT_INFO',
        'gettextCatalog',
        'Lodash',
        'ng2ErrorHandler',
    ];

    /* @ngInject*/
    function PagePerformanceAuditInfoService (PAGE_PERFORMANCE_AUDIT_INFO, gettextCatalog, Lodash, ng2ErrorHandler) {
        return {
            getDifficulty: getDifficulty,
            getDifficultyLevel: getDifficultyLevel,
        };

        /**
         * @memberOf PagePerformanceAuditInfoService
         * @desc Get difficulty translation of active audit
         * @param {number} id Id of audit
         * @return {string}
         */
        function getDifficulty (id) {
            var level = getDifficultyLevel(id);
            var difficulty = '';

            if (level !== null) {
                switch (level) {
                    case 1:
                        difficulty = gettextCatalog.getString('Easy');
                        break;

                    case 2:
                        difficulty = gettextCatalog.getString('Moderate');
                        break;

                    case 3:
                        difficulty = gettextCatalog.getString('Hard');
                        break;
                }
            }

            return difficulty;
        }

        /**
         * @memberOf PagePerformanceAuditInfoService
         * @desc Get difficulty value of active audit
         * @param {Number} auditId - Id of audit
         */
        function getDifficultyLevel (auditId) {
            var audit = Lodash.find(PAGE_PERFORMANCE_AUDIT_INFO, { id: auditId });
            if (audit) {
                return audit.difficulty;
            } else {
                // Log all Audits that were not found in the PAGE_PERFORMANCE_AUDIT_INFO constant
                ng2ErrorHandler.noopError(
                    'Performance audit id: ' + auditId + ' is missing in the Audit Info Service',
                    'warning',
                );
            }
            return null;
        }
    }
})();
