(function () {
    'use strict';

    angular.module('pages.qa.nin').component('ninPages', {
        templateUrl: 'app/pages/qa/nin/pages/index.html',
        controller: NinPagesIndex,
        bindings: {
            nin: '<',
            status: '<',
            type: '<',
        },
        controllerAs: 'vm',
    });

    NinPagesIndex.$inject = ['NinPagesRepo', '$controller', 'ParamService', 'ng2MonEventsService', 'ENTITY_CONSTANT', 'BaseEntityService'];
    /* @ngInject */
    function NinPagesIndex (NinPagesRepo, $controller, ParamService, ng2MonEventsService, ENTITY_CONSTANT, BaseEntityService) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.onSearch = onSearch;
            vm.search = null;
            vm.pages = [];
            vm.tabs = [];

            loadLocations();
            getPage();
        }

        function loadLocations () {
            vm.search = null;
            vm.currentSort = { by: null, direction: null };
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search || null,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                nin_id: vm.nin.id,
                status: 'active',
                'types[]': ['html'],
            };

            if (vm.type === 'document') {
                params['types[]'] = ['document', 'pdf'];
            }

            vm.promise = NinPagesRepo.get(params).then(function (data) {
                vm.pages = data;
            }, angular.noop);
        }

        function onSearch (search) {
            if (search !== '' && vm.tabs.length < 2) {
                vm.tabs.push({ name: 'Filtered', faIcon: 'search', value: 'filtered' });
                vm.activeTab = 'filtered';
            } else if (search === '') {
                vm.tabs.splice(1, 1);
                vm.activeTab = 'all';
            }
            vm.search = search;
            vm.page = 1;
            getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
