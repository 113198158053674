import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { RequestHeadersType, RequestParamsType } from '@monsido/ng2/external/http/options';

export type ConsentType = {
    anon_ip: string,
    country_code:string,
    page_url: string,
    session_id:string,
    tc_string: string,
    timestamp: string,
    user_agent: string
}

type CookieBannerRepoRequestType = RequestParamsType & BaseRepoRequestType;

@Injectable({
    providedIn: 'root',
})
export class CookieBannerRepoService extends BaseRepo {

    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    getConsents (params: CookieBannerRepoRequestType = {}, config: RequestHeadersType = {}): Promise<ConsentType[]> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/cookie_banner/consents`, params, config);
    }

    getStatistics (params: CookieBannerRepoRequestType = {}, config: RequestHeadersType = {}): Promise<ConsentType[]> {
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/cookie_banner/statistics`, params, config);
    }
}
