import { objectToArray } from '@monsido/ng2/shared/utils';

(function () {
    'use strict';

    angular.module('filters.monsido').filter('fuzzyBy', fuzzyBy);

    fuzzyBy.$inject = ['$parse'];

    /* @ngInject */
    function fuzzyBy ($parse) {
        function hasApproxPattern (word, pattern) {
            function indexOf (w, pat, c) {
                let j = 0;
                while ((pat + j) <= word.length) {
                    if (w.charAt(pat + j) === c) {
                        return j;
                    }
                    j++;
                }
                return -1;
            }

            let p = 0;
            for (let i = 0; i <= pattern.length; i++) {
                const index = indexOf(word, p, pattern.charAt(i));
                if (index === -1) {
                    return false;
                }
                p += index + 1;
            }
            return true;
        }

        return function (collection, property, search, cSensitive) {
            const sensitive = cSensitive || false;
            let prop = null;
            let getter = null;

            collection = angular.isObject(collection) ? objectToArray(collection) : collection;

            if (!angular.isArray(collection) || angular.isUndefined(property) ||
                angular.isUndefined(search)) {
                return collection;
            }

            getter = $parse(property);

            return collection.filter(function (elm) {

                prop = getter(elm);
                if (!angular.isString(prop)) {
                    return false;
                }

                prop = (sensitive) ? prop : prop.toLowerCase();
                search = (sensitive) ? search : search.toLowerCase();

                return hasApproxPattern(prop, search) !== false;
            });
        };
    }
})();
