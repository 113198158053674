(function () {
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixAccessibilityChecksWebsiteStructure', {
        templateUrl: 'app/modules/page-fix/pages/website-structure/website-structure.html',
        controller: PageFixAccessibilityChecksWebsiteStructureController,
        controllerAs: 'vm',
    });

    PageFixAccessibilityChecksWebsiteStructureController.$inject = ['PAGE_FIX_ACCESSIBILITY_CHECK_TAB'];
    /* @ngInject */
    function PageFixAccessibilityChecksWebsiteStructureController (PAGE_FIX_ACCESSIBILITY_CHECK_TAB) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.tab = PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE;
        }
    }
})();
