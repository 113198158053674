import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSharedComponentsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import {
    ReceivedReportsTableComponent,
} from './received-reports-table.component';
import { MonTranslationModule } from '@monsido/core/translation/translation.module';

@NgModule({
    declarations: [
        ReceivedReportsTableComponent,
    ],
    imports: [
        CommonModule,
        AngularSharedComponentsModule,
        MonTranslationModule,
    ],
    providers: [
        MonEventService,
    ],
    exports: [
        ReceivedReportsTableComponent,
    ],
})
export class ReceivedReportsTableModule {}
