(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsPolicyInformationImage', {
        templateUrl: 'app/components/pagedetails/details/sections/policies/policy-information/image/image.html',
        controller: SectionPoliciesInformationImageController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '<',
        },
    });

    SectionPoliciesInformationImageController.$inject = ['ng2SessionService'];

    function SectionPoliciesInformationImageController (ng2SessionService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.domainUrl = ng2SessionService.domain.url;
        }
    }
})();
