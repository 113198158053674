import { NgModule } from '@angular/core';
import { SessionModule } from '@monsido/core/session/session.module';
import { RolePipe } from '@monsido/core/pipes/role/role.pipe';
import { SimpleNumberLocaleFormatPipe } from './local-number/simple-number-locale-format-number.pipe';

@NgModule({
    imports: [SessionModule],
    exports: [RolePipe, SimpleNumberLocaleFormatPipe],
    declarations: [RolePipe, SimpleNumberLocaleFormatPipe],
    providers: [],
})
export class PipesModule {}
