import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonImgMetadataOverlayComponent } from './components/img-metadata-overlay/img-metadata-overlay.component';
import { LayoutModule, TableModule, IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [CommonModule, TranslateModule, LayoutModule, TableModule, IconsModule],
    declarations: [MonImgMetadataOverlayComponent],
    exports: [MonImgMetadataOverlayComponent],

})
export class InventoryModule { }

export { InventoryModule as MonInventoryModule };
