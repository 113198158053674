import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { SeoIssue } from '@client/app/modules/seo/interfaces/seo-issue.interface';
import { SeoRepoType } from '@client/app/services/api/seo-repo/seo-repo.service';

const isSeoRepoType = (entry: SeoRepoType | SeoIssue): entry is SeoRepoType => {
    return 'name' in entry;
};

export const removeSeoMissingMetaKeywordUtil = <T extends SeoRepoType | SeoIssue>(collections: MonTableCollection<T>): MonTableCollection<T> => {
    const result: MonTableCollection<T> = collections.filter(entry => {
        if (isSeoRepoType(entry)) {
            return entry.name !== 'seo_missing_meta_keyword';
        } else {
            return entry.translation_name !== 'seo_missing_meta_keyword';
        }
    });

    result.total = collections.total;
    result.pageHits = collections.pageHits;
    result.currentPage = collections.currentPage;
    result.perPage = collections.perPage;
    return result;
};
