(function () {
    'use strict';

    angular.module('pages.uptimes').component('formUptimeScheduledDowntime', {
        templateUrl: 'app/forms/uptime/scheduled_downtimes/scheduled_downtime/scheduled_downtime.html',
        controller: FormUptimeScheduledDownTimeController,
        controllerAs: 'vm',
        bindings: {
            uptimeId: '<',
            schedule: '<',
            monDialogId: '@?',
        },
    });

    FormUptimeScheduledDownTimeController.$inject = [
        'monDialog',
        'gettextCatalog',
        'ng2DateTimeService',
        'uptimeService',
        '$scope',
    ];
    /* @ngInject */
    function FormUptimeScheduledDownTimeController (monDialog, gettextCatalog, ng2DateTimeService, uptimeService, $scope) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;
        vm.submit = submit;
        vm.onChangeRecurringOrStartDate = onChangeRecurringOrStartDate;

        function activate () {
            setupRecurringOptions();
            vm.scheduledDowntime = angular.copy(vm.schedule || {});
            vm.scheduledDowntime.register_downtime = vm.scheduledDowntime.register_downtime || false;
            vm.scheduledDowntime.recurring = vm.scheduledDowntime.recurring || vm.recurringOptions[0].value;
            vm.startDateOptions = {
                initDate: ng2DateTimeService.toDate(undefined),
            };

            vm.endDateOptions = {
                initDate: ng2DateTimeService.addDuration(undefined, 1, 'hours'),
            };
            setupDateParams();
            onChangeRecurringOrStartDate();

            // UI Boostrap datepicker don't have a on-change method
            $scope.$watch('vm.scheduledDowntime.start_at', function (oldValue, newValue) {
                onChangeRecurringOrStartDate();
            });
        }

        function setupDateParams () {
            if (angular.isUndefined(vm.scheduledDowntime.start_at)) {
                vm.scheduledDowntime.start_at = ng2DateTimeService.toDate(undefined);
            } else {
                vm.scheduledDowntime.start_at = ng2DateTimeService.toDate(vm.scheduledDowntime.start_at);
            }
            if (angular.isUndefined(vm.scheduledDowntime.end_at)) {
                vm.scheduledDowntime.end_at = ng2DateTimeService.addDuration(undefined, 1, 'hours');
            } else {
                vm.scheduledDowntime.end_at = ng2DateTimeService.toDate(vm.scheduledDowntime.end_at);
            }
        }

        function setupRecurringOptions () {
            vm.recurringOptions = [
                {
                    name: gettextCatalog.getString('None'),
                    value: 'no',
                },
                {
                    name: gettextCatalog.getString('Daily'),
                    value: 'daily',
                },
                {
                    name: gettextCatalog.getString('Weekly'),
                    value: 'weekly',
                },
                {
                    name: gettextCatalog.getString('Monthly'),
                    value: 'monthly',
                },
            ];
        }

        function onChangeRecurringOrStartDate () {
            const currentEndAt = ng2DateTimeService.toDate(vm.scheduledDowntime.end_at);
            const currentStartAt = ng2DateTimeService.toDate(vm.scheduledDowntime.start_at);
            let maxDate = ng2DateTimeService.toDate(vm.scheduledDowntime.start_at);

            vm.endDateOptions.minDate = currentStartAt;
            if (ng2DateTimeService.isBefore(currentEndAt, currentStartAt)) {
                vm.scheduledDowntime.end_at = ng2DateTimeService.addDuration(currentStartAt, 1, 'hours');
            }

            switch (vm.scheduledDowntime.recurring) {
                case 'daily':
                    maxDate = ng2DateTimeService.addDuration(maxDate, 24, 'hours');
                    break;
                case 'weekly':
                    maxDate = ng2DateTimeService.addDuration(maxDate, 24, 'hours' );
                    maxDate = ng2DateTimeService.addDuration(maxDate, 7, 'days' );
                    break;
                case 'monthly':
                    maxDate = ng2DateTimeService.addDuration(maxDate, 24, 'hours' );
                    maxDate = ng2DateTimeService.addDuration(maxDate,1, 'months' );
                    break;
                default:
                    vm.endDateOptions.maxDate = null;
                    return;
            }

            vm.endDateOptions.maxDate = maxDate;

            if (ng2DateTimeService.isBefore(currentEndAt, maxDate)) {
                vm.scheduledDowntime.end_at = maxDate;
            }
        }

        function submit () {
            var promise;
            if (angular.isUndefined(vm.scheduledDowntime.id)) {
                promise = uptimeService.createScheduledDowntime(vm.uptimeId, vm.scheduledDowntime);
            } else {
                promise = uptimeService.updateScheduledDowntime(
                    vm.uptimeId,
                    vm.scheduledDowntime.id,
                    vm.scheduledDowntime,
                );
            }
            promise.then(function () {
                close();
            }, angular.noop);
        }

        function close () {
            monDialog.close(vm.monDialogId);
        }
    }
})();
