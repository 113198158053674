(function () {
    'use strict';

    angular.module('modules.charts.config').config(ChartJSConfigController);
    ChartJSConfigController.$inject = ['ChartJsProvider'];
    function ChartJSConfigController (ChartJsProvider) {
        ChartJsProvider.setOptions({
            // Globally...
            colors: ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'],
        });
    }
})();
