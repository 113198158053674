(function () {
    'use strict';

    angular.module('modules.source-code-excludes').component('formSourceCodeExcludeAccessibility', {
        templateUrl:
            'app/modules/source-code-excludes/form/source-code-exclude/modules/accessibility/accessibility.html',
        controller: FormSourceCodeExcludeController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
            accessibilityChecksLoading: '<',
        },
    });

    FormSourceCodeExcludeController.$inject = ['gettextCatalog', '$deepmerge', 'MON_CMS'];

    /* @ngInject */
    function FormSourceCodeExcludeController (gettextCatalog, $deepmerge, MON_CMS) {
        var vm = this;
        vm.$onInit = activate;
        vm.addMatchRule = addMatchRule;
        vm.removeMatchRule = removeMatchRule;
        vm.getAccessibilityChecks = getAccessibilityChecks;

        function activate () {
            vm.pageSize = 10;
            vm.page = 1;
            vm.cmses = $deepmerge([], MON_CMS);
            vm.defaultAccessibilityChecksOptions = [];
            if (!Array.isArray(vm.ngModel.match_rules)) {
                vm.ngModel.match_rules = [];
            }

            if (typeof vm.ngModel.id !== 'number' && vm.ngModel.match_rules.length === 0) {
                addMatchRule();
            }
            setupMatchRuleTypeOptions();
        }

        function getAccessibilityChecks () {
            return vm.accessibilityChecksLoading.then(
                function (checks) {
                    return checks;
                },
                function () {
                    return [];
                },
            );
        }

        function setupMatchRuleTypeOptions () {
            vm.typeOptions = [
                {
                    name: gettextCatalog.getString('Exact'),
                    value: 'exact',
                },
                {
                    name: gettextCatalog.getString('Regex'),
                    value: 'regex',
                },
                {
                    name: gettextCatalog.getString('Contains'),
                    value: 'contains',
                },
            ];
        }

        function addMatchRule () {
            vm.ngModel.match_rules.unshift({ type: 'exact' });
            vm.page = 1;
        }

        function removeMatchRule (index) {
            if (vm.ngModel.match_rules.length > 1) {
                vm.ngModel.match_rules.splice(vm.pageSize * (vm.page - 1) + index, 1);
            }
        }
    }
})();
