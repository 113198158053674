(function () {
    'use strict';

    angular.module('modules.demodata').filter('isUsingDemoData', isUsingDemoData);

    isUsingDemoData.$inject = ['ng2DemoHasAccessService'];

    /* @ngInject */
    function isUsingDemoData (ng2DemoHasAccessService) {
        return init;

        // //////////

        /**
         * @desc Determine if the value is Demo Data
         * @param {any} val - object to check
         * @return {boolean}
         */
        function init (val) {
            return ng2DemoHasAccessService.isUsingDemoData(val);
        }
    }
})();
