import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('blocks.service').factory('MenuFormService', MenuFormService);

    MenuFormService.$inject = ['ng2MonEventsService', 'FORM_CONSTANT'];

    function MenuFormService (ng2MonEventsService, FORM_CONSTANT) {
        return {
            openGlobalPolicy: openGlobalPolicy,
            openDomain: openDomain,
            openUser: openUser,
        };

        function openGlobalPolicy () {
            const params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function openDomain () {
            const params = {
                body: FORM_CONSTANT.FORM_DOMAIN,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function openUser () {
            const params = {
                body: FORM_CONSTANT.FORM_USER,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
