import { Injectable } from '@angular/core';
import { PAGE_PERFORMANCE_CONNECTION_PROFILES } from '@client/app/modules/page-performance/constants/page-performance-connection.-profiles';
import { default as PAGE_PERFORMANCE_LOCATIONS } from '@client/app/modules/page-performance/constants/page-performance-locations.json';
import { isEqual } from 'lodash';
import { PagePerformanceProfile, PageProfileDataInterface } from '../profile.model';
import { TranslateService } from '@client/app/services/translate/translate.service';


@Injectable({
    providedIn: 'root',
})
export class CreateProfileService {

    private devices = [
        {
            name: this.translateServce.getString('Desktop'),
            value: 'desktop',
        },
        {
            name: this.translateServce.getString('Mobile'),
            value: 'mobile',
        },
    ];
    private locations: Record<string, {
        city: string;
        country: string;
        country_code: string;
    }> = PAGE_PERFORMANCE_LOCATIONS;

    constructor (
        private translateServce: TranslateService,
    ) { }

    getPagePerformanceProfile (data: PageProfileDataInterface): PagePerformanceProfile {
        const location = this.locations[data.location] || {
            city: '',
            country: '',
            country_code: '',
        };
        const connectionName = this.getProfileName(data);
        const deviceName = this.devices.find(d => d.value === data.device)?.name || '';
        const countryCode = location.country_code;
        const cityName = location.city;
        const countryName = location.country;

        return new PagePerformanceProfile({
            ...data,
            connectionName,
            deviceName,
            countryCode,
            cityName,
            countryName,
        });
    }

    private getProfileName (data: PageProfileDataInterface): string {
        const settings = {
            round_trip_time: data.round_trip_time,
            throughput: data.throughput,
            request_latency: data.request_latency,
            download_throughput: data.download_throughput,
            upload_throughput: data.upload_throughput,
            cpu_slowdown_multiplier: data.cpu_slowdown_multiplier,
        };
        return PAGE_PERFORMANCE_CONNECTION_PROFILES.find((connectionProfile) => {
            return isEqual(connectionProfile.settings, settings);
        })?.name || this.translateServce.getString('Custom Profile');
    }
}
