import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('spellingDashboardMisspellings', {
        templateUrl: 'app/pages/qa/spellcheck/dashboard/misspellings.html',
        controller: SpellingDashboardMisspellingController,
        controllerAs: 'vm',
        bindings: {
            classification: '@',
            header: '@',
        },
    });

    SpellingDashboardMisspellingController.$inject = ['ng2SpellingRepoService', 'ng2MonEventsService', '$controller'];
    /* @ngInject */
    function SpellingDashboardMisspellingController (ng2SpellingRepoService, ng2MonEventsService, $controller) {
        var vm = this;

        vm.openDialog = openDialog;
        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.spellingErrors = [];
            getPage();
        }

        function getPage () {
            var params = {
                page: 1,
                page_size: 5,
                sort_by: 'html_pages_count',
                classification: vm.classification,
                page_fixed: false,
            };

            vm.promise = ng2SpellingRepoService.getAllErrors(params).then(function (data) {
                vm.spellingErrors = data;
            }, angular.noop);
        }

        function openDialog (word) {
            var params = {
                body: 'spellingPagesDialog',
                size: 'lg',
                data: {
                    word: word,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
