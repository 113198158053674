(function () {
    'use strict';

    angular.module('modules.light.pages.domain.consent_manager').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];

    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function checkHasAccessToConsentManager (sessionService, $filter, activeFeatureService, MON_MODULES) {
        var hasConsentManagerPlanTrait = sessionService.customer && sessionService.customer.plan_traits.cookie_banner;

        return (
            hasConsentManagerPlanTrait &&
            ($filter('monCustomerHasPlan')('demo') || activeFeatureService.isFeatureActive(MON_MODULES.cookie_banner))
        );
    }

    function getStates (gettextCatalog) {
        var hasAccessToConsentManager = [
            '$state',
            '$q',
            '$stateParams',
            'ng2SessionService',
            '$filter',
            'ng2ActiveFeatureService',
            'MON_MODULES',
            'ng2MonUIRouterTransitionService',
            function (
                $state,
                $q,
                $stateParams,
                sessionService,
                $filter,
                ng2ActiveFeatureService,
                MON_MODULES,
                ng2MonUIRouterTransitionService,
            ) {
                if (checkHasAccessToConsentManager(sessionService, $filter, ng2ActiveFeatureService, MON_MODULES)) {
                    return $q.resolve(true);
                }
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.light.domain.summary', $stateParams),
                );
            },
        ];

        var isCustomerAdmin = [
            '$state',
            '$q',
            '$stateParams',
            'ng2SessionService',
            'ng2MonUIRouterTransitionService',
            function ($state, $q, $stateParams, sessionService, ng2MonUIRouterTransitionService) {
                var isAdmin = sessionService.hasRole('customer_admin');
                if (isAdmin) {
                    return $q.resolve(true);
                }
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.light.domain.cookie_acceptance_rate', $stateParams),
                );
            },
        ];

        var states = [
            {
                state: 'base.customer.light.domain.cookie_acceptance_rate',
                config: {
                    url: '/cookie-acceptance-rate',
                    template: '<cookie-acceptance-rate-overview></cookie-acceptance-rate-overview>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - Cookie Acceptance Rate'),
                        sidebar: 'light-consent-manager',
                    },
                    resolve: {
                        hasAccessToConsentManager: hasAccessToConsentManager,
                    },
                },
            },
            {
                state: 'base.customer.light.domain.consent_log',
                config: {
                    url: '/consent-log?from&to&uuid',
                    template: '<consent-log></consent-log>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - Cookie Consent Log'),
                        sidebar: 'light-consent-manager',
                    },
                    resolve: {
                        hasAccessToConsentManager: hasAccessToConsentManager,
                    },
                },
            },
            {
                state: 'base.customer.light.domain.cookie_overview',
                config: {
                    url: '/cookie-overview',
                    template: '<cookie-overview></cookie-overview>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Cookie Overview'), sidebar: 'light-consent-manager' },
                    resolve: {
                        hasAccessToConsentManager: hasAccessToConsentManager,
                        isCustomerAdmin: isCustomerAdmin,
                    },
                },
            },
        ];
        return states;
    }
})();
