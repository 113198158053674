(function () {
    'use strict';

    angular.module('modules.cookie_banner.pages').component('cookieOverview', {
        templateUrl: 'app/modules/cookie-banner/pages/cookie-overview/cookie-overview.component.html',
        controller: CookieOverviewController,
        controllerAs: 'vm',
    });

    CookieOverviewController.$inject = ['ng2SessionService', 'CookieOverviewService'];

    /* @ngInject */
    function CookieOverviewController (ng2SessionService, CookieOverviewService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.isDemo = CookieOverviewService.hasDemoAccess();
            getCookies();
        }

        function getCookies () {
            CookieOverviewService.getCookies(vm.domain.id).then(function (cookies) {
                vm.cookies = cookies;
            });
        }
    }
})();
