export const PAGE_PERFORMANCE_CONNECTION_PROFILES = [
    {
        name: 'Cable',
        description: 'Cable Connection Speed',
        settings: {
            round_trip_time: 28,
            throughput: 5120, // 5 multiplied by 1024
            request_latency: 105, // Round Trip Time multiplied by 3.75
            download_throughput: 4608, // Throughput multiplied by 0.9
            upload_throughput: 922, // 1 MBps multiplied by 0.9
            cpu_slowdown_multiplier: 4,
        },
    },
    {
        name: '3G Regular',
        description: 'Regular 3G Connection speed',
        settings: {
            round_trip_time: 300,
            throughput: 700, // 700 KBps
            request_latency: 1125, // Round Trip Time multiplied by 3.75
            download_throughput: 630, // Throughput multiplied by 0.9
            upload_throughput: 630, // 700 KBps multiplied by 0.9
            cpu_slowdown_multiplier: 4,
        },
    },
    {
        name: 'Slow 4G',
        description: 'Slow 4G Connection Profile',
        settings: {
            round_trip_time: 150,
            throughput: 1639, // 1.6 multiplied by 1024
            request_latency: 563, // Round Trip Time multiplied by 3.75
            download_throughput: 1475, // Throughput multiplied by 0.9
            upload_throughput: 675, // 750 KBps multiplied by 0.9
            cpu_slowdown_multiplier: 4,
        },
    },
    {
        name: 'DSL',
        description: 'Modem connection Profile',
        settings: {
            round_trip_time: 50,
            throughput: 1536, // 1.5 MBps multiplied by 1024
            request_latency: 188, // Round Trip Time multiplied by 3.75
            download_throughput: 1382, // Throughput multiplied by 0.9
            upload_throughput: 384, // 384 KBps multiplied by 0.9
            cpu_slowdown_multiplier: 4,
        },
    },
    {
        name: 'LTE',
        description: 'Fast 4G Connection Profile',
        settings: {
            round_trip_time: 70,
            throughput: 12288, // 12 multiplied by 1024
            request_latency: 262, // Round Trip Time multiplied by 3.75
            download_throughput: 11059, // Throughput multiplied by 0.9
            upload_throughput: 11059, // 12 MBps multiplied by 0.9
            cpu_slowdown_multiplier: 4,
        },
    },
] as const;
