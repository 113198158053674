(function () {
    'use strict';

    angular.module('pages.policy').component('pagesPolicyPages', {
        templateUrl: 'app/pages/policy/pages/pages.html',
        controller: PolicyController,
        controllerAs: 'vm',
    });

    PolicyController.$inject = [
        'ng2PolicyRepoService',
        'pagesTabsHelper',
        '$controller',
        'gettextCatalog',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2CheckForDuplicatesService',
        'ng2ActiveFeatureService',
        'ng2LabelService',
    ];
    /* @ngInject */
    function PolicyController (
        ng2PolicyRepoService,
        pagesTabsHelper,
        $controller,
        gettextCatalog,
        ng2MonEventsService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2CheckForDuplicatesService,
        ng2ActiveFeatureService,
        ng2LabelService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onDestroy = onDestroy;
        vm.createOrUpdateLabel = createOrUpdateLabel;

        function activate () {
            vm.hasAccessToStats = ng2ActiveFeatureService.isFeatureActive('statistics');
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pages = [];
            vm.search = '';
            vm.tabs = [];
            vm.activeTab = pagesTabsHelper.getActiveTab();
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            vm.selectedPolicyPriorities = [];
            vm.filters.priorities = [];

            getPage();
            setupTabs();
            setupPolicyPriorities();
            vm.getLabels();
        }

        function setupTabs () {
            vm.tabs = pagesTabsHelper.getTabs();
        }

        function setupPolicyPriorities () {
            vm.availableFilters.priorities = [
                {
                    name: gettextCatalog.getString('Unwanted'),
                    value: 'unwanted',
                },
                {
                    name: gettextCatalog.getString('Required'),
                    value: 'required',
                },
                {
                    name: gettextCatalog.getString('Matches'),
                    value: 'matches',
                },
            ];
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,

                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.activeTab && vm.activeTab !== 'all') {
                params['types[]'] = [vm.activeTab];
            }

            params = vm.updateParamsWithFilters(params);
            params = updateParamsWithPolicyFilters(params);

            vm.progress = ng2PolicyRepoService.domainGetPages(params).then(function (pages) {
                ng2CheckForDuplicatesService.checkForDuplicatePages(pages);
                vm.pages = pages;
            }, angular.noop);
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            updatePolicyFilters(changes);
            vm.getPage();
        }

        function updateParamsWithPolicyFilters (params) {
            if (vm.selectedPolicyPriorities && vm.selectedPolicyPriorities.length > 0) {
                if (vm.selectedPolicyPriorities.indexOf('unwanted') > -1) {
                    params.policy_violations = true;
                }
                if (vm.selectedPolicyPriorities.indexOf('required') > -1) {
                    params.policy_required = true;
                }
                if (vm.selectedPolicyPriorities.indexOf('matches') > -1) {
                    params.policy_searches = true;
                }
            }

            return params;
        }

        function updatePolicyFilters (changes) {
            if (changes.changes) {
                changes = changes.changes;
            }

            if (changes.priorities) {
                vm.selectedPolicyPriorities = changes.priorities.map(function (priority) {
                    return priority.value;
                });
            } else {
                vm.selectedPolicyPriorities = [];
            }
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }

        function createOrUpdateLabel (label) {
            ng2LabelService.createOrUpdateLabel(label);
        }
    }
})();
