import { NgModule } from '@angular/core';
import { QaComponentsModule } from './components/qa-components.module';
import { QaIssueRepoService } from './repos/qa-issue.repo';

@NgModule({
    imports: [
        QaComponentsModule,
    ],
    providers: [
        QaIssueRepoService,
    ],
})
export class QaModule {}
