(function () {
    'use strict';

    angular.module('filters.monsido').filter('percent', percent);
    percent.$inject = [];

    /* @ngInject */
    function percent () {
        return function (input, divided, round) {
            const divider = angular.isString(input) ? Number(input) : input;
            divided = divided || 100;
            round = round || false;

            if (!angular.isNumber(divider) || isNaN(divider)) {
                return input;
            }

            return round
                ? Math.round((divider / divided) * 100)
                : (divider / divided) * 100;
        };
    }
})();
