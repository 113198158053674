(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsSummary', {
        templateUrl: 'app/pages/statistics/visitors/summary/summary.html',
        controller: StatisticsVisitorsSummaryController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsSummaryController.$inject = [
        'ParamService',
        'ng2SessionService',
        'dateRangeService',
    ];
    /* @ngInject */
    function StatisticsVisitorsSummaryController (
        ParamService,
        ng2SessionService,
        dateRangeService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.date = dateRangeService.setRangeDates();
            updateDateRange();
        }

        function updateDateRange () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
            });
        }
    }
})();
