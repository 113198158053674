(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormWizardContainerFooterOptions', {
        templateUrl: 'app/modules/page-fix/forms/wizard-container/footers/options/options.html',
        controller: PageFixFormFooterOptionsController,
        controllerAs: 'vm',
        bindings: {
            formValid: '<',
            pageFixApplied: '<',
            saving: '<',
            removing: '<',
            warning: '<',
        },
    });

    PageFixFormFooterOptionsController.$inject = ['PAGE_FIX_EVENT', 'ng2MonEventsService'];
    /* @ngInject */
    function PageFixFormFooterOptionsController (PAGE_FIX_EVENT, ng2MonEventsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.removeCheck = removeCheck;

        function activate () {
            vm.saving = false;
            vm.removing = false;
        }

        function submit () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.REQUEST_PREPARE_PAYLOAD);
        }

        function removeCheck () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.REQUEST_DESTROY);
        }
    }
})();
