(function () {
    'use strict';

    angular.module('core.config').config([
        '$compileProvider',
        function ($compileProvider) {
            $compileProvider.debugInfoEnabled(false);
            $compileProvider.commentDirectivesEnabled(false);
            // $compileProvider.cssClassDirectivesEnabled(false);
        },
    ]);
})();
