(function () {
    'use strict';

    angular.module('filters.monsido').filter('domainActiveFeature', domainActiveFeature);

    domainActiveFeature.$inject = ['ng2ActiveFeatureService'];
    /* @ngInject */
    function domainActiveFeature (ng2ActiveFeatureService) {
        return active;

        // //////////

        function active (feature, domain, page) {
            return ng2ActiveFeatureService.isFeatureActive(feature, domain, page);
        }
    }
})();
