(function () {
    'use strict';

    angular.module('modules.light', [
        'modules.light.pages',
        'modules.light.components',
        'modules.light.pages',
        'modules.light.forms',
        'modules.light.services',
    ]);
})();
