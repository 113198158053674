(function () {
    'use strict';

    angular.module('pages.domain').component('documentDetailsDashboardAccessibility', {
        templateUrl: 'app/components/documentdetails/details/dashboard/accessibility/accessibility.html',
        controller: DocumentDetailsDashboardAccessibilityController,
        controllerAs: 'vm',
        bindings: {
            document: '<',
        },
    });

    DocumentDetailsDashboardAccessibilityController.$inject = ['PageClarityService', '$filter', 'Lodash'];
    /* @ngInject */
    function DocumentDetailsDashboardAccessibilityController (PageClarityService, $filter, Lodash) {
        var vm = this;
        vm.$onInit = activate;
        vm.getChecksInErrorCount = getChecksInErrorCount;
        vm.getErrorCount = getErrorCount;

        function activate () {
            vm.checks = [];
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            vm.options = {};
            vm.data = [];
            vm.labels = ['', ''];
            getChecks().then(function (checks) {
                vm.totalChecksCount = checks.length;
                vm.checks = checks;
                setupDoughnut();
            }, angular.noop);
        }

        function getChecks () {
            return PageClarityService.getAllChecks(vm.document.id);
        }

        function getChecksInErrorCount () {
            return Lodash.reduce(
                vm.checks,
                function (sum, check) {
                    if (check.errors_count > 0) {
                        sum++;
                    }
                    return sum;
                },
                0,
            );
        }

        function getErrorCount () {
            return Lodash.reduce(
                vm.checks,
                function (sum, check) {
                    if (check.errors_count > 0) {
                        sum += parseInt(check.errors_count);
                    }
                    return sum;
                },
                0,
            );
        }

        function setupDoughnut () {
            var checkErrorCount = getChecksInErrorCount();

            vm.data = [vm.totalChecksCount - checkErrorCount, checkErrorCount];
            var percentage = (checkErrorCount / vm.totalChecksCount) * 100;
            vm.options = {
                centerText: {
                    percentageLabel: $filter('monNumber')((100 - percentage) / 100, '0.[00] %'),
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: checkErrorCount > 0,
                    callbacks: {
                        beforeLabel: function (tooltipItem, data) {
                            return '';
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs')(
                                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                '0,0',
                            );
                        },
                    },
                },
            };
        }
    }
})();
