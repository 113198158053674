(function () {
    'use strict';

    angular.module('models').factory('PagePerformancePageModel', PagePerformancePageModel);

    PagePerformancePageModel.$inject = [];

    function PagePerformancePageModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        return Model;

        // /////////
    }
})();
