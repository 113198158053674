(function () {
    'use strict';
    /**
     * Chart
     *
     * @memberof blocks.chart
     * @ngdoc factory
     * @name BarChart
     * @description
     * Creates series for the line chart
     */

    angular.module('modules.charts.models').factory('BarChart', BarChart);

    BarChart.$inject = ['Chart'];

    /* @ngInject */
    function BarChart (Chart) {
        function Model (attributes) {
            Chart.call(this, attributes);
        }

        Model.prototype = Object.create(Chart.prototype);
        Model.constructor = Model;

        return Model;
    }
})();
