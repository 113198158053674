(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceAuditDisplayInformativeRequestChain', {
        templateUrl:
            'app/modules/page-performance/components/display-modes/informative/request-chain/request-chain.html',
        controller: PagePerformanceAuditDisplayInformativeRequestChainController,
        controllerAs: 'vm',
        bindings: {
            chains: '<',
        },
    });

    PagePerformanceAuditDisplayInformativeRequestChainController.$inject = ['$filter'];
    /* @ngInject */
    function PagePerformanceAuditDisplayInformativeRequestChainController ($filter) {
        var vm = this;
        vm.$onInit = activate;
        vm.getUrlSnippet = getUrlSnippet;
        vm.hasChildren = hasChildren;

        function activate () {}

        function getUrlSnippet (chain) {
            var containerEl = document.createElement('div');
            var anchorEl = document.createElement('a');
            var spanEl = document.createElement('span');
            anchorEl.target = '_blank';
            anchorEl.href = chain.request.href || chain.request.url;
            if (anchorEl.pathname.length > 1) {
                anchorEl.innerText = anchorEl.pathname;

                spanEl.innerHTML = '&nbsp;(' + anchorEl.hostname + ')';
                spanEl.classList.add('text-grey');
            } else {
                anchorEl.innerText = anchorEl.hostname;
            }

            if (!hasChildren(chain)) {
                var strongEl = document.createElement('strong');
                var spanFileSizeEl = document.createElement('span');
                var spanMsEl = document.createElement('span');
                spanFileSizeEl.innerText = $filter('monNumber')(chain.request.transferSize, '0.00 ib');
                spanMsEl.innerHTML =
                    $filter('monNumber')((chain.request.endTime - chain.request.startTime) * 1000, '0') + '&nbsp;ms';
                strongEl.innerHTML = '-&nbsp;' + spanMsEl.innerHTML + ',&nbsp;' + spanFileSizeEl.innerHTML;
                spanEl.innerHTML = spanEl.innerHTML + '&nbsp;' + strongEl.outerHTML;
            }

            containerEl.append(anchorEl);
            containerEl.append(spanEl);
            return containerEl.innerHTML;
        }

        function hasChildren (chain) {
            return Boolean(chain.children);
        }

        // PROTECTED
    }
})();
