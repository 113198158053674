(function () {
    'use strict';
    angular.module('app.components.monButton').component('monButton', {
        transclude: true,
        templateUrl: 'app/components/mon-button/mon-button.html',
        controller: monButtonController,
        controllerAs: 'vm',
        bindings: {
            click: '&?',
            type: '<?',
            monShow: '<?',
            monDisabled: '<?',
            classes: '<?',
        },
    });

    monButtonController.$inject = [];

    function monButtonController () {
        var vm = this;
        vm.$onInit = init;
        vm.onClick = onClick;

        function init () {
            vm.classes = vm.classes || '';
            if (typeof vm.monShow !== 'boolean') {
                vm.monShow = true;
            }
            if (typeof vm.monDisabled !== 'boolean') {
                vm.monDisabled = false;
            }
            if (typeof vm.type !== 'string') {
                vm.type = 'button';
            }
        }

        function onClick () {
            if (typeof vm.click === 'function') {
                vm.click();
            }
        }
    }
})();
