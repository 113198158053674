(function () {
    'use strict';

    angular.module('base.product-updates').component('productUpdatesCategories', {
        templateUrl: 'app/pages/base/product-updates/categories.html',
        controller: ProductUpdatesCategoriesController,
        controllerAs: 'vm',
        bindings: {
            onChange: '&',
        },
    });

    ProductUpdatesCategoriesController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function ProductUpdatesCategoriesController (gettextCatalog) {
        var vm = this;
        vm.onCheck = onCheck;

        vm.$onInit = activate;

        function activate () {
            vm.selectedCategory = null;
            vm.categories = [
                {
                    name: gettextCatalog.getString('All'),
                    value: null,
                },
                {
                    name: gettextCatalog.getString('Update'),
                    value: 'change',
                },
                {
                    name: gettextCatalog.getString('New feature'),
                    value: 'feature',
                },
            ];
        }

        function onCheck () {
            vm.onChange({ category: vm.selectedCategory });
        }
    }
})();
