(function () {
    'use strict';

    angular.module('blocks.base').controller('BaseApiController', BaseApiController);

    BaseApiController.$inject = ['vm', 'API_CONSTANTS', '$location', 'ng2MonEventsService'];
    /* @ngInject */
    function BaseApiController (vm, API_CONSTANTS, $location, ng2MonEventsService) {
        vm.onStatus = onStatus;
        vm.onSearch = onSearch;
        vm.onSortPage = onSortPage;
        vm.onPageChange = onPageChange;
        vm.onTabChange = onTabChange;
        vm.onPageSizeChange = onPageSizeChange;
        vm.onPageDetailsClose = onPageDetailsClose;
        vm.onMonTableSort = onMonTableSort;

        activate();

        function activate () {
            vm.page = parseInt($location.search().page) || 1;
            vm.pageSize = parseInt($location.search().page_size) || API_CONSTANTS.PAGE_SIZE;
        }

        function onStatus (tab) {
            vm.page = 1;
            vm.activeTab = tab;
            vm.getPage();
        }

        function onTabChange (tab) {
            vm.activeTab = tab;
            vm.page = 1;
            ng2MonEventsService.run('actionTrigger', { action: 'tab-change', params: { tab: tab } });
            vm.getPage();
        }

        function onSearch (search) {
            vm.search = search;
            vm.page = 1;
            ng2MonEventsService.run('actionTrigger', { action: 'search', params: { search: search } });
            vm.getPage();
        }

        function onSortPage (direction, by) {
            vm.page = 1;
            vm.currentSort = { by: by, direction: direction };
            ng2MonEventsService.run('actionTrigger', { action: 'table-sort', params: { by: by, direction: direction } });
            vm.getPage();
        }

        // currentSort: { direction: 'asc' | 'desc', by: string }
        function onMonTableSort (currentSort) {
            vm.page = 1;
            vm.currentSort = currentSort;
            ng2MonEventsService.run('actionTrigger', { action: 'table-sort', params: currentSort });
            vm.getPage();
        }

        function onPageChange (page) {
            vm.page = page;
            ng2MonEventsService.run('actionTrigger', { action: 'table-page-change', params: { page: page } });
            vm.getPage();
        }

        function onPageSizeChange (pageSize) {
            vm.pageSize = pageSize;
            vm.page = 1;
            ng2MonEventsService.run('actionTrigger', { action: 'page-size-change', params: { pageSize: pageSize } });
            vm.getPage();
        }

        function onPageDetailsClose () {
            vm.getPage();
        }
    }
})();
