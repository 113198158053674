(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuAdmin', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });


    SideMenuController.$inject = ['gettextCatalog', 'activeFeatures', '$filter', 'ng2ActiveFeatureService', 'ng2SessionService'];
    function SideMenuController (gettextCatalog, activeFeatures, $filter, ng2ActiveFeatureService, sessionService) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Domains'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'DOMAINS']),
                    href: 'base.customer.admin.domains.index',
                },
                {
                    title: gettextCatalog.getString('Users'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'USERS']),
                    href: 'base.customer.admin.users.index',
                },
            ];

            if (activeFeatures.labels) {
                vm.menu.push({
                    title: gettextCatalog.getString('Labels'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'LABELS']),
                    href: 'base.customer.admin.labels.index',
                });
            }

            if (activeFeatures.audit_log) {
                vm.menu.push({
                    title: gettextCatalog.getString('Audit Log'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'AUDIT_LOG']),
                    href: 'base.customer.admin.auditlog.index',
                });
            }

            if (
                ng2ActiveFeatureService.isFeatureActive('admin_source_code_excludes') ||
                ng2ActiveFeatureService.isFeatureActive('admin_accessibility')
            ) {
                vm.menu.push({
                    title: gettextCatalog.getString('Source Code Excludes'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'SOURCE_CODE_EXCLUDES']),
                    href: 'base.customer.admin.source_code_excludes.index',
                });
            }

            vm.menu.push({
                title: gettextCatalog.getString('Company Info'),
                icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'CUSTOMER_INFO']),
                href: 'base.customer.admin.customer_info.index',
            });

            if (ng2ActiveFeatureService.isFeatureActive('account_settings')) {
                vm.menu.push({
                    title: gettextCatalog.getString('Account Settings'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'ACCOUNT_SETTINGS']),
                    href: 'base.customer.admin.account_settings.index',
                });
            }

            if (ng2ActiveFeatureService.isFeatureActivePure('general_shortcuts')) {
                vm.menu.push({
                    title: gettextCatalog.getString('Shortcuts'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'SHORTCUTS']),
                    href: 'base.customer.admin.shortcuts.index',
                });
            }

            if (activeFeatures.roles) {
                vm.menu.push({
                    title: gettextCatalog.getString('Roles'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'ROLES']),
                    href: 'base.customer.admin.roles.index',
                });
            }

            if (ng2ActiveFeatureService.isFeatureActive('api_users')) {
                vm.menu.push({
                    title: gettextCatalog.getString('API Users'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'USERS']),
                    href: 'base.customer.admin.api_users.index',
                });
            }

            if (ng2ActiveFeatureService.isFeatureActive('admin_page_performance')) {
                vm.menu.push({
                    title: gettextCatalog.getString('Performance'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'ADMIN', 'PAGE_PERFORMANCE', 'PROFILES']),
                    href: 'base.customer.admin.page_performance.dashboard',
                    activeRoutes: ['base.customer.admin.page_performance', 'base.customer.admin.page_performance.dashboard'],
                });
            }
        }
    }
})();
