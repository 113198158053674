(function () {
    'use strict';

    angular.module('models').factory('ShortcutModel', ShortcutModel);

    ShortcutModel.$inject = [];

    function ShortcutModel () {
        function Model (attributes) {
            angular.extend(this, attributes);
            this.url_target = this.url_target || '_blank';
            this.on_dashboard = Boolean(this.on_dashboard);
        }

        return Model;
        // /////////
    }
})();
