import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MonUtilsService {
    withinHundred (count: number): number {
        if (count >= 100) {
            return 100;
        } else if (count <= 0) {
            return 0;
        }

        return count;
    }

    getTextStyleByNumberValue (val: number): string {
        if (val > 0) {
            return 'positive';
        }
        if (val < 0) {
            return 'negative';
        }
        return 'neutral';
    }

    getRandomString (): string {
        return Math.random().toString(36)
            .substring(2);
    }
}

