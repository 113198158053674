(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTablePolicy', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/policy/policy.html',
        controller: PolicyController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
        },
    });

    PolicyController.$inject = ['$filter', 'complianceService', 'ng2ChangesHistoryHelperService'];
    /* @ngInject */
    function PolicyController ($filter, complianceService, ng2ChangesHistoryHelperService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.historyOutput = [];
        vm.issuesStack = ['policy_with_required_count', 'policy_with_violations_count'];
        vm.totalCount = 100;

        function activate () {
            getPolicyErrorOptions();
        }

        function onChanges (changes) {
            if (changes.history) {
                vm.historyOutput = prepareHistoryOutput(vm.history);
            }
        }

        function prepareHistoryOutput (history) {
            var result = [];
            var len = history.length;
            var story;
            var entry;

            // for is faster than map
            for (var i = 0; i < len; i += 1) {
                story = history[i];
                entry = {
                    id: story.id,
                    post_processing_done_at: story.post_processing_done_at,
                    date: story.date,
                    page_count: $filter('monNumber')(story.page_count),
                    documents_count: $filter('monNumber')(story.documents_count),
                    error: getError(story),
                    changePercentageValue: $filter('monNumber')(ng2ChangesHistoryHelperService.getChangePercentageValue(story.id, vm.history, vm.issuesStack) / 100, '0.[00]%'),
                    hasCompliance: hasCompliance(story),
                    changeValueColorClass: ng2ChangesHistoryHelperService.getChangeValueColorClass(story.id, vm.history, vm.issuesStack),
                    changeValueIconClass: ng2ChangesHistoryHelperService.getChangeValueIconClass(story.id, vm.history, vm.issuesStack),
                    policy_with_required_count: $filter('monNumber')(story.policy_with_required_count),
                    policy_with_violations_count: $filter('monNumber')(story.policy_with_violations_count),
                };
                result.push(entry);
            }

            return result;
        }

        function getPolicyErrorOptions () {
            vm.policyErrors = [
                {
                    icon: 'fas fa-ban',
                    attr: 'policy_with_violations_count',
                    color: 'text-danger',
                },
                {
                    icon: 'fas fa-exclamation-triangle',
                    attr: 'policy_with_required_count',
                    color: 'text-warning',
                },
            ];
        }

        function hasCompliance (history) {
            return getError(history) !== null;
        }

        function getError (history) {
            var complianceAr = complianceService.policyHistoryCompliance(history);
            if (Array.isArray(complianceAr)) {
                if (complianceService.hasLegacyCompliance()) {
                    vm.totalCount = complianceAr[0] + complianceAr[1];
                }
                return complianceAr[1];
            } else {
                return null;
            }
        }

    }
})();
