import { Component, OnInit, Input } from '@angular/core';
import { uniqBy } from 'lodash';
import { User } from '@monsido/modules/models/api/user';

@Component({
    selector: 'mon-show-more-avatars',
    templateUrl: './show-more-avatars.component.html',
    styleUrls: ['./show-more-avatars.component.scss'],
})
export class ShowMoreAvatarsComponent implements OnInit {
    expanded: boolean = false;
    showButton: boolean = false;
    uniqueUsers: User[] = [];
    @Input() users: User[] = [];
    @Input() minElements: number = 4;

    ngOnInit (): void {
        if (this.users) {
            this.uniqueUsers = this.filterUniqUsers();
            this.showButton = this.uniqueUsers.length > this.minElements;
        }
    }

    toggleExpanded (): void {
        this.expanded = !this.expanded;
    }

    filterUniqUsers (): User[] {
        return uniqBy(this.users, 'id');
    }
}
