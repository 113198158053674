(function () {
    'use strict';
    angular.module('app.components').component('tableCssCompliance', {
        templateUrl: 'app/components/css-compliance/compliance.html',
        controller: TableCssComplianceController,
        controllerAs: 'vm',
        bindings: {
            totalCount: '<',
            errorCount: '<',
            monAction: '&?',
        },
    });

    TableCssComplianceController.$inject = ['$filter', 'ng2MonUtilsService'];
    /* @ngInject */
    function TableCssComplianceController ($filter, ng2MonUtilsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPercentage = getPercentage;
        vm.runAction = runAction;
        vm.hasAction = hasAction;

        function activate () {
            vm.totalCount = vm.totalCount || 0;
            vm.errorCount = vm.errorCount || 0;

        }

        function getPercentage (decimals) {
            var percentage = 0;
            if (vm.totalCount !== 0) {
                var percentage = (1 - vm.errorCount / vm.totalCount);
            }

            if (!decimals) {
                return percentage;
            } else {
                return $filter('monNumber')(ng2MonUtilsService.withinHundred(percentage), '0.[00]%');
            }
        }

        function runAction () {
            if (hasAction()) {
                vm.monAction();
            }
        }

        function hasAction () {
            return typeof vm.monAction === 'function';
        }
    }
})();
