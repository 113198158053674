import { Component, Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({ selector: 'ng1-api-users-dashboard', standalone: true })
export class Ng1ApiUsersDashboardComponentWrapper extends UpgradeComponent {

    constructor (elementRef: ElementRef, injector: Injector) {
        super('apiUsersDashboard', elementRef, injector);
    }
}

@Component({
    selector: 'ng1-api-users-dashboard-component',
    template: `
      <ng1-api-users-dashboard></ng1-api-users-dashboard>
    `,
    standalone: true,
    imports: [
        Ng1ApiUsersDashboardComponentWrapper,
    ],
})
export class Ng1ApiUsersDashboardComponent {

}
