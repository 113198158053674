import { Injectable } from '@angular/core';
import { ReportRepoService } from '../../api/report-repo/report-repo.service';
import { ReportInterface } from '@monsido/ng2/modules/models/api/interfaces/report.interface';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(
        private reportRepoService: ReportRepoService,
    ) { }

    update(id: number, params: Partial<ReportInterface>) {
        return this.reportRepoService.update(id, params);
    }

    create(params: Partial<ReportInterface>) {
        return this.reportRepoService.create(params);
    }

}
