(function () {
    'use strict';

    angular.module('modules.demodata').filter('hasDemoAccess', hasDemoAccess);

    hasDemoAccess.$inject = ['ng2DemoHasAccessService'];

    /* @ngInject */
    function hasDemoAccess (ng2DemoHasAccessService) {
        return canDemoAccess;

        // //////////

        /**
         * @desc determine if demo has access
         * @param {string} module - Module name
         * @return {boolean}
         */
        function canDemoAccess (module) {
            switch (module) {
                case 'clarity':
                    return ng2DemoHasAccessService.canAccessAccessibilityPDF();
                case 'page_statistics':
                    return ng2DemoHasAccessService.useStatisticsDemoData();
                default:
                    return false;
            }
        }
    }
})();
