import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule, AngularSharedComponentsModule, IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import {
    ScheduledReportsListComponent,
} from './scheduled-reports-list.component';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        ScheduledReportsListComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TableModule,
        AngularSharedComponentsModule,
        IconsModule,
    ],
    providers: [
        MonEventService,
    ],
    exports: [
        ScheduledReportsListComponent,
    ],
})
export class ScheduledReportsListModule { }
