(function () {
    'use strict';
    angular.module('app.components').component('seoDialogPagesWithDuplicateTitle', {
        templateUrl: 'app/pages/seo/dialogs/pages-with-duplicate-title/pages-with-duplicate-title.html',
        controller: PagesWithDuplicateTitleController,
        controllerAs: 'vm',
        bindings: {
            issue: '<',
            pageCount: '<',
        },
    });

    PagesWithDuplicateTitleController.$inject = ['ng2SeoRepoService', 'ParamService', '$controller'];

    function PagesWithDuplicateTitleController (ng2SeoRepoService, ParamService, $controller) {
        var vm = this;

        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.getTitles = getTitles;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.currentSort = { by: 'title', direction: 'desc' };
            vm.selectedTitle = null;
            vm.titles = [];
            vm.promise = null;
            getPage();
            vm.exports = [
                {
                    data: {
                        category: 'seo',
                        category_ref: vm.issue.name,
                    },
                },
            ];
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            vm.promise = ng2SeoRepoService.getIssueContent(vm.issue.id, params).then(function (data) {
                vm.titles = data;
                if (vm.titles.length > 0) {
                    vm.selectedTitle = vm.titles[0];
                } else {
                    vm.selectedTitle = null;
                }
            }, angular.noop);
        }

        function getTitles () {
            getPage();
        }
    }
})();
