<mon-issue [id]="issueId"
           [title]="issueTitle"
           [status]="status"
           [issueNotFound]="!loadingIssueData && !issueData"
           class="mon-qa-misspelling-issue-container">
  <div slot="issue-action-button">
    <button class="btn btn-border-radius btn-secondary mr-1"
            (click)="openInExtension(page)"
            [ngbTooltip]="'Inspect page with browser extension' | translate"
            [container]="'body'"
            *ngIf="isPermittedForChanges">
            <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
            <span class="sr-only" translate>Open browser extension</span>
    </button>
    <button (click)="this.searchWordInGoogle(issueData ?issueData.word: '')"
            [attr.aria-describedby]="'linkOpensInNewTab'"
            [ngbTooltip]="'Look up in Google' | translate"
            class="btn btn-secondary cursor-pointer mr-1">
            <mon-icon [icon]="['GOOGLE', 'ICON']"></mon-icon>
            <span class="sr-only" translate>Look up in Google</span>
    </button>
    <mon-action-menu class="mr-1" *ngIf="isPermittedForChanges" [monActionMenuItems]="dropdownOptions">
        <span translate>Action</span>
    </mon-action-menu>
  </div>
  <div slot="issue-overview">
    <h3 class="font-weight-300 mt-3 mx-4" translate>Issue details</h3>
    <hr>
    <div class="row ml-1 mt-4">
        <div class="col-12">
            <b translate>Element</b><br>
            <span translate>Text</span>
        </div>
        <div class="col-12">
            <b translate>Language</b><br>
            <span>{{ issueLanguage }}</span>
        </div>
        <div class="col-12">
            <b translate>Date found</b><br>
            <span>{{ issueData?.created_at }}</span>
        </div>
    </div>
    <div class="row ml-1 mr-4 mt-4">
        <div class="col">
            <div class="row">
                <div class="col">
                    <b translate>Snippet</b>
                </div>
            </div>
            <div class="ml-0 issue-overview-text">
                <div class="p-2 mt-1 bg-danger">
                    {{ issueData?.word }}
                </div>
            </div>
        </div>
    </div>
    <div class="row ml-1 mr-4 mt-4 mb-6">
        <div class="col">
            <div class="row">
                <div class="col">
                    <b translate>Found on page</b>
                </div>
            </div>
            <div class="ml-0">
                <div class="row">
                    <div *ngIf="page" class="col-48">
                        <mon-url-title [title]="page.title" [url]="page.url" [active]="false"></mon-url-title>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div slot="issue-content">
    <h3 class="font-weight-300 mt-3 mx-4">{{ contentTableName }}</h3>
    <hr>
    <div class="m-4">
        <mon-table-container [collection]="pages"
                             [loading]="loadingPages"
                             (pageChangeEvent)="onPagesPageChange($event, getPagesParams)"
                             (perPageChange)="onPagesPerPageChange($event, getPagesParams)">
            <div class="table-container-body">
                <table class="table table-hover table-fixed table-with-labels">
                    <thead>
                        <tr>
                            <th
                                monTableSort
                                [monThTitle]="'Title and URL' | translate"
                                [monSortBy]="'title'"
                                [monSortDirection]="getPagesParams.value.sort_dir || 'asc'"
                                [monCurrentlySortedBy]="getPagesParams.value.sort_by || ''"
                                (monOnSorted)="onSortPages($event, getPagesParams)">
                            </th>
                            <th class="col-6 text-right" translate>Language</th>
                            <th class="col-6 text-right" translate>Misspellings</th>
                            <th class="col-6 text-right" translate>Potential misspellings</th>
                            <th class="pl-5 col-7 text-right"
                                monTableSort
                                *ngIf="viewsColumIsAvailable"
                                [monThTitle]="'Views' | translate"
                                [monSortBy]="'hits'"
                                [monSortDirection]="getPagesParams.value.sort_dir || 'asc'"
                                [monCurrentlySortedBy]="getPagesParams.value.sort_by || ''"
                                (monOnSorted)="onSortPages($event, getPagesParams)">
                            </th>
                            <th class="col-20 col-lg-8 col-md-12 col-sm-16 col-xl-6">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let page of pages">
                        <td>
                            <div class="text-ellipsis">
                                <mon-url-title [title]="page.title" [url]="page.url" [active]="false"></mon-url-title>
                            </div>
                        </td>
                        <td class="text-v-middle text-right">
                            {{ page.language }}
                        </td>
                        <td class="text-v-middle text-right">
                            {{ page.spelling_errors_confirmed_count }}
                        </td>
                        <td class="text-v-middle text-right">
                            {{ page.spelling_errors_potential_count }}
                        </td>
                        <td *ngIf="viewsColumIsAvailable" class="pl-5 text-v-middle text-right">
                            <mon-visits [title]="page.title" [visits]="page.hits" [totalVisits]="pages.pageHits || 0"></mon-visits>
                        </td>
                        <td class="text-v-middle">
                            <div class="d-flex align-items-center justify-content-end flex-wrap">
                                <button (click)="goToPageDetails(page)"
                                        class="btn btn-secondary"
                                        [ngbTooltip]="'Go to page details' | translate"
                                        [container]="'body'">
                                    <mon-icon [icon]="['TABLES', 'PAGEDETAILS']"></mon-icon>
                                    <span class="sr-only" translate>Open page details</span>
                                </button>
                                <button class="btn btn-border-radius btn-secondary ml-1"
                                       (click)="openInExtension(page)"
                                       [ngbTooltip]="'Inspect page with browser extension' | translate"
                                       [container]="'body'">
                                    <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
                                    <span class="sr-only" translate>Open browser extension</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </div>
  </div>
</mon-issue>
