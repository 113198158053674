import { Pipe, PipeTransform } from '@angular/core';
import { Domain } from '@monsido/modules/models/api/domain';
import { CustomerHasPlanPipe } from 'ng2/shared/pipes/customer-has-plan/customer-has-plan.pipe';

@Pipe({
    name: 'isStatsDemoData',
})
export class IsStatsDemoDataPipe implements PipeTransform {
    constructor (private customerHasPlan: CustomerHasPlanPipe) {}

    transform (domain: Domain | null): boolean {
        const statistics = domain?.features?.statistics;
        return !statistics && this.customerHasPlan.transform('demo');
    }
}
