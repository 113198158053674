export const API_CONSTANTS = {
    PAGE_SIZE: 10,
    PAGES: {
        TYPE: {
            '0': 'HTML',
        },
    },
    SEO: {
        CLASSIFICATION: {
            '1': 'text-danger', // Alert
            '2': 'text-warning', // Warning
            '3': 'text-info', // Low
            '4': 'text-grey', // Technical
        },
    },
};

