export const PAGE_PERFORMANCE_AUDIT_INFO = [
    {
        id: 'no-document-write',
        difficulty: 1,
    },
    {
        id: 'viewport',
        difficulty: 1,
    },
    {
        id: 'lcp-lazy-loaded',
        difficulty: 2,
    },
    {
        id: 'no-document-write',
        difficulty: 1,
    },
    {
        id: 'uses-passive-event-listeners',
        difficulty: 1,
    },
    {
        id: 'third-party-facades',
        difficulty: 2,
    },
    {
        id: 'unsized-images',
        difficulty: 1,
    },
    {
        id: 'largest-contentful-paint-element',
        difficulty: 1,
    },
    {
        id: 'non-composited-animations',
        difficulty: 2,
    },
    {
        id: 'long-tasks',
        difficulty: 2,
    },
    {
        id: 'layout-shift-elements',
        difficulty: 3,
    },
    {
        id: 'server-response-time',
        difficulty: 3,
    },
    {
        id: 'duplicated-javascript',
        difficulty: 2,
    },
    {
        id: 'uses-http2',
        difficulty: 2,
    },
    {
        id: 'legacy-javascript',
        difficulty: 2,
    },
    {
        id: 'unused-javascript',
        difficulty: 3,
    },
    {
        id: 'modern-image-formats',
        difficulty: 2,
    },
    {
        id: 'total-byte-weight',
        difficulty: 3,
    },
    {
        id: 'third-party-summary',
        difficulty: 2,
    },
    {
        id: 'bootup-time',
        difficulty: 3,
    },
    {
        id: 'mainthread-work-breakdown',
        difficulty: 3,
    },
    {
        id: 'dom-size',
        difficulty: 3,
    },
    {
        id: 'resource-summary',
        difficulty: 3,
    },
    {
        id: 'font-display',
        difficulty: 1,
    },
    {
        id: 'uses-long-cache-ttl',
        difficulty: 1,
    },
    {
        id: 'critical-request-chains',
        difficulty: 3,
    },
    {
        id: 'user-timings',
        difficulty: 3,
    },
    {
        id: 'uses-text-compression',
        difficulty: 2,
    },
    {
        id: 'offscreen-images',
        difficulty: 2,
    },
    {
        id: 'uses-rel-preload',
        difficulty: 1,
    },
    {
        id: 'redirects',
        difficulty: 2,
    },
    {
        id: 'time-to-first-byte',
        difficulty: 3,
    },
    {
        id: 'uses-responsive-images',
        difficulty: 2,
    },
    {
        id: 'efficient-animated-content',
        difficulty: 1,
    },
    {
        id: 'uses-optimized-images',
        difficulty: 1,
    },
    {
        id: 'render-blocking-resources',
        difficulty: 1,
    },
    {
        id: 'unminified-css',
        difficulty: 1,
    },
    {
        id: 'unminified-javascript',
        difficulty: 1,
    },
    {
        id: 'unused-css-rules',
        difficulty: 2,
    },
    {
        id: 'uses-webp-images',
        difficulty: 2,
    },
    {
        id: 'uses-rel-preconnect',
        difficulty: 3,
    },
    {
        id: 'bf-cache',
        difficulty: 1,
    },
    {
        id: 'prioritize-lcp-image',
        difficulty: 1,
    },
] as const;
