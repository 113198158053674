(function () {
    'use strict';

    angular.module('modules.page_fix.services', [
        'modules.modals',
        'gettext',
        'services.api',
        'plugins.lodash',
        'modules.page_fix',
    ]);
})();
