import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormScheduleReportService } from './services/form-schedule-report/form-schedule-report.service';
import { TranslateReportService } from './services/translate-report/translate-report.service';
import {
    ScheduledReportsListModule,
} from '@monsido/modules/report-center/components/dialogs/scheduled-reports-list/scheduled-reports-list.module';
import { ShowMoreAvatarsModule } from './components/show-more-avatars/show-more-avatars.module';
import { TextSearchService } from '@monsido/services/text-search/text-search.service';
import { ReportCenterDashboardModule } from './pages/report-center-dashboard/report-center-dashboard.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { REPORT_CENTER_STATES } from './report-center.state';
import { ScheduleReportAdminUsersModule } from './components/dialogs/schedule-report-admin-users/schedule-report-admin-users.module';
import { ScheduleReportAdminNonUsersModule } from './components/dialogs/schedule-report-admin-non-users/schedule-report-admin-non-users.module';
import { ScheduleReportFormModule } from './components/dialogs/schedule-report-form/schedule-report-form.module';
import { ScheduleReportAdminModule } from './components/dialogs/schedule-report-admin/schedule-report-admin.module';
import { SendMeReportFormModule } from './components/dialogs/send-me-report-form/send-me-report-form.module';

@NgModule({
    imports: [
        CommonModule,
        ScheduleReportAdminUsersModule,
        ScheduleReportAdminNonUsersModule,
        ScheduleReportFormModule,
        ScheduleReportAdminModule,
        ScheduledReportsListModule,
        SendMeReportFormModule,
        UIRouterUpgradeModule.forChild({ states: REPORT_CENTER_STATES }),
        ShowMoreAvatarsModule,
        ReportCenterDashboardModule,
    ],
    providers: [
        FormScheduleReportService,
        TextSearchService,
        TranslateReportService,
    ],
})
export class ReportCenterModule {}
