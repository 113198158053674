(function () {
    'use strict';

    angular.module('filters.monsido').filter('activeFeature', activeFeature);

    activeFeature.$inject = ['activeFeatures'];

    /* @ngInject */
    function activeFeature (activeFeatures) {
        return active;

        // //////////

        function active (feature) {
            return activeFeatures[feature] || false; // Return false if undefined
        }
    }
})();
