(function () {
    'use strict';

    angular.module('modules.light.pages').component('lightUsersTable', {
        templateUrl: 'app/modules/light/pages/users/table/table.html',
        controller: LightUsersComponent,
        controllerAs: 'vm',
        bindings: {
            isAdmin: '<?',
            getPage: '&',
            updateEntry: '&',
            removeEntry: '&',
            users: '<',
            onPageSort: '&',
            currentSort: '<?',
        },
    });

    LightUsersComponent.$inject = ['CoreUserService', 'ng2LabelRepo', 'ng2DateTimeService', 'hotkeys', 'activeFeatures'];
    /* @ngInject */
    function LightUsersComponent (CoreUserService, ng2LabelRepo, ng2DateTimeService, hotkeys, activeFeatures) {
        var vm = this;

        vm.$onInit = activate;
        vm.formattedLoginDate = formattedLoginDate;
        vm.deleteUserPrompt = deleteUserPrompt;
        vm.updateUserLabels = updateUserLabels;
        vm.updateUser = updateUser;
        vm.getLabels = getLabels;

        function activate () {
            vm.isAdmin = vm.isAdmin || false;
            vm.users = [];
            vm.showLabels = activeFeatures.labels;
            vm.currentSort = vm.currentSort || {
                by: '',
                direction: '',
            };
            hotkeysInit();
            getLabels();
        }

        function hotkeysInit () {
            // TODO: Bind keys without using $scope, https://zube.io/monsido/monsido/c/23007
            /* hotkeys.bindTo($scope).add({
                combo: "n",
                description: "Create user",
                callback: function(event, hotkey) {
                    event.preventDefault();
                    user();
                },
            });*/
        }

        function deleteUserPrompt (user) {
            CoreUserService.deleteUserPrompt(user).then(function () {
                vm.removeEntry({ user: user });
            }, angular.noop);
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function updateUserLabels (user) {
            CoreUserService.updateUserLabels(user);
        }

        function updateUser (user) {
            CoreUserService.updateUser(user).then(function (updatedUser) {
                vm.updateEntry({ user: updatedUser });
            });
        }

        function formattedLoginDate (last_login_at) {
            return ng2DateTimeService.format(last_login_at, 'DD MMM, YYYY');
        }
    }
})();
