(function () {
    'use strict';

    angular.module('pages.seo').component('seoSummaryOpportunitiesInCategory', {
        templateUrl: 'app/pages/seo/summary/blocks/opportunities-in-category/opportunities-in-category.html',
        controller: SeoSummaryOpportunitiesInCategoryController,
        controllerAs: 'vm',
        bindings: {
            alertPageCount: '<',
            warningPageCount: '<',
            infoPageCount: '<',
            technicalPageCount: '<',
            pageCount: '<',
        },
    });

    SeoSummaryOpportunitiesInCategoryController.$inject = ['gettextCatalog', '$filter'];
    /* @ngInject */
    function SeoSummaryOpportunitiesInCategoryController (gettextCatalog, $filter) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.seoCategories = [];
            vm.labels = ['', ''];
            setupSeoDoughnuts();
        }

        function setupSeoDoughnuts () {
            var charts = [
                    {
                        title: gettextCatalog.getString('High priority'),
                        pageErrors: vm.alertPageCount,
                        circleColor: 'text-danger',
                    },
                    {
                        title: gettextCatalog.getString('Medium priority'),
                        pageErrors: vm.warningPageCount,
                        circleColor: 'text-warning',
                    },
                    {
                        title: gettextCatalog.getString('Low priority'),
                        pageErrors: vm.infoPageCount,
                        circleColor: 'text-info',
                    },
                    {
                        title: gettextCatalog.getString('Technical SEO Issues'),
                        pageErrors: vm.technicalPageCount,
                        circleColor: 'text-primary',
                    },
                ];
                var i;

            for (i = 0; i < charts.length; i++) {
                vm.seoCategories.push(setupDoughnut(charts[i]));
            }
        }

        function setupDoughnut (detail) {
            var colors = ['#4dc0bd', '#DCDCDC'];

            return {
                title: detail.title,
                color: detail.circleColor,
                errors: detail.pageErrors,
                inCompliance: vm.pageCount - detail.pageErrors,
                colors: colors,
                data: [vm.pageCount - detail.pageErrors, detail.pageErrors],
                options: getOptions(detail.pageErrors, vm.pageCount),
            };
        }

        function getOptions (errorCount, pagesCount) {
            return {
                centerText: {
                    percentageLabel: $filter('monNumber')(1 - errorCount / pagesCount, '0.[00] %'),
                },
                cutoutPercentage: 80,
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
        }
    }
})();
