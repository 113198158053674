import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationDuplicateTitle', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/duplicate-title/duplicate-title.html',
        controller: SeoTranslationsMultipleH1Controller,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTranslationsMultipleH1Controller.$inject = ['ng2MonEventsService'];
    /* @ngInject */
    function SeoTranslationsMultipleH1Controller (ng2MonEventsService) {
        var vm = this;
        vm.seePages = seePages;

        function seePages () {
            var params = {
                body: 'pageDetailsSeoPagesDialog',
                size: 'lg',
                data: {
                    seo: vm.seo,
                    title: vm.seo.content,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
