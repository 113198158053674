(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name AccessibilityCloseCaptionService
     * @description Validate if Accessibility Check is compatible for closed caption
     */
    angular.module('blocks.service').factory('AccessibilityCloseCaptionService', AccessibilityCloseCaptionService);

    AccessibilityCloseCaptionService.$inject = ['ng2SessionService'];
    /* @ngInject*/
    function AccessibilityCloseCaptionService (ng2SessionService) {
        var checksCloseCaption = [17, 20, 77, 145, 146];

        return {
            showCloseCaption: showCloseCaption,
        };

        // ///////////////

        /**
         * @memberOf AccessibilityCloseCaptionService
         * @description Check if Accessibility check is compatible for closed caption
         * @param {object} check
         * @return {boolean}
         */
        function showCloseCaption (check) {
            if (typeof check.id !== 'number') {
                return false;
            }
            return checksCloseCaption.indexOf(check.id) > -1 && hasCloseCaption();
        }

        // PROTECTED

        function hasCloseCaption () {
            if (ng2SessionService.customer === null) {
                return false;
            }

            return Boolean(ng2SessionService.customer.plan_traits.closed_caption);
        }
    }
})();
