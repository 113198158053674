(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.behavior').factory('ApiUsersService', ApiUsersService);

    ApiUsersService.$inject = ['$deepmerge', 'gettextCatalog', 'Lodash'];
    /* @ngInject*/
    function ApiUsersService ($deepmerge, gettextCatalog, Lodash) {
        var vm = this;


        vm.scopes = [
            {
                name: gettextCatalog.getString('CMS'),
                value: 'cms',
            },
            {
                name: gettextCatalog.getString('Customer'),
                value: 'customer',
            },
            {
                name: gettextCatalog.getString('Admin'),
                value: 'customer_admin',
            },
        ];

        return {
            getScopes: getScopes,
            getScopeTranslation: getScopeTranslation,
        };

        function getScopes () {
            return $deepmerge([], vm.scopes);
        }

        function getScopeTranslation (value) {
            var scope = Lodash.find(vm.scopes, { value: value });
            if (typeof scope !== 'undefined') {
                return scope.name;
            }
        }
    }
})();
