(function () {
    'use strict';
    angular
        .module('modules.page_fix.forms')
        .service('PageFormFormWizardContainerViewModelService', PageFormFormWizardContainerViewModelService);

    PageFormFormWizardContainerViewModelService.$inject = [
        'PAGE_FIX_FORM_VIEW_TYPE',
        'gettextCatalog',
        'PAGE_FIX_FORM_FOOTER',
        'PAGE_FIX_FORM_VIEW_NAME',
    ];
    /* @ngInject*/
    function PageFormFormWizardContainerViewModelService (
        PAGE_FIX_FORM_VIEW_TYPE,
        gettextCatalog,
        PAGE_FIX_FORM_FOOTER,
        PAGE_FIX_FORM_VIEW_NAME,
    ) {
        return function (el, params) {
            var vm = this;

            if (params.type === PAGE_FIX_FORM_VIEW_TYPE.INFO) {
                params.title = params.title || gettextCatalog.getString('Info');
                params.footer = params.footer || PAGE_FIX_FORM_FOOTER.GO_TO;
                params.viewName = params.viewName || PAGE_FIX_FORM_VIEW_NAME.INFO;
            }

            if (params.type === PAGE_FIX_FORM_VIEW_TYPE.TESTING) {
                params.title = params.title || gettextCatalog.getString('Testing');
                params.footer = params.footer || PAGE_FIX_FORM_FOOTER.GO_TO;
                params.viewName = params.viewName || PAGE_FIX_FORM_VIEW_NAME.TESTING;
            }

            if (params.type === PAGE_FIX_FORM_VIEW_TYPE.OPTIONS) {
                params.title = params.title || gettextCatalog.getString('Options');
                params.footer = params.footer || PAGE_FIX_FORM_FOOTER.SUBMIT;
                params.viewName = params.viewName || PAGE_FIX_FORM_VIEW_NAME.OPTIONS;
            }

            vm.type = params.type;
            vm.viewName = params.viewName;
            vm.order = params.order;
            vm.footer = params.footer;
            vm.title = params.title;
            vm.selected = !!params.selected;
            vm.el = el;
        };

        // ///////////////
    }
})();
