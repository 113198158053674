(function () {
    'use strict';

    angular.module('modules.chat.zendesk').provider('Zendesk', function () {
        getter.$inject = ['ng2SessionService'];

        function getter (ng2SessionService) {
            let loaded = false;
            function addScript () {
                if (shouldStartZendesk()) {
                    initScript(ng2SessionService.getChatSystemOptions());
                }
            }

            return {
                addScript: addScript,
            };

            function shouldStartZendesk () {
                return !loaded;
            }

            function setupWidget () {
                try {
                    if (zE) {
                        zE('messenger', 'hide');
                        zE('messenger:on', 'close', function () {
                            zE('messenger', 'hide');
                        });
                    }
                } catch (e) {
                    setTimeout(setupWidget, 100);
                }
            }

            function initScript (options) {
                if (options) {
                    const zendeskScript = document.createElement('script');
                    zendeskScript.nonce = 'POIVxjtpTiXk';
                    zendeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + options.key;
                    zendeskScript.id = 'ze-snippet';
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.nonce = '0MLgjJQShXrb';
                    script.innerHTML = ' window.zESettings = ' + decodeURI(options.settings);
                    loaded = true;
                    document.body.appendChild(zendeskScript);
                    document.body.appendChild(script);
                    setupWidget();
                }
            }
        }
        this.$get = getter;
    });
})();
