(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name AccessibilitySourceCodeExcludesEntity
     * @description Service to handle Accessibility Source Code Excludes
     */
    angular
        .module('services.entities')
        .factory('AccessibilitySourceCodeExcludesEntity', AccessibilitySourceCodeExcludesEntity);

    AccessibilitySourceCodeExcludesEntity.$inject = ['AccessibilitySourceCodeExcludeRepo'];
    /* @ngInject*/
    function AccessibilitySourceCodeExcludesEntity (AccessibilitySourceCodeExcludeRepo) {
        return {
            update: update,
            create: create,
            destroy: destroy,
        };

        function update (sourceCodeExclude, updatedSourceCodeExclude, params, config) {
            return AccessibilitySourceCodeExcludeRepo.update(
                sourceCodeExclude,
                updatedSourceCodeExclude,
                params,
                config,
            );
        }

        function create (sourceCodeExclude, params, config) {
            return AccessibilitySourceCodeExcludeRepo.create(sourceCodeExclude, params, config);
        }

        function destroy (sourceCodeExclude, params, config) {
            return AccessibilitySourceCodeExcludeRepo.destroy(sourceCodeExclude.id, params, config);
        }
    }
})();
