import { Injectable } from '@angular/core';
import { PagePerformanceAuditDetailsService } from '../../../services/page-performance-audit-details/page-performance-audit-details.service';

type ContentType = {
    text: string,
    cssClass: string
}

type ItemType = {
    url: string,
    columns: ContentType[]
}

@Injectable({
    providedIn: 'root',
})
export class DisplayHelperService {

    constructor (private pagePerformanceAuditDetailsService: PagePerformanceAuditDetailsService) {}

    getHeadings (details): ContentType[] {
        const headings: ContentType[] = [];
        details.headings?.forEach((heading) => {
            headings.push({
                text: heading.text || heading.label,
                cssClass: this.pagePerformanceAuditDetailsService.getTableHeadingClass(heading),
            });
        });
        return headings;
    }

    getItems (details): ItemType[] {
        const items: ItemType[] = [];
        details.items?.forEach((item) => {
            const columns: ContentType[] = [];
            details.headings?.forEach((heading) => {
                columns.push({
                    text: this.pagePerformanceAuditDetailsService.getFormatAuditItem(item, heading),
                    cssClass: this.pagePerformanceAuditDetailsService.getTableHeadingClass(heading),
                });
            });
            items.push({
                url: item.url,
                columns,
            });
        });
        return items;
    }


    isDetailTypeTable (type): boolean {
        return this.pagePerformanceAuditDetailsService.isTypeTable(type);
    }

    hasDetails (details): boolean {
        return Boolean(details);
    }

    hasDetailsTypeContent (details): boolean {
        return details.headings.length > 0 && details.items.length > 0;
    }

    isDetailTypeOpportunity (details): boolean {
        return this.pagePerformanceAuditDetailsService.isTypeOpportunity(details.type);
    }

    isTypeCriticalRequestChain (details): boolean {
        return this.pagePerformanceAuditDetailsService.isTypeCriticalRequestChain(details.type);
    }

}
