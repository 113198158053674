(function () {
    'use strict';
    angular.module('pages.accessibility').component('accessibilityDashboardSummary', {
        templateUrl: 'app/pages/accessibility/dashboard/summary/summary.html',
        controller: AccessibilityDashboardSummaryController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            summary: '<',
            accessibilityChecks: '<',
            selectedObs: '<',
        },
    });

    AccessibilityDashboardSummaryController.$inject = [
        'ng2DemoHasAccessService',
        'ng2ActiveFeatureService',
        'ng2ScoresService',
        'gettextCatalog',
        'AccessibilityDoughnutChartService',
        'AccessibilityLineChartService',
    ];
    /* @ngInject */
    function AccessibilityDashboardSummaryController (
        ng2DemoHasAccessService,
        ng2ActiveFeatureService,
        ng2ScoresService,
        gettextCatalog,
        AccessibilityDoughnutChartService,
        AccessibilityLineChartService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getSelectedScanDateName = getSelectedScanDateName;

        vm.allowVerticalAverage = false;
        vm.isVerticalDataSet = false;
        vm.verticalAverage = 0;

        function activate () {
            vm.selectedDate = null;
            vm.selectedObs.subscribe(function (val) {
                setupLineChart(val);
                setupDoughnutChart(val);
            });

            var isDemoAccount = ng2DemoHasAccessService.isDemoAccount();
            if (isDemoAccount) {
                vm.allowVerticalAverage = true;
                setVerticalData(ng2ScoresService.getAccessibilityDemoData());
            } else {
                vm.allowVerticalAverage = ng2ActiveFeatureService.isFeatureActive('monsido_score');
                if (vm.allowVerticalAverage) {
                    ng2ScoresService.getAverageData('accessibility').then(setVerticalData);
                }
            }

            vm.texts = {
                averageTitle: ng2ScoresService.getDefaultTitle(),
                succeeds: gettextCatalog.getString('Succeeds'),
                errors: gettextCatalog.getString('Errors'),
            };
        }

        function setupDoughnutChart (val) {
            var chartService = new AccessibilityDoughnutChartService(vm.summary, vm.accessibilityChecks);
            vm.doughnutData = chartService.getData(val);
            vm.doughnutOptions = chartService.getOptions(val);

            vm.doughnutOptions.tooltips = {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        if (tooltipItem.index === 0) {
                            return vm.texts.succeeds;
                        }
                        return vm.texts.errors;
                    },
                },
            };
        }

        function setupLineChart (val) {
            var service = new AccessibilityLineChartService();
            service.setup().then(function () {
                vm.progress = service.getOption(val, vm.history).then(function (options) {
                    vm.lineOptions = options;
                    vm.lineData = service.getData(vm.history, val);
                }, angular.noop);
            }, angular.noop);
        }

        function getSelectedScanDateName () {
            if (vm.selectedDate === null || typeof vm.selectedDate !== 'object') {
                return gettextCatalog.getString('No date range selected');
            }
            return vm.selectedDate.name;
        }

        function setVerticalData (data) {
            const defaultTooltip = ng2ScoresService.getDefaultTooltip();
            if (vm.texts) {
                vm.texts.verticalName = data.name;
            }
            vm.verticalTooltipText = defaultTooltip + ' ' + data.name;
            vm.verticalAverage = data.score;
            vm.isVerticalDataSet = true;
        }
    }
})();
