(function() {
    "use strict";

    angular.module("app.components.pageDetails").component("quickHelpTranslations", {
        templateUrl:
            "app/components/pagedetails/details/sections/seo/seo-information/sections/quick-help/translations/translations.html",
        controllerAs: "vm",
        bindings: {
            seoName: "<"
        }
    });
})();
