(function () {
    'use strict';

    angular.module('modules.business-intelligence').run(runEvents);

    runEvents.$inject = ['ng2MonEventsService', 'biService'];
    /* @ngInject */
    function runEvents (ng2MonEventsService, biService) {
        ng2MonEventsService.addListener('onPageView', function () {
            biService.pageView();
        });
    }
})();
