(function () {
    'use strict';
    angular.module('blocks.helpers').service('PolicyFormHelper', policyFormHelper);

    policyFormHelper.$inject = ['POLICY_FORM_HELPER_DB', 'gettextCatalog'];

    /* @ngInject*/
    function policyFormHelper (POLICY_FORM_HELPER_DB, gettextCatalog) {
        var policySubject; var ruleType;
        var optionsBySubject = POLICY_FORM_HELPER_DB.SUBJECT_OPTIONS;
        var optionsByRuleType = POLICY_FORM_HELPER_DB.RULE_TYPE_OPTIONS;
        var icons = POLICY_FORM_HELPER_DB.ICONS;
        var rawIcons = POLICY_FORM_HELPER_DB.RAW_ICONS;
        var translations = POLICY_FORM_HELPER_DB.TRANSLATIONS;
        var subEntries = POLICY_FORM_HELPER_DB.RULE_TYPE_SUB_ENTRIES;

        return {
            setPolicySubject: setPolicySubject,
            getSubjectTypeOptions: getSubjectTypeOptions,
            getSubjectTypeOption: getSubjectTypeOption,
            setRuleType: setRuleType,
            getOptionsByRuleType: getOptionsByRuleType,
            getOptionsByRuleSize: getOptionsByRuleSize,
            getMenuDropdownOptions: getMenuDropdownOptions,
            getMenuDropdownOptionsByRuleType: getMenuDropdownOptionsByRuleType,
            getMenuDropdownOptionsByRuleSize: getMenuDropdownOptionsByRuleSize,
            getRuleSubEntries: getRuleSubEntries,
            isRuleTypeText: isRuleTypeText,
            getSubjectTypeHelpBlock: getSubjectTypeHelpBlock,
        };

        // ///////////////

        function setPolicySubject (subject) {
            policySubject = subject;
            return policySubject;
        }

        function getSubjectTypeOptions () {
            var types = optionsBySubject[policySubject];
            if (types) {
                var i;
                var result = [];
                for (i = 0; i < types.length; i++) {
                    result.push(getSubjectTypeOption(types[i]));
                }
                return result;
            } else {
                return [];
            }
        }

        function getSubjectTypeOption (type) {
            return {
                type: type,
                icons: icons.SUBJECT_OPTIONS[type],
                rawIcons: rawIcons.SUBJECT_OPTIONS[type],
                translation: gettextCatalog.getString(translations.SUBJECT_OPTIONS.NAME[type]),
                description: gettextCatalog.getString(translations.SUBJECT_OPTIONS.DESCRIPTION[type]),
            };
        }

        function setRuleType (type) {
            ruleType = type;
            return ruleType;
        }

        function getOptionsByRuleType () {
            return optionsByRuleType[ruleType].map(function (type) {
                return {
                    type: type,
                    translation: gettextCatalog.getString(translations.RULE_TYPE_OPTIONS[type]),
                };
            });
        }

        function getOptionsByRuleSize () {
            return [
                {
                    name: gettextCatalog.getString('KB'),
                    value: 'kb',
                },
                {
                    name: gettextCatalog.getString('MB'),
                    value: 'mb',
                },
            ];
        }

        function getMenuDropdownOptions (source, getLabel, handler) {
            return source().map( option => {
                return {
                    ...option,
                    action: () => handler(option),
                    label: getLabel(option),
                };
            });
        }

        function getMenuDropdownOptionsByRuleType (handler) {
            return getMenuDropdownOptions(getOptionsByRuleType, (option) => option.translation, handler);
        }

        function getMenuDropdownOptionsByRuleSize (handler) {
            return getMenuDropdownOptions(getOptionsByRuleSize, (option) => option.name, handler);
        }

        function getRuleSubEntries () {
            return {
                allTxt: gettextCatalog.getString(translations.RULE_TYPE_SUB_ENTRIES.ALL[ruleType] || 'Select all'),
                labelTxt: gettextCatalog.getString(translations.RULE_TYPE_SUB_ENTRIES.LABEL[ruleType] || 'Type'),
                options: (subEntries[ruleType] || []).map(function (entry) {
                    // Translate the sub entry name
                    entry.name = gettextCatalog.getString(entry.name);
                    return entry;
                }),
            };
        }

        function isRuleTypeText (ruleType) {
            var ruleTypes = [
                'page_html',
                'page_text',
                'page_title',
                'header_text',
                'page_url',
                'image_text',
                'link_text',
                'link_url',
            ];
            return ruleTypes.indexOf(ruleType) > -1;
        }

        function getSubjectTypeHelpBlock (ruleType) {
            var helpBlock = translations.RULE_TYPE_HELP_BLOCKS[ruleType];
            if (typeof helpBlock === 'string') {
                return gettextCatalog.getString(helpBlock);
            }

            return '';
        }
    }
})();
