import { default as TIME_ZONE } from '@monsido/core/constants/timeZone.constant.json';
import { Injectable } from '@angular/core';
import { PageRepoService } from '@client/app/services/api/page-repo/page-repo.service';
import { SessionService } from '@monsido/core/session/session.service';
import {
    generateDemoStatsRepoData,
    generateDownloadsByUrlPage,
    generateEntryPage,
    generateExitPage,
    generateLeastPopularPage,
    generateMostDownloadPage,
    generateMostPopularPage,
    generatePagesDocumentsWithoutDownload,
    generatePageWithoutVisits,
} from '@client/app/modules/demodata/endpoints/pages/demo-stats-repo/demo-stats-repo.data';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { PageModel } from '@client/app/models/page-model-factory.service';
import moment from 'moment';
import { StatisticsDownload } from '@client/app/modules/statistics/interfaces/statistics-download.interface';
import { StatisticsPage } from '@client/app/modules/statistics/interfaces/statistics-vistor.interface';

type DemoStatsRepoDataType = ReturnType<typeof generateDemoStatsRepoData>
type DemoStatsRepoDataKeys = keyof DemoStatsRepoDataType

@Injectable({
    providedIn: 'root',
})
export class DemoStatsRepoService {
    demoData: ReturnType<typeof generateDemoStatsRepoData>;

    constructor (
        private pageRepoService: PageRepoService,
        private sessionService: SessionService,
    ) {
        this.demoData = this.generateData();
    }

    async getSiteSearchKeywords (): Promise<DemoStatsRepoDataType['siteSearchKeywords']> {
        return this.getData('getSiteSearchKeywords');
    }

    async getSiteSearchNoResultsKeywords (): Promise<DemoStatsRepoDataType['siteSearchKeywords']> {
        return this.getData('getSiteSearchNoResultsKeywords');
    }

    async getResolutions (): Promise<DemoStatsRepoDataType['resolutions']> {
        return this.getData('getResolutions');
    }

    async getOs (): Promise<DemoStatsRepoDataType['os']> {
        return this.getData('getOs');
    }

    async getOrganizations (): Promise<DemoStatsRepoDataType['organizations']> {
        return this.getData('getOrganizations');
    }

    async getLocations (): Promise<DemoStatsRepoDataType['locations']> {
        return this.getData('getLocations');
    }

    async getRegions (): Promise<DemoStatsRepoDataType['regions']> {
        return this.getData('getRegions');
    }

    async getCities (): Promise<DemoStatsRepoDataType['cities']> {
        return this.getData('getCities');
    }

    async getLanguages (): Promise<DemoStatsRepoDataType['languages']> {
        return this.getData('getLanguages');
    }

    async getBrowsers (): Promise<DemoStatsRepoDataType['browsers']> {
        return this.getData('getBrowsers');
    }

    async getSearchEngines (): Promise<DemoStatsRepoDataType['searchEngines']> {
        return this.getData('getSearchEngines');
    }

    async getReferrers (): Promise<DemoStatsRepoDataType['referrers']> {
        return this.getData('getReferrers');
    }

    async getSummary (): Promise<DemoStatsRepoDataType['summary']> {
        return this.getData('getSummary');
    }

    async getKeywords (): Promise<DemoStatsRepoDataType['keyWords']> {
        return this.getData('getKeywords');
    }


    async getPagesByUrl (params): Promise<StatisticsPage[]> {
        return this.getDownloadsByUrl(params) as unknown as Promise<StatisticsPage[]>;
    }

    async getDownloadsByUrl (params): Promise<StatisticsDownload[]> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generateDownloadsByUrlPage(page, index);
                    })
                    .filter(function (page) {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getPagesWithoutVisits (params): Promise<MonTableCollection<PageModel>> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generatePageWithoutVisits(page, index);
                    })
                    .filter(function (page) {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getPagesDocumentsWithoutDownloads (params): Promise<PageModel[]> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generatePagesDocumentsWithoutDownload(page, index, pages);
                    })
                    .filter(function (page) {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getLeastPopularPages (params): Promise<MonTableCollection<StatisticsPage>> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generateLeastPopularPage(page, index, pages);
                    })
                    .filter(function (page) {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getExitPages (params): Promise<MonTableCollection<StatisticsPage>> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generateExitPage(page, index, pages);
                    })
                    .filter(function (page) {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getEntryPages (params): Promise<MonTableCollection<StatisticsPage>> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generateEntryPage(page, index, pages);
                    })
                    .filter(function (page) {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getMostPopularPages (params): Promise<MonTableCollection<StatisticsPage>> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generateMostPopularPage(page, index, pages);
                    })
                    .filter((page) => {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getMostDownloads (params): Promise<StatisticsDownload[]> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getPages().then(
            (pages) => {
                return pages
                    .map((page, index) => {
                        return generateMostDownloadPage(page, index, pages);
                    })
                    .filter((page) => {
                        return moment(page.date).isAfter(from, 'day') && moment(page.date).isBefore(to, 'day');
                    })
                    .reverse();
            },
            function (response) {
                return response;
            },
        );
    }

    async getSummaryPerDate (params): Promise<DemoStatsRepoDataType['summaryPerDate']> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getData('getSummaryPerDate').then(function (summaries) {
            return summaries.filter(function (summary) {
                const date = moment(summary.date);
                return date.isAfter(from, 'day') && date.isBefore(to, 'day');
            });
        });
    }

    async getVisitors (params): Promise<DemoStatsRepoDataType['visitors']> {
        const from = moment(params.from, 'YYYY-MM-DD').subtract(1, 'day');
        const to = moment(params.to, 'YYYY-MM-DD').add(1, 'day');
        return this.getData('getVisitors').then(function (data) {
            return data[params.type || 'referring'].filter(function (visitor) {
                const date = moment(visitor.date);
                return date.isAfter(from, 'day') && date.isBefore(to, 'day');
            });
        });
    }

    async getDevices (): Promise<DemoStatsRepoDataType['devices']> {
        return this.getData('getDevices');
    }

    async getSocials (): Promise<DemoStatsRepoDataType['socials']> {
        return this.getData('getSocials');
    }

    private async getData (fnName: 'getSiteSearchKeywords'): Promise<DemoStatsRepoDataType['siteSearchKeywords']>
    private async getData (fnName: 'getSiteSearchNoResultsKeywords'): Promise<DemoStatsRepoDataType['siteSearchKeywords']>
    private async getData (fnName: 'getResolutions'): Promise<DemoStatsRepoDataType['resolutions']>
    private async getData (fnName: 'getOs'): Promise<DemoStatsRepoDataType['os']>
    private async getData (fnName: 'getOrganizations'): Promise<DemoStatsRepoDataType['organizations']>
    private async getData (fnName: 'getLocations'): Promise<DemoStatsRepoDataType['locations']>
    private async getData (fnName: 'getRegions'): Promise<DemoStatsRepoDataType['regions']>
    private async getData (fnName: 'getCities'): Promise<DemoStatsRepoDataType['cities']>
    private async getData (fnName: 'getLanguages'): Promise<DemoStatsRepoDataType['languages']>
    private async getData (fnName: 'getBrowsers'): Promise<DemoStatsRepoDataType['browsers']>
    private async getData (fnName: 'getSearchEngines'): Promise<DemoStatsRepoDataType['searchEngines']>
    private async getData (fnName: 'getReferrers'): Promise<DemoStatsRepoDataType['referrers']>
    private async getData (fnName: 'getKeywords'): Promise<DemoStatsRepoDataType['keyWords']>
    private async getData (fnName: 'getSummary'): Promise<DemoStatsRepoDataType['summary']>
    private async getData (fnName: 'getSummaryPerDate'): Promise<DemoStatsRepoDataType['summaryPerDate']>
    private async getData (fnName: 'getVisitors'): Promise<DemoStatsRepoDataType['visitors']>
    private async getData (fnName: 'getSocials'): Promise<DemoStatsRepoDataType['socials']>
    private async getData (fnName: 'getDevices'): Promise<DemoStatsRepoDataType['devices']>
    private async getData (fnName: string): Promise<DemoStatsRepoDataType[DemoStatsRepoDataKeys] | null> {
        const timer = Math.round(1500 * Math.random());
        await new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, timer);
        });

        switch (fnName) {
            case 'getResolutions':
                return this.demoData['resolutions'];
            case 'getOs':
                return this.demoData.os;
            case 'getOrganizations':
                return this.demoData.organizations;
            case 'getLocations':
                return this.demoData.locations;
            case 'getRegions':
                return this.demoData.regions;
            case 'getCities':
                return this.demoData.cities;
            case 'getLanguages':
                return this.demoData.languages;
            case 'getBrowsers':
                return this.demoData.browsers;
            case 'getSearchEngines':
                return this.demoData.searchEngines;
            case 'getReferrers':
                return this.demoData.referrers;
            case 'getKeywords':
                return this.demoData.keyWords;
            case 'getVisitors':
                return this.demoData.visitors;
            case 'getDevices':
                return this.demoData.devices;
            case 'getSummary':
                return this.demoData.summary;
            case 'getSummaryPerDate':
                return this.demoData.summaryPerDate;
            case 'getSocials':
                return this.demoData.socials;
            case 'getSiteSearchKeywords':
                return this.demoData.siteSearchKeywords;
            case 'getSiteSearchNoResultsKeywords':
                return this.demoData.siteSearchKeywords;
            default:
                return null;
        }
    }

    private getPages (): Promise<MonTableCollection<PageModel>> {
        return this.pageRepoService.getAll({
            page: 1,
            page_size: 10,
        });
    }

    private generateData (): ReturnType<typeof generateDemoStatsRepoData> {
        return generateDemoStatsRepoData(
            this.generateLabel(),
            this.sessionService.customer?.country ?? TIME_ZONE.countries.US.abbr,
        );
    }

    private generateLabel (): string {
        const countryCode = this.sessionService.customer?.country;
        const defaultCountryName = TIME_ZONE.countries.US.name;
        if (!countryCode) {
            return defaultCountryName;
        }
        return TIME_ZONE.countries[countryCode]?.name ?? defaultCountryName;
    }
}
