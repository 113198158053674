(function () {
    'use strict';

    angular.module('modules.data_privacy.pages').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.data_privacy_coming_soon',
                config: {
                    url: '/data-privacy/coming-soon',
                    template: '<privacy-module></privacy-module>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - Data Privacy'),
                    },
                    resolve: {
                        dataPrivacyFeatureEnabled: dataPrivacyFeatureEnabled,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.consent_overview',
                config: {
                    url: '/consent-overview',
                    template: '<div ui-view></div>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - Cookie Acceptance Rate'),
                        sidebar: 'data-privacy',
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy',
                config: {
                    url: '/data-privacy',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'data-privacy',
                        title: gettextCatalog.getString('Acquia Optimize - Data Privacy'),
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.summary',
                config: {
                    url: '',
                    template: '<privacy-summary></privacy-summary>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Data Privacy Summary') },
                    resolve: {
                        redirectInCaseOfLimitedAccessToDataPrivacy: redirectInCaseOfLimitedAccessToDataPrivacy,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.issues',
                config: {
                    url: '/issues?page&page_size&tab&search',
                    template: '<privacy-issues></privacy-issues>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Data Privacy Issues') },
                    resolve: {
                        dataPrivacyFeatureEnabled: dataPrivacyFeatureEnabled,
                        userHasAccessToDataPrivacy: userHasAccessToDataPrivacy,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.checklist',
                config: {
                    url: '/checklist?page&page_size&tab&search',
                    template: '<privacy-checklist></privacy-checklist>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Data Privacy Check List') },
                    resolve: {
                        dataPrivacyFeatureEnabled: dataPrivacyFeatureEnabled,
                        userHasAccessToDataPrivacy: userHasAccessToDataPrivacy,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.ignored-checks',
                config: {
                    url: '/ignored-checks?page&page_size&tab&search',
                    template: '<privacy-ignored-checks></privacy-ignored-checks>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Data Privacy Ignored Checks') },
                    resolve: {
                        dataPrivacyFeatureEnabled: dataPrivacyFeatureEnabled,
                        userHasAccessToDataPrivacy: userHasAccessToDataPrivacy,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.domain-settings',
                config: {
                    url: '/domain-settings',
                    template: '<domain-data-privacy-settings></domain-data-privacy-settings>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Data Privacy Guidelines') },
                    resolve: {
                        dataPrivacyFeatureEnabled: dataPrivacyFeatureEnabled,
                        userHasAccessToDataPrivacy: userHasAccessToDataPrivacy,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.index',
                config: {
                    url: '/list?page&page_size&tab&search',
                    template: '<privacy-list></privacy-list>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Data Privacy Content') },
                    resolve: {
                        dataPrivacyFeatureEnabled: dataPrivacyFeatureEnabled,
                        userHasAccessToDataPrivacy: userHasAccessToDataPrivacy,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.consent_overview.cookie_acceptance_rate',
                config: {
                    url: '/cookie-acceptance-rate',
                    template: '<cookie-acceptance-rate-overview></cookie-acceptance-rate-overview>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - Cookie Acceptance Rate'),
                        sidebar: 'data-privacy',
                    },
                    resolve: {
                        hasAccessToConsentManager: hasAccessToConsentManager,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.consent_overview.consent_log',
                config: {
                    url: '/consent-log?from&to&uuid',
                    template: '<consent-log></consent-log>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - Cookie Consent Log'),
                        sidebar: 'data-privacy',
                    },
                    resolve: {
                        hasAccessToConsentManager: hasAccessToConsentManager,
                    },
                },
            },
            {
                state: 'base.customer.domain.data_privacy.consent_overview.cookie_overview',
                config: {
                    url: '/cookie-overview',
                    template: '<cookie-overview></cookie-overview>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Cookie Overview'), sidebar: 'data-privacy' },
                    resolve: {
                        hasAccessToConsentManager: hasAccessToConsentManager,
                        isCustomerAdmin: isCustomerAdmin,
                    },
                },
            },
        ];
    }

    function checkUserHasAccessToDataPrivacy ($filter, ng2SessionService, MON_MODULES) {
        return $filter('hasAccess')(ng2SessionService.me, MON_MODULES.data_privacy);
    }

    function chackDataPrivacyFeatureEnabled (activeFeatureService, MON_MODULES) {
        return activeFeatureService.isFeatureActive(MON_MODULES.data_privacy);
    }

    function checkHasAccessToConsentManager (ng2SessionService, $filter, activeFeatureService, MON_MODULES) {
        var hasConsentManagerPlanTrait = ng2SessionService.customer && ng2SessionService.customer.plan_traits.cookie_banner;

        return (
            hasConsentManagerPlanTrait &&
            ($filter('monCustomerHasPlan')('demo') || activeFeatureService.isFeatureActive(MON_MODULES.cookie_banner))
        );
    }

    var redirectInCaseOfLimitedAccessToDataPrivacy = [
        '$state',
        '$q',
        '$stateParams',
        'MON_MODULES',
        '$filter',
        'ng2SessionService',
        'ng2ActiveFeatureService',
        'ng2MonUIRouterTransitionService',
        function (
            $state,
            $q,
            $stateParams,
            MON_MODULES,
            $filter,
            ng2SessionService,
            ng2ActiveFeatureService,
            ng2MonUIRouterTransitionService,
        ) {
            var hasAccessToDataPrivacy = checkUserHasAccessToDataPrivacy($filter, ng2SessionService, MON_MODULES);
            var dataPrivacyFeatureIsEnabled = chackDataPrivacyFeatureEnabled(ng2ActiveFeatureService, MON_MODULES);
            var accessToConsentManager = checkHasAccessToConsentManager(ng2SessionService, $filter, ng2ActiveFeatureService, MON_MODULES);
            if ((!hasAccessToDataPrivacy || !dataPrivacyFeatureIsEnabled) && accessToConsentManager) {
                // TODO: https://optimere.atlassian.net/browse/MS-1011
                // removed the MonUIRouterTransitionService wrapper of this $state transition because it does not work in this case.
                $state.go('base.customer.domain.data_privacy.consent_overview.cookie_acceptance_rate', $stateParams);
                return;
            }

            if (!dataPrivacyFeatureIsEnabled && hasAccessToDataPrivacy) {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.data_privacy_coming_soon', $stateParams),
                );
            }

            if (!hasAccessToDataPrivacy) {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.dashboard', $stateParams),
                );
            }

            return $q.resolve(true);
        },
    ];

    var userHasAccessToDataPrivacy = [
        '$state',
        '$q',
        '$stateParams',
        'MON_MODULES',
        '$filter',
        'ng2SessionService',
        'ng2MonUIRouterTransitionService',
        function ($state, $q, $stateParams, MON_MODULES, $filter, ng2SessionService, ng2MonUIRouterTransitionService) {
            if (checkUserHasAccessToDataPrivacy($filter, ng2SessionService, MON_MODULES)) {
                return $q.resolve(true);
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];

    var dataPrivacyFeatureEnabled = [
        'ng2ActiveFeatureService',
        '$state',
        '$q',
        '$stateParams',
        'MON_MODULES',
        'ng2SessionService',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $state, $q, $stateParams, MON_MODULES, ng2SessionService, ng2MonUIRouterTransitionService) {
            if (chackDataPrivacyFeatureEnabled(ng2ActiveFeatureService, MON_MODULES)) {
                return $q.resolve(true);
            }
            var customer = ng2SessionService.customer;
            if (customer && customer.plan_traits && customer.plan_traits.data_protection) {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.data_privacy_coming_soon', $stateParams),
                );
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];

    var hasAccessToConsentManager = [
        '$state',
        '$q',
        '$stateParams',
        'ng2SessionService',
        '$filter',
        'ng2ActiveFeatureService',
        'MON_MODULES',
        'ng2MonUIRouterTransitionService',
        function (
            $state,
            $q,
            $stateParams,
            ng2SessionService,
            $filter,
            ng2ActiveFeatureService,
            MON_MODULES,
            ng2MonUIRouterTransitionService,
        ) {
            if (checkHasAccessToConsentManager(ng2SessionService, $filter, ng2ActiveFeatureService, MON_MODULES)) {
                return $q.resolve(true);
            }

            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.data_privacy.summary', $stateParams),
            );
        },
    ];

    var isCustomerAdmin = [
        '$state',
        '$q',
        '$stateParams',
        'ng2SessionService',
        'ng2MonUIRouterTransitionService',
        function ($state, $q, $stateParams, ng2SessionService, ng2MonUIRouterTransitionService) {
            var isAdmin = ng2SessionService.hasRole('customer_admin');
            if (isAdmin) {
                return $q.resolve(true);
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.data_privacy.consent_overview', $stateParams),
            );
        },
    ];
})();
