(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormInfoView', {
        templateUrl: 'app/modules/page-fix/forms/info-view/info-view.html',
        controller: PageFixFormInfoViewController,
        controllerAs: 'vm',
        transclude: {
            image: '?imgSlot',
            text: '?txtSlot',
        },
        bindings: {
            monName: '<?',
            monType: '<',
            monSelected: '<?',
            monOrder: '<',
            monTitle: '<?',
            monFooter: '<?',
        },
    });

    PageFixFormInfoViewController.$inject = ['$element', 'ng2MonEventsService', 'PAGE_FIX_EVENT'];
    /* @ngInject */
    function PageFixFormInfoViewController ($element, ng2MonEventsService, PAGE_FIX_EVENT) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;

        function activate () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.REGISTER_VIEW, $element[0], {
                type: vm.monType,
                order: vm.monOrder,
                footer: vm.monFooter,
                title: vm.monTitle,
                viewName: vm.monName,
                selected: vm.monSelected,
            });
        }

        function onDestroy () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.UNREGISTER_VIEW, $element[0]);
        }
    }
})();
