(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyPreContentRuleMissingCompanyName', {
        templateUrl: 'app/forms/global-policy/steps/pre-content/rules/missing-company-name.html',
        controller: PolicyPreContentRuleMissingCompanyNameController,
        controllerAs: 'vm',
        require: {
            contentController: '^formGlobalPolicyPreContent',
        },
    });

    PolicyPreContentRuleMissingCompanyNameController.$inject = ['monDialog', 'Lodash', 'gettextCatalog'];
    /* @ngInject */
    function PolicyPreContentRuleMissingCompanyNameController (monDialog, Lodash, gettextCatalog) {
        var vm = this;

        vm.onModelChange = onModelChange;
        vm.$onInit = activate;

        function activate () {
            vm.ngModel = null;
            vm.type = null;
            vm.letterTypes = [
                {
                    value: null,
                    name: gettextCatalog.getString('Custom'),
                },
                {
                    value: 'lowercase',
                    name: gettextCatalog.getString('lowercase'),
                },
                {
                    value: 'uppercase',
                    name: gettextCatalog.getString('UPPERCASE'),
                },
                {
                    value: 'propercase',
                    name: gettextCatalog.getString('Proper Case'),
                },
            ];
        }

        function onModelChange () {
            if (typeof vm.ngModel === 'string') {
                var scope = {
                    company: vm.ngModel,
                };
                switch (vm.type) {
                    case 'lowercase':
                        scope.company = Lodash.lowerCase(vm.ngModel);
                        break;
                    case 'uppercase':
                        scope.company = Lodash.upperCase(vm.ngModel);
                        break;
                    case 'propercase':
                        scope.company = Lodash.startCase(vm.ngModel);
                        break;
                }
                vm.contentController.ngModel = [
                    {
                        name: gettextCatalog.getString('Check for misspelling company name: {{company}}', scope),
                        case_sensitive: true,
                        inverse: false,
                        operator: 'contains',
                        query: scope.company,
                        type: 'page_text',
                        sub_entry: '',
                    },
                ];
            } else {
                vm.contentController.ngModel = null;
            }
        }
    }
})();
