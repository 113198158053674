(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityGuidelinesWcag21', {
        templateUrl: 'app/pages/accessibility/guidelines/acc-standards/wcag-2-1/wcag-2-1.html',
        controller: AccessibilityGuidelinesAccWCAG21Controller,
        controllerAs: 'vm',
        bindings: {
            guidelines: '<',
            refreshPage: '&',
            progress: '<',
        },
    });

    AccessibilityGuidelinesAccWCAG21Controller.$inject = [];

    function AccessibilityGuidelinesAccWCAG21Controller () {}
})();
