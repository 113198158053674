import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MonTranslationService } from '@monsido/core/translation/translation.service';
import { TranslateModule } from '@client/app/modules/translate.module';

function init_app (appLoadService: MonTranslationService) {
    return (): Promise<unknown> => appLoadService.initializeApp();
}

@NgModule({
    imports: [TranslateModule],
    exports: [TranslateModule],
    declarations: [],
    providers: [
        MonTranslationService,
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [MonTranslationService],
            multi: true,
        },
    ],
})
export class MonTranslationModule {}
