type BrokenLinksFiltersType = {
    labels: string[],
    type: string[],
    'response code': string[],
    'internal or external': string[]
}
export class BrokenLinksUtil {
    static updateLinksFilters (
        filters: BrokenLinksFiltersType,
        changes: BrokenLinksFiltersType,
    ): BrokenLinksFiltersType {
        if (changes.type.length === 1) {
            filters.type = changes.type;
        } else if (changes.type.length > 1) {
            filters.type.splice(0, 1);
        } else {
            filters.type = [];
        }
        if (changes['response code'].length === 1) {
            filters['response code'] = changes['response code'];
        } else if (changes['response code'].length > 1) {
            filters['response code'].splice(0, 1);
        } else {
            filters['response code'] = [];
        }
        if (changes['internal or external'].length === 1) {
            filters['internal or external'] = changes['internal or external'];
        } else if (changes['internal or external'].length > 1) {
            filters['internal or external'].splice(0, 1);
        } else {
            filters['internal or external'] = [];
        }

        return filters;
    }
}
