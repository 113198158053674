(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc service
     * @name PerformanceDomainHistoryLineChartService
     * @description
     * Create the necessary objects to shape the performance domain history line chart
     */

    angular.module('blocks.service').service('PerformanceDomainHistoryLineChartService', PerformanceDomainHistoryLineChartService);

    PerformanceDomainHistoryLineChartService.$inject = [
        '$filter',
        'gettextCatalog',
        'LineChart',
        'ChartsDataHelper',
        'ng2DateTimeService',
        'MONSIDO_COLOR',
        'PagePerformanceVersionService',
        '$deepmerge',
        'ng2LegendCursorService',
    ];

    function PerformanceDomainHistoryLineChartService (
        $filter,
        gettextCatalog,
        LineChart,
        ChartsDataHelper,
        ng2DateTimeService,
        MONSIDO_COLOR,
        PagePerformanceVersionService,
        $deepmerge,
        ng2LegendCursorService,
    ) {
        const vm = this;
        const PERFORMANCE_HISTORY_SERIES = {
            AVERAGE_PERFORMANCE_SCORE: {
                label: gettextCatalog.getString('Average Performance Score'),
                key: 'performance_score_average',
                percentageValue: true,
                yAxis: 'y-axis-0',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['secondary-9'],
                },
                unit: '',
            },
            AVERAGE_FMP: {
                label: gettextCatalog.getString('Average First Meaningful Paint'),
                key: 'performance_first_meaningful_paint_average',
                percentageValue: false,
                yAxis: 'y-axis-1',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['secondary-17'],
                },
                unit: 'ms',
            },
            AVERAGE_FCP: {
                label: gettextCatalog.getString('Average First Contentful Paint'),
                key: 'performance_first_contentful_paint_average',
                percentageValue: false,
                yAxis: 'y-axis-1',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['secondary-13'],
                },
                unit: 'ms',
            },
            AVERAGE_LCP: {
                label: gettextCatalog.getString('Average Largest Contentful Paint'),
                key: 'performance_largest_contentful_paint_average',
                percentageValue: false,
                yAxis: 'y-axis-1',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['secondary-12'],
                },
                unit: 'ms',
            },
            AVERAGE_TTI: {
                label: gettextCatalog.getString('Average Time to Interactive'),
                key: 'performance_time_to_interactive_average',
                percentageValue: false,
                yAxis: 'y-axis-1',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['branding-1'],
                },
                unit: 'ms',
            },
            AVERAGE_SI: {
                label: gettextCatalog.getString('Average Speed Index'),
                key: 'performance_speed_index_average',
                percentageValue: false,
                yAxis: 'y-axis-1',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['secondary-16'],
                },
                unit: 'ms',
            },
            AVERAGE_TBT: {
                label: gettextCatalog.getString('Average Total Blocking Time'),
                key: 'performance_total_blocking_time_average',
                percentageValue: false,
                yAxis: 'y-axis-1',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['branding-4'],
                },
                unit: 'ms',
            },
            AVERAGE_CLS: {
                label: gettextCatalog.getString('Average Cumulative Layout Shift'),
                key: 'performance_cumulative_layout_shift_average',
                percentageValue: false,
                yAxis: 'y-axis-3',
                style: {
                    fill: false,
                    borderDash: [],
                    color: MONSIDO_COLOR['secondary-11'],
                },
                unit: 'decimals',
            },
            PERFORMANCE_PAGES: {
                label: gettextCatalog.getString('Performance Pages'),
                key: 'performance_page_count',
                percentageValue: false,
                yAxis: 'y-axis-2',
                style: {
                    fill: true,
                    borderDash: [5, 5],
                    color: '#ff9d4e',
                },
                unit: 'pages',
            },
        };
        const PERFORMANCE_HISTORY_SERIES_KEY_METRIC_MAP = {
            performance_first_contentful_paint_average: 'AVERAGE_FCP',
            performance_first_meaningful_paint_average: 'AVERAGE_FMP',
            performance_largest_contentful_paint_average: 'AVERAGE_LCP',
            performance_time_to_interactive_average: 'AVERAGE_TTI',
            performance_speed_index_average: 'AVERAGE_SI',
            performance_total_blocking_time_average: 'AVERAGE_TBT',
            performance_cumulative_layout_shift_average: 'AVERAGE_CLS',
        };
        /**
         * @memberOf PerformanceDomainHistoryLineChartService
         * @description Initialize the PerformanceDomainHistoryLineChartService factory
         * @return {ObjectgettextCatalog}
         */
        function init () {
            vm.lineChartSettings = new LineChart();
            vm.metrics = [];
            vm.versions = [];
            vm.seriesByVersion = {};
            vm.metrics = [];
            vm.metricsIds = {};
            vm.series = [];
            return {
                getOption: getOption,
                getColors: getColors,
                getSeries: getSeries,
                getOverride: getOverride,
                getLabels: getLabels,
                getData: getData,
            };
        }

        return init;

        /**
         * @memberOf PerformanceDomainHistoryLineChartService
         * @description The option object for the performance domain history line chart
         * @return {Object}
         */
        function getOption (history) {
            setSeriesData(history);

            vm.lineChartSettings.setTooltipSettings({
                intersect: false,
                mode: 'x-axis',
                callbacks: {
                    label: function (tooltipItem, data) {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const value = dataset.data[tooltipItem.index].y;
                        const scope = {
                            label: dataset.label,
                            value: $filter('monNumber')(value, '0,0'),
                        };

                        const key = vm.series[tooltipItem.datasetIndex];
                        const series = PERFORMANCE_HISTORY_SERIES[key];
                        // Milliseconds
                        if (series.unit === 'ms') {
                            return gettextCatalog.getString('{{label}}: {{value}}ms', scope);
                            // Pages
                        } else if (series.unit === 'pages') {
                            return gettextCatalog.getString('{{label}}: {{value}} Pages', scope);
                        } else if (series.unit === 'decimals') {
                            return gettextCatalog.getString('{{label}}: {{value}}', {
                                label: dataset.label,
                                value: $filter('monNumber')(value, '0.00'),
                            });
                        }
                        return gettextCatalog.getString('{{label}}: {{value}}', scope);
                    },
                    title: function (tooltipItem) {
                        return ng2DateTimeService.format(tooltipItem[0].xLabel, 'MMM DD');
                    },
                },
            });

            vm.lineChartSettings.setLegendSettingsOverride({
                display: true,
                position: 'bottom',
                labels: {
                    fontSize: 11,
                    boxWidth: 10,
                },
            });

            vm.lineChartSettings.setScalesSettings({
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            max: 100,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('monNumber')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Score'),
                            fontSize: 11,
                        },
                    },
                    {
                        id: 'y-axis-1',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('monNumber')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Milliseconds'),
                            fontSize: 11,
                        },
                    },
                    {
                        id: 'y-axis-2',
                        display: false,
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            display: false,
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('monNumber')(value, '0,0');
                            },
                        },
                        gridLines: {
                            display: false,
                        },
                        scaleLabel: {
                            display: false,
                        },
                    },
                    {
                        id: 'y-axis-3',
                        display: false,
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            display: false,
                            fontSize: 11,
                            min: 0,
                            max: 1,
                            beginAtZero: true,
                        },
                        gridLines: {
                            display: false,
                        },
                        scaleLabel: {
                            display: false,
                        },
                    },
                ],
                xAxes: [
                    {
                        id: 'x-axis-0',
                        type: 'time',
                        gridLines: {
                            offsetGridLines: true,
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            fontSize: 11,
                            maxTicksLimit: 30,
                        },
                        time: {
                            minUnit: 'day',
                            displayFormats: {
                                // Since the scans always only appear once or twice a week, there's no need for any other date formats to be displayed
                                millisecond: 'MMM D',
                                second: 'MMM D',
                                minute: 'MMM D',
                                hour: 'MMM D',
                                day: 'MMM D',
                                week: 'MMM D',
                                month: 'MMM D',
                                quarter: 'MMM D',
                                year: 'MMM D',
                            },
                        },
                    },
                ],
            });

            const entriesWithVersionChanged = PagePerformanceVersionService.getEntriesWithVersionChanged(history, 'performance_version');

            if (entriesWithVersionChanged.length > 0) {
                const lines = [];
                entriesWithVersionChanged.forEach(function (entry, index) {
                    const previousEntryWithVersionChanged = entriesWithVersionChanged[index - 1];
                    if (previousEntryWithVersionChanged) {
                        const previousVersionIsTransitionVersion = previousEntryWithVersionChanged.performance_version.indexOf('-') > -1;
                        if (previousVersionIsTransitionVersion) {
                            const transitionVersions = previousEntryWithVersionChanged.performance_version.split('-');
                            if (transitionVersions[1] === entry.performance_version) {
                                // If the previous version changed is "v1-v2" and the current is "v2", we don't display the
                                // v2 update indicator
                                return;
                            }
                        }
                    }
                    const versionInfo = PagePerformanceVersionService.getVersionInfo(entry.performance_version);
                    lines.push({
                        xAxisID: 'x-axis-0',
                        x: entry.date,
                        style: {
                            lineDash: [5, 5],
                            color: MONSIDO_COLOR['secondary-15'],
                            width: 3,
                        },
                        label: {
                            text: versionInfo.label,
                            color: MONSIDO_COLOR['secondary-15'],
                            position: 'top',
                        },
                        data: {
                            link: versionInfo.link,
                        },
                    });
                });

                vm.lineChartSettings.options.verticalLines = {
                    lines: lines,
                };
            }
            vm.lineChartSettings.options = ng2LegendCursorService.setHoverState(vm.lineChartSettings.options);
            return vm.lineChartSettings.options;
        }

        /**
         * @memberOf PerformanceDomainHistoryLineChartService
         * @description The colors for the performance domain history line chart
         * @return {Array<String>}
         */
        function getColors (history) {
            setSeriesData(history);
            const colors = vm.series.map(function (key) {
                return PERFORMANCE_HISTORY_SERIES[key].style.color;
            });
            vm.lineChartSettings.addColors(colors);
            return vm.lineChartSettings.colors;
        }

        /**
         * @memberOf PerformanceDomainHistoryLineChartService
         * @description The series for the performance domain history line chart
         * @return {Array<String>}
         */
        function getSeries (crawls) {
            setSeriesData(crawls);
            const labels = [];
            for (let i = 0; i < vm.series.length; i++) {
                const series = vm.series[i];
                labels.push(PERFORMANCE_HISTORY_SERIES[series].label);
            }
            vm.lineChartSettings.addSeries(labels);
            return vm.lineChartSettings.series;
        }

        /**
         * @memberOf PerformanceDomainHistoryLineChartService
         * @description The override object for the performance domain history line chart
         * @return {Array<Object>}
         */
        function getOverride (crawls) {
            setSeriesData(crawls);
            const overrides = vm.series.map(function (key) {
                const series = PERFORMANCE_HISTORY_SERIES[key];
                return {
                    xAxisID: 'x-axis-0',
                    yAxisID: series.yAxis,
                    type: 'line',
                    fill: series.style.fill,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                    borderDash: series.style.borderDash,
                };
            });
            vm.lineChartSettings.addOverrides(overrides);
            return vm.lineChartSettings.override;
        }

        /**
         * @memberOf PerformanceDomainHistoryLineChartService
         * @description The labels for the performance domain history line chart
         * @return {Array<any>}
         */
        function getLabels (crawls) {
            crawls = crawls || [];
            setSeriesData(crawls);
            return crawls.map(function (crawl) {
                return new Date(crawl.date).getTime();
            });
        }

        /**
         * @memberOf PerformanceDomainHistoryLineChartService
         * @description The data object for the performance domain history line chart
         * @return {Array<Array>}
         */
        function getData (crawls) {
            if (!Array.isArray(crawls) || crawls.length === 0) {
                return [];
            }
            setSeriesData(crawls);
            const helper = new ChartsDataHelper('time');

            return vm.series.map(function (key) {
                const series = PERFORMANCE_HISTORY_SERIES[key];
                return crawls.map(function (crawl) {
                    if (series.percentageValue) {
                        return getScoreValue(helper.setData(crawl, series.key));
                    } else {
                        return helper.setData(crawl, series.key);
                    }
                });
            });
        }

        function getScoreValue (data) {
            data.y *= 100;
            return data;
        }

        function setSeriesData (history) {
            if (history.length === 0 || !history[0].performance_version) {
                return;
            }
            const seriesByVersion = PagePerformanceVersionService.getSeriesByVersion($deepmerge([], history), 'performance_version');
            vm.versions = seriesByVersion.versions;
            vm.seriesByVersion = seriesByVersion.series;

            if (vm.versions.length === 1) {
                vm.metrics = vm.versions[0].avgMetrics;
            } else {
                vm.metrics = [];
                vm.metricsIds = {};
                for (let i = 0; i < vm.versions.length; i++) {
                    const versionMetrics = vm.versions[i]?.avgMetrics;
                    if (versionMetrics) {
                        for (let j = 0; j < versionMetrics.length; j++) {
                            const versionMetric = versionMetrics[j];
                            if (!vm.metricsIds[versionMetric.id]) {
                                vm.metrics.push(versionMetric);
                                vm.metricsIds[versionMetric.id] = versionMetric;
                            }
                        }
                    }
                }
            }

            vm.series = ['AVERAGE_PERFORMANCE_SCORE'];
            vm.metrics.forEach(function (metric) {
                vm.series.push(PERFORMANCE_HISTORY_SERIES_KEY_METRIC_MAP[metric.id]);
            });
            vm.series.push('PERFORMANCE_PAGES');
        }
    }
})();
