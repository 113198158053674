(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormMarquee', {
        templateUrl: 'app/modules/page-fix/forms/marquee/marquee.html',
        controller: PageFixFormBlinkController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    PageFixFormBlinkController.$inject = ['ng2MonEventsService', 'PAGE_FIX_EVENT', 'PAGE_FIX_FORM_VIEW_NAME', 'PAGE_FIX_FORM_VIEW_TYPE'];
    /* @ngInject */
    function PageFixFormBlinkController (ng2MonEventsService, PAGE_FIX_EVENT, PAGE_FIX_FORM_VIEW_NAME, PAGE_FIX_FORM_VIEW_TYPE) {
        var customValidationId;
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.closeModal = closeModal;

        function activate () {
            vm.goToViewId = 0;
            vm.updatePageFixId = 0;
            vm.pageFix = vm.resolve.pageFix;
            vm.pageFixId = vm.resolve.typeId || vm.resolve.model.id;
            vm.pageFixSelector = JSON.stringify(['marquee']);
            vm.infoViewType = PAGE_FIX_FORM_VIEW_TYPE.INFO;
            vm.optionsViewType = PAGE_FIX_FORM_VIEW_TYPE.OPTIONS;
            vm.testingViewType = PAGE_FIX_FORM_VIEW_TYPE.TESTING;
            vm.customValidation = true;

            setupListeners();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.goToViewId);
            ng2MonEventsService.remove(vm.updatePageFixId);
            ng2MonEventsService.remove(customValidationId);
        }

        function setupListeners () {
            vm.goToViewId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.FORM_REQUEST_GO_TO_VIEW, function () {
                setView(PAGE_FIX_FORM_VIEW_NAME.OPTIONS);
            });

            vm.updatePageFixId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.UPDATE_PAGEIFX, function (pageFix) {
                vm.pageFix = pageFix;
            });

            customValidationId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.CUSTOM_VALIDATION, function (isValid) {
                vm.customValidation = isValid;
            });
        }

        function setView (viewName) {
            ng2MonEventsService.run(PAGE_FIX_EVENT.FORM_UPDATE_VIEW, viewName);
        }

        function closeModal (pageFix) {
            vm.close({ $value: pageFix });
        }
    }
})();
