import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FastTrackSessionService {
    snippetComplianceProperty = '';
    obsSnippetComplianceProperty = new BehaviorSubject('');
    snippetSourceCodeProperty = '';
    obsSnippetSourceCodeProperty = new BehaviorSubject('');
    snippetIssuesProperty = '';
    obsSnippetIssuesProperty = new BehaviorSubject('');

    clear (): void {
        this.snippetComplianceProperty = '';
        this.obsSnippetComplianceProperty.next('');
        this.snippetSourceCodeProperty = '';
        this.obsSnippetSourceCodeProperty.next('');
        this.snippetIssuesProperty = '';
        this.obsSnippetIssuesProperty.next('');
    }

    setSnippetComplianceProperty (property: string): void {
        this.snippetComplianceProperty = property;
        this.obsSnippetComplianceProperty.next(property);
    }

    setSnippetSourceCodeProperty (property: string): void {
        this.snippetSourceCodeProperty = property;
        this.obsSnippetSourceCodeProperty.next(property);
    }

    setSnippetIssuesProperty (property: string): void {
        this.snippetIssuesProperty = property;
        this.obsSnippetIssuesProperty.next(property);
    }
}
