(function () {
    'use strict';

    angular.module('app.components.documentDetails').component('documentDetailsSectionAccessibilityWcag20Table', {
        templateUrl: 'app/components/documentdetails/details/accessibility/wcag20-table/wcag20-table.html',
        controller: AccessibilityWcag20TableController,
        controllerAs: 'vm',
        bindings: {
            checks: '<',
            selectCheck: '&',
            activeCheck: '<',
            progress: '<',
            document: '<',
        },
    });

    AccessibilityWcag20TableController.$inject = ['AccessibilityClarityService'];
    /* @ngInject */
    function AccessibilityWcag20TableController (AccessibilityClarityService) {
        var vm = this;
        vm.getIconContainer = getIconContainer;
        vm.getIcon = getIcon;

        function getIconContainer (check) {
            return AccessibilityClarityService.getDocumentIconContainer(vm.document, check);
        }

        function getIcon (check) {
            return AccessibilityClarityService.getDocumentIcon(vm.document, check);
        }
    }
})();
