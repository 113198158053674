(function () {
    'use strict';

    angular.module('pages.inventory').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog', 'RouteHelperService'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog, RouteHelperService) {
        routerHelper.configureStates(getStates());
        function getStates () {
            return [
                RouteHelperService.makeRouteWithHasAccess({
                    name1: 'domain',
                    name2: 'inventory',
                    url: '/inventory',
                    featureName: 'inventory_all',
                    redirectURI: 'base.customer.domain.dashboard',
                    abstract: true,
                    sidebar: 'inventory',
                }),
                ...[
                    ['dashboard', '/', gettextCatalog.getString('Acquia Optimize - Inventory')],
                    ['email', '/emails', gettextCatalog.getString('Acquia Optimize - Email addresses')],
                ]
                    .map((type) => RouteHelperService.makeRoute({
                        name1: 'domain',
                        name2: `inventory.${type[0]}`,
                        template: `<inventory-${type[0]}></inventory-${type[0]}>`,
                        url: `${type[1]}`,
                        title: type[2],
                    })),
            ];
        }
    }
})();
