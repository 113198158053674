import { Injectable } from '@angular/core';
import { BaseRepo } from '@client/app/services/api/base-repo/base-repo';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { CookieEntity } from '@client/app/modules/cookie-banner/interfaces/cookie.interface';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';

@Injectable({
    providedIn: 'root',
})
export class CookiesRepoService extends BaseRepo {
    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
    ) {
        super();
    }

    async getCookiesFromCentralDb (): Promise<MonTableCollection<CookieEntity>> {
        let receivedCookies: MonTableCollection<CookieEntity> = [];
        let cookies: MonTableCollection<CookieEntity> = [];
        let pageIndex = 1;
        const pageSize = 1000;

        do {
            const getCookiesResult = await this.apiService
                .supportClientGet<MonTableCollection<CookieEntity>>(`cookies?page=${pageIndex}&page_size=${pageSize}`);
            cookies = getCookiesResult || [];
            receivedCookies = [...receivedCookies, ...cookies];
            ++pageIndex;
        } while (cookies.length > 0);
        return receivedCookies;
    }

    async getAll ({ domain_id: domainId }: { domain_id: number}): Promise<MonTableCollection<CookieEntity>> {
        return this.apiService.get<MonTableCollection<CookieEntity>>(`domains/${domainId}/cookie_banner/cookies`);
    }

    async update (domainId: number, cookie: Partial<CookieEntity>): Promise<CookieEntity> {
        return this.apiService.patchAndSkipRedirectOn403(`domains/${domainId}/cookie_banner/cookies/${cookie.id}`, cookie);
    }

    async create (domainId: number, params: Partial<CookieEntity>): Promise<CookieEntity> {
        return this.apiService.postAndSkipRedirectOn403(`domains/${domainId}/cookie_banner/cookies`, params);
    }

    async destroy (domainId: number, id: number): Promise<void> {
        return this.apiService.delete(`domains/${domainId}/cookie_banner/cookies/${id}`);
    }
}
