import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@monsido/modules/models/api/user';

@Pipe({
    name: 'getUserName',
})
export class GetUserNamePipe implements PipeTransform {

    transform (value: User): string {
        return typeof value.getName === 'function' ? value.getName() : `${value.first_name} ${value.last_name}`;
    }

}
