import { EventsType } from '@monsido/services/mon-event/events.type';

export const MON_EVENTS: Record<string, EventsType> = {
    LOAD_NG2_DIALOG: 'loadNg2Dialog',
    LOAD_DIALOG: 'loadDialog',
    RELOAD_ACCESSIBILITY_ISSUES: 'reloadAccessibilityIssues',
    CLOSE_ALL_DIALOGS: 'closeALLDialogs',
    OPEN_EXTENSION_DEEPLINK: 'openExtensionDeeplink',
    RELOAD_QA_BROKEN_IMAGES: 'reloadQaBrokenImages',
    RELOAD_MISSPELLING: 'reloadMisspelling',
    RELOAD_QA_BROKEN_LINKS: 'reloadQaBrokenLinks',
    RELOAD_DATA_PRIVACY: 'reloadDataPrivacy',
    CLOSE_ALL_OVERLAY_DIALOGS: 'closeALLOverlayDialogs',
    RELOAD_QA_BROKEN_PAGE_DETAILS: 'reloadQABrokenPageDetails',
};
