(function () {
    'use strict';

    angular.module('modules.page_fix.components').component('pageFixChangesetSpelling', {
        templateUrl: 'app/modules/page-fix/components/changeset/spelling/spelling.html',
        controller: AutofixChangesetSpellingController,
        controllerAs: 'vm',
        bindings: {
            data: '<',
        },
    });

    AutofixChangesetSpellingController.$inject = [];
    /* @ngInject */
    function AutofixChangesetSpellingController () {}
})();
