(function () {
    'use strict';

    angular.module('base.product-updates').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.product-updates',
                config: {
                    url: '/product-updates',
                    abstract: true,
                    template: '<div ui-view></div>',
                },
            },
            {
                state: 'base.product-updates.index',
                config: {
                    url: '',
                    template: '<product-updates></product-updates>',
                    data: {},
                },
            },
            {
                state: 'base.product-updates.show',
                config: {
                    url: '/{productUpdateId}?state',
                    template: '<product-update></product-update>',
                    data: {},
                    resolve: {
                        validateParam: [
                            '$stateParams',
                            '$q',
                            '$state',
                            'ng2MonUIRouterTransitionService',
                            function ($stateParams, $q, $state, ng2MonUIRouterTransitionService) {
                                if (isNaN(parseInt($stateParams.productUpdateId))) {
                                    return ng2MonUIRouterTransitionService.onTransitionFinished(
                                        () => $state.go('base.product-updates.index', $stateParams),
                                    );
                                } else {
                                    return $q.resolve();
                                }
                            },
                        ],
                    },
                },
            },
        ];
    }
})();
