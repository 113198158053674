(function () {
    'use strict';
    angular.module('app.components').component('monChartDashboard', {
        templateUrl: 'app/components/mon-chart-dashboard/mon-chart-dashboard.html',
        controller: ChartDashboardController,
        controllerAs: 'vm',
        bindings: {
            progress: '<?',
            doughnutLabels: '<',
            doughnutData: '<',
            doughnutOptions: '<?',
            doughnutColors: '<?',

            lineLabels: '<',
            lineData: '<',
            lineOptions: '<?',
            lineSeries: '<',
            lineColors: '<?',
            lineOverride: '<?',

            cards: '<?',

            verticalData: '<?',
        },
    });

    ChartDashboardController.$inject = [];

    function ChartDashboardController () {
        var vm = this;
        vm.$onInit = activate;
        vm.isNumber = isNumber;

        function activate () {
            vm.cards = vm.cards || [];
        }

        function isNumber (val) {
            return typeof val === 'number';
        }
    }
})();
