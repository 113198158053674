(function () {
    'use strict';

    angular.module('modules.accessibility').service('AccessibilityFiltersService', AccessibilityFiltersService);

    AccessibilityFiltersService.$inject = ['gettextCatalog', 'ng2ActiveFeatureService', 'ng2SessionService'];
    /* @ngInject*/
    function AccessibilityFiltersService (gettextCatalog, ng2ActiveFeatureService, ng2SessionService) {
        return {
            getReponsibilityTranslation: getReponsibilityTranslation,
            getTypes: getTypes,
            getResponsibilities: getResponsibilities,
            canUseMobileGuidelines: canUseMobileGuidelines,
        };

        function getReponsibilityTranslation () {
            return gettextCatalog.getString('Responsibility');
        }

        function getTypes () {
            var types = [
                {
                    name: gettextCatalog.getString('Error'),
                    value: 'error',
                },
                {
                    name: gettextCatalog.getString('Warning'),
                    value: 'warning',
                },
                {
                    name: gettextCatalog.getString('Review'),
                    value: 'review',
                },
            ];
            if (canUseMobileGuidelines()) {
                types.push({
                    name: gettextCatalog.getString('Mobile only'),
                    value: 'mobile',
                });
            }

            return types;
        }

        function getResponsibilities () {
            return [
                {
                    name: gettextCatalog.getString('Visual Design'),
                    value: 'Visual Design',
                },
                {
                    name: gettextCatalog.getString('UX Design'),
                    value: 'UX Design',
                },
                {
                    name: gettextCatalog.getString('Content Authoring'),
                    value: 'Content Authoring',
                },
                {
                    name: gettextCatalog.getString('Front-end Development'),
                    value: 'Front-end Development',
                },
            ];
        }

        function canUseMobileGuidelines () {
            return (ng2ActiveFeatureService.isFeatureActive('mobile_guidelines') &&
                    ng2SessionService.domain &&
                    ng2SessionService.domain.features &&
                    typeof ng2SessionService.domain.features.accessibility === 'string' &&
                    ng2SessionService.domain.features.accessibility.includes('mobile'));
        }
    }
})();
