(function () {
    'use strict';
    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name AccessibilityClarityService
     * @description Manage Accessibility Clarity PDF Information
     */
    angular.module('blocks.service').factory('AccessibilityClarityService', AccessibilityClarityService);

    AccessibilityClarityService.$inject = [];
    /* @ngInject*/
    function AccessibilityClarityService () {
        return {
            getDocumentIconContainer: getDocumentIconContainer,
            getDocumentIcon: getDocumentIcon,
        };

        // ///////////////

        /**
         * @memberOf AccessibilityClarityService
         * @desc Get Accessibility check icon container for a document
         * @param {object} document
         * @param {object} check
         * @return {string}
         */
        function getDocumentIconContainer (document, check) {
            if (!isObject(document) || !isObject(check)) {
                return;
            }
            if (
                document.clarity_queued_at !== null ||
                (document.clarity_queued_at === null && document.clarity_checked_at === null) ||
                document.clarity_tagged === false
            ) {
                return 'fal fa-circle text-grey';
            } else if (check.errors_count > 0) {
                return 'fal fa-circle text-wcag-a';
            } else if (check.errors_count === 0) {
                return 'fas fa-circle text-green';
            }
        }

        /**
         * @memberOf AccessibilityClarityService
         * @desc Get Accessibility check icon for a document
         * @param {object} document
         * @param {object} check
         * @return {string}
         */
        function getDocumentIcon (document, check) {
            if (!isObject(document) || !isObject(check)) {
                return;
            }
            if (
                document.clarity_queued_at !== null ||
                (document.clarity_queued_at === null && document.clarity_checked_at === null) ||
                document.clarity_tagged === false
            ) {
                return 'fas fa-file-alt text-grey';
            } else if (check.errors_count > 0) {
                return 'fas fa-file-alt text-wcag-a';
            } else if (check.errors_count === 0) {
                return 'fas fa-check fa-inverse';
            }
        }

        /**
         * @memberOf AccessibilityClarityService
         * @desc Check if value is a of type function
         * @param {Function} val
         * @return {boolean}
         */
        function isObject (val) {
            return val !== null && !Array.isArray(val) && typeof val === 'object';
        }
    }
})();
