import { Injectable } from '@angular/core';

type RgbType = {
    red: number;
    green: number;
    blue: number;
};

@Injectable()
export class ColorHelperService {

    ratioCheck (ratio: number, num: number): boolean {
        return ratio > num;
    }

    hexToRgb (hex: string): RgbType {
        // If # is input, then it is removed
        hex = hex.replace('#', '');

        return {
            red: parseInt(hex.substring(0, 2), 16),
            green: parseInt(hex.substring(2, 4), 16),
            blue: parseInt(hex.substring(4), 16),
        };
    }

    rgbToHex (rgb: RgbType): string {
        let hex = '';
        Object.keys(rgb).forEach(function (key) {
            let color = rgb[key].toString(16);
            if (color.length < 2) {
                color = 0 + color;
            }
            hex += color;
        });
        return '#' + hex;
    }

    contrast (foregroundColor: RgbType, backgroundColor: RgbType): number | null {
        // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef

        if (!foregroundColor || !backgroundColor) {
            return null;
        }

        const l1 = this.luminance(foregroundColor) + 0.05;
        const l2 = this.luminance(backgroundColor) + 0.05;
        let ratio: number = l1 / l2;

        if (l2 > l1) {
            ratio = 1 / ratio;
        }

        ratio = Number(ratio.toFixed(2));

        return isNaN(ratio) ? null : ratio;
    }

    hexContrast (foregroundColor: string, backgroundColor: string): number | null {
        return this.contrast(this.hexToRgb(foregroundColor), this.hexToRgb(backgroundColor));
    }

    isHEXCodeValid (hexCode: string): boolean {
        return /^#(?:[\da-fA-F]{3}){1,2}$/.test(hexCode);
    }

    private luminance (rgbInput: RgbType): number {
        // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef

        const rgb: number[] = [];
        let i = 0;

        Object.values(rgbInput).forEach((channel) => {
            channel /= 255;

            channel = channel < 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);

            rgb[i++] = channel;
        });

        return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
    }
}
