(function () {
    'use strict';

    angular.module('pages.dashboard').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.dashboard',
                config: {
                    url: '/',
                    template: '<customer_picker></customer_picker>',
                    data: { anonymous_access: false },
                },
            },
        ];
    }
})();
