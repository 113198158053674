(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceProfileDropdown', {
        templateUrl: 'app/modules/page-performance/components/profile-dropdown/profile-dropdown.html',
        controller: PagePerformanceProfileDropdownController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
        },
    });
    PagePerformanceProfileDropdownController.$inject = [
        'ng2MonEventsService',
        '$q',
        'PagePerformanceAssignedProfile',
        'Lodash',
        'ng2SessionService',
        'PagePerformanceProfileObsService',
    ];

    function PagePerformanceProfileDropdownController (
        ng2MonEventsService,
        $q,
        PagePerformanceAssignedProfile,
        Lodash,
        ng2SessionService,
        PagePerformanceProfileObsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.onChangeProfile = onChangeProfile;

        function activate () {
            vm.profileId = null;
            vm.accountProfiles = [];
            vm.profiles = [];
            vm.assignedProfiles = [];
            setupAssignedProfiles();
            setupListeners();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.refreshProfilesId);
        }

        function onChangeProfile (newValue) {
            if (newValue === null || typeof newValue !== 'object' || newValue.id === vm.profileId) {
                return;
            }
            runSelectProfileEvent(newValue);
        }

        // PROTECTED

        function setupListeners () {
            PagePerformanceProfileObsService.profilesObs.subscribe(function (profiles) {
                vm.accountProfiles = profiles;
            });
            vm.refreshProfilesId = ng2MonEventsService.addListener('pagePerformanceRefreshProfiles', setupAssignedProfiles);
        }

        function runSelectProfileEvent (profile) {
            ng2MonEventsService.run('pagePerformanceSelectProfile', profile);
        }

        function setupAssignedProfiles () {
            // Get Assigned Profiles and Profiles sequentially
            ng2MonEventsService.run('pagePerformanceBeforeSelectProfile');
            var chain = $q.when();
            chain = chain.then(getAssignedProfiles, angular.noop);
            chain
                .then(function () {
                    // Merge assigned profile into each profile
                    vm.profiles = getProfilesForPage();
                    vm.profiles.forEach(function (profile) {
                        profile.assignedProfile = vm.assignedProfiles.find(function (assignedProfile) {
                            return vm.page.id === assignedProfile.page.id && assignedProfile.profile_ids.indexOf(profile.id) > -1;
                        });
                    });
                    var profile = Lodash.find(vm.profiles, function (profile) {
                        return profile.assignedProfile.page.id === vm.page.id && profile.assignedProfile.default_profile_id === profile.id;
                    });
                    if (profile) {
                        vm.profileId = profile.id;
                    } else if (vm.profiles.length > 0) {
                        vm.profileId = vm.profiles[0].id;
                    }

                    if (vm.profileId > 0) {
                        runSelectProfileEvent(profile || vm.profiles[0]);
                    }
                })
                .finally(function () {
                    ng2MonEventsService.run('pagePerformanceAfterSelectProfile');
                });
        }

        function getProfilesForPage () {
            var profileIds = Lodash.flatMap(vm.assignedProfiles, 'profile_ids');
            return Lodash.filter(vm.accountProfiles, function (profile) {
                return profileIds.indexOf(profile.id) > -1;
            });
        }

        function getAssignedProfiles () {
            var params = {
                page_id: vm.page.id,
                domain_id: ng2SessionService.getDomainId(),
            };
            return PagePerformanceAssignedProfile.getAll(params).then(function (assignedProfiles) {
                vm.assignedProfiles = assignedProfiles;
            }, angular.noop);
        }
    }
})();
