export const COOKIE_BANNER_SETTINGS = {
    DIRECTION: {
        TOPLEFT: 'top-left',
        TOPRIGHT: 'top-right',
        LEFT: 'left',
        RIGHT: 'right',
        TOPCENTER: 'top-center',
        BOTTOMCENTER: 'bottom-center',
        BOTTOMLEFT: 'bottom-left',
        BOTTOMRIGHT: 'bottom-right',
    },
    BUTTON_TEXT_COLOR: '#ffffff',
    BUTTON_COLOR: '#783CE2',
    MAIN_COLOR: '#783CE2',
    PRIVACY_REGULATIONS: {
        CCPA: 'ccpa',
        GDPR: 'gdpr',
    },
    ICON: {
        COOKIE: 'cookie',
        COOKIE_BITE: 'cookie_bite',
        BADGE: 'badge',
        STROOPWAFEL: 'stroopwafel',
    },
} as const;
