import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('modules.notifications').component('notificationsIcon', {
        templateUrl: 'app/modules/notifications/icon/icon.html',
        controller: NotificationIconController,
        controllerAs: 'vm',
        bindings: {},
    });

    NotificationIconController.$inject = [
        '$localStorage',
        'ng2MonNotificationsService',
        'ng2MonEventsService',
        'notificationRedirectService',
        'NOTIFICATION_CONSTANT',
    ];

    function NotificationIconController (
        $localStorage,
        ng2MonNotificationsService,
        ng2MonEventsService,
        notificationRedirectService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        NOTIFICATION_CONSTANT,
    ) {
        var vm = this;
        var firstLoad = true;
        var restoredNotSeen = $localStorage.MonsidoNotificationsNotSeen || [];
        var newNotificationsId = [];

        vm.toggle = toggle;
        vm.redirect = redirect;
        vm.allNotifications = allNotifications;
        vm.notSeenYet = notSeenYet;
        vm.$onInit = activate;

        function activate () {
            vm.newDoneCount = 0;
            vm.notifications = [];
            vm.open = false;
            vm.spinner = false;

            ng2MonNotificationsService.notificationsFirst.subscribe((notifications) => {
                if (Array.isArray(notifications)) {
                    notifications.forEach(function (incomingNotification) {
                        var incomingId = incomingNotification.id;
                        if (restoredNotSeen.indexOf(incomingId) > -1) {
                            pushToNewNotificationsId(incomingId);
                        }
                    });
                }
            });

            ng2MonNotificationsService.notifications.subscribe((notifications) => {
                if (Array.isArray(notifications)) {
                    var len = vm.notifications.length;
                    notifications.reverse().forEach(function (incomingNotification) {
                        var i = 0;
                        var incomingId = incomingNotification.id;

                        for (i; i < len; i += 1) {
                            if (incomingId === vm.notifications[i].id) {
                                if (notificationsAreTheSame(incomingNotification, vm.notifications[i])) {
                                    return;
                                }
                                vm.notifications.splice(i, 1);
                                break;
                            }
                        }

                        if (!firstLoad && incomingNotification.stage === NOTIFICATION_CONSTANT.STAGE.DONE && !vm.open) {
                            pushToNewNotificationsId(incomingId);
                        }

                        vm.notifications.unshift(incomingNotification);
                    });
                }

                if (!vm.open) {
                    vm.newDoneCount = newNotificationsId.length;
                    $localStorage.MonsidoNotificationsNotSeen = newNotificationsId;
                }

                vm.spinner = vm.notifications.some(function (n) {
                    return n.stage !== NOTIFICATION_CONSTANT.STAGE.DONE;
                });

                firstLoad = false;
            });
        }

        function notSeenYet (id) {
            return newNotificationsId.indexOf(id) > -1;
        }

        function pushToNewNotificationsId (id) {
            if (newNotificationsId.indexOf(id) === -1) {
                newNotificationsId.push(id);
            }
        }

        function redirect (e, row) {
            e.preventDefault();
            e.stopPropagation();
            notificationRedirectService.redirect(row);
        }

        function allNotifications () {
            var params = {
                body: 'allNotificationsDialog',
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function toggle (open) {
            vm.open = open;
            vm.newDoneCount = 0;
            $localStorage.MonsidoNotificationsNotSeen = [];
            if (!open) {
                newNotificationsId = [];
            }
        }

        /**
         * @memberOf notificationsIcon
         * @description Gets 2 Notifications and compares, if they have the same properties: id, stage, created_at, updated_at, done_at
         * @param {Notification} n1 - First Notification
         * @param {Notification} n2 - Second notification to compare the First one with
         * @return {boolean}
         */
        function notificationsAreTheSame (n1, n2) {
            return (
                n1.id + n1.stage + n1.created_at + n1.updated_at + n1.done_at ===
                n2.id + n2.stage + n2.created_at + n2.updated_at + n2.done_at
            );
        }
    }
})();
