import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { Reporting } from '@monsido/modules/models/api/reporting';
import { DomainType } from '@monsido/modules/models/api/domain';

export type ReportingsGetParams = {
    page: number;
    page_size: number;
    sort_dir: string;
    sort_by: string;
    search?: string;
}

export type ReportingsCreateParams = {
    template_id: number;
    target_type?: DomainType;
    target_id?: number;
};

@Injectable({
    providedIn: 'root',
})
export class MeReportingsRepoService {
    constructor (private apiService: ApiService) {}

    async get (params: Partial<ReportingsGetParams>): Promise<MonTableCollection<Reporting>> {
        return this.apiService.get('me/reportings', params );
    }

    async update (id: number, data: Partial<Reporting>): Promise<Reporting> {
        return this.apiService.patch(`me/reportings/${id}`, data);
    }

    async create (params: ReportingsCreateParams): Promise<Reporting> {
        return this.apiService.post('me/reportings', params);
    }
}
