(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name monDecodeHtml
     * @description Decode html special characters
     */

    angular.module('filters.monsido').filter('monDecodeHtml', MonDecodeHtmlFilter);

    MonDecodeHtmlFilter.$inject = [];
    /* @ngInject */
    function MonDecodeHtmlFilter () {
        return decode;

        // //////////

        /**
         * @memberOf monDecodeHtml
         * @param {String} str - String to decode
         * @return {String}
         * @see https://ourcodeworld.com/articles/read/188/encode-and-decode-html-entities-using-pure-javascript
         */
        function decode (str) {
            return str.replace(/&#(\d+);/g, function (match, dec) {
                return String.fromCharCode(dec);
            });
        }
    }
})();
