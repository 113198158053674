import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pages').component('pagesMisspellingsTable', {
        templateUrl: 'app/components/pages/misspellings/misspellings.html',
        controller: PagesMisspellingsController,
        controllerAs: 'vm',
        bindings: {
            disablePagination: '<?',
            pages: '<',
            progress: '<?',
            onPageChange: '&?',
            onPerPageChange: '&?',
            onSortPageChange: '&',
            buttonIcon: '@?',
            target: '<?',
            currentSort: '<',
            callBack: '&?',
            isFiltersOpen: '=?',
            onFiltersChanges: '&',
        },
    });

    PagesMisspellingsController.$inject = ['$controller', 'ng2MonEventsService', 'ng2ActiveFeatureService', 'ng2LabelService'];
    /* @ngInject */
    function PagesMisspellingsController ($controller, ng2MonEventsService, ng2ActiveFeatureService, ng2LabelService) {
        var vm = this;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.openPageDetails = openPageDetails;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.hasAccessToStats = ng2ActiveFeatureService.isFeatureActive('statistics');
            vm.pages = vm.pages || [];
            vm.disablePagination = vm.disablePagination || false;
            vm.buttonIcon = vm.buttonIcon || 'fas fa-circle';
            vm.target = vm.target || 'misspellings';
            vm.createOrUpdateLabel = createOrUpdateLabel;
        }

        function openPageDetails (page, subTarget) {
            var params = {
                body: 'pageDetails',
                size: 'fw',
                classes: 'page-details-app',
                data: {
                    page: page,
                    target: 'page-details-section-qa',
                    subTarget: subTarget,
                },
            };
            const callback = function () {
                if (typeof vm.callBack === 'function') {
                    vm.callBack();
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ size: size });
        }

        function createOrUpdateLabel (label) {
            ng2LabelService.createOrUpdateLabel(label);
        }
    }
})();
