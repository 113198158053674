(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScanLoginTypesForm', {
        templateUrl: 'app/forms/domain/steps/scan/login-types/form/form.html',
        controller: DomainScanLoginTypesFormController,
        controllerAs: 'vm',
        bindings: {
            login: '=',
        },
    });

    DomainScanLoginTypesFormController.$inject = [];
    /* @ngInject */
    function DomainScanLoginTypesFormController () {
        var vm = this;
        vm.$onInit = activate;
        vm.addFormField = addFormField;
        vm.removeFormField = removeFormField;
        vm.toggleValueVisibility = toggleValueVisibility;

        function activate () {
            vm.login = vm.login || {};
            vm.loginShowValue = {};
            if (vm.login.form === null || typeof vm.login.form !== 'object') {
                vm.login.form = {};
            }

            if (!Array.isArray(vm.login.form.fields)) {
                vm.login.form.fields = [{}];
            }
        }

        function toggleValueVisibility (index) {
            vm.loginShowValue[index] = !vm.loginShowValue[index];
        }

        function addFormField () {
            vm.login.form.fields.push({});
        }

        function removeFormField (index) {
            vm.login.form.fields.splice(index, 1);
        }
    }
})();
