(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionAccessibilityInformation', {
        templateUrl: 'app/components/pagedetails/details/sections/accessibility/information/information.html',
        controller: SectionAccessibilityInformationController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
            refreshErrors: '&',
            refreshPage: '&',
            page: '<',
        },
    });

    SectionAccessibilityInformationController.$inject = [
        'gettextCatalog',
        '$scope',
        'ng2MonModalService',
        'ng2SessionService',
        'pageService',
    ];
    /* @ngInject */
    function SectionAccessibilityInformationController (
        gettextCatalog,
        $scope,
        ng2MonModalService,
        ng2SessionService,
        pageService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.iconColor = iconColor;
        vm.onTabChange = onTabChange;
        vm.checkStatus = checkStatus;
        vm.reviewAllErrors = reviewAllErrors;
        vm.reviewError = reviewError;
        vm.refreshAfterFasttrack = refreshAfterFasttrack;

        vm.onClickIgnore = onClickIgnore;
        vm.onClickUnignore = onClickUnignore;
        vm.markCheckAsFixed = markCheckAsFixed;
        vm.allowIgnoreAction = false;

        function activate () {
            vm.tab = 'pending';
            vm.allowIgnoreAction = !ng2SessionService.isDomainGroup();
            watchers();
        }

        function watchers () {
            $scope.$watch('vm.check', function () {
                var tabOptions = [
                    {
                        name: gettextCatalog.getString('Pending'),
                        value: 'pending',
                    },
                ];

                if (vm.check) {
                    if (vm.check.result_type === 'review' && !vm.check.ignored) {
                        tabOptions.push({
                            name: gettextCatalog.getString('Reviewed'),
                            value: 'review',
                        });
                    } else {
                        if (vm.allowIgnoreAction) {
                            tabOptions.push({
                                name: gettextCatalog.getString('Ignored'),
                                value: 'review',
                            });
                        }
                    }
                }

                vm.tabOptions = tabOptions;
            });
        }

        function iconColor (abbr) {
            var colors = {
                'wcag-a': abbr === 'A',
                'wcag-aa': abbr === 'AA',
                'wcag-aaa': abbr === 'AAA',
            };
            var result = 'link';
            var i;

            for (i in colors) {
                if (colors[i] === true) {
                    result = i;
                    break;
                }
            }

            return result;
        }

        function onTabChange (tab) {
            vm.tab = tab;
        }

        function reviewAllErrors (value, comment) {
            var params = {
                type: 'errors',
                ignored_reviewed: value,
                id: vm.check.id,
            };
            if (comment) {
                params.comment = comment;
            }
            pageService.updateAccessibilityCheckErrors(vm.page.id, params).then(function () {
                vm.refreshErrors({ update: true });
                vm.refreshPage();
            }, angular.noop);
        }

        function reviewError (error, value, comment) {
            error.ignored_reviewed = value;
            if (comment) {
                error.comment = comment;
            }
            updateError(error).then(function (data) {
                vm.refreshErrors({ update: true });
                var errorCount = vm.check.errors.filter(function (error) {
                    return error.ignored;
                });
                if (vm.check.errors.length - (errorCount + 1) <= 0) {
                    vm.refreshPage();
                }
            }, angular.noop);
        }

        function checkStatus (status) {
            var confirmText;

            if (status) {
                confirmText =
                    ' <strong>' + gettextCatalog.getString('Are you sure you want to ignore this check for this page?') + '</strong>';
            } else {
                confirmText =
                    ' <strong>' + gettextCatalog.getString('Are you sure you want to unignore this check for this page?') + '</strong>';
            }

            confirmText += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');

            const params = {
                type: 'check',
                ignored: status,
            };

            ng2MonModalService.prompt(confirmText).then(function (comment) {
                if (comment) {
                    params.comment = comment;
                }

                pageService.updateAccessibilityCheck(vm.page.id, vm.check.id, params).then(function () {
                    vm.refreshErrors({ update: true });
                    vm.refreshPage();
                    vm.check.ignored = !vm.check.ignored;
                }, angular.noop);
            }, angular.noop);
        }

        function updateError (error) {
            return pageService.updateAccessibilityError(vm.page.id, error);
        }

        function onClickIgnore () {
            var text = gettextCatalog.getString('Are you sure you want to ignore all errors for this check?');
            if (vm.check.result_type === 'review') {
                text = gettextCatalog.getString('Are you sure you want to review all errors for this check?');
            }
            text += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');
            ng2MonModalService.prompt(text).then(function (comment) {
                vm.reviewAllErrors(true, comment);
            }, angular.noop);
        }

        function onClickUnignore () {
            var text = gettextCatalog.getString('Are you sure you want to unignore all errors for this check?');
            if (vm.check.result_type === 'review') {
                text = gettextCatalog.getString('Are you sure you want to remove review on all errors for this check?');
            }
            text += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');
            ng2MonModalService.prompt(text).then(function (comment) {
                vm.reviewAllErrors(false, comment);
            }, angular.noop);
        }

        function refreshAfterFasttrack () {
            vm.refreshErrors({ update: true });
            vm.refreshPage();
        }

        function markCheckAsFixed () {
            pageService.deleteAccessibilityCheck(vm.page.id, vm.check.id).then(function () {
                vm.refreshErrors({ update: true });
                vm.refreshPage();
            }, angular.noop);
        }
    }
})();
