(function () {
    'use strict';
    angular
        .module('app.forms')
        .service('FormDomainScriptGuideStatisticsService', FormDomainScriptGuideStatisticsService);

    FormDomainScriptGuideStatisticsService.$inject = [
        'ng2ActiveFeatureService',
        'ng2FormDomainScriptGuideService',
        'rx',
        'Lodash',
        'ng2MonEventsService',
        'DOMAIN_SCRIPT_GUIDE_EVENTS',
    ];

    /* @ngInject*/
    function FormDomainScriptGuideStatisticsService (ng2ActiveFeatureService,
        ng2FormDomainScriptGuideService,
        rx,
        Lodash,
        monEventsService,
        DOMAIN_SCRIPT_GUIDE_EVENTS,
    ) {
        var vm = this;
        vm.showSetting = false;
        vm.cookielessTracking = false;
        vm.documentTracking = false;
        vm.documentClass = '';
        vm.documentExtensions = [];
        vm.documentIgnoreClass = [];

        var service = {
            settingChangeObs: new rx.BehaviorSubject(),
            getValue: getValue,
            setValue: setValue,
            getCookielessTracking: getCookielessTracking,
            setCookielessTracking: setCookielessTracking,
            getDocumentTracking: getDocumentTracking,
            setDocumentTracking: setDocumentTracking,
            getFilledDocumentExtensions: getFilledDocumentExtensions,
            getDocumentExtensions: getDocumentExtensions,
            setDocumentExtensions: setDocumentExtensions,
            getDocumentClass: getDocumentClass,
            setDocumentClass: setDocumentClass,
            getDocumentIgnoreClass: getDocumentIgnoreClass,
            setDocumentIgnoreClass: setDocumentIgnoreClass,
            hasAccessToFeature: hasAccessToFeature,
        };

        return service;

        function getValue () {
            return hasAccessToFeature() && vm.showSetting;
        }

        function setValue (value, manualEdit) {
            vm.showSetting = value;
            updateSettings(manualEdit);
            service.settingChangeObs.onNext(null);
        }

        function getCookielessTracking () {
            return getValue() && vm.cookielessTracking;
        }

        function setCookielessTracking (value, manualEdit) {
            vm.cookielessTracking = value;
            updateSettings(manualEdit);
            service.settingChangeObs.onNext(null);
        }

        function getDocumentTracking () {
            return getValue() && vm.documentTracking;
        }

        function setDocumentTracking (value, manualEdit) {
            vm.documentTracking = value;
            updateSettings(manualEdit);
            service.settingChangeObs.onNext(null);
        }

        function getFilledDocumentExtensions () {
            return Lodash.filter(getDocumentExtensions(), function (ext) {
                return ext.length > 0;
            });
        }

        function getDocumentExtensions () {
            if (!Array.isArray(vm.documentExtensions)) {
                return ['pdf', 'doc', 'ppt', 'docx', 'pptx'];
            }
            return vm.documentExtensions;
        }

        function setDocumentExtensions (value, manualEdit) {
            vm.documentExtensions = value;
            updateSettings(manualEdit);
            service.settingChangeObs.onNext(null);
        }

        function getDocumentClass () {
            if (typeof vm.documentClass !== 'string') {
                return 'monsido_download';
            }
            return vm.documentClass;
        }

        function setDocumentClass (value, manualEdit) {
            vm.documentClass = value;
            updateSettings(manualEdit);
            service.settingChangeObs.onNext(null);
        }

        function getDocumentIgnoreClass () {
            if (typeof vm.documentIgnoreClass !== 'string') {
                return 'monsido_ignore_download';
            }
            return vm.documentIgnoreClass;
        }

        function setDocumentIgnoreClass (value, manualEdit) {
            vm.documentIgnoreClass = value;
            updateSettings(manualEdit);
            service.settingChangeObs.onNext(null);
        }

        function hasAccessToFeature () {
            return ng2ActiveFeatureService.isFeatureActive('statistics', ng2FormDomainScriptGuideService.getDomain());
        }

        // PROTECTED

        function updateSettings (manualEdit) {
            monEventsService.run(DOMAIN_SCRIPT_GUIDE_EVENTS.FORM_UNSAVED_UPDATE);
            ng2FormDomainScriptGuideService.setScriptSettingBlock(
                'statistics',
                {
                    enabled: getValue(),
                    cookieless_tracking: getCookielessTracking(),
                    document_tracking: {
                        enabled: getDocumentTracking(),
                        documentIgnoreCls: getDocumentIgnoreClass(),
                        document_cls: getDocumentClass(),
                        document_ext: getDocumentExtensions(),
                    },
                },
                manualEdit,
            );
        }
    }
})();
