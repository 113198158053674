(function () {
    'use strict';

    angular.module('pages.introduction').component('pagesIntroduction', {
        templateUrl: 'app/pages/introduction/introduction.html',
        controller: PagesIntroductionController,
        controllerAs: 'vm',
    });

    PagesIntroductionController.$inject = [];
    /* @ngInject */
    function PagesIntroductionController () {}
})();
