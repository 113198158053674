(function () {
    'use strict';

    angular.module('filters.monsido').filter('isFunction', isFunction);
    isFunction.$inject = [];

    /* @ngInject */
    function isFunction () {
        return function (input) {
            return angular.isFunction(input);
        };
    }
})();
