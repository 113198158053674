import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { ActionMenuItemType } from '@monsido/ng2/shared/components/action-menu-panel/action-menu-panel.type';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Component({
    selector: 'mon-qa-nin-dropdown',
    standalone: true,
    templateUrl: './nin-dropdown.component.html',
    imports: [CommonModule, SharedModule],
})
export class MonQaNinDropdownComponent implements OnChanges {
    @Input() monNiNumber?: { status: string };
    @Input() monUpdateCallback?: (niNumber: unknown, status: string, confirmMessage: string) => void;

    options: ActionMenuItemType[] = [];

    constructor (private translateService: TranslateService) { }

    ngOnChanges (): void {
        if (!this.monNiNumber) {
            return;
        }
        const dropdown = [
            {
                label: this.translateService.getString('Mark as ignored'),
                action: (): void =>{
                    const message = this.translateService.getString('Are you sure you want to mark this national identification number as ignored?');
                    this.action('ignored', message);
                },
                shouldShow: this.monNiNumber.status !== 'ignored',
            },
            {
                label: this.translateService.getString('Mark as active'),
                action: (): void =>{
                    const message = this.translateService.getString('Are you sure you want to mark this national identification number as active?');
                    this.action('active', message);
                },
                shouldShow: this.monNiNumber.status !== 'active',
            },
        ];

        this.options = dropdown.filter((option) => option.shouldShow !== false).map(item => {
            return {
                label: item.label,
                action: item.action,
            };
        });
    }

    action (status: string, message: string): void {
        if (!this.monNiNumber || !this.monUpdateCallback) {
            return;
        }
        this.monUpdateCallback(this.monNiNumber, status, message);
    }
}
