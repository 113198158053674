(function () {
    'use strict';

    angular.module('modules.tracking').run([
        'ng2MonEventsService',
        'matomoService',
        'matomoSettings',
        function (ng2MonEventsService, matomoService, matomoSettings) {
            if (matomoSettings && matomoSettings.matomoUrl) {
                matomoService.setTrackingInfo(matomoSettings);
            }

            ng2MonEventsService.addListener('trackPageView', function (url, title, ref, type, params) {
                matomoService.trackPageView(url, title, ref, type, params);
            });
            ng2MonEventsService.addListener('trackEvent', function (url, type, ref) {
                matomoService.trackEvent(url, type, ref);
            });
        },
    ]);
})();
