import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AccountSettingsComponent } from '@monsido/pages/admin/account/component/account-settings.component';
import { BaseLayoutModule } from '@layout/layout.module';
import { ACCOUNT_STATES } from '@monsido/pages/admin/account/account.state';
import { CoreModule } from '@monsido/core/core.module';
import { AngularSharedComponentsModule, MonSwitchModule, LayoutModule, ToastModule } from '@angular-shared-components';
import { FormsModule } from '@angular/forms';
import { ActiveFeatureModule } from '@monsido/services/active-feature/active-feature.module';

@NgModule({
    imports: [
        ActiveFeatureModule,
        CoreModule,
        BaseLayoutModule,
        CommonModule,
        LayoutModule,
        AngularSharedComponentsModule,
        ToastModule,
        UIRouterUpgradeModule.forChild({ states: ACCOUNT_STATES }),
        FormsModule,
        MonSwitchModule,
    ],
    exports: [AngularSharedComponentsModule],
    declarations: [AccountSettingsComponent],
    providers: [],
})
export class PagesAdminAccountModule {}
