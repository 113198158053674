import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MeRepoService } from '@client/app/services/api/me-repo/me-repo.service';
import { ErrorHandler } from '@client/app/core/services/error-handler/error-handler';
import { OauthService } from '@monsido/oauth/oauth.service';
import { monsidoPath, oauthClientId, oauthClientSecret, oauthScopes } from '@monsido/core/constants/general.constant';
import { LocalStorageService, SessionStorageService } from '@angular-shared-components';
import { HttpLegacyService } from '@monsido/http/http-legacy-service/http-legacy.service';
import { User } from '@monsido/modules/models/api/user';
import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor (
        private sessionService: SessionService,
        private monEventsService: MonEventService,
        private meRepoService: MeRepoService,
        private errorHandler: ErrorHandler,
        private oauthService: OauthService,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private httpLegacyService: HttpLegacyService,
    ) {
        this.monEventsService.addListener('force logout', () => {
            this.logout().catch();
        });
    }

    async getUser (): Promise<User | null> {
        if (this.sessionService.isUserLoaded()) {
            return this.sessionService.me || null;
        }

        return this.loadUser();
    }

    async loadUser (): Promise<User | null> {
        await this.monEventsService.run('beforeLoadUser');
        try {
            const data = await this.meRepoService.get(contextTokens.NO_GLOBAL);

            if (data) {
                this.sessionStorageService.setItem('logouts', 0);
            } else {
                await this.monEventsService.run('newException', 'No user returned upon login', 'critical');
            }

            await this.monEventsService.run('afterLoadUser', data, this.sessionService.isSudo);
            return null;
        } catch (response) {
            if (response.status !== 423) {
                if (!this.sessionService.me || !this.sessionService.customer) {
                    this.errorHandler.standardMsg('Missing user or customer', 'info');
                }
                await this.logout();
                return null;
            }
            return response;
        }
    }

    async logout (): Promise<void> {
        const token = this.getAuthToken();
        const bodyParams = {
            token: token,
            client_id: oauthClientId,
            client_secret: oauthClientSecret,
        };

        this.sessionService.clearSession();
        this.localStorageService.setItem('roles', [].toString());

        try {
            await this.httpLegacyService.post(`${monsidoPath}oauth/revoke.json`, bodyParams);
            await this.monEventsService.run('logout');
            window.onbeforeunload = null;
            const url = this.oauthService.getLogoutUrl();
            if (url) {
                this.oauthService.clearLogoutUrl();
                window.location.href = url;
            } else {
                const logoutCount = this.sessionStorageService.getItem('logouts');
                this.sessionStorageService.setItem('logouts', (logoutCount || 0) + 1);

                if (logoutCount == null || logoutCount > 2) {
                    window.location.href = `${monsidoPath}logout?redirect_uri=${this.getEncodedBaseUrl()}`;
                } else {
                    window.location.href = `${monsidoPath}logout?token=${token}&redirect_uri=${this.getEncodedBaseUrl()}`;
                }
            }

        } catch (e) {}
    }

    setMe (user: User | null): void {
        this.sessionService.me = user;
        this.sessionService.meObs.next(user);
        if (user !== null) {
            user.settings = user.settings || { dashboard: {}, accessibility: false };
            this.sessionService.accessibilityMode = Boolean(user.settings.accessibility);
        }
    }

    setup2FA (): void {
        window.location.href = this.oauthService.generateOTPUrl(
            monsidoPath,
            'setup',
            encodeURIComponent(window.location.pathname + window.location.search),
        );
    }

    getAuthorizeUrl (): string {
        return this.oauthService.getAuthUrlAndSetStateParam(monsidoPath, oauthClientId, oauthScopes);
    }

    getAuthToken (): string {
        return this.oauthService.getToken();
    }
    getRefreshToken (): string {
        return this.oauthService.getRefreshToken();
    }

    isLoggedIn (): boolean {
        return this.oauthService.isAuthenticated();
    }

    getApiPath (): string {
        return this.oauthService.getApiPath();
    }

    private getEncodedBaseUrl (): string {
        const baseUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
        return encodeURIComponent(baseUrl);
    }
}
