(function () {
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixSummaryTable', {
        templateUrl: 'app/modules/page-fix/pages/summary/table/table.html',
        controller: PFSummaryTableController,
        controllerAs: 'vm',
        bindings: {
            pageFixes: '<',
        },
    });

    PFSummaryTableController.$inject = ['PageFixListService'];
    /* @ngInject */
    function PFSummaryTableController (PageFixListService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getErrors = getErrors;

        function activate () {
            vm.pageFixes = vm.pageFixes || [];
        }

        function getErrors (pageFix) {
            return PageFixListService.getError(pageFix);
        }
    }
})();
