(function () {
    'use strict';

    angular.module('modules.api-users').component('formApiUsers', {
        templateUrl: 'app/modules/api-users/forms/api-users/api-users.html',
        controller: FormApiUserController,
        controllerAs: 'vm',
        bindings: {
            apiUser: '<?',
            monDialogId: '@',
        },
    });

    FormApiUserController.$inject = ['monDialog', 'ApiUsersRepo', 'ApiUsersService', 'Lodash'];

    /* @ngInject */
    function FormApiUserController (monDialog, ApiUsersRepo, ApiUsersService, Lodash) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.setScope = setScope;
        vm.isScopeChecked = isScopeChecked;

        function activate () {
            vm.saving = false;
            vm.ngModel = vm.apiUser || {};
            vm.ngModel.scopes = vm.ngModel.scopes || [];
            vm.scopes = ApiUsersService.getScopes();
        }

        function setScope (scopeValue) {
            if (isScopeChecked(scopeValue)) {
                var index = Lodash.indexOf(vm.ngModel.scopes, scopeValue);
                vm.ngModel.scopes.splice(index, 1);
            } else {
                vm.ngModel.scopes.push(scopeValue);
            }
        }

        function isScopeChecked (scopeValue) {
            return Lodash.indexOf(vm.ngModel.scopes, scopeValue) > -1;
        }

        function submit () {
            vm.saving = true;

            ApiUsersRepo.create(vm.ngModel).then(
                function (apiUser) {
                    close(apiUser);
                },
                function () {
                    vm.saving = false;
                },
            );
        }

        function close (apiUser) {
            monDialog.close(vm.monDialogId, apiUser);
        }
    }
})();
