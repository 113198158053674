export const PATH_WHITELIST = [
    'base.customer.domain.dashboard',
    'base.customer.domain.qa',
    'base.customer.domain.pages',
    'base.customer.domain.policy',
    'base.customer.domain.accessibility',
    'base.customer.domain.seo',
    'base.customer.domain.uptimes',
    'base.customer.domain.page_fix',
    'base.customer.domain.data_privacy',
    'base.customer.domain.page_performance',
    'base.customer.domain.statistics',
    'base.customer.domain.inventory',
] as const;
