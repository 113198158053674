
<div class="panel-header-wrapper">
    <mon-panel-header
            faIcon="{{['REPORT_CENTER', 'RECEIVED'] | monIcons}} text-link"
            pHeader="{{'Received reports' | translate}}"
            panelLeftClass="col-30"
            panelRightClass="col-10 inner-none"
            hideHr="true">
    </mon-panel-header>
    <button class="btn btn-no-style text-link py-3"
            *ngIf="total > rowAmount"
            (click)="openReceivedReportsDialog()"
            translate>
        Show more
    </button>
</div>
<div>
    <mon-received-reports-table
        [showPagination]="false"
        (total)="setTotal($event)"
        ></mon-received-reports-table>
</div>

