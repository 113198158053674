(function () {
    'use strict';

    angular.module('modules.cookie_banner').service('CookieCategoriesLocalizeService', CookieCategoriesLocalizeService);

    CookieCategoriesLocalizeService.$inject = ['COOKIE_CATEGORIES', 'gettextCatalog'];
    /* @ngInject*/
    function CookieCategoriesLocalizeService (COOKIE_CATEGORIES, gettextCatalog) {
        var categories = {};
        categories[COOKIE_CATEGORIES.analytics] = gettextCatalog.getString('Statistics');
        categories[COOKIE_CATEGORIES.basic] = gettextCatalog.getString('Basic');
        categories[COOKIE_CATEGORIES.essential] = gettextCatalog.getString('Essential');
        categories[COOKIE_CATEGORIES.marketing] = gettextCatalog.getString('Marketing');
        categories[COOKIE_CATEGORIES.personalisation] = gettextCatalog.getString('Preferences');

        return categories;
    }
})();
