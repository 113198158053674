(function () {
    'use strict';

    angular.module('app.layout', [
        'ui.bootstrap.modal',
        'blocks.helpers',
        'angular-click-outside',
        'ngAvatar',
        'ngAnimate',
        'angular-bind-html-compile',
        'plugins.lodash',
        'modules.roles',
    ]);
})();
