(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityGuidelinesWcag20', {
        templateUrl: 'app/pages/accessibility/guidelines/acc-standards/wcag-2-0/wcag-2-0.html',
        controller: AccessibilityGuidelinesAccWCAGController,
        controllerAs: 'vm',
        bindings: {
            guidelines: '<',
            refreshPage: '&',
            progress: '<',
        },
    });

    AccessibilityGuidelinesAccWCAGController.$inject = [];

    function AccessibilityGuidelinesAccWCAGController () {}
})();
