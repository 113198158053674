import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MonLabelBlockedByDomainGroupsComponent } from './modals/label-blocked-by-domain-groups.component';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [CommonModule, TranslateModule, LayoutModule],
    declarations: [MonLabelBlockedByDomainGroupsComponent],
    exports: [MonLabelBlockedByDomainGroupsComponent],
})
export class MonLabelsModule {}
