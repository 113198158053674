<button *ngIf="enableExport"
        data-test="export-button"
        class="btn btn-border-radius btn-secondary mr-2 py-2 px-3"
        [ngClass]="btnClass"
        [disabled]="disabled"
        (click)="exportFiles()"
        [ngbTooltip]="'Export' | translate"
        attr.aria-label="{{ 'Export' | translate }}">
    <mon-icon class="icon" [monIconDirect]="'faDownload'"></mon-icon>
    <span class="sr-only" translate>Export</span>
</button>
