import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PropertiesSumService {
    constructor () {}

    /**
     * Sums the object's properties according to the provided property array.
     */
    sum (object: Record<string, number>, properties: string[]): number {
        object = object || {};
        properties = properties || [];

        return properties.reduce(
            (result: number, property: string) => result + (object[property] || 0),
            0,
        );
    }
}
