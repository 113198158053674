import { Injectable } from '@angular/core';
import { TransitionHookFn, UIRouterGlobals } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class MonUIRouterTransitionService {
    constructor (
        private uiRouterGlobals: UIRouterGlobals,
    ) {}

    public onTransitionFinished (callback: TransitionHookFn): void {
        if (this.uiRouterGlobals.transition && !this.uiRouterGlobals.transition.ignored()) {
            this.uiRouterGlobals.transition.onFinish({}, callback);
        } else {
            (callback as () => void)();
        }
    }

    public onTransitionFinishedAsync (): Promise<void> {
        return new Promise(resolve => {
            this.onTransitionFinished(() => resolve());
        });
    }
}
