import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionAccessibilityInformationPending', {
        templateUrl: 'app/components/pagedetails/details/sections/accessibility/information/tabs/pending/pending.html',
        controller: SectionAccessibilityInformationController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
            updateError: '&',
            refresh: '&',
            page: '<',
        },
    });

    SectionAccessibilityInformationController.$inject = [
        '$scope',
        'ACCESSIBILITY_HELPER',
        'ng2MonModalService',
        'gettextCatalog',
        'ng2SessionService',
        'pageService',
        'ng2MonEventsService',
        'ng2DirectNavigationService',
    ];
    /* @ngInject */
    function SectionAccessibilityInformationController (
        $scope,
        ACCESSIBILITY_HELPER,
        ng2MonModalService,
        gettextCatalog,
        ng2SessionService,
        pageService,
        ng2MonEventsService,
        ng2DirectNavigationService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.toggleCharacterLimit = toggleCharacterLimit;
        vm.filterErrors = filterErrors;
        vm.openFastTrack = openFastTrack;
        vm.isErrorImageRelated = isErrorImageRelated;
        vm.onReview = onReview;
        vm.openIssueModal = openIssueModal;
        vm.markAsFixed = markAsFixed;

        function activate () {
            vm.check = vm.check || {};
            vm.characterLimit = 150;
            vm.characterLimits = [];
            vm.allowIgnoreAction = !ng2SessionService.isDomainGroup();
            watchers();

            ng2MonEventsService.addListener('reloadAccessibilityIssues', () => {
                vm.refresh();
            });
        }

        function watchers () {
            $scope.$watch('vm.check.errors', function () {
                vm.characterLimits = [];
                filterErrors().forEach(function (error, index) {
                    vm.characterLimits[index] = vm.characterLimit;
                });
            });
        }

        function toggleCharacterLimit (index) {
            var limit = vm.characterLimits[index];
            if (limit === vm.characterLimit || limit === undefined) {
                vm.characterLimits[index] = null;
            } else {
                vm.characterLimits[index] = vm.characterLimit;
            }
        }

        function filterErrors () {
            if (vm.check && Array.isArray(vm.check.errors)) {
                return vm.check.errors.filter((error) => {
                    if (error.type === 'review' && /^<body(\s|>)/.test(error.source_code)) {
                        error.source_code = gettextCatalog.getString('You must perform a manual review of the page to confirm if this issue is present');
                        error.isBodyReview = true;
                    }
                    return !error.ignored;
                });
            }
            return [];
        }

        function openFastTrack (sourceCodeId) {
            var params = {
                size: 'sm',
                body: 'pageDetailsSectionAccessibilityInformationFastTrack',
                data: { sourceCodeId: sourceCodeId },
            };
            const callback = function () {
                vm.refresh({});
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function isErrorImageRelated (error) {
            return ACCESSIBILITY_HELPER.IMG_CHECKS.indexOf(error.check_id) !== -1;
        }

        function onReview (error) {
            var text;
            if (vm.check.result_type === 'review') {
                text = gettextCatalog.getString('Are you sure you want to review this issue?');
            } else {
                text = gettextCatalog.getString('Are you sure you want to ignore this issue?');
            }
            var confirmText = ' <strong>' + text + '</strong>';
            confirmText += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');

            ng2MonModalService
                .prompt(confirmText)
                .then(function (comment) {
                    vm.updateError({ error: error, value: true, comment: comment });
                })
                .catch(angular.noop);
        }

        function markAsFixed (error) {
            pageService.deleteAccessibilityError(vm.page.id, error).then(function () {
                vm.refresh();
            }, angular.noop);
        }

        function openIssueModal (issue) {
            ng2DirectNavigationService.navigateToIssueOverlay({
                issueOverlayIssueId: issue.id,
                issueOverlaySourceCodeId: issue.source_code_id,
                issueOverlayPageId: vm.page.id,
                issueOverlayCheckId: issue.check_id,
                issueOverlayIssueType: 'accessibility',
            });
        }
    }
})();
