(function () {
    'use strict';

    angular.module('modules.roles', [
        'angularMoment',
        'services',
        'modules.cache',
        'core.services.login',
    ]);
})();
