(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name QABrokenLinkService
     * @description Filter helper for QA broken link
     */
    angular.module('blocks.service').factory('QABrokenLinkService', QABrokenLinkService);

    QABrokenLinkService.$inject = [];
    /* @ngInject*/
    function QABrokenLinkService () {
        var responseCodes = [
            {
                id: 0,
                name: 'N/A',
            },
            {
                id: 301,
                name: '301',
            },
            {
                id: 302,
                name: '302',
            },
            {
                id: 303,
                name: '303',
            },
            {
                id: 403,
                name: '403',
            },
            {
                id: 404,
                name: '404',
            },
            {
                id: 405,
                name: '405',
            },
            {
                id: 410,
                name: '410',
            },
            {
                id: 414,
                name: '414',
            },
            {
                id: 500,
                name: '500',
            },
            {
                id: 501,
                name: '501',
            },
            {
                id: 502,
                name: '502',
            },
            {
                id: 503,
                name: '503',
            },
            {
                id: 504,
                name: '504',
            },
            {
                id: 505,
                name: '505',
            },
        ];

        return {
            getResponseCodes: getResponseCodes,
        };

        function getResponseCodes () {
            return responseCodes;
        }
    }
})();
