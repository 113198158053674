(function () {
    'use strict';

    angular.module('modules.business-intelligence').run(runAction);

    runAction.$inject = ['ng2MonEventsService', 'biService', '$window'];
    /* @ngInject */
    function runAction (ng2MonEventsService, biService, $window) {
        ng2MonEventsService.addListener('actionTrigger', function (payload) {
            if (!payload.ref) {
                payload.ref = $window.location.href;
            }
            if (!payload.params) {
                payload.params = {};
            }
            biService.action(payload.action, payload.ref, payload.params);
        });
    }
})();
