import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { CustomerInterface } from '@monsido/ng2/modules/models/api/interfaces/customer.interface';
import { Customer } from '@monsido/ng2/modules/models/api/customer';

@Injectable({
    providedIn: 'root',
})
export class AccountRepoService {

    constructor (private apiService: ApiService) { }

    get (): Promise<Customer> {
        return this.apiService.get('account').then(this.toModel);
    }

    update (data: CustomerInterface): Promise<Customer> {
        return this.apiService.patch('account', data).then(this.toModel);
    }

    toModel (model: CustomerInterface): Customer {
        return new Customer(model);
    }
}
