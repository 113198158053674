(function () {
    'use strict';

    angular.module('modules.shortcut').run([
        'activeFeatures',
        'ShortcutService',
        'ng2MonEventsService',
        'ng2ActiveFeatureService',
        function (activeFeatures, ShortcutService, ng2MonEventsService, ng2ActiveFeatureService) {
            ng2MonEventsService.addListener('logout', function () {
                ShortcutService.logout();
            });

            ng2MonEventsService.addListener('exitCustomer', function () {
                if (activeFeatures.shortcuts) {
                    ShortcutService.clearShortcuts();
                }
            });
            ng2MonEventsService.addListener('afterCustomer', function () {
                if (ng2ActiveFeatureService.isFeatureActivePure('general_shortcuts')) {
                    ShortcutService.getShortcuts();
                }
            });
        },
    ]);
})();
