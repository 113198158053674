import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { DemoPageRepoService } from '../endpoints/pages/demo-page-repo.service';
import { DemoPdfDocumentType, DemoPdfRepoService, isDemoPdfDocumentType } from '../endpoints/demo-pdf-repo/demo-pdf-repo.service';
import { Customer } from '@monsido/ng2/modules/models/api/customer';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { Page } from '@monsido/ng2/modules/page-details/models';
import { isPage } from '@monsido/ng2/modules/page-details/models/page.utils';

@Injectable({
    providedIn: 'root',
})
export class DemoHasAccessService {

    constructor (
        private sessionService: SessionService,
        private demoPageRepoService: DemoPageRepoService,
        private demoPdfRepoService: DemoPdfRepoService,
    ) {}

    get customer (): Customer | null {
        return this.sessionService.customer;
    }

    get domain (): Domain | null {
        return this.sessionService.domain;
    }

    isDemoHeatmaps (): boolean {
        return this.isDemoAccount() && this.customerHasHeatmaps();
    }

    isDemoConsentManager (): boolean {
        return this.isDemoAccount() &&
            (this.customer?.plan_traits.cookie_banner || false);
    }

    canAccessAccessibilityPDF (): boolean {
        return this.isDemoAccount() &&
            this.domainCanScan() &&
            this.domainHasAccessibility() &&
            !this.customerHasClarity();
    }

    isUsingDemoData (val: Page | DemoPdfDocumentType): boolean {
        if (!this.isDemoAccount()) {
            return false;
        }

        if (isPage(val)) {
            return this.demoPageRepoService.isDemo(val);
        }

        if (isDemoPdfDocumentType(val)) {
            return this.demoPdfRepoService.isDemo(val);
        }

        return false;
    }

    useStatisticsDemoData (): boolean {
        return !this.domainHasStatistics() && this.isDemoAccount();
    }

    isDemoAccount (): boolean {
        return this.customer?.plan_type === 'demo';
    }

    private domainCanScan (): boolean {
        return this.domain?.scan.scan_documents || false;
    }

    private domainHasAccessibility (): boolean {
        return !!this.domain?.features.accessibility;
    }

    private customerHasClarity (): boolean {
        return this.customer?.plan_traits.clarity_pdf_accessibility || false;
    }

    private domainHasStatistics (): boolean {
        return this.domain?.features.statistics || false;
    }

    private customerHasHeatmaps (): boolean {
        const planTraits = this.customer?.plan_traits;

        return Boolean(planTraits && (planTraits.heatmap_click ||
            planTraits.heatmap_movement ||
            planTraits.heatmap_scroll));
    }
}
