(function () {
    'use strict';

    angular.module('app.forms').component('formDomainGroupsBasic', {
        templateUrl: 'app/forms/domain/steps/domain_groups/steps/basic/basic.html',
        controller: DomainGroupsBasicController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
        },
    });

    DomainGroupsBasicController.$inject = [];
    /* @ngInject */
    function DomainGroupsBasicController () {
        var vm = this;
    }
})();
