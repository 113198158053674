export const PAGE_PERFORMANCE_VERSION = {
    v1: {
        metrics: [
            {
                id: 'first_contentful_paint',
                auditId: 'first-contentful-paint',
                label: 'First contentful paint (FCP)',
                chartLabel: 'First Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-fcp',
            },
            {
                id: 'first_meaningful_paint',
                auditId: 'first-meaningful-paint',
                label: 'First meaningful Paint (FMP)',
                chartLabel: 'First meaningful Paint',
                unit: 'ms',
                rulerClass: 'ruler-fmp',
            },
            {
                id: 'time_to_interactive',
                auditId: 'interactive',
                label: 'Time to interactive (TTI)',
                chartLabel: 'Time to Interactive',
                unit: 'ms',
                rulerClass: 'ruler-tti',
            },
        ],
        avgMetrics: [
            {
                id: 'performance_first_contentful_paint_average',
                label: 'Avg. First contentful paint (FCP)',
                chartLabel: 'Average First Contentful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_first_meaningful_paint_average',
                label: 'Avg. First meaningful paint (FMP)',
                chartLabel: 'Average First Meaningful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_time_to_interactive_average',
                label: 'Avg. Time to interactive (TTI)',
                chartLabel: 'Average Time to Interactive',
                unit: 'ms',
            },
        ],
    },
    'v1-v2': {
        label: 'Update Lighthouse v8',
        link: 'https://help.monsido.com/en/articles/5789360-performance-scores-suddenly-changed-what-happened',
        metrics: [
            {
                id: 'first_contentful_paint',
                auditId: 'first-contentful-paint',
                label: 'First contentful paint (FCP)',
                chartLabel: 'First Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-fcp',
            },
            {
                id: 'largest_contentful_paint',
                auditId: 'largest-contentful-paint',
                label: 'Largest contentful paint (LCP)',
                chartLabel: 'Largest Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-lcp',
            },
            {
                id: 'time_to_interactive',
                auditId: 'interactive',
                label: 'Time to interactive (TTI)',
                chartLabel: 'Time to Interactive',
                unit: 'ms',
                rulerClass: 'ruler-tti',
            },
            {
                id: 'speed_index',
                auditId: 'speed-index',
                label: 'Speed Index (SI)',
                chartLabel: 'Speed Index',
                unit: 'ms',
                rulerClass: 'ruler-si',
            },
            {
                id: 'total_blocking_time',
                auditId: 'total-blocking-time',
                label: 'Total Blocking Time (TBT)',
                chartLabel: 'Total Blocking Time',
                unit: 'ms',
                rulerClass: 'ruler-tbt',
            },
            {
                id: 'cumulative_layout_shift',
                auditId: 'cumulative-layout-shift',
                label: 'Cumulative Layout Shift (CLS)',
                chartLabel: 'Cumulative Layout Shift',
                unit: '',
                rulerClass: 'ruler-cls',
            },
        ],
        avgMetrics: [
            {
                id: 'performance_first_contentful_paint_average',
                label: 'Avg. First contentful paint (FCP)',
                chartLabel: 'Average First Contentful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_first_meaningful_paint_average',
                label: 'Avg. First meaningful paint (FMP)',
                chartLabel: 'Average First Meaningful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_largest_contentful_paint_average',
                label: 'Avg. Largest contentful paint (LCP)',
                chartLabel: 'Average First Meaningful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_time_to_interactive_average',
                label: 'Avg. Time to interactive (TTI)',
                chartLabel: 'Average Time to Interactive',
                unit: 'ms',
            },
            {
                id: 'performance_speed_index_average',
                label: 'Avg. Speed Index (SI)',
                chartLabel: 'Average Speed Index',
                unit: 'ms',
            },
            {
                id: 'performance_total_blocking_time_average',
                label: 'Avg. Total Blocking Time (TBT)',
                chartLabel: 'Average Total Blocking Time',
                unit: 'ms',
            },
            {
                id: 'performance_cumulative_layout_shift_average',
                label: 'Avg. Cumulative Layout Shift (CLS)',
                chartLabel: 'Average Cumulative Layout Shift',
                unit: '',
            },
        ],
    },
    v2: {
        label: 'Update Lighthouse v8',
        link: 'https://help.monsido.com/en/articles/5789360-performance-scores-suddenly-changed-what-happened',
        metrics: [
            {
                id: 'first_contentful_paint',
                auditId: 'first-contentful-paint',
                label: 'First contentful paint (FCP)',
                chartLabel: 'First Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-fcp',
            },
            {
                id: 'largest_contentful_paint',
                auditId: 'largest-contentful-paint',
                label: 'Largest contentful paint (LCP)',
                chartLabel: 'Largest Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-lcp',
            },
            {
                id: 'time_to_interactive',
                auditId: 'interactive',
                label: 'Time to interactive (TTI)',
                chartLabel: 'Time to Interactive',
                unit: 'ms',
                rulerClass: 'ruler-tti',
            },
            {
                id: 'speed_index',
                auditId: 'speed-index',
                label: 'Speed Index (SI)',
                chartLabel: 'Speed Index',
                unit: 'ms',
                rulerClass: 'ruler-si',
            },
            {
                id: 'total_blocking_time',
                auditId: 'total-blocking-time',
                label: 'Total Blocking Time (TBT)',
                chartLabel: 'Total Blocking Time',
                unit: 'ms',
                rulerClass: 'ruler-tbt',
            },
            {
                id: 'cumulative_layout_shift',
                auditId: 'cumulative-layout-shift',
                label: 'Cumulative Layout Shift (CLS)',
                chartLabel: 'Cumulative Layout Shift',
                unit: '',
                rulerClass: 'ruler-cls',
            },
        ],
        avgMetrics: [
            {
                id: 'performance_first_contentful_paint_average',
                label: 'Avg. First contentful paint (FCP)',
                chartLabel: 'Average First Contentful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_largest_contentful_paint_average',
                label: 'Avg. Largest contentful paint (LCP)',
                chartLabel: 'Average First Meaningful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_time_to_interactive_average',
                label: 'Avg. Time to interactive (TTI)',
                chartLabel: 'Average Time to Interactive',
                unit: 'ms',
            },
            {
                id: 'performance_speed_index_average',
                label: 'Avg. Speed Index (SI)',
                chartLabel: 'Average Speed Index',
                unit: 'ms',
            },
            {
                id: 'performance_total_blocking_time_average',
                label: 'Avg. Total Blocking Time (TBT)',
                chartLabel: 'Average Total Blocking Time',
                unit: 'ms',
            },
            {
                id: 'performance_cumulative_layout_shift_average',
                label: 'Avg. Cumulative Layout Shift (CLS)',
                chartLabel: 'Average Cumulative Layout Shift',
                unit: '',
            },
        ],
    },
    'v2-v3': {
        label: 'Update Lighthouse v10',
        link: 'https://help.monsido.com/en/articles/8300445-performance-module-upgrade-to-lighthouse-10',
        metrics: [
            {
                id: 'first_contentful_paint',
                auditId: 'first-contentful-paint',
                label: 'First contentful paint (FCP)',
                chartLabel: 'First Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-fcp',
            },
            {
                id: 'largest_contentful_paint',
                auditId: 'largest-contentful-paint',
                label: 'Largest contentful paint (LCP)',
                chartLabel: 'Largest Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-lcp',
            },
            {
                id: 'speed_index',
                auditId: 'speed-index',
                label: 'Speed Index (SI)',
                chartLabel: 'Speed Index',
                unit: 'ms',
                rulerClass: 'ruler-si',
            },
            {
                id: 'total_blocking_time',
                auditId: 'total-blocking-time',
                label: 'Total Blocking Time (TBT)',
                chartLabel: 'Total Blocking Time',
                unit: 'ms',
                rulerClass: 'ruler-tbt',
            },
            {
                id: 'cumulative_layout_shift',
                auditId: 'cumulative-layout-shift',
                label: 'Cumulative Layout Shift (CLS)',
                chartLabel: 'Cumulative Layout Shift',
                unit: '',
                rulerClass: 'ruler-cls',
            },
        ],
        avgMetrics: [
            {
                id: 'performance_first_contentful_paint_average',
                label: 'Avg. First contentful paint (FCP)',
                chartLabel: 'Average First Contentful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_largest_contentful_paint_average',
                label: 'Avg. Largest contentful paint (LCP)',
                chartLabel: 'Average First Meaningful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_speed_index_average',
                label: 'Avg. Speed Index (SI)',
                chartLabel: 'Average Speed Index',
                unit: 'ms',
            },
            {
                id: 'performance_total_blocking_time_average',
                label: 'Avg. Total Blocking Time (TBT)',
                chartLabel: 'Average Total Blocking Time',
                unit: 'ms',
            },
            {
                id: 'performance_cumulative_layout_shift_average',
                label: 'Avg. Cumulative Layout Shift (CLS)',
                chartLabel: 'Average Cumulative Layout Shift',
                unit: '',
            },
        ],
    },
    v3: {
        label: 'Update Lighthouse v10',
        link: 'https://help.monsido.com/en/articles/8300445-performance-module-upgrade-to-lighthouse-10',
        metrics: [
            {
                id: 'first_contentful_paint',
                auditId: 'first-contentful-paint',
                label: 'First contentful paint (FCP)',
                chartLabel: 'First Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-fcp',
            },
            {
                id: 'largest_contentful_paint',
                auditId: 'largest-contentful-paint',
                label: 'Largest contentful paint (LCP)',
                chartLabel: 'Largest Contentful Paint',
                unit: 'ms',
                rulerClass: 'ruler-lcp',
            },
            {
                id: 'speed_index',
                auditId: 'speed-index',
                label: 'Speed Index (SI)',
                chartLabel: 'Speed Index',
                unit: 'ms',
                rulerClass: 'ruler-si',
            },
            {
                id: 'total_blocking_time',
                auditId: 'total-blocking-time',
                label: 'Total Blocking Time (TBT)',
                chartLabel: 'Total Blocking Time',
                unit: 'ms',
                rulerClass: 'ruler-tbt',
            },
            {
                id: 'cumulative_layout_shift',
                auditId: 'cumulative-layout-shift',
                label: 'Cumulative Layout Shift (CLS)',
                chartLabel: 'Cumulative Layout Shift',
                unit: '',
                rulerClass: 'ruler-cls',
            },
        ],
        avgMetrics: [
            {
                id: 'performance_first_contentful_paint_average',
                label: 'Avg. First contentful paint (FCP)',
                chartLabel: 'Average First Contentful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_largest_contentful_paint_average',
                label: 'Avg. Largest contentful paint (LCP)',
                chartLabel: 'Average First Meaningful Paint',
                unit: 'ms',
            },
            {
                id: 'performance_speed_index_average',
                label: 'Avg. Speed Index (SI)',
                chartLabel: 'Average Speed Index',
                unit: 'ms',
            },
            {
                id: 'performance_total_blocking_time_average',
                label: 'Avg. Total Blocking Time (TBT)',
                chartLabel: 'Average Total Blocking Time',
                unit: 'ms',
            },
            {
                id: 'performance_cumulative_layout_shift_average',
                label: 'Avg. Cumulative Layout Shift (CLS)',
                chartLabel: 'Average Cumulative Layout Shift',
                unit: '',
            },
        ],
    },
};
