(function () {
    'use strict';

    angular.module('app.forms').component('formRuleImportUpload', {
        templateUrl: 'app/forms/domain/steps/domain_groups/steps/rules/import/upload/upload.html',
        controller: ImportRuleUploadController,
        controllerAs: 'vm',
        bindings: {
            step: '=',
            data: '=',
        },
    });

    ImportRuleUploadController.$inject = ['papa', '$scope'];
    /* @ngInject */
    function ImportRuleUploadController (papa, $scope) {
        var vm = this;

        vm.openFileDialog = openFileDialog;
        vm.newBulkFile = newBulkFile;

        function openFileDialog () {
            var el = document.getElementById('new_bulk_csv');
            el.click();
        }

        function newBulkFile (event) {
            vm.data = papa.parse(event.target.files[0], {
                complete: function (data) {
                    vm.data = data;
                    vm.step = 2;
                    $scope.$apply();
                },
            });
        }
    }
})();
