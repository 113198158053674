import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@client/app/modules/translate.module';
import { FormBuilderValidationModule, IconsModule, MonEventTriggerModule, MonPromptModule, MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormsModule, NgForm } from '@angular/forms';
import { PiwikValidationDirective } from '../directives/piwik-validation/piwik-validation.directive';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { StateService } from '@uirouter/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { DomainService } from '@client/app/services/entities/domain/domain.service';

@Component({
    selector: 'mon-advanced-analytics-default',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        IconsModule,
        MonEventTriggerModule,
        FormsModule,
        FormBuilderValidationModule,
        MonEventTriggerModule,
        MonPromptModule,
        PiwikValidationDirective,
    ],
    templateUrl: './advanced-analytics-default.component.html',
    styleUrls: ['./advanced-analytics-default.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedAnalyticsDefaultComponent {

    piwikAccountUrl = '';
    savingUrlInProgress = false;

    constructor (
        private monPromptService: MonPromptService,
        private translateService: TranslateService,
        private stateService: StateService,
        private sessionService: SessionService,
        private domainService: DomainService,
    ) {}

    async connectAccount (form: NgForm): Promise<void> {

        if (!this.piwikAccountUrl || this.savingUrlInProgress) {
            return;
        }

        const domain = this.sessionService.domain;

        if (!domain) {
            console.warn('Cannot find the domain.');
            return;
        }

        const domainId = domain.id;
        const customerId = this.sessionService.customer && this.sessionService.customer.id;
        let url = this.piwikAccountUrl;

        if (!url.startsWith('https://')) {
            url = 'https://' + url;
        }

        if (form.valid) {
            this.savingUrlInProgress = true;
            await this.domainService.update(domainId, {
                features: {
                    ...domain.features,
                    advanced_analytics_url: url,
                },
            });

            this.savingUrlInProgress = false;

            if (this.sessionService.domain?.id === domainId) {
                this.sessionService.domain.features.advanced_analytics_url = url;
            }

            try {
                await this.monPromptService.custom({
                    title: this.translateService.getString('Piwik PRO account successfully connected'),
                    message: this.translateService.getString(
                        'Your Piwik PRO account has now been linked to Acquia Optimize. The next time you click the Advanced Analytics module in the main navigation, your Piwik PRO instance opens in a new tab.') +
                        '\n\n' +
                        this.translateService.getString('You can unlink your Piwik PRO account from Acquia Optimize in Admin Settings.'),
                    buttons: [
                        {
                            label: this.translateService.getString('Return to Dashboard'),
                            className: 'btn btn-primary',
                            callback: (): void => {
                                this.stateService.transitionTo('base.customer.domain.dashboard', { domainId, customerId });
                            },
                        },
                    ],
                }, {
                    backdrop: 'static',
                });
            } catch (_e) {
                this.stateService.transitionTo('base.customer.domain.dashboard', { domainId, customerId });
            }
        }

    }
}
