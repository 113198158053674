import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TextSearchService {
    fuzzySearch (needle: string, haystack: string): boolean {
        if (needle === '') {
            return true;
        }

        const lowerCasedNeedle = needle.toLowerCase().replace(/ /g, '');
        const lowerCasedHaystack = haystack.toLowerCase();

        // All characters in needle must be present in haystack
        let j = 0; // haystack position
        for (let i = 0; i < lowerCasedNeedle.length; i++) {
            // Go down the haystack until we find the current needle character
            while (lowerCasedNeedle[i] !== lowerCasedHaystack[j]) {
                j++;
                // If we reached the end of the haystack, then this is not a match
                if (j === lowerCasedHaystack.length) {
                    return false;
                }
            }
        }
        return true;
    }

    contains (needle: string, haystack: string): boolean {
        return haystack.toLowerCase().includes(needle.toLowerCase());
    }
}
