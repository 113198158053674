<div class="mon-scrollable-dropdown-menu-wrapper">
    <ul role="menu" [attr.aria-labelledby]="monLabelledBy" #scrollContainerRef class="mon-scrollable-dropdown-menu" [attr.id]="monId" tabindex="-1">
        <li *ngFor="let menuItem of monMenuItems" role="none" class="mon-scrollable-dropdown-menu-item-container">
            <button
                type="button"
                role="menuitem"
                tabindex="-1"
                class="btn mon-scrollable-dropdown-menu-item"
                (click)="onMenuItemClick(menuItem)">
                <span *ngFor="let icon of menuItem.icons" class="mon-scrollable-dropdown-menu-item-icon" [ngClass]="icon" aria-hidden="true"></span>
                <span class="mon-scrollable-dropdown-menu-item-text">{{ menuItem.text }}</span>
            </button>
        </li>
    </ul>
</div>
