import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.cookie_banner.forms').component('formDomainScriptGuideFeaturesCookieBanner', {
        templateUrl: 'app/modules/cookie-banner/forms/domain-script-guide/domain-script-guide.html',
        controller: DomainScriptGuideFeaturesCookieBannerController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            cookieBannerModel: '<',
        },
    });

    DomainScriptGuideFeaturesCookieBannerController.$inject = [
        'CookieBannerHelperService',
        'ng2FormDomainScriptGuideService',
        '$timeout',
        'monDialog',
        'ng2MonEventsService',
        'DOMAIN_SCRIPT_GUIDE_EVENTS',
        'ng2MonModalService',
        'gettextCatalog',
        'Lodash',
    ];

    function DomainScriptGuideFeaturesCookieBannerController (
        CookieBannerHelperService,
        ng2FormDomainScriptGuideService,
        $timeout,
        monDialog,
        ng2MonEventsService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DOMAIN_SCRIPT_GUIDE_EVENTS,
        ng2MonModalService,
        gettextCatalog,
        Lodash,
    ) {
        var vm = this;
        const discardChangesConfirmationText = gettextCatalog.getString(
            'The settings form contains incorrect data. Close the form anyway?',
        );
        let cmSettingsFormIsValid = true;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.$onChanges = onChanges;
        vm.onEnabledChange = onEnabledChange;
        vm.canUseFeature = canUseFeature;
        vm.openConsentManagerScriptSettings = openConsentManagerScriptSettings;
        vm.dialogId;
        vm.listenerIds = [];

        function activate () {
            var firstTimeEnableCM = !(vm.cookieBannerModel && vm.cookieBannerModel.settings && vm.cookieBannerModel.settings.enabled);
            vm.cookieBannerModel.setSettings({
                firstTimeEnableCM: firstTimeEnableCM,
            });

            const updateConsentManagerSettingsEventId =
                ng2MonEventsService.addListener('updateConsentManagerSettings', (consentManagerSettings) => {
                    vm.cookieBannerModel = Object.assign(vm.cookieBannerModel, {
                        settings: Lodash.cloneDeep(consentManagerSettings),
                    });
                });
            vm.listenerIds.push(updateConsentManagerSettingsEventId);

            const closeConsentManagerDialogEventId = ng2MonEventsService.addListener('closeConsentManagerDialog', () => {
                if (Array.isArray(vm.dialogId)) {
                    monDialog.close(vm.dialogId[0]);
                } else {
                    monDialog.close(vm.dialogId);
                }
            });
            vm.listenerIds.push(closeConsentManagerDialogEventId);

            const cmSettingsFormValidStatusEventId =
                ng2MonEventsService.addListener('cmSettingsFormValidStatus', (valid) => {
                    cmSettingsFormIsValid = valid;
                });

            vm.listenerIds.push(cmSettingsFormValidStatusEventId);
        }

        function onChanges () {
            vm.settingEnabled = vm.cookieBannerModel && vm.cookieBannerModel.settings && vm.cookieBannerModel.settings.enabled;
        }

        function onEnabledChange () {
            ng2MonEventsService.run(DOMAIN_SCRIPT_GUIDE_EVENTS.FORM_UNSAVED_UPDATE);
            vm.settingEnabled = !vm.settingEnabled;
            $timeout(function () {
                vm.cookieBannerModel.setSettings({ enabled: vm.settingEnabled });
                updateDomainSettings();
            });
        }

        async function openConsentManagerScriptSettings () {
            var params = {
                body: 'consentManagerComponent',
                size: 'md',
            };
            ng2FormDomainScriptGuideService.setShowScript(false);
            var callback = function (settings) {
                if (settings) {
                    ng2MonEventsService.run(DOMAIN_SCRIPT_GUIDE_EVENTS.FORM_UNSAVED_UPDATE);
                    vm.cookieBannerModel.settings = ng2FormDomainScriptGuideService.getDomainSettings().cookie_banner;
                }
                ng2FormDomainScriptGuideService.setShowScript(true);
            };
            const onBeforeClose = () => new Promise((resolve) => {
                if (cmSettingsFormIsValid) {
                    resolve(true);
                } else {
                    ng2MonModalService.confirm(discardChangesConfirmationText).then(() => {
                        resolve(true);
                    }, () => {
                        resolve(false);
                    });
                }
            });

            vm.dialogId = await ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback, onBeforeClose });
            $timeout(() => {
                ng2MonEventsService.run('setupConsentManager', vm.cookieBannerModel.settings);
            });
        }

        function canUseFeature () {
            return CookieBannerHelperService.isAbleToSetupScript(vm.domain);
        }

        function onDestroy () {
            for (const listenerId of vm.listenerIds) {
                ng2MonEventsService.remove(listenerId);
            }
        }

        // PROTECTED

        function updateDomainSettings () {
            $timeout(function () {
                ng2FormDomainScriptGuideService.setCookieBannerEdit(vm.cookieBannerModel);
            });
        }
    }
})();
