(function () {
    'use strict';

    angular.module('modules.support', [
        'mon.dialog',
        'gettext',
        'core.constant',
        'services.entities',
        'modules.support.services',
    ]);
})();
