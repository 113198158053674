(function () {
    'use strict';

    angular.module('modules.page_fix.components').component('pageFixChangesetAccessibility', {
        templateUrl: 'app/modules/page-fix/components/changeset/accessibility/accessibility.html',
        controller: AutofixChangesetAccessibilityController,
        controllerAs: 'vm',
        bindings: {
            data: '<',
        },
    });

    AutofixChangesetAccessibilityController.$inject = ['gettextCatalog', 'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG'];
    /* @ngInject */
    function AutofixChangesetAccessibilityController (gettextCatalog, PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG) {
        var vm = this;
        vm.$onInit = activate;
        vm.hasDataOnTarget = hasDataOnTarget;
        vm.parseTargetCollection = parseTargetCollection;
        vm.getChangeDescription = getChangeDescription;

        function activate () {
            vm.popoverOpen = false;
        }

        function hasDataOnTarget () {
            return vm.data && vm.data.change.target;
        }

        function parseTargetCollection () {
            return JSON.parse(vm.data.change.target);
        }

        function getChangeDescription () {
            return getTranslation();
        }

        // PROTECTED

        function getTranslation () {
            var checkConfig = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[vm.data.change.check_id];
            var scopes = {
                value: vm.data.change.value,
            };

            if (checkConfig) {
                return gettextCatalog.getString(checkConfig.translation, scopes);
            }

            return vm.data.change.value;
        }
    }
})();
