import { IssueStatusEnum } from '@client/app/modules/common/enum/issue-status.enum';
import { PAGE_FIX_FIXABLE_TYPES } from '@client/app/modules/page-fix/constants/page-fix-fixable-types';
import { PageFixModelHelper } from '@client/app/modules/page-fix/services/model-helper/page-fix-model-helper';

export type LinkType = {
    id: number,
    domain_id: number,
    url: string,
    mime_type: string | null,
    type: string,
    status: IssueStatusEnum,
    relation: {
        rel: number,
    },
    redirect: boolean,
    status_code: number,
    accessibility_reviewed: boolean,
    html_pages_count: number,
    documents_count: number,
    dead_on_pages_count: number
    dead_on_documents_count: number,
    ignored_on_pages_count: number,
    ignored_on_documents_count: number,
    fixed_on_pages_count: number,
    fixed_on_documents_count: number,
    groups: number[] | null,
    content_length: number,
    lastcheck: string,
    created_at: string,
    updated_at: string,

}

export type PageLinkType = {
    id: number,
    page_id: number,
    link_id: number,
    status: IssueStatusEnum,
    text: string,
    title_alt: string,
    orig_url: string,
    no_follow: boolean,
    rel: string | null,
    lastcheck: string,
    created_at: string,
    updated_at: string,
    link: LinkType
}

export interface PageLinkModel extends PageLinkType {
    getPageFixType(): string;
    getPageFixSelector(): string;
}
export class PageLinkModel {
    constructor (input: PageLinkType) {
        Object.assign(this, input);
    }

    getPageFixType (): string {
        return PAGE_FIX_FIXABLE_TYPES.link;
    }

    getPageFixSelector (): string {
        if (this.link.type === 'link') {
            return PageFixModelHelper.getSelectorValue('<a href="' + this.link.url + '"></a>');
        } else {
            return PageFixModelHelper.getSelectorValue('<img href="' + this.link.url + '"/>');
        }
    }

}
