import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MonPromptService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import { Report } from '@monsido/modules/models/api/report';
import { SessionService } from '@monsido/core/session/session.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { MonLogService } from '@monsido/services/mon-log-service/mon-log.service';
import { EnhancedReport } from '../types';
import { ScheduleReportAdminComponent } from '../../dialogs/schedule-report-admin/schedule-report-admin.component';
import { SendMeReportFormComponent } from '../../dialogs/send-me-report-form/send-me-report-form.component';
import { ScheduleReportFormComponent } from '../../dialogs/schedule-report-form/schedule-report-form.component';
import { DialogOptionsAdapter } from '@monsido/ng2/core/modules/dialog-container/dialog-container.component';
import { ReceivedReportsContainerComponent } from '../../dialogs/received-reports-container/received-reports-container.component';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { MeReportingsRepoService } from '@client/app/services/api/me-reportings-repo/me-reportings-repo.service';
import { MeService } from '@client/app/services/entities/me/me.service';

@Component({
    selector: 'mon-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
    isAdmin?: boolean;
    @Input() report?: EnhancedReport;
    @Input() isReceivingReport?: boolean;
    @Output() fetchReceivedReports = new EventEmitter<void>();
    @Output() getPage = new EventEmitter<void>();

    constructor (
        private sessionService: SessionService,
        private eventService: MonEventService,
        private promptService: MonPromptService,
        private translateService: TranslateService,
        private logger: MonLogService,
        private meReportingsRepoService: MeReportingsRepoService,
        private meService: MeService,
    ) {
    }

    ngOnInit (): void {
        this.isAdmin = this.checkIsAdmin();
    }

    checkIsAdmin (): boolean {
        return this.sessionService.hasRole('customer_admin');
    }

    sendMeReport () {
        if (!this.report) {
            this.logger.warn('The report is not defined');
        } else {
            const params = {
                template_id: this.report.template.id,
            };

            this.meReportingsRepoService.create(params)
                .then(() => this.showGeneratingReportCustomDialog(), () => {
                });
        }
    }

    openReportAdministration () {
        const params = {
            component: ScheduleReportAdminComponent,
            data: { report: this.report },
            dialogOptions: {
                size: 'fw',
                cb: (data: Report) => {
                    if (data && !isEqual(data, this.report)) {
                        this.getPage.emit();
                    }
                },
            },
        };

        this.eventService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
    }

    openSendMeReport () {
        const callback = (data) => {
            if (data && isObject(data)) {
                this.showGeneratingReportCustomDialog();
            }
        };

        this.eventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: SendMeReportFormComponent,
            data: { report: this.report },
            dialogOptions: {
                cb: callback,
                size: 'sm',
            },
        });
    }

    sendReport () {
        const params = {
            component: ScheduleReportFormComponent,
            data: { report: this.report },
            dialogOptions: {
                cb: (data: Report) => {
                    if (data && !isEqual(data, this.report)) {
                        this.getPage.emit();
                    }
                },
                size: 'sm',
            },
        };

        this.eventService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
    }

    deleteReport () {
        this.promptService.confirm(
            this.translateService.getString('Are you sure you want to stop receiving this report?'),
        )
            .then(() => this.report?.id ? this.meService.deleteReport(this.report.id) : null)
            .then(() => this.getPage.emit())
            .catch(() => {
            });
    }

    showAllReceivedReports () {
        const options: DialogOptionsAdapter<ReceivedReportsContainerComponent> = {
            component: ReceivedReportsContainerComponent,
            dialogOptions: {
                size: 'sm',
                cb: () => this.getPage.emit(),
            },
        };

        this.eventService.run(MON_EVENTS.LOAD_NG2_DIALOG, options);
    }

    showGeneratingReportCustomDialog () {
        this.promptService.custom({
            message: this.translateService.getString(
                'Your report is currently being generated. You will receive an email when the report is ready\n\nYou can see the current progress under the \'Received reports\' box to the right',
            ),
            title: this.translateService.getString('Generating report'),
            buttons: [
                {
                    label: this.translateService.getString('Open received reports'),
                    className: 'btn btn-default',
                    callback: () =>
                        this.showAllReceivedReports(),
                    value: 'success',
                },
                {
                    label: this.translateService.getString('OK'),
                    className: 'btn btn-primary',
                    callback: () => this.fetchReceivedReports.emit(),
                    value: 'cancel',
                },
            ],
        })
            .catch(() => {
            });
    }
}
