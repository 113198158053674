(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyPreContentRuleWithoutContext', {
        templateUrl: 'app/forms/global-policy/steps/pre-content/rules/without-context.html',
        controller: PolicyPreContentRuleWithoutContextController,
        controllerAs: 'vm',
        require: {
            contentController: '^formGlobalPolicyPreContent',
        },
    });

    PolicyPreContentRuleWithoutContextController.$inject = ['monDialog'];
    /* @ngInject */
    function PolicyPreContentRuleWithoutContextController (monDialog) {
        var vm = this;

        vm.onModelChange = onModelChange;
        vm.$onInit = activate;
        vm.addRow = addRow;
        vm.removeRow = removeRow;

        function activate () {
            vm.ngModel = [{ word: '', context: '' }];
        }

        function onModelChange () {
            var rules = [];
            angular.forEach(vm.ngModel, function (row) {
                rules.push({
                    name: 'Find word: ' + row.word + ' not followed by context: ' + row.context,
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: row.word + '\\s(?!' + row.context + ')',
                    type: 'page_text',
                    sub_entry: '',
                });
            });
            vm.contentController.ngModel = rules;
        }

        function addRow () {
            vm.ngModel.push({ word: '', context: '' });
        }

        function removeRow (row) {
            var index = vm.ngModel.indexOf(row);
            if (index !== -1) {
                vm.ngModel.splice(index, 1);
            }
        }
    }
})();
