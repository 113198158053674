import { Component } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { DialogOptionsAdapter } from '@monsido/ng2/core/modules/dialog-container/dialog-container.component';
import { ReceivedReportsContainerComponent } from '../../dialogs/received-reports-container/received-reports-container.component';

@Component({
    selector: 'mon-received-reports',
    templateUrl: './received-reports.component.html',
    styleUrls: ['./received-reports.component.scss'],
})
export class ReceivedReportsComponent {
    total: number = 0;
    rowAmount: number = 5;
    constructor (
        private eventService: MonEventService,
    ) {}

    setTotal (total: number): void {
        this.total = total;
    }

    openReceivedReportsDialog (): void {
        const options: DialogOptionsAdapter<ReceivedReportsContainerComponent> = {
            component: ReceivedReportsContainerComponent,
            dialogOptions: {
                size: 'sm',
            },
        };
        this.eventService.run(MON_EVENTS.LOAD_NG2_DIALOG, options);
    }

}
