(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSourcesSocial', {
        templateUrl: 'app/pages/statistics/sources/social/social.html',
        controller: StatisticsSourcesSocial,
        controllerAs: 'vm',
    });
    StatisticsSourcesSocial.$inject = [
        'ng2StatsRepoService',
        'API_CONSTANTS',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        '$filter',
        'ng2SessionService',
        'dateRangeService',
    ];
    /* @ngInject */

    function StatisticsSourcesSocial (
        ng2StatsRepoService,
        API_CONSTANTS,
        $stateParams,
        ParamService,
        gettextCatalog,
        $filter,
        ng2SessionService,
        dateRangeService,
    ) {
        var vm = this;

        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.updateDateRange = updateDateRange;
        vm.$onInit = activate;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.totalVisits = 0;
            vm.summary = {};
            vm.chartSeries = [gettextCatalog.getString('Visits')];
            vm.chartLabels = [];
            vm.chartData = [];
            vm.chartOptions = {
                legend: {
                    display: false,
                    position: 'bottom',
                    fullWidth: true,
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
            vm.date = dateRangeService.setRangeDates();
            vm.loading = false;
            vm.pages = [];

            vm.chartOverride = [
                {
                    borderColor: ['#36A2EB', '#36A2EB', '#36A2EB', '#36A2EB'],
                    backgroundColor: [
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderWidth: 1,
                },
            ];

            updateDateRange();
        }

        function getPage () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };

            vm.loading = true;

            vm.promise = ng2StatsRepoService.getSocials(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    vm.chartLabels = vm.pages.map(function (page) {
                        return page.label;
                    });
                    vm.chartData = [
                        vm.pages.map(function (page) {
                            return page.visits;
                        }),
                    ];
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });

            getSummary(params);
        }

        function pageChange (page) {
            vm.page = page;
            getPage();
        }

        function perPageChange (pageSize) {
            vm.pageSize = pageSize;
            getPage();
        }

        function updateDateRange () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
            });
            getPage();
        }

        function getSummary (params) {
            params.type = 'social';
            return ng2StatsRepoService.getVisitors(params).then(function (data) {
                var fields = ['visits', 'uniq_visitors', 'bounce_rate', 'avg_time'];
                for (var i = 0; i < fields.length; i++) {
                    vm.summary[fields[i]] = 0;
                    if (Array.isArray(data)) {
                        for (var j = 0; j < data.length; j++) {
                            vm.summary[fields[i]] += parseInt(data[j][fields[i]]);
                        }
                    }
                }
            }, angular.noop);
        }
    }
})();
