(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsLog', {
        templateUrl: 'app/pages/statistics/visitors/log/log.html',
        controller: StatisticsVisitorsController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsController.$inject = [];
    /* @ngInject */
    function StatisticsVisitorsController () {
        var vm = this;

        vm.$onInit = activate;
        vm.listItem = [];
        vm.focusItem = undefined;
        vm.onViewItem = onViewItem;
        vm.hideItem = hideItem;

        function activate () {}

        function onViewItem (item) {
            vm.focusItem = item;
        }

        function hideItem () {
            vm.focusItem = undefined;
        }
    }
})();
