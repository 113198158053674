(function () {
    'use strict';
    /**
     * @memberof services
     * @ngdoc factory
     * @name PagesFilesPagesService
     * @description PagesFilesPagesService service contains a helper functions that can customize page-header based on file pageType.
     */
    angular.module('app.components.pages').factory('PagesFilesPagesService', PagesFilesPagesService);

    PagesFilesPagesService.$inject = ['gettextCatalog'];

    /* @ngInject*/
    function PagesFilesPagesService (gettextCatalog) {
        return {
            getHeaderTranslation: getHeaderTranslation,
        };

        function getHeaderTranslation (linkType, pageType) {
            if (pageType === 'document') {
                switch (linkType) {
                    case 'slides':
                        return gettextCatalog.getString('Documents with this slide');
                    case 'pdf':
                        return gettextCatalog.getString('Documents with this PDF');
                    case 'spreadsheet':
                        return gettextCatalog.getString('Documents with this spreadsheet');
                    case 'archive':
                        return gettextCatalog.getString('Documents with this archive file');
                    case 'text':
                        return gettextCatalog.getString('Documents with this text file');
                    default:
                        return gettextCatalog.getString('Documents with this {{type}}', { type: linkType });
                }
            } else {
                switch (linkType) {
                    case 'slides':
                        return gettextCatalog.getString('Pages with this slide');
                    case 'pdf':
                        return gettextCatalog.getString('Pages with this PDF');
                    case 'spreadsheet':
                        return gettextCatalog.getString('Pages with this spreadsheet');
                    case 'archive':
                        return gettextCatalog.getString('Pages with this archive file');
                    case 'text':
                        return gettextCatalog.getString('Pages with this text file');
                    default:
                        return gettextCatalog.getString('Pages with this {{type}}', { type: linkType });
                }
            }
        }
    }
})();
