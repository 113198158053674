import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { PageAccessibilityRepoCheckType, Responsibility } from '@client/app/services/api/page-accessibility/page-accessibility-repo.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { DemoDataDecisionTreeType } from '../../demo-supportcenter-accessibility-checks-repo/data-decision-tree.demo';
import { DemoSupportCenterAccessibilityChecksRepoService } from '../../demo-supportcenter-accessibility-checks-repo/demo-support-center-accessibility-checks-repo.service';

type SupportAccessibilityChecksRepoGetAllType = {
    id: number,
    check_id: number,
    description: string,
    friendly_name: string,
    impact: 'minor' | 'moderate' | 'serious',
    responsibilities: Responsibility[],
    html_element: string,
    name: string,
    difficulty: number,
    tags: string[],
    quick_guide: string,
    short_description: string,
    how_to_repair: string,
    pass_examples: string,
    fail_examples: string,
    others: unknown[],
    locations: string[],
    mobile: false,
    article_url: string
};

@Injectable({
    providedIn: 'root',
})
export class SupportAccessibilityChecksRepoService extends BaseRepo {
    constructor (
        protected sessionService: SessionService,
        private apiService: ApiService,
        private demoSupportCenterAccessibilityChecksRepoService: DemoSupportCenterAccessibilityChecksRepoService,
    ) {
        super();
    }

    getAll (params: BaseRepoRequestType & { check_id: number }): Promise<MonTableCollection<SupportAccessibilityChecksRepoGetAllType>> {
        params = params || {};
        return this.apiService.supportClientGet('accessibility_checks', params);
    }


    get (accessibilityCheckId: number, params: BaseRepoRequestType): Promise<SupportAccessibilityChecksRepoGetAllType> {
        params = params || {};
        return this.apiService.supportClientGet('accessibility_checks/' + accessibilityCheckId, params);
    }

    getDecisionTree (check: PageAccessibilityRepoCheckType): Promise<DemoDataDecisionTreeType | null> {
        return this.demoSupportCenterAccessibilityChecksRepoService.getDecisionTree(check);
    }
}
