(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSourcesSummary', {
        templateUrl: 'app/pages/statistics/sources/summary/summary.html',
        controller: StatisticsSourcesSummary,
        controllerAs: 'vm',
    });
    StatisticsSourcesSummary.$inject = [
        'ParamService',
        'ng2StatsRepoService',
        '$filter',
        'gettextCatalog',
        'moment',
        'ng2SessionService',
        '$deepmerge',
        'dateRangeService',
    ];
    /* @ngInject */

    function StatisticsSourcesSummary (
        ParamService,
        ng2StatsRepoService,
        $filter,
        gettextCatalog,
        moment,
        ng2SessionService,
        $deepmerge,
        dateRangeService,
    ) {
        const SOURCE_TYPES = ['organic', 'direct', 'referring', 'social', 'paid'];
        const vm = this;
        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;
        vm.setChartData = setChartData;

        async function activate () {
            vm.domain = ng2SessionService.domain;
            vm.itemReferringInformation = {
                externalReffering: {
                    header: gettextCatalog.getString('External referring domains'),
                    headerIcon: '',
                    footer: gettextCatalog.getString('External referees overview'),
                    labels: [],
                    footerUrl: 'base.customer.domain.statistics.sources.domains',
                },
                searchEngines: {
                    header: gettextCatalog.getString('Search engines'),
                    headerIcon: '',
                    footer: gettextCatalog.getString('search engines overview'),
                    labels: [],
                    footerUrl: 'base.customer.domain.statistics.sources.searchEngines',
                },
                social: {
                    header: gettextCatalog.getString('Social Networks'),
                    headerIcon: '',
                    footer: gettextCatalog.getString('social network overview'),
                    labels: [],
                    footerUrl: 'base.customer.domain.statistics.sources.social',
                },
            };
            vm.type = 'visits';
            vm.remoteData = [];
            vm.totalVisits = 0;
            vm.date = dateRangeService.setRangeDates();

            setupChartSettings();
            await updateDateRange();
        }

        async function updateDateRange () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
            });
            await setupChart();
            setupReferrersBoxes();
        }

        function setupChartSettings () {
            vm.lineChartSeries = [
                gettextCatalog.getString('Search engine'),
                gettextCatalog.getString('Direct visits'),
                gettextCatalog.getString('Referring visits'),
                gettextCatalog.getString('Social visits'),
                gettextCatalog.getString('Campaign visits'),
            ];
            vm.lineChartData = [];
            vm.lineChartColors = ['#57BCDB', '#57B754', '#EAA646', '#9500AC', '#D24747'];
            vm.lineChartLabels = [];
            vm.lineChartOverride = [];
            vm.lineChartOptions = {
                tooltips: {
                    mode: 'label',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            if (vm.type === 'avgVisitTime') {
                                return (
                                    data.datasets[tooltipItem.datasetIndex].label +
                                    ': ' +
                                    $filter('numeraljs')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0, '00:00:00')
                                );
                            } else if (vm.type === 'bounceRate') {
                                return (
                                    data.datasets[tooltipItem.datasetIndex].label +
                                    ': ' +
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
                                    '%'
                                );
                            }
                            return (
                                data.datasets[tooltipItem.datasetIndex].label +
                                ': ' +
                                $filter('numeraljs', '0,0')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0)
                            );
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    if (vm.type === 'avgVisitTime') {
                                        return $filter('numeraljs')(value, '00:00:00');
                                    } else if (vm.type === 'bounceRate') {
                                        return parseInt(value) + '%';
                                    }
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                            stacked: true,
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            stacked: true,
                        },
                    ],
                },
            };
        }

        function setupChart () {
            const params = {
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };
            const promises = [getTotalVisits(params)];
            for (let j = 0; j < SOURCE_TYPES.length; j++) {
                promises.push(getVisitorsForType(SOURCE_TYPES[j], params));
            }
            vm.loading = true;
            return Promise.all(promises)
                .then(function () {
                    setChartData(vm.type);
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function setChartData (type) {
            vm.type = type;
            vm.lineChartData = [];
            const remoteDataParameter = SOURCE_TYPES.reduce(function (param, key) {
                if (vm.remoteData[key].length > vm.remoteData[param].length) {
                    return key;
                }
                return param;
            }, 'referring');
            const visitParameter = mapType(type);
            SOURCE_TYPES.forEach(function (remoteDataParam) {
                // Setup chart data for each statistics type
                if (Array.isArray(vm.remoteData[remoteDataParam])) {
                    vm.lineChartData.push(
                        vm.remoteData[remoteDataParam].map(function (data) {
                            if (vm.type === 'bounceRate') {
                                return bounceRateCalculate(data);
                            } else {
                                return parseInt(data[visitParameter], 10) || 0;
                            }
                        }),
                    );
                } else {
                    vm.lineChartData.push([]);
                }
            });
            if (vm.type === 'bounceRate' || vm.type === 'avgVisitTime') {
                setLineChart();
            } else {
                setBarChart();
            }
            vm.lineChartLabels = vm.remoteData[remoteDataParameter].map(function (val) {
                // Setup labels
                return moment(val.date).format('ll');
            });
        }

        function mapType (type) {
            switch (type) {
                case 'uniqueVisits':
                    return 'uniq_visitors';
                case 'pageViews':
                    return 'pageviews';
                case 'avgVisitTime':
                    return 'avg_time';
                case 'bounceRate':
                    return 'bounce_rate';
                default:
                    return type;
            }
        }

        function setLineChart () {
            vm.lineChartOverride = [];
            for (let i = 0; i < vm.lineChartSeries.length; i++) {
                vm.lineChartOverride.push({
                    type: 'line',
                    fill: false,
                    borderColor: vm.lineChartColors[i],
                    borderWidth: 1,
                    lineTension: 0,
                });
            }
            vm.lineChartOptions.scales.yAxes[0].stacked = false;
        }

        function setBarChart () {
            vm.lineChartOverride = [];
            for (let i = 0; i < vm.lineChartSeries.length; i++) {
                vm.lineChartOverride.push({
                    type: 'bar',
                    fill: false,
                    borderColor: vm.lineChartColors[i],
                    borderWidth: 1,
                });
            }
            vm.lineChartOptions.scales.yAxes[0].stacked = true;
        }

        function bounceRateCalculate (data) {
            data = data || {};
            if (isNaN(+data.visits) || isNaN(+data.bounce_rate) || +data.bounce_rate === 0 || +data.visits === 0) {
                return 0;
            }

            return parseInt((+data.bounce_rate * 100) / +data.visits, 10);
        }

        function getVisitorsForType (type, params) {
            return ng2StatsRepoService.getVisitors($deepmerge(params, { type: type })).then(
                function (data) {
                    vm.remoteData[type] = data;
                },
                function () {
                    vm.remoteData[type] = [];
                },
            );
        }

        function setupReferrersBoxes () {
            const params = {
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
                limit: 3,
            };

            ng2StatsRepoService.getReferrers(params).then(function (data) {
                vm.itemReferringInformation.externalReffering.data = data;
            }, angular.noop);

            ng2StatsRepoService.getSearchEngines(params).then(function (data) {
                vm.itemReferringInformation.searchEngines.data = data;
            }, angular.noop);

            ng2StatsRepoService.getSocials(params).then(function (data) {
                vm.itemReferringInformation.social.data = data;
            }, angular.noop);
        }

        function getTotalVisits (params) {
            return ng2StatsRepoService.getSummary(params).then(function (data) {
                vm.totalVisits = parseInt(data.visits) || 0;
            }, angular.noop);
        }
    }
})();
