export const ACCESSIBILITY_HELP_CENTER_TAG = {
    screen_reader: {
        translation: 'Screen Reader',
        styleClass: '',
    },
    keyboard: {
        translation: 'Keyboard',
        styleClass: '',
    },
    visually_impaired: {
        translation: 'Visually impaired',
        styleClass: '',
    },
    blind: {
        translation: 'Blind',
        styleClass: '',
    },
    color_blind: {
        translation: 'Color blind',
        styleClass: '',
    },
    physically_impaired: {
        translation: 'Physically impaired',
        styleClass: '',
    },
    official: {
        translation: 'Official',
        styleClass: '',
    },
    recommended: {
        translation: 'Recommended',
        styleClass: '',
    },
} as const;
