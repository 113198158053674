<button class="mon-button" 
    [type]="monType" 
    [attr.role]="monRole ? monRole : null"
    [attr.aria-label]="monAriaLabel"
    [attr.aria-labelledby]="monAriaLabeledBy"
    [attr.aria-describedby]="monAriaDescribedBy"
    [attr.aria-controls]="monAriaControls"
    [attr.aria-haspopup]="monAriaHasPopup"
    [attr.aria-pressed]="monPressed != null ? monPressed : null"
    [disabled]="monDisabled"
    [ngbTooltip]="monTooltip"
    >
    <span class="mon-button-wrapper">
        <span class="mon-button-icon-wrapper left" *ngIf="monCustomButtonImage">
            <img [class]="monCustomButtonImageClass" [src]="monCustomButtonImage" alt="">            
        </span>
        <span class="mon-button-icon-wrapper left" *ngIf="monLeftIcon">            
            <mon-icon [monIconDirect]="monLeftIcon" *ngIf="monLeftIcon" [spin]="monSpinLeftIcon ? true : false"></mon-icon>
        </span>
        <span class="mon-button-text-wrapper">
            <ng-content></ng-content>
        </span>
        <span class="mon-button-icon-wrapper right" *ngIf="monRightIcon">
            <mon-icon [monIconDirect]="monRightIcon" [spin]="monSpinRightIcon ? true : false"></mon-icon>
        </span>
    </span>
</button>
