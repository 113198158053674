import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { ArgsInterface, ToastService, IndividualConfig } from '@angular-shared-components';
import { Customer } from '@monsido/ng2/modules/models/api/customer';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { AccountRepoService } from '@client/app/services/api/account-repo/account-repo.service';

@Injectable({
    providedIn: 'root',
})
export class AccountSettingsService {
    constructor (
        private sessionService: SessionService,
        private translateService: TranslateService,
        private accountRepo: AccountRepoService,
        private toasterService: ToastService,
    ) {}

    public updateCustomer (data): Promise<Customer> {
        return this.accountRepo.update(data);
    }

    public getString (text: string, args?: ArgsInterface, context?: string): string {
        return this.translateService.getString(text, args, context);
    }

    public getCustomer (): Promise<Customer> {
        return this.accountRepo.get();
    }

    public getSessionCustomer (): Customer | null {
        return this.sessionService.customer;
    }

    public setSessionCustomer (customer: Customer): void {
        this.sessionService.customer = customer;
    }

    public success (title: string, message?: string, override?: Partial<IndividualConfig>): void {
        this.toasterService.success(title, message, override);
    }

    public error (title: string, message?: string, override?: Partial<IndividualConfig>): void {
        this.toasterService.error(title, message, override);
    }
}
