(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyPreContentRuleRepeatedShortWords', {
        templateUrl: 'app/forms/global-policy/steps/pre-content/rules/repeated-short-words.html',
        controller: PolicyPreContentRuleRepeatedShortWordsController,
        controllerAs: 'vm',
        require: {
            contentController: '^formGlobalPolicyPreContent',
        },
    });

    PolicyPreContentRuleRepeatedShortWordsController.$inject = ['monDialog'];
    /* @ngInject */
    function PolicyPreContentRuleRepeatedShortWordsController (monDialog) {
        var vm = this;

        vm.onModelChange = onModelChange;
        vm.$onInit = activate;

        function activate () {
            vm.ngModel = null;
        }

        function onModelChange () {
            vm.contentController.ngModel = [
                {
                    name: 'Repeated words of ' + parseInt(vm.ngModel) + ' characters long or shorter',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '\\p{Space}(\\p{Word}{,' + parseInt(vm.ngModel) + '})\\p{Space}\\1',
                    type: 'page_text',
                    sub_entry: '',
                },
            ];
        }
    }
})();
