import { Component, Input } from '@angular/core';

@Component({
    selector: 'mon-data-privacy-severity-level',
    templateUrl: './severity-level.component.html',
    styleUrls: ['./severity-level.component.scss'],
})
export class MonDataPrivacySeverityLevelComponent {
    @Input() monPriority: string = '';
}
