export const INFOTYPE_GROUPS = [
    {
        name: 'Banking data',
        value: 'banking_data',
    },
    {
        name: 'General personal data',
        value: 'general_personal_data',
    },
    {
        name: 'Healthcare',
        value: 'healthcare',
    },
    {
        name: 'National ID',
        value: 'national_id',
    },
    {
        name: 'Product identifier',
        value: 'product_identifier',
    },
    {
        name: 'Personal technical',
        value: 'personal_technical',
    },
    {
        name: 'Sensitive personal data',
        value: 'sensitive_personal_data',
    },
    {
        name: 'Taxpayer ID',
        value: 'taxpayer_id',
    },
    {
        name: 'Technical',
        value: 'technical',
    },
    {
        name: 'Other',
        value: 'other',
    },
];
