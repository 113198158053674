(function () {
    'use strict';

    angular.module('services.api').factory('NiNumbersRepo', NiNumbersRepo);

    NiNumbersRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function NiNumbersRepo (ng2ApiService, ng2SessionService) {
        var service = {
            find: find,
            get: get,
            update: update,
        };

        return service;

        // //////////////////////////////

        function find (id, params) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/ni_numbers/' + id, params);
        }

        function get (params) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/ni_numbers', params);
        }

        function update (id, data, params) {
            data = data || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/ni_numbers/' + id, data, params);
        }
    }
})();
