(function () {
    'use strict';
    /**
     * @memberof modules.fastTrack
     * @ngdoc service
     * @name FastTrackService
     * @description Manages stored variables in Fast Track Session
     */
    angular.module('modules.fastTrack').service('FastTrackService', FastTrackService);

    FastTrackService.$inject = ['AccessibilityFastTrackRepo'];
    /* @ngInject*/
    function FastTrackService (AccessibilityFastTrackRepo) {
        var service = {
            getFastTrack: getFastTrack,
            getAllChecks: getAllChecks,
            markCheckAsFixed: markCheckAsFixed,
            updateCheck: updateCheck,
        };

        return service;

        function getFastTrack (id) {
            return AccessibilityFastTrackRepo.get(id);
        }

        function getAllChecks (id) {
            return AccessibilityFastTrackRepo.getAllChecks(id);
        }

        function markCheckAsFixed (domain_id, source_code_id, check_id) {
            return AccessibilityFastTrackRepo.markCheckAsFixed(domain_id, source_code_id, check_id);
        }

        function updateCheck (acc_id, check) {
            return AccessibilityFastTrackRepo.updateCheck(acc_id, check);
        }
    }
})();
