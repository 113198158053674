import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    intercept (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            map(resp => {
                if (resp instanceof HttpResponse) {
                    const data = resp.clone();
                    // TODO: Add api constants

                    if (resp.headers.get('x-total')) {
                        data.body.total = parseInt(resp.headers.get('x-total') || '0', 10);
                        data.body.currentPage = parseInt(resp.headers.get('x-page') || '1', 10);
                        data.body.pageHits = parseInt(resp.headers.get('x-page-hits') || '0', 10);
                        data.body.perPage = parseInt(resp.headers.get('x-per-page') || '10', 10);
                    }

                    return data;
                } else {
                    return resp;
                }
            }),
        );
    }
}
