(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilitySummaryLineChart', {
        templateUrl: 'app/pages/accessibility/dashboard/summary/line-chart/line-chart.html',
        controller: AccessibilityDashboardSummaryLineChartController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            data: '<',
            options: '<',
        },
    });

    AccessibilityDashboardSummaryLineChartController.$inject = ['AccessibilityLineChartService', 'ng2DateTimeService'];
    /* @ngInject */
    function AccessibilityDashboardSummaryLineChartController (AccessibilityLineChartService, ng2DateTimeService) {
        var vm = this;
        vm.$onInit = activate;
        vm.chartLoaded = false;

        function activate () {
            setupLineChart();
        }

        function setupLineChart () {
            var service = new AccessibilityLineChartService();
            service.setup().then(function () {
                vm.colors = service.getColors();
                vm.labels = setupLabels();
                vm.overrides = service.getOverride();
                vm.series = service.getSeries();
                vm.chartLoaded = true;
            }, angular.noop);
        }

        function setupLabels () {
            var labels = vm.history.map(function (crawl) {
                return new Date(crawl.date);
            });
            if (labels.length > 0) {
                labels.unshift(ng2DateTimeService.substractDuration(labels[0], 2, 'days'));
                labels.push(ng2DateTimeService.addDuration(labels[labels.length - 1], 2, 'days'));
            }

            return labels;
        }
    }
})();
