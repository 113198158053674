(function () {
    'use strict';

    angular.module('modules.tracking').factory('matomoService', matomoService);

    matomoService.$inject = ['$window', 'ng2SessionService'];
    /* @ngInject*/
    function matomoService ($window, ng2SessionService) {

        var activateTracking = false;
        return {
            trackPageView: trackPageView,
            trackEvent: trackEvent,
            setTrackingInfo: setTrackingInfo,
        };

        function trackEvent (url, type, ref) {
            if (activateTracking) {
                url = removeCustomAndDomainId(removeOrigin(url));
                ref = removeCustomAndDomainId(removeOrigin(ref));
                if (ng2SessionService.customer && ng2SessionService.customer.name) {
                    if (ng2SessionService.me && ng2SessionService.me.email) {
                        $window._paq.push(['setCustomDimension', 2, ng2SessionService.customer.id]);
                        $window._paq.push(['setCustomDimension', 1, ng2SessionService.me.id]);
                        $window._paq.push(['setUserId', ng2SessionService.customer.id + '_' + ng2SessionService.me.id]);
                    }
                }
                $window._paq.push(['trackEvent', type, url, ref]);
            }
        }

        function trackPageView (url, title, ref, virtual, params) {
            if (activateTracking) {
                url = cleanupUrl(url);
                $window._paq.push(['setCustomUrl', url]);
                $window._paq.push(['setDocumentTitle', title]);

                if (ng2SessionService.customer && ng2SessionService.customer.name) {
                    if (ng2SessionService.me && ng2SessionService.me.email) {
                        $window._paq.push(['setCustomDimension', 2, ng2SessionService.customer.id]);
                        $window._paq.push(['setCustomDimension', 1, ng2SessionService.me.id]);
                        $window._paq.push(['setUserId', ng2SessionService.customer.id + '_' + ng2SessionService.me.id]);
                    }
                }
                if (ref) {
                    ref = cleanupUrl(ref);
                    $window._paq.push(['setReferrerUrl', ref]);
                }
                $window._paq.push(['trackPageView']);
            }
        }

        function setTrackingInfo (settings) {
            if (!ng2SessionService.isSudo) {
                var _paq = (window._paq = window._paq || []);
                _paq.push(['setTrackerUrl', settings.matomoUrl]);
                _paq.push(['setSiteId', settings.matomoSiteId]);
                _paq.push(['enableLinkTracking']);
                _paq.push(['disableCookies']);
                var d = document;
                var g = d.createElement('script');
                var s = d.getElementsByTagName('script')[0];
                g.nonce = '87b65f78ab';
                g.async = true;
                g.src = settings.matomoScriptUrl;
                s.parentNode.insertBefore(g, s);
                activateTracking = true;
            }
        }

        function cleanupUrl (url) {
            return addOrigin(removeCustomAndDomainId(removeOrigin(url)));
        }

        function addOrigin (url) {
            return $window.location.origin + url;
        }

        function removeOrigin (url) {
            return url.replace($window.location.origin, '');
        }

        function removeCustomAndDomainId (url) {
            return url
                .replace(/^\/(\d+)\//, '/{CustomerId}/')
                .replace(/^\/{CustomerId}\/domains\/(\d+)/, '/{CustomerId}/domains/{DomainId}');
        }
    }
})();
