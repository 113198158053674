(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormCustom', {
        templateUrl: 'app/modules/page-fix/forms/custom/custom.html',
        controller: AutofixListController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    AutofixListController.$inject = [
        '$deepmerge',
        'PAGE_FIX_SELECTOR_QUERIES',
        'PAGE_FIX_CHANGE_TYPES',
        'ng2PageFixRepoService',
    ];
    /* @ngInject */
    function AutofixListController ($deepmerge, PAGE_FIX_SELECTOR_QUERIES, PAGE_FIX_CHANGE_TYPES, ng2PageFixRepoService) {
        var vm = this;
        vm.$onInit = activate;
        vm.cancel = cancel;
        vm.submit = submit;

        function activate () {
            vm.model = $deepmerge({}, vm.resolve.pageFix || {});
        }

        function submit () {
            var model = {
                change_type: PAGE_FIX_CHANGE_TYPES.word,
                selector: getSelectorValue(),
                change: {
                    value: vm.model.change.value,
                },
            };
            var promise;

            if (!vm.resolve.pageFix) {
                promise = ng2PageFixRepoService.create(model);
            } else {
                promise = ng2PageFixRepoService.update(vm.resolve.pageFix, model);
            }

            promise.then(function (pageFix) {
                closeModal(pageFix);
            }, angular.noop);
        }

        function cancel () {
            vm.dismiss();
        }

        function closeModal (result) {
            vm.close({ $value: result });
        }

        // PROTECTED

        function getSelectorValue () {
            return PAGE_FIX_SELECTOR_QUERIES.tree_walker + ';' + vm.model.custom_selector;
        }
    }
})();
