(function () {
    'use strict';

    angular.module('filters.monsido').filter('monCharacterLimit', CharacterLimitController);

    CharacterLimitController.$inject = [];
    /* @ngInject */
    function CharacterLimitController () {
        return characterLimit;

        // //////////

        function characterLimit (word, limit) {
            if (!word) {
                return;
            }
            if (!limit) {
                return word;
            }
            var ext = word.length > limit ? '...' : '';
            return word.substring(0, limit) + ext;
        }
    }
})();
