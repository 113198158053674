(function () {
    'use strict';
    angular.module('blocks.helpers').service('pagesTabsHelper', pagesTabsHelper);

    pagesTabsHelper.$inject = ['ng2SessionService', '$location', '$filter', 'ng2MonEventsService', 'gettextCatalog'];
    /* @ngInject*/
    function pagesTabsHelper (ng2SessionService, $location, $filter, ng2MonEventsService, gettextCatalog) {
        var tabs = [
            {
                name: gettextCatalog.getString('All'),
                faIcon: $filter('monIcons')(['TABS', 'PAGES', 'ALL']),
                value: null,
            },
            {
                name: gettextCatalog.getString('Pages'),
                faIcon: $filter('monIcons')(['TABS', 'PAGES', 'HTML']),
                value: 'html',
            },
            {
                name: gettextCatalog.getString('PDF Documents'),
                faIcon: $filter('monIcons')(['TABS', 'PAGES', 'PDF']),
                value: 'pdf',
            },
            {
                name: gettextCatalog.getString('Other Documents'),
                faIcon: $filter('monIcons')(['TABS', 'PAGES', 'DOCUMENT']),
                value: 'document',
            },
        ];
        var availableTabs = tabs;
        var scanDocuments = false;
        init();

        function init () {
            checkScanDocument();
            ng2MonEventsService.addListener('changed-domain', function () {
                // When domain is changed, check scan_documents property and set tabs accordingly
                checkScanDocument();
            });
        }

        return {
            getTabs: getTabs,
            getActiveTab: getActiveTab,
        };

        // ///////////////

        function getTabs () {
            return availableTabs;
        }

        function getActiveTab (attr) {
            attr = attr || 'type';
            checkScanDocument();
            return scanDocuments ? $location.search()[attr] || null : 'html'; // Default type to html if scan_documents are false
        }

        function checkScanDocument () {
            if (ng2SessionService.domain &&
                ng2SessionService.domain.scan &&
                ng2SessionService.domain.scan.scan_documents
            ) {
                scanDocuments = ng2SessionService.domain.scan.scan_documents;
            } else {
                scanDocuments = false;
            }

            if (!scanDocuments) {
                // Remove available tabs
                availableTabs = [];
            } else {
                availableTabs = tabs;
            }
        }
    }
})();
