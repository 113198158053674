(function () {
    'use strict';

    angular.module('services.api').factory('SourceCodeExcludeRepo', SourceCodeExcludeRepo);

    SourceCodeExcludeRepo.$inject = ['ng2ApiService'];

    function SourceCodeExcludeRepo (ng2ApiService) {
        return {
            getAll: getAll,
            get: get,
            create: create,
            update: update,
            destroy: destroy,
        };

        // //////////////////////////////

        function get (sourceExcludeId, params, headers) {
            params = params || {};
            return ng2ApiService.get('source_code_excludes/' + sourceExcludeId, params, headers);
        }

        function getAll (params, headers) {
            params = params || {};
            return ng2ApiService.get('source_code_excludes', params, headers);
        }

        function create (sourceExclude, params, headers) {
            params = params || {};
            return ng2ApiService.post('source_code_excludes', sourceExclude, params, headers);
        }

        function update (sourceExclude, updatedSourceExclude, params, headers) {
            return ng2ApiService.patch('source_code_excludes/' + sourceExclude.id, updatedSourceExclude, params, headers);
        }

        function destroy (sourceExcludeId, params, headers) {
            params = params || {};
            return ng2ApiService.delete('source_code_excludes/' + sourceExcludeId, params, headers);
        }
    }
})();
