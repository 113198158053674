(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionStatisticsTrafficSources', {
        templateUrl: 'app/components/pagedetails/details/sections/statistics/traffic-sources/traffic-sources.html',
        controller: SectionSummaryController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            startDate: '<',
            endDate: '<',
        },
    });

    SectionSummaryController.$inject = ['$q', 'pageService', '$deepmerge', 'ng2DateTimeService', '$filter', 'gettextCatalog', 'Chart'];

    /* @ngInject */
    function SectionSummaryController ($q, pageService, $deepmerge, ng2DateTimeService, $filter, gettextCatalog, Chart) {
        const vm = this;
        vm.$onInit = activate;
        vm.$onChanges = updateDateRange;

        function activate () {
            vm.remoteData = {};
            vm.counts = {
                total: 0,
            };
            setupChartSettings();
        }

        function updateDateRange (changes) {
            if (changes.startDate || changes.endDate) {
                setupChart();
            }
        }

        function setupChart () {
            const promises = [];
            const params = {
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            const types = ['organic', 'direct', 'referring', 'paid', 'social'];
            for (let j = 0; j < types.length; j++) {
                promises.push(getVisitorsForType(types[j], params));
            }

            vm.lineChartData = [];
            vm.loading = true;
            $q.all(promises)
                .then( () => {
                    var typeWithMostData = types.reduce((primaryType, type) => {
                        if (vm.remoteData[type].length < vm.remoteData[primaryType].length) {
                            return type;
                        }
                        return primaryType;
                    }, 'organic');
                    vm.counts.total = 0;

                    types.forEach((type) => {
                        vm.lineChartData.push(
                            vm.remoteData[type].map( (typeData) => {
                                return +typeData.visits || 0;
                            }),
                        );
                        vm.counts[type] = vm.lineChartData[vm.lineChartData.length - 1].reduce((num, currentValue) => {
                            return num + currentValue;
                        }, 0);
                        vm.counts.total += vm.counts[type];
                    });

                    vm.lineChartLabels = vm.remoteData[typeWithMostData].map((typeData) => {
                        return ng2DateTimeService.getFormatString(typeData.date, 'll');
                    });
                }, angular.noop)
                .finally(() => {
                    vm.loading = false;
                });
        }

        function setupChartSettings () {
            vm.lineChartLabels = [];
            vm.lineChartOverride = [];
            vm.lineChartSeries = [
                gettextCatalog.getString('Search engine'),
                gettextCatalog.getString('Direct visits'),
                gettextCatalog.getString('Referring visits'),
                gettextCatalog.getString('Campaign visits'),
                gettextCatalog.getString('Social Media visits'),
            ];
            vm.lineChartColors = ['#71a3f6', '#57B754', '#EAA646', '#D24747', '#205C9E'];
            vm.chart = new Chart();
            vm.chart.setTooltipCustom({
                mode: 'label',
                intersect: false,
                callbacks: {
                    label: (tooltipItem, data) => (
                        data.datasets[tooltipItem.datasetIndex].label +
                            ': ' +
                            $filter('numeraljs', '0,0')(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                    ),
                },
            });
            vm.chart.setScalesSettings({
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: 4,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('numeraljs')(value, '0,0');
                            },
                            min: 0,
                        },
                        stacked: true,
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        stacked: true,
                        ticks: {
                            callback: (value) => {
                                if (value) {
                                    return ng2DateTimeService.format(value, 'MMM DD', 'll');
                                }
                                return '';
                            },
                        },
                    },
                ],
            });

            for (let i = 0; i < vm.lineChartSeries.length; i++) {
                vm.lineChartOverride.push({
                    type: 'bar',
                    fill: false,
                    borderColor: vm.lineChartColors[i],
                    borderWidth: 1,
                });
            }
        }

        function getVisitorsForType (type, params) {
            return pageService.getStatVisitors(vm.page.id, $deepmerge(params, { type: type })).then(
                (data) => {
                    vm.remoteData[type] = data;
                },
                () => {
                    vm.remoteData[type] = [];
                },
            );
        }
    }
})();
