import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PiwikValidationService {

    constructor () { }

    validateUrl (url: string): boolean {
        return /^(https:\/\/)*([\S]+).piwik.pro(\/|$)/.test(url);
    }
}
