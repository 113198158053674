(function () {
    'use strict';

    angular.module('app.forms').component('formDomainGroupsCreate', {
        templateUrl: 'app/forms/domain/steps/domain_groups/_form.html',
        controller: DomainGroupsCreateController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            users: '<',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    DomainGroupsCreateController.$inject = ['domainGroupService', 'gettextCatalog', 'monDialog', 'UserRepo', '$timeout'];

    /* @ngInject */
    function DomainGroupsCreateController (domainGroupService, gettextCatalog, monDialog, UserRepo, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;
        vm.save = save;

        function activate () {
            vm.saving = false;
            vm.domainGroup = { rules: [] };
            vm.title = gettextCatalog.getString('Create new domain group');
            vm.users = [];

            UserRepo.getAll({ page_size: 0 }).then(function (users) {
                vm.users = users;
            }, angular.noop);

            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.groupForm });
                }
            });
        }

        function close (data) {
            if (vm.groupForm) {
                vm.groupForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, data || vm.domainGroup);
        }

        function save () {
            vm.saving = true;
            vm.domainGroup.rules = domainGroupService.cleanupRules(vm.domainGroup.rules);
            domainGroupService
                .create(vm.domain, vm.domainGroup)
                .then(function (data) {
                    close(data);
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }
    }
})();
