(function () {
    'use strict';

    angular.module('modules', [
        'modules.support',
        'modules.accessibility',
        'modules.dataGatherer',
        'modules.page_fix',
        'modules.behavior',
        'modules.business-intelligence',
        'modules.cache',
        'modules.cc',
        'modules.charts',
        'modules.chat',
        'modules.cookie_banner',
        'modules.data_privacy',
        'modules.deeplink',
        'modules.globalPolicy',
        'modules.demodata',
        'modules.export',
        'modules.extension',
        'modules.fastTrack',
        'modules.flags',
        'modules.heatmaps',
        'modules.languages',
        'modules.light',
        'modules.moments',
        'modules.notifications',
        'modules.page-performance',
        'modules.policies',
        'modules.roles',
        'modules.rollbar',
        'modules.shortcut',
        'modules.source-code-excludes',
        'modules.spotlight',
        'modules.statistics',
        'modules.translations',
        'modules.tracking',
        'modules.modals',
        'modules.terms',
        'modules.tools',
        'modules.wizard',
    ]);
})();
