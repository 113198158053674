import { Injectable } from '@angular/core';

export type PrivacyFastTrackModelOptionsType = {
    content: string;
    created_at: string;
    effect_on_compliance: number;
    id: number;
    pages_affected?: number;
};

export class PrivacyFastTrackModel {
    content: string;
    created_at: string;
    effect_on_compliance: number;
    id: number;
    pages_affected: number;
    issueCount: number;

    constructor (options: PrivacyFastTrackModelOptionsType) {
        const {
            content,
            created_at,
            effect_on_compliance,
            id,
            pages_affected,
        } = options;

        this.pages_affected = pages_affected != null ? pages_affected : 0;
        this.content = content;
        this.created_at = created_at;
        this.effect_on_compliance = effect_on_compliance;
        this.id = id;
        this.issueCount = 0;
    }

    setIssueCount (issueCollection: Array<unknown>): void {
        this.issueCount = issueCollection.length;
    }
}

@Injectable({
    providedIn: 'root',
})
export class PrivacyFastTrackModelFactoryService {

    constructor () { }

    getModel (options: PrivacyFastTrackModelOptionsType): PrivacyFastTrackModel {
        return new PrivacyFastTrackModel(options);
    }
}
