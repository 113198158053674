(function () {
    'use strict';

    angular.module('pages.accessibility').component('guidelinesDialogsChecks', {
        templateUrl: 'app/pages/accessibility/guidelines/dialogs/checks/checks.html',
        controller: AccessibilityGuidelinesDialogsChecksController,
        controllerAs: 'vm',
        bindings: {
            subgroupId: '<',
            accessibilityGroupId: '<',
            classification: '<',
        },
    });
    AccessibilityGuidelinesDialogsChecksController.$inject = ['ng2AccessibilityGuidelinesRepoService'];

    function AccessibilityGuidelinesDialogsChecksController (ng2AccessibilityGuidelinesRepoService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getChecks = getChecks;

        function activate () {
            getChecks();
        }

        function getChecks () {
            var params = {
                classification: vm.classification,
            };

            vm.progress = ng2AccessibilityGuidelinesRepoService.getAllChecksForSubGroup(
                vm.accessibilityGroupId,
                vm.subgroupId,
                params,
            ).then(function (data) {
                vm.checks = data;
            }, angular.noop);
        }
    }
})();
