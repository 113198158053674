(function () {
    'use strict';

    angular.module('modules.chat.velaro').provider('Velaro', function () {
        getter.$inject = [];

        function getter () {
            var loaded = false;
            function addScript () {
                if (shouldStartIntercom()) {
                    initScript();
                }
            }

            return {
                addScript: addScript,
            };

            function shouldStartIntercom () {
                return !loaded;
            }

            function initScript () {
                var script = document.createElement('script');
                script.nonce = 'cb56f32a23f';
                script.innerHTML =
                    '(function(){ ' +
                    '   var globals = document.createElement(\'script\');' +
                    '   globals.src = \'https://eastprodstorage.azureedge.net/velaroscripts/20608/globals.js\';' +
                    '   var inline = document.createElement(\'script\');' +
                    '   inline.src = \'https://eastprodcdn.azureedge.net/bundles/velaro.inline.js\';' +
                    '   var scriptNode = document.getElementsByTagName(\'script\')[0];' +
                    '   scriptNode.parentNode.insertBefore(globals, scriptNode); ' +
                    '   globals.onload = function () { ' +
                    '       Velaro.Globals.ActiveSite = 20608; ' +
                    '       Velaro.Globals.ActiveGroup = 0; ' +
                    '       Velaro.Globals.InlineEnabled = true;  ' +
                    '       Velaro.Globals.VisitorMonitoringEnabled = true; ' +
                    '       Velaro.Globals.InlinePosition = 0; ' +
                    '       scriptNode.parentNode.insertBefore(inline, scriptNode) ' +
                    '   }' +
                    '})();';
                loaded = true;
                document.body.appendChild(script);
            }
        }
        this.$get = getter;
    });
})();
