(function () {
    'use strict';

    angular.module('filters.monsido').filter('translateWithPlural', TranslateWithPluralController);

    TranslateWithPluralController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function TranslateWithPluralController (gettextCatalog) {
        function result (single, number, plural, scope) {
            var num = parseInt(number);
            scope = scope || {};
            return gettextCatalog.getPlural(num, single, plural, scope);
        }

        result.$stateful = true;
        return result;
    }
})();
