import { Injectable } from '@angular/core';
import { devMode } from '@monsido/ng2/core/constants/general.constant';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { MonLogService } from '@monsido/ng2/services/mon-log-service/mon-log.service';
import { noop } from 'angular';
import { MonModalService } from 'app/modules/modals/services/mon-modal/mon-modal.service';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandler {
    constructor (
        private monModalService: MonModalService,
        private monEventService: MonEventService,
        private monLogService: MonLogService,
        private translateService: TranslateService,
    ) {}

    standardMsg (errorMsg: string, severity: string): void {
        this.monModalService
            .alert(
                this.translateService.getString('An error has occurred - please try again. If the error continues, please contact Acquia Optimize'),
            )
            .then(noop, noop);

        this.monLogService.error(errorMsg);

        if (!devMode) {
            this.setLogLevel(errorMsg, severity);
        }
    }

    noopError (errorMsg: string, severity: string): void {
        this.monLogService.error(errorMsg);

        if (!devMode) {
            this.setLogLevel(errorMsg, severity);
        }
    }

    private setLogLevel (errorMsg: string, severity: string): void {
        this.monEventService.run('newException', errorMsg, severity);
    }
}
