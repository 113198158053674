(function () {
    'use strict';
    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name complianceAccessibilityLegacyService
     * @description Get the necessary counters depending on the selected accessibility result type
     */

    angular
        .module('blocks.service')
        .factory('complianceAccessibilityLegacyService', complianceAccessibilityLegacyService);

    complianceAccessibilityLegacyService.$inject = [];
    /* @ngInject*/
    function complianceAccessibilityLegacyService () {
        return {
            getDataFromResultType: getDataFromResultType,
            getPercentageFromResultType: getPercentageFromResultType,
        };

        /**
         * @memberOf complianceAccessibilityLegacyService
         * @description Select and return the correct data correspondent to the selected resultType
         * @param {String} resultType
         * @param {Object} summary
         * @return {Array}
         */
        function getDataFromResultType (resultType, summary) {
            if (!isObject(summary)) {
                return [0, 0];
            }
            switch (resultType) {
                case 'errors':
                    return getDataWithResultTypeError(summary);
                case 'errors_and_warnings':
                    return getDataWithResultTypeErrorsAndWarnings(summary);
                default:
                    return getDataWithNoResultType(summary);
            }
        }

        // PROTECTED

        /**
         * @memberOf complianceAccessibilityLegacyService
         * @description Return the page count of result type errors
         * @param {Object} summary
         * @return {Array}
         */
        function getDataWithResultTypeError (summary) {
            var errors = 0;

            if (isObject(summary.pages_by_result_type)) {
                errors = summary.pages_by_result_type.error || 0;
            }

            return [summary.pages - errors, errors];
        }

        /**
         * @memberOf complianceAccessibilityLegacyService
         * @description Return all distinct pages count that contains accessibility errors
         * @param {Object} summary
         * @return {Array}
         */
        function getDataWithNoResultType (summary) {
            var errors = summary.pages_with_issues || 0;
            return [summary.pages - errors, errors];
        }

        /**
         * @memberOf complianceAccessibilityLegacyService
         * @description Return the page count of result types errors and warnings
         * @param {Object} summary
         * @return {Array}
         */
        function getDataWithResultTypeErrorsAndWarnings (summary) {
            var errors = 0;

            if (isObject(summary.pages_by_result_type)) {
                errors = summary.pages_by_result_type.error_and_warning || 0;
            }

            return [summary.pages - errors, errors];
        }

        /**
         * @memberOf complianceAccessibilityLegacyService
         * @description Return the percentage value of the data selected from the resultType
         * @param {String} resultType
         * @param {Object} summary
         * @return {number}
         */
        function getPercentageFromResultType (resultType, summary) {
            var errors = 0;

            if (!isObject(summary)) {
                return 0;
            }

            switch (resultType) {
                case 'errors':
                    errors = summary.pages_by_result_type.error || 0;
                    break;
                case 'errors_and_warnings':
                    errors = summary.pages_by_result_type.error_and_warning || 0;
                    break;
                default:
                    errors = summary.pages_with_issues || 0;
            }

            return (errors / summary.pages) * 100;
        }

        /**
         * @memberOf complianceAccessibilityLegacyService
         * @description Validate if input is a object
         * @param {Object} obj
         * @return {boolean}
         */

        function isObject (obj) {
            return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
        }
    }
})();
