(function () {
    'use strict';
    angular.module('modules.page_fix.forms').service('PageFixFormBlinkOptionService', PageFixFormBlinkOptionService);

    PageFixFormBlinkOptionService.$inject = ['ng2PageFixFormHtmlElementOptionsService', 'accessibilityAutofixService'];
    /* @ngInject*/
    function PageFixFormBlinkOptionService (ng2PageFixFormHtmlElementOptionsService, accessibilityAutofixService) {
        var vm = this;
        vm.elementOptions = ng2PageFixFormHtmlElementOptionsService.getOptions();

        return {
            getElementOptions: getElementOptions,
            getElementRegex: getElementRegex,
            getElementPreview: getElementPreview,
            isElementNameValid: isElementNameValid,
        };

        // ///////////////

        function getElementOptions () {
            return vm.elementOptions;
        }

        function getElementRegex () {
            return accessibilityAutofixService.getElementRegex();
        }

        function getElementPreview (elementName) {
            return accessibilityAutofixService.getElementPreview(elementName);
        }

        function isElementNameValid (elementName) {
            return accessibilityAutofixService.isElementNameValid(elementName);
        }
    }
})();
