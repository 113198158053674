(function () {
    'use strict';

    angular.module('services.api').factory('PagePerformanceProfileRepo', PagePerformanceProfileRepo);

    PagePerformanceProfileRepo.$inject = ['ng2ApiService', 'PagePerformanceProfileModel'];

    /* @ngInject */
    function PagePerformanceProfileRepo (ng2ApiService, PagePerformanceProfileModel) {
        return {
            getAll: getAll,
            update: update,
            destroy: destroy,
            create: create,
            archiveProfile: archiveProfile,
        };

        // /////////////

        function getAll (params, contextToken) {
            params = params || {};
            return ng2ApiService.get('performance/profiles', params, {}, contextToken).then(function (profiles) {
                return toModels(profiles);
            });
        }

        function update (profile, params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.patch('performance/profiles/' + profile.id, profile, params, config).then(toModel);
        }

        function destroy (profile, params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.delete('performance/profiles/' + profile.id, params, config);
        }

        function create (profile, params, config) {
            config = config || {};
            return ng2ApiService.post('performance/profiles', profile, params, config).then(toModel);
        }

        function archiveProfile (profile, params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.patch('/performance/profiles/' + profile.id + '/archived', profile, params, config);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new PagePerformanceProfileModel(model);
        }
    }
})();
