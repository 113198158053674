(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionInventoryDashboard', {
        templateUrl: 'app/components/pagedetails/details/sections/inventory/dashboard/dashboard.html',
        controller: SectionInventoryDashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onGoToSection: '&',
        },
    });

    SectionInventoryDashboardController.$inject = ['gettextCatalog', '$filter', 'ng2PageRepoService', 'ng2PagePageLinksRepoService', '$q'];
    /* @ngInject */
    function SectionInventoryDashboardController (gettextCatalog, $filter, ng2PageRepoService, ng2PagePageLinksRepoService, $q) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToSection = goToSection;

        function activate () {
            vm.incomingLinkCount = 0;
            vm.imageCount = 0;
            vm.outgoingLinkCount = 0;
            vm.pdfCount = 0;
            vm.excelCount = 0;
            vm.documentCount = 0;
            vm.cssCount = 0;
            vm.jsCount = 0;
            vm.frameCount = 0;
            vm.iframeCount = 0;
            vm.slideCount = 0;
            vm.loading = true;
            setupData();
        }

        function setupData () {
            var promises = [
                getIncomingLinks(),
                getLinks('image'),
                getLinks('link'), // External
                getLinks('pdf'),
                getLinks('spreadsheet'),
                getLinks('document'),
                getLinks('css'),
                getLinks('js'),
                getLinks('frame'),
                getLinks('iframe'),
                getLinks('slide'),
            ];
            $q.all(promises)
                .then(function (values) {
                    vm.incomingLinkCount = parseInt(values[0].total);
                    vm.imageCount = parseInt(values[1].total);
                    vm.outgoingLinkCount = parseInt(values[2].total);
                    vm.pdfCount = parseInt(values[3].total);
                    vm.excelCount = parseInt(values[4].total);
                    vm.documentCount = parseInt(values[5].total);
                    vm.cssCount = parseInt(values[6].total);
                    vm.jsCount = parseInt(values[7].total);
                    vm.frameCount = parseInt(values[8].total);
                    vm.iframeCount = parseInt(values[9].total);
                    vm.slideCount = parseInt(values[10].total);
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function goToSection (section) {
            vm.onGoToSection({ section: section });
        }

        // PROTECTED

        function getIncomingLinks () {
            var params = {
                page_size: 1,
                page: 1,
            };
            return ng2PageRepoService.getIncomingLinks(vm.page.id, params).then(
                function (data) {
                    return data;
                },
                function () {
                    return { total: 0 };
                },
            );
        }

        function getLinks (type) {
            var params = {
                type: type,
                page_size: 1,
                page: 1,
            };
            return ng2PagePageLinksRepoService.getAll(vm.page.id, params).then(
                function (data) {
                    return data;
                },
                function () {
                    return { total: 0 };
                },
            );
        }
    }
})();
