import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { IssueInterface } from 'app/modules/accessibility/interfaces/issue-interface';
import { SnippetInterface } from 'app/modules/accessibility/interfaces/snippet-interface';
import { GetPagesParamsInterface } from 'app/modules/qa/repos/qa-issue-repo.interface';
import { RollbarErrorHandlerService } from 'ng2/external/rollbar/rollbar-error-handler.service';
import { stringifyRequestParams } from 'ng2/shared/utils';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';

type AccessibilityIssueRepoRequestType = RequestParamsType & BaseRepoRequestType;

@Injectable({
    providedIn: 'root',
})
export class AccessibilityIssueRepo extends BaseRepo {

    constructor (private client: ApiClient, protected sessionService: SessionService, private rollbar: RollbarErrorHandlerService) {
        super();
    }

    getAccessibilityIssue (issueId: number): Promise<IssueInterface[]> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'getAccessibilityIssue - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }

        return this.client.getPromise(`domains/${domainId}/accessibility/fast_track/${issueId}/checks`);
    }

    getAccessibilitySnippet (issueId: number): Promise<SnippetInterface> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'getAccessibilitySnippet - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }

        return this.client.getPromise(`domains/${domainId}/accessibility/fast_track/${issueId}`);
    }

    getHelp (checkId: number, params: AccessibilityIssueRepoRequestType = {}):
    Promise<Record<string, string | number | boolean>> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'getHelp - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }
        params = this.setGroupId(params);

        return this.client.getPromise(`domains/${domainId}/accessibility/checks/${checkId}/help`,
            { params: {
                ...stringifyRequestParams(params),
            } });
    }

    markCheckAsFixed (pageId: number, errorId: number, params: AccessibilityIssueRepoRequestType = {}): Promise<void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'markCheckAsFixed - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }
        params = this.setGroupId(params);
        return this.client.deletePromise(
            `domains/${domainId}/pages/${pageId}/accessibility/errors/${errorId}`,
            { params: {
                ...stringifyRequestParams(params),
            } },
        );
    }

    markIssueAsFixed (sourceCodeId: number, checkId: number, params:AccessibilityIssueRepoRequestType = {}): Promise<void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'markIssueAsFixed - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }
        params = this.setGroupId(params);
        return this.client.deletePromise(
            `domains/${domainId}/accessibility/fast_track/${sourceCodeId}/checks/${checkId}/pages`,
            { params: {
                ...stringifyRequestParams(params),
            } },
        );
    }

    updateError (pageId: number, error: Record<string, string | number | boolean>,
        params: AccessibilityIssueRepoRequestType = {}): Promise<unknown> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'updateError - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(errorMsg);
        }
        params = this.setGroupId(params);
        return this.client.patchPromise(
            `domains/${domainId}/pages/${pageId}/accessibility/errors/${error.id}`,
            error,
            { params: {
                ...stringifyRequestParams(params),
            } },
        );
    }

    updateCheck (sourceCodeId: number, issue: IssueInterface):
    Promise<unknown> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'updateCheck - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }
        issue = issue || {};
        issue = issue ?? `${this.sessionService.getGroupId()}`;
        return this.client.patchPromise(`domains/${domainId}/accessibility/fast_track/${sourceCodeId}/checks/${issue.id}`, issue);
    }

    getAccessibilityIssuePages (checkId: number, params: GetPagesParamsInterface): Promise<unknown> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'getAccessibilityIssuePages - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }
        return this.client.getPromise(`domains/${domainId}/accessibility/checks/${checkId}/pages`, { params: {
            ...stringifyRequestParams(params as unknown as Record<string, unknown>),
        } });
    }

    getAccessibilityErrors (pageId: number, checkId: number, params: Record<string, string | number>): Promise<unknown> {
        const domainId = this.getDomainId();

        if (!domainId) {
            const errorMsg = 'getAccessibilityErrors - No domain id was found!';
            this.rollbar.warn(errorMsg);
            throwError(new Error(errorMsg));
        }

        return this.client.getPromise(
            `domains/${domainId}/pages/${pageId}/accessibility/checks/${checkId}/errors`,
            { params: {
                ...stringifyRequestParams(params),
            } },
        );
    }
}

