import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type HTTPMethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type HTTPServiceConfigOptionsType = {
    headers?: Record<string, string>,
    params?: Record<string, string>,
    url?: string;
};

/*

    This service should be used only in the Angularjs code.

*/
@Injectable({
    providedIn: 'root',
})
export class HttpLegacyService {
    private shouldCancelAllRequests$ = new Subject<void>();

    get onCancelRequests (): Observable<void> {
        return this.shouldCancelAllRequests$.asObservable();
    }

    constructor (
        private httpClient: HttpClient,
    ) {}

    public get (url: string): Promise<unknown> {
        return this.makeRequest('GET', url, undefined);
    }

    public post (url: string, body: unknown): Promise<unknown> {
        return this.makeRequest('POST', url, body);
    }

    public put (url: string, body: unknown): Promise<unknown> {
        return this.makeRequest('PUT', url, body);
    }

    public patch (url: string, body: unknown): Promise<unknown> {
        return this.makeRequest('PATCH', url, body);
    }

    public delete (url: string): Promise<unknown> {
        return this.makeRequest('DELETE', url, undefined);
    }

    public cancelRequests (): void {
        this.shouldCancelAllRequests$.next();
    }

    public makeRequest (
        method: HTTPMethodType,
        url: string,
        body: unknown,
        config: HTTPServiceConfigOptionsType = {},
    ): Promise<unknown> {

        const { headers, params } = config;
        const init: { headers?: HttpHeaders, params?: HttpParams } = {};

        if (headers) {
            init.headers = new HttpHeaders(Object.keys(headers).reduce((acc, key) => {
                acc[key] = String(headers[key]); // Value must be a string, otherwise Angular silently throws it away
                return acc;
            }, {} as Record<string, string>));
        }

        if (params) {

            const filteredParams = Object.assign({}, params);

            Object.keys(filteredParams).forEach(key => {
                if (filteredParams[key] == null) {
                    delete filteredParams[key];
                }
            });

            init.params = new HttpParams({
                fromObject: filteredParams,
            });
        }

        const request = new HttpRequest(
            method,
            (config.url || '') + url,
            body,
            init,
        );

        return new Promise((resolve, reject) => {
            this.httpClient.request(request)
                .pipe(takeUntil(this.onCancelRequests))
                .subscribe(
                    (response: HttpResponse<unknown>) => {
                        if (response instanceof HttpResponse) {
                            resolve(response.body);
                        }
                    },
                    (error: HttpErrorResponse) => {
                        reject(error);
                    },
                );
        });
    }
}
