import { Injectable } from '@angular/core';
import { MON_ICONS } from '@layout/icons/icons.constants';

@Injectable()
export class IconsService {
    getIcon (path: string[]): string | typeof MON_ICONS {
        let i: number;
        let result: string | typeof MON_ICONS = MON_ICONS;

        for (i = 0; i < path.length; i++) {
            result = (result as Record<string, string>)[path[i]];
        }
        return result;
    }
}
