(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionInventory', {
        templateUrl: 'app/components/pagedetails/details/sections/inventory/inventory.html',
        controller: SectionInventoryController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            active: '<',
        },
    });

    SectionInventoryController.$inject = [];
    /* @ngInject */
    function SectionInventoryController () {
        var vm = this;
        vm.$onInit = activate;
        vm.goToActive = goToActive;

        function activate () {
            vm.active = selectInventorySection();
            vm.page = vm.page || {};
        }

        function selectInventorySection () {
            var sections = [
                'spreadsheet',
                'pdf',
                'archive',
                'document',
                'file',
                'image',
                'links',
                'incominglinks',
                'form',
                'headlinks',
                'iframe',
                'frame',
                'css',
                'js',
                'metadata',
                'header',
                'emails',
            ];
            var index = sections.indexOf(vm.active);
            if (index === -1) {
                return 'dashboard';
            }
            return sections[index];
        }

        function goToActive (active) {
            vm.active = active;
        }
    }
})();
