import { DomainGroupModelType } from "./domain-group-model.type";

export class DomainGroupModel implements DomainGroupModelType {
    id: number;
    title: string;
    description: string | null;
    updating: boolean;
    rules: { match: string; match_rule: string; key: string; text: string; label_ids: string[]; exclude: boolean; }[];
    created_at: string;
    updated_at: string;
    last_updated_at: string | null;
    domain_group_members: { send_report: boolean; visible: boolean; domain_group_id: number; user_id: number; id: number; }[];
    page_count: number;
    documents_count: number;
    dead_links_count: number;
    dead_images_count: number;
    accessibility_checks_by_result_type_and_abbr_counters: { error: { A: number; AA?: number; AAA?: number; }; warning: { A: number; AA?: number; AAA?: number; }; review: { A: number; AA?: number; AAA?: number; }; } | null;
    accessibility_checks_counters: { error?: number; review?: number; warning?: number; };
    accessibility_pages_by_result_type_and_abbr_counters: { error: { A: number; AA?: number; AAA?: number; }; error_and_warning: { A: number; AA?: number; AAA?: number; }; error_and_warning_and_review: { A: number; AA?: number; AAA?: number; }; } | null;
    accessibility_pages_counters: { error?: number; error_and_warning?: number; error_and_warning_and_review?: number; };
    policy_with_required_count: number;
    policy_with_search_hits_count: number;
    policy_with_violations_count: number;
    spelling_errors_confirmed_count: number;
    seo_errors_alerts_count: number;
    seo_errors_infos_count: number;
    seo_errors_technical_count: number;
    seo_errors_warnings_count: number;
    date: string;
    accessibility_checks: unknown[];
    seo_count: number;
    qa_count: number;

    constructor(attributes: DomainGroupModelType) {
        this.id = attributes.id;
        this.title = attributes.title;
        this.description = attributes.description;
        this.updating = attributes.updating;
        this.rules = [];
        for (const rule of attributes.rules) {
            this.rules.push({ 
                match: rule.match, 
                match_rule: rule.match_rule,
                key: rule.key,
                text: rule.text,
                label_ids: [...rule.label_ids],
                exclude: rule.exclude
            });
        }
        this.created_at = attributes.created_at;
        this.updated_at = attributes.updated_at;
        this.last_updated_at = attributes.last_updated_at;
        this.domain_group_members = []; 
        for (const member of attributes.domain_group_members) {
            this.domain_group_members.push({ 
                send_report: member.send_report, 
                visible: member.visible, 
                domain_group_id: member.domain_group_id,
                user_id: member.user_id, 
                id: member.id 
            });
        }
        this.page_count = attributes.page_count;
        this.documents_count = attributes.documents_count;
        this.dead_links_count = attributes.dead_links_count;
        this.dead_images_count = attributes.dead_images_count;
        if (attributes.accessibility_checks_by_result_type_and_abbr_counters) {
            this.accessibility_checks_by_result_type_and_abbr_counters = { 
                error: {
                    A: attributes.accessibility_checks_by_result_type_and_abbr_counters.error.A,
                    AA: attributes.accessibility_checks_by_result_type_and_abbr_counters.error.AA, 
                    AAA: attributes.accessibility_checks_by_result_type_and_abbr_counters.error.AAA
                },
                warning: { 
                    A: attributes.accessibility_checks_by_result_type_and_abbr_counters.warning.A,
                    AA: attributes.accessibility_checks_by_result_type_and_abbr_counters.warning.AA, 
                    AAA: attributes.accessibility_checks_by_result_type_and_abbr_counters.warning.AAA
                },
                review: { 
                    A: attributes.accessibility_checks_by_result_type_and_abbr_counters.review.A,
                    AA: attributes.accessibility_checks_by_result_type_and_abbr_counters.review.AA, 
                    AAA: attributes.accessibility_checks_by_result_type_and_abbr_counters.review.AAA
                } 
            };
        } else {
            this.accessibility_checks_by_result_type_and_abbr_counters = null;
        }
        this.accessibility_checks_counters = { 
            error: attributes.accessibility_checks_counters.error, 
            review: attributes.accessibility_checks_counters.review,
            warning: attributes.accessibility_checks_counters.warning 
        };
        if (attributes.accessibility_pages_by_result_type_and_abbr_counters) {
            this.accessibility_pages_by_result_type_and_abbr_counters = { 
                error: { 
                    A: attributes.accessibility_pages_by_result_type_and_abbr_counters.error.A,
                    AA: attributes.accessibility_pages_by_result_type_and_abbr_counters.error.AA,
                    AAA: attributes.accessibility_pages_by_result_type_and_abbr_counters.error.AAA
                },
                error_and_warning: { 
                    A: attributes.accessibility_pages_by_result_type_and_abbr_counters.error_and_warning.A,
                    AA: attributes.accessibility_pages_by_result_type_and_abbr_counters.error_and_warning.AA,
                    AAA: attributes.accessibility_pages_by_result_type_and_abbr_counters.error_and_warning.AAA
                },
                error_and_warning_and_review: { 
                    A: attributes.accessibility_pages_by_result_type_and_abbr_counters.error_and_warning_and_review.A,
                    AA: attributes.accessibility_pages_by_result_type_and_abbr_counters.error_and_warning_and_review.AA,
                    AAA: attributes.accessibility_pages_by_result_type_and_abbr_counters.error_and_warning_and_review.AAA
                }
            };
        } else {
            this.accessibility_pages_by_result_type_and_abbr_counters = null;
        }
        this.accessibility_pages_counters = { 
            error: attributes.accessibility_pages_counters.error,
            error_and_warning: attributes.accessibility_pages_counters.error_and_warning,
            error_and_warning_and_review: attributes.accessibility_pages_counters.error_and_warning_and_review
        };
        this.policy_with_required_count = attributes.policy_with_required_count || 0;
        this.policy_with_search_hits_count = attributes.policy_with_search_hits_count || 0;
        this.policy_with_violations_count = attributes.policy_with_violations_count || 0;
        this.spelling_errors_confirmed_count = attributes.spelling_errors_confirmed_count;
        this.seo_errors_alerts_count = attributes.seo_errors_alerts_count;
        this.seo_errors_infos_count = attributes.seo_errors_infos_count;
        this.seo_errors_technical_count = attributes.seo_errors_technical_count;
        this.seo_errors_warnings_count = attributes.seo_errors_warnings_count;
        this.date = attributes.date || '';
        this.accessibility_checks = attributes.accessibility_checks || [];
        this.seo_count = attributes.seo_count || 0;
        this.qa_count = attributes.qa_count || 0;
    }
}