export const PAGE_PERFORMANCE_AUDIT_ID = {
    IS_ON_HTTPS: 'is-on-https',
    REDIRECTS_HTTP: 'redirects-http',
    SERVICE_WORKER: 'service-worker',
    WORKS_OFFLINE: 'works-offline',
    VIEWPORT: 'viewport',
    WITHOUT_JAVASCRIPT: 'without-javascript',
    FIRST_CONTENTFUL_PAINT: 'first-contentful-paint',
    FIRST_MEANINGFUL_PAINT: 'first-meaningful-paint',
    LOAD_FAST_ENOUGH_FOR_PWA: 'load-fast-enough-for-pwa',
    SPEED_INDEX: 'speed-index',
    SCREENSHOT_THUMBNAILS: 'screenshot-thumbnails',
    FINAL_SCREENSHOT: 'final-screenshot',
    ESTIMATED_INPUT_LATENCY: 'estimated-input-latency',
    TOTAL_BLOCKING_TIME: 'total-blocking-time',
    MAX_POTENTIAL_FID: 'max-potential-fid',
    ERRORS_IN_CONSOLE: 'errors-in-console',
    TIME_TO_FIRST_BYTE: 'time-to-first-byte',
    FIRST_CPU_IDLE: 'first-cpu-idle',
    INTERACTIVE: 'interactive',
    USER_TIMINGS: 'user-timings',
    CRITICAL_REQUEST_CHAINS: 'critical-request-chains',
    REDIRECTS: 'redirects',
    INSTALLABLE_MANIFEST: 'installable-manifest',
    APPLE_TOUCH_ICON: 'apple-touch-icon',
    SPLASH_SCREEN: 'splash-screen',
    THEMED_OMNIBOX: 'themed-omnibox',
    CONTENT_WIDTH: 'content-width',
    IMAGE_ASPECT_RATIO: 'image-aspect-ratio',
    DEPRECATIONS: 'deprecations',
    MAINTHREAD_WORK_BREAKDOWN: 'mainthread-work-breakdown',
    BOOTUP_TIME: 'bootup-time',
    USES_REL_PRELOAD: 'uses-rel-preload',
    USES_REL_PRECONNECT: 'uses-rel-preconnect',
    FONT_DISPLAY: 'font-display',
    DIAGNOSTICS: 'diagnostics',
    NETWORK_REQUESTS: 'network-requests',
    NETWORK_RTT: 'network-rtt',
    NETWORK_SERVER_LATENCY: 'network-server-latency',
    MAIN_THREAD_TASKS: 'main-thread-tasks',
    METRICS: 'metrics',
    OFFLINE_START_URL: 'offline-start-url',
    PERFORMANCE_BUDGET: 'performance-budget',
    RESOURCE_SUMMARY: 'resource-summary',
    THIRD_PARTY_SUMMARY: 'third-party-summary',
    PWA_CROSS_BROWSER: 'pwa-cross-browser',
    PWA_PAGE_TRANSITIONS: 'pwa-page-transitions',
    PWA_EACH_PAGE_HAS_URL: 'pwa-each-page-has-url',
    DOCUMENT_TITLE: 'document-title',
    IMAGE_ALT: 'image-alt',
    USES_LONG_CACHE_TTL: 'uses-long-cache-ttl',
    TOTAL_BYTE_WEIGHT: 'total-byte-weight',
    OFFSCREEN_IMAGES: 'offscreen-images',
    RENDER_BLOCKING_RESOURCES: 'render-blocking-resources',
    UNMINIFIED_CSS: 'unminified-css',
    UNMINIFIED_JAVASCRIPT: 'unminified-javascript',
    UNUSED_CSS_RULES: 'unused-css-rules',
    USES_WEBP_IMAGES: 'uses-webp-images',
    USES_OPTIMIZED_IMAGES: 'uses-optimized-images',
    USES_TEXT_COMPRESSION: 'uses-text-compression',
    USES_RESPONSIVE_IMAGES: 'uses-responsive-images',
    EFFICIENT_ANIMATED_CONTENT: 'efficient-animated-content',
    APPCACHE_MANIFEST: 'appcache-manifest',
    DOCTYPE: 'doctype',
    DOM_SIZE: 'dom-size',
    EXTERNAL_ANCHORS_USE_REL_NOOPENER: 'external-anchors-use-rel-noopener',
    GEOLOCATION_ON_START: 'geolocation-on-start',
    NO_DOCUMENT_WRITE: 'no-document-write',
    NO_VULNERABLE_LIBRARIES: 'no-vulnerable-libraries',
    JS_LIBRARIES: 'js-libraries',
    NOTIFICATION_ON_START: 'notification-on-start',
    PASSWORD_INPUTS_CAN_BE_PASTED_INTO: 'password-inputs-can-be-pasted-into',
    USES_HTTP2: 'uses-http2',
    USES_PASSIVE_EVENT_LISTENERS: 'uses-passive-event-listeners',
    META_DESCRIPTION: 'meta-description',
    HTTP_STATUS_CODE: 'http-status-code',
    FONT_SIZE: 'font-size',
    LINK_TEXT: 'link-text',
    IS_CRAWLABLE: 'is-crawlable',
    ROBOTS_TXT: 'robots-txt',
    TAP_TARGETS: 'tap-targets',
    HREFLANG: 'hreflang',
    PLUGINS: 'plugins',
    CANONICAL: 'canonical',
    STRUCTURED_DATA: 'structured-data',
    SERVER_RESPONCE_TIME: 'server-response-time',
    LARGEST_CONTENTFUL_PAINT_ELEMENT: 'largest-contentful-paint-element',
    UNUSED_JAVASCRIPT: 'unused-javascript',
    LONG_TASKS: 'long-tasks',
    THIRD_PARTY_FACADES: 'third-party-facades',
    DUPLICATED_JAVASCRIPT: 'duplicated-javascript',
    LEGACY_JAVASCRIPT: 'legacy-javascript',
    LAYOUT_SHIFT_ELEMENTS: 'layout-shift-elements',
    NON_COMPOSITED_ANIMATIONS: 'non-composited-animations',
    UNSIZED_IMAGES: 'unsized-images',
    CUMULATIVE_LAYOUT_SHIFT: 'cumulative-layout-shift',
    LARGEST_CONTENTFUL_PAINT: 'largest-contentful-paint',
    BF_CACHE: 'bf-cache',
    PRIORITIZE_LCP: 'prioritize-lcp-image',
} as const;
