import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';

export type SupportFeedbackCreateParams = {
    recipient_name: string;
    recipient_email: string;
    message: string;
}

@Injectable({
    providedIn: 'root',
})
export class SupportFeedbackRepoService {

    constructor (private apiService: ApiService) { }

    create (params: SupportFeedbackCreateParams): Promise<void> {
        params = params || {};
        return this.apiService.post('support/feedback', params);
    }
}
