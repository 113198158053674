(function () {
    'use strict';

    angular.module('models').factory('StatisticsEntryPageModel', StatisticsEntryPageModelFactory);

    StatisticsEntryPageModelFactory.$inject = [];

    function StatisticsEntryPageModelFactory () {
        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getBounceRateFloat = getBounceRateFloat;
        return Model;

        // /////////

        function getBounceRateFloat () {
            return parseFloat(this.bounce_rate) / 100;
        }
    }
})();
