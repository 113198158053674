export interface PageProfileDataInterface {
    archived_at: null | string;
    cpu_slowdown_multiplier: number;
    created_at: string;
    customer_id: number;
    description: string;
    device: 'mobile' | 'desktop';
    download_throughput: number;
    id: number;
    location: string;
    name: string;
    request_latency: number;
    round_trip_time: number;
    throughput: number;
    updated_at: string;
    upload_throughput: number;
}

type ComputedProfileProperties = {
    connectionName: string;
    deviceName: string;
    countryCode: string;
    cityName: string;
    countryName: string;
};

export class PagePerformanceProfile implements PageProfileDataInterface {

    archived_at: null | string = null;
    cpu_slowdown_multiplier: number;
    created_at: string;
    customer_id: number;
    description: string;
    device: 'mobile' | 'desktop';
    download_throughput: number;
    id: number;
    location: string;
    name: string;
    request_latency: number;
    round_trip_time: number;
    throughput: number;
    updated_at: string;
    upload_throughput: number;

    private connectionName: string;
    private deviceName: string;
    private countryCode: string;
    private cityName: string;
    private countryName: string;

    constructor (data: PageProfileDataInterface & ComputedProfileProperties) {

        this.connectionName = data.connectionName;
        this.deviceName = data.deviceName;
        this.countryCode = data.countryCode;
        this.cityName = data.cityName;
        this.countryName = data.countryName;

        if (data.archived_at) {
            this.archived_at = data.archived_at;
        }

        this.cpu_slowdown_multiplier = data.cpu_slowdown_multiplier;
        this.created_at = data.created_at;
        this.customer_id = data.customer_id;
        this.description = data.description;
        this.device = data.device;
        this.download_throughput = data.download_throughput;
        this.id = data.id;
        this.location = data.location;
        this.name = data.name;
        this.request_latency = data.request_latency;
        this.round_trip_time = data.round_trip_time;
        this.throughput = data.throughput;
        this.updated_at = data.updated_at;
        this.upload_throughput = data.upload_throughput;
    }

    getConnectionName (): string {
        return this.connectionName;
    }

    getDeviceName (): string {
        return this.deviceName;
    }

    getCountryCode (): string {
        return this.countryCode;
    }

    getCity (): string {
        return this.cityName;
    }

    getCountry (): string {
        return this.countryName;
    }

}
