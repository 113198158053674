(function () {
    'use strict';

    angular.module('modules.globalPolicy', [
        'app.components.label',
        'ui.bootstrap.tooltip',
        'plugins.lodash',
        'blocks.helpers',
    ]);
})();
