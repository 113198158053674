(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogRule', {
        templateUrl: 'app/forms/global-policy/dialogs/rule/rule.html',
        controller: PolicyRuleController,
        controllerAs: 'vm',
        bindings: {
            rule: '<',
            monDialogId: '@',
            setForm: '&?',
        },
    });

    PolicyRuleController.$inject = ['PolicyFormHelper', '$timeout'];
    /* @ngInject */
    function PolicyRuleController (PolicyFormHelper, $timeout) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.ruleType = PolicyFormHelper.getSubjectTypeOption(vm.rule.type);
            vm.cRule = angular.copy(vm.rule);
            if (typeof vm.cRule.case_sensitive !== 'boolean') {
                vm.cRule.case_sensitive = true;
            }
            vm.cRule.inverse = vm.cRule.inverse || false;
            vm.cRule.multiline = vm.cRule.multiline || false;
            vm.cRule.sub_entry = vm.cRule.sub_entry || '';
            vm.cRule.sub_entry = vm.cRule.sub_entry.split(',').filter(Boolean);
            vm.countList = [
                'external_link_count',
                'incoming_link_count',
                'page_title_length',
                'link_text_length',
                'image_text_length',
                'header_text_length',
            ];
            vm.standardBlacklist = [
                'readability_level',
                'file_size',
                'image_size',
                'meta_header',
                'meta_header_length',
            ].concat(vm.countList);
            setupHeaderClasses();

            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.form });
                }
            });
        }

        function setupHeaderClasses () {
            vm.headerClass = '';
            vm.pushHeader = false;

            if (!(angular.isString(vm.ruleType.description) && vm.ruleType.description.length > 0)) {
                // Push the title 5px if no description is available
                vm.pushHeader = true;
                vm.headerClass += 'pt-1';
            }
        }
    }
})();
