(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceOpportunitiesByImpact', {
        templateUrl:
            'app/modules/page-performance/components/charts/opportunities-by-impact/opportunities-by-impact.html',
        controller: PagePerformanceOpportunitiesByImpactController,
        controllerAs: 'vm',
        bindings: {
            audits: '<',
            goToSection: '&',
        },
    });

    PagePerformanceOpportunitiesByImpactController.$inject = [
        'PagePerformanceAuditImpactService',
        'PAGE_PERFORMANCE_AUDIT_IMPACT',
        'Lodash',
        '$filter',
    ];
    /* @ngInject */
    function PagePerformanceOpportunitiesByImpactController (
        PagePerformanceAuditImpactService,
        PAGE_PERFORMANCE_AUDIT_IMPACT,
        Lodash,
        $filter,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;

        function activate () {
            vm.passedAuditsCount = 0;
            vm.notPassedAuditsCount = 0;
            vm.highImpactPercentage = '0%';
            vm.mediumImpactPercentage = '0%';
            vm.lowImpactPercentage = '0%';
            vm.impacts = [];
            getImpactValues();
        }

        function onChanges () {
            getImpactValues();
        }

        function getImpactValues () {
            if (vm.audits.length === 0) {
                return;
            }
            vm.highImpacts = [];
            vm.mediumImpacts = [];
            vm.lowImpacts = [];
            vm.passedAudits = [];
            vm.failedAudits = [];
            vm.impacts = vm.audits.map(function (audit) {
                return {
                    label: PagePerformanceAuditImpactService.getImpactTranslation(audit),
                    value: PagePerformanceAuditImpactService.getImpactValue(audit),
                };
            });
            Lodash.forEach(vm.audits, function (audit) {
                if (audit.score === 1) {
                    vm.passedAudits.push(audit);
                } else {
                    vm.failedAudits.push(audit);
                    var impactValue = PagePerformanceAuditImpactService.getImpactValue(audit);
                    switch (impactValue) {
                        case PAGE_PERFORMANCE_AUDIT_IMPACT.HIGH:
                            vm.highImpacts.push(audit);
                            break;
                        case PAGE_PERFORMANCE_AUDIT_IMPACT.MEDIUM:
                            vm.mediumImpacts.push(audit);
                            break;
                        case PAGE_PERFORMANCE_AUDIT_IMPACT.LOW:
                            vm.lowImpacts.push(audit);
                            break;
                    }
                }
            });
            vm.highImpactPercentage = $filter('monNumber')((vm.highImpacts.length * 100) / vm.failedAudits.length, '0');
            vm.mediumImpactPercentage = $filter('monNumber')(
                (vm.mediumImpacts.length * 100) / vm.failedAudits.length,
                '0',
            );
            vm.lowImpactPercentage = $filter('monNumber')((vm.lowImpacts.length * 100) / vm.failedAudits.length, '0');
            vm.passedAuditsCount = vm.passedAudits.length;
            vm.notPassedAuditsCount = vm.failedAudits.length;
        }
    }
})();
