import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { PiwikValidationService } from '../../services/piwik-validation/piwik-validation.service';

@Directive({
    selector: '[monPiwikValidation]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PiwikValidationDirective,
            multi: true,
        },
    ],
    standalone: true,
})
export class PiwikValidationDirective {

    constructor (
        private piwikValidationService: PiwikValidationService,
    ) { }

    validate (control: AbstractControl): ValidationErrors | null {
        if (!control.value || this.piwikValidationService.validateUrl(control.value)) {
            return null;
        }
        return {
            pattern: 'Error',
        };
    }
}
