import { NgModule } from '@angular/core';
import { MonBrowserService } from './mon-browser.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [MonBrowserService],
})
export class MonBrowserModule {}
