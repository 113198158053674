(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScanLoginTypesBasicAuth', {
        templateUrl: 'app/forms/domain/steps/scan/login-types/basic-auth/basic-auth.html',
        controller: DomainScanLoginTypesBasicAuthController,
        controllerAs: 'vm',
        bindings: {
            login: '=',
            url: '<',
            whenHostValidityChange: '&',
        },
    });

    DomainScanLoginTypesBasicAuthController.$inject = ['ng2MonModalService', 'gettextCatalog', 'ng2GlobalHelperService'];
    /* @ngInject */
    function DomainScanLoginTypesBasicAuthController (ng2MonModalService, gettextCatalog, ng2GlobalHelperService) {
        var vm = this;
        vm.$onInit = activate;
        vm.addHost = addHost;
        vm.removeHost = removeHost;
        vm.isValidHostname = isValidHostname;

        function activate () {
            vm.login = vm.login || {};
            vm.validHostname = [];
            if (!isObject(vm.login.basic_auth)) {
                vm.login.basic_auth = {};
            }

            if (!Array.isArray(vm.login.basic_auth.hosts)) {
                setupHostsArray();
            }

            calculateValidHost();
        }

        function addHost () {
            vm.login.basic_auth.hosts.push('');
            calculateValidHost();
        }

        function removeHost (index) {
            ng2MonModalService.confirm(gettextCatalog.getString('Are you sure you want to remove this host?')).then(function () {
                vm.login.basic_auth.hosts.splice(index, 1);
                vm.validHostname.splice(index, 1);
                calculateValidHost();
            }, angular.noop);
        }

        function isObject (val) {
            return val !== null && typeof val === 'object' && !Array.isArray(val);
        }

        function setupHostsArray () {
            vm.login.basic_auth.hosts = [''];
            if (typeof vm.url === 'string') {
                vm.login.basic_auth.hosts = [vm.url.replace(/(^\w+:|^)\/\//, '')];
            }
        }

        function isValidHostname (hostnames, index) {
            vm.validHostname[index] = hostnames[index] && ng2GlobalHelperService.isValidHostname(hostnames[index]);
            updateValidity();
        }

        function calculateValidHost () {
            vm.validHostname = vm.login.basic_auth.hosts.map((hostName) => Boolean(hostName && ng2GlobalHelperService.isValidHostname(hostName)));
            updateValidity();
        }

        function updateValidity () {
            const invalid = vm.validHostname.some(v => !v);
            if (vm.whenHostValidityChange) {
                vm.whenHostValidityChange({ valid: !invalid });
            }
        }
    }
})();
