(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardPdfDoughnut', {
        templateUrl: 'app/pages/accessibility/dashboard/pdf/doughnut/doughnut.html',
        controller: AccessibilityDashboardPdfDoughnutController,
        controllerAs: 'vm',
        bindings: {
            data: '<',
            type: '@',
            typeAsText: '@',
        },
    });

    AccessibilityDashboardPdfDoughnutController.$inject = ['$filter', 'gettextCatalog', '$state', 'ng2MonUIRouterTransitionService'];
    /* @ngInject */
    function AccessibilityDashboardPdfDoughnutController ($filter, gettextCatalog, $state, ng2MonUIRouterTransitionService) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToPDF = goToPDF;

        function activate () {
            vm.title = $filter('translateWithPlural')(
                gettextCatalog.getString('Pending PDF review'),
                vm.data.unreviewed,
                gettextCatalog.getString('Pending PDF reviews'),
            );
            setupDoughnutChart();
        }

        function setupDoughnutChart () {
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            vm.doughnutData = [];
            vm.totalPDF = vm.data.unreviewed;
            vm.doughnutData.push(vm.data.reviewed);
            vm.doughnutData.push(vm.data.unreviewed || 1);
            var percentage = (vm.data.reviewed / vm.data.total) * 100 || 0;

            vm.options = {
                maintainAspectRatio: true,
                centerText: {
                    percentageLabel: $filter('monNumber')(percentage / 100, '0.[00] %'),
                    subLabel: gettextCatalog.getString('reviewed'),
                    totalLabel: vm.typeAsText,
                    totalFontSize: 170,
                    subFontSize: 170,
                },
                cutoutPercentage: 80,
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem) {
                            if (tooltipItem.index === 0) {
                                return getTooltipLabelText(vm.data.reviewed, true);
                            }
                            return getTooltipLabelText(vm.data.reviewed, false);
                        },
                    },
                },
            };

            vm.labels = [gettextCatalog.getString('Reviewed'), gettextCatalog.getString('Pending')];
        }

        function goToPDF () {
            if (vm.type === 'internal') {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.accessibility.internal_pdf'),
                );
            } else {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.accessibility.external_pdf'),
                );
            }
        }

        function getTooltipLabelText (pages, reviewed) {
            pages = pages || 0;
            var scope = {
                pages: $filter('numeraljs')(pages),
            };
            var suffix = reviewed ? 'reviewed' : 'pending';
            var single = '1 page ' + suffix;
            var multi = '{{pages}} pages ' + suffix;
            return $filter('translateWithPlural')(single, pages, multi, scope);
        }
    }
})();
