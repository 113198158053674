(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name accessibilityChecksByResultTypes
     * @description Filter accessibility checks according to their result type(s)
     */

    angular
        .module('filters.monsido')
        .filter('accessibilityChecksByResultTypes', AccessibilityChecksByResultTypesFilter);

    AccessibilityChecksByResultTypesFilter.$inject = [];

    /* @ngInject */
    function AccessibilityChecksByResultTypesFilter () {
        return filter;

        // //////////

        /**
         * @param {Array} checks
         * @param {Object} params {
         *      only_errors: {Boolean}
         *      abbreviations: {Array[string]}
         *      result_types: {Array[string]} Current options are error, warning, review
         * }
         * @return {Array}
         */
        function filter (checks, params) {
            if (!Array.isArray(checks)) {
                return [];
            }

            params = params || {};
            params.only_errors = Boolean(params.only_errors);
            params.abbreviations = params.abbreviations || [];
            params.abbreviations.map(function (abbr) {
                return abbr.toUpperCase();
            });

            if (!Array.isArray(params.result_types) || params.result_types.length === 0) {
                params.result_types = ['error', 'warning', 'review'];
            }

            return checks.filter(function (c) {
                if (c.ignored) {
                    return false;
                }

                if (params.only_errors && c.pages_count === 0) {
                    return false;
                }

                if (params.result_types.indexOf(c.result_type) === -1) {
                    return false;
                }

                if (Array.isArray(params.abbreviations) && params.abbreviations.length > 0) {
                    if (params.abbreviations.indexOf(c.issue_abbr) === -1) {
                        return false;
                    }
                }

                return true;
            });
        }
    }
})();
