(function () {
    'use strict';

    angular.module('services.entities', [
        'services.entities.user',
        'modules.demodata',
        'blocks.router',
        'core.constant',
        'services.api',
    ]);
})();
