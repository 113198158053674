(function () {
    'use strict';

    angular.module('app.components.pages', [
        'app.core',
        'app.components.table',
        'app.components.layout',
        'services.api',
        'mon.helpers.base64',
        'modules.translations',
        'filters.monsido',
    ]);
})();
