(function () {
    'use strict';

    angular.module('app.forms').component('formProfile', {
        templateUrl: 'app/forms/profile/profile.html',
        controller: ProfileController,
        controllerAs: 'vm',
        bindings: {
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    ProfileController.$inject = [
        'monDialog',
        'gettextCatalog',
        'ng2MonModalService',
        '$timeout',
        'ng2UserLocaleService',
        'ng2MeService',
        'ng2CoreLoginService',
        'ng2SessionService',
        'translation',
        '$state',
        'ng2DateTimeService',
    ];
    /* @ngInject */
    function ProfileController (
        monDialog,
        gettextCatalog,
        ng2MonModalService,
        $timeout,
        ng2UserLocaleService,
        ng2MeService,
        ng2CoreLoginService,
        ng2SessionService,
        translation,
        $state,
        ng2DateTimeService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.setup2FA = setup2FA;

        function activate () {
            vm.saving = false;

            ng2CoreLoginService.getUser().then(function (user) {
                vm.user = user;
                vm.profile = angular.copy(user);
                if (ng2SessionService.agreement && ng2SessionService.agreement.settings) {
                    vm.profile.settings = angular.copy(ng2SessionService.agreement.settings);
                }
            }, angular.noop);
            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.dialogForm });
                }
            });
            setupLocaleOptions();
        }

        function setupLocaleOptions () {
            vm.localeOptions = ng2UserLocaleService.getLocaleOptions();
        }

        function setup2FA () {
            ng2CoreLoginService.setup2FA();
        }

        function submit () {
            vm.saving = true;
            if (!vm.profile.password && !vm.profile.password_confirmation) {
                delete vm.profile.password;
                delete vm.profile.password_confirmation;
            }

            if (vm.profile.password === vm.profile.password_confirmation) {
                const currentLang = vm.user.locale;
                const newLang = vm.profile.locale;

                translation.setLanguage(newLang);
                ng2DateTimeService.setLocale(newLang);

                ng2MeService
                    .update(vm.profile)
                    .then(function (me) {
                        if (vm.dialogForm) {
                            vm.dialogForm.$setPristine();
                        }
                        if (newLang !== currentLang) {
                            $state.go($state.current, {}, { reload: true });
                        }
                        monDialog.close(vm.monDialogId, me);
                    }, function () {
                        translation.setLanguage(currentLang);
                    })
                    .finally(function () {
                        vm.saving = false;
                    });
            } else {
                ng2MonModalService.alert(gettextCatalog.getString('Passwords does not match'));
            }
        }
    }
})();
