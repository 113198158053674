import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.export').component('csvExportDialog', {
        templateUrl: 'app/modules/export/components/dialog/dialog.html',
        controller: MultipleExportsController,
        controllerAs: 'vm',
        bindings: {
            exports: '<',
            monDialogId: '@',
        },
    });

    MultipleExportsController.$inject = ['ng2ExportService', 'ng2SessionService', 'monDialog', 'gettextCatalog', 'ng2MonEventsService'];
    /* @ngInject */
    function MultipleExportsController (ng2ExportService, ng2SessionService, monDialog, gettextCatalog, ng2MonEventsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.download = download;
        vm.goToExports = goToExports;
        vm.modifyDefaultExportNumber = modifyDefaultExportNumber;

        function activate () {
            vm.params = {};
            vm.startExportTxt = gettextCatalog.getString('Start export');
            vm.onDownload = false;
            vm.downloadDone = false;
            vm.confirm = false;
            vm.modify = false;
        }

        function goToExports () {
            var params = {
                body: 'csvExportsOverlay',
            };
            monDialog.close(vm.monDialogId);
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function download (exportParams, index) {
            const isValidExportsInputsArray = Array.isArray(vm.exports) &&
                vm.exports[index] &&
                Array.isArray(vm.exports[index].inputs) &&
                vm.exports[index].inputs.length > 0;

            const params = {
                recipients: [ng2SessionService.me.email],
            };

            angular.forEach(exportParams, function (value, key) {
                params[key] = value;
            });

            if (params.date) {
                params.category_ref =
                    params.date.startDate.format('YYYY-MM-DD') +
                    ',' +
                    params.date.endDate.format('YYYY-MM-DD') +
                    ',' +
                    (params.category_ref < 1000 ? params.category_ref : 1000);
            }

            if (isValidExportsInputsArray) {
                params.category_ref =
                    params.date.startDate.format('YYYY-MM-DD') +
                    ',' +
                    params.date.endDate.format('YYYY-MM-DD') +
                    ',' +
                    (vm.exports[index].inputs[0].default < 1000 ? vm.exports[index].inputs[0].default : 1000);
            }

            if (!params.isGlobalExport) {
                if (ng2SessionService.domainGroup) {
                    params.source_type = 'DomainGroup';
                    params.source_id = ng2SessionService.domainGroup.id;
                } else if (ng2SessionService.domain) {
                    params.source_type = 'Domain';
                    params.source_id = ng2SessionService.domain.id;
                }

                if (!params.source_type) {
                    delete params.source_type;
                }

                if (!params.source_id) {
                    delete params.source_id;
                }
            }
            vm.onDownload = true;
            vm.params = params;
            ng2ExportService.create(params)
                .toPromise()
                .then(function () {
                    vm.onDownload = false;
                    vm.downloadDone = true;
                }, angular.noop);
        }

        function modifyDefaultExportNumber () {
            vm.modify = true;
            vm.confirm = false;
        }
    }
})();
