import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { Page } from '@monsido/ng2/modules/page-details/models';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';
import { SpellingModel, SpellingModelType } from './spelling-model';
import { RequestHeadersType } from '@monsido/ng2/external/http/options';

type SpellingRepoGetIgnoredSpellingErrorsRequestParamsType = BaseRepoRequestType & {
    type: string,
    language: string,
    domainId: number,
    classification?: string,
};

type SpellingRepoGetAllErrorsRequestType = BaseRepoRequestType & {
    domainId?: number,
    classification: string,
    page_fixed: boolean,
    language?: string,
    labels?: number[],
};


type SpellingRepoGetAllPagesRequestParamsType = BaseRepoRequestType & {
    classification: string,
    type: string,
};

type SpellingRepoConfirmRequestParamsType = BaseRepoRequestType & {
    id: number,
    labels: number[],
    classification?: string,
}

type SpellingRepoPageIgnoreSpellingErrorType = {
    created_at: string,
    id: number,
    updated_at: string,
    word: string,
};

type SpellingRepoAuthorType = {
    id: number,
    email: string,
    first_name: string,
    last_name: string
};

type SpellingRepoIgnoredSpellingErrorType = {
    added_by: SpellingRepoAuthorType
    classification: string,
    created_at: string,
    customer_id: number;
    id: number;
    language: string | null,
    updated_at: string,
    word: string,
};

type SpellingRepoPageSpellingErrorRequestParamsType = BaseRepoRequestType & {
    language: string,
    classification: string,
    word: string,
};

type SpellingRepoSummeryType = {
    confirmed_misspellings: number,
    languages: number,
    potential_misspellings: number,
};

type SpellingRepoDeleteWordType = {
    action: string,
    author: string,
    created_at: string,
    customer_id: number,
    data: {
        group: number | null
        identifier: string,
        locale: string | null
    };
    domain_id: number,
    id: number,
    module: string,
    target_uri: string,
}

type SpellingRepoPageType = Omit<Page, 'cms_url' | 'words_count' | 'readability_words_count' | 'data_protection_violations_counts'>;

type SpellingRepoLanguage = {
    confirmed_misspellings_count: number,
    html_page_count: number,
    lang: string,
    page_count: number,
    potential_misspellings_count: number,
}

@Injectable({
    providedIn: 'root',
})
export class SpellingRepoService extends BaseRepo {

    constructor (protected sessionService: SessionService, private apiService: ApiService) {
        super();
    }

    destroyPageSpellingError (pageId: number, errorId: number): Promise<void> {
        return this.apiService.delete('domains/' + this.getDomainId() + '/pages/' + pageId + '/spelling_errors/' + errorId);
    }

    destroySpellingError (errorId: number): Promise<void> {
        return this.apiService.delete('domains/' + this.getDomainId() + '/spelling/errors/' + errorId);
    }

    updatePageIgnoreSpellingError (pageId: number, errorId: number): Promise<SpellingRepoPageIgnoreSpellingErrorType> {
        return this.apiService.post('domains/' + this.getDomainId() + '/pages/' + pageId + '/spelling_errors/' + errorId + '/ignore');
    }

    getAllPageSpellingErrors (pageId: number, params: SpellingRepoPageSpellingErrorRequestParamsType): Promise<SpellingModel[]> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/pages/' + pageId + '/spelling_errors', params)
            .then((res: SpellingModelType[]) => this.toSpellingModels(res));
    }

    getAllIgnoredSpellingErrors (params: SpellingRepoGetIgnoredSpellingErrorsRequestParamsType): Promise<SpellingRepoIgnoredSpellingErrorType[]> {
        params = params || {};
        params = this.setGroupId(params);
        params.classification = 'ignore';
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/spelling/dictionary', params);
    }

    getAllIgnoredPageSpellingErrors (pageId: number, params: BaseRepoRequestType): Promise<MonTableCollection<SpellingRepoPageIgnoreSpellingErrorType>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/pages/' + pageId + '/ignored_spelling_errors', params);
    }

    destroyIgnoredPageSpellingError (pageId: number, spellingId: number): Promise<unknown> {
        let params = {};
        params = this.setGroupId(params);
        return this.apiService.delete('domains/' + this.getDomainId() + '/pages/' + pageId + '/ignored_spelling_errors/' + spellingId, params);
    }

    getSummary (params: BaseRepoRequestType): Promise<SpellingRepoSummeryType> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/spelling/summary', params);
    }

    getAllPages (params: SpellingRepoGetAllPagesRequestParamsType): Promise<SpellingRepoPageType[]> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/spelling/pages', params);
    }

    getAllWords (params: SpellingRepoGetIgnoredSpellingErrorsRequestParamsType): Promise<SpellingRepoIgnoredSpellingErrorType> {
        params = params || {};
        params = this.setGroupId(params);
        params.classification = 'dictionary';
        return this.apiService.get(
            'domains/' + this.getDomainId(params.domain_id) + '/spelling/dictionary',
            params,
        );
    }

    destroyWord (wordId: number): Promise<SpellingRepoDeleteWordType> {
        return this.apiService.delete('domains/' + this.getDomainId() + '/spelling/dictionary/' + wordId);
    }

    getAllErrors (params: SpellingRepoGetAllErrorsRequestType): Promise<MonTableCollection<SpellingModel>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domainId) + '/spelling/errors', params)
            .then((res: MonTableCollection<SpellingModelType>) => this.toSpellingModels(res));
    }

    getAllPagesWithError (errorId: number, params: BaseRepoRequestType, config: RequestHeadersType): Promise<SpellingRepoPageType[]> {
        config = config || {};
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/spelling/errors/' + errorId + '/pages', params, config);
    }

    addToDictionary (params: Partial<SpellingRepoPageSpellingErrorRequestParamsType>): Promise<SpellingRepoIgnoredSpellingErrorType> {
        params = params || {};
        return this.apiService.post('domains/' + this.getDomainId(params.domain_id) + '/spelling/dictionary', params);
    }

    confirm (wordId: number, params: SpellingRepoConfirmRequestParamsType): Promise<SpellingModel> {
        params = params || {};
        if (!params.group_id) {
            params = this.setGroupId(params);
        }
        return this.apiService.patch(
            'domains/' + this.getDomainId(params.domain_id) + '/spelling/errors/' + wordId,
            params,
        ).then((res: SpellingModelType) => this.toSpellingModel(res));
    }

    getAllLanguages (params: BaseRepoRequestType): Promise<SpellingRepoLanguage[]> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/spelling/languages', params);
    }

    private toSpellingModels (models: MonTableCollection<SpellingModelType>): MonTableCollection<SpellingModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toSpellingModel(models[i]);
        }
        return models as SpellingModel[];
    }

    private toSpellingModel (model: SpellingModelType): SpellingModel {
        return new SpellingModel(model);
    }
}
