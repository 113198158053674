import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';

export type SpellingToolLanguageType = {
    code: string;
    name: string;
};

@Injectable({
    providedIn: 'root',
})
export class SpellingToolRepoService {

    constructor (private apiService: ApiService) { }

    get (): Promise<SpellingToolLanguageType[]> {
        return this.apiService.get('spelling_tool/languages');
    }
}
