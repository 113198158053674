(function () {
    'use strict';

    angular.module('pages.policy').component('policyPriority', {
        templateUrl: 'app/pages/policy/policy/priority/priority.html',
        controller: PolicyDashboardPriorityController,
        controllerAs: 'vm',
        bindings: {
            policies: '<',
        },
    });

    PolicyDashboardPriorityController.$inject = ['gettextCatalog'];

    function PolicyDashboardPriorityController (gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            var max;
            vm.chartLabels = [
                gettextCatalog.getString('High'),
                gettextCatalog.getString('Medium'),
                gettextCatalog.getString('Low'),
            ];
            vm.chartData = [[getByPriority('high'), getByPriority('medium'), getByPriority('low')]];
            vm.chartSeries = [''];
            max = vm.chartData[0].reduce(function (a, b) {
                return Math.max(a, b);
            }, 0);
            vm.chartOptions = {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            ticks: {
                                min: 0,
                                max: max * 1.5,
                            },
                            display: false,
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            categoryPercentage: 0.5,
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                fontColor: '#a6a6a6',
                                fontSize: 11,
                            },
                        },
                    ],
                },
                drawLabelOnChart: true,
            };

            vm.barColors = ['#a8d6f3', '#a8d6f3', '#a8d6f3'];

            vm.chartOverrides = [
                {
                    borderWidth: 0,
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                },
            ];
        }

        function getByPriority (priority) {
            return vm.policies.reduce(function (num, policy) {
                if (policy.priority === priority && policy.matches_count > 0) {
                    num++;
                }
                return num;
            }, 0);
        }
    }
})();
