<div class="alert alert-warning font-size-1-3 font-weight-500 justify-content-center row" *ngIf="domain | isStatsDemoData" translate>
    Statistics results are sample data for demo purposes - export is disabled
</div>
<mon-page-header [monIcon]="['STATISTICS', 'SIDE_MENU', 'SITE_SEARCH']"
    header="{{'Site Search' | translate }}">
    <div class="header-actions">
        <mon-link class="ml-1" [monHref]="monHref" monLinkText="{{ 'Help Center' | translate }}" ></mon-link>
    </div>
</mon-page-header>
<div class="d-flex justify-content-end align-items-center mb-2">
    <mon-date-picker
        [date]="(selectedDateRange$ | async) ?? {}"
        [monDateFormat]="dateFormat"
        (dateChange)="onSelectedDateRange($event)"
        class="events-date-picker"
    />
    <mon-export-button *ngIf="!(domain | isStatsDemoData)" [exports]="exports"></mon-export-button>
    <mon-table-search
        (getPage)="search$.next($event)"
        [model]="(search$ | async) ?? ''"
        [minSearchLength]="2"
    >
    </mon-table-search>
</div>
<div class="card panel-monsido-card">
    <div class="card-body">
        <mon-horizontal-bar-chart
            *ngIf="!loading"
            [data]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [colors]="chartColors"
            [series]="chartSeries"
            [override]="chartOverride"
            [monTooltipModel]="tooltipModel"
            (chartRefChange)="onChartRefChange($event)"
            [monTooltipChartRef]="chartRef">
        </mon-horizontal-bar-chart>
        <mon-table-container-pager [collection]="collection"
            class="table-vertial-align"
            [page]="getSiteSearchParams$.value.page"
            [pageSize]="getSiteSearchParams$.value.limit"
            [progress]="loading"
            (onPageChange)="onPageChange($event)"
            (onPerPageChange)="onPageSizeChange($event)">
            <div class="table-container-body">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th *ngFor="let header of headers; let i = index;"
                                class="col-sm-7 col-lg-9"
                                [ngClass]="{ 'text-right' : i !== 0 }"
                                monTableSort
                                [monSortBy]="header.value"
                                [monSortDirection]="getSiteSearchParams$.value.sortDirection"
                                [monCurrentlySortedBy]="getSiteSearchParams$.value.sortBy"
                                [monStartAsc]="getSiteSearchParams$.value.sortDirection === 'asc'"
                                (monOnSorted)="onSortEvents($event)"
                                >{{ header.name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of collection; let i = index;">
                            <td>{{ row.label }}</td>
                            <td class="text-right">{{ row.visits }}</td>
                            <td class="text-right">{{ row.pages_per_search }}</td>
                            <td class="text-right">{{ row.exit_rate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container-pager>
    </div>
</div>
