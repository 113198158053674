(function () {
    'use strict';

    angular.module('pages.policy').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog', 'RouteHelperService'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog, RouteHelperService) {
        routerHelper.configureStates(getStates());
        function getStates () {
            return [
                RouteHelperService.makeRouteWithActiveFeatures({
                    name1: 'domain',
                    name2: 'policy',
                    url: '/policies',
                    features: ['global_policy', 'policies_all'],
                    redirectURI: 'base.dashboard',
                    abstract: true,
                    sidebar: 'policy',
                }),
                ...[
                    ['dashboard', '/summary', '<pages-policy></pages-policy>', gettextCatalog.getString('Acquia Optimize - Policies')],
                    ['pages', '/pages', '<pages-policy-pages></pages-policy-pages>', gettextCatalog.getString('Acquia Optimize - Content With Policy Matches')],
                    ['policies', '/policies?search&policy_id&tab', '<pages-policy-policies></pages-policy-policies>', gettextCatalog.getString('Acquia Optimize - Policy List')],
                    ['ignored_pages', '/pages_with_ignored_checks', '<pages-policy-ignored-pages></pages-policy-ignored-pages>', gettextCatalog.getString('Acquia Optimize - Policy Pages With Ignored Checks')],
                ]
                    .map((type) => RouteHelperService.makeRoute({
                        state: `base.customer.domain.policy.${type[0]}`,
                        url: `${type[1]}`,
                        template: `${type[2]}`,
                        title: type[3],
                    })),
            ];
        }
    }

})();
