import { Injectable } from '@angular/core';
import { INFOTYPE_ICON } from '../../constants/into-type-icon.constant';

@Injectable()
export class MonDataPrivacyInfoTypeIconService {
    getIcon (group: string): string {
        if (!group) {
            return INFOTYPE_ICON.OTHER;
        }
        return INFOTYPE_ICON[group.toUpperCase()] || INFOTYPE_ICON.OTHER;
    }

    getColor (priority: string): string {
        return priority;
    }
}
