import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/ng2/modules/endpoints/api/api-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export type GroupUpdateStatusType = {
    id: number;
    updating: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DomainGroupUpdateInfoService {

    static readonly updateIntervalMs = 30000;

    private updateInProgress: Record<number, BehaviorSubject<Set<number>>> = {};

    constructor (private apiClient: ApiClient) { }

    getUpdateStatus (domainId: number, groupIds: number[], until?: Subject<unknown>): Observable<Set<number>> {
        return this.requestPolling(domainId, groupIds, until);
    }

    private requestPolling (domainId: number, groupIds: number[], until?: Subject<unknown>): Observable<Set<number>> {
        if (this.updateInProgress[domainId] == null) {
            this.updateInProgress[domainId] = new BehaviorSubject(new Set(groupIds));
            this.doPolling(domainId);
        } else {
            const currentIds = this.updateInProgress[domainId].getValue();
            groupIds.forEach(id => currentIds.add(id));
            this.updateInProgress[domainId].next(currentIds);
        }

        let result$ = this.updateInProgress[domainId];

        // this should happen only on unit tests,
        // when "doPolling" is executed synchronously
        if (!result$) {
            result$ = new BehaviorSubject(new Set());
        }

        if (until) {
            return result$.asObservable()
                .pipe(
                    takeUntil(until),
                );
        } else {
            return result$.asObservable();
        }
    }

    private doPolling (domainId: number): void {
        const groupIDs = this.updateInProgress[domainId]?.getValue();
        if (groupIDs) {
            this.apiClient.getObservable<GroupUpdateStatusType[]>(`/domains/${domainId}/domain_groups/update`, {
                params: { group_ids: Array.from(groupIDs).toString() },
            })
                .pipe(
                    take(1), // just for extra safety; apiClient should complete it's observable
                )
                .subscribe((data) => {
                    this.processUpdatingStatus(domainId, data);
                    setTimeout(() => {
                        this.doPolling(domainId);
                    }, DomainGroupUpdateInfoService.updateIntervalMs);
                });
        }
    }

    private processUpdatingStatus (domainId: number, data: GroupUpdateStatusType[]): void {
        const groupIDs = this.updateInProgress[domainId]?.getValue();
        if (!groupIDs) {
            return;
        }
        data.forEach(entry => {
            if (entry.updating) {
                groupIDs.add(entry.id);
            } else {
                groupIDs.delete(entry.id);
            }
        });
        this.updateInProgress[domainId].next(groupIDs);

        if (!groupIDs.size) {
            this.updateInProgress[domainId].complete();
            delete this.updateInProgress[domainId];
        }
    }
}
