(function () {
    'use strict';

    angular.module('modules.export').component('csvExportAuditLogDialog', {
        templateUrl: 'app/modules/export/components/dialog/dialog-auditlog.html',
        controller: MultipleExportsController,
        controllerAs: 'vm',
        bindings: {
            monDialogId: '@',
            total: '<',
            running: '<',
            params: '<',
        },
    });

    MultipleExportsController.$inject = ['monDialog', 'exporter'];
    /* @ngInject */
    function MultipleExportsController (monDialog, exporter) {
        var vm = this;
        vm.$onInit = activate;
        vm.cancel = cancel;

        function activate () {}
        function cancel () {
            exporter.cancelAuditLog();
            monDialog.close(vm.monDialogId);
        }
    }
})();
