(function () {
    'use strict';
    angular.module('app.components').component('pagesWithDuplicateTitleTitles', {
        templateUrl: 'app/pages/seo/dialogs/pages-with-duplicate-title/blocks/titles/titles.html',
        controller: PagesWithDuplicateTitleTitlesController,
        controllerAs: 'vm',
        bindings: {
            selectedTitle: '=',
            onPerPageChange: '&',
            onPageChange: '&',
            onSortPage: '&',
            titles: '<',
            currentSort: '<',
            promise: '<',
        },
    });

    PagesWithDuplicateTitleTitlesController.$inject = [];

    function PagesWithDuplicateTitleTitlesController () {
        var vm = this;
        vm.$onInit = activate;
        vm.changeSelectedTitle = changeSelectedTitle;
        vm.perPageChange = perPageChange;
        vm.pageChange = pageChange;

        function activate () {}

        function changeSelectedTitle (title) {
            vm.selectedTitle = title;
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (pageSize) {
            vm.onPerPageChange({ pageSize: pageSize });
        }
    }
})();
