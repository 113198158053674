(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name monNumber
     * @description Filter numbers by the user's locale
     */

    angular.module('filters.monsido').filter('monNumber', MonNumberFilter);

    MonNumberFilter.$inject = ['$filter'];

    /* @ngInject */
    function MonNumberFilter ($filter) {
        return filter;

        // //////////

        /**
         * @memberOf monNumber
         * @param {integer} num
         * @param {string} format
         * @return {string}
         */
        function filter (num, format) {
            return $filter('numeraljs')(num, format);
        }
    }
})();
