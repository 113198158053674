(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsPolicyInformation', {
        templateUrl: 'app/components/pagedetails/details/sections/policies/policy-information/policy-information.html',
        controller: SectionPoliciesInformationController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
            refreshPolicies: '&',
            page: '<',
            matchLimit: '=',
        },
    });

    SectionPoliciesInformationController.$inject = [
        'gettextCatalog',
        'ng2SessionService',
        'policyService',
        '$timeout',
        '$filter',
        'PolicyHelper',
        'PolicyFormHelper',
        '$scope',
        'ng2MonModalService',
        'pageService',
        'ng2MonEventsService',
    ];

    function SectionPoliciesInformationController (
        gettextCatalog,
        ng2SessionService,
        policyService,
        $timeout,
        $filter,
        PolicyHelper,
        PolicyFormHelper,
        $scope,
        ng2MonModalService,
        pageService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.runPolicy = runPolicy;
        vm.updatePolicyStatus = updatePolicyStatus;
        vm.getPolicyTarget = getPolicyTarget;
        vm.deletePolicy = deletePolicy;
        vm.getLastRunAt = getLastRunAt;
        vm.findIndexOfElement = findIndexOfElement;
        vm.isMatchALink = isMatchALink;
        vm.isMatchAnImage = isMatchAnImage;
        vm.loadMoreMatches = loadMoreMatches;
        vm.dropdownOptions = [];

        function activate () {
            setupTabs();
            vm.policy = {};
            vm.domain = ng2SessionService.domain;
            setupWatchers();
        }

        function setupWatchers () {
            $scope.$watch('vm.policy', function () {
                getElements();
                updateDropdown();
            });
        }

        function updateDropdown () {
            const dropdown = [
                {
                    label: gettextCatalog.getString('Run policy again'),
                    leftIcon: 'faPlay',
                    action: runPolicy,
                    eventParams: { action: 'page-details-policies-return' },
                    shouldShow: vm.policy.state !== 'running' && vm.policy.state !== 'queued',
                },
                {
                    label: gettextCatalog.getString('Policy in queue'),
                    leftIcon: 'faPlay',
                    shouldShow: vm.policy.state === 'queued',
                    disabled: true,
                },
                {
                    label: gettextCatalog.getString('Unignore'),
                    leftIcon: 'faEye',
                    action: ()=>updatePolicyStatus(false),
                    eventParams: { action: 'page-details-policies-unignore' },
                    shouldShow: vm.policy.matches_count > 0 && vm.policy.ignored,
                },
                {
                    label: gettextCatalog.getString('Ignore'),
                    leftIcon: 'faEyeSlash',
                    action: ()=>updatePolicyStatus(true),
                    eventParams: { action: 'page-details-policies-ignore' },
                    shouldShow: vm.policy.matches_count > 0 && !vm.policy.ignored,
                },
                {
                    label: gettextCatalog.getString('Mark as fixed'),
                    leftIcon: 'faCheck',
                    action: deletePolicy,
                    eventParams: { action: 'page-details-policies-mark-fixed' },
                    shouldShow: vm.policy.matches_count > 0 && !vm.policy.ignored,
                },
            ];

            vm.dropdownOptions = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                return {
                    label: item.label,
                    leftIcon: item.leftIcon,
                    disabled: item.disabled,
                    action: ()=>{
                        if (item.eventParams) {
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                        }

                        if (item.action) {
                            item.action();
                        }
                    },
                };
            });
        }

        function setupTabs () {
            vm.tabOptions = [
                {
                    name: gettextCatalog.getString('Information'),
                    value: 'information',
                },
            ];

            vm.tab = vm.tabOptions[0].value;
        }

        function runPolicy () {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString('Are you sure you want to run the policy <strong>{{policyName}}</strong> again?', {
                        policyName: vm.policy.name,
                    }),
                )
                .then(function () {
                    policyService.run(vm.policy.id).then(function () {
                        $timeout(function () {
                            vm.refreshPolicies();
                        }, 50);
                    }, angular.noop);
                }, angular.noop);
        }

        function updatePolicyStatus (status) {
            var message;
            if (status) {
                message = gettextCatalog.getString('Are you sure you want to ignore the policy <strong>{{policyName}}</strong>?', {
                    policyName: vm.policy.name,
                });
            } else {
                message = gettextCatalog.getString('Are you sure you want to unignore the policy <strong>{{policyName}}</strong>?', {
                    policyName: vm.policy.name,
                });
            }
            ng2MonModalService.confirm(message).then(function () {
                var model = {
                    id: vm.policy.id,
                    ignored: status,
                };

                pageService.updatePolicy(vm.page.id, model).then(function () {
                    vm.refreshPolicies({ refreshPage: true });
                }, angular.noop);
            }, angular.noop);
        }

        function deletePolicy () {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString('Are you sure you want to mark the policy <strong>{{policyName}}</strong> as fixed?', {
                        policyName: vm.policy.name,
                    }),
                )
                .then(function () {
                    pageService.destroyPolicy(vm.page.id, vm.policy.id).then(function () {
                        vm.refreshPolicies({ refreshPage: true });
                    }, angular.noop);
                }, angular.noop);
        }

        function getPolicyTarget (policy) {
            return PolicyHelper.getType(policy);
        }

        function getLastRunAt () {
            if (vm.policy.constructor === Object && Object.keys(vm.policy).length > 0) {
                var domainTarget = vm.policy.targets.find(function (domain) {
                    return vm.domain.id === domain.id;
                });
                if (domainTarget && domainTarget.latest_run_at !== null) {
                    return $filter('userDate')(domainTarget.latest_run_at);
                } else {
                    return gettextCatalog.getString('Never');
                }
            }
        }

        function getElements () {
            PolicyFormHelper.setPolicySubject(vm.policy.subject);
            vm.elements = PolicyFormHelper.getSubjectTypeOptions();
        }

        function findIndexOfElement (ruleType) {
            var result = -1;
            for (var i = 0; i < vm.elements.length; i++) {
                if (vm.elements[i].type === ruleType) {
                    result = i;
                    break;
                }
            }
            return result;
        }

        function isMatchALink (match) {
            return PolicyHelper.isMatchLinkable(match.type);
        }

        function isMatchAnImage (match) {
            return PolicyHelper.isMatchAnImage(match.type);
        }

        function loadMoreMatches (last, inview) {
            if (last && inview) {
                vm.matchLimit += 100;
            }
        }
    }
})();
