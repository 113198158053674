import { UrlService } from '@uirouter/core';
import { DEFAULTS } from '@monsido/core/constants/defaults.constant';
// prettier-ignore
type ResolvableParamNamesType = 'currentSort' | 'activeTab' | 'search' | 'page' | 'pageSize';
interface UrlParamInterface {
    name: string;
    type: 'number' | 'string';
    default: number | string;
    propertyName: ResolvableParamNamesType;
}
export abstract class BaseComponent {
    urlParams?: Array<UrlParamInterface>;
    loading: boolean = false;
    currentSort: string = '';
    activeTab: string = '';
    search: string = '';
    page: number = 1;
    pageSize: number = DEFAULTS.API.PAGE_SIZE;
    abstract getPage(): void;
    constructor (private $location: UrlService) {}

    loadLocations (): void {
        if (this.urlParams) {
            for (const param of this.urlParams) {
                // prettier-ignore
                let value: string | number = (<Record<string, string>> this.$location.search())[param.name];

                if (param.type === 'number') {
                    value = Number(value);
                }

                if (value == null || value === '' || isNaN(<number>value)) {
                    value = param.default;
                }

                (this[param.propertyName] as unknown) = value;
            }
        }
        this.getPage();
    }

    onStatus (tab: string): void {
        this.page = 1;
        this.activeTab = tab;
        this.getPage();
    }

    onTabChange (tab: string): void {
        this.activeTab = tab;
        this.page = 1;
        this.getPage();
    }

    onSearch (search: string): void {
        this.search = search;
        this.page = 1;
        this.getPage();
    }

    onPageChange (page: number): void {
        this.page = page;
        this.getPage();
    }

    onPageSizeChange (size: number): void {
        this.pageSize = size;
        this.page = 1;
        this.getPage();
    }

    onPageDetailsClose (): void {
        this.getPage();
    }

    onSortPage (sort: string): void {
        this.page = 1;
        this.currentSort = sort || this.currentSort; // The directive has already overwritten the variable
        this.getPage();
    }
}
