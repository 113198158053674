(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name ninService
     * @description Service to handle ni numbers
     */
    angular.module('services.entities').factory('ninService', ninService);

    ninService.$inject = ['NiNumbersRepo'];
    /* @ngInject*/
    function ninService (NiNumbersRepo) {
        return {
            update: update,
        };

        function update (id, data, params) {
            return NiNumbersRepo.update(id, data, params);
        }
    }
})();
