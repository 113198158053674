(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSourcesSummaryBlockReferringInformation', {
        templateUrl:
            'app/pages/statistics/sources/summary/block-referring-information/block-referring-information.html',
        controller: StatisticsSourcesSummaryBlockReferringInformationController,
        controllerAs: 'vm',
        bindings: {
            header: '@',
            hint: '@',
            headerIcon: '@',
            footer: '@',
            labels: '<',
            content: '<',
            totalVisits: '<',
            footerUrl: '@',
        },
    });

    StatisticsSourcesSummaryBlockReferringInformationController.$inject = [];
    /* @ngInject */
    function StatisticsSourcesSummaryBlockReferringInformationController () {
        var vm = this;

        vm.$onInit = activate;
        function activate () {
            vm.data = [];
            vm.item = [];
            if (!vm.content || vm.content.length === 0) {
                vm.content = [];
                vm.content.total = 0;
            }
        }
    }
})();
