(function () {
    'use strict';

    angular.module('pages.global.policy').component('globalPolicyDistribution', {
        templateUrl: 'app/pages/global/policy/policy/distribution/distribution.html',
        controller: GlobalPolicyDistributionController,
        controllerAs: 'vm',
        bindings: {
            policies: '<',
        },
    });

    GlobalPolicyDistributionController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function GlobalPolicyDistributionController (gettextCatalog) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            var priorities = {
                low: vm.policies.filter(function (policy) {
                    return policy.priority === 'low';
                }),
                medium: vm.policies.filter(function (policy) {
                    return policy.priority === 'medium';
                }),
                hard: vm.policies.filter(function (policy) {
                    return policy.priority === 'high';
                }),
            };

            vm.data = [
                filterDistribution(priorities, 1, 'violation'),
                filterDistribution(priorities, 3, 'required'),
                filterDistribution(priorities, 2, 'search'),
            ];
            vm.series = [
                '',
                gettextCatalog.getString('High'),
                gettextCatalog.getString('Medium'),
                gettextCatalog.getString('Low'),
            ];
            vm.labels = [
                '',
                gettextCatalog.getString('Unwanted'),
                gettextCatalog.getString('Required'),
                gettextCatalog.getString('Matches'),
            ];
            vm.colors = ['#9EBFFA', '#ADD5FD', '#D5E4FE', '#D6E5FE'];
            vm.options = {
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                max: vm.series.length,
                                beginAtZero: true,
                                suggestedMax: vm.series.length * 2,
                                maxTicksLimit: vm.series.length * 2,
                                callback: function (value, index) {
                                    return vm.series[index];
                                },
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                max: vm.labels.length,
                                beginAtZero: true,
                                suggestedMax: vm.labels.length * 2,
                                maxTicksLimit: vm.labels.length * 2,
                                callback: function (value, index) {
                                    return vm.labels[index];
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'point',
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                data.labels[tooltipItem.xLabel] +
                                ': ' +
                                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].val
                            );
                        },
                    },
                },
            };
        }

        function filterDistribution (priorities, x, mode) {
            var result = [];
            var y = 1;
            // eslint-disable-next-line guard-for-in
            for (var i in priorities) {
                var val = priorities[i].filter(function (policy) {
                    return policy.mode === mode && policy.matches_count > 0;
                }).length;
                result.push({
                    x: x,
                    y: y,
                    r: setRadius(val),
                    val: val,
                });
                y += 1;
            }
            return result;
        }

        function setRadius (val) {
            if (val > 0) {
                var maxRadiusOnChartElements = 25; // Pixel values
                var minRadiusOnChartElements = 5;
                if (val > maxRadiusOnChartElements - minRadiusOnChartElements) {
                    return maxRadiusOnChartElements;
                }
                return val + minRadiusOnChartElements;
            }
            return 0.00001;
        }
    }
})();
