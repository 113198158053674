(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsOs', {
        templateUrl: 'app/pages/statistics/visitors/os/os.html',
        controller: StatisticsVisitorsController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsController.$inject = [
        'ng2StatsRepoService',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        '$filter',
        'moment',
        'ng2SessionService',
        'dateRangeService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsVisitorsController (
        ng2StatsRepoService,
        $stateParams,
        ParamService,
        gettextCatalog,
        $filter,
        moment,
        ng2SessionService,
        dateRangeService,
        DATE_FORMAT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;
        vm.getTotalBounceRate = getTotalBounceRate;
        vm.getActionsPerVisit = getActionsPerVisit;
        vm.getAvgTime = getAvgTime;
        vm.sum = sum;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.loading = false;
            vm.chartType = 'bar';
            vm.totalVisits = 0;
            vm.summary = {};
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;

            setupDoughnutSettings();
            setupBarChartSettings();
            updateDateRange();
        }

        function setupBarChartSettings () {
            vm.barOptions = {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]) +
                                ' ' +
                                gettextCatalog.getString('Visits')
                            );
                        },
                    },
                },
            };
            vm.chartOverride = [];
            vm.chartSeries = [gettextCatalog.getString('Visits')];
            vm.barOverride = [
                {
                    borderWidth: 0,
                    borderColor: '#50a6fb',
                    backgroundColor: '#50a6fb',
                },
            ];
        }

        function setupDoughnutSettings () {
            vm.barData = [];
            vm.doughnutLabels = [];
            vm.doughnutData = [];
            vm.doughnutOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    onHover: function (element) {
                        element.target.style.cursor = 'pointer';
                    },
                    onLeave: function (element) {
                        element.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    callbacks: {
                        title: function () {
                            return gettextCatalog.getString('Visits');
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
        }

        function getPage () {
            var params = {
                limit: 100,
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export visitors operating systems'),
                    name: gettextCatalog.getString('Visitors operating systems export'),
                    data: {
                        category: 'visitors_os',
                        category_ref: vm.date.startDate?.format('YYYY-MM-DD') + ',' + vm.date.endDate?.format('YYYY-MM-DD'),
                    },
                },
            ];
            var others = 0;
            vm.loading = true;
            vm.promise = ng2StatsRepoService.getOs(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    vm.doughnutLabels = [];
                    vm.doughnutData = [];
                    for (var i = 0; i < vm.pages.length; i++) {
                        if (i < 5) {
                            vm.doughnutLabels.push(vm.pages[i].label);
                            vm.doughnutData.push(vm.pages[i].visits);
                        } else {
                            others += parseInt(vm.pages[i].visits);
                        }
                    }
                    if (vm.pages.length > 5) {
                        vm.doughnutLabels.push(gettextCatalog.getString('Others'));
                        vm.doughnutData.push(others);
                    }

                    vm.barData = [vm.doughnutData];
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function updateDateRange () {
            if (vm.date.startDate || vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
                getPage();
            }
        }

        function getTotalBounceRate () {
            var bounceRates = sum(vm.pages, 'bounce_rate');
            var visits = sum(vm.pages, 'visits');
            return (bounceRates / visits) * 100;
        }

        function getActionsPerVisit () {
            var actions = sum(vm.pages, 'actions');
            var visits = sum(vm.pages, 'visits');
            return actions / visits;
        }

        function getAvgTime () {
            var avgTimes = sum(vm.pages, 'avg_time');
            var visits = sum(vm.pages, 'visits');
            return avgTimes / visits;
        }

        function sum (collection, property) {
            if (!Array.isArray(collection)) {
                return 0;
            }

            return collection.reduce(function (num, item) {
                return num + parseInt(item[property]);
            }, 0);
        }
    }
})();
