(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.business-intelligence.services').factory('biService', biService);

    biService.$inject = ['businessIntelligenceRepo', 'activeFeatures', 'ng2SessionService', 'ng2MonEventsService', '$window'];

    /* @ngInject*/
    function biService (businessIntelligenceRepo, activeFeatures, ng2SessionService, ng2MonEventsService, $window) {
        var config = {
            headers: {},
        };
        return {
            pageView: pageView,
            action: action,
            log: log,
        };

        function log (data) {
            if (activeFeatures.bi_tracking && ng2SessionService.me) {
                businessIntelligenceRepo
                    .post(
                        {
                            customer: ng2SessionService.me.customer.name,
                            customerId: ng2SessionService.me.customer.id.toString(),
                            user: ng2SessionService.me.email,
                            userId: ng2SessionService.me.id.toString(),
                            type: 'apiError',
                            responseCode: data.responseCode,
                            requestMethod: data.requestMethod,
                            requestUrl: data.requestUrl,
                            responseTime: data.responseTime,
                            url: data.url,
                        },
                        null,
                        config,
                    )
                    .then(function () {}, angular.noop);
            }
        }

        function action (action, ref, params) {
            ng2MonEventsService.run('trackEvent', action, 'Button', ref);
            if (activeFeatures.bi_tracking && ng2SessionService.me) {
                businessIntelligenceRepo
                    .post(
                        {
                            action: action,
                            url: ref,
                            parameters: JSON.stringify(params),
                            type: 'action',
                            customer: ng2SessionService.me.customer.name,
                            customerId: ng2SessionService.me.customer.id.toString(),
                            user: ng2SessionService.me.email,
                            userId: ng2SessionService.me.id.toString(),
                        },
                        null,
                        config,
                    )
                    .then(function () {}, angular.noop);
            }
        }

        function pageView (url, title, ref, type, params) {
            callEventTrackPageView(url, title, ref, type, params);
            if (activeFeatures.bi_tracking && ng2SessionService.me) {
                businessIntelligenceRepo
                    .post(
                        {
                            url: url,
                            title: title || 'Undefined',
                            reference: ref,
                            virtual: type,
                            parameters: JSON.stringify(params),
                            type: 'pageview',
                            customer: ng2SessionService.me.customer.name,
                            customerId: ng2SessionService.me.customer.id.toString(),
                            user: ng2SessionService.me.email,
                            userId: ng2SessionService.me.id.toString(),
                        },
                        null,
                        config,
                    )
                    .then(function () {}, angular.noop);
            }
        }

        function callEventTrackPageView (url, title, ref, virtual, params) {
            if (virtual) {
                ng2MonEventsService.run('trackEvent', url, 'Dialog', $window.location.href);
            } else {
                ng2MonEventsService.run('trackPageView', url, title, ref, virtual, params);
            }
        }
    }
})();
