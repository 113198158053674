(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsCampaigns', {
        templateUrl: 'app/pages/statistics/campaigns/campaigns.html',
        controller: StatisticsCampaignsController,
        controllerAs: 'vm',
    });

    StatisticsCampaignsController.$inject = [
        'ng2StatsRepoService',
        '$controller',
        '$stateParams',
        'ParamService',
        '$filter',
        'gettextCatalog',
        'dateRangeService',
    ];
    /* @ngInject */
    function StatisticsCampaignsController (ng2StatsRepoService, $controller, $stateParams, ParamService, $filter, gettextCatalog, dateRangeService) {
        var vm = this;

        vm.updateDateRange = updateDateRange;
        vm.average = average;
        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.totalVisits = 0;
            vm.summary = {};
            vm.chartSeries = [gettextCatalog.getString('Visits')]; // Use gettextCatalog, when we know which fields should be in the series array
            vm.chartLabels = [];
            vm.chartData = [];
            vm.chartOverride = [];
            vm.chartColors = [];
            vm.chartOptions = {
                legend: {
                    display: false,
                    position: 'bottom',
                    fullWidth: true,
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                },
            };
            vm.date = dateRangeService.setRangeDates();

            updateDateRange();
            getPage();
            getSummary();
        }

        function getPage () {
            var params = {
                limit: vm.pageSize,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };

            vm.loading = true;

            vm.promise = ng2StatsRepoService.getEntryPages(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.pages.currentPage = vm.page;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);

                    vm.pages.sort(function (a, b) {
                        return b.visits - a.visits;
                    });
                    vm.chartLabels = vm.pages.map(function (page) {
                        return page.label;
                    });
                    vm.chartData = [
                        vm.pages.map(function (page) {
                            return page.visits;
                        }),
                    ];
                    vm.chartOverride = [
                        {
                            borderWidth: 1,
                            borderColor: [],
                            backgroundColor: [],
                        },
                    ];
                    for (var i = 0; i < vm.pages.length; i++) {
                        vm.chartOverride[0].borderColor.push('#50a6fb');
                        vm.chartOverride[0].backgroundColor.push('#50a6fb');
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function updateDateRange () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
            });
            getPage();
        }

        function average (array, prop) {
            var sum = 0;
            if (!angular.isArray(array) || array.length === 0) {
                return 0;
            }
            angular.forEach(array, function (item) {
                sum += parseInt(item[prop]);
            });
            return sum / array.length;
        }

        function getSummary () {
            var params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
                type: 'total',
            };
            ng2StatsRepoService.getSummary(params)
                .then(function (data) {
                    vm.summary = data;
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }
    }
})();
