(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name QAImportantHighlightsService
     * @description Get details for QA Important Highlights
     */
    angular.module('blocks.service').factory('QAImportantHighlightsService', QAImportantHighlightsService);

    QAImportantHighlightsService.$inject = ['ng2SessionService', 'gettextCatalog', 'ng2ActiveFeatureService'];
    /* @ngInject*/
    function QAImportantHighlightsService (ng2SessionService, gettextCatalog, ng2ActiveFeatureService) {
        return {
            getBrokenLinkText: getBrokenLinkText,
            getBrokenImageText: getBrokenImageText,
            showBrokenLink: showBrokenLink,
            showBrokenImage: showBrokenImage,
            getLinkSearchType: getLinkSearchType,
        };

        /**
         * @memberOf QAImportantHighlightsService
         * @desc Get description for Broken links
         * @return {string}
         */
        function getBrokenLinkText () {
            if (hasDomainScan()) {
                return gettextCatalog.getString('Broken link affecting most content');
            }

            return gettextCatalog.getString('Broken link affecting most pages');
        }

        /**
         * @memberOf QAImportantHighlightsService
         * @desc Get description for Broken images
         * @return {string}
         */
        function getBrokenImageText () {
            if (hasDomainScan()) {
                return gettextCatalog.getString('Broken image affecting most content');
            }
            return gettextCatalog.getString('Broken image affecting most pages');
        }

        /**
         * @memberOf QAImportantHighlightsService
         * @desc Get api search type for broken links and images
         * @return {string}
         */
        function getLinkSearchType () {
            if (hasDomainScan()) {
                return 'all';
            }
            return 'html';
        }

        /**
         * @memberOf QAImportantHighlightsService
         * @desc Check if broken link should be shown in Important Highlights
         * @return {boolean}
         */
        function showBrokenLink (brokenLink) {
            if (!brokenLink || !ng2ActiveFeatureService.isFeatureActivePure('qa_links')) {
                return false;
            }

            return brokenLink.html_pages_count > 0 || (hasDomainScan() && brokenLink.documents_count > 0);
        }

        /**
         * @memberOf QAImportantHighlightsService
         * @desc Check if broken image should be shown in Important Highlights
         * @return {boolean}
         */
        function showBrokenImage (brokenImage) {
            if (!brokenImage || !ng2ActiveFeatureService.isFeatureActivePure('qa_links')) {
                return false;
            }

            return brokenImage.html_pages_count > 0 || (hasDomainScan() && brokenImage.documents_count > 0);
        }

        // PROTECTED

        /**
         * @memberOf QAImportantHighlightsService
         * @desc Check if domain has scan documents
         * @return {boolean}
         */
        function hasDomainScan () {
            return ng2SessionService.domain !== null && ng2SessionService.domain.scan.scan_documents;
        }
    }
})();
