(function () {
    'use strict';

    angular.module('modules.source-code-excludes').component('formSourceCodeExcludeStepsModule', {
        templateUrl: 'app/modules/source-code-excludes/form/source-code-exclude/steps/module/module.html',
        controller: FormSourceCodeExcludeController,
        controllerAs: 'vm',
        bindings: {
            selectModule: '&',
        },
    });

    FormSourceCodeExcludeController.$inject = ['SourceCodeExcludesService'];

    /* @ngInject */
    function FormSourceCodeExcludeController (SourceCodeExcludesService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.modules = SourceCodeExcludesService.getTabs();
        }
    }
})();
