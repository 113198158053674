import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report.component';
import { AngularSharedComponentsModule, IconsModule, MonPromptModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import {
    ShowMoreAvatarsModule
} from '../../show-more-avatars/show-more-avatars.module';
import { ReportCenterPipesModule } from '@monsido/modules/report-center/pipes/report-center-pipes.module';
import { MonEventModule } from '@monsido/services/mon-event/mon-event.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        ReportComponent,
    ],
    exports: [
        ReportComponent,
    ],
    imports: [
        MonEventModule,
        CommonModule,
        TranslateModule,
        IconsModule,
        ShowMoreAvatarsModule,
        AngularSharedComponentsModule,
        ReportCenterPipesModule,
        NgbTooltipModule,
        MonPromptModule,
    ],
})
export class ReportModule {}
