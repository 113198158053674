(function () {
    'use strict';
    angular.module('modules.page_fix.services').factory('accessibilityAutofixService', accessibilityAutofixService);

    accessibilityAutofixService.$inject = [
        '$q',
        'ng2PageFixRepoService',
        'ng2ActiveFeatureService',
        'Lodash',
        'PAGE_FIX_FIXABLE_TYPES',
        'ng2EditAltTextService',
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        'ng2PageFixAccessibilityConfigService',
    ];
    /* @ngInject*/
    function accessibilityAutofixService (
        $q,
        ng2PageFixRepoService,
        ng2ActiveFeatureService,
        Lodash,
        PAGE_FIX_FIXABLE_TYPES,
        ng2EditAltTextService,
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        ng2PageFixAccessibilityConfigService,
    ) {
        return {
            parseChecks: parseChecks,
            isCheckFixed: isCheckFixed,
            useCheckId: useCheckId,
            getElementRegex: getElementRegex,
            getElementPreview: getElementPreview,
            isElementNameValid: isElementNameValid,
        };

        // ///////////////

        function getElementRegex () {
            return /^((?![\W_\s])|(?![\-]{2})[a-zA-Z\-\d]){1,}$/;
        }

        function isElementNameValid (elementName) {
            if (!elementName) {
                return false;
            }
            var regex = getElementRegex();
            return regex.test(elementName);
        }

        function getElementPreview (elementName) {
            if (!isElementNameValid(elementName)) {
                return '';
            }
            try {
                var el = document.createElement(elementName);
                el.innerText = 'Text';
                el.className = 'inline-block my-0 mx-0 py-0 px-0';
                return el.outerHTML;
            } catch (e) {
                // Silently fail
            }
            return '';
        }

        function parseChecks (checks, sourceCode) {
            if (!ng2ActiveFeatureService.isFeatureActive('page_fix')) {
                return $q.resolve(checks);
            }
            return $q
                .all([
                    getPageFixes(PAGE_FIX_FIXABLE_TYPES.accessibility_source_code, sourceCode.id),
                    getPageFixes(PAGE_FIX_FIXABLE_TYPES.accessibility_check),
                ])
                .then(function (data) {
                    var fixes = data[0].concat(data[1]);
                    ng2EditAltTextService.setHTMLSnippet(sourceCode.source_code);
                    for (var i = 0; i < checks.length; i++) {
                        checks[i].html_snippet = sourceCode.source_code;
                        checks[i].page_fix_fixable = isCheckFixable(checks[i]);
                        checks[i].page_fixed = checks[i].page_fix_fixable && anyFixesForCheck(fixes, checks[i]);
                    }

                    return checks;
                }, angular.noop);
        }

        /**
         * @desc Check if Accessibility Check is part of the Checks section in PageCorrect.
         * @param checkId - Accessibility Check ID
         */
        function useCheckId (checkId) {
            var checkConfig = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[checkId];
            return Boolean(
                checkConfig && PAGE_FIX_FIXABLE_TYPES[checkConfig.type] === PAGE_FIX_FIXABLE_TYPES.accessibility_check,
            );
        }

        function isCheckFixed (fix, check) {
            if (fix.change.check_id === check.id) {
                return true;
            } else if (isAltImageCheck(check)) {
                return ng2PageFixAccessibilityConfigService.isPageFixImg(fix);
            }

            return false;
        }

        // PROTECTED

        function anyFixesForCheck (fixes, check) {
            return Lodash.some(fixes, function (fix) {
                return isCheckFixed(fix, check);
            });
        }

        function isAltImageCheck (check) {
            return ng2PageFixAccessibilityConfigService.isCheckImg(check);
        }

        function isCheckFixable (check) {
            if (PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[check.id]) {
                if (isAltImageCheck(check)) {
                    if (ng2EditAltTextService.canReadSource()) {
                        return !ng2EditAltTextService.getSource().startsWith('data:image/');
                    }
                }
                return true;
            }
            return false;
        }

        function getPageFixes (type, id) {
            return ng2PageFixRepoService.getAll({
                fixable_type: type,
                fixable_id: id,
            }).then(
                function (fixes) {
                    return fixes;
                },
                function () {
                    return [];
                },
            );
        }
    }
})();
