(function () {
    'use strict';

    angular.module('modules.globalPolicy').component('policyExchangeCenter', {
        templateUrl: 'app/modules/global-policy/exchange-center/exchange-center.html',
        controller: PolicyExchangeCenterController,
        controllerAs: 'vm',
        bindings: {
            chooseTemplate: '&',
        },
    });

    PolicyExchangeCenterController.$inject = [
        'POLICY_EXCHANGE_CENTER_DB',
        'Lodash',
        'ng2ActiveFeatureService',
        '$timeout',
        '$deepmerge',
        'gettextCatalog',
        'ng2TextSearchService',
    ];

    /* @ngInject */
    function PolicyExchangeCenterController (
        POLICY_EXCHANGE_CENTER_DB,
        Lodash,
        ng2ActiveFeatureService,
        $timeout,
        $deepmerge,
        gettextCatalog,
        ng2TextSearchService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.setEmptyPolicy = setEmptyPolicy;
        vm.sortChange = sortChange;
        vm.onSearch = onSearch;
        vm.selectTemplateView = selectTemplateView;
        vm.removeLabel = removeLabel;

        function activate () {
            vm.hasTableFilter = ng2ActiveFeatureService.isFeatureActive('table_filters');
            vm.showTableFilter = false;
            vm.showDropdown = false;
            vm.templateView = 'grid';
            vm.originalTemplates = $deepmerge([], POLICY_EXCHANGE_CENTER_DB);
            vm.filters = {};
            vm.filters[gettextCatalog.getString('Sort by', 'Exchange center filter option')] = [];
            // TODO: Reimplement Region & Vertical https://zube.io/monsido/monsido/c/21967
            // vm.filters[gettext("Vertical", "Exchange center filter option")] = [];
            // vm.filters[gettext("Region", "Exchange center filter option")] = [];
            $timeout(function () {
                // We do this because the dropdown is shown on init, then disappears right afterwards
                vm.showTableFilter = true;
            });
            getTemplates();
            setDropdownOptions();
        }

        function setEmptyPolicy () {
            var policy = {
                rules: [],
                targets: [],
                tags: [],
                labels: [],
            };
            vm.filters = {};
            vm.search = vm.search ? vm.search : '';
            vm.chooseTemplate({ template: { policy: policy } });
        }

        function removeLabel (group, index) {
            vm.filters[group].splice(index, 1);
            sortChange(vm.filters);
        }

        function selectTemplateView (view) {
            vm.templateView = view;
        }

        function onSearch () {
            vm.templates = vm.originalTemplates.filter(function (template) {
                if (Lodash.includes(template.name.toLowerCase(), vm.search.toLowerCase())) {
                    return template;
                }
            });
        }

        function getTemplates () {
            vm.templates = vm.originalTemplates.map(function (template) {
                template.tags = template.policy.tags
                    .map(function (tag) {
                        return tagOption(tag);
                    })
                    .join(', ');
                return template;
            });
        }

        function sortChange (filters) {
            var search = typeof vm.search === 'string' ? vm.search : '';
            var sortBy = Lodash.map(filters[gettextCatalog.getString('Sort by', null, 'Exchange center filter option')], 'value');
            // TODO: Reimplement Region & Vertical https://zube.io/monsido/monsido/c/21967
            // var regions = Lodash.map(filters[gettext("Region", "Exchange center filter option")], "value");
            // var verticals = Lodash.map(filters[gettext("Vertical", "Exchange center filter option")], "value");

            var sortDirections = sortBy.map(function (sort) {
                return sort === 'name' ? 'asc' : 'desc';
            });

            var filteredTemplates = vm.originalTemplates.filter(function (template) {
                // TODO: Reimplement Region & Vertical https://zube.io/monsido/monsido/c/21967
                /** if (verticals.length > 0 && !hasVertical(template, verticals)) {
                    return false;
                }

                if (regions.length > 0 && !hasRegion(template, regions)) {
                    return false;
                }**/
                var haystack = template && template.name;

                if (typeof haystack !== 'string') {
                    return false;
                }

                return ng2TextSearchService.fuzzy(search, haystack);
            });

            vm.templates = Lodash.orderBy(filteredTemplates, sortBy, sortDirections);
        }

        // PROTECTED

        function tagOption (tag) {
            switch (tag) {
                case 'qa':
                    return gettextCatalog.getString('QA');
                case 'seo':
                    return gettextCatalog.getString('SEO');
                case 'accessibility':
                    return gettextCatalog.getString('Accessibility');
                default:
                    return null;
            }
        }

        // eslint-disable-next-line no-unused-vars
        function hasVertical (template, verticals) {
            return Lodash.some(template.vertical, function (vertical) {
                return verticals.indexOf(vertical) > -1;
            });
        }

        // eslint-disable-next-line no-unused-vars
        function hasRegion (template, regions) {
            return Lodash.some(template.country, function (region) {
                return regions.indexOf(region) > -1;
            });
        }

        function setDropdownOptions () {
            vm.availableFilters = {};
            vm.availableFilters[gettextCatalog.getString('Sort by', 'Exchange center filter option')] = [
                {
                    name: gettextCatalog.getString('Name', 'Exchange Center Sort by option'),
                    value: 'name',
                },
                {
                    name: gettextCatalog.getString('Newest', 'Exchange Center Sort by option'),
                    value: 'created_at',
                },
            ];
            // TODO: Reimplement Region & Vertical https://zube.io/monsido/monsido/c/21967
            // vm.availableFilters[gettext("Vertical", "Exchange center filter option")] = [
            //     {
            //         name: gettext("Finance", "Exchange Center Vertical option"),
            //         value: "finance",
            //     },
            //     {
            //         name: gettext("Education", "Exchange Center Vertical option"),
            //         value: "education",
            //     },
            //     {
            //         name: gettext("Government", "Exchange Center Vertical option"),
            //         value: "government",
            //     },
            // ];
            // vm.availableFilters[gettext("Region", "Exchange center filter option")] = [
            //     {
            //         name: gettext("North America", "Exchange Center Region option"),
            //         value: "north_america",
            //     },
            //     {
            //         name: gettext("Europe", "Exchange Center Region option"),
            //         value: "europe",
            //     },
            //     {
            //         name: gettext("Africa", "Exchange Center Region option"),
            //         value: "africa",
            //     },
            //     {
            //         name: gettext("Asia", "Exchange Center Region option"),
            //         value: "asia",
            //     },
            // ];
        }
    }
})();
