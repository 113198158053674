import { Component, Input, OnInit } from '@angular/core';
import { MonDataPrivacyInfoTypeIconService } from './info-type-icon.service';

@Component({
    selector: 'mon-data-privacy-info-type-icon',
    templateUrl: './info-type-icon.component.html',
})
export class MonDataPrivacyInfoTypeIconComponent implements OnInit {
    @Input() monTooltipText: string = '';
    @Input() monPriority: string = '';
    @Input() monGroup: string = '';

    colorClass: string = '';
    iconClass: string = '';

    constructor (private infoTypeIconService: MonDataPrivacyInfoTypeIconService) {}

    ngOnInit (): void {
        this.iconClass = this.infoTypeIconService.getIcon(this.monGroup);
        this.colorClass = this.infoTypeIconService.getColor(this.monPriority);
    }
}
