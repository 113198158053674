import { Page } from '@monsido/modules/page-details/models';
import { Label } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';

export class PageModel implements Page {
    id: number;
    domain_id: number;
    title: string;
    url: string;
    mrank: number;
    hit_score: number;
    link_score: number;
    error_score: number;
    priority_score: number;
    detected_language: string;
    language_override: boolean | null;
    no_index: boolean;
    hits: number;
    type: string;
    dead_links_count: number;
    ignored_links_count: number;
    fixed_links_count: number;
    dead_images_count: number;
    ignored_images_count: number;
    fixed_images_count: number;
    seo_issues_count: number;
    spelling_errors_count: number;
    notification_count: number;
    spelling_errors_confirmed_count: number;
    spelling_errors_potential_count: number;
    accessibility_checks_with_errors_count: number;
    nin_count: number;
    quality_notification_count: number;
    cms_url: string | null;
    words_count: number;
    readability_words_count: number;
    language: string;
    priority_score_as_text: 'low' | 'medium' | 'high';
    readability: string | null;
    readability_level: string;
    readability_reason: string;
    policy_violation_count: number;
    policy_search_count: number;
    policy_required_count: number;
    lastcheck: string;
    created_at: string;
    updated_at: string;
    labels: Label[];
    data_protection_violations_counts: {
        violations_count: number,
        active_violations: number,
        ignored_violations: number,
        fixed_violations: number,
    };
    error_searches_count: number;
    searches_count: number;
    quality_notifications_not_searches: number;
    performance_scores?: {
        first_contentful_paint: number,
        first_meaningful_paint: number,
        time_to_interactive: number,
    };

    constructor (page: Page) {
        this.id = page.id;
        this.domain_id = page.domain_id;
        this.title = page.title;
        this.url = page.url;
        this.mrank = page.mrank;
        this.hit_score = page.hit_score;
        this.link_score = page.link_score;
        this.error_score = page.error_score;
        this.priority_score = page.priority_score;
        this.detected_language = page.detected_language;
        this.language_override = page.language_override;
        this.no_index = page.no_index;
        this.hits = page.hits;
        this.type = page.type;
        this.dead_links_count = page.dead_links_count;
        this.ignored_links_count = page.ignored_links_count;
        this.fixed_links_count = page.fixed_links_count;
        this.dead_images_count = page.dead_images_count;
        this.ignored_images_count = page.ignored_images_count;
        this.fixed_images_count = page.fixed_images_count;
        this.seo_issues_count = page.seo_issues_count;
        this.spelling_errors_count = page.spelling_errors_count;
        this.notification_count = page.notification_count;
        this.spelling_errors_confirmed_count = page.spelling_errors_confirmed_count;
        this.spelling_errors_potential_count = page.spelling_errors_potential_count;
        this.accessibility_checks_with_errors_count = page.accessibility_checks_with_errors_count;
        this.nin_count = page.nin_count;
        this.quality_notification_count = page.quality_notification_count;
        this.cms_url = page.cms_url;
        this.words_count = page.words_count;
        this.readability_words_count = page.readability_words_count;
        this.readability_reason = page.readability_reason;
        this.language = page.language;
        this.priority_score_as_text = page.priority_score_as_text;
        this.readability = page.readability;
        this.readability_level = page.readability_level;
        this.policy_violation_count = page.policy_violation_count;
        this.policy_search_count = page.policy_search_count;
        this.policy_required_count = page.policy_required_count;
        this.lastcheck = page.lastcheck;
        this.created_at = page.created_at;
        this.updated_at = page.updated_at;
        this.labels = cloneDeep(page.labels);
        this.data_protection_violations_counts = cloneDeep(page.data_protection_violations_counts);
        this.error_searches_count = page.error_searches_count;
        this.searches_count = page.searches_count;
        this.quality_notifications_not_searches = page.quality_notifications_not_searches;

        if (page.performance_scores) {
            this.performance_scores = cloneDeep(page.performance_scores);
        }
    }

    getQualityNotificationsCount (): number {
        const deadImagesCount = this.dead_images_count || 0;
        const deadLinksCount = this.dead_links_count || 0;
        const spellingErrorsConfirmedCount = this.spelling_errors_confirmed_count || 0;
        const ninCount = this.nin_count || 0;
        return deadImagesCount + deadLinksCount + spellingErrorsConfirmedCount + ninCount;
    }
}


@Injectable({
    providedIn: 'root',
})
export class PageModelFactoryService {
    getModel (page: Page): PageModel {
        return new PageModel(page);
    }
}
