(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScriptGuideLegacyStatistics', {
        templateUrl: 'app/forms/domain-script-guide/legacy/statistics/statistics.html',
        controller: DomainScriptGuideFeaturesStatisticsV1Controller,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
        },
    });

    DomainScriptGuideFeaturesStatisticsV1Controller.$inject = ['FormDomainScriptGuideStatisticsService', '$timeout'];
    /* @ngInject */
    function DomainScriptGuideFeaturesStatisticsV1Controller (FormDomainScriptGuideStatisticsService, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.onChangeValue = onChangeValue;

        function activate () {
            vm.show = FormDomainScriptGuideStatisticsService.hasAccessToFeature();
            vm.canUse = FormDomainScriptGuideStatisticsService.getValue();
        }

        function onChangeValue () {
            $timeout(function () {
                setValue(vm.canUse);
            });
        }

        // PROTECTED

        function setValue (value) {
            FormDomainScriptGuideStatisticsService.setValue(value);
        }
    }
})();
