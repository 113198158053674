(function () {
    'use strict';
    angular.module('modules.roles').component('adminRoles', {
        templateUrl: 'app/modules/roles/pages/roles/roles.html',
        controller: AdminRolesController,
        controllerAs: 'vm',
    });

    AdminRolesController.$inject = ['$controller', 'RolesApiService'];
    /* @ngInject */
    function AdminRolesController ($controller, RolesApiService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.roles = [];
            getPage();
        }

        function getPage () {
            var params = {
                page: 1,
                page_size: 10,
                search: vm.search,
            };

            vm.promise = RolesApiService.getAll(params).then(function (roles) {
                vm.roles = roles;
            }, angular.noop);
        }
    }
})();
