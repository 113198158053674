(function () {
    'use strict';

    angular.module('services.api').factory('JobsRepo', JobsRepo);

    JobsRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function JobsRepo (ng2ApiService, ng2SessionService) {
        var service = {
            find: find,
        };

        return service;

        // /////////////

        function find (id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('jobs/' + id, params, config);
        }
    }
})();
