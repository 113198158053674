(function () {
    'use strict';

    angular.module('filters.monsido').filter('monCustomerHasPlan', CustomerHasPlanController);

    CustomerHasPlanController.$inject = ['ng2SessionService'];
    /* @ngInject */
    function CustomerHasPlanController (ng2SessionService) {
        return hasPlan;

        // //////////

        function hasPlan (plan) {
            // Array or string
            plan = plan || ''; // Just to make sure that the application doesn't fail, if the filter is being weird
            var customer = ng2SessionService.customer;
            return customer !== null && plan.indexOf(customer.plan_type) > -1;
        }
    }
})();
