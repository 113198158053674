import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';

export type PolicySubjectType = 'page' | 'page_html' | 'page_document' | 'link'
export type PolicyVerticalType = 'finance' | 'education' | 'government'
export type PolicyVerticalIconObjectType = {
    description: string,
    icon: string,
    backgroundColor: string | null,
    color: string
}
export type PolicyCountriesType = 'europe' | 'africa' | 'asia' | 'north_america'
export type PolicyCountriesObjectType = {
    description: string,
    icon: string
}

@Injectable({
    providedIn: 'root',
})
export class GlobalPolicyExchangeCenterTemplateService {
    readonly subjectIcons: Record<PolicySubjectType, string> = {
        page: 'fas fa-globe',
        page_html: 'far fa-copy',
        page_document: 'far fa-file-alt',
        link: 'far fa-link',
    };
    readonly verticalIcons: Record<PolicyVerticalType, PolicyVerticalIconObjectType> = {
        finance: {
            description: this.translateService.getString('Finance'),
            icon: 'far fa-money-bill-wave-alt',
            backgroundColor: 'text-link',
            color: 'fa-inverse',
        },
        education: {
            description: this.translateService.getString('Education'),
            icon: 'far fa-graduation-cap',
            backgroundColor: 'text-purple',
            color: 'fa-inverse',
        },
        government: {
            description: this.translateService.getString('Government'),
            icon: 'far fa-building',
            backgroundColor: null,
            color: 'fa-inverse',
        },
    };
    readonly countries: Record<PolicyCountriesType, PolicyCountriesObjectType> = {
        europe: {
            description: this.translateService.getString('Europe'),
            icon: 'fas fa-globe-europe',
        },
        africa: {
            description: this.translateService.getString('Africa'),
            icon: 'fas fa-globe-africa',
        },
        asia: {
            description: this.translateService.getString('Asia'),
            icon: 'fas fa-globe-asia',
        },
        north_america: {
            description: this.translateService.getString('North America'),
            icon: 'fas fa-globe-americas',
        },
    };

    constructor (private translateService: TranslateService) {}

    getSubjectIcon (template): string {
        return this.subjectIcons[template.policy.subject];
    }

    getVerticalIcon (vertical: PolicyVerticalType): string {
        return this.verticalIcons[vertical].icon;
    }

    getVerticalDescription (vertical: PolicyVerticalType): string {
        return this.verticalIcons[vertical].description;
    }

    getVerticalBackground (vertical: PolicyVerticalType): string | null {
        return this.verticalIcons[vertical].backgroundColor;
    }

    getVerticalColor (vertical: PolicyVerticalType): string {
        return this.verticalIcons[vertical].color;
    }

    getCountryIcon (country: PolicyCountriesType): string {
        return this.countries[country].icon;
    }

    getCountryDescription (country: PolicyCountriesType): string {
        return this.countries[country].description;
    }
}
