export class PagePerformanceAuditScoreUtil {
    static getProperScoreNo (score: number | null): number {
        if (score === null) {
            return 0;
        }
        return score;
    }

    static hasScorePassed (score: number): boolean {
        return score >= 90 && score <= 100;
    }

    static hasScoreAverage (score: number): boolean {
        return score >= 50 && score <= 89;
    }

    static hasScoreFailed (score: number | null): boolean {
        return score !== null && score >= 0 && score <= 49;
    }
}
