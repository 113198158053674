(function () {
    'use strict';

    angular.module('pages.qa.nin').component('pageDetailsSectionQaNin', {
        templateUrl: 'app/components/pagedetails/details/sections/qa/subsections/nin/nin.html',
        controller: NiNumbersIndex,
        controllerAs: 'vm',
        bindings: {
            pageObj: '<',
            headline: '@',
            refreshPage: '&',
        },
    });

    NiNumbersIndex.$inject = ['PageNinRepo', '$controller', 'gettextCatalog', '$q', 'pageService'];
    /* @ngInject */
    function NiNumbersIndex (PageNinRepo, $controller, gettextCatalog, $q, pageService) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.update = update;

        vm.pageObj = {};
        vm.activeTab = [];

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.selected = [];
            vm.niNumbers = [];
            vm.potentialTabCounter = 0;
            vm.unlikeyTabCounter = 0;
            vm.ignoredTabCounter = 0;
            vm.tabs = [];
            setupTabs();
            vm.activeTab = 'potential';
            getPage();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Potential'),
                    tooltip: gettextCatalog.getString(
                        'These numbers represent potential National Identification numbers. ' +
                            'This means that the format, date, and year are all in accordance with a valid National Identification number.',
                    ),
                    value: 'potential',
                },
                {
                    name: gettextCatalog.getString('Unlikely'),
                    tooltip: gettextCatalog.getString(
                        'These numbers have a format, date, and year which are in accordance with a National Identification number. ' +
                            'The numbers have been found somewhere on a page where National Identification numbers are not normally found. ' +
                            'Therefore it is likely that they aren\'t National Identification numbers.',
                    ),
                    value: 'unlikely',
                },
                {
                    name: gettextCatalog.getString('Ignored'),
                    value: 'ignored',
                },
            ];
        }

        function getPage () {
            var params = {
                page_id: vm.pageObj.id,
                page: vm.page,
                page_size: vm.pageSize,
                status: 'active',
            };
            if (vm.activeTab === 'potential' || vm.activeTab === 'unlikely') {
                params.priority = vm.activeTab;
            }

            if (vm.activeTab === 'ignored') {
                params.status = vm.activeTab;
            }

            vm.promise = PageNinRepo.get(params).then(function (data) {
                vm.niNumbers = data;
            }, angular.noop);
        }

        function update (nin, status, confirmMessage) {
            ng2MonModalService.confirm(confirmMessage).then(()=>{
                const params = {
                    status: status,
                };
                return pageService.updateNin(vm.pageObj.id, nin.id, params).then(function (data) {
                    getPage();
                    vm.refreshPage();
                }, angular.noop);
            });
        }
    }
})();
