(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationTooLongMetaDescription', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/too-long-meta-description/too-long-meta-description.html',
        controller: SeoTranslationsMultipleH1Controller,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTranslationsMultipleH1Controller.$inject = [];
    /* @ngInject */
    function SeoTranslationsMultipleH1Controller () {
        var vm = this;
        vm.count = count;
        vm.countCharacters = countCharacters;

        function count (content) {
            if (Array.isArray(content)) {
                return content.length;
            } else if (content === null || content === undefined) {
                return 0;
            }

            return 1;
        }

        function countCharacters (content) {
            if (Array.isArray(content)) {
                return content.reduce(function (a, b) {
                    return a + b.length;
                }, 0);
            }
            return 0;
        }
    }
})();
