import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import { ArgsInterface } from '@monsido_services/services/mon-translate/dist/translate.interface';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Pipe({
    name: 'translate',
    pure: false, // needs to be false to perform further updates when fx - changing language
})
export class TranslatePipe implements PipeTransform, OnDestroy {
    /**
     * Subscription that handles language changes
     *
     * @private
     */
    private onLangChange: Subscription | null = null;

    /**
     * {value} is used to store the result from the transform method after translations are applied
     *
     * @private
     */
    private value: string = '';

    /**
     * {lastText} and {lastArgs} are used for comparison between current text and args values and
     * previous text and args value; they can determine if the current inputs of the transform method are
     * the same as last ones so as to avoid unnecessary computations.
     *
     * @private
     */
    private lastText: string | null = null;
    private lastArgs: ArgsInterface | null = null;

    /**
     * {text} {args} and {context} are used to pass the values to the constructor subscription
     *
     * @private
     */
    private text = '';
    private args: ArgsInterface | null = null;
    private context: string | null = null;

    constructor (
        private translateService: TranslateService,
    ) {
        this.onLangChange = this.translateService.onLanguageChange$().subscribe({
            next: () => {
                if (this.lastText) {
                    this.lastText = null;
                    this.updateValue(this.text = '', this.args, this.context);
                }
            },
        });
    }

    ngOnDestroy (): void {
        this.dispose();
    }

    /**
     * Translate string
     *
     * @param text - string to be translated
     * @param context - string - the context of the string
     * @param args - ArgsInterface - arguments passed for interpolation
     */
    transform (text: string, context?: string, args?: ArgsInterface): string {
        if (!text) {
            return text;
        }

        // capturing values here to use in the constructor subscription
        this.args = args || null;
        this.context = context || null;
        this.text = text;

        // if we ask another time for the same text, return the last value
        if (isEqual(args, this.lastArgs) && this.lastText === text) {
            return this.value;
        }

        // store the text, in case it changes
        this.lastText = text;

        // store args, in case they change
        this.lastArgs = args || null;

        this.updateValue(text, args || null, context || null);

        return this.value;
    }

    /**
     * update the text to be translated, stored in the {value} prop
     *
     * @param text
     * @param args
     * @param context
     *
     * @private
     */
    private updateValue (text: string, args: ArgsInterface | null, context: string | null): void {
        const res = context ? this.translateService.getString(text, args || {}, context) : this.translateService.getString(text, args || {});

        this.value = res !== undefined ? res : text;
        this.lastText = text;
    }

    /**
     * Clean existing subscriptions
     *
     * @private
     */
    private dispose (): void {
        if (this.onLangChange !== null) {
            this.onLangChange.unsubscribe();
            this.onLangChange = null;
        }
    }
}
