export const NOTIFICATION_CONSTANT = {
    ACTION: {
        ACCESSIBILITY_ERRORS_IGNORED: 'accessibility-errors-ignored',
        ACCESSIBILITY_CHECK_REVIEWED: 'accessibility-check-reviewed',
        ACCESSIBILITY_CHECK_IGNORED: 'accessibility-check-ignored',
        DEAD: 'dead',
        FIXED: 'fixed',
        IGNORED: 'ignored',
        HIDDEN: 'hidden',
        REVIEWED: 'reviewed',
        UPDATE: 'update',
        CREATE: 'create',
        CRAWL_DONE: 'crawl-done',
        CRAWL_FINALIZED: 'crawl-finalized',
        REQUEST_SCAN: 'request-scan',
        COOKIES_FOUND: 'cookies-found',
    },
    TARGET: {
        SPELLING_IGNORED: 'SpellingIgnored',
        SPELLING_CONFIRMED: 'SpellingConfirmed',
        SPELLING_DICTIONARY: 'SpellingDictionary',
        EXPORT: 'Export',
        SEO_ISSUE: 'Seo::Issue',
        SEO_PAGE_ISSUE: 'Seo::PageIssue',
        ACCESSIBILITY_SOURCE_CODE: 'AccessibilitySourceCode',
        LINK: 'Link',
        DOMAIN: 'Domain',
        PAGE: 'Page',
        ACCESSIBILITY_ERRORS_PAGE: 'AccessibilityErrorsPage',
        ACCESSIBILITY_CHECK: 'AccessibilityCheck',
        ACCESSIBILITY_ERROR: 'AccessibilityError',
        REQUEST_SCAN: 'request-scan',
    },
    STAGE: {
        DONE: 'done',
    },
};

