(function () {
    'use strict';

    /**
     * @memberof modules.accessibility
     * @ngdoc factory
     * @name AccessibilityImpactService
     * @description Service that will manage impact value translations from the Accessibility Help Center
     */
    angular.module('modules.accessibility').service('AccessibilityImpactService', AccessibilityTagsService);

    AccessibilityTagsService.$inject = ['ACCESSIBILITY_HELP_CENTER_IMPACT', 'gettextCatalog'];
    /* @ngInject*/
    function AccessibilityTagsService (ACCESSIBILITY_HELP_CENTER_IMPACT, gettextCatalog) {
        return {
            getTranslation: getTranslation,
            getClass: getClass,
        };

        /**
         * @memberOf AccessibilityImpactService
         * @desc Get the translation text from the Accessibility Help Center Impact Constant
         * @param impactKey - Key value to look for in the Help Center Impact Constant
         */
        function getTranslation (impactKey) {
            var tag = ACCESSIBILITY_HELP_CENTER_IMPACT[impactKey];
            if (tag) {
                return gettextCatalog.getString(tag.translation);
            }
            var result = tagKey.replace(/_/g, ' ');
            result = result[0].toUpperCase() + result.substring(1);
            return result;
        }

        /**
         * @memberOf AccessibilityImpactService
         * @desc Get the translation text from the Accessibility Help Center Impact Constant
         * @param impactKey - Key value to look for in the Help Center Impact Constant
         */
        function getClass (impactKey) {
            var tag = ACCESSIBILITY_HELP_CENTER_IMPACT[impactKey];
            if (tag && tag.styleClass.length > 0) {
                return tag.styleClass;
            }

            return 'label-info';
        }
    }
})();
