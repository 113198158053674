export const RESPONSE_TIME = {
    SECONDS: {
        OK: {
            FROM: 0,
            TO: 200,
            COLORCLASS: 'btn-success',
            GRAPHCOLOR: '#def7e7',
        },
        WARNING: {
            FROM: 201,
            TO: 750,
            COLORCLASS: 'btn-warning',
            GRAPHCOLOR: '#fefbd5',
        },
        ERROR: {
            FROM: 751,
            TO: 100000,
            COLORCLASS: 'btn-danger',
            GRAPHCOLOR: '#f8e1e0',
        },
    },
    PERCENTAGE: {
        OK: {
            FROM: 98,
            COLORCLASS: 'btn-success',
        },
        WARNING: {
            FROM: 95,
            COLORCLASS: 'btn-warning',
        },
        ERROR: {
            FROM: 0,
            COLORCLASS: 'btn-error',
        },
    },
} as const;
