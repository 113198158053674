(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionDashboardBlocksOverview', {
        transclude: true,
        templateUrl: 'app/components/pagedetails/details/sections/dashboard/blocks/overview/overview.html',
        controller: DashboardOverviewController,
        controllerAs: 'vm',
        bindings: {
            infoHeading: '@',
            icon: '@',
            monTooltipText: '@?',
        },
    });

    DashboardOverviewController.$inject = [];
    /* @ngInject */
    function DashboardOverviewController () {
        var vm = this;
    }
})();
