import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';

@Pipe({
    name: 'customerHasPlan',
})
export class CustomerHasPlanPipe implements PipeTransform {
    constructor (private sessionService: SessionService) {}

    transform (value: string | string[] = ''): boolean {
        const customer = this.sessionService.customer;
        return customer !== null && value.includes(customer.plan_type);
    }

}
