(function () {
    'use strict';

    angular.module('app.layout').controller('BaseController', BaseController);

    BaseController.$inject = [
        '$state',
        '$sce',
        '$transitions',
        'ng2MonBrowserService',
    ];
    /* @ngInject */
    function BaseController (
        $state,
        $sce,
        $transitions,
        monBrowserService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToMainContent = goToMainContent;
        vm.minimizeIeLegacyNotification = minimizeIeLegacyNotification;
        vm.maximizeIeLegacyNotification = maximizeIeLegacyNotification;

        function activate () {
            $transitions.onExit({ exiting: (state) => !!state.onExit }, () => {});

            var stateObj = $state.current.data;

            handleSubMenu(stateObj);

            $transitions.onSuccess({}, function (transition) {
                var toState = transition.to();
                handleSubMenu(toState.data);
            });

            vm.ieLegacyNotificationMinimized = false;
            if (monBrowserService.isIe11() || monBrowserService.isEdgeLegacy()) {
                vm.hasIeLegacyNotification = true;
            }
        }

        function handleSubMenu (data) {
            vm.activeMenuEntry = data.activeSidebarEntry;
            vm.sidebar = data.sidebar;
            vm.withSidebar = data.sidebar !== undefined;
            vm.menu = $sce.trustAsHtml('<side-menu-' + data.sidebar + '></side-menu-' + data.sidebar + '>');
            vm.submenu = $sce.trustAsHtml('<submenu-' + data.topmenu + '></submenu-' + data.topmenu + '>');
            vm.showSubMenu = data.topmenu !== undefined;
        }

        function goToMainContent () {
            var mainContent = document.getElementById('maincontent');
            mainContent.scrollIntoView();
            mainContent.focus();
        }

        function minimizeIeLegacyNotification () {
            vm.ieLegacyNotificationMinimized = true;
        }

        function maximizeIeLegacyNotification () {
            vm.ieLegacyNotificationMinimized = false;
        }
    }
})();
