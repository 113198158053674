import { objectToArray } from '@monsido/ng2/shared/utils';

(function () {
    'use strict';

    angular.module('filters.monsido').filter('searchField', searchField);

    searchField.$inject = ['$parse'];

    /* @ngInject */
    function searchField ($parse) {
        return function (collection, ...args) {
            let get;
            let field;

            collection = angular.isObject(collection) ? objectToArray(collection) : collection;

            if (!angular.isArray(collection) || !args.length) {
                return collection;
            }

            return collection.map((member) => {
                field = args.map((f) => {
                    get = $parse(f);
                    return get(member);
                }).join(' ');

                return angular.extend(member, { searchField: field });
            });
        };
    }
})();
