(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceScoreLineChart', {
        templateUrl: 'app/modules/page-performance/components/charts/score-line/score-line.html',
        controller: PagePerformanceScoreLineChartController,
        controllerAs: 'vm',
        bindings: {
            data: '<',
            height: '<',
            width: '<',
            milestones: '<', // Array<{link: string; text: string; date: string}>
        },
    });

    PagePerformanceScoreLineChartController.$inject = [
        '$element',
        'PagePerformanceChartsService',
        '$deepmerge',
        '$timeout',
        '$window',
        'ng2DateTimeService',
        'Lodash',
    ];
    /* @ngInject */
    function PagePerformanceScoreLineChartController (
        $element,
        PagePerformanceChartsService,
        $deepmerge,
        $timeout,
        $window,
        ng2DateTimeService,
        Lodash,
    ) {
        const vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.$onDestroy = onDestroy;
        vm.groupPath = groupPath;
        vm.groupPathFillColor = groupPathFillColor;
        vm.groupLine = groupLine;
        vm.groupLineFillColor = groupLineFillColor;
        vm.setTooltip = setTooltip;
        vm.disableTooltip = disableTooltip;
        vm.dataGroups = [];
        vm.milestonesToDisplay = [];

        function activate () {
            vm.onResizeHandler = Lodash.debounce(function () {
                vm.width = vm.container.clientWidth;
                setupChartData();
            }, 500);

            vm.height = vm.height || 160;
            vm.tooltipStyle = {
                display: 'none',
            };
            vm.headerStyle = {
                display: 'none',
            };
            vm.tooltip = {};
            vm.data = vm.data || [];
            vm.container = $element[0].querySelector('.score-line-container');

            $timeout(function () {
                vm.width = vm.width || $element[0].querySelector('.score-line-container').clientWidth;
                setupChartData();
            });

            $window.addEventListener('resize', vm.onResizeHandler);
        }

        function onChanges (changes) {
            if (changes.data || changes.width) {
                $timeout(function () {
                    vm.width = vm.width || $element[0].querySelector('.score-line-container').clientWidth;
                    setupChartData();
                });
            }
            if (changes.milestones) {
                if (vm.dataGroups.length) {
                    setupChartData();
                }
            }
        }

        function onDestroy () {
            $window.removeEventListener('resize', vm.onResizeHandler);
        }

        function setupChartData () {
            vm.dataGroups = [];
            if (!vm.data || vm.data.length === 0) {
                return;
            }
            vm.chartSetupDone = false;
            vm.chart = {
                stepWidth: Math.round(vm.width / vm.data.length),
                height: vm.height * 0.8,
                baseY: 0,
            };
            vm.chart.baseX = (vm.width - vm.chart.stepWidth * (vm.data.length - 1)) / 2;
            vm.chart.maxScore = 100;
            if (vm.data.length === 1) {
                vm.data.unshift(vm.data[0]);
            }
            for (let i = 0; i < vm.data.length - 1; i++) {
                const startScore = vm.data[i].score || 0;
                const endScore = vm.data[i + 1].score || 0;
                const startTime = vm.data[i].time;
                const endTime = vm.data[i + 1].time;
                const dataGroup = {
                    start: {
                        x: i * vm.chart.stepWidth,
                        y: toPosY(startScore),
                        score: startScore,
                        time: startTime,
                        isBreakPoint: vm.data.length === 2,
                    },
                    end: {
                        x: (i + 1) * vm.chart.stepWidth,
                        y: toPosY(endScore),
                        score: endScore,
                        time: endTime,
                    },
                };
                const breakPoints = isContainBreakPoints(dataGroup.start, dataGroup.end);
                let lastPoint = $deepmerge({}, dataGroup.start);
                if (breakPoints.length > 0) {
                    for (var j = 0; j < breakPoints.length; j++) {
                        vm.dataGroups.push({
                            start: lastPoint,
                            end: breakPoints[j],
                        });
                        lastPoint = $deepmerge({}, breakPoints[j]);
                    }
                    vm.dataGroups.push({
                        start: lastPoint,
                        end: dataGroup.end,
                    });
                } else {
                    vm.dataGroups.push(dataGroup);
                }
            }
            const highestPoint = vm.dataGroups.reduce(function (res, cur) {
                if (res > cur.start.y) {
                    res = cur.start.y;
                }
                if (res > cur.end.y) {
                    res = cur.end.y;
                }
                return res;
            }, vm.height * 0.8);
            vm.headerStyle = {
                display: 'block',
                position: 'absolute',
                'padding-left': vm.chart.baseX,
                top: highestPoint / 2 - 40,
            };
            vm.chart.baseY = -highestPoint / 2 + 5;
            vm.chartSetupDone = true;

            $timeout(function () {
                vm.svg = $element[0].querySelector('#performance-score-line-chart');
                vm.svg.setAttribute('height', vm.height);
                vm.svg.setAttribute('width', vm.width);
                if (Array.isArray(vm.milestones) && vm.milestones.length) {
                    setMilestones();
                }
            });
        }

        function groupLine (dataset) {
            const start = dataset.start;
            const end = dataset.end;
            let path = '';
            const baseX = vm.chart.baseX;
            const baseY = vm.chart.baseY;
            path += 'M' + (baseX + start.x) + ' ' + (baseY + start.y) + ' ';
            path += 'L' + (baseX + end.x) + ' ' + (baseY + end.y) + ' ';
            return path;
        }

        function groupLineFillColor (dataset) {
            return PagePerformanceChartsService.getColorByScore(dataset.end.score * 100, 'dark');
        }

        function groupPath (dataset) {
            const start = dataset.start;
            const end = dataset.end;
            let path = '';
            const baseX = vm.chart.baseX;
            const baseY = vm.chart.baseY;
            path += 'M' + (baseX + start.x) + ' ' + (baseY + start.y) + ' ';
            path += 'L' + (baseX + end.x) + ' ' + (baseY + end.y) + ' ';
            path += 'L' + (baseX + end.x) + ' ' + (baseY + vm.height) + ' ';
            path += 'L' + (baseX + start.x) + ' ' + (baseY + vm.height) + ' ';
            path += 'Z';
            return path;
        }

        function groupPathFillColor (dataset) {
            return PagePerformanceChartsService.getColorByScore(dataset.end.score * 100, 'light');
        }

        function calculateXOnlineFromPoints (start, end, y) {
            const a = end.y - start.y;
            const b = start.x - end.x;
            const c = a * start.x + b * start.y;
            if (b < 0) {
                return Math.round((c - b * y) / a);
            } else {
                return Math.round((c + b * y) / a);
            }
        }

        function toPosY (score) {
            score = score * 100 || 1;
            return vm.height - (vm.chart.height * score) / vm.chart.maxScore - 5;
        }

        function isContainBreakPoints (start, end) {
            const breakPoints = [0.49, 0.89, 0.9];
            const containsBreakPoints = [];
            for (let i = 0; i < breakPoints.length; i++) {
                const breakPoint = breakPoints[i];
                if (breakPoint >= start.score && breakPoint <= end.score) {
                    containsBreakPoints.push({
                        x: calculateXOnlineFromPoints(start, end, toPosY(breakPoint)),
                        y: toPosY(breakPoint),
                        score: breakPoint,
                        isBreakPoint: true,
                    });
                }
            }
            return containsBreakPoints;
        }

        function setTooltip (event, dataset) {
            if (event.target.tagName !== 'path' || !vm.svg) {
                return;
            }
            const anchor = {};
            const mouseX = event.originalEvent.clientX - vm.svg.getBoundingClientRect().left - vm.svg.clientLeft - vm.chart.baseX;
            if (mouseX > dataset.start.x + (dataset.end.x - dataset.start.x) / 2) {
                anchor.x = dataset.end.x;
                anchor.y = vm.chart.baseY + dataset.end.y;
                if (dataset.end.isBreakPoint) {
                    return;
                }
                vm.tooltip.score = dataset.end.score;
                vm.tooltip.time = dataset.end.time;
            } else {
                anchor.x = dataset.start.x;
                anchor.y = vm.chart.baseY + dataset.start.y;
                if (dataset.start.isBreakPoint) {
                    return;
                }
                vm.tooltip.score = dataset.start.score;
                vm.tooltip.time = dataset.start.time;
            }

            vm.tooltipStyle.left = anchor.x + 18 + 'px';
            vm.tooltipStyle.top = anchor.y + 12 + 'px';
            vm.tooltipStyle.display = 'block';
            vm.tooltipStyle.color = PagePerformanceChartsService.getColorByScore(dataset.end.score * 100, 'dark');

            vm.tooltip.time = ng2DateTimeService.formatBrowserLocalized(vm.tooltip.time, 'll LTS');
        }

        function disableTooltip () {
            vm.tooltipStyle.display = 'none';
        }

        function setMilestones () {
            if (vm.dataGroups.length) {
                vm.milestonesToDisplay = vm.milestones.map(function (milestone) {
                    const correspondingPoint = vm.dataGroups[getClosestDateIndex(milestone.date, vm.dataGroups)];
                    const positionX = correspondingPoint && correspondingPoint.start && correspondingPoint.start.x;
                    return {
                        text: milestone.text,
                        link: milestone.link,
                        x: vm.chart.baseX + positionX + 1,
                    };
                });
                $timeout(adjustMilestonesTextPosition);
            }
        }

        function getClosestDateIndex (date, dates) {
            let result = -1;
            let diff;
            let diffCandidate;
            const len = dates.length;
            let dateToCheck;
            const timestamp = new Date(date).valueOf();
            diff = new Date(date).valueOf();

            for (let i = 0; i < len; i += 1) {
                dateToCheck = (dates[i].start || {}).time;
                if (dateToCheck) {
                    diffCandidate = Math.abs(timestamp - new Date(dateToCheck).valueOf());
                    if (diffCandidate < diff) {
                        diff = diffCandidate;
                        result = i;
                    }
                }
            }

            return result;
        }

        function adjustMilestonesTextPosition () {
            vm.svg = $element[0].querySelector('#performance-score-line-chart');
            if (vm.svg) {
                Array.prototype.forEach.call(vm.svg.querySelectorAll('.milestoneText'), function (el) {
                    const svgBounds = vm.svg.getBoundingClientRect();
                    const elBounds = el.getBoundingClientRect();
                    const widthShift = Math.round(elBounds.width / 2);
                    const originalX = Math.round(Number(el.getAttribute('data-x')));
                    const shiftHalfToLeft = originalX - widthShift;
                    const eLOriginalLeft = svgBounds.left + originalX;

                    if (eLOriginalLeft + elBounds.width - widthShift >= svgBounds.right) {
                        el.setAttribute('x', svgBounds.width - elBounds.width);
                    } else if (eLOriginalLeft + shiftHalfToLeft < svgBounds.left) {
                        el.setAttribute('x', 0);
                    } else {
                        el.setAttribute('x', shiftHalfToLeft);
                    }
                });
            }
        }
    }
})();
