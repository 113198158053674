(function () {
    'use strict';

    angular.module('filters.monsido').filter('stripHtml', StripHtmlFilter);

    StripHtmlFilter.$inject = [];
    function StripHtmlFilter () {
        return htmlToPlaintext;

        // //////////

        function htmlToPlaintext (text) {
            return text ? String(text).replace(/<[^>]+>/gm, '') : '';
        }
    }
})();
