(function () {
    'use strict';
    angular
        .module('modules.page_fix.services')
        .service('PageFixAccessibilityDifficultyService', PageFixAccessibilityDifficultyService);

    PageFixAccessibilityDifficultyService.$inject = ['gettextCatalog', 'PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY'];

    /* @ngInject*/
    function PageFixAccessibilityDifficultyService (gettextCatalog, PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY) {
        var vm = this;
        vm.difficulties = [
            {
                name: gettextCatalog.getString('Easy'),
                value: PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.EASY,
                weight: 0,
            },
            {
                name: gettextCatalog.getString('Medium'),
                value: PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.MEDIUM,
                weight: 10,
            },
            {
                name: gettextCatalog.getString('Hard'),
                value: PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.HARD,
                weight: 20,
            },
        ];
        vm.difficultyMap = {};
        vm.difficultyMap[PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.EASY] = 0;
        vm.difficultyMap[PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.MEDIUM] = 1;
        vm.difficultyMap[PAGE_FIX_ACCESSIBILITY_CHECK_DIFFICULTY.HARD] = 2;

        return {
            getDifficulty: getDifficulty,
        };

        // ///////////////

        function getDifficulty (difficultyKeyName) {
            return vm.difficulties[vm.difficultyMap[difficultyKeyName] || 0];
        }
    }
})();
