(function () {
    // TODO: Remove this component when reinstating sub sections for PageCorrect https://zube.io/monsido/monsido/c/21815
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixChecks', {
        templateUrl: 'app/modules/page-fix/pages/checks/checks.html',
        controller: PageFixChecksController,
        controllerAs: 'vm',
    });

    PageFixChecksController.$inject = [];
    /* @ngInject */
    function PageFixChecksController () {}
})();
