(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationMissingMetaDescription', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/missing-meta-description/missing-meta-description.html',
        controller: SeoTranslationsMultipleH1Controller,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTranslationsMultipleH1Controller.$inject = [];
    /* @ngInject */
    function SeoTranslationsMultipleH1Controller () {
        var vm = this;
    }
})();
