(function () {
    'use strict';

    angular.module('pages.domain').component('pageDetailsDashboardChartsAccessibility', {
        templateUrl: 'app/components/pagedetails/details/sections/dashboard/charts/accessibility/accessibility.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onClickHandler: '&',
        },
    });

    DashboardController.$inject = ['$filter', 'ng2PageAccessibilityRepoService', 'PAGE_DETAILS_SECTION_CONSTANT'];
    /* @ngInject */
    function DashboardController ($filter, ng2PageAccessibilityRepoService, PAGE_DETAILS_SECTION_CONSTANT) {
        var vm = this;
        vm.$onInit = activate;
        vm.getChecksWithErrorsCount = getChecksWithErrorsCount;

        function activate () {
            vm.checks = [];
            vm.totalIssuesCount = 0;
            vm.colors = ['#4dc0bd', '#dcdcdc'];
            vm.options = {};
            vm.data = [];
            vm.labels = ['', ''];
            getChecks().then(function (checks) {
                vm.checks = checks;
                setupChecksCount();
                setupDoughnut();
            }, angular.noop);
            vm.pageDeatilsSectionAccessibility = PAGE_DETAILS_SECTION_CONSTANT.ACCESSIBILITY;
        }

        function getChecks () {
            var params = {
                page_size: 0,
            };
            return ng2PageAccessibilityRepoService.getAllChecks(vm.page.id, params);
        }

        function setupDoughnut () {
            var totalCheckCount = vm.checks.reduce(function (num, check) {
                if (!check.ignored && !check.ignored_on_page) {
                    num++;
                }
                return num;
            }, 0);

            vm.data = [totalCheckCount - vm.totalcheckErrors, vm.totalcheckErrors];
            var percentage = (vm.totalcheckErrors / totalCheckCount) * 100;
            vm.options = {
                centerText: {
                    percentageLabel: $filter('monNumber')((100 - percentage) / 100, '0.[00] %'),
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: totalCheckCount > 0,
                    callbacks: {
                        beforeLabel: function () {
                            return '';
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs')(
                                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
                                '0,0',
                            );
                        },
                    },
                },
            };
        }

        function setupChecksCount () {
            vm.guidelineIds = [
                {
                    name: 'A',
                    guidelineId: 'WCAG2-A',
                    className: 'bubble-wcag-a',
                    abbr: 'A',
                },
                {
                    name: 'AA',
                    guidelineId: 'WCAG2-AA',
                    className: 'bubble-wcag-aa',
                    abbr: 'AA',
                },
                {
                    name: 'AAA',
                    guidelineId: 'WCAG2-AAA',
                    className: 'bubble-wcag-aaa',
                    abbr: 'AAA',
                },
                {
                    name: 'A',
                    guidelineId: 'WCAG21-A',
                    className: 'bubble-wcag-a',
                    abbr: 'A',
                },
                {
                    name: 'AA',
                    guidelineId: 'WCAG21-AA',
                    className: 'bubble-wcag-aa',
                    abbr: 'AA',
                },
                {
                    name: 'AAA',
                    guidelineId: 'WCAG21-AAA',
                    className: 'bubble-wcag-aaa',
                    abbr: 'AAA',
                },
            ];

            vm.totalcheckErrors = getChecksWithErrorsCount();
            vm.totalIssuesCount = vm.checks.reduce(function (num, check) {
                if (!check.ignored && !check.ignored_on_page) {
                    num += parseInt(check.errors_count);
                }
                return num;
            }, 0);
        }

        function getChecksWithErrorsCount (abbr) {
            return vm.checks.reduce(function (num, check) {
                if (!check.ignored && !check.ignored_on_page && check.errors_count > 0 && usesAbbr(abbr, check)) {
                    num++;
                }
                return num;
            }, 0);
        }

        function usesAbbr (abbr, check) {
            if (typeof abbr !== 'string') {
                return true;
            }
            return check.issue_abbr === abbr;
        }
    }
})();
