import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyExchangeCenter', {
        templateUrl: 'app/forms/global-policy/steps/exchange-center/exchange-center.html',
        controller: GlobalPolicyExchangeCenterController,
        controllerAs: 'vm',
        bindings: {
            chooseTemplate: '&',
        },
    });

    GlobalPolicyExchangeCenterController.$inject = ['ng2MonEventsService', 'FORM_CONSTANT'];

    /* @ngInject */
    function GlobalPolicyExchangeCenterController (ng2MonEventsService, FORM_CONSTANT) {
        var vm = this;
        vm.$onInit = activate;
        vm.suggestPolicy = suggestPolicy;

        function activate () {}

        function suggestPolicy () {
            var params = {
                body: FORM_CONSTANT.FORM_FEEDBACK,
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
