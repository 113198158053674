(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuPolicy', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: PolicySideMenuController,
        controllerAs: 'vm',
    });

    PolicySideMenuController.$inject = ['gettextCatalog', '$filter'];
    function PolicySideMenuController (gettextCatalog, $filter) {
        var vm = this;
        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'POLICIES', 'SUMMARY']),
                    href: 'base.customer.domain.policy.dashboard',
                },
                {
                    title: gettextCatalog.getString('Content with Policy Matches'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'POLICIES', 'PAGES']),
                    href: 'base.customer.domain.policy.pages',
                },
                {
                    title: gettextCatalog.getString('Policy List'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'POLICIES', 'POLICIES']),
                    href: 'base.customer.domain.policy.policies',
                },
                {
                    title: gettextCatalog.getString('Pages with Ignored Checks'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'POLICIES', 'IGNORED_PAGES']),
                    href: 'base.customer.domain.policy.ignored_pages',
                },
            ];
        }
    }
})();
