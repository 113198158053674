(function () {
    'use strict';

    angular.module('modules.statistics').component('statisticsVisits', {
        templateUrl: 'app/modules/statistics/components/statistics-visits/statistics-visits.html',
        controller: VisitsComponent,
        controllerAs: 'vm',
        bindings: {
            visits: '@',
            totalVisits: '@',
            removeNumber: '<?',
        },
    });

    VisitsComponent.$inject = [];
    /* @ngInject */
    function VisitsComponent () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.visits = parseInt(vm.visits);
            vm.totalVisits = parseInt(vm.totalVisits);
            vm.progress = 0;

            if (vm.totalVisits > 0) {
                vm.progress = (vm.visits / vm.totalVisits) * 100;
            }

            if (vm.progress > 100) {
                vm.progress = 100;
            }

            if (vm.progress < 0) {
                vm.progress = 0;
            }
        }
    }
})();
