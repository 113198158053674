(function () {
    'use strict';
    angular.module('modules.wizard').service('WizardHandlerService', WizardHandlerService);

    WizardHandlerService.$inject = ['rx', '$timeout', 'ng2ErrorHandler'];
    /* @ngInject*/
    function WizardHandlerService (rx, $timeout, ng2ErrorHandler) {
        var vm = this;
        vm.wizards = {};
        vm.stepObservable = {};

        return {
            getObservableStep: getObservableStep,
            getWizard: getWizard,
            addWizard: addWizard,
            removeWizard: removeWizard,
            isFirstStep: isFirstStep,
            isLastStep: isLastStep,
            getStepIndex: getStepIndex,
            getEnabledSteps: getEnabledSteps,
            goToStep: goToStep,
        };

        function getObservableStep (name) {
            if (!vm.stepObservable[name]) {
                ng2ErrorHandler.standardMsg('Observable Wizard Step "' + name + '" doesn\'t exist');
                return;
            }
            return vm.stepObservable[name];
        }

        function getWizard (name) {
            if (!vm.wizards[name]) {
                ng2ErrorHandler.standardMsg('Wizard "' + name + '" doesn\'t exist');
                return;
            }
            return vm.wizards[name];
        }

        function addWizard (name, wizard) {
            if (vm.wizards[name]) {
                ng2ErrorHandler.standardMsg('Wizard "' + name + '" already exists');
                return;
            }
            vm.wizards[name] = wizard;
            vm.stepObservable[name] = new rx.BehaviorSubject(null);
        }

        function removeWizard (name) {
            if (!vm.wizards[name]) {
                ng2ErrorHandler.standardMsg('Wizard "' + name + '" doesn\'t exist');
                return;
            }
            $timeout(function () {
                delete vm.wizards[name];
                delete vm.stepObservable[name];
            });
        }

        function getStepIndex (name) {
            var wizard = getWizard(name);
            return wizard.getCurrentStep();
        }

        function getEnabledSteps (name) {
            var wizard = getWizard(name);
            return wizard.getEnabledSteps();
        }

        function goToStep (index, name) {
            var wizard = getWizard(name);
            wizard.goToStep(index);
        }

        function isFirstStep (name) {
            var stepIndex = getStepIndex(name);
            var enabledSteps = getEnabledSteps(name);
            return enabledSteps.length === 0 || stepIndex === 0;
        }

        function isLastStep (name) {
            var stepIndex = getStepIndex(name);
            var enabledSteps = getEnabledSteps(name);
            return enabledSteps.length - 1 <= stepIndex;
        }
    }
})();
