(function () {
    'use strict';

    angular.module('modules.modals.directives').directive('monModalPrompt', monModalPrompt);

    monModalPrompt.$inject = ['ng2MonModalService', 'gettextCatalog'];

    /* @ngInject */
    function monModalPrompt (ng2MonModalService, gettextCatalog) {
        return {
            restrict: 'A',
            scope: {
                actionOK: '&monModalPromptAction',
                actionCancel: '&monModalPromptActionCancel',
            },
            link: function (scope, element, attr) {
                var msg = attr.monModalPrompt || gettextCatalog.getString('Are you sure?');
                element.bind('click', function () {
                    ng2MonModalService.prompt(msg).then(
                        function (result) {
                            scope.actionOK({ result: result });
                        },
                        function () {
                            scope.actionCancel();
                        },
                    );
                });
            },
        };
    }
})();
