(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityGuidelines', {
        templateUrl: 'app/pages/accessibility/guidelines/guidelines.html',
        controller: AccessibilityGuidelinesController,
        controllerAs: 'vm',
    });
    AccessibilityGuidelinesController.$inject = [
        'ng2AccessibilityGuidelinesRepoService',
        'ng2SessionService',
        'activeFeatures',
        '$controller',
        'gettextCatalog',
        '$filter',
    ];

    function AccessibilityGuidelinesController (
        ng2AccessibilityGuidelinesRepoService,
        ng2SessionService,
        activeFeatures,
        $controller,
        gettextCatalog,
        $filter,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFilterChange = onFilterChange;
        vm.getSubHeader = getSubHeader;

        function activate () {
            vm.search = '';
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.onSearch = onSearch;
            vm.accessibility = ng2SessionService.domain.features.accessibility;
            vm.showFilters = activeFeatures.table_filters;
            setupFilters();
            getPage();
        }

        function setupFilters () {
            vm.accessibilityType = [];
            vm.accessibilityAbbr = [];

            vm.filters = {};
            vm.filters[gettextCatalog.getString('Accessibility review type')] = [];
            vm.filters[gettextCatalog.getString('Accessibility level')] = [];

            vm.availableFilters = {};
            vm.availableFilters[gettextCatalog.getString('Accessibility level')] = getAccessibilityOptions();
            if (vm.availableFilters[gettextCatalog.getString('Accessibility level')].length === 0) {
                // Remove
                delete vm.availableFilters[gettextCatalog.getString('Accessibility level')];
            }
            vm.availableFilters[gettextCatalog.getString('Accessibility review type')] = [
                {
                    name: gettextCatalog.getString('Error'),
                    value: 'error',
                },
                {
                    name: gettextCatalog.getString('Warning'),
                    value: 'warning',
                },
                {
                    name: gettextCatalog.getString('Review'),
                    value: 'review',
                },
            ];
        }

        function getAccessibilityOptions () {
            var accessibilityOptions = [];

            switch (vm.accessibility) {
                case 'WCAG2-AAA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AAA'),
                        value: 'AAA',
                    });
                    break;
                case 'WCAG2-AA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    break;
                case 'WCAG21-AAA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AAA'),
                        value: 'AAA',
                    });
                    break;
                case 'WCAG21-AA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    break;
                case 'WCAG22-AAA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AAA'),
                        value: 'AAA',
                    });
                    break;
                case 'WCAG22-AA':
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level A'),
                        value: 'A',
                    });
                    accessibilityOptions.push({
                        name: gettextCatalog.getString('Level AA'),
                        value: 'AA',
                    });
                    break;
            }

            return accessibilityOptions;
        }

        function getPage () {
            vm.progress = ng2AccessibilityGuidelinesRepoService.getAll().then(function (data) {
                vm.totalGuidelines = data;
                setGuidelines(data);
            }, angular.noop);
        }

        function filterBySearch (guidelines) {
            // Search sub groups
            if (vm.search.length === 0) {
                return guidelines;
            }

            return guidelines
                .map(function (guideline) {
                    guideline.subgroups = $filter('filter')($filter('searchField')(guideline.subgroups, ['abbr', 'name']), vm.search);
                    return guideline.subgroups.length > 0 ? guideline : undefined;
                })
                .filter(Boolean);
        }

        function filterByAbbreviation (guidelines) {
            // Filter by abbreviation (A, AA, AAA)
            if (!Array.isArray(vm.accessibilityAbbr) || vm.accessibilityAbbr.length === 0) {
                return guidelines;
            }

            var mappedGuidelines = guidelines.map(function (guideline) {
                guideline.subgroups = guideline.subgroups.filter(function (subGroup) {
                    return vm.accessibilityAbbr.indexOf(subGroup.abbr) > -1;
                });

                return guideline.subgroups.length > 0 ? guideline : undefined;
            });
            return mappedGuidelines.filter(Boolean);
        }

        function filterByType (guidelines) {
            // Filter by type (Error, warning, review) condition is {Number} > 0
            if (!Array.isArray(vm.accessibilityType) || vm.accessibilityType.length === 0) {
                return guidelines;
            }

            var mappedGuidelines = guidelines.map(function (guideline) {
                guideline.subgroups = guideline.subgroups.filter(function (subGroup) {
                    for (var i = 0; i < vm.accessibilityType.length; i++) {
                        if (subGroup[vm.accessibilityType[i]] > 0) {
                            return true;
                        }
                    }
                    return false;
                });

                return guideline.subgroups.length > 0 ? guideline : undefined;
            });
            return mappedGuidelines.filter(Boolean);
        }

        function onFilterChange (changes) {
            if (
                angular.isDefined(changes[gettextCatalog.getString('Accessibility review type')]) &&
                angular.isArray(changes[gettextCatalog.getString('Accessibility review type')])
            ) {
                vm.accessibilityType = changes[gettextCatalog.getString('Accessibility review type')].map(function (type) {
                    return type.value;
                });
            }
            if (
                angular.isDefined(changes[gettextCatalog.getString('Accessibility level')]) &&
                angular.isArray(changes[gettextCatalog.getString('Accessibility level')])
            ) {
                vm.accessibilityAbbr = changes[gettextCatalog.getString('Accessibility level')].map(function (abbr) {
                    return abbr.value;
                });
            }
            getPage();
        }

        function filterByWcag2 (guidelines) {
            var result = {
                principleOne: [],
                principleTwo: [],
                principleThree: [],
                principleFour: [],
            };

            angular.forEach(guidelines, function (guideline) {
                if (guideline.number >= 1 && guideline.number < 2) {
                    result.principleOne.push(guideline);
                } else if (guideline.number >= 2 && guideline.number < 3) {
                    result.principleTwo.push(guideline);
                } else if (guideline.number >= 3 && guideline.number < 4) {
                    result.principleThree.push(guideline);
                } else if (guideline.number >= 4 && guideline.number < 5) {
                    result.principleFour.push(guideline);
                }
            });

            return result;
        }

        function filterForFivePrinciples (guidelines) {
            var result = {
                principleOne: [],
                principleTwo: [],
                principleThree: [],
                principleFour: [],
                principleFive: [],
            };

            angular.forEach(guidelines, function (guideline) {
                if (guideline.number >= 1 && guideline.number < 2) {
                    result.principleOne.push(guideline);
                } else if (guideline.number >= 2 && guideline.number < 3) {
                    result.principleTwo.push(guideline);
                } else if (guideline.number >= 3 && guideline.number < 4) {
                    result.principleThree.push(guideline);
                } else if (guideline.number >= 4 && guideline.number < 5) {
                    result.principleFour.push(guideline);
                } else if (guideline.number >= 5 && guideline.number < 6) {
                    result.principleFive.push(guideline);
                }
            });

            return result;
        }

        function onSearch (search) {
            vm.search = search;
            setGuidelines(vm.totalGuidelines);
        }

        function setGuidelines (guidelines) {
            guidelines = angular.copy(guidelines);
            guidelines = filterByAbbreviation(guidelines);
            guidelines = filterByType(guidelines);
            guidelines = filterBySearch(guidelines);

            if ($filter('accessibilityHasGuideline')('wcag2')) {
                vm.guidelines = filterByWcag2(guidelines);
            } else if ($filter('accessibilityHasGuideline')('wcag21')) {
                vm.guidelines = filterForFivePrinciples(guidelines);
            } else if ($filter('accessibilityHasGuideline')('wcag22')) {
                vm.guidelines = filterForFivePrinciples(guidelines);
            } else if ($filter('accessibilityHasGuideline')('qualweb')) {
                vm.guidelines = filterForFivePrinciples(guidelines);
            }
        }

        function getSubHeader () {
            var guideline;
            if ($filter('accessibilityHasGuideline')('wcag2')) {
                guideline = 'WCAG 2.0';
            } else if ($filter('accessibilityHasGuideline')('wcag21')) {
                guideline = 'WCAG 2.1';
            } else if ($filter('accessibilityHasGuideline')('wcag22')) {
                guideline = 'WCAG 2.2';
            } else if ($filter('accessibilityHasGuideline')('qualweb')) {
                guideline = 'QUALWEB';
            }
            return gettextCatalog.getString('List of checks with and without issues categorized by {{level}} guidelines', {
                level: guideline,
            });
        }
    }
})();
