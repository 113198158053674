 <div class="search-and-filter">
     <mon-filter-button [isFiltersOpen]="filterIsOpen" (click)="toggleFilter()" [showFiltersBtn]="true"></mon-filter-button>
     <div class="search-container">
         <mon-table-search [size]="'lg'" [model]="search$.value" (getPage)="search$.next($event)" [minSearchLength]="3"></mon-table-search>
     </div>
 </div>
 <mon-table-filters [filters]="filters$.value" (filtersChange)="filters$.next($event)" [(open)]="filterIsOpen">
 </mon-table-filters>
 <ul class="report-list-component">
     <li *ngFor="let reports of mapOfReports">
         <h2>
             {{ reports[0] }}
         </h2>
         <ul>
             <li *ngFor="let report of reports[1]">
                 <mon-report [report]="report" (getPage)="getPage.emit()" (fetchReceivedReports)="fetchReceivedReports.emit()"></mon-report>
             </li>
         </ul>
     </li>
 </ul>
