<div class="label-blocked-by-domain-groups">
    <p class="label-blocked-by-domain-groups-description" translate>Label used in the groups below. Please edit or delete the groups and try again.</p>
    <div *ngFor="let domain of monDomains">
        <div class="domain-row">
            <div class="domain-status">
                <span class="fas fa-exclamation-triangle fa-2x inactive-domain-color" [ngClass]="{invisible: domain.active}"></span>
            </div>
            <div class="domain-url-container">
                <mon-url-title [title]="domain.title"
                               [url]="domain.url"
                    ></mon-url-title>
            </div>
        </div>
        <div *ngFor="let domainGroup of domain.domain_groups" class="domain-group-row">
            <div class="domain-group-icon"><span class="fas fa-copy fa-2x"></span></div>
            <div>
                <div class="domain-group-title">{{ domainGroup.title }}</div>
                <div class="domain-group-labels" *ngIf="monLabel">
                    <div class="domain-group-label-block" [ngStyle]="{backgroundColor: monLabel.color}"></div>
                </div>
            </div>
        </div>
    </div>
</div>

