import { Injectable } from '@angular/core';
import { ApiService } from '../api/api-service/api.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { AccessibilityCheckNameType } from '@client/app/models/types/accessibility-check-name.type';

@Injectable({
    providedIn: 'root',
})
export class GlobalAccessibilityChecksRepoService {
    constructor (private apiService: ApiService) { }

    getAll (params?: RequestParamsType): Promise<AccessibilityCheckNameType[]> {
        return this.apiService.get('accessibility_checks', params);
    }

    update (check: { id: number, labels: string[] }): Promise<AccessibilityCheckNameType> {
        return this.apiService.patch('accessibility_checks/' + check.id, check);
    }
}
