<div class="cookie-overview-language-tabs-container">
    <div class="cookie-overview-language-tab" *ngFor="let language of monShowLanguages">
        <button type="button"
                class="btn cookie-overview-language-btn"
                [ngClass]="{active: monModel === language.value}"
                (click)="onTabClick(language)">
            {{ language.name }}
        </button>
        <button *ngIf="monShowLanguages.length > 1"
                class="btn remove-language-btn" 
                type="button" 
                [attr.aria-label]="'Delete the language tab - [[language]]' | translate:undefined:{language: language.name}"
                (click)="onRemoveClick(language)">
            <span class="fas fa-times"></span>
        </button>
    </div>
    
    <div class="cookie-overview-language-add-tab" *ngIf="languageOptionsList.length > 0">
        <button #addButtonRef
                id="cookieOverviewLanguageMenuBtn"
                type="button"
                class="btn add-language-btn"
                (click)="showLanguagesList($event)">
            <span class="fas fa-plus"></span>
            <span class="sr-only">{{'Add language' | translate}}</span>
        </button>
        <mon-scrollable-dropdown-menu 
            class="language-options-list"
            *ngIf="showLanguageOptionsList"
            [monFocusFirstMenuItem]="false"
            [monMenuItems]="languageOptionsListView"
            [monId]="'cookieOverviewLanguageMenu'"
            [monLabelledBy]="'cookieOverviewLanguageMenuBtn'"
            (monOnSelect)="onAddLanguage($event)"
            (monOnClose)="hideLanguagesList($event)"
            (clickOutside)="hideLanguagesList()"
        ></mon-scrollable-dropdown-menu>
    </div>
</div>
