(function () {
    'use strict';
    angular.module('pages.seo').component('seoDashboardIssues', {
        templateUrl: 'app/pages/seo/issues/issues.html',
        controller: SeoDashboardIssuesController,
        controllerAs: 'vm',
    });

    SeoDashboardIssuesController.$inject = ['ng2PageRepoService', 'complianceService'];

    function SeoDashboardIssuesController (ng2PageRepoService, complianceService) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.pageCount = 0;
            setPageCount();
        }

        function setPageCount () {
            var params = {
                page: 1,
                page_size: 1,
            };
            if (!complianceService.hasLegacyComplianceOnChecks()) {
                params.type = 'html';
            }
            ng2PageRepoService.getAll(params).then(function (data) {
                vm.pageCount = parseInt(data.total, 10);
            }, angular.noop);
        }
    }
})();
