import { Component, Input, OnChanges } from '@angular/core';
import { ACCESSIBILITY_HELPER } from '@monsido/core/constants/accessibility-helper.constant';
import { SessionService } from '@monsido/core/session/session.service';
import { FullUrlPipe } from 'ng2/shared/pipes/full-url/full-url.pipe';
import { AccessibilityError, Page } from '../../models';

@Component({
    selector: 'mon-page-details-section-accessibility-image',
    templateUrl: './page-details-section-accessibility-image.component.html',
})
export class PageDetailsSectionAccessibilityImageComponent implements OnChanges {
    @Input() page?: Page;
    @Input() model?: AccessibilityError;

    images: Array<string> = [];

    constructor (private fullUrlPipe: FullUrlPipe, private sessionService: SessionService) {}

    ngOnChanges (): void {
        this.checkIfCheckIncludesImage();
    }

    checkIfCheckIncludesImage (): void {
        if (!this.page || !this.model || ACCESSIBILITY_HELPER.IMG_CHECKS.indexOf(this.model.check_id) === -1) {
            return;
        }

        const srcRegex = new RegExp('src=["\'](.*?)["\']', 'g');
        let matches: RegExpExecArray | null;

        do {
            matches = srcRegex.exec(this.model.source_code);
            if (!matches || matches.length < 2) {
                continue;
            }

            const url = matches[1].startsWith('data:image')
                ? matches[1]
                : this.fullUrlPipe.transform(matches[1], this.sessionService.domain?.url, this.page.url);

            if (url) {
                this.images.push(url);
            }
        } while (matches);
    }
}
