import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { getQUALWEBGuidelineInfoData, getWCAGGuidelineInfoData, GuidelineInfoType } from './guideline-info.data';

@Injectable({
    providedIn: 'root',
})
export class GuidelineInfoService {

    wcag: GuidelineInfoType;
    qw: GuidelineInfoType;
    currentWCAGGuidelineVersion = 5;
    currentQWGuidelineVersion = 1;

    constructor (
        private translateService: TranslateService,
    ) {
        this.wcag = getWCAGGuidelineInfoData(this.translateService);
        this.qw = getQUALWEBGuidelineInfoData(this.translateService);
    }
}
