import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';

@Pipe({
    name: 'simpleNumberLocaleFormat',
})
export class SimpleNumberLocaleFormatPipe implements PipeTransform {

    constructor (private sessionService?: SessionService) { }

    transform (value: number | string): string {
        if (value == undefined) {
            return '';
        }

        if (typeof value === 'string') {
            value = Number(value);
        }

        return value.toLocaleString(this.getUserLocale());
    }


    private getUserLocale (): string | undefined {
        switch (this.sessionService?.me?.locale) {
            case 'da':
                return 'da-dk';
            case 'sv':
                return 'sv-se';
            case 'en':
                return 'en-gb';
            case 'nl':
                return 'nl-nl';
        }
    }
}


