(function () {
    'use strict';
    angular.module('app.components').directive('accessibilityCcListItem', [
        'AccessibilityCloseCaptionService',
        function (AccessibilityCloseCaptionService) {
            return {
                bindToController: true,
                restrict: 'A',
                controller: AccessibilityCCListItemController,
                controllerAs: 'vm',
                template: '<a title="{{ vm.title }}" translate>Closed Caption</a>',
                link: function (scope, elem) {
                    var ctrl = scope.vm;
                    if (AccessibilityCloseCaptionService.showCloseCaption(ctrl.check)) {
                        elem.attr('aria-label', ctrl.title);
                        elem.bind('click', function () {
                            ctrl.confirmation();
                        });
                    } else {
                        elem.remove();
                    }
                },
                scope: {
                    check: '<',
                    snippetId: '<',
                    onCloseCaptionSuccess: '&?', // Returns caption
                    onCloseCaptionError: '&?', // Returns response
                    afterCloseCaption: '&?',
                },
            };
        },
    ]);

    AccessibilityCCListItemController.$inject = [
        'AccessibilityCCService',
        'ng2MonModalService',
        'gettextCatalog',
        'ng2TrapFocusService',
    ];

    /* @ngInject */
    function AccessibilityCCListItemController (AccessibilityCCService, ng2MonModalService, gettextCatalog, ng2TrapFocusService) {
        var vm = this;
        vm.title = AccessibilityCCService.getTitle();
        vm.confirmation = confirmation;

        function closeCaption () {
            AccessibilityCCService.closeCaption(
                vm.snippetId,
                vm.onCloseCaptionSuccess,
                vm.onCloseCaptionError,
                vm.afterCloseCaption,
            );
        }

        function confirmation () {
            var message = AccessibilityCCService.getConfirmationMessage();
            var options = {
                message: message,
                title: vm.title,
            };
            ng2MonModalService.confirm(options).then(function () {
                var msgOpts = {
                    message: AccessibilityCCService.getShortenedConfirmationMessage(),
                    title: '',
                    buttons: [
                        {
                            label: gettextCatalog.getString('Cancel'),
                            className: 'btn btn-secondary',
                            callback: function () {
                                ng2TrapFocusService.unPauseLast();
                            },
                            value: 'cancel',
                        },
                        {
                            label: gettextCatalog.getString('Confirm'),
                            className: 'btn btn-primary',
                            callback: function () {
                                closeCaption();
                                ng2TrapFocusService.unPauseLast();
                            },
                            value: 'success',
                        },
                    ],
                };
                ng2MonModalService.customDialog(msgOpts);
            }, angular.noop);
        }
    }
})();
