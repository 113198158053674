import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color, ColorPickerControl } from '@iplab/ngx-color-picker';
import { ColorHelperService } from 'app/modules/tools/services/color-contrast/color-helper.service';
import { debounce } from 'lodash';

@Component({
    selector: 'mon-color-simple-picker',
    templateUrl: './color-simple-picker.html',
    styleUrls: ['./color-simple-picker.scss'],
})
export class ColorSimplePickerComponent implements OnInit {

    @Input() colorValue: string = '';
    @Input() secondColor: string = '';
    @Input() position: 'top' | 'bottom' = 'bottom';

    @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();

    readonly compactControl = new ColorPickerControl();

    validateColor: () => void = () => {};

    colorPickerShown = false;
    isColorValid = true;

    constructor (
        private colorService: ColorHelperService,
    ) { }

    ngOnInit (): void {
        this.compactControl.hidePresets();
        this.compactControl.value = new Color(this.colorValue);
        this.compactControl.valueChanges.subscribe((color) => {
            this.colorValue = color.toHexString();
            this.validateColor();
        });

        this.validateColor = debounce(() => {
            this.isColorValid = this.colorService.isHEXCodeValid(this.colorValue);
            this.onValueChange.emit(this.colorValue);
        }, 100);
    }

    onInputChange (): void {
        this.validateColor();
        this.onValueChange.emit(this.colorValue);
    }

    showColorPicker (): void {
        this.colorPickerShown = true;
    }

    hideColorPicker (): void {
        if (this.colorPickerShown) {
            this.colorPickerShown = false;
        }
    }

}
