export const FORM_CONSTANT = {
    LITE_DOMAIN_FORM: 'formLiteDomain',
    FORM_LITE_USER: 'formLiteUser',
    FORM_USER: 'formUser',
    FORM_API_USERS: 'formApiUsers',
    FORM_DOMAIN: 'formDomain',
    FORM_FEEDBACK: 'formFeedback',
    FORM_DOMAIN_GROUPS_EDIT: 'formDomainGroupsEdit',
    FORM_DOMAIN_GROUPS_CREATE: 'formDomainGroupsCreate',
    FORM_IMPORT_GROUP_RULES: 'formImportGroupRules',
    FORM_PROFILE: 'formProfile',
    FORM_PAGE_PERFORMANCE_PROFILE: 'formPagePerformanceProfile',
    FORM_SHORTCUT: 'formShortcut',
    FORM_PAGE_PERFORMANCE_PAGE: 'formPagePerformancePage',
    FORM_LABEL: 'formLabel',
    FORM_GLOBAL_POLICY: 'formGlobalPolicy',
    FORM_GLOBAL_POLICY_PRE_CONTENT: 'formGlobalPolicyPreContent',
    FORM_GLOBAL_POLICY_DIALOG_SAVE: 'formGlobalPolicyDialogSave',
    FORM_GLOBAL_POLICY_DIALOG_RULE: 'formGlobalPolicyDialogRule',
    FORM_DOMAIN_EXCLUDE_IP: 'formDomainExcludeIp',
    FORM_DOMAIN_SCRIPT_GUIDE: 'formDomainScriptGuide',
    FORM_DOMAIN_SCRIPT_GUIDE_LEGACY: 'formDomainScriptGuideLegacy',
    FORM_CUSTOMER_INFO: 'formCustomerInfo',
    FORM_SOURCE_CODE_EXCLUDE: 'formSourceCodeExclude',
    FORM_UPTIME_SCHEDULED_DOWNTIME: 'formUptimeScheduledDowntime',
    FORM_UPTIME_SCHEDULED_DOWNTIMES: 'formUptimeScheduledDowntimes',
    FORM_PAGE_ASSIST_SCRIPT_SETTINGS: 'formPageAssistScriptSettings',
    FORM_CONSENT_MANAGER_SCRIPT_SETTINGS: 'formConsentManagerSettings',
} as const;
