import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.inventory.content.links').component('inventoryDialogsContentLinks', {
        templateUrl: 'app/pages/inventory/dialogs/links/links.html',
        controller: InventoryDialogsLinksController,
        controllerAs: 'vm',
    });

    InventoryDialogsLinksController.$inject = [
        'ng2LinksRepoService',
        '$controller',
        '$stateParams',
        'ng2SessionService',
        'ng2MonEventsService',
        'gettextCatalog',
        '$filter',
    ];

    function InventoryDialogsLinksController (
        ng2LinksRepoService,
        $controller,
        $stateParams,
        ng2SessionService,
        ng2MonEventsService,
        gettextCatalog,
        $filter,
    ) {
        var vm = this;
        vm.getPage = getPage;
        vm.openDialog = openDialog;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.links = [];
            vm.search = null;
            vm.domain_id = $stateParams.domainId;
            vm.scanDocuments = ng2SessionService.domain.scan.scan_documents;
            setupTabs();
            getPage();
            vm.export = [
                {
                    name: gettextCatalog.getString('Internal Export'),
                    data: {
                        category: 'inventory_links',
                        category_ref: 'internal',
                    },
                },
            ];

            vm.exports = [
                {
                    name: gettextCatalog.getString('External Export'),
                    data: {
                        category: 'inventory_links',
                        category_ref: 'external',
                    },
                },
            ];
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Internal'),
                    value: 'internal',
                },
                {
                    name: gettextCatalog.getString('External'),
                    value: 'external',
                },
            ];
            vm.activeTab = vm.tabs[0].value;
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                type: 'link',
                relation: vm.activeTab,
                page_fixed: false,
            };

            vm.promise = ng2LinksRepoService.getAll(params).then(function (data) {
                vm.links = data;
            }, angular.noop);
        }

        function openDialog (link, type) {
            var params = {
                body: 'pagesFilesPages',
                size: 'lg',
                data: {
                    link: link,
                    domainId: vm.domain_id,
                    active: 'links',
                    icon: $filter('monIcons')(['INVENTORY', 'DIALOGS', 'LINKS']),
                    type: type,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
