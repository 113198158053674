import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';

type LocaleOptionType = {
    name: string;
    value: string;
    numeralValue: string;
};

@Injectable({
    providedIn: 'root',
})
export class UserLocaleService {
    readonly defaultOption: LocaleOptionType;
    readonly localeOptions: LocaleOptionType[];

    constructor (
        translateService: TranslateService,
    ) {
        this.defaultOption = {
            name: translateService.getString('English'),
            value: 'en',
            numeralValue: 'en-gb',
        };

        this.localeOptions = [
            this.defaultOption,
            {
                name: translateService.getString('Danish'),
                value: 'da',
                numeralValue: 'da-dk',
            },
            {
                name: translateService.getString('Dutch'),
                value: 'nl',
                numeralValue: 'nl-nl',
            },
            {
                name: translateService.getString('Japanese'),
                value: 'ja',
                numeralValue: 'ja',
            },
            {
                name: translateService.getString('Norwegian'),
                value: 'nb',
                numeralValue: 'no',
            },
            {
                name: translateService.getString('Swedish'),
                value: 'sv',
                numeralValue: 'da-dk', // Swedish format is not supported but is the same as danish
            },
            {
                name: translateService.getString('French'),
                value: 'fr',
                numeralValue: 'fr',
            },
        ];
    }

    getLocaleOptions (): LocaleOptionType[] {
        return this.localeOptions;
    }

    getNumeralValue (locale: string = ''): string {
        const option = this.localeOptions.find(opt => opt.value === locale) ||
            this.defaultOption;

        return option.numeralValue;
    }
}
