import { OauthService } from '@monsido/oauth/oauth.service';
import { Injectable } from '@angular/core';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { IssueTypeEnum } from '@monsido/core/enum/issue-type.enum';
import { ComponentType } from '@angular/cdk/overlay';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { AccessibilityIssueComponent } from 'app/modules/accessibility/components/accessibility-issue/accessibility-issue.component';
import { DataPrivacyComponent } from '@monsido/modules/data-privacy/components/data-privacy-issue/data-privacy-issue.component';
import { QaBrokenIssueComponent } from 'app/modules/qa/components/qa-broken-issue/qa-broken-issue.component';
import { QaMisspellingIssueComponent } from 'app/modules/qa/components/qa-misspelling-issue/qa-misspelling-issue.component';
import { MonUIRouterTransitionService } from '../ui-router-transition-service/ui-router-transition-service';

@Injectable()
export class OverlayService {

    constructor (
        private eventService: MonEventService,
        private stateService: StateService,
        private uiRouterGlobals: UIRouterGlobals,
        private monUiRouterTransitionService: MonUIRouterTransitionService,
        private oauthService: OauthService,
    ) {}

    async openOverlay (params: Record<string, string>): Promise<void> {
        if (!this.oauthService.isAuthenticated()) {
            return Promise.resolve();
        }

        const { issueOverlayIssueId, issueOverlayIssueType } = params;
        let component: ComponentType<unknown> | undefined;

        switch (issueOverlayIssueType) {
            case IssueTypeEnum.accessibility:
                component = AccessibilityIssueComponent;
                break;
            case IssueTypeEnum.qaBrokenLink:
            case IssueTypeEnum.qaBrokenImage:
                component = QaBrokenIssueComponent;
                break;
            case IssueTypeEnum.misspellings:
            case IssueTypeEnum.potentialMisspellings:
                component = QaMisspellingIssueComponent;
                break;
            case IssueTypeEnum.dataPrivacy:
                component = DataPrivacyComponent;
                break;
        }

        if (issueOverlayIssueId && component) {
            if (this.hasNoActiveTransition()) {
                this.requestOverlay(issueOverlayIssueId, component);
            } else {
                await this.whenCurrentTransitionEnds();
                this.requestOverlay(issueOverlayIssueId, component);
            }
        }
    }

    closeAllDialogs (): void {
        this.eventService.run(MON_EVENTS.CLOSE_ALL_OVERLAY_DIALOGS);
    }

    private requestOverlay (issueId: string, component: ComponentType<unknown>): void {
        const beforeCloseCallbacks: Array<() => Promise<boolean>> = [];
        const beforeCloseCb = async (): Promise<boolean> => {
            for (const callback of beforeCloseCallbacks) {
                if (!(await callback())) {
                    return false;
                }
            }
            await this.removeOverlayQueryParams();
            return true;
        };

        this.eventService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component,
            data: {
                issueId,
                beforeCloseCallbacks,
            },
            beforeCloseCb,
        });
    }

    private async removeOverlayQueryParams (): Promise<void> {
        if (this.hasNoActiveTransition()) {
            await this.doCloseTransition();
        } else {
            await this.whenCurrentTransitionEnds();
            await this.doCloseTransition();
        }
    }

    private hasNoActiveTransition (): boolean {
        let noActiveTransition = true;

        try {
            noActiveTransition = !this.uiRouterGlobals.transition.isActive();
        } catch (_e) {
            // uiRouter silently fails, if there is no active transition
        }

        return noActiveTransition;
    }

    private async doCloseTransition (): Promise<void> {
        await this.monUiRouterTransitionService.onTransitionFinishedAsync();
        await this.stateService.transitionTo(
            this.uiRouterGlobals.$current.name,
            // TODO: add a handler for multiple overlays here
            Object.assign(this.uiRouterGlobals.params, { issueOverlay: '' }),
        );
    }

    private whenCurrentTransitionEnds (): Promise<void> {
        return new Promise((resolve) => {
            this.uiRouterGlobals.transition.onSuccess({}, async () => {
                resolve();
            });
            this.uiRouterGlobals.transition.onError({}, async () => {
                resolve();
            });
        });

    }
}
