import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { AccessibilityCheckEntityType } from '@client/app/modules/accessibility/interfaces/accessibility-check.interface';
import { AccessibilityGroup } from '@client/app/modules/accessibility/interfaces/accessibility-group.interface';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type AccessibilityGuidelinesRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class AccessibilityGuidelinesRepoService extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    async getAll (): Promise<MonTableCollection<AccessibilityGroup>> {
        let params: AccessibilityGuidelinesRepoRequestType = {};
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId()}/accessibility/groups`, params);
    }

    async getAllChecksForSubGroup (
        accGroupId: number,
        subGroupId: number,
        params: AccessibilityGuidelinesRepoRequestType ,
    ): Promise<MonTableCollection<AccessibilityCheckEntityType>> {
        params = this.setGroupId(params);
        return this.apiService.get( `domains/${this.getDomainId()}/accessibility/groups/${accGroupId}/subgroups/${subGroupId}/checks`, params);
    }
}
