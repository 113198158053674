import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { AngularSharedComponentsModule, FormBuilderValidationModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgClickOutsideDelayOutsideDirective, NgClickOutsideDirective } from 'ng-click-outside2';
import { PaOptionsService } from './features/pa-script-settings/pa-options.service';
import { PAScriptSettingsComponent } from './features/pa-script-settings/pa-script-settings.component';
import { ColorSimplePickerComponent } from './components/color-picker/color-simple-picker.component';
import { SampleImageComponent } from './components/sample-image/sample-image.component';
import { ConsentManagerComponent } from './features/consent-manager/consent-manager.component';
import { ConsentManagerOptionsService } from './features/consent-manager/consent-manager-options.service';

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
        AngularSharedComponentsModule,
        ColorPickerModule,
        FormsModule,
        NgClickOutsideDirective,
        NgClickOutsideDelayOutsideDirective,
        FormBuilderValidationModule,
    ],
    exports: [],
    declarations: [
        PAScriptSettingsComponent,
        ConsentManagerComponent,
        ColorSimplePickerComponent,
        SampleImageComponent,
    ],
    providers: [
        NgForm,
        PaOptionsService,
        ConsentManagerOptionsService,
    ],
})
export class DomainScriptModule {}
