import { Injectable } from '@angular/core';
import { PATH_WHITELIST } from '../../constants/helpers/path-whitelist';
import { PathNode } from '@uirouter/core';


@Injectable({
    providedIn: 'root',
})
export class RedirectWhitelistHelperService {
    constructor () { }

    isPathWhitelisted (paths: PathNode[]): boolean {
        return Boolean(this.getNameString(paths));
    }

    private getNameString (paths: PathNode[]): string | undefined {
        const isWhitelisted = paths.find( (path) => {
            return PATH_WHITELIST.some((whitelist) => {
                return path.state.name === whitelist;
            });
        });
        return isWhitelisted?.state.name;
    }
}
