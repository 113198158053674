(function () {
    'use strict';
    angular.module('modules.chat.intercom').component('monIntercom', {
        transclude: true,
        templateUrl: 'app/modules/chat/intercom/components/icon/intercom.html',
        controller: IntercomController,
        controllerAs: 'vm',
    });

    IntercomController.$inject = ['ng2MonEventsService', '$scope', 'ng2SessionService', 'intercomService', 'chatSystems'];

    function IntercomController (ng2MonEventsService, $scope, ng2SessionService, intercomService, chatSystems) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.intercomCount = 0;
            vm.showIntercom = false;
            vm.intercomId = chatSystems.intercom.intercomId;
            setupWatchers();
            if (ng2SessionService.me !== null) {
                setupIntercomUser();
            }
        }

        function setupWatchers () {
            ng2MonEventsService.addListener('intercomUnread', function (count) {
                vm.intercomCount = count;
                $scope.$apply();
            });

            ng2MonEventsService.addListener('intercomShow', function () {
                vm.showIntercom = true;
                vm.intercomCount = 0;
                $scope.$apply();
            });

            ng2MonEventsService.addListener('intercomHide', function () {
                vm.showIntercom = false;
                vm.intercomCount = 0;
                $scope.$apply();
            });
        }

        function setupIntercomUser () {
            var settings = {
                email: ng2SessionService.me.email,
                name: ng2SessionService.me.getName(),
                user_id: ng2SessionService.me.id,
                user_hash: ng2SessionService.me.intercom_user_hash,
            };
            intercomService.update(settings);
        }
    }
})();
