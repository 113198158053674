(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name linkService
     * @description Service to handle links
     */
    angular.module('services.entities').factory('linkService', linkService);

    linkService.$inject = ['ng2LinksRepoService'];
    /* @ngInject*/
    function linkService (ng2LinksRepoService) {
        return {
            update: update,
        };

        function update (link, params) {
            return ng2LinksRepoService.update(link, params);
        }
    }
})();
