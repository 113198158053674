(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardCompliance', {
        templateUrl: 'app/pages/accessibility/dashboard/compliance/compliance.html',
        controller: AccessibilityDashboardComplianceController,
        controllerAs: 'vm',
        bindings: {
            theme: '@',
            header: '@',
            type: '@',
            lastCrawlTxt: '@',
            color: '@',
            history: '<',
            checks: '<',
            progress: '<',
            selectedObs: '<',
        },
    });

    AccessibilityDashboardComplianceController.$inject = ['$filter', 'AccessibilityResultTypeService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function AccessibilityDashboardComplianceController ($filter, AccessibilityResultTypeService, ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;
        vm.hasError = hasError;

        function activate () {
            vm.passedChecks = 0;
            vm.selectedObs.subscribe(function (resultType) {
                var params = {
                    result_types: ['review'],
                    only_errors: true,
                    abbreviations: [vm.type],
                };
                vm.review = $filter('accessibilityChecksByResultTypes')(vm.checks, params);
                params.result_types = ['error'];
                vm.error = $filter('accessibilityChecksByResultTypes')(vm.checks, params);
                params.result_types = ['warning'];
                vm.warning = $filter('accessibilityChecksByResultTypes')(vm.checks, params);
                vm.checksCount = getChecksCount(resultType);
                vm.passedChecks = getPassedChecksCount(resultType);
                vm.onlySummaryIsAvailable = ng2ActiveFeatureService.isFeatureActive('accessibility_summary');
            });
        }

        function getPassedChecksCount (resultType) {
            var checksCount = getChecksCount(resultType);
            var checksWithIssuesCount = AccessibilityResultTypeService.getChecksCount(
                vm.checks,
                resultType,
                [vm.type],
                true,
            );
            return checksCount - checksWithIssuesCount;
        }

        function getChecksCount (resultType) {
            return AccessibilityResultTypeService.getChecksCount(vm.checks, resultType, [vm.type]);
        }

        function hasError () {
            if (!Array.isArray(vm.error) && !Array.isArray(vm.review) && !Array.isArray(vm.warning)) {
                return false;
            }
            return vm.review.length || vm.error.length || vm.warning.length;
        }
    }
})();
