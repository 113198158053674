(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name AccessibilityDialogsHelpCenterService
     * @description Service to manage large data of the accessibilityDialogsHelpCenter component
     */
    angular.module('modules.accessibility').factory('AccessibilityDialogsHelpCenterService', CheckTypeService);

    CheckTypeService.$inject = ['gettextCatalog', 'AccessibilityTagsService', 'AccessibilityImpactService'];
    /* @ngInject*/
    function CheckTypeService (gettextCatalog, AccessibilityTagsService, AccessibilityImpactService) {
        return {
            getGuidelineTranslation: getGuidelineTranslation,
            getImpactTranslation: getImpactTranslation,
            getImpactClass: getImpactClass,
            getDifficultyTranslation: getDifficultyTranslation,
            getTagTranslation: getTagTranslation,
            getTagClass: getTagClass,
        };

        /**
         * @memberOf AccessibilityDialogsHelpCenterService
         * @desc Get Accessibility Check Translation
         * @param {String} guideline - Accessibility Guideline ID
         * @return {String}
         */
        function getGuidelineTranslation (guideline) {
            var scope = {
                spanStart: '<span>',
                spanEnd: '</span>',
            };
            switch (guideline) {
                case 'WCAG2-A':
                    return gettextCatalog.getString('{{spanStart}}WCAG 2.0{{spanEnd}} (Level A)', scope);
                case 'WCAG2-AA':
                    return gettextCatalog.getString('{{spanStart}}WCAG 2.0{{spanEnd}} (Level AA)', scope);
                case 'WCAG2-AAA':
                    return gettextCatalog.getString('{{spanStart}}WCAG 2.0{{spanEnd}} (Level AAA)', scope);
                case 'WCAG21-A':
                    return gettextCatalog.getString('{{spanStart}}WCAG 2.1{{spanEnd}} (Level A)', scope);
                case 'WCAG21-AA':
                    return gettextCatalog.getString('{{spanStart}}WCAG 2.1{{spanEnd}} (Level AA)', scope);
                case 'WCAG21-AAA':
                    return gettextCatalog.getString('{{spanStart}}WCAG 2.1{{spanEnd}} (Level AAA)', scope);
            }
        }

        /**
         * @memberOf AccessibilityDialogsHelpCenterService
         * @desc Get Impact Translation
         * @param {String} impact - Impact level of Accessibility Check
         * @return {String}
         */
        function getImpactTranslation (impact) {
            return AccessibilityImpactService.getTranslation(impact);
        }

        /**
         * @memberOf AccessibilityDialogsHelpCenterService
         * @desc Get Impact Translation
         * @param {String} impact - Impact level of Accessibility Check
         * @return {String}
         */
        function getImpactClass (impact) {
            return AccessibilityImpactService.getClass(impact);
        }

        /**
         * @memberOf AccessibilityDialogsHelpCenterService
         * @desc Get Difficulty translation
         * @param {Number} level - Difficulty level
         * @return {String}
         */
        function getDifficultyTranslation (level) {
            return gettextCatalog.getString('Level {{level}}', {
                level: level,
            });
        }

        /**
         * @memberOf AccessibilityDialogsHelpCenterService
         * @desc Get Tag Translation
         * @param {String} tag - Tag name
         * @return {String}
         */
        function getTagTranslation (tag) {
            return AccessibilityTagsService.getTranslation(tag);
        }

        /**
         * @memberOf AccessibilityDialogsHelpCenterService
         * @desc Get Tag Translation
         * @param {String} tag - Tag name
         * @return {String}
         */
        function getTagClass (tag) {
            return AccessibilityTagsService.getClass(tag);
        }
    }
})();
