import colors from '@monsido/colors/dist/es6/colors';
import { hexToRgba } from '../../helpers/hex-to-rgba';

import { MAX_PAGE_SIZE_FOR_COMPARISON } from '@monsido/modules/statistics/statistics.constant';
import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsBounceRatesPages', {
        templateUrl: 'app/pages/statistics/pages/bounce-rates-pages/bounce-rates-pages.html',
        controller: StatisticsBounceRatesPagesController,
        controllerAs: 'vm',
    });

    StatisticsBounceRatesPagesController.$inject = [
        'ng2StatsRepoService',
        '$controller',
        '$stateParams',
        'ParamService',
        '$filter',
        'gettextCatalog',
        'Lodash',
        'ng2SessionService',
        'dateRangeService',
        'ng2MonModalService',
        'ng2StatisticsDateComparisonService',
    ];

    /* @ngInject */
    function StatisticsBounceRatesPagesController (
        ng2StatsRepoService,
        $controller,
        $stateParams,
        ParamService,
        $filter,
        gettextCatalog,
        Lodash,
        ng2SessionService,
        dateRangeService,
        ng2MonModalService,
        ng2StatisticsDateComparisonService,
    ) {
        var vm = this;
        const noPagesMsg = gettextCatalog.getString('No pages found');
        const noSearchMsg =
            gettextCatalog.getString('No pages were found. Try searching for the page URL instead of the page title.');
        const dateFormat = 'YYYY-MM-DD';
        vm.updateDateRange = updateDateRange;
        vm.average = average;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.validateDate = ng2StatisticsDateComparisonService.validateDate;
        vm.onComparisonRangeSelected = onComparisonRangeSelected;
        vm.updateCompareDate = updateCompareDate;
        vm.onPageChangeLocal = onPageChangeLocal;
        vm.comparisonEnabled = false;
        vm.compareDate = { startDate: null, endDate: null };
        vm.noPagesResultMsg = noPagesMsg;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.domain = ng2SessionService.domain;
            vm.totalVisits = 0;
            vm.summary = {};
            vm.chartSeries = [gettextCatalog.getString('Bounce rate')];
            vm.chartLabels = [];
            vm.chartData = [];
            vm.chartOverride = [];
            vm.chartColors = [];
            vm.search = '';
            vm.onSearch = onSearch;
            vm.chartOptions = {
                legend: {
                    display: false,
                    position: 'bottom',
                    fullWidth: true,
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: function (value) {
                                    return Lodash.truncate(value, 40);
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        title: function (tooltipItem) {
                            return vm.chartLabels[tooltipItem[0].index];
                        },
                        label: function (tooltipItem, data) {
                            return (
                                ' ' +
                                (vm.comparisonEnabled
                                    ? data.datasets[tooltipItem.datasetIndex].label
                                    : gettextCatalog.getString('Bounce rate')) +
                                ': ' +
                                $filter('numeraljs', '0,0')(
                                    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / 100,
                                    '0.[00]%',
                                )
                            );
                        },
                    },
                },
            };
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'entry_exit_bounce_rates',
                    },
                },
            ];
            vm.loading = false;
            vm.date = dateRangeService.setRangeDates();
        }

        function getPage () {
            var params = {
                limit: vm.pageSize,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format(dateFormat),
                to: vm.date.endDate?.format(dateFormat),
                sort_by: 'bounce_rate',
                sort_dir: 'desc',
                search: vm.search,
            };

            vm.loading = true;
            vm.promise = Promise.all([
                ng2StatsRepoService.getEntryPages(params, contextTokens.NO_PARSE_SEARCH)
                    .then((data) => {
                        vm.pages = data;
                        vm.pages.currentPage = vm.page;
                        vm.totalVisits = data.reduce((total, page) => total + parseInt(page.visits), 0);
                        vm.chartLabels = vm.pages.map((page) => page.title ? page.title : page.label);
                        vm.chartData = [
                            vm.pages.map((page) => parseInt(page.bounce_rate, 10)),
                        ];
                        vm.chartOverride = [
                            {
                                borderWidth: 1,
                                borderColor: [],
                                backgroundColor: [],
                            },
                        ];
                        for (let i = 0; i < vm.pages.length; i++) {
                            vm.chartOverride[0].borderColor.push(colors['main-1']);
                            vm.chartOverride[0].backgroundColor.push(colors['main-1']);
                        }
                        setNoResultMsg(vm.pages, vm.search);
                    }, angular.noop),
                getSummary(),
            ]).finally(() => vm.loading = false);
        }

        async function onComparisonRangeSelected (currentStartDate, currentEndDate, compareStartDate, compareEndDate) {
            vm.loading = true;
            const currentDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: currentStartDate?.format(dateFormat),
                to: currentEndDate?.format(dateFormat),
                sort_by: 'bounce_rate',
                sort_dir: 'desc',
                search: vm.search,
            };
            const compareDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: compareStartDate?.format(dateFormat),
                to: compareEndDate?.format(dateFormat),
                sort_by: 'bounce_rate',
                sort_dir: 'desc',
                search: vm.search,
            };

            const [currentPages, comparePages] = await Promise.all([
                ng2StatsRepoService.getEntryPages(currentDataRequestParams, contextTokens.NO_PARSE_SEARCH),
                ng2StatsRepoService.getEntryPages(compareDataRequestParams, contextTokens.NO_PARSE_SEARCH),

                getSummary(),
            ]);

            const currentPagesMap = new Map();

            for (let i = 0; i < currentPages.length; i++) {
                const page = currentPages[i];
                const pageCompare = page.compare ? page.compare : null;
                const computedPage = {
                    ...page,
                    compare: {
                        ...(pageCompare ? {
                            visits: pageCompare.visits,
                            visitors: pageCompare.visitors,
                            bounces: pageCompare.bounces,
                            bounce_rate: pageCompare.bounce_rate,
                            entry_visits: pageCompare.entry_visits,
                            avg_time: pageCompare.avg_time,
                        } : {
                            visits: 0,
                            visitors: 0,
                            bounces: 0,
                            bounce_rate: 0,
                            entry_visits: 0,
                            avg_time: 0,
                        }),
                    },
                };
                currentPagesMap.set(page.label, computedPage);
            }

            for (let i = 0; i < comparePages.length; i++) {
                const comparePage = comparePages[i];
                const existedPageInMap = currentPagesMap.get(comparePage.label);

                if (existedPageInMap) {
                    existedPageInMap.compare = comparePage;
                }
            }

            const computedPages = Array.from(currentPagesMap.values());
            vm.pages = computedPages;
            vm.chartSeries = [
                gettextCatalog.getString('Bounce rate'),
                gettextCatalog.getString('Bounce rate (comp.)'),
            ];
            vm.chartData = [[], []];
            vm.chartOverride = [
                {
                    borderWidth: 1,
                    borderColor: [],
                    backgroundColor: [],
                },
                {
                    borderWidth: 1,
                    borderColor: [],
                    backgroundColor: [],
                },
            ];
            vm.chartLabels = [];
            for (let i = 0; i < computedPages.length; i++) {
                const page = computedPages[i];
                const bounceRate = page.visitors === 0 ? 0 : Math.round((page.bounces * 100) / page.visitors);
                const bounceRateCompare = page.compare.visitors === 0
                    ? 0
                    : Math.round((page.compare.bounces * 100) / page.compare.visitors);
                vm.chartData[0].push(bounceRate);
                vm.chartData[1].push(bounceRateCompare);

                vm.chartLabels.push(page.title || page.label);

                vm.chartOverride[0].borderColor.push(colors['main-1']);
                vm.chartOverride[0].backgroundColor.push(colors['main-1']);

                vm.chartOverride[1].borderColor.push(hexToRgba(colors['main-1'], 0.25));
                vm.chartOverride[1].backgroundColor.push(hexToRgba(colors['main-1'], 0.25));
            }

            setNoResultMsg(vm.pages, vm.search);
            vm.loading = false;
        }

        function setNoResultMsg (list, search) {
            if (!list.length && !search) {
                vm.noPagesResultMsg = noPagesMsg;
            }

            if (!list.length && search) {
                vm.noPagesResultMsg = noSearchMsg;
            }
        }

        function onSearch (search) {
            vm.search = search;
            vm.page = 1;
            if (vm.comparisonEnabled && vm.date && vm.compareDate) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function updateDateRange () {
            if (vm.date.startDate && vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                    search: vm.search,
                });
            }
            setExportCategoryRef();
            getPage();
        }

        function average (array, prop) {
            if (!Array.isArray(array) || array.length === 0) {
                return 0;
            }
            const total = array.reduce((sum, item) => sum + parseInt(item[prop]), 0);
            return total / array.length;
        }

        function getSummary () {
            var params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format(dateFormat),
                to: vm.date.endDate?.format(dateFormat),
                type: 'total',
            };
            return ng2StatsRepoService.getSummary(params)
                .then(function (data) {
                    vm.summary = data;
                }, angular.noop)
                .finally(() => {
                    vm.loading = false;
                });
        }

        function updateCompareDate (e) {
            const isFutureDates = ng2StatisticsDateComparisonService.isFutureDate(e.startDate) ||
                ng2StatisticsDateComparisonService.isFutureDate(e.endDate);

            vm.comparisonEnabled = e.startDate && e.endDate && !isFutureDates;

            if (!vm.comparisonEnabled && vm.chartData?.length > 1) {
                vm.chartData.pop();
            }

            if (isFutureDates) {
                return ng2MonModalService.alert(
                    gettextCatalog.getString(
                        'The start and end date cannot be in the future, please enter the compare dates again',
                    ),
                );
            }

            if (vm.comparisonEnabled) {
                vm.compareDate.startDate = e.startDate;
                vm.compareDate.endDate = e.endDate;
                vm.pageSize = 10;
                vm.page = 1;
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            }
        }

        function onPageChangeLocal (page) {
            vm.page = page;
            if (vm.comparisonEnabled && vm.date && vm.compareDate) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function setExportCategoryRef () {
            vm.exports[0].data.category_ref = vm.date.startDate?.format(dateFormat) + ',' + vm.date.endDate?.format(dateFormat);
        }
    }
})();
