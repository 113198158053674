(function () {
    'use strict';

    angular.module('modules.globalPolicy').component('policyExchangeCenterViewsGrid', {
        templateUrl: 'app/modules/global-policy/exchange-center/views/grid/grid.html',
        controller: PolicyExchangeCenterViewsGridController,
        controllerAs: 'vm',
        bindings: {
            templates: '<',
            chooseTemplate: '&',
        },
    });

    PolicyExchangeCenterViewsGridController.$inject = ['ng2GlobalPolicyExchangeCenterTemplateService'];

    /* @ngInject */
    function PolicyExchangeCenterViewsGridController (ng2GlobalPolicyExchangeCenterTemplateService) {
        var vm = this;
        vm.selectTemplate = selectTemplate;
        vm.getSubjectIcon = ng2GlobalPolicyExchangeCenterTemplateService.getSubjectIcon;
        vm.getVerticalIcon = ng2GlobalPolicyExchangeCenterTemplateService.getVerticalIcon;
        vm.getVerticalDescription = ng2GlobalPolicyExchangeCenterTemplateService.getVerticalDescription;
        vm.getVerticalBackground = ng2GlobalPolicyExchangeCenterTemplateService.getVerticalBackground;
        vm.getVerticalColor = ng2GlobalPolicyExchangeCenterTemplateService.getVerticalColor;
        vm.getCountryIcon = ng2GlobalPolicyExchangeCenterTemplateService.getCountryIcon;
        vm.getCountryDescription = ng2GlobalPolicyExchangeCenterTemplateService.getCountryDescription;

        function selectTemplate (template) {
            vm.chooseTemplate({ template: angular.copy(template) });
        }
    }
})();
