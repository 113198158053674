(function () {
    'use strict';

    angular.module('base.product-updates').component('productUpdate', {
        templateUrl: 'app/pages/base/product-updates/product-update/product-update.html',
        controller: ProductUpdatesProductUpdateController,
        controllerAs: 'vm',
    });

    ProductUpdatesProductUpdateController.$inject = [
        '$stateParams',
        'SupportProductUpdateRepo',
        '$state',
        '$sce',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject */
    function ProductUpdatesProductUpdateController (
        $stateParams,
        SupportProductUpdateRepo,
        $state,
        $sce,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.goBack = goBack;

        function activate () {
            vm.productUpdate = {};
            vm.productUpdateId = $stateParams.productUpdateId;
            vm.trustedContent = '';
            getProductUpdate();
        }

        function getProductUpdate () {
            SupportProductUpdateRepo.get(vm.productUpdateId).then(function (productUpdate) {
                vm.productUpdate = productUpdate;
                vm.trustedContent = $sce.trustAsHtml(productUpdate.content);
            }, angular.noop);
        }

        function goBack () {
            var previousState = $stateParams.state;
            if (typeof previousState === 'string') {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go(previousState).then(angular.noop, function () {
                        return ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.product-updates.index'),
                        );
                    }),
                );
            } else {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.product-updates.index'),
                );
            }
        }
    }
})();
