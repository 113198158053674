import moment from 'moment';
import { StatisticsCity, StatisticsLocation, StatisticsRegion, StatisticsVisitor } from '@client/app/modules/statistics/interfaces/statistics-vistor.interface';
import { StatisticsKeyword } from '@client/app/modules/statistics/interfaces/statistics-keyword.interface';
import { StatisticsSummary } from '@client/app/modules/statistics/interfaces/statistics-summary.interface';
import { StatisticsSiteSearchKeyword } from '@client/app/modules/statistics/interfaces/statistics-site-search-keyword.interface';
import { PageModel } from '@client/app/models/page-model-factory.service';

export type DemoStatsRepoDataType = {
    resolutions: StatisticsVisitor[],
    os: StatisticsVisitor[],
    organizations: StatisticsVisitor[],
    locations: StatisticsLocation[],
    regions: StatisticsRegion[],
    cities: StatisticsCity[],
    languages: StatisticsVisitor[],
    browsers: StatisticsVisitor[],
    searchEngines: StatisticsVisitor[],
    keyWords: StatisticsKeyword[],
    visitors: {
        referring: StatisticsVisitor[],
        direct: StatisticsVisitor[],
        social: StatisticsVisitor[],
        organic: StatisticsVisitor[],
        paid: StatisticsVisitor[]
    },
    devices: StatisticsVisitor[],
    summary: StatisticsSummary,
    summaryPerDate: StatisticsSummary[],
    socials: StatisticsVisitor[],
    referrers: StatisticsVisitor[],
    siteSearchKeywords: StatisticsSiteSearchKeyword[]
}

const hostNameRegex = /^https?:\/\/(?:w{3}\.)?([a-z0-9\.-]+)\.(?:[a-z\.]{2,10})(?:[a-z\.-])/;
const maxBouncesPerPage = Math.floor(Math.random() * 100) + 10;

export const generateDemoStatsRepoData = (label: string, countryCode: string): DemoStatsRepoDataType => {
    const now = moment().subtract(1, 'days');
    return {
        resolutions: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '60',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '375x667',
                bounce_rate: '40',
                bounces: null,
                actions: '234',
                avg_time: '11021',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '29',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '320x568',
                bounce_rate: '17',
                bounces: null,
                actions: '82',
                avg_time: '3576',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '26',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '768x1024',
                bounce_rate: '12',
                bounces: null,
                actions: '174',
                avg_time: '4673',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '22',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '360x640',
                bounce_rate: '13',
                bounces: null,
                actions: '63',
                avg_time: '2922',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '21',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1920x1080',
                bounce_rate: '8',
                bounces: null,
                actions: '187',
                avg_time: '6412',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '21',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1920x1200',
                bounce_rate: '8',
                bounces: null,
                actions: '98',
                avg_time: '6171',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '17',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1440x900',
                bounce_rate: '6',
                bounces: null,
                actions: '142',
                avg_time: '11748',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '13',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1366x768',
                bounce_rate: '6',
                bounces: null,
                actions: '68',
                avg_time: '2842',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '12',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1280x800',
                bounce_rate: '3',
                bounces: null,
                actions: '84',
                avg_time: '4025',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '11',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '375x812',
                bounce_rate: '3',
                bounces: null,
                actions: '80',
                avg_time: '2692',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '10',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1536x864',
                bounce_rate: '3',
                bounces: null,
                actions: '82',
                avg_time: '4592',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '9',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '414x736',
                bounce_rate: '5',
                bounces: null,
                actions: '52',
                avg_time: '2505',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '9',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1680x1050',
                bounce_rate: '3',
                bounces: null,
                actions: '53',
                avg_time: '1324',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '8',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '800x600',
                bounce_rate: '5',
                bounces: null,
                actions: '11',
                avg_time: '3677',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '7',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '360x740',
                bounce_rate: '5',
                bounces: null,
                actions: '17',
                avg_time: '263',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '7',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1600x900',
                bounce_rate: '3',
                bounces: null,
                actions: '52',
                avg_time: '1718',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '5',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1280x720',
                bounce_rate: '3',
                bounces: null,
                actions: '40',
                avg_time: '1149',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '5',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '2560x1440',
                bounce_rate: '3',
                bounces: null,
                actions: '14',
                avg_time: '789',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '4',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '360x598',
                bounce_rate: '1',
                bounces: null,
                actions: '14',
                avg_time: '241',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '4',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1024x1366',
                bounce_rate: '4',
                bounces: null,
                actions: '4',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '3',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '412x732',
                bounce_rate: '2',
                bounces: null,
                actions: '4',
                avg_time: '15',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '360x512',
                bounce_rate: '0',
                bounces: null,
                actions: '6',
                avg_time: '94',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1536x960',
                bounce_rate: '1',
                bounces: null,
                actions: '10',
                avg_time: '608',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '2000x2000',
                bounce_rate: '2',
                bounces: null,
                actions: '2',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '2021x1137',
                bounce_rate: '1',
                bounces: null,
                actions: '9',
                avg_time: '125',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '320x569',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '360x720',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '360x760',
                bounce_rate: '0',
                bounces: null,
                actions: '5',
                avg_time: '201',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '412x869',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '424x753',
                bounce_rate: '0',
                bounces: null,
                actions: '2',
                avg_time: '49',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '601x962',
                bounce_rate: '0',
                bounces: null,
                actions: '25',
                avg_time: '599',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '834x1112',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1024x768',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1152x720',
                bounce_rate: '0',
                bounces: null,
                actions: '6',
                avg_time: '114',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1280x960',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1344x840',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1440x960',
                bounce_rate: '0',
                bounces: null,
                actions: '14',
                avg_time: '655',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1455x818',
                bounce_rate: '0',
                bounces: null,
                actions: '4',
                avg_time: '38',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1477x923',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1504x1003',
                bounce_rate: '0',
                bounces: null,
                actions: '3',
                avg_time: '36',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1745x982',
                bounce_rate: '0',
                bounces: null,
                actions: '3',
                avg_time: '126',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1745x1091',
                bounce_rate: '0',
                bounces: null,
                actions: '2',
                avg_time: '277',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '1829x1029',
                bounce_rate: '0',
                bounces: null,
                actions: '58',
                avg_time: '1822',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: '2259x1271',
                bounce_rate: '0',
                bounces: null,
                actions: '7',
                avg_time: '124',
                pageviews: null,
            },
        ],
        os: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '140',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'iOS',
                bounce_rate: '82',
                bounces: null,
                actions: '627',
                avg_time: '24467',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '103',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Windows',
                bounce_rate: '43',
                bounces: null,
                actions: '659',
                avg_time: '30419',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '44',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Android',
                bounce_rate: '24',
                bounces: null,
                actions: '139',
                avg_time: '4384',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '40',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Mac',
                bounce_rate: '13',
                bounces: null,
                actions: '291',
                avg_time: '17953',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '3',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'GNU/Linux',
                bounce_rate: '3',
                bounces: null,
                actions: '3',
                avg_time: '0',
                pageviews: null,
            },
        ],
        organizations: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '129',
                url: 'http://www.tdc.net/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Tdc',
                bounce_rate: '61',
                bounces: null,
                actions: '727',
                avg_time: '37510',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '50',
                url: '{}',
                visitors: null,
                uniq_visitors: '0',
                label: 'Unknown',
                bounce_rate: '28',
                bounces: null,
                actions: '241',
                avg_time: '10099',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '29',
                url: 'http://www.3.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: '3',
                bounce_rate: '12',
                bounces: null,
                actions: '211',
                avg_time: '9570',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '18',
                url: 'http://www.telenor.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Telenor',
                bounce_rate: '13',
                bounces: null,
                actions: '37',
                avg_time: '1243',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '18',
                url: 'http://www.telia.com/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Telia',
                bounce_rate: '6',
                bounces: null,
                actions: '80',
                avg_time: '2241',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '13',
                url: 'http://www.fibianet.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Fibianet',
                bounce_rate: '5',
                bounces: null,
                actions: '79',
                avg_time: '1967',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '11',
                url: 'http://www.hiper.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Hiper',
                bounce_rate: '7',
                bounces: null,
                actions: '22',
                avg_time: '670',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '9',
                url: 'http://www.fullrate.ninja/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Fullrate',
                bounce_rate: '4',
                bounces: null,
                actions: '79',
                avg_time: '3139',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '7',
                url: 'http://www.telekom.hu/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Telekom',
                bounce_rate: '4',
                bounces: null,
                actions: '10',
                avg_time: '3677',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '5',
                url: 'http://www.parknet.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Parknet',
                bounce_rate: '3',
                bounces: null,
                actions: '10',
                avg_time: '463',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '5',
                url: 'http://www.stofanet.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Stofanet',
                bounce_rate: '3',
                bounces: null,
                actions: '9',
                avg_time: '96',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '3',
                url: 'http://www.dtu.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Dtu',
                bounce_rate: '2',
                bounces: null,
                actions: '5',
                avg_time: '16',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '3',
                url: 'http://www.fiberby.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Fiberby',
                bounce_rate: '1',
                bounces: null,
                actions: '24',
                avg_time: '1002',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '3',
                url: 'http://www.powerhosting.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Powerhosting',
                bounce_rate: '2',
                bounces: null,
                actions: '4',
                avg_time: '46',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: 'http://www.googleusercontent.com/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Googleusercontent',
                bounce_rate: '0',
                bounces: null,
                actions: '6',
                avg_time: '94',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: 'http://www.sefiber.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Sefiber',
                bounce_rate: '1',
                bounces: null,
                actions: '4',
                avg_time: '37',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: 'http://www.sundbynet.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Sundbynet',
                bounce_rate: '0',
                bounces: null,
                actions: '94',
                avg_time: '2350',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.arrownet.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Arrownet',
                bounce_rate: '0',
                bounces: null,
                actions: '5',
                avg_time: '103',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.bnaa.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Bnaa',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.cdi.no/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Cdi',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.comflex.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Comflex',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.comhem.se/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Comhem',
                bounce_rate: '0',
                bounces: null,
                actions: '13',
                avg_time: '746',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.comxnet.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Comxnet',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.danskkabeltv.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Danskkabeltv',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.dseurope.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Dseurope',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.egservice.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Egservice',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.kti.rs/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Kti',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.lyse.net/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Lyse',
                bounce_rate: '0',
                bounces: null,
                actions: '3',
                avg_time: '55',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.m247.com/',
                visitors: null,
                uniq_visitors: '0',
                label: 'M247',
                bounce_rate: '0',
                bounces: null,
                actions: '5',
                avg_time: '270',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.netgroup.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Netgroup',
                bounce_rate: '0',
                bounces: null,
                actions: '9',
                avg_time: '743',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.nextconnex.net/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Nextconnex',
                bounce_rate: '0',
                bounces: null,
                actions: '23',
                avg_time: '1010',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.regionsjaelland.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Regionsjaelland',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.scansafe.net/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Scansafe',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.sdu.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Sdu',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.studentergaarden.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Studentergaarden',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.tele2.se/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Tele2',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.xerox.com/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Xerox',
                bounce_rate: '0',
                bounces: null,
                actions: '4',
                avg_time: '47',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://www.zentura.dk/',
                visitors: null,
                uniq_visitors: '0',
                label: 'Zentura',
                bounce_rate: '0',
                bounces: null,
                actions: '2',
                avg_time: '29',
                pageviews: null,
            },
        ],
        locations: [
            {
                visits: '310',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label,
                bounce_rate: '154',
                bounces: null,
                actions: '1651',
                avg_time: '71512',
                pageviews: null,
                country_code: countryCode,
            },
            {
                visits: '6',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Hungary',
                bounce_rate: '3',
                bounces: null,
                actions: '9',
                avg_time: '3677',
                pageviews: null,
                country_code: 'hu',
            },
            {
                visits: '4',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Sweden',
                bounce_rate: '2',
                bounces: null,
                actions: '20',
                avg_time: '883',
                pageviews: null,
                country_code: 'se',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'United Kingdom',
                bounce_rate: '0',
                bounces: null,
                actions: '27',
                avg_time: '1057',
                pageviews: null,
                country_code: 'gb',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Austria',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'at',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Finland',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'fi',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Norway',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'no',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Serbia',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'rs',
            },
        ],
        regions: [
            {
                visits: '203',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Hovedstaden',
                bounce_rate: '96',
                bounces: null,
                actions: '1220',
                avg_time: '51043',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
            },
            {
                visits: '59',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Unknown',
                bounce_rate: '24',
                bounces: null,
                actions: '356',
                avg_time: '15294',
                pageviews: null,
                country_code: 'xx',
                region_code: 'xx',
                country_name: 'Unknown',
            },
            {
                visits: '42',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Midtjylland',
                bounce_rate: '21',
                bounces: null,
                actions: '147',
                avg_time: '6115',
                pageviews: null,
                country_code: 'dk',
                region_code: '18',
                country_name: 'Denmark',
            },
            {
                visits: '27',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Sjelland',
                bounce_rate: '11',
                bounces: null,
                actions: '108',
                avg_time: '7974',
                pageviews: null,
                country_code: 'dk',
                region_code: '20',
                country_name: 'Denmark',
            },
            {
                visits: '26',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Syddanmark',
                bounce_rate: '18',
                bounces: null,
                actions: '48',
                avg_time: '939',
                pageviews: null,
                country_code: 'dk',
                region_code: '21',
                country_name: 'Denmark',
            },
            {
                visits: '4',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Nordjylland',
                bounce_rate: '3',
                bounces: null,
                actions: '8',
                avg_time: '137',
                pageviews: null,
                country_code: 'dk',
                region_code: '19',
                country_name: 'Denmark',
            },
        ],
        cities: [
            {
                visits: '114',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Copenhagen',
                bounce_rate: '63',
                bounces: null,
                actions: '599',
                avg_time: '22230',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '20',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Brønshøj',
                bounce_rate: '8',
                bounces: null,
                actions: '189',
                avg_time: '9225',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '18',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Herlev',
                bounce_rate: '3',
                bounces: null,
                actions: '98',
                avg_time: '7840',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '8',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Frederiksberg',
                bounce_rate: '3',
                bounces: null,
                actions: '46',
                avg_time: '831',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '6',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Hellerup',
                bounce_rate: '3',
                bounces: null,
                actions: '15',
                avg_time: '406',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '5',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Charlottenlund',
                bounce_rate: '2',
                bounces: null,
                actions: '46',
                avg_time: '1685',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '4',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Valby',
                bounce_rate: '2',
                bounces: null,
                actions: '24',
                avg_time: '850',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '3',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Vallensbæk',
                bounce_rate: '2',
                bounces: null,
                actions: '17',
                avg_time: '325',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Albertslund',
                bounce_rate: '1',
                bounces: null,
                actions: '9',
                avg_time: '570',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Ballerup',
                bounce_rate: '0',
                bounces: null,
                actions: '12',
                avg_time: '784',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Gentofte',
                bounce_rate: '1',
                bounces: null,
                actions: '19',
                avg_time: '778',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Helsingør',
                bounce_rate: '1',
                bounces: null,
                actions: '15',
                avg_time: '1057',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Hillerød',
                bounce_rate: '1',
                bounces: null,
                actions: '37',
                avg_time: '1873',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Søborg',
                bounce_rate: '0',
                bounces: null,
                actions: '19',
                avg_time: '960',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Ølstykke',
                bounce_rate: '1',
                bounces: null,
                actions: '10',
                avg_time: '217',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Allinge',
                bounce_rate: '0',
                bounces: null,
                actions: '5',
                avg_time: '75',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Birkerød',
                bounce_rate: '0',
                bounces: null,
                actions: '4',
                avg_time: '38',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Dragør',
                bounce_rate: '0',
                bounces: null,
                actions: '3',
                avg_time: '67',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Frederikssund',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Græsted',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Ishøj',
                bounce_rate: '0',
                bounces: null,
                actions: '7',
                avg_time: '247',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Rødovre',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Taastrup',
                bounce_rate: '0',
                bounces: null,
                actions: '7',
                avg_time: '96',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Virum',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Værløse',
                bounce_rate: '0',
                bounces: null,
                actions: '34',
                avg_time: '889',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
            {
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Østermarie',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
                country_code: 'dk',
                region_code: '17',
                country_name: 'Denmark',
                city_code: '0',
                region_name: 'Hovedstaden',
            },
        ],
        languages: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '286',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Danish - Denmark (da-dk)',
                bounce_rate: '143',
                bounces: null,
                actions: '1398',
                avg_time: '61591',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '26',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'English (en)',
                bounce_rate: '11',
                bounces: null,
                actions: '257',
                avg_time: '10169',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '8',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Unknown (xx)',
                bounce_rate: '5',
                bounces: null,
                actions: '11',
                avg_time: '3677',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '4',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'English - United States (en-us)',
                bounce_rate: '2',
                bounces: null,
                actions: '33',
                avg_time: '960',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Norwegian Bokmål - Norway (nb-no)',
                bounce_rate: '1',
                bounces: null,
                actions: '4',
                avg_time: '80',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Swedish - Sweden (sv-se)',
                bounce_rate: '1',
                bounces: null,
                actions: '14',
                avg_time: '746',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'German - Austria (de-at)',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: '0',
                visitors: null,
                uniq_visitors: '0',
                label: 'Russian (ru)',
                bounce_rate: '1',
                bounces: null,
                actions: '1',
                avg_time: '0',
                pageviews: null,
            },
        ],
        browsers: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '132',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Mobile Safari',
                bounce_rate: '79',
                bounces: null,
                actions: '598',
                avg_time: '23259',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '78',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Chrome',
                bounce_rate: '26',
                bounces: null,
                actions: '629',
                avg_time: '28483',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '32',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Chrome Mobile',
                bounce_rate: '20',
                bounces: null,
                actions: '74',
                avg_time: '1554',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '29',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Safari',
                bounce_rate: '12',
                bounces: null,
                actions: '161',
                avg_time: '12479',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '21',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Internet Explorer',
                bounce_rate: '12',
                bounces: null,
                actions: '61',
                avg_time: '3200',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '10',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Firefox',
                bounce_rate: '4',
                bounces: null,
                actions: '39',
                avg_time: '1357',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '8',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Chrome Mobile iOS',
                bounce_rate: '3',
                bounces: null,
                actions: '29',
                avg_time: '1208',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '8',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Microsoft Edge',
                bounce_rate: '4',
                bounces: null,
                actions: '87',
                avg_time: '3452',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '7',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Samsung Browser',
                bounce_rate: '3',
                bounces: null,
                actions: '26',
                avg_time: '1990',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '4',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Firefox Mobile',
                bounce_rate: '1',
                bounces: null,
                actions: '14',
                avg_time: '241',
                pageviews: null,
            },
        ],
        searchEngines: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '191',
                url: 'http://google.com',
                visitors: null,
                uniq_visitors: '185',
                label: 'Google',
                bounce_rate: null,
                bounces: '106',
                actions: '949',
                avg_time: null,
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://bing.com',
                visitors: null,
                uniq_visitors: '1',
                label: 'Bing',
                bounce_rate: null,
                bounces: '1',
                actions: '1',
                avg_time: null,
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'http://search.yahoo.com',
                visitors: null,
                uniq_visitors: '1',
                label: 'Yahoo!',
                bounce_rate: null,
                bounces: '1',
                actions: '1',
                avg_time: null,
                pageviews: null,
            },
        ],
        keyWords: [{
            word: 'Keyword not defined',
            actions: '2',
            visits: '3',
            bounce_rate: '4%',
        }],
        visitors: {
            referring: [
                {
                    date: moment(now)
                        .subtract(6, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '1',
                    url: null,
                    visitors: null,
                    uniq_visitors: '1',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '7',
                    avg_time: '247',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(5, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(4, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(3, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '1',
                    url: null,
                    visitors: null,
                    uniq_visitors: '1',
                    label: null,
                    bounce_rate: '1',
                    bounces: null,
                    actions: '1',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(2, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '2',
                    url: null,
                    visitors: null,
                    uniq_visitors: '2',
                    label: null,
                    bounce_rate: '2',
                    bounces: null,
                    actions: '2',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(1, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '3',
                    url: null,
                    visitors: null,
                    uniq_visitors: '3',
                    label: null,
                    bounce_rate: '1',
                    bounces: null,
                    actions: '9',
                    avg_time: '694',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
            ],
            direct: [
                {
                    date: moment(now)
                        .subtract(6, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '18',
                    url: null,
                    visitors: null,
                    uniq_visitors: '15',
                    label: null,
                    bounce_rate: '7',
                    bounces: null,
                    actions: '58',
                    avg_time: '5307',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(5, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '15',
                    url: null,
                    visitors: null,
                    uniq_visitors: '12',
                    label: null,
                    bounce_rate: '4',
                    bounces: null,
                    actions: '111',
                    avg_time: '3046',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(4, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '21',
                    url: null,
                    visitors: null,
                    uniq_visitors: '20',
                    label: null,
                    bounce_rate: '11',
                    bounces: null,
                    actions: '136',
                    avg_time: '7213',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(3, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '15',
                    url: null,
                    visitors: null,
                    uniq_visitors: '14',
                    label: null,
                    bounce_rate: '4',
                    bounces: null,
                    actions: '135',
                    avg_time: '8639',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(2, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '35',
                    url: null,
                    visitors: null,
                    uniq_visitors: '29',
                    label: null,
                    bounce_rate: '15',
                    bounces: null,
                    actions: '193',
                    avg_time: '8832',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(1, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '26',
                    url: null,
                    visitors: null,
                    uniq_visitors: '22',
                    label: null,
                    bounce_rate: '12',
                    bounces: null,
                    actions: '116',
                    avg_time: '7585',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
            ],
            social: [
                {
                    date: moment(now)
                        .subtract(6, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: null,
                    bounces: null,
                    actions: '0',
                    avg_time: null,
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(5, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: null,
                    bounces: null,
                    actions: '0',
                    avg_time: null,
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(4, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: null,
                    bounces: null,
                    actions: '0',
                    avg_time: null,
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(3, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '1',
                    url: null,
                    visitors: null,
                    uniq_visitors: '1',
                    label: null,
                    bounce_rate: null,
                    bounces: null,
                    actions: '1',
                    avg_time: null,
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(2, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '1',
                    url: null,
                    visitors: null,
                    uniq_visitors: '1',
                    label: null,
                    bounce_rate: null,
                    bounces: null,
                    actions: '1',
                    avg_time: null,
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(1, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: null,
                    bounces: null,
                    actions: '0',
                    avg_time: null,
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: null,
                    bounces: null,
                    actions: '0',
                    avg_time: null,
                    pageviews: null,
                },
            ],
            organic: [
                {
                    date: moment(now)
                        .subtract(6, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '35',
                    url: null,
                    visitors: null,
                    uniq_visitors: '33',
                    label: null,
                    bounce_rate: '23',
                    bounces: null,
                    actions: '117',
                    avg_time: '5538',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(5, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '23',
                    url: null,
                    visitors: null,
                    uniq_visitors: '23',
                    label: null,
                    bounce_rate: '14',
                    bounces: null,
                    actions: '138',
                    avg_time: '4374',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(4, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '36',
                    url: null,
                    visitors: null,
                    uniq_visitors: '33',
                    label: null,
                    bounce_rate: '18',
                    bounces: null,
                    actions: '178',
                    avg_time: '6710',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(3, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '31',
                    url: null,
                    visitors: null,
                    uniq_visitors: '31',
                    label: null,
                    bounce_rate: '19',
                    bounces: null,
                    actions: '129',
                    avg_time: '5981',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(2, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '34',
                    url: null,
                    visitors: null,
                    uniq_visitors: '34',
                    label: null,
                    bounce_rate: '15',
                    bounces: null,
                    actions: '232',
                    avg_time: '8343',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(1, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '34',
                    url: null,
                    visitors: null,
                    uniq_visitors: '33',
                    label: null,
                    bounce_rate: '19',
                    bounces: null,
                    actions: '157',
                    avg_time: '4714',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
            ],
            paid: [
                {
                    date: moment(now)
                        .subtract(6, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(5, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(4, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(3, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(2, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .subtract(1, 'days')
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
                {
                    date: moment(now)
                        .toDate()
                        .toISOString(),
                    visits: '0',
                    url: null,
                    visitors: null,
                    uniq_visitors: '0',
                    label: null,
                    bounce_rate: '0',
                    bounces: null,
                    actions: '0',
                    avg_time: '0',
                    pageviews: null,
                },
            ],
        },
        devices: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '152',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Smartphone',
                bounce_rate: '89',
                bounces: null,
                actions: '562',
                avg_time: '23579',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '145',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Desktop',
                bounce_rate: '59',
                bounces: null,
                actions: '947',
                avg_time: '47955',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '33',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Tablet',
                bounce_rate: '17',
                bounces: null,
                actions: '210',
                avg_time: '5689',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Camera',
                bounce_rate: '0',
                bounces: null,
                actions: '0',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Car browser',
                bounce_rate: '0',
                bounces: null,
                actions: '0',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Console',
                bounce_rate: '0',
                bounces: null,
                actions: '0',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Feature phone',
                bounce_rate: '0',
                bounces: null,
                actions: '0',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Phablet',
                bounce_rate: '0',
                bounces: null,
                actions: '0',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Portable media player',
                bounce_rate: '0',
                bounces: null,
                actions: '0',
                avg_time: '0',
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                url: '0',
                visitors: '0',
                uniq_visitors: '0',
                label: 'Smart display',
                bounce_rate: '0',
                bounces: null,
                actions: '0',
                avg_time: '0',
                pageviews: null,
            },
        ],
        summary: {
            date: moment(now)
                .toDate()
                .toISOString(),
            visits: '1230',
            visitors: 296,
            avg_time: '234',
            bounce_rate: '50%',
            avg_actions: '5.2',
            pageviews: '1585',
            uniq_pageviews: null,
            bounce_count: '165',
        },
        summaryPerDate: [
            {
                date: moment(now)
                    .subtract(6, 'days')
                    .toDate()
                    .toISOString(),
                visits: '54',
                visitors: 47,
                avg_time: '205',
                bounce_rate: '56%',
                avg_actions: '3.4',
                pageviews: '165',
                uniq_pageviews: '137',
                bounce_count: '30',
            },
            {
                date: moment(now)
                    .subtract(5, 'days')
                    .toDate()
                    .toISOString(),
                visits: '38',
                visitors: 35,
                avg_time: '195',
                bounce_rate: '47%',
                avg_actions: '6.6',
                pageviews: '235',
                uniq_pageviews: '179',
                bounce_count: '18',
            },
            {
                date: moment(now)
                    .subtract(4, 'days')
                    .toDate()
                    .toISOString(),
                visits: '57',
                visitors: 51,
                avg_time: '244',
                bounce_rate: '51%',
                avg_actions: '5.5',
                pageviews: '306',
                uniq_pageviews: '255',
                bounce_count: '29',
            },
            {
                date: moment(now)
                    .subtract(3, 'days')
                    .toDate()
                    .toISOString(),
                visits: '47',
                visitors: 42,
                avg_time: '311',
                bounce_rate: '51%',
                avg_actions: '5.6',
                pageviews: '264',
                uniq_pageviews: '234',
                bounce_count: '24',
            },
            {
                date: moment(now)
                    .subtract(2, 'days')
                    .toDate()
                    .toISOString(),
                visits: '71',
                visitors: 63,
                avg_time: '242',
                bounce_rate: '45%',
                avg_actions: '6',
                pageviews: '380',
                uniq_pageviews: '299',
                bounce_count: '32',
            },
            {
                date: moment(now)
                    .subtract(1, 'days')
                    .toDate()
                    .toISOString(),
                visits: '63',
                visitors: 58,
                avg_time: '206',
                bounce_rate: '51%',
                avg_actions: '4.5',
                pageviews: '235',
                uniq_pageviews: '189',
                bounce_count: '32',
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '0',
                visitors: 0,
                avg_time: '0',
                bounce_rate: '0',
                avg_actions: '0',
                pageviews: '0',
                uniq_pageviews: '0',
                bounce_count: '0',
            },
        ],
        socials: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '2',
                url: 'facebook.com',
                visitors: null,
                uniq_visitors: '2',
                label: 'Facebook',
                bounce_rate: null,
                bounces: '2',
                actions: '2',
                avg_time: null,
                pageviews: null,
            },
        ],
        referrers: [
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '5',
                url: 'www.googleadservices.com',
                visitors: null,
                uniq_visitors: '5',
                label: 'www.googleadservices.com',
                bounce_rate: null,
                bounces: '2',
                actions: '17',
                avg_time: null,
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'l.facebook.com',
                visitors: null,
                uniq_visitors: '1',
                label: 'l.facebook.com',
                bounce_rate: null,
                bounces: '1',
                actions: '1',
                avg_time: null,
                pageviews: null,
            },
            {
                date: moment(now)
                    .toDate()
                    .toISOString(),
                visits: '1',
                url: 'www.facebook.com',
                visitors: null,
                uniq_visitors: '1',
                label: 'www.facebook.com',
                bounce_rate: null,
                bounces: '1',
                actions: '1',
                avg_time: null,
                pageviews: null,
            },
        ],
        siteSearchKeywords: [{
            'label': 'Bus schedule',
            'visits': '398',
            'pageviews': '11',
            'pages_per_search': '4',
            'bounce_rate': '0%',
            'exit_rate': '10%',
            'exit_visits': '2',
            'avg_time': '43',
            'sum_time': '474',
        }, {
            'label': 'Visa application',
            'visits': '102',
            'pageviews': '8',
            'pages_per_search': '1',
            'bounce_rate': '0%',
            'exit_rate': '4%',
            'exit_visits': '3',
            'avg_time': '8',
            'sum_time': '67',
        }, {
            'label': 'Holiday',
            'visits': '93',
            'pageviews': '2',
            'pages_per_search': '2',
            'bounce_rate': '0%',
            'exit_rate': '7%',
            'exit_visits': '0',
            'avg_time': '9',
            'sum_time': '17',
        }, {
            'label': 'Opening hours',
            'visits': '250',
            'pageviews': '2',
            'pages_per_search': '5',
            'bounce_rate': '0%',
            'exit_rate': '20%',
            'exit_visits': '0',
            'avg_time': '406',
            'sum_time': '812',
        }, {
            'label': 'Contact',
            'visits': '19',
            'pageviews': '1',
            'pages_per_search': '1',
            'bounce_rate': '0%',
            'exit_rate': '0%',
            'exit_visits': '0',
            'avg_time': '26',
            'sum_time': '26',
        }],
    };
};

export const generateDownloadsByUrlPage = (page: PageModel, index: number) => {
    return {
        actions: '0',
        downloads: 0,
        avg_time: '47',
        bounce_rate: '0',
        bounces: null,
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        visitors: 236,
        visits: 0,
    };
};

export const generatePageWithoutVisits = (page: PageModel, index: number) => {
    return {
        actions: '0',
        avg_time: '47',
        bounce_rate: '0',
        bounces: null,
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        visitors: 236,
        visits: 0,
        compare: {
            visits: index + 1,
        },
    };
};

export const generateLeastPopularPage = (page: PageModel, index: number, pages: PageModel[]) => {
    return {
        actions: '0',
        avg_time: '47',
        bounce_rate: '0',
        bounces: null,
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        visitors: null,
        visits: (pages.length - index) * 123,
        compare: {
            visits: (pages.length - index) * 45,
            pageviews: '123',
            avg_time: '21',
        },
    };
};

export const generateExitPage = (page: PageModel, index: number, pages: PageModel[]) => {
    return {
        actions: '0',
        avg_time: '47',
        bounce_rate: '0',
        bounces: null,
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        visitors: 236,
        visits: (pages.length - index) * 123,
        compare: {
            visits: (pages.length - index) * 67,
            exit_visits: 34,
            avg_time: '24',
        },
    };
};

export const generateEntryPage = (page: PageModel, index: number, pages: PageModel[]) => {
    return {
        actions: '0',
        avg_time: '47',
        bounce_rate: 100 - 7 * index,
        bounces: Math.abs(maxBouncesPerPage - index * 3),
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        entry_visits: 34,
        visitors: 236,
        visits: (pages.length - index) * 123,
        compare: {
            visits: (pages.length - index) * 12,
            bounces: Math.abs(maxBouncesPerPage - index * 2),
            bounce_rate: 100 - 5 * index,
            visitors: index + 103,
            entry_visits: 12,
            avg_time: '23',
        },
    };
};

export const generateMostPopularPage = (page: PageModel, index: number, pages: PageModel[]) => {
    return {
        actions: '0',
        avg_time: '47',
        bounce_rate: '0',
        bounces: null,
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        visitors: null,
        visits: (pages.length - index) * 123,
        compare: {
            visits: (pages.length - index) * 56,
            pageviews: '123',
            avg_time: '24',
        },
    };
};

export const generateMostDownloadPage = (page: PageModel, index: number, pages: PageModel[]) => {
    return {
        actions: '0',
        avg_time: '47',
        downloads: (index + 1) * 10,
        bounce_rate: '0',
        bounces: null,
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        visitors: null,
        visits: (pages.length - index) * 123,
        compare: {
            downloads: (index + 1) * 5,
            pageviews: '143',
        },
    };
};

export const generatePagesDocumentsWithoutDownload = (page: PageModel, index: number, pages: PageModel[]) => {
    return {
        actions: '0',
        avg_time: '47',
        bounce_rate: '0',
        bounces: null,
        date: moment()
            .subtract(index + 1, 'days')
            .toDate()
            .toISOString(),
        label: page.url.replace(hostNameRegex, ''),
        pageviews: '236',
        uniq_visitors: '0',
        url: page.url,
        visitors: 236,
        visits: 0,
        downloads: 0,
        compare: {
            downloads: index + 1,
        },
    };
};
