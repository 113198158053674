(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardDistribution', {
        templateUrl: 'app/pages/accessibility/dashboard/distribution/distribution.html',
        controller: AccessibilityDashboardDistributionController,
        controllerAs: 'vm',
        bindings: {
            summary: '<',
            total: '<',
        },
    });

    AccessibilityDashboardDistributionController.$inject = [];
    /* @ngInject */
    function AccessibilityDashboardDistributionController () {
        var vm = this;
        vm.calcWidth = calcWidth;
        vm.$onInit = active;

        function active () {
            vm.checkA = 0;
            vm.checkAA = 0;
            vm.checkAAA = 0;
            vm.fixed = 0;
            getChecks();
        }

        function getChecks () {
            vm.checkA = vm.summary.checks_with_issues_by_abbr.A;
            vm.checkAA = vm.summary.checks_with_issues_by_abbr.AA;
            vm.checkAAA = vm.summary.checks_with_issues_by_abbr.AAA;
            var issueAbbrs = Object.keys(vm.summary.checks_with_issues_by_abbr);
            vm.fixed =
                vm.total -
                issueAbbrs.reduce(function (a, b) {
                    return a + vm.summary.checks_with_issues_by_abbr[b];
                }, 0); // Now it's the API team's problem if this count fails, muhahaha!
        }

        function calcWidth (input) {
            return (parseInt(input) / vm.total) * 100 + '%';
        }
    }
})();
