import { Injectable } from '@angular/core';
import { SessionStorageService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { SessionService } from '@monsido/ng2/core/session/session.service';

type CacheSettingType = {
    maxAge: number
};
type CacheConfigType = CacheSettingType & {modifiedAt: number};
type PayloadType<T> = { payload: Record<string,T> };
type CacheType<T> = CacheConfigType & PayloadType<T>;
const defaultConfig: CacheSettingType = {
    maxAge: 3600000, // 1 hour
};

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    cacheConfig = defaultConfig;
    constructor (private sessionStorageService: SessionStorageService, private sessionService: SessionService) { }

    setConfig (config: CacheSettingType): void {
        this.cacheConfig = {
            ...this.cacheConfig,
            ...config,
        };
    }

    get<T> (key: string): T | undefined {
        const bucketName = this.getBucketName();
        const payload = this.getCache<T>(key)?.payload;

        if (payload && bucketName) {
            return payload[bucketName];
        }
        return undefined;
    }

    set<T> (key: string, data: T): Record<string,T> | undefined {
        const bucketName = this.getBucketName();
        const cache = this.getCache<T>(key);
        if (bucketName) {
            const payload = {
                ...cache?.payload,
                [bucketName]: data,
            };
            this.setCache<T>(key, payload);
            return payload;
        }
        return undefined;
    }

    clearCache (key: string): void {
        this.sessionStorageService.removeItem(key);
    }

    private getCache<T> (key: string): CacheType<T> | undefined {
        let cache = JSON.parse(this.sessionStorageService.getItem(key)) as CacheType<T> | undefined;
        if (cache) {
            cache = this.handleExpire<T>(key, cache);
        }
        return cache;
    }

    private setCache<T> (key: string, payload: Record<string,T>): CacheType<T> {
        const cache: CacheType<T> = {
            ...this.cacheConfig,
            modifiedAt: Date.now(),
            payload,
        };
        this.sessionStorageService.setItem(key, JSON.stringify(cache));
        return cache;
    }

    private handleExpire<T> (key: string, cache: CacheType<T>): CacheType<T> | undefined {
        const expirationTime = cache.modifiedAt + cache.maxAge;
        const currentTimeStamp = Date.now();
        if (currentTimeStamp >= expirationTime) {
            this.sessionStorageService.removeItem(key);
            return undefined;
        }
        return cache;
    }

    private getBucketName (): string | undefined {
        if (this.sessionService.customer && this.sessionService.me) {
            return this.sessionService.me.id + '~' + this.sessionService.customer.id;
        }
        return undefined;
    }
}
