(function () {
    'use strict';

    angular.module('modules.rollbar').config(config);

    config.$inject = ['RollbarProvider', 'devMode', 'ROLLBAR_CONFIG'];
    /* @ngInject */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    function config (RollbarProvider, devMode, ROLLBAR_CONFIG) {
        if (devMode || window.location.hostname === 'localhost') {
            RollbarProvider.deinit();
        } else {
            RollbarProvider.init(ROLLBAR_CONFIG);
        }
    }
})();
