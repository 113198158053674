<mon-page-header [monIcon]="['ACTIONS', 'EDIT']"
                 header="{{'Account Settings' | translate }}"
                 subHeader="{{'My Account Settings' | translate }}">
</mon-page-header>
<div class="card panel-monsido-card">
    <mon-panel-header pHeader="{{'My Account Settings' | translate }}">
    </mon-panel-header>

    <div class="card-body">
        <form *ngIf="customer" ngForm>
            <div class="row align-items-center">
                <label class="col-sm-8 text-right" for="legacy_mode" translate>
                    Use Legacy Compliance
                </label>
                <div class="col-sm-40">
                    <mon-switch
                        [name]="'legacy_mode'"
                        [(ngModel)]="customer.settings.legacy_compliance"
                        (change)="updateCustomer()"
                        monLabel="legacy_mode">
                    </mon-switch>
                </div>
            </div>
            <div class="row align-items-center">
                <label class="col-sm-8 text-right" for="legacy_mode_checks" translate>
                    Use Legacy Compliance calculation on individual checks
                </label>
                <div class="col-sm-40 mt-3">
                    <mon-switch
                        [name]="'legacy_mode_checks'"
                        [(ngModel)]="customer.settings.legacy_check_compliance"
                        (change)="updateCustomer()"
                        monLabel="legacy_mode_checks">
                    </mon-switch>
                </div>
            </div>
            <div class="row align-items-center" *ngIf="isSeoActive">
                <label class="col-sm-8 mt-2 text-right" for="legacy_mode_seo" translate>
                    Is using legacy compliance calculation on individual SEO issues:
                </label>
                <div class="col-sm-40 mt-3">
                        <mon-switch
                            [name]="'legacy_mode_seo'"
                            [(ngModel)]="customer.settings.legacy_seo_compliance"
                            (change)="updateCustomer()"
                            monLabel="legacy_mode_seo">
                        </mon-switch>
                </div>
            </div>
        </form>
    </div>
</div>
