import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('blocks.service').factory('ComponentDetailsView', ComponentDetailsViewService);

    ComponentDetailsViewService.$inject = ['ng2MonEventsService', '$deepmerge'];
    /* @ngInject*/
    function ComponentDetailsViewService (ng2MonEventsService, $deepmerge) {
        return {
            goToDocumentDetails: goToDocumentDetails,
        };

        /**
         * @param {object} document
         * @param {object|undefined} dataParams
         * @param {function|undefined} cb
         * @return {void}
         */
        function goToDocumentDetails (document, dataParams, cb) {
            var data = $deepmerge(
                {
                    document: document,
                },
                dataParams,
            );

            var params = {
                body: 'documentDetails',
                size: 'page-details',
                classes: 'document-details-app',
                data: data,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: cb });
        }
    }
})();
