(function () {
    'use strict';
    angular
        .module('modules.light.pages.domain.page_fix')
        .service('LightDomainPageFixDashboardPageFixModelService', LightDomainPageFixDashboardPageFixModelService);

    LightDomainPageFixDashboardPageFixModelService.$inject = [
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        'PAGE_FIX_ACCESSIBILITY_CHECK_TAB',
        'gettextCatalog',
    ];

    /* @ngInject*/
    function LightDomainPageFixDashboardPageFixModelService (
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        PAGE_FIX_ACCESSIBILITY_CHECK_TAB,
        gettextCatalog,
    ) {
        function Model (pageFix) {
            var vm = this;
            vm.translatedCategories = [];
            angular.extend(vm, pageFix);
            var checkId = pageFix.change.check_id;
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[checkId];
            if (config && config.work_types) {
                for (var i = 0; i < config.work_types.length; i++) {
                    vm.translatedCategories.push(getCategoryTranslation(config.work_types[i]));
                }
            }
        }

        Model.prototype.getImage = getImage;

        return Model;

        // ///////////////

        function getImage () {
            var checkId = this.change.check_id;
            var config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[checkId];
            return config.imagePath;
        }

        // PROTECTED

        function getCategoryTranslation (category) {
            switch (category) {
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE:
                    return gettextCatalog.getString('Website Structure');
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN:
                    return gettextCatalog.getString('Design');
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS:
                    return gettextCatalog.getString('Links');
                case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA:
                    return gettextCatalog.getString('Media');
                default:
                    return '';
            }
        }
    }
})();
