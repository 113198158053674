export const POLICY_EXCHANGE_CENTER_DB = [
    {
        created_at: new Date('September 27, 2017'),
        name: 'Text that starts with Lorem ipsum',
        'bg-color': '#ffffff',
        icon: 'fal fa-file',
        vertical: [],
        country: [],
        policy: {
            name: 'Text that starts with Lorem ipsum',
            template: 'text_lorem_ipsum',
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Text that starts with Lorem ipsum',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'starts_with',
                    query: 'Lorem ipsum',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        name: 'Find pages with links that contain Lorem ipsum',
        'bg-color': '#000000',
        icon: 'far fa-link',
        vertical: ['education', 'government'],
        country: ['europe'],
        policy: {
            name: 'Find pages with links that contain Lorem ipsum',
            template: 'link_lorem',
            subject: 'page_html',
            tags: ['qa'],
            mode: 'required',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Find pages with links that contain Lorem ipsum',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(lorem|Lorem|LOREM)',
                    type: 'link_url',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        name: 'Page titles that contain Lorem ipsum',
        'bg-color': null,
        icon: 'far fa-copy',
        vertical: ['government'],
        country: ['africa'],
        policy: {
            name: 'Page titles that contain Lorem ipsum',
            template: 'title_lorem',
            subject: 'page_html',
            tags: ['qa'],
            mode: 'violation',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Page titles that contain Lorem ipsum',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(lorem|Lorem|LOREM)',
                    type: 'page_title',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        name: 'Images greater than 500kB',
        'bg-color': '#ffffff',
        icon: 'far fa-image',
        vertical: [],
        country: ['asia'],
        policy: {
            name: 'Images greater than 500kB',
            template: 'file_gt_500_kb',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Images greater than 500kB',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'gt',
                    query: '500kb',
                    type: 'image_size',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        name: 'Images greater than 1MB',
        'bg-color': '#ffffff',
        icon: 'far fa-image',
        vertical: ['finance', 'education'],
        country: [],
        policy: {
            name: 'Images greater than 1MB',
            template: 'file_gt_1_mb',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Images greater than 1MB',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'gt',
                    query: '1mb',
                    type: 'image_size',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        name: 'Images greater than 100kB',
        'bg-color': '#ffffff',
        icon: 'far fa-image',
        vertical: ['finance', 'education'],
        country: [],
        policy: {
            name: 'Images greater than 100kB',
            template: 'file_gt_100_kb',
            subject: 'page',
            tags: ['seo'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Images greater than 100kB',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'gt',
                    query: '100kb',
                    type: 'image_size',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        name: 'Find publicly exposed credit card numbers',
        'bg-color': '#ffffff',
        icon: 'far fa-credit-card',
        vertical: ['finance'],
        country: ['north_america'],
        policy: {
            name: 'Find publicly exposed credit card numbers',
            template: 'find_credit_card_numbers',
            subject: 'page',
            tags: ['qa'],
            mode: 'violation',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Publicly exposed credit card numbers',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '\\b(?:\\d[ -]*?){13,16}\\b',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        name: 'Find long paragraph',
        'bg-color': '#ffffff',
        icon: 'fas fa-paragraph',
        vertical: [],
        country: ['north_america'],
        policy: {
            name: 'Find long paragraph',
            template: 'find_long_paragraph',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Long paragraph',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '([\\p{Word}\\p{Punct}\\p{Blank}]{400,})',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('September 27, 2017'),
        new: false,
        name: 'Find words in all capital letters',
        'bg-color': '#ffffff',
        icon: 'fal fa-text-height',
        vertical: ['government'],
        country: ['north_america'],
        policy: {
            name: 'Find words in all capital letters',
            template: 'find_all_capital_letters',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Capital letters',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '\\b([[:upper:]]{2,})\\b',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 15, 2017'),
        new: false,
        name: 'Terms to avoid when writing about gender and age',
        'bg-color': '#ffffff',
        icon: 'far fa-venus-mars',
        vertical: ['finance', 'education', 'government'],
        country: [],
        policy: {
            name: 'Terms to avoid when writing about gender and age',
            template: 'avoid_gender_and_age_us',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Contains mankind',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'mankind',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains manpower',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'manpower',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains to man',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains_sentence',
                    query: 'to man',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains fathering',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'fathering',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains mothering',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'mothering',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains fireman',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'fireman',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains policeman',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'policeman',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains postman',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'postman',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains chairman',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'chairman',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains chairwoman',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'chairwoman',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains his/her',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'his/her',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains alumnus/alumna',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'alumnus/alumna',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains alumnus/na',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'contains',
                    query: 'alumnus/na',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 15, 2017'),
        new: false,
        name: 'Terms to avoid when writing about disabilities',
        'bg-color': '#ffffff',
        icon: 'fas fa-universal-access',
        vertical: ['education', 'government'],
        country: ['north_america'],
        policy: {
            name: 'Terms to avoid when writing about disabilities',
            template: 'avoid_disabilities_us',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Contains abnormal',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(abnormal|Abnormal|ABNORMAL)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains afflicted with',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:afflicted|Afflicted|AFFLICTED) (?:with|With|WITH)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains able-bodied',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(able-bodied|Able-bodied|Able-Bodied|ABLE-BODIED)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains confined to a wheelchair',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:confined|Confined|CONFINED) (?:to|To|TO) (?:a|A) (?:wheelchair|Wheelchair|WHEELCHAIR)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains crazy',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(crazy|Crazy|CRAZY)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains insane',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(insane|Insane|INSANE)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains nuts',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(nuts|Nuts|NUTS)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains psycho',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '\\b(psycho|Psycho|PSYCHO)\\b',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains deaf and dumb',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:deaf|Deaf|DEAF) (?:and|And|AND) (?:dumb|Dumb|DUMB)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains deaf-mute',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(deaf-mute|Deaf-mute|Deaf-Mute|DEAF-MUTE)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains defect',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(defect|Defect|DEFECT)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains birth defect',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:birth|Birth|BIRTH) (?:defect|Defect|DEFECT)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains defective',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(defective|Defective|DEFECTIVE)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains demented',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(demented|Demented|DEMENTED)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains senile',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(senile|Senile|SENILE)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains epileptic fit',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:epileptic|Epileptic|EPILEPTIC) (?:fit|Fit|FIT)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains lunatic',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(lunatic|Lunatic|LUNATIC)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains loony',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(loony|Loony|LOONY)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains mentally retarded',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:mentally|Mentally|MENTALLY) (?:retarded|Retarded|RETARDED)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains midget',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(midget|Midget|MIDGET)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains paraplegic',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(paraplegic|Paraplegic|PARAPLEGIC)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains psychotic',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(psychotic|Psychotic|PSYCHOTIC)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains quadriplegic',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(quadriplegic|Quadriplegic|QUADRIPLEGIC)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains schizophrenic',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(schizophrenic|Schizophrenic|SCHIZOPHRENIC)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains spastic',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(spastic|Spastic|SPASTIC)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains a spaz',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:a|A) (?:spaz|Spaz|SPAZ)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains stricken with',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:stricken|Stricken|STRICKEN) (?:with|With|WITH)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains suffers from',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:suffers|Suffers|SUFFERS) (?:from|From|FROM)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains victim of',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:victim|Victim|VICTIM) (?:of|Of|OF)',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains vegetable',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(vegetable|Vegetable|VEGETABLE)',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 15, 2017'),
        new: false,
        name: 'Max. word length',
        'bg-color': '#ffffff',
        icon: 'fas fa-exchange-alt',
        vertical: [],
        country: [],
        pre_content_component: 'form-global-policy-pre-content-rule-max-length',
        policy: {
            name: 'Max. word length',
            template: 'max_word_length',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [],
        },
    },
    {
        created_at: new Date('January 06, 2018'),
        new: false,
        name: 'Repeated words',
        'bg-color': '#ffffff',
        icon: 'fas fa-redo-alt ',
        vertical: [],
        country: [],
        pre_content_component: 'form-global-policy-pre-content-rule-repeated-short-words',
        policy: {
            name: 'Repeated words',
            template: 'repeated_short_words',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [],
        },
    },
    {
        created_at: new Date('December 18, 2017'),
        new: false,
        name: 'Brand name without trademark',
        'bg-color': '#ffffff',
        icon: 'far fa-trademark',
        vertical: ['government'],
        country: [],
        pre_content_component: 'form-global-policy-pre-content-rule-missing-trademark',
        policy: {
            name: 'Brand name without trademark',
            template: 'brand_name_without_trademark',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'high',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [],
        },
    },
    {
        created_at: new Date('December 18, 2017'),
        new: false,
        name: 'Words without specific context',
        'bg-color': '#ffffff',
        icon: 'far fa-file-alt',
        vertical: [],
        country: [],
        pre_content_component: 'form-global-policy-pre-content-rule-without-context',
        policy: {
            name: 'Words without specific context',
            template: 'words_without_context',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [],
        },
    },
    {
        created_at: new Date('December 18, 2017'),
        new: false,
        name: 'Wrong date format',
        'bg-color': '#ffffff',
        icon: 'far fa-calendar-alt',
        vertical: ['finance'],
        country: ['north_america'],
        policy: {
            name: 'Wrong date format',
            template: 'wrong_date_format_us',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Contains invalid separator in dates',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '\\b(?:1[0-2]|0[1-9])[^\\/](?:[0-2][0-9]|3[0-1])[^\\/]\\d{2,4}\\b',
                    type: 'page_text',
                    sub_entry: '',
                },
                {
                    name: 'Contains day before month in dates',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '\\b(0?[1-9]|[12]\\d|30|31)([\\/\\.\\-])(?:1[0-2]|0[1-9])([\\/\\.\\-])\\d{2,4}\\b',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 18, 2017'),
        new: false,
        name: 'Incorrect usage of digit separators',
        'bg-color': '#ffffff',
        icon: 'far fa-cut',
        vertical: [],
        country: [],
        policy: {
            name: 'Incorrect usage of digit separators',
            template: 'incorrect_digit_separator_us',
            subject: 'page',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Incorrect usage of digit separators',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '(?:^|[[:blank:]])((?:\\d{1,3}\\.)*\\d{3},\\d+)(?:[[:blank:]]|$)',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('October 31, 2018'),
        new: false,
        name: 'Find all Pages with Dropbox links',
        'bg-color': '#ffffff',
        icon: 'fab fa-dropbox',
        vertical: [],
        country: [],
        policy: {
            name: 'Find all Pages with Dropbox links',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Find Pages with links that starts with https://dropbox.com',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'starts_with',
                    query: 'https://dropbox.com',
                    type: 'link_url',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all missing terms and conditions links',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all missing terms and conditions links',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Links that don\'t contain the word `terms and conditions`',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'terms and conditions',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain the word `Terms and Conditions`',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'Terms and Conditions',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain T&C\'s',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'regex',
                    query: '[tT]&[cC]\'[sS]',
                    type: 'link_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all missing product disclosure statement links',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all missing product disclosure statement links',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Links that don\'t contain the word \'product disclosure statement\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'product disclosure statement',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain the word \'Product Disclosure Statements\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'Product Disclosure Statements',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain PDS',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'PDS',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain pds',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'pds',
                    type: 'link_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all missing privacy policy links',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all missing privacy policy links',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Links that don\'t contain the word \'privacy policy\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'privacy policy',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain the word \'Privacy Policy\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'Privacy Policy',
                    type: 'link_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all missing financial services guide links',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all missing financial services guide links',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Links that don\'t contain the word \'financial services guide\' links',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'financial services guide',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain the word \'Financial Services Guide\' links',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'Financial Services Guide',
                    type: 'link_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all missing banking practice links',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all missing banking practice links',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Links that don\'t contain the word \'banking practice links\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'banking practice links',
                    type: 'link_text',
                    sub_entry: '',
                },
                {
                    name: 'Links that don\'t contain the word \'Code of Banking Practice links\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains',
                    query: 'Code of Banking Practice',
                    type: 'link_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all pages missing the word “AFSL”',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all pages missing the word “AFSL”',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain the word \'AFSL\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains_words',
                    query: 'AFSL',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all pages missing the word “ABN”',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all pages missing the word “ABN”',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain the word \'ABN\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains_words',
                    query: 'ABN',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all pages missing the word “BSB”',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all pages missing the word “BSB”',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain word BSB',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains_words',
                    query: 'BSB',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find misspelled [Company Name]',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        pre_content_component: 'form-global-policy-pre-content-rule-missing-company-name',
        policy: {
            name: 'Find misspelled [Company Name]',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain [company name]',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains_words',
                    query: '',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all pages missing [Company Name]',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all pages missing [Company Name]',
            template: null,
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain [COMPANY NAME]',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'contains_words',
                    query: '',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all pages missing the words “disclosure document(s)”',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all pages missing the words “disclosure document(s)”',
            template: null,
            subject: 'page',
            tags: ['qa'],
            mode: 'violation',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain the words \'disclosure documents\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'regex',
                    query: '(?:disclosure|Disclosure|DISCLOSURE) (?:documents|Documents|DOCUMENTS)',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all pages missing the word “fees”',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all pages missing words fees',
            template: null,
            subject: 'page',
            tags: ['qa'],
            mode: 'violation',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain the word \'fees\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'regex',
                    query: '(fees|Fees|FEES)',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find all pages missing the word “cost”',
        'bg-color': '#ffffff',
        icon: 'fas fa-file-signature',
        policy: {
            name: 'Find all pages missing words “cost”',
            template: null,
            subject: 'page',
            tags: ['qa'],
            mode: 'violation',
            priority: 'low',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Pages that don\'t contain the word \'cost\'',
                    case_sensitive: true,
                    inverse: true,
                    operator: 'regex',
                    query: '(cost|Cost|COST)',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Find emails not inside an anchor element',
        'bg-color': '#ffffff',
        icon: 'fal fa-envelope-square',
        vertical: [],
        country: [],
        policy: {
            name: 'Find emails not inside an anchor element',
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Find emails not inside an anchor element',
                    case_sensitive: true,
                    inverse: false,
                    multiline: true,
                    operator: 'regex',
                    query: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b(?![^<]*<\/a>)',
                    type: 'page_html',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Search for [Link Name]',
        'bg-color': '#ffffff',
        icon: 'far fa-link',
        vertical: [],
        country: [],
        policy: {
            name: 'Search for [Link Name]',
            subject: 'page_html',
            tags: ['qa', 'accessibility'],
            mode: 'search',
            priority: 'medium',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Search for Link [Link Name]',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'regex',
                    query: '<a.*?>.*?(Here|Click here|More|Click).*?<\\/a>',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Search for Pages with unsafe links',
        'bg-color': '#ffffff',
        icon: 'far fa-link',
        vertical: [],
        country: [],
        policy: {
            name: 'Search for Pages with unsafe links',
            subject: 'page_html',
            tags: [],
            mode: 'search',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Search for pages with unsafe links',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'starts_with',
                    query: 'http://',
                    type: 'link_url',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('December 07, 2020'),
        new: false,
        name: 'Search for pages without viewport element',
        'bg-color': '#ffffff',
        icon: 'far fa-link',
        vertical: [],
        country: [],
        policy: {
            name: 'Search for pages without viewport element',
            subject: 'page_html',
            tags: ['qa', 'accessibility'],
            mode: 'violation',
            priority: 'high',
            rules_operator: 'or',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Search for pages without viewport element',
                    case_sensitive: true,
                    inverse: false,
                    operator: 'eq',
                    query: '0',
                    type: 'meta_header_length',
                    sub_entry: 'viewport',
                },
            ],
        },
    },
    {
        created_at: new Date('March 30, 2023'),
        new: true,
        name: 'Find empty tags',
        'bg-color': '#ffffff',
        icon: 'fal fa-file',
        vertical: [],
        country: [],
        policy: {
            name: 'Find empty tags',
            template: 'find_empty_tags',
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Find empty tags',
                    case_sensitive: false,
                    inverse: false,
                    operator: 'regex',
                    query: '(<\w+>)+[ \n(<br>)]*(<\/\w+>)',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('March 30, 2023'),
        new: true,
        name: 'Find canonical values',
        'bg-color': '#ffffff',
        icon: 'fal fa-file',
        vertical: [],
        country: [],
        policy: {
            name: 'Find canonical values',
            template: 'find_canonical_values',
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Find canonical values',
                    case_sensitive: false,
                    inverse: false,
                    operator: 'regex',
                    query: '<link rel="canonical" href="([^"]+)"',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
    {
        created_at: new Date('March 30, 2023'),
        new: true,
        name: 'Find email adresses',
        'bg-color': '#ffffff',
        icon: 'fal fa-file',
        vertical: [],
        country: [],
        policy: {
            name: 'Find email adresses',
            template: 'find_email_adresses',
            subject: 'page_html',
            tags: ['qa'],
            mode: 'search',
            priority: 'high',
            rules_operator: 'and',
            targets: [],
            labels: [],
            rules: [
                {
                    name: 'Find email adresses',
                    case_sensitive: false,
                    inverse: false,
                    operator: 'regex',
                    query: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$',
                    type: 'page_text',
                    sub_entry: '',
                },
            ],
        },
    },
];
