(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceAuditDisplayManual', {
        templateUrl: 'app/modules/page-performance/components/display-modes/manual/manual.html',
        controller: PagePerformanceAuditDisplayManualController,
        controllerAs: 'vm',
        bindings: {
            audit: '<',
        },
    });

    PagePerformanceAuditDisplayManualController.$inject = [];
    /* @ngInject */
    function PagePerformanceAuditDisplayManualController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {}

        // PROTECTED
    }
})();
