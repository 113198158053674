(function() {
    "use strict";

    /**
     * @memberof modules.translations.services
     * @ngdoc factory
     * @name translation
     * @description Service to set the Global Locale settings.
     */
    angular.module("modules.translations.services").service("translation", translation);

    translation.$inject = ["gettextCatalog", "amMoment", "$rootScope"];
    /* ngInject */
    function translation(gettextCatalog, amMoment, $rootScope) {
        return {
            setDateLocale: setDateLocale,
            setLanguage: setLanguage,
        };

        ///////////////

        /**
         * @memberOf translation
         * @desc Globally define the date format in the application
         * @param language - Date locale. Check "node_modules/moment/locales" for the supported locale list
         */
        function setDateLocale(language) {
            amMoment.changeLocale(language);
        }

        /**
         * @memberOf translation
         * @desc Globally set the locale translation key
         * @param locale - locale translation to use. Should always be based on the available locale defined in the API...
         */
        function setLanguage(locale) {
            $rootScope.applicationLocale = locale;
            gettextCatalog.setCurrentLanguage(locale);
        }
    }
})();
