import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicy', {
        templateUrl: 'app/forms/global-policy/policy.html',
        controller: GlobalPolicyController,
        controllerAs: 'vm',
        bindings: {
            policy: '<?',
            monDialogId: '@',
            setForm: '&?',
        },
    });

    GlobalPolicyController.$inject = [
        'ng2DomainRepoService',
        'monDialog',
        'gettextCatalog',
        'ng2MonModalService',
        '$q',
        'ng2SessionService',
        '$deepmerge',
        '$timeout',
        'FORM_CONSTANT',
        'ng2MonEventsService',
        'smartScroll',
        'uibModalOpenerService',
    ];

    /* @ngInject */
    function GlobalPolicyController (
        ng2DomainRepoService,
        monDialog,
        gettextCatalog,
        ng2MonModalService,
        $q,
        ng2SessionService,
        $deepmerge,
        $timeout,
        FORM_CONSTANT,
        ng2MonEventsService,
        smartScroll,
        uibModalOpenerService,
    ) {
        var vm = this;
        var formSteps;
        var listenScrollToDropzone;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getStep = getStep;
        vm.submit = submit;
        vm.wizPrev = wizPrev;
        vm.wizNext = wizNext;
        vm.chooseTemplate = chooseTemplate;

        function activate () {
            vm.loading = true;
            if (angular.isObject(vm.policy) && vm.policy.subject) {
                formSteps = ['policyRules'];
            } else {
                vm.policy = vm.policy || {};
                formSteps = ['policyContent', 'policyRules'];
            }

            if (Object.keys(vm.policy).length === 0) {
                vm.step = -1;
            } else {
                if (angular.isString(vm.policy.subject) && vm.policy.subject.length > 0) {
                    vm.step = formSteps.indexOf('policyRules');
                } else {
                    vm.step = 0;
                }
            }

            vm.firstStep = true;
            vm.lastStep = formSteps.length === 1;

            listenScrollToDropzone = ng2MonEventsService.addListener('scrollToDropzone', function () {
                smartScroll.scrollTo(policyModalScrollable, 'scrollToPolicyBottom', 250);
            });

            vm.loading = false;

            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.policyForm });
                }
            });
        }

        function onDestroy () {
            ng2MonEventsService.remove(listenScrollToDropzone);
        }

        function chooseTemplate (template) {
            vm.policy = $deepmerge(vm.policy, template.policy);
            var step = angular.isString(vm.policy.subject) ? 'policyRules' : 'policyContent';

            if (angular.isDefined(template.pre_content_component)) {
                var dialogParams = {
                    body: FORM_CONSTANT.FORM_GLOBAL_POLICY_PRE_CONTENT,
                    data: { template: template },
                    size: 'lg',
                };

                const callback = function (result) {
                    if (result) {
                        vm.policy.rules = result;
                        goTo(step);
                    }
                };
                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params: dialogParams, callback });
            } else {
                goTo(step);
            }
        }


        function submit () {
            var policy = angular.copy(vm.policy);
            policy.labels = policy.labels || [];
            policy.target_all_domains = policy.target_all_domains === 'true';
            if (policy.target_all_domains) {
                policy.targets = [];
            }
            policy.labels = policy.labels.map(function (label) {
                return label.id;
            });
            checkTargets(policy).then(function () {
                openSaveDialog(policy);
            }, angular.noop);
        }

        function checkTargets (policy) {
            if (!policy.targets || (policy.targets.length === 0 && !policy.target_all_domains)) {
                var msg = gettextCatalog.getString('There are no domains assigned to this policy. Save policy anyway?');
                return ng2MonModalService.confirm(msg);
            } else {
                return $q.resolve();
            }
        }

        function openSaveDialog (policy) {
            uibModalOpenerService
                .open({
                    component: FORM_CONSTANT.FORM_GLOBAL_POLICY_DIALOG_SAVE,
                    resolve: {
                        policy: function () {
                            return policy;
                        },
                    },
                })
                .result.then(function () {
                    if (vm.policyForm) {
                        vm.policyForm.$setPristine();
                    }
                    close();
                });
        }

        function getStep () {
            return formSteps[vm.step] || false;
        }

        function close () {
            monDialog.close(vm.monDialogId, vm.policy);
        }

        function wizPrev () {
            vm.step = vm.step <= -1 ? 0 : vm.step - 1;
            if (vm.step === -1) {
                vm.policy = {};
            }
            checkSteps();
        }

        function wizNext () {
            vm.step = vm.step + 1 >= formSteps.length ? vm.step : vm.step + 1;
            checkSteps();
        }

        function goTo (stepName) {
            vm.step = formSteps.indexOf(stepName);
            checkSteps();
        }

        function checkSteps () {
            vm.firstStep = vm.step === -1;
            vm.lastStep = formSteps.length - 1 === vm.step;
        }


    }
})();
