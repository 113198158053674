(function () {
    'use strict';
    /**
     * @memberof modules.accessibility
     * @ngdoc service
     * @name AccessibilityFastTrackService
     * @description Manage Logical operations used by the Fast Track Component
     */
    angular.module('modules.accessibility').service('AccessibilityFastTrackService', AccessibilityFastTrackService);

    AccessibilityFastTrackService.$inject = [
        'ng2FastTrackSessionService',
        'ng2SessionService',
        'Lodash',
        'ng2MonEventsService',
        'gettextCatalog',
    ];
    /* @ngInject*/
    function AccessibilityFastTrackService (ng2FastTrackSessionService, ng2SessionService, Lodash, ng2MonEventsService, gettextCatalog) {
        var vm = this;
        vm.excludedChecks = {};
        vm.closedCaptions = {};

        return {
            setupSession: setupSession,
            clear: clear,
            getNonExcludedChecks: getNonExcludedChecks,
            getAffectedPagesCount: getAffectedPagesCount,
            addExcludedCheck: addExcludedCheck,
            getHelpCenterGuidelineDescription: getHelpCenterGuidelineDescription,
            getAccessibilityGuideline: getAccessibilityGuideline,
            closeCaptionSuccess: closeCaptionSuccess,
            isClosedCaption: isClosedCaption,
        };

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Clears Session Variables stored in Fast Track
         */
        function clear () {
            ng2FastTrackSessionService.clear();
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Setup Session Variables to be used in Fast Track
         */
        function setupSession () {
            ng2FastTrackSessionService.setSnippetSourceCodeProperty('source_code');
            ng2FastTrackSessionService.setSnippetComplianceProperty('effect_on_compliance');
            ng2FastTrackSessionService.setSnippetIssuesProperty('caused_issues');
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Filters out all checks that have already been excluded in Fast Track
         * @param snippet - Fast Track Snippet
         * @param checks - Unfiltered checks collection
         */
        function getNonExcludedChecks (snippet, checks) {
            var domainId = ng2SessionService.getDomainId();
            if (vm.excludedChecks[domainId] && vm.excludedChecks[domainId][snippet.id]) {
                var excludedChecks = vm.excludedChecks[domainId][snippet.id];

                return Lodash.filter(checks, function (check) {
                    return !Lodash.some(excludedChecks, { id: check.id });
                });
            }

            return checks;
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Get the highest amount of affected pages found from the Checks collection
         * @param checks - Checks Collection
         */
        function getAffectedPagesCount (checks) {
            return Lodash.reduce(
                checks,
                function (num, content) {
                    return Math.max(content.pages_count, num);
                },
                0,
            );
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Add excluded check to the excludedChecks collection
         * @param snippet - Fast Track Snippet
         * @param check - Accessibility Check
         */
        function addExcludedCheck (snippet, check) {
            var domainId = ng2SessionService.getDomainId();
            if (!vm.excludedChecks[domainId]) {
                vm.excludedChecks[domainId] = {};
            }

            if (snippet !== null) {
                if (!vm.excludedChecks[domainId][snippet.id]) {
                    vm.excludedChecks[domainId][snippet.id] = [];
                }

                vm.excludedChecks[domainId][snippet.id].push(check);
            } else {
                ng2MonEventsService.run('newException', 'Unable to add Excluded Check ' + check.id + ', on Domain ' + domainId, 'error');
            }
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Get description to prefix to the help Center Button
         * @param check - Accessibility Check
         */
        function getHelpCenterGuidelineDescription (check) {
            return gettextCatalog.getString('Part of success criteria {{checkIssueNumber}}', {
                checkIssueNumber: check.issue_number,
            });
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Get Domain Accessibility Guideline
         */
        function getAccessibilityGuideline () {
            return ng2SessionService.domain && ng2SessionService.domain.features.accessibility;
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Get Domain Accessibility Guideline
         * @param snippet - Fast Track Snippet
         * @param check - Accessibility Check
         */
        function isClosedCaption (snippet, check) {
            var domainId = ng2SessionService.getDomainId();
            if (!vm.closedCaptions[domainId] || !vm.closedCaptions[domainId][snippet.id]) {
                return false;
            }

            return vm.closedCaptions[domainId][snippet.id][check.id];
        }

        /**
         * @memberOf AccessibilityFastTrackService
         * @desc Annotate Close Caption in the Fast Track Service
         * @param snippet - Fast Track Snippet
         * @param check - Accessibility Check
         */
        function closeCaptionSuccess (snippet, check) {
            var domainId = ng2SessionService.getDomainId();
            if (!vm.closedCaptions[domainId]) {
                vm.closedCaptions[domainId] = {};
            }

            if (snippet !== null) {
                if (!vm.closedCaptions[domainId][snippet.id]) {
                    vm.closedCaptions[domainId][snippet.id] = {};
                }

                vm.closedCaptions[domainId][snippet.id][check.id] = true;
            } else {
                ng2MonEventsService.run('newException', 'Unable to add Close Caption to ' + check.id + ', on Domain ' + domainId, 'error');
            }
        }

        // PROTECTED
    }
})();
