(function () {
    'use strict';

    angular.module('services.api').factory('PagePerformanceRescanRepo', PagePerformanceRescanRepo);

    PagePerformanceRescanRepo.$inject = ['ng2ApiService', 'PagePerformanceRescanModel'];

    /* @ngInject */
    function PagePerformanceRescanRepo (ng2ApiService, PagePerformanceRescanModel) {
        return {
            get: get,
            create: create,
        };

        // /////////////

        function get (params, config, contextToken) {
            config = config || {};
            params = params || {};
            return ng2ApiService.get('performance/scans', params, config, contextToken).then(toModels);
        }

        function create (params, contextToken) {
            params = params || {};
            return ng2ApiService.post('performance/scans', params, contextToken);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new PagePerformanceRescanModel(model);
        }
    }
})();
