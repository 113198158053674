(function () {
    'use strict';
    angular.module('modules.page-performance').component('tableScoreChart', {
        templateUrl: 'app/modules/page-performance/components/score-chart/score.html',
        controller: TableScoreChartController,
        controllerAs: 'vm',
        bindings: {
            performanceScore: '<',
            bigChart: '<',
            monAction: '&?',
        },
    });

    TableScoreChartController.$inject = ['$filter'];

    /* @ngInject */
    function TableScoreChartController ($filter) {
        var vm = this;
        vm.$onInit = activate;
        vm.getDoughnutClass = getDoughnutClass;
        vm.getPerformanceScore = getPerformanceScore;

        function activate () {
            vm.bigChart = Boolean(vm.bigChart);
        }

        function getDoughnutClass () {
            return 'chart-point-' + getSliceSize();
        }

        function getSliceSize () {
            if (typeof vm.performanceScore === 'number') {
                return $filter('monNumber')(100 * vm.performanceScore, '0');
            }
            return 0;
        }

        function getPerformanceScore () {
            if (typeof vm.performanceScore === 'number') {
                return $filter('monNumber')(vm.performanceScore * 100, '0');
            }
            return 'N/A';
        }
    }
})();
