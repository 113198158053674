(function () {
    'use strict';

    angular.module('modules.statistics').component('statisticsUrlTitle', {
        templateUrl: 'app/modules/statistics/components/url-title/url-title.html',
        controller: StatisticsUrlTitleController,
        controllerAs: 'vm',
        bindings: {
            url: '<',
            title: '<',
        },
    });

    StatisticsUrlTitleController.$inject = [];

    function StatisticsUrlTitleController () {}
})();
