import { Component } from '@angular/core';
import { Report } from '@monsido/modules/models/api/report';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { ScheduleReportFormComponent } from '../schedule-report-form/schedule-report-form.component';

@Component({
    selector: 'mon-scheduled-reports-list',
    templateUrl: './scheduled-reports-list.component.html',
    styleUrls: ['./scheduled-reports-list.component.scss'],
})
export class ScheduledReportsListComponent {
    maxTargets: number = 2;
    reports: Report[] = [];

    constructor (
        private monEventService: MonEventService,
    ) {}

    editReport (report: Report, index: number): void {
        const callback = (reportFromDialog: Report | null): void => {
            if (reportFromDialog !== null && !Array.isArray(reportFromDialog) && typeof reportFromDialog === 'object') {
                this.reports[index] = reportFromDialog;
            }
        };

        const params = {
            component: ScheduleReportFormComponent,
            data: { report },
            dialogOptions: {
                cb: callback,
                size: 'sm',
            },
        };

        this.monEventService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
    }

}
