import { Injectable } from '@angular/core';
import * as focusTrap from 'focus-trap';

@Injectable({
    providedIn: 'root',
})
export class TrapFocusService {

    private list: Set<focusTrap.FocusTrap> = new Set();

    constructor () { }

    create (elements: HTMLElement[], userOptions: focusTrap.Options): focusTrap.FocusTrap {
        const focus = focusTrap.createFocusTrap(elements, userOptions);
        focus.activate();
        this.list.add(focus);
        return focus;
    }

    unPauseLast (): void {
        const focus = this.getLast();
        if (focus) {
            focus.unpause();
        }
    }

    pauseLast (): void {
        const focus = this.getLast();
        if (focus) {
            focus.pause();
        }
    }

    remove (focus: focusTrap.FocusTrap): void {
        if (this.list.has(focus)) {
            focus.deactivate();
            this.list.delete(focus);
        }
    }

    private getLast (): focusTrap.FocusTrap | undefined {
        return Array.from(this.list).pop();
    }

}
