(function () {
    'use strict';

    angular.module('modules.light.pages.domain.page_fix').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog', 'PAGE_FIX_ACCESSIBILITY_CHECK_TAB', 'ng2LightPageFixService'];

    /* @ngInject */
    function appRun (routerHelper, gettextCatalog, PAGE_FIX_ACCESSIBILITY_CHECK_TAB, ng2LightPageFixService) {
        routerHelper.configureStates(getStates(gettextCatalog, PAGE_FIX_ACCESSIBILITY_CHECK_TAB, ng2LightPageFixService));
    }

    function getStates (gettextCatalog, PAGE_FIX_ACCESSIBILITY_CHECK_TAB, ng2LightPageFixService) {
        var states = [
            {
                state: 'base.customer.light.domain.page_fix',
                config: {
                    url: '',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'light-page-fix',
                    },
                    resolve: {
                        hasAccessToPageFix: hasAccessToPageFix,
                    },
                },
            },
            {
                state: 'base.customer.light.domain.page_fix.home',
                config: {
                    url: '/summary',
                    template: '<light-domain-page-fix-dashboard></light-domain-page-fix-dashboard>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - PageCorrect Accessibility Fixes'),
                    },
                },
            },
            {
                state: 'base.customer.light.domain.page_fix.checks',
                config: {
                    url: '/checks?search',
                    template:
                        '<light-domain-page-fix-accessibility-checks></light-domain-page-fix-accessibility-checks>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - PageCorrect Accessibility Fixes'),
                    },
                },
            },
            {
                state: 'base.customer.light.domain.page_fix.accessibility_checks',
                config: {
                    url: '/accessibility-checks',
                    template: '<div ui-view></div>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - PageCorrect Accessibility Fixes'),
                    },
                },
            },
        ];
        var categoryKeys = Object.keys(PAGE_FIX_ACCESSIBILITY_CHECK_TAB).filter(function (categoryKey) {
            var category = PAGE_FIX_ACCESSIBILITY_CHECK_TAB[categoryKey];
            return ng2LightPageFixService.getCategoryCount(category);
        });
        for (var i = 0; i < categoryKeys.length; i++) {
            var categoryValue = PAGE_FIX_ACCESSIBILITY_CHECK_TAB[categoryKeys[i]];
            var title = ng2LightPageFixService.getTitle(categoryValue);
            states.push({
                state: 'base.customer.light.domain.page_fix.accessibility_checks.' + categoryValue,
                config: {
                    url: '/' + categoryValue + '?search',
                    template:
                        '<light-domain-page-fix-accessibility-checks category=\'' +
                        categoryValue +
                        '\'></light-domain-page-fix-accessibility-checks>',
                    data: {
                        title: gettextCatalog.getString('Acquia Optimize - PageCorrect ' + title),
                    },
                    resolve: {
                        hasAccessToSubPages: hasAccessToSubPages,
                    },
                },
            });
        }
        return states;
    }

    var hasAccessToPageFix = [
        'ng2ActiveFeatureService',
        '$state',
        '$q',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $state, $q, $stateParams, ng2MonUIRouterTransitionService) {
            if (
                ng2ActiveFeatureService.isFeatureActive('page_fix') &&
                ng2ActiveFeatureService.isFeatureActive('admin_accessibility')
            ) {
                return $q.resolve(true);
            }

            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.light.domains', $stateParams),
            );
        },
    ];

    var hasAccessToSubPages = [
        'ng2LightPageFixService',
        '$state',
        '$q',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2LightPageFixService, $state, $q, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2LightPageFixService.canAccessSubPages()) {
                return $q.resolve(true);
            }

            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.light.domain.page_fix.checks', $stateParams),
            );
        },
    ];
})();
