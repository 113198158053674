(function () {
    'use strict';

    angular.module('modules.page_fix.forms', [
        'mon.dialog',
        'plugins.lodash',
        'services.entities',
        'gettext',
    ]);
})();
