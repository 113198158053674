(function (angular) {
    angular.module('core.constant').constant(
        'MON_CMS',
        [
            {
                name: 'CivicEngage Central',
                id: 'civicengage_central',
            },
            {
                name: 'CivicEngage Evolve',
                id: 'civicengage_evolve',
            },
            {
                name: 'Web Open',
                id: 'civicengage_open',
            },
            {
                name: 'Craft CMS',
                id: 'craft',
            },
            {
                name: 'SIMsite',
                id: 'simsite',
            },
            {
                name: 'Adobe CMS',
                id: 'adobe',
            },
            {
                name: 'Konform',
                id: 'konform',
            },
            {
                name: 'Acos',
                id: 'acos',
            },
            {
                name: 'AEM',
                id: 'aem',
            },
            {
                name: 'Shopify',
                id: 'shopify',
            },
            {
                name: 'EPiServer',
                id: 'episerver',
            },
            {
                name: 'iCreate',
                id: 'icreate',
            },
            {
                name: 'Elcom',
                id: 'elcom',
            },
            {
                name: 'Cascade',
                id: 'cascade',
            },
            {
                name: 'CorePublish',
                id: 'corepublish',
            },
            {
                name: 'Custom CMS',
                id: 'custom',
            },
            {
                name: 'Sitecore',
                id: 'sitecore',
            },
            {
                name: 'Dynamicweb',
                id: 'dynamicweb',
            },
            {
                name: 'Umbraco',
                id: 'umbraco',
            },
            {
                name: 'Custom meta header',
                id: 'special',
            },
            {
                name: 'WordPress',
                id: 'wordpress',
            },
            {
                name: 'WordPress Multisite',
                id: 'wordpress_multisite',
            },
            {
                name: 'Omniupdate',
                id: 'omniupdate',
            },
            {
                name: 'Publicera',
                id: 'publicera',
            },
            {
                name: 'Drupal',
                id: 'drupal',
            },
            {
                name: 'Finalsite',
                id: 'finalsite',
            },
            {
                name: 'Kentico',
                id: 'kentico',
            },
            {
                name: 'Municode',
                id: 'municode',
            },
            {
                name: 'Presence',
                id: 'presence',
            },
            {
                name: 'Gabbart',
                id: 'gabbart',
            },
            {
                name: 'Hubspot',
                id: 'hubspot',
            },
            {
                name: 'Squarespace',
                id: 'squarespace',
            },
            {
                name: 'Squiz',
                id: 'squiz',
            },
            {
                name: 'SilverStripe',
                id: 'silverstripe',
            },
            {
                name: 'Sitefinity',
                id: 'sitefinity',
            },
            {
                name: 'Sitevision',
                id: 'sitevision',
            },
            {
                name: 'Plone',
                id: 'plone',
            },
            {
                name: 'OpenCities',
                id: 'opencities',
            },
            {
                name: 'Joomla',
                id: 'joomla',
            },
            {
                name: 'Smart Sites',
                id: 'smart_sites',
            },
        ].sort(function (a, b) {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
        }),
    );
})(angular);
