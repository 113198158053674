export const CHART_COLORS_CONSTANT = {
    LIGHT_BLUE: '#299bf7',
    PEACH: '#ff9879',
    DARK_GREEN_BLUE: '#052942',
    YELLOW: '#fdc50d',
    PURPLE: '#5e3ea8',
    LIGHT_TURQUOISE: '#7beaf3',
    ORANGE: '#ff6c00',
    GREEN_BLUE: '#007a9e',
    LIGHT_PURPLE: '#837bc1',
    CERISE: '#d6347b',
    DARK_BLUE: '#004ac1',
    TURQUOISE: '#00dedb',
    RED: '#ba322d',
    LIGHT_YELLOW: '#efc271',
    PINK: '#f398c4',
    GRAY: '#9a9d9e',
    DARK_GRAY: '#555557',
    GREEN: '#26ff5c',
    LIGHT_GRAY: '#dfdfde',
    BLUE: '#205c9e',
} as const;
