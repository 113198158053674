(function () {
    'use strict';

    angular.module('services.api').factory('ApiUsersRepo', ApiUserRepo);

    ApiUserRepo.$inject = ['ng2ApiService'];
    /* @ngInject */
    function ApiUserRepo (ng2ApiService) {
        return {
            getAll: getAll,
            get: get,
            create: create,
            destroy: destroy,
            revokeToken: revokeToken,
        };

        // /////////////

        function getAll (params, headers) {
            params = params || {};
            return ng2ApiService.get('api_users', params, headers);
        }

        function get (apiUser, params, headers) {
            params = params || {};
            return ng2ApiService.get('api_users/' + apiUser.id, params, headers);
        }

        function create (params, headers) {
            params = params || {};
            return ng2ApiService.post('api_users', params, headers);
        }

        function destroy (apiUser, params, headers) {
            params = params || {};
            return ng2ApiService.delete('api_users/' + apiUser.id, params, headers);
        }

        function revokeToken (apiUser, params, headers) {
            params = params || {};
            return ng2ApiService.post('api_users/' + apiUser.id + '/revoke_token', params, headers);
        }
    }
})();
