import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';

export type DictionaryType = {
    qa: string;
    accessibility: string;
    pdf: string;
    seo: string;
    inventory: string;
    policy: string;
    heatmaps: string;
    uptime: string;
    stats: string;
    data_protection: string;
};

@Injectable({
    providedIn: 'root',
})
export class TranslateReportService {
    private dictionary: DictionaryType;

    constructor (
        private translateService: TranslateService,
    ) {
        this.dictionary = this.getDictionary();
        this.translateService.onLanguageChange$().subscribe(() => {
            this.dictionary = this.getDictionary();
        });
    }

    private parseReportName (reportName?: string): string {
        if (!reportName) {
            return '';
        }
        let wordIndex;
        if (reportName.indexOf('data_protection') !== -1) {
            wordIndex = reportName.indexOf('n') + 1;
        } else {
            wordIndex = reportName.indexOf('_');
            if (wordIndex === -1) {
                wordIndex = reportName.length - 1;
            }
        }

        return reportName.substring(0, wordIndex);
    }

    getTranslation (reportName?: string): string {
        const parsedReportName = this.parseReportName(reportName);
        return this.dictionary[parsedReportName] || this.translateService.getString('None');
    }

    getDictionary (): DictionaryType {
        return {
            qa: this.translateService.getString('Quality Assurance'),
            accessibility: this.translateService.getString('Accessibility'),
            pdf: this.translateService.getString('Accessibility'),
            seo: this.translateService.getString('SEO'),
            inventory: this.translateService.getString('Inventory'),
            policy: this.translateService.getString('Policies'),
            heatmaps: this.translateService.getString('Heatmaps'),
            uptime: this.translateService.getString('Heartbeat'),
            stats: this.translateService.getString('Statistics'),
            data_protection: this.translateService.getString('Data Privacy'),
        };
    }
}
