<div class="sample-widget-container">
    <div class="widget-sample">
        <div class="widget-area" [ngClass]="{'unfocus': highlightMessage}">
            <div class="widget-header" [ngStyle]="{'background-color': widgetSchemeColor}">
                <div class="widget-logo-area">
                    <div class="widget-logo draw-line" [ngClass]="{'focus': highlightUpload}">&nbsp;</div>
                </div>
                <div class="widget-title-area">
                    <div class="widget-title draw-line" [ngClass]="{'focus': highlightTitle}" [ngStyle]="{'background-color': drawLineColor}">&nbsp;</div>
                </div>
                <div class="widget-info-text-area">
                    <div class="widget-info-text draw-line" [ngClass]="{'focus': highlightText}" [ngStyle]="{'background-color': drawLineColor}">&nbsp;</div>
                    <div class="widget-info-text draw-line" [ngClass]="{'focus': highlightText}" [ngStyle]="{'background-color': drawLineColor}">&nbsp;</div>
                    <div class="widget-info-text draw-line" [ngClass]="{'focus': highlightText}" [ngStyle]="{'background-color': drawLineColor}">&nbsp;</div>
                </div>
            </div>
            <div class="widget-body">
                <div class="sample-items">
                    <div class="sample-item col-22"></div>
                    <div class="sample-item col-22"></div>
                    <div class="sample-item col-22"></div>
                    <div class="sample-item col-22"></div>
                    <div class="sample-item col-22"></div>
                    <div class="sample-item col-22"></div>
                </div>
            </div>
        </div>
        <div class="widget-button-area">
            <div class="mt-2 widget-button" [ngStyle]="{'background-color': widgetSchemeColor}" [ngClass]="iconShape" [ngClass]="direction" [ngClass]="{'focus': highlightMessage}">
                <div class="draw-line" [ngClass]="{'focus': highlightMessage}"></div>
            </div>
        </div>
    </div>
</div>
