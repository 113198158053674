(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSeoPagesDialog', {
        templateUrl: 'app/components/pagedetails/details/sections/seo/seo-information/pages/index.html',
        controller: PagesController,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
            title: '<?',
        },
    });

    PagesController.$inject = ['ng2SeoRepoService', '$controller', 'ng2CheckForDuplicatesService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function PagesController (ng2SeoRepoService, $controller, ng2CheckForDuplicatesService, ng2ActiveFeatureService) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.search = null;
            vm.tabs = [];
            vm.pages = [];
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                content: vm.seo.content,
                page_size: vm.pageSize,
                title: vm.title || null,
            };

            ng2SeoRepoService.getSeoIssuePages(vm.seo.id, params).then(function (data) {
                ng2CheckForDuplicatesService.checkForDuplicatePages(data);
                vm.pages = data;
            }, angular.noop);
        }
    }
})();
