(function () {
    'use strict';

    angular.module('modules.source-code-excludes').component('formSourceCodeExclude', {
        templateUrl: 'app/modules/source-code-excludes/form/source-code-exclude/source-code-exclude.html',
        controller: FormSourceCodeExcludeController,
        controllerAs: 'vm',
        bindings: {
            sourceCodeExclude: '<?',
            module: '<',
            monDialogId: '@',
        },
    });

    FormSourceCodeExcludeController.$inject = ['monDialog', 'SourceCodeExcludesApiService', '$deepmerge'];

    /* @ngInject */
    function FormSourceCodeExcludeController (monDialog, SourceCodeExcludesApiService, $deepmerge) {
        var vm = this;
        vm.$onInit = activate;
        vm.nextStep = nextStep;
        vm.previousStep = previousStep;
        vm.selectModule = selectModule;
        vm.submit = submit;

        function activate () {
            vm.step = 0;
            vm.ngModel = $deepmerge(vm.sourceCodeExclude || {}, {});
            vm.ngModel.module = vm.module;
            setupStep();
        }

        function selectModule (module) {
            vm.ngModel.module = module;
            nextStep();
        }

        function nextStep () {
            vm.step++;
        }

        function previousStep () {
            vm.step--;
        }

        function submit () {
            var promise;
            vm.ngModel.default_for_cms = vm.ngModel.default_for_cms || null;
            var model = $deepmerge({}, vm.ngModel);
            vm.saving = true;
            if (isNaN(parseFloat(vm.ngModel.id))) {
                promise = SourceCodeExcludesApiService.create(vm.ngModel.module, model);
            } else {
                promise = SourceCodeExcludesApiService.update(vm.ngModel.module, vm.sourceCodeExclude, model);
            }

            promise.then(
                function (sourceCodeExclude) {
                    close(sourceCodeExclude);
                },
                function () {
                    vm.saving = false;
                },
            );
        }

        function setupStep () {
            if (vm.ngModel.id > 0) {
                vm.step = 1;
            }
        }

        function close (sourceCodeExclude) {
            monDialog.close(vm.monDialogId, sourceCodeExclude);
        }
    }
})();
