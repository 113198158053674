import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Report } from '@monsido/modules/models/api/report';
import { User } from '@monsido/modules/models/api/user';
import uniqBy from 'lodash/uniqBy';
import { REPORT_CENTER_MODULES } from '../../report-center-modules.constant';
import { ActiveFeatureService } from '@monsido/ng2/services/active-feature/active-feature.service';
import { MonFeature, MonFeatureType } from '@monsido/ng2/services/active-feature/active-feature.types';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class ReportCenterDashboardService {
    private featureToModuleMap: Partial<Record<MonFeatureType, Record<string, string>>>;
    private moduleText: string;

    constructor (
        private translateService: TranslateService,
        private sessionService: SessionService,
        private activeFeatureService: ActiveFeatureService,
    ) {
        this.moduleText = this.translateService.getString('Module');
        this.featureToModuleMap = {
            [MonFeature.admin_accessibility]: {
                name: this.translateService.getString('Accessibility'),
                value: REPORT_CENTER_MODULES.accessibility,
            },
            [MonFeature.seo_all]: {
                name: this.translateService.getString('SEO'),
                value: REPORT_CENTER_MODULES.seo,
            },
            [MonFeature.admin_statistics]: {
                name: this.translateService.getString('Statistics'),
                value: REPORT_CENTER_MODULES.statistics,
            },
            [MonFeature.policies_all]: {
                name: this.translateService.getString('Policy'),
                value: REPORT_CENTER_MODULES.policy,
            },
            [MonFeature.inventory_all]: {
                name: this.translateService.getString('Inventory'),
                value: REPORT_CENTER_MODULES.other,
            },
            [MonFeature.admin_heartbeat]: {
                name: this.translateService.getString('Heartbeat'),
                value: REPORT_CENTER_MODULES.heartbeat,
            },
            [MonFeature.qa_module]: {
                name: this.translateService.getString('Quality Assurance'),
                value: REPORT_CENTER_MODULES.qa,
            },
            [MonFeature.admin_heatmaps]: {
                name: this.translateService.getString('Heatmaps'),
                value: REPORT_CENTER_MODULES.heatmaps,
            },
        };
    }

    getAvailableFilters (): Record<string, unknown> {
        const filters = {};
        const featureStatus = this.getFeatureActivePureStatus();

        filters[this.moduleText] = Object.keys(this.featureToModuleMap)
            .map((feature: MonFeatureType) => featureStatus[feature] ? this.featureToModuleMap[feature] : null)
            .filter(u => u);

        return filters;
    }

    getDefaultFilters (): Record<string, unknown> {
        const filters = {};
        filters[this.moduleText] = [];
        return filters;
    }

    getUniqueUsersByIdFromReport (report: Report): User[] {
        const users = report.targets.flatMap((domain) => domain.users);
        return uniqBy(users, 'id') as User[];
    }

    isReceivingReport (users: User[]): boolean {
        return users.some(user => {
            const meId = this.sessionService.me?.id;
            if (meId !== undefined) {
                return meId === user.user?.id;
            }
            return false;
        });
    }

    private getFeatureActivePureStatus (): Partial<Record<MonFeatureType, boolean>> {
        const res: Partial<Record<MonFeatureType, boolean>> = {};
        Object.keys(this.featureToModuleMap)
            .forEach((feature: MonFeatureType) => res[feature] = this.activeFeatureService.isFeatureActivePure(feature));

        return res;
    }
}
