(function () {
    'use strict';

    angular.module('app.components', [
        'components.templates',
        'ui.bootstrap.tpls',
        'modules.modals',
        'ui.bootstrap.popover',
        'infinite-scroll',
        'app.components.table',
        'app.components.tabs',
        'app.components.formBuilder',
        'app.components.layout',
        'app.components.pages',
        'app.components.charts',
        'app.components.label',
        'app.components.pageDetails',
        'app.components.panels',
        'app.components.pageLinkDropdown',
        'app.components.dialog',
        'app.components.colorPicker',
        'app.components.documentDetails',
        'app.components.monButton',
    ]);
})();
