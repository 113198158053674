(function () {
    'use strict';

    angular.module('filters.monsido').filter('isObject', isObject);
    isObject.$inject = [];

    /* @ngInject */
    function isObject () {
        return function (input) {
            return angular.isObject(input);
        };
    }
})();
