import { Injectable } from '@angular/core';
import { DEMO_DATA_DECISION_TREE, DemoDataDecisionTreeType } from './data-decision-tree.demo';
import { cloneDeep } from 'lodash';
import { PageAccessibilityRepoCheckType } from '@client/app/services/api/page-accessibility/page-accessibility-repo.service';


@Injectable({
    providedIn: 'root',
})
export class DemoSupportCenterAccessibilityChecksRepoService {

    constructor () { }

    getDecisionTree (check: PageAccessibilityRepoCheckType): Promise<DemoDataDecisionTreeType | null> {
        return this.delay(() => {
            const decisionTree = DEMO_DATA_DECISION_TREE.find((question) => {
                return question.check_id.indexOf(check.id) > -1;
            });
            return decisionTree ? cloneDeep(decisionTree) : null;
        });
    }

    private delay<T> (fn: () => T): Promise<T | null> {
        const timer = Math.round(1000 * Math.random());
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const result = fn();

                result ? resolve(result) : reject(new Error('no demo data'));
            }, timer);
        });
    }

}
