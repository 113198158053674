(function () {
    'use strict';

    angular.module('modules.cookie_banner').service('CookieFieldsLocalizeService', CookieFieldsLocalizeService);

    CookieFieldsLocalizeService.$inject = ['COOKIE_FIELDS', 'gettextCatalog'];
    /* @ngInject*/
    function CookieFieldsLocalizeService (COOKIE_FIELDS, gettextCatalog) {
        var fields = {};
        fields[COOKIE_FIELDS.id] = gettextCatalog.getString('Id');
        fields[COOKIE_FIELDS.platform] = gettextCatalog.getString('Platform');
        fields[COOKIE_FIELDS.name] = gettextCatalog.getString('Name');
        fields[COOKIE_FIELDS.domain] = gettextCatalog.getString('Domain');
        fields[COOKIE_FIELDS.source] = gettextCatalog.getString('Source');
        fields[COOKIE_FIELDS.expires] = gettextCatalog.getString('Duration');
        fields[COOKIE_FIELDS.type] = gettextCatalog.getString('Category');
        fields[COOKIE_FIELDS.description] = gettextCatalog.getString('Description');

        return fields;
    }
})();
