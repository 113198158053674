import { Injectable } from '@angular/core';
import { StatisticsEventsAction, StatisticsEventsCategoryOrName } from '@monsido/modules/statistics/models/statistics-events.type';

@Injectable({ providedIn: 'root' })
export class StatisticsEventsDemoRepoService {
    getEventsCategories (): Promise<StatisticsEventsCategoryOrName[]> {
        return Promise.resolve(this.getEventsCategoryData());
    }

    getEventsActions (): Promise<StatisticsEventsAction[]> {
        return Promise.resolve(this.getEventsActionsData());
    }

    getEventsNames (): Promise<StatisticsEventsCategoryOrName[]> {
        return Promise.resolve(this.getEventsNameData());
    }

    private getEventsActionsDataByLabel (label: string): StatisticsEventsAction {
        return {
            event_count: '1927',
            visits: '12',
            uniq_visitors: '234',
            label,
            avg_event_value: '34',
            event_names: [
                {
                    label: 'Website header button clicked',
                    event_count: '933',
                },
                {
                    label: 'Website header button clicked',
                    event_count: '101',
                },
                {
                    label: 'Form on Contact Us page',
                    event_count: '818',
                },
                {
                    label: 'Website footer link clicked',
                    event_count: '75',
                },
            ],
        };
    }

    private getEventsCategoryOrNameDataByLabel (cat: string, label: string): StatisticsEventsCategoryOrName {
        return {
            event_category: cat,
            event_count: '9323',
            visits: '12',
            uniq_visitors: '234',
            label,
            avg_event_value: '34',
            event_actions: [
                {
                    label: 'Website header button clicked',
                    event_count: '3948',
                },
                {
                    label: 'Website header button clicked',
                    event_count: '4538',
                },
                {
                    label: 'Form on Contact Us page',
                    event_count: '800',
                },
                {
                    label: 'Website footer link clicked',
                    event_count: '37',
                },
            ],
        };
    }

    private getEventsActionsData (): StatisticsEventsAction[] {
        const labels = ['Book a demo', 'Book a demo', 'Form submitted', 'Statement opened'];
        return labels.map(label => this.getEventsActionsDataByLabel(label));
    }

    private getEventsCategoryData (): StatisticsEventsCategoryOrName[] {
        const labels = {
            'conversion': 'Conversion',
            'form-submissions': 'Form Submissions' ,
            'accessibility-statement': 'Accessibility statement' ,
        };

        return this.setDataFromLabels(labels);
    }

    private getEventsNameData (): StatisticsEventsCategoryOrName[] {
        const labels = {
            'conversion': 'Website header button clicked',
            'form-submissions': 'Form on Contact Us page' ,
            'accessibility-statement': 'Website footer link clicked' ,
        };

        return this.setDataFromLabels(labels);
    }

    private setDataFromLabels (labels: Record<string, string>): StatisticsEventsCategoryOrName[] {
        const res: StatisticsEventsCategoryOrName[] = [];
        for (const [key, value] of Object.entries(labels)) {
            res.push(this.getEventsCategoryOrNameDataByLabel(key, value));
        }

        return res;
    }
}
