import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuditlogService {

    constructor () { }

    isAnUndoLog (action): boolean {
        return action === 'undo_ignored' || action === 'undo_reviewed';
    }

    canUndo (target): boolean {
        return target === 'AccessibilitySourceCode';
    }
}
