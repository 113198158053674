(function () {
    'use strict';

    angular.module('modules.page-performance').run([
        'ng2MonEventsService',
        'PagePerformanceProfileObsService',
        'PagePerformanceAssignedProfileObsService',
        'ng2ActiveFeatureService',
        function (ng2MonEventsService, PagePerformanceProfileObsService, PagePerformanceAssignedProfileObsService, ng2ActiveFeatureService) {
            ng2MonEventsService.addListener('finishCustomer', function () {
                if (ng2ActiveFeatureService.isFeatureActive('admin_page_performance')) {
                    PagePerformanceProfileObsService.startLiveReload();
                    PagePerformanceAssignedProfileObsService.startLiveReload();
                }
            });

            ng2MonEventsService.addListener('exitCustomer', function () {
                PagePerformanceProfileObsService.disposeProfiles();
                PagePerformanceAssignedProfileObsService.disposeAssignedProfiles();
            });

            ng2MonEventsService.addListener('logout', function () {
                PagePerformanceProfileObsService.disposeProfiles();
                PagePerformanceAssignedProfileObsService.disposeAssignedProfiles();
            });
        },
    ]);
})();
