(function () {
    'use strict';

    angular.module('pages.policy').component('pagesPolicyDialogPages', {
        templateUrl: 'app/pages/policy/dialogs/pages/pages.html',
        controller: PolicyController,
        controllerAs: 'vm',
        bindings: {
            policy: '<',
            pageCount: '<',
            contentCount: '<',
            pdfCount: '<',
            documentCount: '<',
        },
    });

    PolicyController.$inject = [
        'ng2PolicyRepoService',
        '$controller',
        'gettextCatalog',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2LabelService',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function PolicyController (
        ng2PolicyRepoService,
        $controller,
        gettextCatalog,
        ng2MonEventsService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2LabelService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFiltersChanged = onFiltersChanged;
        vm.onSearchChanged = onSearchChanged;
        vm.$onDestroy = onDestroy;
        vm.createOrUpdateLabel = createOrUpdateLabel;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.totalIssues = vm.policy.pages_count;
            vm.isFiltersOpen = false;
            vm.search = null;
            vm.pages = [];
            vm.currentPageCount = 0;
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    faIcon: 'fas fa-copy',
                    value: '',
                },
                {
                    name: gettextCatalog.getString('HTML Pages'),
                    faIcon: 'fas fa-window-maximize',
                    value: 'html',
                },
                {
                    name: gettextCatalog.getString('PDFs'),
                    faIcon: 'fas fa-file-pdf',
                    value: 'pdf',
                },
                {
                    name: gettextCatalog.getString('Documents'),
                    faIcon: 'fas fa-file-alt',
                    value: 'document',
                },
            ];
            vm.activeTab = '';

            getPage();
            vm.getLabels();

            vm.exports = [
                {
                    name: gettextCatalog.getString('Policy Pages'),
                    data: {
                        category: 'policy_pages',
                        category_ref: vm.policy.id,
                    },
                },
                {
                    name: gettextCatalog.getString('Policy with matching terms'),
                    data: {
                        category: 'policy_matching_terms',
                        category_ref: vm.policy.id,
                    },
                },
            ];
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.search && vm.search !== '') {
                params.search = vm.search;
            }

            if (vm.activeTab) {
                params.type = vm.activeTab;
            }

            params = vm.updateParamsWithFilters(params);

            vm.progress = ng2PolicyRepoService.domainGetPolicyPages(vm.policy.id, params).then(function (pages) {
                vm.pages = pages;
                vm.totalIssues = pages.total;
                switch (vm.activeTab) {
                    case 'html':
                        vm.currentPageCount = vm.pageCount;
                        break;
                    case 'pdf':
                        vm.currentPageCount = vm.pdfCount;
                        break;
                    case 'document':
                        vm.currentPageCount = vm.documentCount;
                        break;
                    default:
                        vm.currentPageCount = vm.contentCount;
                        break;
                }
            }, angular.noop);
        }

        function onFiltersChanged (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }

        function onSearchChanged (search) {
            vm.search = search;
            vm.getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }

        function createOrUpdateLabel (label) {
            ng2LabelService.createOrUpdateLabel(label);
        }
    }
})();
