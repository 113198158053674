import { SessionService } from '@monsido/ng2/core/session/session.service';

export type BaseRepoRequestType = {
    page?: number,
    page_size?: number,
    search?: string,
    sort_by?: string,
    sort_dir?: string,
    group_id?: number,
    domain_id?: number
};

export abstract class BaseRepo {
    protected abstract sessionService: SessionService;

    protected setGroupId<T extends BaseRepoRequestType> (params: T = {} as T): T {
        if (!params.group_id) {
            const currentGroupId = this.sessionService.getGroupId();
            if (currentGroupId) {
                params.group_id = currentGroupId;
            }
        }
        return params;
    }

    protected getDomainId (domainId?: number): number | undefined {
        if (domainId) {
            return domainId;
        }
        return this.sessionService.getDomainId();
    }
}
