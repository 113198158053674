import { PAGE_FIX_FIXABLE_TYPES } from '../modules/page-fix/constants/page-fix-fixable-types';
import { PageFixModelHelper } from '../modules/page-fix/services/model-helper/page-fix-model-helper';
import { LinkType } from './pages/page-link.model';

export interface LinkModel extends LinkType {
    getPageFixType(): string;
    getPageFixSelector(): string;
}
export class LinkModel {
    constructor (input: LinkType) {
        Object.assign(this, input);
    }

    getPageFixType (): string {
        return PAGE_FIX_FIXABLE_TYPES.link;
    }

    getPageFixSelector (): string {
        if (this.type === 'link') {
            return PageFixModelHelper.getSelectorValue('<a href="' + this.url + '"></a>');
        } else {
            return PageFixModelHelper.getSelectorValue('<img src="' + this.url + '"/>');
        }
    }
}
