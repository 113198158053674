import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkModule } from './components/link/link.module';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { OrderByModule } from './pipes/order-by/order-by.module';
import { GetUserNameModule } from './pipes/get-user-name/get-user-name.module';
import { ExportButtonModule } from './components/export-button/export-button.module';
import { ExportDialogModule } from './components/export-dialog/export-dialog.module';
import { SimpleDoughnutChartModule } from './components/simple-doughnut-chart/simple-doughnut-chart.module';
import { MonButtonNextModule } from './components/button/button-next.module';
import { MonActionMenuModule } from './components/action-menu/action-menu.module';
import { MonActionMenuPanelModule } from './components/action-menu-panel/action-menu-panel.module';

const commonModules = [
    SharedPipesModule,
    ExportDialogModule,
    ExportButtonModule,
    LinkModule,
    CommonModule,
    OrderByModule,
    GetUserNameModule,
    SimpleDoughnutChartModule,
    MonButtonNextModule,
    MonActionMenuModule,
    MonActionMenuPanelModule,
];

@NgModule({
    declarations: [],
    imports: [...commonModules],
    exports: [
        ...commonModules,
        FormsModule,
    ],
})
export class SharedModule {}
