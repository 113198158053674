import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.qa.readability.summary').component('readabilitySummaryScore', {
        templateUrl: 'app/pages/qa/readability/summary/score/score.html',
        controller: ReadabilitySummaryScoreController,
        controllerAs: 'vm',
        bindings: {
            summary: '<',
            totalPageCount: '<',
            filterType: '<',
        },
    });

    ReadabilitySummaryScoreController.$inject = ['ng2MonEventsService', 'QAReadabilityService'];

    function ReadabilitySummaryScoreController (ng2MonEventsService, QAReadabilityService) {
        var vm = this;
        vm.$onChanges = onChanges;
        vm.openPages = openPages;
        vm.hasTooltipDescription = hasTooltipDescription;
        vm.getTooltipDescription = getTooltipDescription;
        vm.isHighestPageCount = isHighestPageCount;

        function onChanges () {
            vm.readabilityScores = QAReadabilityService.sortReadability(vm.summary.test, vm.summary.distribution);
            vm.highestPageCountIndex = vm.readabilityScores.reduce(function (highestIndex, distribution, index) {
                if (vm.readabilityScores[highestIndex].page_count < distribution.page_count) {
                    return index;
                }
                return highestIndex;
            }, 0);
        }

        function isHighestPageCount (index) {
            return vm.highestPageCountIndex === index;
        }

        function openPages (score) {
            var params = {
                body: 'readabilityPagesWithScore',
                size: 'lg',
                data: {
                    score: score,
                    totalPageCount: vm.totalPageCount,
                    filterType: vm.filterType,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function hasTooltipDescription (level) {
            return QAReadabilityService.hasTooltipScore(level.name, vm.summary.test);
        }

        function getTooltipDescription (level) {
            return QAReadabilityService.getTooltipScore(level.name, vm.summary.test);
        }
    }
})();
