(function () {
    'use strict';

    angular.module('modules.statistics').component('statisticsDatePicker', {
        template:
            '<mon-asc-date-picker [un-restricted-max]="vm.unRestrictedMax" (date-change)="vm.apply()" [options]="vm.options" [(date)]="vm.date" [mon-date-format]="vm.dateFormat" class="pull-right" />',
        controller: StatisticsDatepickerController,
        controllerAs: 'vm',
        bindings: {
            onApply: '&?',
            options: '<?',
            date: '=?',
            unRestrictedMax: '<?',
            dateRangeGroup: '@',
            allowToday: '<?',
        },
    });

    StatisticsDatepickerController.$inject = ['dateRangeService', 'moment', 'DATE_FORMAT'];

    function StatisticsDatepickerController (dateRangeService, moment, DATE_FORMAT) {
        var vm = this;
        vm.apply = apply;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        var subject;
        var subscription;

        function activate () {
            vm.date = moment(vm.date);
            vm.dateFormat = DATE_FORMAT;
            subject = dateRangeService.setRangeGroup(vm.dateRangeGroup, vm.date);

            if (vm.dateRangeGroup) {
                subscription = subject.subscribe(function (newDate) {
                    if (newDate && newDate !== vm.date) {
                        vm.date = newDate;
                    }
                });
            }
            vm.options = vm.options || { maxDate: moment() };
            vm.unRestrictedMax = vm.unRestrictedMax || false;
            vm.allowToday = vm.allowToday || false;

            if (vm.date.endDate) {
                if (moment(vm.date.endDate).isAfter(moment().endOf('day'))) {
                    vm.date.endDate = moment()
                        .subtract(vm.allowToday ? 0 : 1, 'days')
                        .endOf('day');
                }
                vm.date.endDate = moment(vm.date.endDate);
            } else {
                vm.date.endDate = moment()
                    .subtract(vm.allowToday ? 0 : 1, 'days')
                    .endOf('day');
            }

            if (vm.date.startDate) {
                vm.date.startDate = moment(vm.date.startDate);
            } else {
                vm.date.startDate = moment().subtract(7, 'days');
            }
        }

        function onDestroy () {
            subscription.dispose();
        }

        function apply () {
            subject.onNext(vm.date);
            vm.onApply({});
        }
    }
})();
