<div class="alert alert-warning font-size-1-3 font-weight-500 justify-content-center row" *ngIf="domain | isStatsDemoData" translate>
    Statistics results are sample data for demo purposes - export is disabled
</div>
<mon-page-header [monIcon]="['ACTIONS', 'ARROW_POINTER']"
                 header="{{'Events' | translate }}">
    <div class="header-actions">
        <mon-switch-button
            [options]="tabs"
            [label]="'Events filter buttons' | translate"
            (onSelected)="onTabSelected($event)"
            [defaultIndex]="defaultTabIndex"
        >
        </mon-switch-button>

        <mon-link class="ml-1" [monHref]="monHref" monLinkText="{{ 'Help Center' | translate }}" ></mon-link>
    </div>

</mon-page-header>

<div class="d-flex justify-content-end align-items-center mb-2">
    <mon-date-picker
        [date]="(selectedDateRange$ | async) ?? {}"
        [monDateFormat]="dateFormat"
        (dateChange)="onSelectedDateRange($event)"
        class="events-date-picker"
    />
    <mon-export-button [exports]="exports" *ngIf="!(domain | isStatsDemoData)"></mon-export-button>
    <mon-table-search
        (getPage)="search$.next($event)"
        [model]="(search$ | async) ?? ''"
        [minSearchLength]="2"
    >
    </mon-table-search>
</div>
<div class="card panel-monsido-card">
    <div class="card-body">
        <mon-table-container-pager
            [collection]="rawEvents"
            class="table-vertial-align"
            [page]="getEventsParams$.value.page"
            [pageSize]="getEventsParams$.value.limit"
            [progress]="loading"
            (onPageChange)="onPageChange($event)"
            (onPerPageChange)="onPageSizeChange($event)"
        >
            <div class="table-container-body">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <ng-container *ngFor="let header of headers[(selectedTabIndex$ | async) || -1]; let i = index;">
                            <th *ngIf="i === 0"
                                class="col-sm-7 col-lg-9"
                                monTableSort
                                [monSortBy]="header.field"
                                [monSortDirection]="getEventsParams$.value.sortDirection"
                                [monCurrentlySortedBy]="getEventsParams$.value.sortBy"
                                [monStartAsc]="getEventsParams$.value.sortDirection === 'asc'"
                                (monOnSorted)="onSortEvents($event)"
                            >{{ header.columnName }}</th>
                            <th *ngIf="i !== 0"
                                [ngClass]="{'col-sm-7 col-lg-9': i === 0, 'col-29': i === 1, 'col-4 text-right': i === 2}"
                            >{{ header.columnName }}</th>
                        </ng-container>

                        <th class="col-sm-8 col-lg-6 text-right"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let event of rawEvents; let i = index;">
                            <tr>
                                <td>{{ event.label }}</td>
                                <td></td>
                                <td class="text-right">{{ event.event_count | simpleNumberLocaleFormat }}</td>
                                <td class="text-right">
                                    <button class="btn-no-style btn-mon-icon"
                                            [monTableSpoilerAccordion]="i">
                                        <mon-icon class="icon"
                                                  [monIconDirect]="'faChevronDown'">
                                        </mon-icon>
                                    </button>
                                </td>
                            </tr>
                            <ng-container *ngIf="selectedTab !== 'Actions'">
                                <tr [attr.data-hidden-row]="i" *ngFor="let eventEntry of event.event_actions; let j = index;" [ngClass]="{'first-expanded-row': j === 0 }">
                                    <td></td>
                                    <td>{{ eventEntry.label }}</td>
                                    <td class="text-right">{{ eventEntry.event_count | simpleNumberLocaleFormat }}</td>
                                    <td></td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="selectedTab === 'Actions'">
                                <tr [attr.data-hidden-row]="i" *ngFor="let eventEntry of event.event_names; let j = index;" [ngClass]="{'first-expanded-row': j === 0 }">
                                    <td></td>
                                    <td>{{ eventEntry.label }}</td>
                                    <td class="text-right">{{ eventEntry.event_count | simpleNumberLocaleFormat }}</td>
                                    <td></td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>

                </table>
            </div>
        </mon-table-container-pager>
    </div>

</div>
