import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { AgreementInterface } from '@monsido/ng2/modules/models/api/interfaces/agreement.interface';

@Injectable({
    providedIn: 'root',
})
export class AgreementRepoService {

    constructor (
        private apiService: ApiService,
    ) { }

    getAll (): Promise<AgreementInterface> {
        return this.apiService.get('agreements');
    }
}
