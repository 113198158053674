import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { ApiService } from '../api-service/api.service';

export type PdfRequestParamsType = {
    page: number;
    page_size: number;
    reviewed: boolean;
    sort_by: 'title';
    sort_dir: 'desc' | 'asc';
    type: 'pdf';
    clarity?: 'pending' | 'queued' | 'failed';
    group_id?: number;
}

export type PdfType = {
    id: number,
    domain_id: number,
    title: string,
    url: string,
    reviewed: boolean,
    content_length: number,
    pages_count: number,
    word_count: number,
    clarity_accessibility_errors_count: number,
    clarity_accessibility_checks_with_errors_count: number,
    clarity_tagged: number | null,
    clarity_queued_at: string | null,
    clarity_checked_at: string | null,
    clarity_remediated: boolean,
    created_at: string | null,
    standard: string | null
}

type GroupIdParamsType = {
    group_id?: number;
}

@Injectable({
    providedIn: 'root',
})
export class PdfRepoService {

    constructor (
        private sessionService: SessionService,
        private apiService: ApiService,
    ) { }

    get (params: PdfRequestParamsType): Promise<PdfType> {
        params.group_id = this.sessionService.getGroupId(params);
        return this.apiService.get('domains/' + this.sessionService.getDomainId(params) + '/accessibility/pdfs', params);
    }

    createClarity (pdf: PdfType): Promise<PdfType> {
        return this.apiService.post(
            'domains/' + this.sessionService.getDomainId() + '/accessibility/pdfs/' + pdf.id + '/clarity-report',
            undefined,
            undefined,
            this.makeGroupIdParam(),
        );
    }

    update (pdf: PdfType, data: Partial<PdfType>): Promise<void> {
        return this.apiService.patch('domains/' + this.sessionService.getDomainId() + '/accessibility/pdfs/' + pdf.id, data, this.makeGroupIdParam());
    }

    createRemediation (pdf: PdfType): Promise<void> {
        return this.apiService.post(
            'domains/' + this.sessionService.getDomainId() + '/accessibility/pdfs/' + pdf.id + '/clarity-remediation',
            undefined,
            undefined,
            this.makeGroupIdParam(),
        );
    }

    createAllClarity (): Promise<void> {
        return this.apiService.post('domains/' + this.sessionService.getDomainId() + '/accessibility/pdfs/clarity-report');
    }

    markAllPdfsAsReviewed (): Promise<void> {
        return this.apiService.post('domains/' + this.sessionService.getDomainId() + '/accessibility/pdfs/review-all');
    }

    requestRemeditionQuoteForAllPdfs (): Promise<void> {
        return this.apiService.post('domains/' + this.sessionService.getDomainId() + '/accessibility/pdfs/clarity-remediation');
    }

    private makeGroupIdParam (): GroupIdParamsType {
        const params: GroupIdParamsType = {};
        const groupId = this.sessionService.getGroupId();
        if (groupId !== undefined) {
            params.group_id = groupId;
        }
        return params;
    }
}
