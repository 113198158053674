(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsSummaryBubble', {
        templateUrl: 'app/pages/statistics/visitors/summary/charts/bubble/bubble.html',
        controller: StatisticsVisitorsSummarybubbleController,
        controllerAs: 'vm',
        bindings: {
            title: '@',
            linkText: '@',
            link: '@',
            startDate: '<?',
            endDate: '<?',
        },
    });

    StatisticsVisitorsSummarybubbleController.$inject = [
        'ng2StatsRepoService',
        'API_CONSTANTS',
        '$stateParams',
        'gettextCatalog',
    ];
    /* @ngInject */
    function StatisticsVisitorsSummarybubbleController (ng2StatsRepoService, API_CONSTANTS, $stateParams, gettextCatalog) {
        var vm = this;

        vm.$onChanges = update;

        function update () {
            vm.bubbleSeries = [
                gettextCatalog.getString('1st'),
                gettextCatalog.getString('2nd'),
                gettextCatalog.getString('3rd'),
            ];
            vm.bubbleLabels = [];
            vm.bubbleData = [];
            vm.bubbleOptions = {
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'point',
                    callbacks: {
                        label: function (tooltipItem) {
                            return (
                                vm.bubbleLabels[tooltipItem.datasetIndex] +
                                ': ' +
                                vm.pages[tooltipItem.datasetIndex].visits +
                                ' ' +
                                gettextCatalog.getString('visits')
                            );
                        },
                    },
                },
            };
            vm.bubbleColors = ['#3da3e9', '#89cdfd', '#3669d3'];
            getPage();
        }

        function getPage () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.startDate.format('YYYY-MM-DD'),
                to: vm.endDate.format('YYYY-MM-DD'),
            };
            vm.loading = true;
            vm.promise = ng2StatsRepoService.getResolutions(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    var maxVisits = data.reduce(function (a, b) {
                        return Math.max(a, parseInt(b.visits));
                    }, 0);
                    vm.bubbleLabels = [];
                    vm.bubbleData = [];
                    var label;
                    vm.pages.slice(0, 3).forEach(function (page) {
                        label = page.label || '';
                        vm.bubbleData.push([
                            {
                                x: parseInt(label.substring(0, label.indexOf('x'))),
                                y: parseInt(label.substring(label.indexOf('x') + 1, label.length)),
                                r: setRadius(page.visits, maxVisits),
                            },
                        ]);
                        vm.bubbleLabels.push(label);
                    });
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function setRadius (val, max) {
            val = parseInt(val);
            if (val > 0) {
                var maxRadiusOnChartElements = 25; // Pixel values
                if (val === max) {
                    return maxRadiusOnChartElements;
                } else {
                    return (val * maxRadiusOnChartElements) / max;
                }
            }
            return 0.00001;
        }
    }
})();
