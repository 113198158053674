import { Injectable } from '@angular/core';
import { PageModel } from '@client/app/models/page-model-factory.service';
import { HeatmapModel, HeatmapType } from '@client/app/services/api/heatmaps-repo/heatmaps-repo.service';
import { PageRepoService } from '@client/app/services/api/page-repo/page-repo.service';
import { CollectionInterface } from '@monsido/angular-shared-components/dist/angular-shared-components/lib/interfaces/collection.interface';

@Injectable({
    providedIn: 'root',
})
export class DemoHeatmapsRepoService {

    private rules: CollectionInterface<HeatmapModel> = [];
    private pk = 1;

    constructor (
        private pageRepoService: PageRepoService,
    ) {
        this.rules.total = 0;
    }

    destroy (domainId: number, ruleId: number): Promise<unknown> {
        return this.destroyData(domainId, ruleId);
    }

    create (domainId: number, params: HeatmapType): Promise<HeatmapModel> {
        return this.createData(domainId, params);
    }

    getAllFromCustomer (): Promise<CollectionInterface<HeatmapModel>> {
        return this.getData('getAllFromCustomer');
    }

    private getData (fnName: string): Promise<CollectionInterface<HeatmapModel>> {
        return new Promise((resolve) => {
            this.delay(() => {
                switch (fnName) {
                    case 'getAllFromCustomer':
                        resolve(this.getAllFromCustomerData());
                        break;
                    default:
                        resolve([]);
                }
            });
        });
    }


    private createData (domainId: number, model: HeatmapType): Promise<HeatmapModel> {
        return this.getPage(model.page_id, domainId).then((page) => {
            this.rules.push({
                id: this.pk,
                domain_id: domainId,
                page_id: model.page_id,
                heatmap_type: model.heatmap_type,
                page: {
                    id: page.id,
                    url: page.url,
                    title: page.title,
                    domain_id: domainId,
                    type: page.type,
                },
                updated_at: new Date().toISOString(),
                created_at: new Date().toISOString(),
            });
            this.rules.total = this.rules.length;
            this.pk++;
            return this.rules[this.rules.length - 1];
        });
    }

    private destroyData (domainId, ruleId): Promise<void> {
        return new Promise((resolve) => {
            this.delay(() => {
                const index = this.rules.findIndex((r) => r.domain_id === domainId && r.id === ruleId);
                if (index > -1) {
                    this.rules.splice(index, 1);
                }
                resolve();
            });
        });
    }

    private getAllFromCustomerData (): CollectionInterface<HeatmapModel> {
        return this.rules;
    }

    private delay (fn: () => void): void {
        const timer = Math.round(1000 * Math.random());
        setTimeout(() => {
            fn();
        }, timer);
    }

    private getPage (pageId: number, domainId: number): Promise<PageModel> {
        return this.pageRepoService.get(pageId, { domain_id: domainId });
    }
}
