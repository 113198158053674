(function () {
    'use strict';

    angular.module('pages.admin.auditlog').component('adminAuditlogMoreInfo', {
        templateUrl: 'app/pages/admin/auditlog/more/more.html',
        controller: AuditlogMoreInfoController,
        controllerAs: 'vm',
        bindings: {
            action: '<',
            data: '<',
            created: '<',
            target: '<',
            sourceCodeId: '<',
            domainId: '<',
            monDialogId: '@?',
        },
    });

    AuditlogMoreInfoController.$inject = [
        'ng2AccessibilityCheckNameService',
        'accessibilityService',
        'monDialog',
        'ng2ToastService',
        'gettextCatalog',
        'ng2AuditlogService',
        'ng2MonModalService',
    ];
    /* @ngInject */
    function AuditlogMoreInfoController (
        ng2AccessibilityCheckNameService,
        accessibilityService,
        monDialog,
        toastService,
        gettextCatalog,
        ng2AuditlogService,
        ng2MonModalService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.undoAccessibilityCheck = undoAccessibilityCheck;
        vm.IsAnUndoLog = IsAnUndoLog;
        vm.CanUndo = CanUndo;

        function activate () {
            vm.name = '';
            if (vm.data.check_id) {
                ng2AccessibilityCheckNameService.getName(vm.data.check_id).then(function (name) {
                    vm.name = name;
                }, angular.noop);
            }
        }

        function undoAccessibilityCheck () {
            var msg = '<div class=\'text-line\'>' + gettextCatalog.getString('Add comment (Optional)');
            ng2MonModalService.prompt(msg).then(function (comment) {
                var params = {
                    comment: comment,
                    ignored_reviewed: false,
                };

                accessibilityService.undoAccessibilityCheck(vm.domainId, vm.sourceCodeId, vm.data.check_id, params).then(function () {
                    toastService.success(gettextCatalog.getString('Snippet reset'));
                    monDialog.close(vm.monDialogId);
                });
            }, angular.noop);
        }

        function IsAnUndoLog () {
            return ng2AuditlogService.isAnUndoLog(vm.action);
        }

        function CanUndo () {
            return ng2AuditlogService.canUndo(vm.target);
        }
    }
})();
