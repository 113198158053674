import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationDuplicateH1', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/duplicate-h1/duplicate-h1.html',
        controller: SeoTranslationsDuplicateH1Controller,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTranslationsDuplicateH1Controller.$inject = ['ng2MonEventsService'];
    /* @ngInject */
    function SeoTranslationsDuplicateH1Controller (ng2MonEventsService) {
        var vm = this;
        vm.seePages = seePages;

        function seePages (headline) {
            var params = {
                body: 'pageDetailsSeoPagesDialog',
                size: 'lg',
                data: {
                    seo: vm.seo,
                    title: headline,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
