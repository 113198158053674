<mon-multiselect-next [size]="'small'"
                      [hideLabel]="true"
                      [placeholder]="'Choose domains' | translate"
                      name="domains"
                      [(ngModel)]="model"
                      [options]="domainsOptions"
                      [endScrollOffset]="15"
                      [optionTemplate]="dropdownOptionTemplate"
                      [selectedValueTemplate]="selectedValueTemplate"
                      [disableFuzzySearch]="true"
                      (scrollToEnd)="getMoreDomains()"
                      (searchInput)="searchDomains($event)"

>
</mon-multiselect-next>
<ng-template #dropdownOptionTemplate let-option="option">
    <div class="d-flex align-items-center text-ellipsis">
        <img *ngIf="option.value.type === 'Domain'"
             [attr.src]="'https://www.google.com/s2/favicons?domain=' + option.value.url"
             alt="logo"/>
        <span *ngIf="option.value.type === 'DomainGroup'" class="ml-2 fas fa-clone"></span>
        <span class="inline-block ml-1 text-ellipsis">
        {{ option.name }}
    </span>
    </div>
</ng-template>
<ng-template #selectedValueTemplate let-value="value" let-domainPickerValue="value.value">
    <div class="d-flex align-items-center text-ellipsis" *ngIf="value">
        <img *ngIf="domainPickerValue.type === 'Domain'"
             [attr.src]="'https://www.google.com/s2/favicons?domain=' + domainsInfoMap[domainPickerValue.id].url"
             alt="logo"/>
        <span *ngIf="domainPickerValue.type === 'DomainGroup'" class="fas fa-clone"></span>
        <span class="inline-block ml-1">
            {{ domainPickerValue.type === 'DomainGroup' ? domainGroupsInfoMap[domainPickerValue.id].title : domainsInfoMap[domainPickerValue.id].title }}
        </span>
    </div>
</ng-template>

