(function () {
    'use strict';

    angular.module('modules.export').factory('exporterAllParser', exporterAllParser);

    exporterAllParser.$inject = ['ng2HistoryHelperService', '$filter', 'gettextCatalog'];
    /* @ngInject */
    function exporterAllParser (ng2HistoryHelperService, $filter, gettextCatalog) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines () {
            return [
                gettextCatalog.getString('Crawled at'),
                gettextCatalog.getString('Pages with Accessibility Issues'),
                gettextCatalog.getString('Pages with SEO Opportunities'),
                gettextCatalog.getString('Pages with QA Issues'),
                gettextCatalog.getString('Pages with Policy Issues'),
                gettextCatalog.getString('Pages crawled'),
                gettextCatalog.getString('Documents crawled'),
                gettextCatalog.getString('Changes since previous crawl'),
            ];
        }

        function parse (data) {
            var csv = [];
            var entry = [];
            var issuesStack = ['page_count', 'documents_count'];
            csv.push(getHeadlines());

            for (var i = 0; i < data.length; i++) {
                entry = [];
                entry.push($filter('userDate')(data[i].post_processing_done_at || data[i].date, 'LL LT'));
                entry.push(data[i].pages_with_accessibility_errors_count);
                entry.push(data[i].pages_with_seo_errors_count);
                entry.push(data[i].pages_with_notifications_count);
                entry.push(data[i].pages_with_policy_matches_count);
                entry.push(data[i].page_count);
                entry.push(data[i].documents_count);
                entry.push(
                    getCaret(data[i].id, data, issuesStack) +
                        ' ' +
                        $filter('monNumber')(
                            ng2HistoryHelperService.getChangePercentageValue(data[i].id, data, issuesStack) / 100,
                            '0.[00]%',
                        ),
                );
                csv.push(entry);
            }
            return csv;
        }

        function getCaret (id, data, issuesStack) {
            var value = ng2HistoryHelperService.getChangeValue(id, data, issuesStack);
            if (value > 0) {
                return String.fromCharCode(94);
            } else if (value < 0) {
                return 'ˇ';
            }
            return '';
        }
    }
})();
