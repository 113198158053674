import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import {
    AccessibilityGuidelineInterface,
    AccessibilityResultTypeConstantType,
    AccessbilityGuidelineRequestParamsInterface,
} from '../../interfaces/acc-info-interface';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityGuidelinesInfoService {
    private cacheAlive = 43200000; // 12 hours
    private dataForCompatibility = {};
    private cachedGuidelineMap: Map<string, AccessibilityGuidelineInterface> = new Map();

    private requestingQueueMap: Map<string, Promise<AccessibilityGuidelineInterface[]>> = new Map();

    constructor (private client: ApiClient) {}

    public async get (requestAbbrs: string[] = []): Promise<Array<AccessibilityGuidelineInterface>> {
        const taskId = requestAbbrs.join('-');
        // API Calls memoize
        const requestingMap = this.requestingQueueMap.get(taskId);
        if (requestingMap) {
            return requestingMap;
        } else {
            const promise = async (): Promise<AccessibilityGuidelineInterface[]> => {
                const result: AccessibilityGuidelineInterface[] = [];
                const abbrsParam: string[] = [];

                for (let i = 0; i < requestAbbrs.length; i++) {
                    const abbr = requestAbbrs[i];
                    // Guidelines memoize
                    const cachedGuideline = this.cachedGuidelineMap.get(abbr);
                    if (cachedGuideline) {
                        result.push(cachedGuideline);
                    } else {
                        abbrsParam.push(abbr);
                    }
                }

                if (abbrsParam.length > 0 || requestAbbrs.length === 0) {
                    const params: AccessbilityGuidelineRequestParamsInterface = { 'abbrs[]': [] };
                    params['abbrs[]'] = abbrsParam || [];
                    const guidelines = (await this.client.getPromise<AccessibilityGuidelineInterface[]>('accessibility_guidelines', {
                        params,
                    }));
                    for (let i = 0; i < guidelines.length; i++) {
                        const guideline = guidelines[i];
                        this.cachedGuidelineMap.set(guideline.abbr, guideline);
                        result.push(guideline);

                        setTimeout(() => {
                            this.cachedGuidelineMap.delete(guideline.abbr);
                        }, this.cacheAlive);
                    }
                }

                this.dataForCompatibility = this.transformDataToOldFormat(Array.from(this.cachedGuidelineMap.values()));

                return result;
            };

            this.requestingQueueMap.set(
                taskId,
                promise().then((res) => {
                    this.requestingQueueMap.delete(taskId);
                    return res;
                }),
            );
            return this.requestingQueueMap.get(taskId) || [];
        }
    }

    /**
     * @deprecated NEVER USE THIS METHOD IN NEW PROJECTS!
     *  IT IS ONLY FOR SAKE OF COMPATIBILITY WITH OLD ACCESSIBILITY_HELPER.RESULT_TYPES.
     *  USE THE ASYNCHRONOUS METHOD “get” INSTEAD!
     */
    public retrieveSynchronousData (): unknown {
        return this.dataForCompatibility;
    }

    /**
     * @deprecated NEVER USE THIS METHOD IN NEW PROJECTS!
     *  IT IS ONLY FOR SAKE OF COMPATIBILITY WITH OLD ACCESSIBILITY_HELPER.RESULT_TYPES.
     *  USE THE ASYNCHRONOUS METHOD “get” INSTEAD!
     */
    private transformDataToOldFormat (
        accGuideLinesInfo: Array<AccessibilityGuidelineInterface>,
    ): Record<string, AccessibilityResultTypeConstantType> {
        const result: Record<string, AccessibilityResultTypeConstantType> = {};

        for (const guideline of accGuideLinesInfo) {
            const data: AccessibilityResultTypeConstantType = {};

            for (const level of guideline.levels) {
                data[level.name] = {
                    total: level.total,
                    error: level.error,
                    warning: level.warning,
                    review: level.review,
                };
            }
            result[guideline.abbr] = data;
        }
        return result;
    }
}
