(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionInventoryIncominglinks', {
        templateUrl: 'app/components/pagedetails/details/sections/inventory/subsections/incominglinks/links.html',
        controller: SectionQpController,
        controllerAs: 'vm',
        bindings: {
            pageObj: '<',
        },
    });

    SectionQpController.$inject = ['$controller', 'ng2PageRepoService', 'gettextCatalog', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function SectionQpController ($controller, ng2PageRepoService, gettextCatalog, ng2ActiveFeatureService) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.pageObj = vm.pageObj || {};
            vm.links = [];
            vm.activeTab = '0';
            vm.search = null;
            setupTabs();
            getPage();
            setExports(vm.pageObj.id);
        }

        function setupTabs () {
            vm.tabOptions = [
                {
                    name: gettextCatalog.getString('Links from own domain'),
                    value: '0',
                },
                {
                    name: gettextCatalog.getString('Links from other account domains'),
                    value: '1',
                },
            ];
        }

        function getPage () {
            vm.selected = [];
            var params = {
                page_size: vm.pageSize,
                page: vm.page,
                all: vm.activeTab,
                search: vm.search,
            };

            vm.promise = ng2PageRepoService.getIncomingLinks(vm.pageObj.id, params).then(function (data) {
                vm.links = data;
                setupTabs();
                vm.tabOptions[vm.activeTab].name += ' (' + vm.links.total + ')';
            }, angular.noop);
        }

        function setExports (pageId) {
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'incoming_links',
                        category_ref: pageId,
                    },
                },
            ];
        }
    }
})();
