(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationInformation', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/information.html',
        controller: SectionSeoInformationController,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
            page: '<',
        },
    });

    SectionSeoInformationController.$inject = [];

    function SectionSeoInformationController () {
        var vm = this;
        vm.isArray = isArray;

        function isArray (obj) {
            return Array.isArray(obj);
        }
    }
})();
