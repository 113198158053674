import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

export type HelpCenterInfoType = {
    description: string;
    how_to_mitigate: string;
}

@Injectable({
    providedIn: 'root',
})
export class HelpCenterRepoService {

    constructor (
        private apiService: ApiService,
    ) { }

    get (params: RequestParamsType): Promise<HelpCenterInfoType[]> {
        params = params || {};
        return this.apiService.get('info_types', params);
    }
}
