(function () {
    'use strict';
    angular.module('modules.chat.velaro').component('monVelaro', {
        transclude: true,
        templateUrl: 'app/modules/chat/velaro/components/icon/velaro.html',
        controller: VelaroController,
        controllerAs: 'vm',
    });

    VelaroController.$inject = ['$window'];

    function VelaroController ($window) {
        var vm = this;
        vm.toggle = toggle;

        function toggle () {
            if ($window.Velaro && $window.Velaro.Engagement) {
                $window.Velaro.Engagement.ToggleInline();
            }
        }
    }
})();
