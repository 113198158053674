import { Injectable } from '@angular/core';
import {
    CookieBannerSettingsType,
    FormDomainScriptCookieBannerModel,
} from '@client/app/forms/domain-script-guide/models/cookie-banner/form-domain-script-cookie-banner-model';
import { COOKIE_BANNER_SETTINGS } from '@client/app/modules/cookie-banner/constants/cookie-banner-settings';
import { COOKIE_BANNER_I18N } from '@monsido/modules/cookie-banner/constants/cookie-banner-i18n.constant';
import { WIDGET_SETTINGS } from '@client/app/core/constants/widget-settings';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { CONSENT_MANAGER_PRIVACY_REGULATION_OPTIONS } from '@client/app/forms/domain-script-guide/features/consent-manager/consent-manager.constant';
import { RegulationSettings } from '@client/app/forms/domain-script-guide/features/consent-manager/consent-manager-settings-options.interface';


@Injectable({
    providedIn: 'root',
})
export class FormDomainScriptCookieBannerService {
    private readonly defaultSettings: CookieBannerSettingsType;
    private readonly privacyRegulations: RegulationSettings[];

    constructor (private translateService: TranslateService) {
        this.privacyRegulations = CONSENT_MANAGER_PRIVACY_REGULATION_OPTIONS.map(option => {
            return {
                name: this.translateService.getString(option.name),
                value: option.value,
                tooltip: this.translateService.getString(option.tooltip),
            };
        });
        this.defaultSettings = {
            enabled: true,
            direction: COOKIE_BANNER_SETTINGS.DIRECTION.BOTTOMRIGHT,
            privacyRegulation: this.privacyRegulations[0].value as string,
            cookie_policy_url: '',
            optOutUrl: '',
            cookies_overview: [],
            per_category_consent: true,
            hasOverlay: false,
            explicit_reject_option: false,
            enable_iab_and_consent_fw: false,
            support_google_consent_mode: false,
            icon_picture_url: COOKIE_BANNER_SETTINGS.ICON.COOKIE,
            icon_shape: WIDGET_SETTINGS.ICON_SHAPE.CIRCLE,
            included_languages: [COOKIE_BANNER_I18N.EN_US],
            defaultLanguage: COOKIE_BANNER_I18N.EN_US,
            theme: {
                buttonColor: COOKIE_BANNER_SETTINGS.BUTTON_COLOR,
                buttonTextColor: COOKIE_BANNER_SETTINGS.BUTTON_TEXT_COLOR,
            },
        };
    }

    getFormDomainScriptCookieBannerModel (settings: Partial<CookieBannerSettingsType>): FormDomainScriptCookieBannerModel {
        return new FormDomainScriptCookieBannerModel(settings, this.defaultSettings);
    }

}
