(function () {
    'use strict';

    angular.module('modules.modals.directives').directive('monModalConfirm', monModalConfirm);

    monModalConfirm.$inject = ['ng2MonModalService', 'gettextCatalog'];

    /* @ngInject */
    function monModalConfirm (ng2MonModalService, gettextCatalog) {
        return {
            restrict: 'A',
            scope: {
                actionOK: '&monModalConfirmAction',
                actionCancel: '&monModalConfirmActionCancel',
            },
            link: function (scope, element, attr) {
                var msg = attr.monModalConfirm || gettextCatalog.getString('Are you sure?');
                element.bind('click', function () {
                    ng2MonModalService.confirm(msg).then(
                        function () {
                            scope.actionOK();
                        },
                        function () {
                            scope.actionCancel();
                        },
                    );
                });
            },
        };
    }
})();
