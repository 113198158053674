import { NgModule } from '@angular/core';
import { PagesAdminModule } from '@monsido/pages/admin/admin.module';
import { PagesGlobalModule } from '@monsido/pages/global/global.module';
import { PagesDomainModule } from '@monsido/pages/domain/domain.module';

@NgModule({
    imports: [PagesAdminModule, PagesGlobalModule, PagesDomainModule],
    exports: [],
    declarations: [],
    providers: [],
})
export class PagesModule {}
