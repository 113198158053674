import { PaScriptSettingsOptionsInterface } from './pa-script-settings-options.interface';
import { PAGE_ASSIST_SCRIPT_CONSTANT } from './pa-script.constant';
export class PaScriptSettingsModel {
    theme: string = PAGE_ASSIST_SCRIPT_CONSTANT.THEME.LIGHT;
    title: string = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.title;
    enabled: boolean = false;
    // eslint-disable-next-line camelcase
    skip_to: boolean = false;
    greeting: string = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.greeting;
    language: string = '';
    direction: string = PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.TOPLEFT;
    linkColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.LINK.LIGHT;
    linkColorDark: string = PAGE_ASSIST_SCRIPT_CONSTANT.LINK.DARK;
    mainColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.mainColor;
    textColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.textColor;
    mainDarkColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.mainDarkColor;
    textDarkColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.textDarkColor;
    buttonHoverColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.BUTTON_HOVER_COLOR.LIGHT;
    buttonHoverDarkColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.BUTTON_HOVER_COLOR.DARK;
    mainButtonBorderColor: string = '';
    // eslint-disable-next-line camelcase
    icon_shape: string = PAGE_ASSIST_SCRIPT_CONSTANT.ICON_SHAPE.CIRCLE;
    // eslint-disable-next-line camelcase
    main_color: string = '';
    // eslint-disable-next-line camelcase
    text_color: string = '';
    // eslint-disable-next-line camelcase
    title_text: string = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.title_text;
    alwaysOnTop: boolean = false;
    coordinates: string = PAGE_ASSIST_SCRIPT_CONSTANT.COORDINATES.LEFTTOP;
    pixelsOnTop: string = '';
    pixelsOnLeft: string = '';
    pixelsOnRight: string = '';
    pixelsOnBottom: string = '';
    // eslint-disable-next-line camelcase
    icon_picture_url: string = PAGE_ASSIST_SCRIPT_CONSTANT.ICON.DEFAULT;
    // eslint-disable-next-line camelcase
    logo_picture_url: string = '';
    // eslint-disable-next-line
    included_languages: any = []; // TODO: https://optimere.atlassian.net/browse/MON-2233
    // included_languages: string[];
    // eslint-disable-next-line camelcase
    logo_picture_base64: string = '';
    customInformationText: string = '';
    customInformationTextEnabled: boolean = false;
    switchAccessibilityStatementURL: boolean = false;
    accessibilityStatementURL: string = '';
    mainButtonBorderColorEnabled: boolean = false;

    constructor (options: PaScriptSettingsOptionsInterface) {

        Object.keys(options).forEach((k: keyof PaScriptSettingsOptionsInterface) => {
            this[k] = options[k] as never;
        });
    }
}
