
const difficulity = {
    VERY_EASY: 'very easy',
    EASY: 'easy',
    MEDIUM: 'medium',
    HARD: 'hard',
    VERY_HARD: 'very hard',
    NA: 'na',
};

const fleschKincaid = {
    below_fifth_grade: 1,
    fifth_grade: 5,
    sixth_grade: 6,
    seventh_grade: 7,
    eights_grade: 8,
    tenth_grade: 10,
    college: 15,
    college_grad: 17,
    test_name: -1,
    na: 'na',
};

const reasons = {
    language_not_supported: 'language_not_supported',
    other: 'other',
};

export const READABILITY_HELPER = {
    VERY_EASY: difficulity.VERY_EASY,
    EASY: difficulity.EASY,
    MEDIUM: difficulity.MEDIUM,
    HARD: difficulity.HARD,
    VERY_HARD: difficulity.VERY_HARD,
    NA: difficulity.NA,
    below_fifth_grade: 1,
    fifth_grade: 5,
    sixth_grade: 6,
    seventh_grade: 7,
    eights_grade: 8,
    tenth_grade: 10,
    college: 15,
    college_grad: 17,
    test_name: -1,
    lix: [
        difficulity.VERY_EASY,
        difficulity.EASY,
        difficulity.MEDIUM,
        difficulity.HARD,
        difficulity.VERY_HARD,
        difficulity.NA,
    ],
    flesch_kincaid_re: [
        fleschKincaid.below_fifth_grade,
        fleschKincaid.fifth_grade,
        fleschKincaid.sixth_grade,
        fleschKincaid.seventh_grade,
        fleschKincaid.eights_grade,
        fleschKincaid.tenth_grade,
        fleschKincaid.college,
        fleschKincaid.college_grad,
    ],
    na_reasons: {
        flesch_kincaid_re: [
            reasons.other,
            reasons.language_not_supported,
        ],
    },
    weights: {
        lix: {
            [difficulity.VERY_EASY]: 0,
            [difficulity.EASY]: 1,
            [difficulity.MEDIUM]: 2,
            [difficulity.HARD]: 3,
            [difficulity.VERY_HARD]: 4,
            [difficulity.NA]: 5,
        },
        flesch_kincaid_re: {
            [fleschKincaid.below_fifth_grade]: 1,
            [fleschKincaid.fifth_grade]: 2,
            [fleschKincaid.sixth_grade]: 3,
            [fleschKincaid.seventh_grade]: 4,
            [fleschKincaid.eights_grade]: 5,
            [fleschKincaid.tenth_grade]: 6,
            [fleschKincaid.college]: 7,
            [fleschKincaid.college_grad]: 8,
            [fleschKincaid.na]: 9,
            [fleschKincaid.test_name]: 10,
        },
    },
} as const;

