(function () {
    'use strict';

    angular.module('app.components.pages').component('pagesFiles', {
        templateUrl: 'app/components/pages/files/files.html',
        controller: PagesFilesController,
        controllerAs: 'vm',
        bindings: {
            type: '<',
            lookFor: '<?',
            domainId: '<',
            singularName: '@?',
            icon: '<',
            status: '<?',
        },
    });

    PagesFilesController.$inject = ['ng2LinksRepoService', 'ng2MonEventsService', '$controller', 'ng2SessionService', 'gettextCatalog', 'MON_EVENTS'];
    /* @ngInject */
    function PagesFilesController (ng2LinksRepoService, ng2MonEventsService, $controller, ng2SessionService, gettextCatalog, MON_EVENTS) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.openDialog = openDialog;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.scanDocuments = ng2SessionService.domain.scan.scan_documents;
            vm.links = [];
            vm.links.total = 0;
            if (vm.singularName) {
                vm.singularName = vm.singularName.toLowerCase();
            } else {
                vm.singularName = vm.type.name.substring(0, vm.type.name.length - 1).toLowerCase();
            }
            vm.search = '';
            vm.status = vm.status || null;
            vm.activeTab = vm.scanDocuments ? 'internal' : null;

            vm.exports = {
                pdf: [
                    {
                        name: gettextCatalog.getString('Export both internal and external links'),
                        data: {
                            category: 'inventory_pdf_links',
                        },
                    },
                    {
                        name: gettextCatalog.getString('Export only internal links'),
                        data: {
                            category: 'inventory_pdf_links',
                            category_ref: 'internal',
                        },
                    },
                    {
                        name: gettextCatalog.getString('Export only external links'),
                        data: {
                            category: 'inventory_pdf_links',
                            category_ref: 'external',
                        },
                    },
                ],
            };

            setupTabs();
            getPage();
        }

        function setupTabs () {
            if (!vm.scanDocuments) {
                vm.tabs = [];
                return;
            }
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Internal'),
                    value: 'internal',
                },
                {
                    name: gettextCatalog.getString('External'),
                    value: 'external',
                },
            ];
        }

        function getPage () {
            var params = {
                type: vm.type.type || null,
                status: vm.status,
                relation: vm.activeTab,
                page_size: vm.pageSize,
                page: vm.page,
                search: vm.search || null,
            };

            vm.promise = ng2LinksRepoService.getAll(params).then(function (data) {
                vm.links = data;
            }, angular.noop);
        }

        function openDialog (link, type) {
            var params = {
                body: 'pagesFilesPages',
                size: 'lg',
                data: { link: link, domainId: vm.domain_id, active: vm.lookFor, type: type },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
