import { Page } from './page.interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPage (page: any): page is Page {
    if (!page) {
        return false;
    }
    return 'data_protection_violations_counts' in page &&
        'accessibility_checks_with_errors_count' in page;
}
