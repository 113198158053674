import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';
import { MonRunLoadingService } from '@monsido/services/loading/run/run-loading.service';
import { MonOverlaySpinner } from '@monsido/services/loading/component/loading-spinner.component';
import { OverlayModule } from '@angular/cdk/overlay';

export function init_app (appLoadService: MonRunLoadingService) {
    return (): Promise<unknown> => appLoadService.initializeApp();
}

@NgModule({
    imports: [OverlayModule],
    exports: [],
    declarations: [MonOverlaySpinner],
    providers: [
        MonLoadingService,
        MonRunLoadingService,
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [MonRunLoadingService],
            multi: true,
        },
    ],
})
export class LoadingModule {}
