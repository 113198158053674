import { NgModule } from '@angular/core';
import { MonCookieOverviewLanguageTabs } from './components/cookie-overview-language-tabs.component';
import { CommonModule } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { BaseLayoutModule } from '@layout/layout.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [CommonModule, TranslateModule, NgClickOutsideDirective, BaseLayoutModule],
    declarations: [MonCookieOverviewLanguageTabs],
    exports: [MonCookieOverviewLanguageTabs],
})
export class MonCookieBannerModule {}
