(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name AccessibilityCheckService
     * @description Service to handle Accessibility Check endpoints
     */
    angular.module('services.entities').factory('AccessibilityCheckService', AccessibilityCheckService);

    AccessibilityCheckService.$inject = ['ng2GlobalAccessibilityChecksRepoService'];
    /* @ngInject*/
    function AccessibilityCheckService (ng2GlobalAccessibilityChecksRepoService) {
        return {
            getAll: getAll,
            update: update,
        };

        /**
         * @memberOf AccessibilityCheckService
         * @desc get Accessibility Checks
         * @param {Object} params the query parameters to add to the request
         * @param {Object} config The $http configurations to use in the request
         * @returns {Promise}
         */
        function getAll (params, config) {
            return ng2GlobalAccessibilityChecksRepoService.getAll(params, config);
        }

        /**
         * @memberOf AccessibilityCheckService
         * @desc get Accessibility Checks
         * @param {Object} check The Accessibility Check Object
         * @param {Object} params the query parameters to add to the request
         * @param {Object} config The $http configurations to use in the request
         * @returns {Promise}
         */
        function update (check) {
            return ng2GlobalAccessibilityChecksRepoService.update(check);
        }
    }
})();
