(function () {
    'use strict';
    angular.module('blocks.service').factory('complianceAccessibilityService', complianceAccessibilityService);

    complianceAccessibilityService.$inject = ['ng2SessionService', '$filter'];
    /* @ngInject*/
    function complianceAccessibilityService (ng2SessionService, $filter) {
        return {
            getPercentageFromResultType: getPercentageFromResultType,
            getChecksCountByResultType: getChecksCountByResultType,
            getTotalPercentageCountByResultType: getTotalPercentageCountByResultType,
        };

        /**
         *
         * @param resultType
         * @param pageCountAttr
         * @param accessibilityChecks
         * @param summary
         * @returns {number}
         */
        function getPercentageFromResultType (resultType, pageCountAttr, accessibilityChecks, summary) {
            var resultTypeAr = [];
            switch (resultType) {
                case 'errors':
                    resultTypeAr = ['error'];
                    break;
                case 'errors_and_warnings':
                    resultTypeAr = ['error', 'warning'];
                    break;
                default:
                    resultTypeAr = [];
            }

            var pageErrors = getTotalPercentageCountByResultType(resultTypeAr, pageCountAttr, accessibilityChecks, summary);
            var checks = getChecksCountByResultType(resultTypeAr, accessibilityChecks);
            var result = 100 - pageErrors / checks;

            if (isNaN(result)) {
                return 0;
            } else {
                return result;
            }
        }

        /**
         *
         * @param resultTypes
         * @param accessibilityChecks
         * @returns {*}
         */
        function getChecksCountByResultType (resultTypes, accessibilityChecks) {
            var checks = $filter('accessibilityChecksByResultTypes')(accessibilityChecks, {
                result_types: resultTypes,
            });
            return checks.length;
        }

        /**
         *
         * @param resultTypes
         * @param pageCountAttr
         * @param accessibilityChecks
         * @param summary
         * @returns {*}
         */
        function getTotalPercentageCountByResultType (resultTypes, pageCountAttr, accessibilityChecks, summary) {
            pageCountAttr = pageCountAttr || 'pages';
            var params = {
                result_types: resultTypes,
                only_errors: true,
            };
            var checks = $filter('accessibilityChecksByResultTypes')(accessibilityChecks, params);
            return checks.reduce(function (num, check) {
                return num + (parseInt(check.pages_count) / summary[pageCountAttr]) * 100;
            }, 0);
        }
    }
})();
