(function () {
    'use strict';

    angular.module('modules.globalPolicy').component('policyExchangeCenterLabels', {
        templateUrl: 'app/modules/global-policy/exchange-center/label-selector-labels/label-selector-labels.html',
        controller: PolicyExchangeCenterLabelSelectorLabelsController,
        controllerAs: 'vm',
        bindings: {
            labels: '<',
            onCloseLabel: '&',
        },
    });

    PolicyExchangeCenterLabelSelectorLabelsController.$inject = ['gettextCatalog'];

    /* @ngInject */
    function PolicyExchangeCenterLabelSelectorLabelsController (gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onChanges = $onChanges;
        vm.getLabelCloseTranslation = getLabelCloseTranslation;
        vm.labelItems = [];

        function activate () {}

        function getLabelCloseTranslation (groupName, labelName) {
            var scopes = {
                groupName: groupName,
                labelName: labelName,
            };
            return gettextCatalog.getString('Close {{groupName}}: {{labelName}}', scopes);
        }

        function $onChanges (changes) {
            var labelsValue = (changes.labels && changes.labels.currentValue) || undefined;
            if (labelsValue) {
                Object.keys(labelsValue).forEach(function (key) {
                    vm.labelItems.push({
                        labelGroup: key,
                        items: labelsValue[key],
                    });
                });
            }
        }
    }
})();
