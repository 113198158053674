(function () {
    'use strict';

    angular.module('plugins.scroll').factory('smartScroll', smartScroll);

    smartScroll.$inject = ['Zenscroll', '$timeout'];
    /* @ngInject*/
    function smartScroll (Zenscroll, $timeout) {
        var services = {
            scrollTo: scrollTo,
        };

        return services;

        function scrollTo (containerElement, sectionID, offsetTop) {
            $timeout(function () {
                var defaultDuration = 500;
                var edgeOffset = 30;
                var scroller = Zenscroll.createScroller(containerElement, defaultDuration, edgeOffset);
                var target = document.getElementById(sectionID);
                offsetTop = offsetTop || 0;
                scroller.toY(cumulativeOffset(target).top - offsetTop);
            });
        }

        function cumulativeOffset (element) {
            var top = 0;
            var left = 0;
            while (element) {
                top += element.offsetTop || 0;
                left += element.offsetLeft || 0;
                element = element.offsetParent;
            }

            return {
                top: top,
                left: left,
            };
        }
    }
})();
