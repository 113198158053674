(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicySettings', {
        templateUrl: 'app/forms/global-policy/steps/rules/boxes/settings/settings.html',
        controller: PolicySettingsController,
        controllerAs: 'vm',
        bindings: {
            policy: '=',
        },
    });

    PolicySettingsController.$inject = [
        'gettextCatalog',
        'ng2SessionService',
    ];
    /* @ngInject */
    function PolicySettingsController (
        gettextCatalog,
        ng2SessionService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.selectAllTags = selectAllTags;
        vm.checkSelectedTags = checkSelectedTags;
        vm.setPolicyTargets = setPolicyTargets;

        function activate () {
            vm.policy.priority = vm.policy.priority || 'low';
            vm.policy.mode = vm.policy.mode || 'violation';
            if (typeof vm.policy.scheduled !== 'boolean') {
                vm.policy.scheduled = true;
            }
            vm.policy.tags = vm.policy.tags || [];
            if (vm.policy.target_all_domains !== 'false' && vm.policy.target_all_domains !== 'true') {
                vm.policy.target_all_domains = Boolean(vm.policy.target_all_domains).toString();
            }

            if (!vm.policy.targets) {
                setupPredefinedTarget();
            }

            setupPriorityOptions();
            setupDomainOptions();
            setupTagOptions();
            checkSelectedTags();
            if (!vm.areAllTagsSelected && vm.policy.tags.length === 0) {
                selectAllTags();
            }
        }

        function setupPriorityOptions () {
            vm.policyPriorityOptions = [
                {
                    name: gettextCatalog.getString('Low'),
                    value: 'low',
                },
                {
                    name: gettextCatalog.getString('Medium'),
                    value: 'medium',
                },
                {
                    name: gettextCatalog.getString('High'),
                    value: 'high',
                },
            ];
        }

        function setupDomainOptions () {
            vm.domainOptions = [
                {
                    name: gettextCatalog.getString('Global - All Domains'),
                    value: 'true',
                },
                {
                    name: gettextCatalog.getString('Choose domains'),
                    value: 'false',
                },
            ];
        }

        function setupTagOptions () {
            vm.tagOptions = [
                {
                    name: gettextCatalog.getString('Quality Assurance'),
                    value: 'qa',
                },
                {
                    name: gettextCatalog.getString('Accessibility'),
                    value: 'accessibility',
                },
                {
                    name: gettextCatalog.getString('SEO'),
                    value: 'seo',
                },
            ];
        }

        function selectAllTags () {
            if (vm.policy.tags.length === vm.tagOptions.length) {
                vm.policy.tags = [];
                vm.areAllTagsSelected = false;
            } else {
                vm.policy.tags = vm.tagOptions.map(function (entry) {
                    return entry.value;
                });
                vm.areAllTagsSelected = true;
            }
        }

        function checkSelectedTags () {
            vm.areAllTagsSelected = vm.tagOptions.length > 0 && vm.tagOptions.length === vm.policy.tags.length;
        }

        function setPolicyTargets (payload) {
            vm.policy.targets = payload;
        }

        function setupPredefinedTarget () {
            if (angular.isObject(ng2SessionService.domainGroup)) {
                vm.policy.targets = [
                    {
                        type: 'DomainGroup',
                        id: ng2SessionService.domainGroup.id,
                    },
                ];
            } else if (angular.isObject(ng2SessionService.domain)) {
                vm.policy.targets = [
                    {
                        type: 'Domain',
                        id: ng2SessionService.domain.id,
                    },
                ];
            }
        }
    }
})();
