import { Injectable } from '@angular/core';
import { LinkModel } from '@client/app/models/link.model';
import { LinkType } from '@client/app/models/pages/page-link.model';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { CancelerType } from '@monsido/ng2/external/http/http-client.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

export type LinksGetAllRequestParams = BaseRepoRequestType & {
    type?: string,
    status?: string,
    relation?: string,
    accessibility_reviewed?: boolean,
}

export type OriginalUrlsType = {
    url: string
}

@Injectable({
    providedIn: 'root',
})
export class LinksRepoService extends BaseRepo {
    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
    ) {
        super();
    }

    async getAll (params: LinksGetAllRequestParams = {}): Promise<MonTableCollection<LinkModel>> {
        params = this.setGroupId(params);
        const route = `domains/${this.getDomainId()}/links`;

        return this.apiService
            .get<MonTableCollection<LinkType>>(route, params)
            .then(res => this.toModels(res));
    }

    getAllObservable (params: RequestParamsType = {}, canceler$: CancelerType): Observable<MonTableCollection<LinkModel>> {
        params = this.setGroupId(params);
        const route = `domains/${this.getDomainId()}/links`;

        return this.apiService
            .getObservable<MonTableCollection<LinkType>>(route, params, {}, canceler$)
            .pipe(map(res => this.toModels(res)));
    }

    async get (id: number): Promise<LinkModel> {
        return this.apiService
            .get<LinkType>(`domains/${this.getDomainId()}/links/${id}`)
            .then(res => this.toModel(res));
    }

    async update (link: Partial<LinkType>, params = {}): Promise<LinkModel> {
        params = this.setGroupId(params);
        return this.apiService
            .patch<LinkType>(`domains/${this.getDomainId()}/links/${link.id}`, link, params)
            .then(res => this.toModel(res));
    }

    async getAllPageLinks (params: BaseRepoRequestType = {}): Promise<MonTableCollection<LinkModel>> {
        params = this.setGroupId(params);

        return this.apiService
            .get<MonTableCollection<LinkType>>(`domains/${this.getDomainId()}/page-links`, params)
            .then(res => this.toModels(res));
    }

    async getOriginalUrls (link: Partial<LinkType>, params = {}): Promise<MonTableCollection<OriginalUrlsType>> {
        params = this.setGroupId(params);
        return this.apiService
            .get<MonTableCollection<OriginalUrlsType>>(`domains/${this.getDomainId()}/links/${link.id}/original-urls`, params);
    }

    private toModels (models: MonTableCollection<LinkType>): MonTableCollection<LinkModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }

        return models as MonTableCollection<LinkModel>;
    }

    private toModel (model: LinkType): LinkModel {
        return new LinkModel(model);
    }
}
