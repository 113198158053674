import { STATISTICS } from '@monsido/modules/statistics/statistics.constant';
import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsPagesWithoutVisits', {
        templateUrl: 'app/pages/statistics/pages/without-visits/without-visits.html',
        controller: StatisticsPagesWithoutVisits,
        controllerAs: 'vm',
    });

    StatisticsPagesWithoutVisits.$inject = [
        'ng2StatsRepoService',
        '$stateParams',
        'ParamService',
        '$controller',
        'ng2SessionService',
        'gettextCatalog',
        'dateRangeService',
        'ng2MonModalService',
        'ng2StatisticsDateComparisonService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsPagesWithoutVisits (
        ng2StatsRepoService,
        $stateParams,
        ParamService,
        $controller,
        ng2SessionService,
        gettextCatalog,
        dateRangeService,
        ng2MonModalService,
        ng2StatisticsDateComparisonService,
        DATE_FORMAT,
    ) {
        const vm = this;
        const MAX_PAGE_SIZE_FOR_COMPARISON = 10;
        const dateFormat = STATISTICS.DATE_FORMAT;

        vm.updateDateRange = updateDateRange;
        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.validateDate = ng2StatisticsDateComparisonService.validateDate;
        vm.onComparisonRangeSelected = onComparisonRangeSelected;
        vm.updateCompareDate = updateCompareDate;
        vm.noResultsMsg = gettextCatalog.getString('No pages found');
        vm.noResultsMsgSuggestion = gettextCatalog.getString(
            'No pages were found. Try searching for the page URL instead of the page title.',
        );
        vm.noPagesResultMessage = vm.noResultsMsg;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.domain = ng2SessionService.domain;
            vm.onPageChangeLocal = onPageChangeLocal;
            vm.onPageSizeChangeLocal = onPageSizeChangeLocal;
            vm.totalVisits = 0;
            vm.summary = {};
            vm.loading = false;
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;
            vm.compareDate = { startDate: null, endDate: null };
            vm.onSearch = onSearch;
            vm.search = '';

            vm.exports = [
                {
                    name: gettextCatalog.getString('Export both pages and documents'),
                    type: 'all',
                    data: {
                        category: 'pages_without_visits',
                        category_ref: setExportCategoryRef('all'),
                    },
                },
                {
                    name: gettextCatalog.getString('Export only html pages without visits'),
                    type: 'html',
                    data: {
                        category: 'pages_without_visits',
                        category_ref: setExportCategoryRef('html'),
                    },
                },
                {
                    name: gettextCatalog.getString('Export only documents without downloads'),
                    type: 'document',
                    data: {
                        category: 'pages_without_visits',
                        category_ref: setExportCategoryRef('document'),
                    },
                },
            ];
        }


        function getPage () {
            const params = {
                limit: vm.pageSize,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format(dateFormat),
                to: vm.date.endDate?.format(dateFormat),
                search: vm.search,
            };

            vm.loading = true;

            vm.promise = ng2StatsRepoService.getPagesWithoutVisits(params, contextTokens.NO_PARSE_SEARCH)
                .then((data) => {
                    vm.pages = data;
                    vm.pages.currentPage = vm.page;
                    vm.totalVisits = vm.pages.reduce((total, page) => total + parseInt(page.visits), 0);
                    vm.pages.sort((a, b) => b.visits - a.visits);
                    vm.pageUrls = vm.pages.map(({ url }) => url);
                    setNoSearchResultsMsgs(vm.pages, vm.search);
                }, angular.noop)
                .finally(() => {
                    vm.loading = false;
                });
        }

        function onSearch (search) {
            vm.search = search;
            vm.page = 1;
            if (vm.comparisonEnabled && vm.date && vm.compareDate) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }


        async function onComparisonRangeSelected (currentStartDate, currentEndDate, compareStartDate, compareEndDate) {
            vm.loading = true;

            const currentDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: currentStartDate?.format(dateFormat),
                to: currentEndDate?.format(dateFormat),
                search: vm.search,
            };

            const compareDataRequestParams = {
                domainId: $stateParams.domainId,
                from: compareStartDate?.format(dateFormat),
                to: compareEndDate?.format(dateFormat),
            };

            const currentPages = await ng2StatsRepoService.getPagesWithoutVisits(
                currentDataRequestParams,
                contextTokens.NO_PARSE_SEARCH,
            );

            let comparePages = [];
            if (currentPages.length) {
                comparePages = await ng2StatsRepoService.getPagesByUrl(
                    { ...compareDataRequestParams, urls: currentPages.map(({ url }) => url) },
                );
            }

            const currentPagesMap = new Map();

            for (let i = 0; i < currentPages.length; i++) {
                const page = currentPages[i];
                const computedPage = {
                    ...page,
                    compare: {
                        visits: page.compare?.visits || 0,
                    },
                };

                currentPagesMap.set(page.url, computedPage);
            }

            for (let i = 0; i < comparePages.length; i++) {
                const comparePage = comparePages[i];
                const existedPageInMap = currentPagesMap.get(comparePage.url);

                if (existedPageInMap) {
                    existedPageInMap.compare = comparePage;
                }
            }

            vm.pages = Array.from(currentPagesMap.values());
            vm.pages.currentPage = currentPages.currentPage;
            vm.pages.pageHits = currentPages.pageHits;
            vm.pages.perPage = currentPages.perPage;
            vm.pages.total = currentPages.total;
            setNoSearchResultsMsgs(vm.pages, vm.search);

            vm.loading = false;
        }

        function setNoSearchResultsMsgs (results, searchTerm) {
            if (!results.length && !searchTerm) {
                return vm.noPagesResultMessage = noResultsMsg;
            }

            if (!results.length && searchTerm) {
                vm.noPagesResultMessage = vm.noResultsMsgSuggestion;
            }
        }

        function onPageChangeLocal (page) {
            vm.page = page;

            if (vm.comparisonEnabled && vm.date && vm.compareDate) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function onPageSizeChangeLocal (pageSize) {
            vm.pageSize = pageSize;
            vm.page = 1;
            getPage();
        }

        function updateCompareDate (e) {
            const isFutureDates = ng2StatisticsDateComparisonService.isFutureDate(e.startDate) ||
                ng2StatisticsDateComparisonService.isFutureDate(e.endDate);

            if (isFutureDates) {
                return ng2MonModalService.alert(
                    gettextCatalog.getString(
                        'The start and end date cannot be in the future, please enter the compare dates again',
                    ),
                );
            }

            vm.comparisonEnabled = e.startDate && e.endDate;

            if (vm.comparisonEnabled) {
                vm.compareDate.startDate = e.startDate;
                vm.compareDate.endDate = e.endDate;
                vm.page = 1;
                vm.pageSize = 10;
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            }
        }

        async function updateDateRange () {
            if (vm.date.startDate && vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
            }

            vm.exports = getResetExports();
            getPage();
        }

        function setExportCategoryRef (type) {
            return vm.date.startDate.format('YYYY-MM-DD') + ',' + vm.date.endDate.format('YYYY-MM-DD') + ',' + type;
        }

        function getResetExports () {
            return vm.exports.map(exp => {
                return {
                    ...exp,
                    data: {
                        ...exp.data,
                        category_ref: setExportCategoryRef(exp.type || 'all'),
                    },
                };
            });
        }
    }
})();
