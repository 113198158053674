import { Location } from '@angular/common';
import { Injectable } from '@angular/core';


@Injectable()
export class DirectNavigationService {

    constructor (
        private location: Location,
    ) {}

    navigateToIssueOverlay (params: Record<string, string | number | boolean>): void {

        const overlayParams = Object.assign(params, {
            issueOverlay: 'true',
            time: Date.now(),
        });

        const newSearchParams = window.location.pathname + Location.normalizeQueryParams(
            new URLSearchParams(Object.assign(this.getCurrentSearchParams(), overlayParams)).toString(),
        );

        this.location.go(newSearchParams);

    }

    private getCurrentSearchParams (): Record<string, string> {
        return Array.from((new URLSearchParams(window.location.search)).entries())
            .reduce((acc, current) => {
                acc[current[0]] = current[1];
                return acc;
            }, {});
    }
}
