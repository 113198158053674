<div class="mon-dialog-header">
    <div class="outer-t-smurf">
        <mon-page-header
                header="{{ 'Received reports' | translate }}"
                panelLeftClass="col-48 outer-t-mini">
        </mon-page-header>
    </div>
</div>

<div class="mon-dialog-body">
    <div class="pt-4">
        <div class="mx-4 mb-2">
            <div class="row">
                <div class="col-sm-16 offset-sm-32">
                    <mon-table-search
                            (getPage)="onSearch($event)"
                            [model]="search"
                            [minSearchLength]="1">
                    </mon-table-search>
                </div>
            </div>
        </div>

        <div class="card panel-monsido-card mx-4">
            <div class="card-body px-7">
                <mon-received-reports-table
                        [search]="search"
                        [showPagination]="true"
                        (total)="setTotal($event)"
                        ></mon-received-reports-table>

            </div>
        </div>
    </div>
</div>
