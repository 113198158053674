import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityPages', {
        templateUrl: 'app/pages/accessibility/pages/pages.html',
        controller: AccessibilityPagesController,
        controllerAs: 'vm',
    });

    AccessibilityPagesController.$inject = [
        'ng2AccessibilityRepoService',
        'ng2SessionService',
        'gettextCatalog',
        '$controller',
        '$filter',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'AccessibilityFiltersService',
        'ng2ActiveFeatureService',
        'ng2AccessibilityAuxService',
    ];

    function AccessibilityPagesController (
        ng2AccessibilityRepoService,
        ng2SessionService,
        gettextCatalog,
        $controller,
        $filter,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        AccessibilityFiltersService,
        ng2ActiveFeatureService,
        ng2AccessibilityAuxService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.goToPageDetails = goToPageDetails;
        vm.$onDestroy = onDestroy;
        var allyLevelString = gettextCatalog.getString('Accessibility level');
        var allyErrorTypeString = gettextCatalog.getString('Accessibility error type');

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            vm.accessibility = ng2SessionService.domain.features.accessibility;
            vm.search = '';
            vm.pages = [];
            vm.currentSort = {
                by: 'priority_score',
                direction: 'desc',
            };
            vm.issueCount = 0;
            vm.subheaderTranslation = '';

            setIssuesCounter();
            setupFilters();
            getPage();
        }

        function setupFilters () {
            vm.reviewType = [];
            vm.accessibilityAbbr = [];

            vm.filters[allyErrorTypeString] = [];
            vm.filters[allyLevelString] = [];
            vm.availableFilters[allyLevelString] = ng2AccessibilityAuxService.getAccessibilityOptions(vm.accessibility);

            if (vm.availableFilters[allyLevelString].length === 0) {
                delete vm.availableFilters[allyLevelString];
            }

            vm.availableFilters[allyErrorTypeString] = AccessibilityFiltersService.getTypes();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };
            if (AccessibilityFiltersService.canUseMobileGuidelines()) {
                cleanupReviewTypeWhenMobileOnly(vm.reviewType);

                params['mobile'] = vm.checksMobile;
            }
            if (vm.filters[allyErrorTypeString]?.length > 1) {
                vm.filters[allyErrorTypeString].shift();
            }
            params['result_type'] = vm.reviewType;
            params['abbr[]'] = vm.accessibilityAbbr;
            params = vm.updateParamsWithFilters(params);

            vm.progress = ng2AccessibilityRepoService.getAllPages(params).then(function (data) {
                vm.pages = data;
                vm.subheaderTranslation = ng2AccessibilityAuxService.getSubHeaderDescription($filter('monNumber')(vm.pages.total, '0,0'));
            }, angular.noop);
        }

        function setIssuesCounter () {
            ng2AccessibilityRepoService.getSummary().then(function (summary) {
                vm.issueCount = summary.checks;
            });
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);

            if (changes.changes) {
                changes = changes.changes;
            }

            if (changes[allyErrorTypeString].length === 0) {
                vm.filters[allyErrorTypeString] = [];
            }

            if (changes[allyErrorTypeString].length === 1) {
                vm.filters[allyErrorTypeString] = changes[allyErrorTypeString];
            }

            vm.checksMobile = false;

            if (Array.isArray(changes[allyErrorTypeString])) {
                vm.reviewType = changes[allyErrorTypeString].map(function (type) {
                    if (type.name === gettextCatalog.getString('Mobile only')) {
                        if (vm.checksMobile = !vm.checksMobile) {
                            vm.checksMobile = true;
                        }
                        return undefined;
                    } else {
                        return type.value;
                    }
                });
            } else {
                vm.reviewType = null;
            }

            if (Array.isArray(changes[allyLevelString])) {
                vm.accessibilityAbbr = changes[allyLevelString].map(function (abbr) {
                    return abbr.value;
                });
            }

            getPage();
        }

        function cleanupReviewTypeWhenMobileOnly (reviewType) {
            if (reviewType.includes(undefined)) {
                vm.reviewType = reviewType.filter(function (value) {
                    return value !== undefined;
                });
            }
        }

        function goToPageDetails (page) {
            var params = {
                body: 'pageDetails',
                size: 'fw',
                classes: 'page-details-app',
                data: {
                    page: page,
                    target: 'page-details-section-accessibility',
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
