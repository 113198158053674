(function () {
    'use strict';
    /**
     * Chart
     *
     * @memberof blocks.chart
     * @ngdoc factory
     * @name DoughnutChart
     * @description
     * Create settings for the doughnut
     */

    angular.module('modules.charts.models').factory('DoughnutChart', DoughnutChart);

    DoughnutChart.$inject = ['Chart'];

    /* @ngInject */
    function DoughnutChart (Chart) {
        function Model (attributes) {
            Chart.call(this, attributes);
            this.options.cutoutPercentage = 70;
            this.options.centerText = {};
        }

        Model.prototype = Object.create(Chart.prototype);
        Model.constructor = Model;
        Model.prototype.setCutOutPercentage = setCutOutPercentage;
        Model.prototype.setCenterText = setCenterText;
        Model.prototype.setTotalFontSize = setTotalFontSize;
        Model.prototype.setTotalFontWeight = setTotalFontWeight;
        Model.prototype.setSubFontSize = setSubFontSize;
        Model.prototype.setSubFontWeight = setSubFontWeight;
        Model.prototype.setPercentageFontSize = setPercentageFontSize;
        Model.prototype.setPercentageFontWeight = setPercentageFontWeight;

        /**
         * @memberOf DoughnutChart
         * @description Set the cut out percentage of the doughnut, default value is described in the constructor method (Model)
         * @param {Integer} percentage
         */
        function setCutOutPercentage (percentage) {
            this.options.cutoutPercentage = percentage;
        }

        /**
         * @memberOf DoughnutChart
         * @description Set the centerText of the doughnut
         * @param {String|Integer} percentage
         * @param {String} total
         * @param {String} sub
         */
        function setCenterText (percentage, total, sub) {
            this.options.centerText = {
                percentageLabel: percentage,
                totalLabel: total,
                subLabel: sub,
            };
        }

        /**
         * @memberOf DoughnutChart
         * @description Set the font size of the percentage text
         * @param {Integer} size
         */
        function setPercentageFontSize (size) {
            this.options.centerText = this.options.centerText || {};
            this.options.centerText.percentageFontSize = size;
        }

        /**
         * @memberOf DoughnutChart
         * @description Set the font weight of the percentage text
         * @param {Integer} size
         */
        function setPercentageFontWeight (size) {
            this.options.centerText = this.options.centerText || {};
            this.options.centerText.percentageFontWeight = size;
        }

        /**
         * @memberOf DoughnutChart
         * @description Set the font size of the total text
         * @param {Integer} size
         */
        function setTotalFontSize (size) {
            this.options.centerText = this.options.centerText || {};
            this.options.centerText.totalFontSize = size;
        }

        /**
         * @memberOf DoughnutChart
         * @description Set the font weight of the total text
         * @param {Integer} size
         */
        function setTotalFontWeight (size) {
            this.options.centerText = this.options.centerText || {};
            this.options.centerText.totalFontWeight = size;
        }

        /**
         * @memberOf DoughnutChart
         * @description Set the font size of the sub text
         * @param {Integer} size
         */
        function setSubFontSize (size) {
            this.options.centerText = this.options.centerText || {};
            this.options.centerText.subFontSize = size;
        }

        /**
         * @memberOf DoughnutChart
         * @description Set the font weight of the sub text
         * @param {Integer} size
         */
        function setSubFontWeight (size) {
            this.options.centerText = this.options.centerText || {};
            this.options.centerText.subFontWeight = size;
        }

        return Model;
    }
})();
