(function () {
    'use strict';

    /**
     * @memberof modules.accessibility
     * @ngdoc component
     * @name accessibilityDialogsNewHelpCenterDecisionTree
     * @description Accessibility Decision Tree Component
     */
    angular.module('modules.accessibility').component('accessibilityDialogsNewHelpCenterDecisionTree', {
        templateUrl: 'app/modules/accessibility/dialogs/new-help-center/decision-tree/decision-tree.html',
        controller: AccessibilityDialogsNewHelpCenterDecisionTreeController,
        controllerAs: 'vm',
        bindings: {
            decisionTree: '<',
        },
    });

    AccessibilityDialogsNewHelpCenterDecisionTreeController.$inject = [
        'AccessibilityDialogsNewHelpCenterDecisionTreeService',
    ];

    /* @ngInject */
    function AccessibilityDialogsNewHelpCenterDecisionTreeController (
        AccessibilityDialogsNewHelpCenterDecisionTreeService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getAnswers = getAnswers;
        vm.gotoAnswer = gotoAnswer;
        vm.hasSolution = hasSolution;
        vm.addAnswer = addAnswer;
        vm.getLatestAnswer = getLatestAnswer;

        function activate () {
            vm.question = null;
            AccessibilityDialogsNewHelpCenterDecisionTreeService.setQuestions(vm.decisionTree.questions);
            setNextQuestion();
        }

        /**
         * @memberOf accessibilityDialogsNewHelpCenterDecisionTree
         * @desc Get the Answer stack
         */
        function getAnswers () {
            return AccessibilityDialogsNewHelpCenterDecisionTreeService.getAllResults();
        }

        /**
         * @memberOf accessibilityDialogsNewHelpCenterDecisionTree
         * @desc Add Answer to the Answer Stack
         */
        function addAnswer (answer, answerText) {
            AccessibilityDialogsNewHelpCenterDecisionTreeService.addResult(vm.question, answer, answerText);
            setNextQuestion();
        }

        /**
         * @memberOf accessibilityDialogsNewHelpCenterDecisionTree
         * @param answer - Answer Object
         * @desc Reset the answer stack back to defined answer
         */
        function gotoAnswer (answer) {
            AccessibilityDialogsNewHelpCenterDecisionTreeService.resetToResult(answer);
            setNextQuestion();
        }

        /**
         * @memberOf accessibilityDialogsNewHelpCenterDecisionTree
         * @desc Check if a solution has been found.
         */
        function hasSolution () {
            return vm.question === null;
        }

        /**
         * @memberOf accessibilityDialogsNewHelpCenterDecisionTree
         * @desc Gets the Latest Answer Result.
         * NB! This is used to get the Solution as the question will be defined as null
         */
        function getLatestAnswer () {
            return AccessibilityDialogsNewHelpCenterDecisionTreeService.getLatestResult();
        }

        // PROTECTED

        /**
         * @memberOf accessibilityDialogsNewHelpCenterDecisionTree
         * @desc Set the new Active Question
         */
        function setNextQuestion () {
            vm.question = AccessibilityDialogsNewHelpCenterDecisionTreeService.getActiveQuestion();
        }
    }
})();
