(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormItalicOptions', {
        templateUrl: 'app/modules/page-fix/forms/italic/italic-options/italic-options.html',
        controller: PageFixFormSkipToContentOptionsController,
        controllerAs: 'vm',
        bindings: {
            monPageFix: '<?',
            monPageFixId: '<',
            monPageFixSelector: '<',
            monName: '<',
            monType: '<',
            monSelected: '<',
            monOrder: '<',
            monTitle: '<',
            monFooter: '<',
        },
    });

    PageFixFormSkipToContentOptionsController.$inject = [
        'PAGE_FIX_FIXABLE_TYPES',
        'PAGE_FIX_CHANGE_TYPES',
        'PAGE_FIX_EVENT',
        'ng2MonEventsService',
        '$element',
        'Lodash',
        '$timeout',
        'gettextCatalog',
        'accessibilityAutofixService',
    ];

    /* @ngInject */
    function PageFixFormSkipToContentOptionsController (
        PAGE_FIX_FIXABLE_TYPES,
        PAGE_FIX_CHANGE_TYPES,
        PAGE_FIX_EVENT,
        ng2MonEventsService,
        $element,
        Lodash,
        $timeout,
        gettextCatalog,
        accessibilityAutofixService,
    ) {
        var vm = this;
        var warningText = gettextCatalog.getString('This option might cause problems to your icons');
        var defaultReplacementElement = 'em';
        var regexes = {};

        vm.replacementRegex = accessibilityAutofixService.getElementRegex();
        vm.defaultClassOptionValue = 'i:not([class])';
        vm.attributesData = {
            class: {
                key: 'class',
                name: 'class',
                inputPlaceholder: gettextCatalog.getString('Enter class name'),
                regexPattern: '[^\\s]+',
                regexError: gettextCatalog.getString('Element name should not contain spaces'),
            },
            role: {
                key: 'role',
                name: 'role',
                inputPlaceholder: gettextCatalog.getString('Enter role name'),
                regexPattern: '[^\\s]+',
                regexError: gettextCatalog.getString('Element name should not contain spaces'),
            },
            ariaLabel: {
                key: 'ariaLabel',
                name: 'aria-label',
                inputPlaceholder: gettextCatalog.getString('Enter aria-label name'),
                regexPattern: '',
                regexError: '',
            },
        };

        regexes[vm.attributesData.class.key] = /\(\.(.*)\)/;
        regexes[vm.attributesData.role.key] = /\(\[role="(.*)"/;
        regexes[vm.attributesData.ariaLabel.key] = /\(\[aria-label="(.*)"/;

        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getElementText = getElementText;
        vm.updatePreview = updatePreview;
        vm.addRow = addRow;
        vm.removeRow = removeRow;
        vm.setReplacementSetting = setReplacementSetting;

        function activate () {
            vm.classOptions = [
                {
                    name: gettextCatalog.getString('Without any assigned class'),
                    value: vm.defaultClassOptionValue,
                },
                {
                    name: gettextCatalog.getString('All'),
                    value: 'i',
                },
            ];

            vm.elementAttributesClass = {
                name: gettextCatalog.getString('Class'),
                value: vm.attributesData.class.key,
            };
            vm.elementAttributesRole = {
                name: gettextCatalog.getString('Role'),
                value: vm.attributesData.role.key,
            };

            vm.elementAttributesAriaLabel = {
                name: gettextCatalog.getString('Aria-label'),
                value: vm.attributesData.ariaLabel.key,
            };

            vm.elementAttributes = [vm.elementAttributesClass, vm.elementAttributesRole, vm.elementAttributesAriaLabel];

            vm.extraSelectors = [];
            vm.classOptionValue = vm.defaultClassOptionValue;
            vm.customElement = '';
            vm.replacementSetting = 0;
            vm.requestPreparePayloadId = 0;
            vm.previewText = '';

            ng2MonEventsService.run(PAGE_FIX_EVENT.REGISTER_VIEW, $element[0], {
                type: vm.monType,
                order: vm.monOrder,
                footer: vm.monFooter,
                title: vm.monTitle,
                viewName: vm.monName,
                selected: vm.monSelected,
            });

            if (vm.monPageFix) {
                setInitialValues(vm.monPageFix);
            }

            onChangeSelector();
            setupListeners();
            updatePreview();
        }

        function onDestroy () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.UNREGISTER_VIEW, $element[0]);
            ng2MonEventsService.remove(vm.requestPreparePayloadId);
        }

        function addRow () {
            vm.extraSelectors.push({
                type: vm.attributesData.class.key,
                value: '',
                newEntry: true,
            });
        }

        function removeRow (index) {
            vm.extraSelectors.splice(index, 1);
        }

        function getElementText () {
            if (vm.replacementSetting === 0) {
                return vm.elementReplacement;
            }

            return vm.customElement;
        }

        function updatePreview () {
            $timeout(function () {
                var elementName = getElementText();
                var valid = accessibilityAutofixService.isElementNameValid(elementName);
                ng2MonEventsService.run(PAGE_FIX_EVENT.CUSTOM_VALIDATION, valid);
                setPreview(elementName);
            });
        }

        function setReplacementSetting (setting) {
            vm.replacementSetting = setting;
            if (setting === 0) {
                vm.elementReplacement = defaultReplacementElement;
            }
            onChangeSelector();
            updatePreview();
        }

        function onChangeSelector () {
            var message = warningText;
            if (vm.classOptionValue === vm.defaultClassOptionValue) {
                message = '';
                vm.elementAttributes = [vm.elementAttributesRole, vm.elementAttributesAriaLabel];
                vm.extraSelectors = vm.extraSelectors.filter(function (entry) {
                    return entry.type !== vm.attributesData.class.key;
                });
            } else {
                vm.elementAttributes = [vm.elementAttributesClass, vm.elementAttributesRole, vm.elementAttributesAriaLabel];
            }
            ng2MonEventsService.run(PAGE_FIX_EVENT.UPDATE_WARNING, message);
        }

        // PROTECTED

        function setPreview (elementName) {
            vm.previewText = accessibilityAutofixService.getElementPreview(elementName);
        }

        function setInitialValues (pageFix) {
            var customSelector;
            var type;
            var customSelectorLength = 0;
            var classOptionValue = vm.defaultClassOptionValue;

            vm.elementReplacement = vm.monPageFix.change.value;

            try {
                customSelector = JSON.parse(pageFix.custom_selector)[0].split(/:not[\)]*/);
            } catch (_e) {}

            if (Array.isArray(customSelector)) {
                classOptionValue = customSelector.shift();

                if (classOptionValue === 'i') {
                    if (customSelector[0] === '([class])') {
                        classOptionValue = 'i:not' + customSelector.shift();
                    }

                    customSelectorLength = customSelector.length;
                    customSelector.forEach(function (selector) {
                        if (selector.indexOf('[' + vm.attributesData.ariaLabel.name) > -1) {
                            type = vm.attributesData.ariaLabel.key;
                        } else if (selector.indexOf('[' + vm.attributesData.role.name) > -1) {
                            type = vm.attributesData.role.key;
                        } else {
                            type = vm.attributesData.class.key;
                        }
                        vm.extraSelectors.push({
                            type: type,
                            value: (selector.match(regexes[type]) || [])[1] || '',
                        });
                    });
                } else {
                    classOptionValue = vm.defaultClassOptionValue;
                }
            }

            vm.classOptionValue = classOptionValue;

            if (vm.elementReplacement !== defaultReplacementElement || customSelectorLength > 0) {
                vm.customElement = vm.elementReplacement;
                vm.replacementSetting = 1;
            }

            if (Lodash.find(vm.classOptions, { value: pageFix.selector })) {
                vm.classOptionValue = pageFix.selector;
            }
        }

        function getPayload () {
            var payload = {
                change: {
                    attribute: 'i',
                    check_id: vm.monPageFixId,
                    value: getElementText(),
                },
            };

            var extraSelectorsData = [vm.classOptionValue];

            if (vm.replacementSetting !== 0) {
                var extraSelector;
                vm.extraSelectors.forEach(function (item) {
                    switch (item.type) {
                        case vm.attributesData.class.key:
                            if (vm.classOptionValue !== vm.defaultClassOptionValue) {
                                extraSelector = item.value ? '.' + item.value : '[class]';
                            }
                            break;
                        case vm.attributesData.role.key:
                            extraSelector = item.value ? '[role="' + item.value + '"]' : '[role]';
                            break;
                        case vm.attributesData.ariaLabel.key:
                            extraSelector = item.value ? '[aria-label="' + item.value + '"]' : '[aria-label]';
                            break;
                        default:
                            extraSelector = '';
                            break;
                    }
                    if (extraSelector) {
                        extraSelector = ':not(' + extraSelector + ')';
                    }
                    if (extraSelector && extraSelectorsData.indexOf(extraSelector) === -1) {
                        extraSelectorsData.push(extraSelector);
                    }
                });
            }

            payload.selector = 'q;' + JSON.stringify([extraSelectorsData.join('')]);
            if (!vm.pageFix) {
                payload.fixable_type = PAGE_FIX_FIXABLE_TYPES.accessibility_check;
                payload.fixable_id = vm.monPageFixId;
                payload.change_type = PAGE_FIX_CHANGE_TYPES.element + ':i';
            }

            return payload;
        }

        function setupListeners () {
            vm.requestPreparePayloadId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.REQUEST_PREPARE_PAYLOAD, function () {
                ng2MonEventsService.run(PAGE_FIX_EVENT.REQUEST_SUBMIT, getPayload());
            });
        }
    }
})();
