(function () {
    'use strict';

    angular.module('modules.light.pages').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.customer.light.domains',
                config: {
                    url: '/domains?view',
                    template: '<light-domains></light-domains>',
                    data: {
                        sidebar: 'light',
                    },
                },
            },
            {
                state: 'base.customer.light.users',
                config: {
                    url: '/users',
                    template: '<light-users></light-users>',
                    data: {
                        sidebar: 'light',
                    },
                },
            },
            {
                state: 'base.customer.light.account',
                config: {
                    url: '/account',
                    template: '<light-account></light-account>',
                    data: {
                        sidebar: 'light',
                    },
                },
            },
        ];
    }
})();
