(function () {
    'use strict';

    angular.module('modules.statistics').component('statisticsCapText', {
        templateUrl: 'app/modules/statistics/components/statistics-cap-text/statistics-cap-text.html',
        controller: StatisticsCapTextController,
        controllerAs: 'vm',
        bindings: {},
    });

    StatisticsCapTextController.$inject = [];
    /* @ngInject */
    function StatisticsCapTextController () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {}
    }
})();
