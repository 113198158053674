(function () {
    'use strict';

    angular.module('pages.login').component('login', {
        templateUrl: 'app/pages/login/login/login.html',
        controller: LoginController,
        controllerAs: 'vm',
    });

    LoginController.$inject = ['ng2CoreLoginService', '$state', '$window', 'ng2MonUIRouterTransitionService'];
    /* @ngInject */
    function LoginController (ng2CoreLoginService, $state, $window, ng2MonUIRouterTransitionService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            if (ng2CoreLoginService.isLoggedIn()) {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard'),
                );
                return;
            }
            $window.location.href = ng2CoreLoginService.getAuthorizeUrl();
        }
    }
})();
