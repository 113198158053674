import { NgModule } from '@angular/core';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { SharedModule } from 'ng2/shared/shared.module';

@NgModule({
    imports: [ApiModule, SharedModule],
    exports: [ApiModule],
    declarations: [],
    providers: [],
})
export class EndpointsModule {}
