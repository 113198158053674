(function () {
    'use strict';
    /**
     * Check if the input matches the required email format for uptime notifications
     * The required format is multiple emails seperated by comma
     *
     * @memberof directives.monsido
     * @ngdoc directive
     * @name uptimeEmail
     *
     */
    angular.module('directives.monsido').directive('validDomain', function () {
        return {
            require: '?ngModel',
            link: function (scope, elm, attrs, ngModel) {
                if (ngModel) {
                    ngModel.$validators.validDomain = function (modelValue) {
                        if (ngModel.$isEmpty(modelValue)) {
                            return true;
                        }
                        var url;
                        try {
                            url = new URL(modelValue);
                        } catch (err) {}
                        return !!url;
                    };
                }
            },
        };
    });
})();
