import { Injectable } from '@angular/core';
import { ISSUE_TYPES } from '@monsido/ng2/modules/deeplink/constants/deeplink.constant';
import { MonUIRouterTransitionService } from '@monsido/ng2/services/ui-router-transition-service/ui-router-transition-service';
import { RawParams, StateService } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class DeeplinkService {

    constructor (
        private monUIRouterTransitionService: MonUIRouterTransitionService,
        private state: StateService,
    ) { }

    redirect (customerId: number, domainId: number | 'na', issueTypeId: string, issueId: number, params?: RawParams): void {
        let stateParams = Object.assign({}, params);
        if (!(stateParams !== null && typeof stateParams === 'object' && !Array.isArray(stateParams))) {
            stateParams = {};
        }
        stateParams.issueId = issueId;
        stateParams.customerId = customerId;
        const baseUrl = this.getBaseUrl(issueTypeId);
        if (!baseUrl) {
            return this.monUIRouterTransitionService.onTransitionFinished(
                () => {
                    this.state.go('base.dashboard', stateParams);
                },
            );
        } else if (domainId !== 'na') {
            stateParams.domainId = domainId;
            return this.monUIRouterTransitionService.onTransitionFinished(
                () => {
                    this.state.go(baseUrl, stateParams);
                },
            );
        } else {
            return this.monUIRouterTransitionService.onTransitionFinished(
                () => {
                    this.state.go(baseUrl, stateParams);
                },
            );
        }
    }

    private getBaseUrl (issueTypeId: string): string {
        for (const value of Object.values(ISSUE_TYPES)) {
            if (value.id === issueTypeId) {
                return value.route;
            }
        }
        return '';
    }


}
