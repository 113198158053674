(function () {
    'use strict';

    angular.module('pages.base.terms').component('baseTermsClosedCaption', {
        templateUrl: 'app/pages/base/terms/closed-caption/closed-caption.html',
        controller: AccessibilityClosedCaptionController,
        controllerAs: 'vm',
    });

    AccessibilityClosedCaptionController.$inject = [];
    /* @ngInject */
    function AccessibilityClosedCaptionController () {}
})();
