<div class="row">
    <div class="ml-4 mr-auto page-details-title col-sm-48">
        <div class="pl-5 mon-dialog-header">
            <h1 class="ml-4 pt-2 mb-1">{{ this.title }}</h1>
            <span class="ml-4 mt-0">
                <a [href]="linkUrl" target="_blank" aria-describedby="linkOpensInNewTab">
                    <mon-icon class="icon fa-2x ml-4 mt-4" [monIconDirect]="'faExternalLinkAlt'" class="text-link"></mon-icon> {{ linkUrl }}
                </a>
            </span>
        </div>
    </div>
</div>

<div class="mon-dialog-body pt-1 preview-header-border">
    <div class="card panel-monsido-card mt-4 mx-4">
    <div class="card-body">
        <h3 class="font-weight-300 mt-3 mx-4" translate>Image preview</h3>
        <hr>

        <div class="row">
            <div class="col-48 text-center">
                <a [href]="linkUrl" target="_blank" aria-describedby="linkOpensInNewTab">
                    <img [src]="linkUrl" alt="{{ 'Inventory image preview' | translate }}" class="img-thumbnail img-preview mx-4 my-4">
                </a>
            </div>
        </div>
    </div>
    </div>

    <div class="card panel-monsido-card mt-4 mx-4">
        <div class="card-body">
        <h3 class="font-weight-300 mt-3 mx-4" translate>Image metadata</h3>
        <hr>

        <div class="table-container-body px-4 mt-4">
            <table class="table px-4">
                <thead>
                    <tr>
                        <th class="col-20 pl-4">
                            {{ 'Metadata property' | translate }}
                        </th>
                        <th class="col-28">
                            {{ 'Content' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of imageMetaData">
                    <td>
                        <div class="text-v-middle pl-4">
                            {{ data.key }}
                        </div>
                    </td>
                    <td class="text-v-middle">
                        {{ data.value }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
</div>

