(function () {
    'use strict';

    angular.module('modules.page-performance').component('formPagePerformanceProfile', {
        templateUrl: 'app/modules/page-performance/forms/profile/profile.html',
        controller: FormPagePerformanceProfileController,
        controllerAs: 'vm',
        bindings: {
            profile: '<?',
            monDialogId: '@',
        },
    });

    FormPagePerformanceProfileController.$inject = [
        'monDialog',
        '$deepmerge',
        'PAGE_PERFORMANCE_CONNECTION_PROFILES',
        'PagePerformanceProfile',
        'gettextCatalog',
        '$filter',
        'PagePerformanceLocationService',
        'PagePerformanceProfileObsService',
    ];
    /* @ngInject */
    function FormPagePerformanceProfileController (
        monDialog,
        $deepmerge,
        PAGE_PERFORMANCE_CONNECTION_PROFILES,
        PagePerformanceProfile,
        gettextCatalog,
        $filter,
        PagePerformanceLocationService,
        PagePerformanceProfileObsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.getSpinnerIcon = getSpinnerIcon;
        vm.profileRequest = {};

        function activate () {
            vm.saving = false;
            vm.domains = [];
            vm.model = $deepmerge({}, vm.profile || {});
            vm.devices = [];
            vm.locations = PagePerformanceLocationService.getLocations();
            vm.model.device = vm.model.device || 'desktop';
            vm.currentLocation = vm.model.location || vm.locations[0].key;
            setupDevices();
            setupConnectionProfile();
        }

        function submit () {
            vm.saving = true;
            var model = $deepmerge({}, vm.model);
            var promise;
            model.location = vm.currentLocation;
            model.default_domain_ids = [];
            mergeConnectionProfile(vm.currentConnectionProfile, model);
            if (vm.model.id) {
                promise = PagePerformanceProfile.update(model).then(PagePerformanceProfileObsService.updateProfile);
            } else {
                promise = PagePerformanceProfile.create(model).then(PagePerformanceProfileObsService.addProfile);
            }
            promise.then(
                function (profile) {
                    close(profile);
                },
                function () {
                    vm.saving = false;
                },
            );
        }

        function getSpinnerIcon () {
            if (vm.saving) {
                return 'fa-spin ' + $filter('monIcons')(['SPINNER', 'ICON']);
            }
            return '';
        }

        // PROTECTED

        function close (value) {
            monDialog.close(vm.monDialogId, value);
        }

        function setupDevices () {
            vm.devices = [
                {
                    name: gettextCatalog.getString('Desktop'),
                    value: 'desktop',
                    icon: 'fas fa-desktop-alt',
                },
                {
                    name: gettextCatalog.getString('Mobile'),
                    value: 'mobile',
                    icon: 'fas fa-mobile',
                },
            ];
        }

        function setupConnectionProfile () {
            vm.currentConnectionProfile = null;
            vm.connectionProfiles = PAGE_PERFORMANCE_CONNECTION_PROFILES;
            if (vm.model.id) {
                var target = vm.connectionProfiles.find(function (connectionProfile) {
                    for (var key in connectionProfile.settings) {
                        if (connectionProfile.settings[key] !== vm.model[key]) {
                            return false;
                        }
                    }
                    return true;
                });
                if (target) {
                    vm.currentConnectionProfile = target.name;
                }
            }
        }

        function mergeConnectionProfile (connectionProfileName, obj) {
            var connectionProfile = vm.connectionProfiles.find(function (entry) {
                return entry.name === connectionProfileName;
            });
            if (!connectionProfile) {
                return;
            }
            for (var setting in connectionProfile.settings) {
                obj[setting] = connectionProfile.settings[setting];
            }
        }
    }
})();
