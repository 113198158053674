export enum MonFeature {
    page_assist = 'page_assist',
    support_center = 'support_center',
    customer_admin = 'customer_admin',
    admin_heatmaps = 'admin_heatmaps',
    spotlight = 'spotlight',
    velaro = 'velaro',
    shortcuts = 'shortcuts',
    general_book_training = 'general_book_training',
    data_export = 'data_export',
    suggestions_and_ideas = 'suggestions_and_ideas',
    classic_ui = 'classic_ui',
    admin_accessibility = 'admin_accessibility',
    accessibility_summary = 'accessibility_summary',
    accessibility = 'accessibility',
    page_fix = 'page_fix',
    cookie_banner = 'cookie_banner',
    monsido_score = 'monsido_score',
    statistics = 'statistics',
    admin_readability = 'admin_readability',
    readability = 'readability',
    page_fix_accessibility_check = 'page_fix_accessibility_check',
    page_fix_accessibility_source_code = 'page_fix_accessibility_source_code',
    page_fix_qa_links = 'page_fix_qa_links',
    page_fix_qa_misspelling = 'page_fix_qa_misspelling',
    page_fix_qa_custom = 'page_fix_qa_custom',
    notifications = 'notifications',
    heatmaps = 'heatmaps',
    admin_source_code_excludes = 'admin_source_code_excludes',
    api_users = 'api_users',
    admin_page_fix = 'admin_page_fix',
    admin_page_assist = 'admin_page_assist',
    dataprivacy = 'dataprivacy',
    admin_data_privacy = 'admin_data_privacy',
    page_performance = 'page_performance',
    admin_page_performance = 'admin_page_performance',
    page_page_performance = 'page_page_performance',
    admin_accessibility_help_center = 'admin_accessibility_help_center',
    history_center_all = 'history_center_all',
    qa_module = 'qa_module',
    seo_all = 'seo_all',
    general_shortcuts = 'general_shortcuts',
    policies_all = 'policies_all',
    prioritized_content_all = 'prioritized_content_all',
    report_center_all = 'report_center_all',
    general_chat_support = 'general_chat_support',
    inventory_all = 'inventory_all',
    qa_links = 'qa_links',
    qa_spellcheck = 'qa_spellcheck',
    qa_summary = 'qa_summary',
    admin_heartbeat = 'admin_heartbeat',
    admin_statistics = 'admin_statistics',
    mobile_guidelines = 'mobile_guidelines',
    image_metadata = 'image_metadata',
}

export type MonFeatureType = keyof typeof MonFeature

