import { PageFixMisspellingUtil } from './PageFixMisspelling.util';

(function () {
    'use strict';
    angular.module('modules.page_fix.forms').component('pageFixFormMisspelling', {
        templateUrl: 'app/modules/page-fix/forms/misspelling/misspelling.html',
        controller: PageFixMisspellingController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    PageFixMisspellingController.$inject = [
        'ng2PageFixRepoService',
        'PAGE_FIX_FIXABLE_TYPES',
        'PAGE_FIX_SELECTOR_QUERIES',
        'PAGE_FIX_CHANGE_TYPES',
    ];
    /* @ngInject */
    function PageFixMisspellingController (
        ng2PageFixRepoService,
        PAGE_FIX_FIXABLE_TYPES,
        PAGE_FIX_SELECTOR_QUERIES,
        PAGE_FIX_CHANGE_TYPES,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.cancel = cancel;

        function activate () {
            const pageFixMisspellingUtil = new PageFixMisspellingUtil();
            pageFixMisspellingUtil.setModel(vm.resolve.model);
            pageFixMisspellingUtil.setPageFix(vm.resolve.pageFix);
            pageFixMisspellingUtil.setupSettings();
            vm.misspelling = pageFixMisspellingUtil.getMisspelling();
            vm.correctSpelling = pageFixMisspellingUtil.getCorrectSpelling();
            vm.pageFix = pageFixMisspellingUtil.getPageFix();
        }

        function submit () {
            var promise;
            var change = {
                value: vm.correctSpelling,
            };

            if (vm.pageFix) {
                promise = ng2PageFixRepoService.update(vm.pageFix, { change: change });
            } else {
                promise = ng2PageFixRepoService.create({
                    change: change,
                    selector: PAGE_FIX_SELECTOR_QUERIES.tree_walker + ';' + vm.misspelling,
                    fixable_type: PAGE_FIX_FIXABLE_TYPES.misspelling,
                    fixable_id: vm.resolve.typeId || vm.resolve.model.id,
                    change_type: PAGE_FIX_CHANGE_TYPES.word,
                });
            }

            promise.then(function (pageFix) {
                closeModal(pageFix);
            });
        }

        function cancel () {
            vm.dismiss();
        }

        function closeModal (result) {
            vm.close({ $value: result });
        }
    }
})();
