(function () {
    'use strict';
    /**
     * Check if the input matches matches ngModel
     *
     * @memberof directives.monsido
     * @ngdoc directive
     * @name monMatchInput
     *
     */
    angular.module('directives.monsido').directive('monMatchInput', function () {
        return {
            require: '?ngModel',
            link: function (scope, elm, attr, ctrl) {
                if (!ctrl) {
 return;
}

                var match;
                attr.$observe('monMatchInput', function (value) {
                    match = value;
                    ctrl.$validate();
                });

                ctrl.$validators.matchInput = function (modelValue, viewValue) {
                    return (
                        ctrl.$isEmpty(viewValue) || isUndefined(viewValue) || isUndefined(match) || match === viewValue
                    );
                };

                function isUndefined (value) {
                    return typeof value === 'undefined';
                }
            },
        };
    });
})();
