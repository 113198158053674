import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { AuditLogModel } from '@client/app/models/audit-log.model';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type AuditRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class AuditRepoService extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    async getAuditLogs (params: AuditRepoRequestType = {}): Promise<MonTableCollection<AuditLogModel>> {
        params = this.setGroupId(params);
        return this.apiService.get('audit_logs', params)
            .then((auditLogs: MonTableCollection<AuditLogModel>) => {
                for (let i = 0; i < auditLogs.length; i++) {
                    auditLogs[i] = new AuditLogModel(auditLogs[i]);
                }
                return auditLogs;
            });
    }
}
