(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name SourceCodeExcludesEntity
     * @description Service to handle Source Code Excludes
     */
    angular.module('services.entities').factory('SourceCodeExcludesEntity', SourceCodeExcludesEntity);

    SourceCodeExcludesEntity.$inject = ['SourceCodeExcludeRepo'];
    /* @ngInject*/
    function SourceCodeExcludesEntity (SourceCodeExcludeRepo) {
        return {
            update: update,
            create: create,
            destroy: destroy,
        };

        function update (sourceCodeExclude, updatedSourceCodeExclude, params, config) {
            return SourceCodeExcludeRepo.update(sourceCodeExclude, updatedSourceCodeExclude, params, config);
        }

        function create (sourceCodeExclude, params, config) {
            return SourceCodeExcludeRepo.create(sourceCodeExclude, params, config);
        }

        function destroy (sourceCodeExclude, params, config) {
            return SourceCodeExcludeRepo.destroy(sourceCodeExclude.id, params, config);
        }
    }
})();
