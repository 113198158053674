import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { Page } from '@monsido/modules/page-details/models';
import {
    ReadabilityGetPagesParams,
    ReadabilityGetSummaryParams,
    ReadabilityGetSummaryResponse,
} from '@client/app/services/api/readability-repo/readability-repo.type';
import { BaseRepo } from '@client/app/services/api/base-repo/base-repo';


@Injectable({
    providedIn: 'root',
})
export class ReadabilityRepoService extends BaseRepo {
    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
    ) {
        super();
    }

    summary (params: ReadabilityGetSummaryParams = {}): Promise<ReadabilityGetSummaryResponse> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId()}/readability/`, params);
    }

    pages (params: ReadabilityGetPagesParams = {}): Promise<MonTableCollection<Page>> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId()}/readability/pages`, params);
    }
}
