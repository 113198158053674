(function () {
    'use strict';
    angular.module('modules.page_fix.services').factory('PageFixFormEditAltTextService', PageFixFormEditAltTextService);

    PageFixFormEditAltTextService.$inject = ['gettextCatalog', 'PAGE_FIX_SELECTOR_QUERIES'];
    /* @ngInject*/
    function PageFixFormEditAltTextService (gettextCatalog, PAGE_FIX_SELECTOR_QUERIES) {
        return {
            getTextDescription: getTextDescription,
            getPageFixSelector: getPageFixSelector,
            getPageFixUrls: getPageFixUrls,
        };

        // ///////////////

        function getTextDescription (checkId) {
            switch (checkId) {
                case 1:
                    return gettextCatalog.getString('All <code>img</code> elements have an <code>alt</code> attribute');
                case 2:
                    return gettextCatalog.getString(
                        'Alt text is not the same as the filename unless author has confirmed it is correct',
                    );
                case 3:
                    return gettextCatalog.getString('Non-Decorative images must have Alt text');
                case 4:
                    return gettextCatalog.getString('Image Alt text is short');
                case 5:
                    return gettextCatalog.getString('Important images should not have spacer Alt text');
                case 6:
                    return gettextCatalog.getString(
                        'Alt text for all \u003ccode\u003eimg\u003c/code\u003e elements is not placeholder text unless author has confirmed it is correct',
                    );
                case 7:
                    return gettextCatalog.getString(
                        'Alt text for all \u003ccode\u003eimg\u003c/code\u003e elements used as source anchors is not empty when there is no other text in the anchor',
                    );
                case 11:
                    return gettextCatalog.getString(
                        'Alt text for all \u003ccode\u003eimg\u003c/code\u003e elements contains all text in the image unless the image text is decorative or appears elsewhere in the document',
                    );
                case 16:
                    return gettextCatalog.getString(
                        'Alt text for all <code>img</code> elements is the empty string ("") if the image is decorative.',
                    );
                case 178:
                    return gettextCatalog.getString('Alt text does not convey the same information as the image');
            }
        }

        function getPageFixSelector (urls) {
            var query = urls.map(function (url) {
                return 'img[src="' + url.url + '"]';
            });
            return PAGE_FIX_SELECTOR_QUERIES.query_selector + ';' + JSON.stringify(query);
        }

        function getPageFixUrls (pageFix) {
            var selectorQueries = pageFix.selector
                .split(';')
                .slice(1)
                .join(';');
            return JSON.parse(selectorQueries).map(function (selector) {
                return selector.match(/.*="(.*)"]/)[1];
            });
        }
    }
})();
