import { Injectable } from '@angular/core';
import moment from 'moment';
import numeral from 'numeral';
import { PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE } from '../../constants/page-performance-display-mode-detail-heading-type';
import { TranslateService } from '@client/app/services/translate/translate.service';

type PagePerformanceAuditNodeType = {
    fromProtocol?: boolean,
    isCrossOrigin?: boolean,
    node?: NodeType[],
    totalBytes?: number,
    url?: string,
    wastedBytes?: number,
    wastedWebpBytes?: number,
    cacheLifeTimeMs?: number,
    cacheHitProbability?: number,
    entity?: string,
    transferSize?: number,
    blockingTime?: number,
    total?: number,
    scripting?: number,
    mainThreadTime?: number,
    scriptParseCompile?: number,
    failureType?: number,
    reason?: number,
    duration?: number,
    groupLabel?: number,
    label?: number,
    requestCount?: number,
    wastedMs?: number,
    startTime?: number,
    statistics?: number,
    value?: number,
    link?: LinkType
    subItems?: {
        type?: string,
        items?: {
            blockingTime: number,
            transferSize: number,
        }[],
        value: string,
    },
    debugData?: {
        type: string,
        public: boolean,
        'max-age': number
    },
}
type NodeType = {
    type: string,
    nodeLabel: string,
    snippet: string,
    lhId: string,
    path: string,
    selector: string,
    boundingRect: {
        bottom: number,
        height: number,
        left: number,
        right: number,
        top: number,
        width: number,
    }
}

type PagePerformanceAuditHeadingKeyType = 'cacheLifeTimeMs' | 'totalBytes' | 'url' | 'entity' | 'transferSize' | 'blockingTime' | 'total' |
'scripting' | 'scriptParseCompile' | 'failureType' | 'reason' | 'duration' | 'groupLabel' | 'label' |
'requestCount' | 'node' | 'wastedMs' | 'startTime' | 'statistics' | 'value' | 'link';

type PagePerformanceAuditHeadingValueType = 'url' | 'node' | 'text' | 'code' | 'numeric' | 'thumbnail' | 'ms' | 'timespanMs' | 'bytes' | 'link';

type PagePerformanceAuditHeadingType = {
    key: PagePerformanceAuditHeadingKeyType,
    label: string,
    valueType: PagePerformanceAuditHeadingValueType,
    itemType?: PagePerformanceAuditHeadingValueType,
    displayUnit?: string,
    granularity?: number,
    subItemHeading?: {
        key: string,
        valueType?: string,
    }[],
}

type PagePerformanceAuditType = 'table' | 'opportunity' | 'criticalrequestchain';

type LinkType = {
    type: string,
    url: string,
    text: string,
}

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceAuditDetailsService {

    constructor (private translateService: TranslateService) { }

    getTableHeadingClass (heading: PagePerformanceAuditHeadingType): string {
        const type = heading.itemType ?? heading.valueType;
        if (type === PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.THUMBNAIL) {
            return 'table-column-thumbnail';
        }

        return '';
    }

    isTypeTable (type: PagePerformanceAuditType): boolean {
        return type === 'table';
    }

    isTypeOpportunity (type: PagePerformanceAuditType): boolean {
        return type === 'opportunity';
    }

    isTypeCriticalRequestChain (type: PagePerformanceAuditType): boolean {
        return type === 'criticalrequestchain';
    }

    getFormatAuditItem (item: PagePerformanceAuditNodeType, heading: PagePerformanceAuditHeadingType): string {
        const type = heading.itemType ?? heading.valueType;
        switch (type) {
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.URL:
                return this.getURLHTMLSnippet(item);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.NODE:
                return this.getNodeHTMLSnippet(item[heading.key] as unknown as NodeType);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.TEXT:
                return item[heading.key] as string;
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.CODE:
                if (item.subItems?.items?.length && item.subItems.items.length > 0) {
                    return this.getCodeHTMLSnippet(item.subItems.value);
                }
                return this.getCodeHTMLSnippet(item[heading.key] as string);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.NUMERIC:
                return this.getNumericHTMLSnippet(item[heading.key] as string);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.THUMBNAIL:
                return this.getThumbnailSnippet(item[heading.key] as string);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.MS:
                return this.getMSSnippet(item[heading.key] as number);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.TIMESPAN_MS:
                return this.getTimespanMs(item[heading.key] as number);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.BYTES:
                return this.getBytesSnippet(item[heading.key] as number);
            case PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE.LINK:
                return this.getLinkSnippet(item[heading.key] as LinkType);
            default:
                return item[heading.key] as string;
        }
    }

    // PROTECTED
    private getCodeHTMLSnippet (code: string): string {
        if (code) {
            const preEl = document.createElement('pre');
            preEl.innerText = code;
            return preEl.outerHTML;
        } else if (typeof code === 'string') {
            return code;
        }
        return '';
    }


    private getNumericHTMLSnippet (numeric: string): string {
        return numeral(numeric).value()
            ?.toString() ?? '';
    }


    private getNodeHTMLSnippet (item: NodeType): string {
        if (!item) {
            return '';
        }
        const containerEl = document.createElement('div');
        const strongEl = document.createElement('strong');
        const newLineEl = document.createElement('br');
        const preEl = document.createElement('pre');
        strongEl.innerText = item.nodeLabel;
        preEl.innerText = item.snippet;
        containerEl.append(strongEl);
        containerEl.append(newLineEl);
        containerEl.append(preEl);
        return containerEl.innerHTML;
    }


    private getURLHTMLSnippet (item): string {
        const containerEl = document.createElement('div');
        const anchorEl = document.createElement('a');
        const spanEl = document.createElement('span');
        anchorEl.target = '_blank';
        anchorEl.href = item.href || item.url;
        if (anchorEl.pathname.length > 1) {
            anchorEl.innerText = anchorEl.pathname;
            spanEl.innerHTML = '&nbsp;-&nbsp;(' + anchorEl.hostname + ')';
            spanEl.classList.add('text-grey');
        } else {
            anchorEl.innerText = anchorEl.hostname;
        }

        containerEl.append(anchorEl);
        containerEl.append(spanEl);
        return containerEl.innerHTML;
    }


    getThumbnailSnippet (url: string): string {
        const imageEl = document.createElement('img');
        imageEl.src = url;
        imageEl.title = url;
        imageEl.alt = '';
        imageEl.classList.add('page-performance-thumbnail');
        return imageEl.outerHTML;
    }


    getMSSnippet (ms: number): string {
        if (ms === 0) {
            return this.translateService.getString('None');
        } else if (ms < 30000) {
            const num = numeral(Math.ceil(ms)).value() ?? 0;
            return this.translateService.getString('[[ms]] ms', {
                ms: num,
            });
        }

        return moment.duration(ms, 'ms').humanize();
    }


    getTimespanMs (ms: number): string {
        if (ms === 0) {
            return this.translateService.getString('None');
        }

        const num = Math.ceil(ms);
        return this.translateService.getString('[[ms]] ms', { ms: num });
    }


    getBytesSnippet (bytes: number): string {
        return numeral(bytes).format('0 ib');
    }


    getLinkSnippet (entity: LinkType): string {
        if (entity.type === 'link') {
            const anchorEl = document.createElement('a');
            anchorEl.href = entity.url;
            anchorEl.innerText = entity.text;
            anchorEl.target = '_blank';
            return anchorEl.outerHTML;
        }
        return '';
    }
}
