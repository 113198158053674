import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

export type RulesetRepoParamsType = RequestParamsType & BaseRepoRequestType;

@Injectable({
    providedIn: 'root',
})
export class DataPrivacyRulesetRepo extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    getAll (params: RulesetRepoParamsType = {}): Promise<unknown> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/data_protection/custom_ruleset`, params);
    }

    getAllForPage (pageId: number, params: RulesetRepoParamsType = {}): Promise<unknown> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/pages/${pageId}/data_protection/custom_ruleset`, params);
    }
}
