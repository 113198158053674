(function () {
    'use strict';
    angular
        .module('app.forms')
        .service('FormDomainScriptGuideLegacyPageAssistService', FormDomainScriptGuideLegacyPageAssistService);

    FormDomainScriptGuideLegacyPageAssistService.$inject = ['PAGE_ASSIST_SETTINGS', 'gettextCatalog', '$deepmerge'];

    /* @ngInject*/
    function FormDomainScriptGuideLegacyPageAssistService (PAGE_ASSIST_SETTINGS, gettextCatalog, $deepmerge) {
        var vm = this;
        vm.themes = [
            {
                name: gettextCatalog.getString('Light'),
                value: 'light',
            },
            {
                name: gettextCatalog.getString('Dark'),
                value: 'dark',
            },
        ];
        vm.directions = [
            {
                name: gettextCatalog.getString('Bottom left'),
                value: 'left',
            },
            {
                name: gettextCatalog.getString('Bottom right'),
                value: 'right',
            },
        ];
        return {
            getThemes: getThemes,
            getDirections: getDirections,
            getTheme: getTheme,
            getDirection: getDirection,
        };

        function getThemes () {
            return $deepmerge([], vm.themes);
        }

        function getDirections () {
            return $deepmerge([], vm.directions);
        }

        function getTheme (theme) {
            if (!theme || theme === 'white') {
                return 'light';
            }
            return theme;
        }

        function getDirection (direction) {
            if (
                PAGE_ASSIST_SETTINGS.DIRECTION.LEFT === direction ||
                PAGE_ASSIST_SETTINGS.DIRECTION.RIGHT === direction
            ) {
                return direction;
            }

            return direction && direction.indexOf('right') > -1 ? 'right' : 'left';
        }
    }
})();
