import { ChartFactory } from './base-chart/chart-factory';

(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc service
     * @name DataPrivacyLineChartService
     * @description
     * Create the necessary objects to shape the Data Privacy line chart
     */
    angular.module('blocks.service').service('DataPrivacyLineChartService', DataPrivacyLineChartService);

    DataPrivacyLineChartService.$inject = ['gettextCatalog', 'LineChart', 'ChartsDataHelper'];

    function DataPrivacyLineChartService (gettextCatalog, LineChart, ChartsDataHelper) {
        let dataPrivacyLineChartService;
        function init () {
            dataPrivacyLineChartService = new ChartFactory(new LineChart());
            dataPrivacyLineChartService.setOptions([gettextCatalog.getString('Issues'), gettextCatalog.getString('Pages')]);
            dataPrivacyLineChartService.setColors(['#A94442', '#F0AD4E', '#31708F', '#ff9d4e', '#a9dad6']);
            dataPrivacyLineChartService.setOverrides([3, 2]);
            dataPrivacyLineChartService.setSeries([
                gettextCatalog.getString('High'),
                gettextCatalog.getString('Medium'),
                gettextCatalog.getString('Low'),
                gettextCatalog.getString('Pages with issues'),
                gettextCatalog.getString('Scanned pages'),
            ]);
            dataPrivacyLineChartService.setData([
                'data_protection_high_priority_page_violations_count',
                'data_protection_medium_priority_page_violations_count',
                'data_protection_low_priority_page_violations_count',
                'data_protection_pages_with_violations_count',
                'page_count'],
            new ChartsDataHelper('time'));
            return dataPrivacyLineChartService;
        }
        return init;
    }
})();
