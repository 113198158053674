(function () {
    'use strict';

    angular.module('core.config').config([
        '$qProvider',
        function ($qProvider) {
            $qProvider.errorOnUnhandledRejections(false);
        },
    ]);
})();
