(function () {
    'use strict';
    angular.module('modules.statistics').component('statisticsInformation', {
        templateUrl: 'app/modules/statistics/components/statistics-information/statistics-information.html',
        controller: StatisticsInformationController,
        controllerAs: 'vm',
        bindings: {
            title: '@?',
            value: '@?',
            noRuler: '<',
        },
    });

    StatisticsInformationController.$inject = [];
    function StatisticsInformationController () {
        var vm = this;
        vm.noRuler = vm.noRuler || false;
    }
})();
