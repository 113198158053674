(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyBoxes', {
        templateUrl: 'app/forms/global-policy/steps/rules/boxes/boxes.html',
        controller: PolicyBoxesController,
        controllerAs: 'vm',
        bindings: {
            policy: '=',
            elements: '<',
            tab: '=',
        },
    });

    PolicyBoxesController.$inject = [];

    /* @ngInject */
    function PolicyBoxesController () {
        var vm = this;
        vm.$onInit = activate;
        vm.switchTab = switchTab;

        function activate () {
            vm.tab = 'settings';
        }

        function switchTab (tabName) {
            vm.tab = tabName;
        }
    }
})();
