import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleReportAdminUsersComponent } from './schedule-report-admin-users.component';
import {
    ConfirmModule,
    LayoutModule,
    FormBuilderCheckboxModule,
    TableModule,
    TableSearchModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@layout/icons/icons.module';
import { AvatarModule } from 'ngx-avatar-2';
import { GetUserNameModule } from 'ng2/shared/pipes/get-user-name/get-user-name.module';
import { TranslateModule } from '@client/app/modules/translate.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [ScheduleReportAdminUsersComponent],
    imports: [
        CommonModule,
        LayoutModule,
        TranslateModule,
        ConfirmModule,
        FormsModule,
        IconsModule,
        FormBuilderCheckboxModule,
        AvatarModule,
        GetUserNameModule,
        TableModule,
        TableSearchModule,
        NgbModule,
    ],
    exports: [ScheduleReportAdminUsersComponent],
})
export class ScheduleReportAdminUsersModule {}
