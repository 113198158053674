import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonIssueModule } from '@monsido/modules/issue/issue.module';
import { IconsModule, LayoutModule, MonTableSortModule, TableModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { QaMisspellingIssueComponent } from './qa-misspelling-issue/qa-misspelling-issue.component';
import { LanguagesModule } from 'app/modules/languages-ng2/languages.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { QaBrokenIssueComponent } from './qa-broken-issue/qa-broken-issue.component';
import { SharedModule } from '@monsido/ng2/shared/shared.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MonIssueModule,
        TableModule,
        TranslateModule,
        IconsModule,
        NgbTooltipModule,
        LayoutModule,
        MonTableSortModule,
        LanguagesModule,
        NgbTooltipModule,
        SharedModule,
    ],
    exports: [],
    declarations: [
        QaBrokenIssueComponent,
        QaMisspellingIssueComponent,
    ],
    providers: [],
})
export class QaComponentsModule {}
