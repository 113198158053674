(function () {
    'use strict';

    /**
     * @memberof modules.accessibility
     * @ngdoc factory
     * @name AccessibilityTagsService
     * @description Service that will manage tag value translations from the Accessibility Help Center
     */
    angular.module('modules.accessibility').service('AccessibilityTagsService', AccessibilityTagsService);

    AccessibilityTagsService.$inject = ['ACCESSIBILITY_HELP_CENTER_TAG', 'gettextCatalog'];
    /* @ngInject*/
    function AccessibilityTagsService (ACCESSIBILITY_HELP_CENTER_TAG, gettextCatalog) {
        return {
            getTranslation: getTranslation,
            getClass: getClass,
        };

        /**
         * @memberOf AccessibilityTagsService
         * @desc Get the translation text from the Accessibility Help Center tags Constant
         * @param tagKey - Key value to look for in the Help Center Tags Constant
         */
        function getTranslation (tagKey) {
            var tag = ACCESSIBILITY_HELP_CENTER_TAG[tagKey];
            if (tag) {
                return gettextCatalog.getString(tag.translation);
            }
            var result = tagKey.replace(/_/g, ' ');
            result = result[0].toUpperCase() + result.substring(1);
            return result;
        }

        /**
         * @memberOf AccessibilityTagsService
         * @desc Get the translation text from the Accessibility Help Center tags Constant
         * @param tagKey - Key value to look for in the Help Center Tags Constant
         */
        function getClass (tagKey) {
            var tag = ACCESSIBILITY_HELP_CENTER_TAG[tagKey];
            if (tag && tag.styleClass.length > 0) {
                return tag.styleClass;
            }

            return 'tag-info';
        }
    }
})();
