(function () {
    'use strict';
    /**
     * Chart
     *
     * @memberof blocks.chart
     * @ngdoc factory
     * @name LineChart
     * @description
     * Creates series for the line chart
     */

    angular.module('modules.charts.models').factory('LineChart', LineChart);

    LineChart.$inject = ['Chart'];

    /* @ngInject */
    function LineChart (Chart) {
        function Model (attributes) {
            Chart.call(this, attributes);
            this.series = [];
        }

        Model.prototype = Object.create(Chart.prototype);
        Model.constructor = Model;
        Model.prototype.addSerie = addSerie;
        Model.prototype.addSeries = addSeries;

        /**
         * @memberOf LineChart
         * @description Add a serie to the series array
         * @param {String|Array} serie
         */
        function addSerie (serie) {
            if (angular.isArray(serie)) {
                this.addSeries(serie);
            } else {
                this.series.push(serie);
            }
        }

        /**
         * @memberOf LineChart
         * @description Add series to the series array
         * @param {Array} series
         */
        function addSeries (series) {
            if (!angular.isArray(series)) {
                throw new Exception('Input must be an array');
            }
            this.series = this.series.concat(series);
        }

        return Model;
    }
})();
