import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'mon-legacy-browser-notification',
    templateUrl: './legacy-browser-notification.component.html',
    styleUrls: ['./legacy-browser-notification.scss'],
})
export class MonLegacyBrowserNotificationComponent {
    @Input() monMinimized: boolean = false;
    @Output() monClose: EventEmitter<void> = new EventEmitter();
    @Output() monOpen: EventEmitter<void> = new EventEmitter();
}
