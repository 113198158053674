export const REGULATIONS = [
    {
        name: 'GDPR',
        full_name: 'General Data Protection Regulation',
        value: 'GDPR',
    },
    {
        name: 'CCPA',
        full_name: 'California Consumer Privacy Act',
        value: 'CCPA',
    },
    {
        name: 'APA',
        full_name: 'Australia Privacy Act',
        value: 'APA',
    },
    {
        name: 'SHIELD',
        full_name: 'New York SHIELD Act',
        value: 'SHIELD',
    },
    {
        name: 'PIPEDA',
        full_name: 'Personal Information Protection and Electronic Documents Act',
        value: 'PIPEDA',
    },
];

