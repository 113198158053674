(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceAuditDisplayNumeric', {
        templateUrl: 'app/modules/page-performance/components/display-modes/numeric/numeric.html',
        controller: PagePerformanceAuditDisplayNumericController,
        controllerAs: 'vm',
        bindings: {
            audit: '<',
        },
    });

    PagePerformanceAuditDisplayNumericController.$inject = ['ng2DisplayHelperService'];
    /* @ngInject */
    function PagePerformanceAuditDisplayNumericController (ng2DisplayHelperService) {
        var vm = this;
        vm.$onInit = activate;
        vm.headings = [];
        vm.items = [];

        function activate () {
            vm.hasDetails = ng2DisplayHelperService.hasDetails(vm.audit.details);
            vm.showTable = ng2DisplayHelperService.hasDetails(vm.audit.details) && (ng2DisplayHelperService.isDetailTypeTable(vm.audit.details.type) ||
            ng2DisplayHelperService.isDetailTypeOpportunity(vm.audit.details)) && ng2DisplayHelperService.hasDetailsTypeContent(vm.audit.details);

            if (vm.showTable) {
                vm.headings = ng2DisplayHelperService.getHeadings(vm.audit.details);
                vm.items = ng2DisplayHelperService.getItems(vm.audit.details);
            }
        }
    }
})();
