(function () {
    'use strict';

    angular.module('modules.behavior').run([
        'ng2MonEventsService',
        'behaviorService',
        function (ng2MonEventsService, behaviorService) {
            ng2MonEventsService.addListener('intercomShow', function () {
                behaviorService.openIntercom();
            });

            ng2MonEventsService.addListener('domainGroupAdded', function (params) {
                behaviorService.addDomainGroup(params.domain.url, params.group.title);
            });

            ng2MonEventsService.addListener('policyAdded', function (params) {
                behaviorService.addPolicy(params.name);
            });

            ng2MonEventsService.addListener('policyUpdated', function (params) {
                behaviorService.updatePolicy(params.name);
            });

            ng2MonEventsService.addListener('domainGroupUpdated', function (params) {
                behaviorService.updateDomainGroup(params.domain.url, params.group.title);
            });

            ng2MonEventsService.addListener('domainAdded', function (params) {
                behaviorService.addNewDomain(params.url, params.title);
            });

            ng2MonEventsService.addListener('userAdded', function (params) {
                behaviorService.addUser(params.email, params.customer_admin);
            });

            ng2MonEventsService.addListener('userDeleted', function (params) {
                behaviorService.deleteUser(params.email);
            });

            ng2MonEventsService.addListener('openFasttrack', function () {
                behaviorService.openFasttrack();
            });

            ng2MonEventsService.addListener('fasttrackIgnore', function (params) {
                behaviorService.fasttrackIgnore(params);
            });

            ng2MonEventsService.addListener('remediationBtnClick', function (params) {
                behaviorService.remediationBtnClick(params.url, params.title);
            });

            ng2MonEventsService.addListener('remediationCreated', function (params) {
                behaviorService.remediationCreated(params.url, params.title);
            });

            ng2MonEventsService.addListener('remediationMultiBtnClick', function (params) {
                behaviorService.remediationMultiBtnClick(params.files);
            });
        },
    ]);
})();
