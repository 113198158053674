import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Page } from '@monsido/modules/page-details/models/page.interface';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestOptionsType, RequestParamsType } from '@monsido/ng2/external/http/options';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';

type RageRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class PageRepo extends BaseRepo {
    constructor (private client: ApiClient, protected sessionService: SessionService) {
        super();
    }

    getPage (pageId: number): Promise<Page> {
        const domainId = this.getDomainId();
        if (domainId) {
            return this.client.getPromise(`domains/${domainId}/pages/${pageId}`);
        }
        throw (new Error('No domain id present'));
    }

    update (page: Page, params: RageRepoRequestType = {}): Promise<void> {
        const domainId = this.getDomainId();
        if (domainId) {
            params = this.setGroupId(params);
            const options: RequestOptionsType = {
                params,
            };
            return this.client.patchPromise(`domains/${domainId}/pages/${page.id}`, page, options);
        }

        throw (new Error('No domain id present'));
    }

}
