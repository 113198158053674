import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Injectable({
    providedIn: 'root',
})
export class StatisticsDateComparisonService {
    constructor (
        private translateService: TranslateService,
    ) {
        this.validateDate = this.validateDate.bind(this);
    }

    isFutureDate (date: Moment): boolean {
        return moment() < date;
    }

    validateDate (date: Moment): string | undefined {
        if (date.isValid() && this.isFutureDate(date)) {
            return this.translateService.getString('The date cannot be in the future');
        }
    }

}
