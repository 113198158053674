(function () {
    'use strict';

    angular.module('filters.monsido').filter('monIcons', MonIconsController);

    MonIconsController.$inject = ['MON_ICONS'];

    /* @ngInject */
    function MonIconsController (MON_ICONS) {
        return monIcons;

        // //////////

        function monIcons (icon) {
            var i;
                var result = MON_ICONS;
            for (i = 0; i < icon.length; i++) {
                result = result[icon[i]];
            }
            return result;
        }
    }
})();
