import { NgModule } from '@angular/core';
import { StatisticsDateComparisonService } from './services/date-comparison/statistics-date-comparison.service';
import { StatisticsPipesModule } from './pipes/statistics-pipes.module';
import { StatisticsContentModule } from './pages/content/statistics-content.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [
        TranslateModule,
        StatisticsPipesModule,
        StatisticsContentModule,
    ],
    exports: [],
    declarations: [],
    providers: [
        StatisticsDateComparisonService,
    ],
})
export class StatisticsModule { }
