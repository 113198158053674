import { Injectable } from '@angular/core';

export type DeviceDetectorBrowser = 'chrome' | 'edge' | 'firefox' | 'other';
export type DeviceDetectorOs = 'mac' | 'other';

@Injectable({
    providedIn: 'root',
})
export class DeviceDetectorService {
    get browser (): DeviceDetectorBrowser {
        const { userAgent } = window.navigator;
        if (userAgent.includes('Edg')) {
            return 'edge';
        }

        if (userAgent.includes('Chrome')) {
            return 'chrome';
        }

        if (userAgent.includes('Firefox')) {
            return 'firefox';
        }

        return 'other';
    }

    get os (): DeviceDetectorOs {
        const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        if (macosPlatforms.includes(window.navigator.platform)) {
            return 'mac';
        }

        return 'other';
    }

    isMobile (): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}
