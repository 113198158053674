(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceAuditDisplayNotApplicable', {
        templateUrl: 'app/modules/page-performance/components/display-modes/not-applicable/not-applicable.html',
        controller: PagePerformanceAuditDisplayNotApplicableController,
        controllerAs: 'vm',
        bindings: {
            audit: '<',
        },
    });

    PagePerformanceAuditDisplayNotApplicableController.$inject = [];
    /* @ngInject */
    function PagePerformanceAuditDisplayNotApplicableController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {}

        // PROTECTED
    }
})();
