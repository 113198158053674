(function () {
    'use strict';

    angular.module('pages.qa').component('qaSummaryReadability', {
        templateUrl: 'app/pages/qa/summary/readability/readability.html',
        controller: ReadabilityController,
        controllerAs: 'vm',
        bindings: {
            readabilitySummary: '<',
            progress: '<',
            loading: '<',
        },
    });

    ReadabilityController.$inject = ['$timeout'];

    function ReadabilityController ($timeout) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.mostUsesReadabilityLevel = null;
            setupReadability();
        }

        function setupReadability () {
            vm.progress.then(function () {
                $timeout(function () {
                    if (angular.isObject(vm.readabilitySummary)) {
                        if (
                            angular.isDefined(vm.readabilitySummary.distribution) &&
                            Object.keys(vm.readabilitySummary.distribution).length > 0
                        ) {
                            var distribution = Object.keys(vm.readabilitySummary.distribution).map(function (key) {
                                return { name: key, page_count: vm.readabilitySummary.distribution[key] };
                            });

                            distribution = distribution.sort(function (a, b) {
                                return a.page_count < b.page_count ? 1 : a.page_count > b.page_count ? -1 : 0;
                            });
                            vm.mostUsesReadabilityLevel = distribution[0];
                        }
                    }
                }, 100);
            }, angular.noop);
        }
    }
})();
