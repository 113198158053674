(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationMultipleH1', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/multiple-h1/multiple-h1.html',
        controller: SeoTranslationsMultipleH1Controller,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTranslationsMultipleH1Controller.$inject = [];
    /* @ngInject */
    function SeoTranslationsMultipleH1Controller () {
        var vm = this;
    }
})();
