(function () {
    'use strict';

    angular.module('pages.global', [
        'pages.global.changesHistory',
        'pages.global.policy',
        'base.product-updates',
        'pages.global.users',
    ]);
})();
