(function () {
    'use strict';

    angular.module('pages.qa').component('violationModules', {
        templateUrl: 'app/pages/qa/summary/violation-modules/violation-modules.html',
        controller: ViolationModulesController,
        controllerAs: 'vm',
        bindings: {
            qaSummary: '<',
            progress: '<',
        },
    });

    ViolationModulesController.$inject = ['ng2ActiveFeatureService'];

    function ViolationModulesController (ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.showDeadlinks = ng2ActiveFeatureService.isFeatureActivePure('qa_links');
            vm.showSpelling = ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck');
            vm.isOnlyQASummary = ng2ActiveFeatureService.isFeatureActive('qa_summary');
        }
    }
})();
