import { default as PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG } from '@client/app/modules/page-fix/constants/page-fix-accessibility-check-config.json';

type CategoryParamsType = {
    name: string,
    category: string,
}

type AccessibilityCheckConfig = {
    type: string,
    imagePath: string,
    snippet: string,
    translation: string,
    check_description: string,
    work_types: string[],
    difficulty: string,
    formComponent: string,
}

export type PageFixType = {
    change: {
        value: string,
        attribute: string,
        check_id: number,
        target: string[],
    },
    change_type: string,
    created_at: string,
    custom_selector: string,
    customer_id: number,
    documents_count: null,
    fixable_id: number,
    fixable_type: string,
    html_pages_count: null,
    id: number,
    selector: string,
    translatedCategories: string[],
    updated_at: string,
}

export class LightDomainPageFixllDashboardCategoryModel {
    name: string;
    totalFixes: number;
    fixes: number;
    fixCollection: PageFixType[];

    constructor (params: CategoryParamsType) {
        this.name = params.name;
        this.totalFixes = 0;
        this.fixes = 0;
        this.fixCollection = [];
        const category = params.category;
        for (const key in PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG) {
            if (PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG.hasOwnProperty(key)) {
                const config: AccessibilityCheckConfig = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[key];
                if (config.work_types?.find((type) => type === category)) {
                    this.totalFixes++;
                }
            }
        }
    }

    addPageFix (pageFix: PageFixType): void {
        this.fixes = this.fixCollection.push(pageFix);
    }
}
