(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name CheckTypeService
     * @description Get region details of current active region
     */
    angular.module('blocks.service').factory('CheckTypeService', CheckTypeService);

    CheckTypeService.$inject = [];
    /* @ngInject*/
    function CheckTypeService () {
        return {
            isFunction: isFunction,
        };

        /**
         * @memberOf CheckTypeService
         * @desc Check if input is of type function
         * @param {Function} fn
         * @return {boolean}
         */
        function isFunction (fn) {
            return typeof fn === 'function';
        }
    }
})();
