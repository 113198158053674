(function () {
    'use strict';

    angular.module('models').factory('User', User);

    function User () {
        var me = this;
        this.$onInit = getAvatarInitials;

        function Model (attributes) {
            angular.extend(this, attributes);
        }

        Model.prototype.getAvatarInitials = getAvatarInitials;
        Model.prototype.getName = getName;
        Model.prototype.getFirstName = getFirstName;
        Model.prototype.getLastName = getLastName;

        return Model;

        // /////////

        function getName () {
            var me = this;
            return [me.getFirstName(), me.getLastName()].join(' ').trim();
        }

        function getAvatarInitials () {
            var firstNameInitial = this
                .getFirstName()
                .charAt(0)
                .toUpperCase();
            var lastNameInitial = this
                .getLastName()
                .charAt(0)
                .toUpperCase();
            return firstNameInitial + lastNameInitial;
        }

        function getFirstName () {
            // Apparently first_name does not need to be set.

            var me = this;
            return (!angular.isString(me.first_name) ? '' : me.first_name).trim();
        }

        function getLastName () {
            // Apparently last_name does not need to be set.

            var me = this;
            return (!angular.isString(me.last_name) ? '' : me.last_name).trim();
        }
    }
})();
