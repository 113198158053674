import { NgModule } from '@angular/core';
import { BaseStateComponent } from '@layout/base-state.component';
import { IconsModule } from '@layout/icons/icons.module';
import { MonScrollableDropdownMenuComponent } from './scrollable-dropdown-menu/scrollable-dropdown-menu.component';
import { CommonModule } from '@angular/common';
@NgModule({
    imports: [IconsModule, CommonModule],
    declarations: [BaseStateComponent, MonScrollableDropdownMenuComponent],
    exports: [BaseStateComponent, IconsModule, MonScrollableDropdownMenuComponent],
})
export class BaseLayoutModule {}
