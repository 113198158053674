(function () {
    'use strict';

    angular.module('filters.monsido').filter('isString', isString);
    isString.$inject = [];

    /* @ngInject */
    function isString () {
        return function (input) {
            return angular.isString(input);
        };
    }
})();
