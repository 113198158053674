import { Injectable } from '@angular/core';
import moment, { Duration } from 'moment';


type DurationType = 'years' | 'months' | 'weeks'| 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';
type PeriodType = 'year' | 'month' | 'week'| 'day' | 'hour' | 'minute' | 'second' | 'millisecond';
type DateTimeInputType = {
    years?: number;
    year?: number;
    y?: number;

    months?: number;
    month?: number;
    M?: number;

    days?: number;
    day?: number;
    d?: number;

    dates?: number;
    date?: number;
    D?: number;

    hours?: number;
    hour?: number;
    h?: number;

    minutes?: number;
    minute?: number;
    m?: number;

    seconds?: number;
    second?: number;
    s?: number;

    milliseconds?: number;
    millisecond?: number;
    ms?: number;
}

type DateType = string | Date | undefined;

@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    public toDate (date: DateType, format?: string): Date {
        return moment(date, format).toDate();
    }

    public toUtcDate (date: DateType, format?: string): Date {
        return moment(date, format).utc()
            .toDate();
    }

    public addDuration (date: DateType, amount: number, type: DurationType, format?: string): Date {
        return moment(date, format).add(moment.duration(amount, type))
            .toDate();
    }

    public substractDuration (date: DateType, amount: number, type: DurationType, format?: string): Date {
        return moment(date, format).subtract(moment.duration(amount, type))
            .toDate();
    }

    public format (date: DateType, format: string, inputFormat?: string): string {
        return moment(date, inputFormat).format(format);
    }

    /**
     * @deprecated should not be used, should use format method, that use localization from the service
     */
    public formatBrowserLocalized (date: DateType, format: string, inputFormat?: string): string {
        const userLang = navigator.language;
        return moment(date, inputFormat).locale(userLang)
            .format(format);
    }

    public toISOString (date: DateType): string {
        return moment(date).toISOString();
    }

    public formatISO (date: DateType): string {
        return moment(date, moment.ISO_8601).format();
    }

    public isoDateToISOString (date: DateType): string {
        return moment(date, moment.ISO_8601).toISOString();
    }

    public timeDiffString (start: DateType, end: DateType): string {
        const diff = this.diff(start, end);
        const duration = this.duration(start, end);
        return Math.floor(duration.asHours()) + moment.utc(diff).format(':mm:ss');
    }

    public isoWeekDay (date: DateType): number {
        return moment(date).isoWeekday();
    }

    public weekDay (date: DateType): number {
        return moment(date).day();
    }

    public durationHumanized (start: DateType, end: DateType): string {
        return this.duration(start, end).humanize();
    }

    public durationFromNumberHumanized (amount: number, type: PeriodType): string {
        return moment.duration(amount, type).humanize();
    }

    public set (date: DateType, dateTimeToSet: DateTimeInputType): Date {
        return moment(date).set(dateTimeToSet)
            .toDate();
    }

    public utcOffset (date: DateType, utcOffset: number): Date {
        return moment(date).utcOffset(utcOffset)
            .toDate();
    }

    public day (date: DateType, day: string|number): Date {
        return moment(date).day(day)
            .toDate();
    }

    public startOf (date: DateType, type: PeriodType): Date {
        return moment(date).startOf(type)
            .toDate();
    }

    public endOf (date: DateType, type: PeriodType): Date {
        return moment(date).endOf(type)
            .toDate();
    }

    public setLocale (locale: string): void {
        moment.locale(locale);
    }

    public locale (date: DateType, locale: string): void {
        moment(date).locale(locale)
            .toDate();
    }

    public isBefore (dateOne: DateType, dateTwo: DateType): boolean {
        return moment(dateOne).isBefore(dateTwo);
    }

    public isAfter (dateOne: DateType, dateTwo: DateType, precision?: PeriodType): boolean {
        return moment(dateOne).isAfter(dateTwo, precision);
    }

    public isBetween (date: DateType, dateOne: DateType, dateTwo:DateType, format?: string): boolean {
        return moment(date, format).isBetween(dateOne, dateTwo);
    }

    public isSame (dateOne: DateType, dateTwo: DateType, precision: PeriodType): boolean {
        return moment(dateOne).isSame(dateTwo, precision);
    }

    public isValid (date: DateType): boolean {
        return moment(date).isValid();
    }

    public localeLongDateTimeFormat (): string {
        return `${moment().localeData()
            .longDateFormat('L')} ${moment().localeData()
            .longDateFormat('LT')}`;
    }

    public utcOffsetNumber (date: DateType): number {
        return moment(date).utcOffset();
    }

    public diff (start: DateType, end: DateType, type?: PeriodType): number {
        return moment(start).diff(end, type);
    }

    public duration (start: DateType, end: DateType): Duration {
        const diff = this.diff(start, end);
        return moment.duration(diff);
    }

    public get (date: DateType, type: PeriodType): number {
        return moment(date).get(type);
    }
}
