<div class="legacy-browser-notification-container" *ngIf="!monMinimized">
    <div class="notification-icon">
        <img src="images/internet-explorer.png" alt="Internet Explorer logo">
    </div>
    <div class="notification-content">
        <div class="notification-title">
            <span translate>Internet Explorer & Edge v.78 (or below) no longer supported</span>
            <button class="btn toggle-btn" type="button" (click)="monClose.emit()">
                <span class="fas fa-times fa-fw" aria-hidden="true"></span>
                <span class="sr-only" translate>Close</span>
            </button>
        </div>
        <div translate>
            From March 1, 2022, Acquia Optimize will no longer be supporting any version of Internet Explorer or Edge browser version 78 or below. While you will still be able to log on and use Acquia Optimize with Internet Explorer and older versions of Edge, we are no longer testing releases and fixing bugs specifically for those browsers. We encourage you to switch to another browser such as Chrome or newer version of Edge to be sure that Acquia Optimize continues to work optimally on your device.
        </div>
    </div>
</div>
<div class="legacy-browser-notification-container minimized" *ngIf="monMinimized">
    <button class="btn toggle-btn" (click)="monOpen.emit()">
        <span class="fas fa-info-circle fa-2x" aria-hidden="true"></span>
        <span class="sr-only">Open</span>
    </button>
</div>
