(function () {
    'use strict';

    angular.module('modules.page-performance').service('PerformanceAuditRelations', PerformanceAuditRelations);

    PerformanceAuditRelations.$inject = ['PAGE_PERFORMANCE_AUDIT_ID', 'PAGE_PERFORMANCE_METRICS'];

    function PerformanceAuditRelations (PAGE_PERFORMANCE_AUDIT_ID, PAGE_PERFORMANCE_METRICS) {
        var FCP = PAGE_PERFORMANCE_METRICS.FCP;
        var LCP = PAGE_PERFORMANCE_METRICS.LCP;
        var TBT = PAGE_PERFORMANCE_METRICS.TBT;
        var CLS = PAGE_PERFORMANCE_METRICS.CLS;
        var metrics = {};
        var audits = {};

        audits[FCP] = [
            PAGE_PERFORMANCE_AUDIT_ID.SERVER_RESPONCE_TIME,
            PAGE_PERFORMANCE_AUDIT_ID.RENDER_BLOCKING_RESOURCES,
            PAGE_PERFORMANCE_AUDIT_ID.REDIRECTS,
            PAGE_PERFORMANCE_AUDIT_ID.CRITICAL_REQUEST_CHAINS,
            PAGE_PERFORMANCE_AUDIT_ID.USES_TEXT_COMPRESSION,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRECONNECT,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRELOAD,
            PAGE_PERFORMANCE_AUDIT_ID.FONT_DISPLAY,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_CSS,
            PAGE_PERFORMANCE_AUDIT_ID.UNUSED_CSS_RULES,
            PAGE_PERFORMANCE_AUDIT_ID.BF_CACHE,
        ];
        audits[LCP] = [
            PAGE_PERFORMANCE_AUDIT_ID.SERVER_RESPONCE_TIME,
            PAGE_PERFORMANCE_AUDIT_ID.RENDER_BLOCKING_RESOURCES,
            PAGE_PERFORMANCE_AUDIT_ID.REDIRECTS,
            PAGE_PERFORMANCE_AUDIT_ID.CRITICAL_REQUEST_CHAINS,
            PAGE_PERFORMANCE_AUDIT_ID.USES_TEXT_COMPRESSION,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRECONNECT,
            PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRELOAD,
            PAGE_PERFORMANCE_AUDIT_ID.FONT_DISPLAY,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_CSS,
            PAGE_PERFORMANCE_AUDIT_ID.UNUSED_CSS_RULES,
            PAGE_PERFORMANCE_AUDIT_ID.LARGEST_CONTENTFUL_PAINT_ELEMENT,
            PAGE_PERFORMANCE_AUDIT_ID.UNUSED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.EFFICIENT_ANIMATED_CONTENT,
            PAGE_PERFORMANCE_AUDIT_ID.TOTAL_BYTE_WEIGHT,
            PAGE_PERFORMANCE_AUDIT_ID.PRIORITIZE_LCP,
        ];

        audits[TBT] = [
            PAGE_PERFORMANCE_AUDIT_ID.LONG_TASKS,
            PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_SUMMARY,
            PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_FACADES,
            PAGE_PERFORMANCE_AUDIT_ID.BOOTUP_TIME,
            PAGE_PERFORMANCE_AUDIT_ID.MAINTHREAD_WORK_BREAKDOWN,
            PAGE_PERFORMANCE_AUDIT_ID.DOM_SIZE,
            PAGE_PERFORMANCE_AUDIT_ID.DUPLICATED_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.LEGACY_JAVASCRIPT,
            PAGE_PERFORMANCE_AUDIT_ID.VIEWPORT,
        ];

        audits[CLS] = [
            PAGE_PERFORMANCE_AUDIT_ID.LAYOUT_SHIFT_ELEMENTS,
            PAGE_PERFORMANCE_AUDIT_ID.NON_COMPOSITED_ANIMATIONS,
            PAGE_PERFORMANCE_AUDIT_ID.UNSIZED_IMAGES,
        ];

        metrics[PAGE_PERFORMANCE_AUDIT_ID.SERVER_RESPONCE_TIME] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.RENDER_BLOCKING_RESOURCES] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.REDIRECTS] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.CRITICAL_REQUEST_CHAINS] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.USES_TEXT_COMPRESSION] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRECONNECT] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.USES_REL_PRELOAD] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.FONT_DISPLAY] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_JAVASCRIPT] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.UNMINIFIED_CSS] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.UNUSED_CSS_RULES] = [FCP, LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.LARGEST_CONTENTFUL_PAINT_ELEMENT] = [LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.UNUSED_JAVASCRIPT] = [LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.EFFICIENT_ANIMATED_CONTENT] = [LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.TOTAL_BYTE_WEIGHT] = [LCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.LONG_TASKS] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_SUMMARY] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.THIRD_PARTY_FACADES] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.BOOTUP_TIME] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.MAINTHREAD_WORK_BREAKDOWN] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.DOM_SIZE] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.DUPLICATED_JAVASCRIPT] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.LEGACY_JAVASCRIPT] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.VIEWPORT] = [TBT];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.LAYOUT_SHIFT_ELEMENTS] = [CLS];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.NON_COMPOSITED_ANIMATIONS] = [CLS];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.UNSIZED_IMAGES] = [CLS];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.BF_CACHE] = [FCP];
        metrics[PAGE_PERFORMANCE_AUDIT_ID.PRIORITIZE_LCP] = [LCP];

        return {
            metrics: metrics,
            audits: audits,
        };
    }
})();
