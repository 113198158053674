import { Injectable } from '@angular/core';
import { ApiService } from '../../api-service/api.service';
import { HttpContextToken } from '@angular/common/http';

type PagePerformanceType = {
    page_id: number;
    profile_id: number;
    status: string;
    created_at: string;
    error: string | null
}

type PagePerformanceCreateType = {
    page_id: number;
    profile_id: number;
};

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceRescanRepoService {

    constructor (private apiService: ApiService) { }

    get (params: PagePerformanceCreateType, contextToken?: HttpContextToken<unknown>): Promise<PagePerformanceType> {
        return this.apiService.get('performance/scans', params, {}, contextToken);
    }

    create (params: PagePerformanceCreateType, contextToken?: HttpContextToken<unknown>): Promise<PagePerformanceType> {
        return this.apiService.post('performance/scans', params, contextToken);
    }
}
