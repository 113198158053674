(function () {
    'use strict';

    angular.module('services.api').factory('PageSearchRepo', PageSearchRepo);

    PageSearchRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function PageSearchRepo (ng2ApiService, ng2SessionService) {
        var service = {
            getAll: getAll,
            update: update,
        };

        return service;

        // /////////////

        function getAll (pageId, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + pageId + '/searches', params, config);
        }

        function update (pageId, params, config) {
            config = config || {};
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + pageId + '/searches/' + params.id,
                params,
                null,
                config,
            );
        }
    }
})();
