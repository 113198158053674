import { Injectable } from '@angular/core';
import { AccessibilityIssueRepo } from '@monsido/modules/endpoints/api/accessibility-issue.repo';
import { GetPagesParamsInterface } from 'app/modules/qa/repos/qa-issue-repo.interface';
import { IssueInterface } from '../../interfaces/issue-interface';
import { SnippetInterface } from '../../interfaces/snippet-interface';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityIssueService {
    constructor (
        private accessibilityIssueRepo: AccessibilityIssueRepo,
    ) {}

    getAccessibilityIssues (sourceCodeId: number): Promise<IssueInterface[]> {
        return this.accessibilityIssueRepo.getAccessibilityIssue(sourceCodeId);
    }

    getAccessibilitySnippet (sourceCodeId: number): Promise<SnippetInterface> {
        return this.accessibilityIssueRepo.getAccessibilitySnippet(sourceCodeId);
    }

    deleteAccessibilityCheck (pageId: number, checkId: number): Promise<void> {
        return this.accessibilityIssueRepo.markCheckAsFixed(pageId, checkId);
    }

    deleteAccessibilityError (pageId: number, errorId: number): Promise<void> {
        return this.accessibilityIssueRepo.markIssueAsFixed(pageId, errorId);
    }

    updateAccessibilityError (pageId: number, error: Record<string, string | number | boolean>): Promise<unknown> {
        return this.accessibilityIssueRepo.updateError(pageId, error);
    }

    updateCheck (sourceCodeId: number, issue: IssueInterface): Promise<unknown> {
        return this.accessibilityIssueRepo.updateCheck(sourceCodeId, issue);
    }

    getAccessibilityIssuePages (id: number, params: GetPagesParamsInterface): Promise<unknown> {
        return this.accessibilityIssueRepo.getAccessibilityIssuePages(id, params);
    }

    getAccessibilityErrors (pageId: number, checkId: number, params: Record<string, string | number>): Promise<unknown> {
        return this.accessibilityIssueRepo.getAccessibilityErrors(pageId, checkId, params);
    }
}
