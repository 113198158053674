import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe<T> implements PipeTransform {
    transform (array: T[], propName: string, sortBy: string = 'asc'): T[] {
        return array.slice(0).sort((a: T, b: T): number => {
            if (typeof a[propName] === 'string') {
                const lowerA = a[propName].toLowerCase();
                const lowerB = b[propName].toLowerCase();
                switch (sortBy) {
                    case 'asc':
                        return lowerA.localeCompare(lowerB);
                    case 'desc':
                        return lowerB.localeCompare(lowerA);
                    default:
                        return lowerA.localeCompare(lowerB);
                }
            }
            return 0;
        });
    }
}
