(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.chat').factory('activeChatService', activeChatService);

    activeChatService.$inject = ['chatSystems', 'ng2SessionService'];
    /* @ngInject*/
    function activeChatService (chatSystems, ng2SessionService) {
        let activeSystemName = { system: 'none' };

        const computeActiveChat = () => {
            const intercomId = chatSystems.intercom.intercomId;
            let activeSystem = {
                system: 'intercom',
            };
            const selectedServer = ng2SessionService.getSelectedServer();
            for (const [key, value] of Object.entries(chatSystems.environments)) {
                if (key === selectedServer) {
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].entire_env === true) {
                            activeSystem = value[i];
                        } else if (ng2SessionService.customer && value[i].reseller === ng2SessionService.customer.reseller.id) {
                            activeSystem = value[i];
                        }
                    }
                }
            }
            if (activeSystem.system === 'intercom') {
                if (!(intercomId !== undefined && intercomId !== null && intercomId !== '')) {
                    activeSystem = { system: 'none' };
                }
            }
            return activeSystem;
        };


        ng2SessionService.customer$.subscribe((customer) => {
            if (customer) {
                activeSystemName = computeActiveChat();
            } else {
                activeSystemName = { system: 'none' };
            }
        });


        return {
            getActiveChat: getActiveChat,
        };

        function getActiveChat () {
            return activeSystemName;
        }
    }
})();
