import { Injectable } from '@angular/core';
import { PaOptionsInterface } from './pa-options.interface';
import { PAGE_ASSIST_SCRIPT_CONSTANT } from './pa-script.constant';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Injectable()
export class PaOptionsService {

    options: PaOptionsInterface = {} as PaOptionsInterface;

    constructor (private translateService: TranslateService) {
        this.options.themeItems = [
            {
                name: this.translateService.getString('Light'),
                value: 'light',
            },
            {
                name: this.translateService.getString('Dark'),
                value: 'dark',
            },
        ];

        this.options.directions = [
            {
                name: this.translateService.getString('Top left'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.TOPLEFT,
            },
            {
                name: this.translateService.getString('Top center'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.TOPCENTER,
            },
            {
                name: this.translateService.getString('Top right'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.TOPRIGHT,
            },
            {
                name: this.translateService.getString('Left middle'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.LEFT,
            },
            {
                name: this.translateService.getString('Right middle'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.RIGHT,
            },
            {
                name: this.translateService.getString('Bottom left'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.BOTTOMLEFT,
            },
            {
                name: this.translateService.getString('Bottom center'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.BOTTOMCENTER,
            },
            {
                name: this.translateService.getString('Bottom right'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.BOTTOMRIGHT,
            },
        ];

        this.options.icons = [
            {
                icon: 'fa-fw fa-2x fas fa-universal-access',
                value: PAGE_ASSIST_SCRIPT_CONSTANT.ICON.DEFAULT,
            },
            {
                icon: 'fa-fw fa-2x fab fa-accessible-icon',
                value: PAGE_ASSIST_SCRIPT_CONSTANT.ICON.WHEELCHAIR,
            },
            {
                icon: 'fa-fw fa-2x fas fa-heart',
                value: PAGE_ASSIST_SCRIPT_CONSTANT.ICON.HEART,
            },
            {
                icon: 'fa-fw fa-2x fas fa-brush',
                value: PAGE_ASSIST_SCRIPT_CONSTANT.ICON.BRUSH,
            },
        ];

        this.options.iconShapes = [
            {
                name: this.translateService.getString('Circle'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.ICON_SHAPE.CIRCLE,
            },
            {
                name: this.translateService.getString('Rounded Box'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.ICON_SHAPE.ROUNDED_BOX,
            },
        ];

        this.options.mainButtonBorderColor = PAGE_ASSIST_SCRIPT_CONSTANT.DEFAULT_SETTINGS.mainButtonBorderColor;

        this.options.languages = [
            {
                name: this.translateService.getString('English'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.ENUS,
            },
            {
                name: this.translateService.getString('Spanish Mexican'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.ESMX,
            },
            {
                name: this.translateService.getString('Danish'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.DADK,
            },
            {
                name: this.translateService.getString('German'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.DEDE,
            },
            {
                name: this.translateService.getString('Swedish'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.SVSE,
            },
            {
                name: this.translateService.getString('Norwegian Bokmål'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.NBNO,
            },
            {
                name: this.translateService.getString('Norwegian Nynorsk'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.NNNO,
            },
            {
                name: this.translateService.getString('Dutch'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.NLNL,
            },
            {
                name: this.translateService.getString('Estonian'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.ETEE,
            },
            {
                name: this.translateService.getString('French'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.FRFR,
            },
            {
                name: this.translateService.getString('Portuguese'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.PTPT,
            },
            {
                name: this.translateService.getString('Simplified Chinese'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.ZHCN,
            },
            {
                name: this.translateService.getString('Russian'),
                value: PAGE_ASSIST_SCRIPT_CONSTANT.LANGUAGE.RURU,
            },

        ];
    }
}
