(function () {
    'use strict';

    angular.module('modules.light.components').component('sideMenuLightConsentManager', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = [
        'gettextCatalog',
        '$filter',
        'ng2SessionService',
        'ng2ActiveFeatureService',
        'MON_MODULES',
    ];

    function SideMenuController (gettextCatalog, $filter, sessionService, ng2ActiveFeatureService, MON_MODULES) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.menu = [];
            consentManagerSideMenu();
        }

        function consentManagerSideMenu () {
            var isPageCorrectEnabled = ng2ActiveFeatureService.isFeatureActivePure(MON_MODULES.cookie_banner);
            if (isPageCorrectEnabled) {
                vm.menu.push(
                    {
                        title: gettextCatalog.getString('Cookie Acceptance Rate'),
                        icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CONSENT_OVERVIEW', 'COOKIE_BITE']),
                        href: 'base.customer.light.domain.cookie_acceptance_rate',
                    },
                    {
                        title: gettextCatalog.getString('Consent Log'),
                        icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CONSENT_OVERVIEW', 'CONSENT_LOG']),
                        href: 'base.customer.light.domain.consent_log',
                    },
                );
                cookieOverviewSideMenu();
            }
        }

        function cookieOverviewSideMenu () {
            var isAdmin = sessionService.hasRole('customer_admin');
            if (isAdmin) {
                vm.menu.push({
                    title: gettextCatalog.getString('Cookie Overview'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'DATA_PRIVACY', 'CONSENT_OVERVIEW', 'COOKIE']),
                    href: 'base.customer.light.domain.cookie_overview',
                });
            }
        }
    }
})();
