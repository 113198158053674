(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityGuidelinesWcag22', {
        templateUrl: 'app/pages/accessibility/guidelines/acc-standards/wcag-2-2/wcag-2-2.html',
        controller: AccessibilityGuidelinesAccWCAG22Controller,
        controllerAs: 'vm',
        bindings: {
            guidelines: '<',
            refreshPage: '&',
            progress: '<',
        },
    });

    AccessibilityGuidelinesAccWCAG22Controller.$inject = [];

    function AccessibilityGuidelinesAccWCAG22Controller () {}
})();
