(function () {
    'use strict';

    angular.module('pages.domain').component('captext', {
        templateUrl: 'app/pages/domain/captext/captext.html',
        controller: CaptextController,
        controllerAs: 'vm',
        bindings: {
            domainTitle: '<',
            domainItemCount: '<',
            maxPageCount: '<',
            scanDocuments: '<',
        },
    });

    CaptextController.$inject = ['intercomService'];
    /* @ngInject */
    function CaptextController (intercomService) {
        var vm = this;

        vm.getDomainTitle = getDomainTitle;
        vm.contact = contact;

        function contact () {
            intercomService.addMsg('I would like to chat to someone about increasing my page limit');
        }

        function getDomainTitle () {
            return '<span class="text-bold">' + vm.domainTitle + '</span>';
        }
    }
})();
