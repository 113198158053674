(function () {
    'use strict';

    angular.module('pages.admin.user').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.admin.users',
                config: {
                    url: '/users',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - User List') },
                },
            },
            {
                state: 'base.customer.admin.users.index',
                config: {
                    url: '',
                    template: '<admin-users></admin-users>',
                    data: {},
                },
            },
            {
                state: 'base.customer.admin.users.create',
                config: {
                    url: '/create',
                    template: '<admin-user></admin-user>',
                    data: {},
                },
            },
            {
                state: 'base.customer.admin.users.edit',
                config: {
                    url: '/{id:\\d+}/edit',
                    template: '<admin-user></admin-user>',
                    data: {},
                },
            },
        ];
    }
})();
