<div class="privacy-info-type-icon">
    <span *ngIf="monTooltipText"
          class="fa-stack fa-lg" 
          [ngClass]="colorClass"
          [ngbTooltip]="monTooltipText"
          placement="right">
      <span aria-hidden="true" class="fal fa-circle fa-stack-2x"></span>
      <span aria-hidden="true" class="fa-stack-1x" [ngClass]="iconClass"></span>
    </span>
    <span *ngIf="!monTooltipText"
          class="fa-stack fa-lg" 
          [ngClass]="colorClass"
          [ngbTooltip]="monTooltipText"
          placement="right">
      <span aria-hidden="true" class="fal fa-circle fa-stack-2x"></span>
      <span aria-hidden="true" class="fa-stack-1x" [ngClass]="iconClass"></span>
    </span>
  </div>
  