(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name PagePerformanceAssignedProfile
     * @description Service to handle Page Performance Assigned Profiles
     */
    angular.module('services.entities').factory('PagePerformanceAssignedProfile', PagePerformanceAssignedProfile);

    PagePerformanceAssignedProfile.$inject = ['PagePerformanceAssignedProfileRepo'];
    /* @ngInject*/
    function PagePerformanceAssignedProfile (PagePerformanceAssignedProfileRepo) {
        return {
            getAll: getAll,
            destroy: destroy,
            create: create,
            update: update,
        };

        function create (assignedProfile, params, config) {
            return PagePerformanceAssignedProfileRepo.create(assignedProfile, params, config);
        }

        function destroy (assignedProfile, params, config) {
            return PagePerformanceAssignedProfileRepo.destroy(assignedProfile, params, config);
        }

        function getAll (params, contextToken) {
            return PagePerformanceAssignedProfileRepo.getAll(params, contextToken);
        }

        function update (assignedProfile, params, config) {
            return PagePerformanceAssignedProfileRepo.update(assignedProfile, params, config);
        }
    }
})();
