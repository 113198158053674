import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { User } from '@monsido/ng2/modules/models/api/user';
import { UserInterface } from '@monsido/ng2/modules/models/api/interfaces/user.interface';
import { CollectionInterface } from '@monsido/angular-shared-components/dist/angular-shared-components/lib/interfaces/collection.interface';
import { RequestParamsType } from '@monsido/ng2/external/http/options';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type UserRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class UserRepoService extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService, private monEventsService: MonEventService) {
        super();
    }

    get (id: number, params: UserRepoRequestType = {}): Promise<User> {
        params = this.setGroupId(params);
        return this.apiService.get<UserInterface>('users/' + id, params).then(this.toModel);
    }

    destroy (user): Promise<void> {
        return this.apiService.delete('users/' + user.id).then(
            () => {
                this.monEventsService.run('userDeleted', user);
            },
            () => {},
        );
    }

    create (params): Promise<User | void> {
        return this.apiService.post<UserInterface>('users', params).then(
            (user: UserInterface) => {
                this.monEventsService.run('userAdded', user);
                return this.toModel(user);
            },
            () => {},
        );
    }

    update (params: UserRepoRequestType): Promise<User | void> {
        return this.apiService.patch<UserInterface>('users/' + params.id, params).then(this.toModel);
    }

    getAll (params: UserRepoRequestType = {}): Promise<CollectionInterface<User>> {
        params = this.setGroupId(params);
        return this.apiService.get<CollectionInterface<UserInterface>>('users', params).then((res)=>{
            return this.toModels(res);
        });
    }

    toModels (models: CollectionInterface<UserInterface>): CollectionInterface<User> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    toModel (model: UserInterface): User {
        return new User(model);
    }
}


