import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MonDataPrivacyInfoTypeIconComponent } from './info-type-icon.component';
import { MonDataPrivacyInfoTypeIconService } from './info-type-icon.service';

@NgModule({
    imports: [CommonModule, NgbTooltipModule],
    declarations: [MonDataPrivacyInfoTypeIconComponent],
    exports: [MonDataPrivacyInfoTypeIconComponent],
    providers: [MonDataPrivacyInfoTypeIconService],
})
export class MonDataPrivacyInfoTypeIconModule {}
