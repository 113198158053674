(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('tablePageDetails', {
        controller: PageDetailsBtnsController,
        templateUrl: 'app/components/pagedetails/components/icon/page-details.html',
        controllerAs: 'vm',
        bindings: {
            page: '<',
            callBack: '&?',
            target: '<',
            subTarget: '<',
            ref: '@?',
            infoType: '<?',
        },
    });

    PageDetailsBtnsController.$inject = [
        'API_CONSTANTS',
        'gettextCatalog',
        'ng2GlobalHelperService',
        'ng2MonEventsService',
        'MON_EVENTS',
        'ng2ActiveFeatureService',
        'Lodash',
    ];

    /* @ngInject */
    function PageDetailsBtnsController (
        API_CONSTANTS,
        gettextCatalog,
        ng2GlobalHelperService,
        ng2MonEventsService,
        MON_EVENTS,
        ng2ActiveFeatureService,
        Lodash,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToPageDetails = goToPageDetails;
        vm.hasAccessToDetailsOverlay = ng2ActiveFeatureService.isFeatureActive('page_details');

        function activate () {
            vm.pageTypes = API_CONSTANTS.PAGES.TYPE;
            vm.openPageDetailsMsg = gettextCatalog.getString('Open page details', 'Aria label when clicking on open pagedetails');
            vm.moduleName = ng2GlobalHelperService.getCurrentModule();
        }

        function goToPageDetails () {
            var data = {
                page: vm.page,
                target: vm.target,
                subTarget: vm.subTarget,
                ref: vm.ref,
                infoType: vm.infoType,
            };

            var params = {
                body: 'pageDetails',
                size: 'page-details',
                classes: 'page-details-app',
                data: data,
            };

            const options = {
                params,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options, function () {
                if (angular.isFunction(vm.callBack)) {
                    vm.callBack();
                }
            });
        }
    }
})();
