(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsVisitorsBrowsers', {
        templateUrl: 'app/pages/statistics/visitors/browsers/browsers.html',
        controller: StatisticsVisitorsController,
        controllerAs: 'vm',
    });

    StatisticsVisitorsController.$inject = [
        'ng2StatsRepoService',
        'API_CONSTANTS',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        '$filter',
        'moment',
        'ng2SessionService',
        'dateRangeService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsVisitorsController (
        ng2StatsRepoService,
        API_CONSTANTS,
        $stateParams,
        ParamService,
        gettextCatalog,
        $filter,
        moment,
        ng2SessionService,
        dateRangeService,
        DATE_FORMAT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.loading = false;
            vm.chartType = 'bar-chart';
            vm.pages = [];
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;
            vm.summary = {};

            setupChart();
            updateDateRange();
            getSummary();
            getPage();
        }

        function setupChart () {
            vm.chartLabels = [];
            vm.chartData = [];
            vm.barData = [];
            vm.doughnutColors = ['#FF5879', '#FF9439', '#FEC64B', '#42B8B8', '#2F98E9'];
            vm.barOverride = [
                {
                    borderWidth: 0,
                    borderColor: '#50a6fb',
                    backgroundColor: '#50a6fb',
                },
            ];
            vm.doughnutOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    onHover: function (element) {
                        element.target.style.cursor = 'pointer';
                    },
                    onLeave: function (element) {
                        element.target.style.cursor = 'default';
                    },
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        title: function () {
                            return gettextCatalog.getString('Visits');
                        },
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
            vm.barOptions = {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]) +
                                ' ' +
                                gettextCatalog.getString('Visits')
                            );
                        },
                    },
                },
            };
        }

        function getPage () {
            var params = {
                limit: vm.pageSize || API_CONSTANTS.PAGE_SIZE,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };
            vm.exports = [
                {
                    tooltip: gettextCatalog.getString('Export visitors browsers'),
                    name: gettextCatalog.getString('Visitors browsers export'),
                    data: {
                        category: 'visitors_browsers',
                        category_ref: vm.date.startDate?.format('YYYY-MM-DD') + ',' + vm.date.endDate?.format('YYYY-MM-DD'),
                    },
                },
            ];
            var others = 0;
            vm.loading = true;
            vm.promise = ng2StatsRepoService.getBrowsers(params)
                .then(function (data) {
                    vm.pages = data;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);
                    vm.chartLabels = [];
                    vm.chartData = [];
                    for (var i = 0; i < vm.pages.length; i++) {
                        if (i < 5) {
                            vm.chartLabels.push(vm.pages[i].label);
                            vm.chartData.push(vm.pages[i].visits);
                        } else {
                            others += parseInt(vm.pages[i].visits);
                        }
                    }
                    if (vm.pages.length > 5) {
                        vm.chartLabels.push(gettextCatalog.getString('Others'));
                        vm.chartData.push(others);
                    }

                    vm.barData = [vm.chartData];
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function updateDateRange () {
            if (vm.date.startDate || vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
                getPage();
            }
        }

        function getSummary () {
            var params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
                type: 'total',
            };
            ng2StatsRepoService.getSummary(params)
                .then(function (data) {
                    vm.summary = data;
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }
    }
})();
