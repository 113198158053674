<div>
    <div class="mon-dialog-header">
        <div class="row">
            <div class="col-48 outer-t-smurf">
                <mon-page-header header="{{'Add non users' | translate}}"
                                 panel-class="row"
                                 panel-left-class="col-48 outer-t-mini">
                </mon-page-header>
            </div>
        </div>
    </div>
    <div class="mon-dialog-body-with-footer">
        <div class="card panel-monsido-card outer-t outer-r outer-l">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Input user data</h3>
            </div>
            <div class="card-body">
                <form #tempUserForm="ngForm" (ngSubmit)="tempUserForm.valid && selectTempUser()" novalidate>
                    <div class="form-group row mb-1">
                        <label
                                for="first_name"
                                class="col-sm-8 col-form-label text-bold capitalize-first-letter text-right"
                                translate>
                            First name *</label>
                        <div class="col-sm-40">
                            <input type="text"
                                   class="form-control"
                                   name="first_name"
                                   id="first_name"
                                   #firstNameInput="ngModel"
                                   required
                                   [(ngModel)]="firstName"
                                   [placeholder]="'First name' | translate" />
                            <mon-form-field-errors-ac
                                    [offset]="0"
                                    [formElementControl]="firstNameInput.control"
                                    ></mon-form-field-errors-ac>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label
                                for="last_name"
                                class="col-sm-8 col-form-label text-bold capitalize-first-letter text-right"
                                translate>
                            Last name *</label>
                        <div class="col-sm-40">
                            <input type="text"
                                   class="form-control"
                                   name="last_name"
                                   id="last_name"
                                   #lastNameInput="ngModel"
                                   required
                                   [(ngModel)]="lastName"
                                   [placeholder]="'Last name' | translate" />
                            <mon-form-field-errors-ac
                                    [offset]="0"
                                    [formElementControl]="lastNameInput.control"
                                    ></mon-form-field-errors-ac>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <label
                                for="last_name"
                                class="col-sm-8 col-form-label text-bold capitalize-first-letter text-right"
                                translate>
                            Email *</label>
                        <div class="col-sm-40">
                            <input type="email"
                                   class="form-control"
                                   monEmailValidator
                                   name="email"
                                   id="email"
                                   #emailInput="ngModel"
                                   required
                                   [(ngModel)]="email"
                                   [placeholder]="'Email' | translate" />
                            <mon-form-field-errors-ac
                                    [offset]="0"
                                    [formElementControl]="emailInput.control"
                                    ></mon-form-field-errors-ac>
                        </div>
                    </div>
                    <button
                            data-test="add-button"
                            type="submit"
                            class="btn btn-border-radius btn-primary pull-right mt-2"
                            translate
                            [disabled]="tempUserForm.invalid">
                        Add</button>
                </form>
            </div>
        </div>
        <div class="card panel-monsido-card outer-t outer-r outer-l">
            <div class="card-header">
                <h3 class="font-weight-300" translate>Non-user list</h3>
            </div>
            <div class="card-body">
                <table class="table table-fixed table-hover">
                    <tbody>
                        <tr *ngFor="let user of selectedTempUsers; index as userIndex">
                            <td class="text-v-middle">
                                <div class="d-flex text-ellipsis">
                                    <div class="ml-8 text-ellipsis">
                                        <a>{{ user.first_name }} {{ user.last_name}}</a>
                                        <br/>
                                        <div class="d-flex text-ellipsis">
                                            <div class="pr-1">
                                                <a class="fas fa-envelope"></a>
                                            </div>
                                            <div class="text-grey text-ellipsis">
                                                {{ user.email }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-right text-v-middle">
                                <button class="delete-non-user btn btn-secondary"
                                        type="button"
                                        monConfirm="{{'Are you sure you want to remove manually added user?' | translate}}"
                                        [monPromptOptions]="promptOptions"
                                        (monConfirmAction)="removeTempUser(userIndex)">
                                    <span class="sr-only"></span>
                                    <span [ngClass]="['ACTIONS', 'REMOVE'] | monIcons" aria-hidden="true"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="mon-dialog-footer">
        <div class="row">
            <div class="col-md-48 text-right">
                <button
                        class="btn btn-border-radius btn-secondary"
                        type="button"
                        (click)="close()"
                        translate>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
