import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { Report } from '@monsido/modules/models/api/report';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ScheduleReportAdminComponent } from '../dialogs/schedule-report-admin/schedule-report-admin.component';
import { ScheduleReportFormComponent } from '../dialogs/schedule-report-form/schedule-report-form.component';
import { ScheduledReportsListComponent } from '../dialogs/scheduled-reports-list/scheduled-reports-list.component';
import type { EnhancedReport } from '../dashboard/types';
import { ReportCenterDashboardService } from '../../services/dashboard/report-center-dashboard.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'mon-scheduled-report',
    templateUrl: './scheduled-report.component.html',
    styleUrls: ['./scheduled-report.component.scss'],
})
export class ScheduledReportComponent implements OnInit {
    @Input() admin?: boolean = false;
    @Input() set reports (newReports: Report[]) {
        this._reports = newReports;

        this.enhancedReports = newReports.map(report => {
            const uniqueUsers = this.dashboardService.getUniqueUsersByIdFromReport(report);
            const enhancedReport: EnhancedReport = cloneDeep(report);
            enhancedReport.users = uniqueUsers;

            return enhancedReport;
        });
    }
    get reports (): Report[] {
        return this._reports;
    }
    @Output() getPage = new EventEmitter();

    maxTargets = 2;
    headerText = '';
    enhancedReports: EnhancedReport[] = [];
    private _reports: Report[] = [];

    constructor (
        private eventsService: MonEventService,
        private dashboardService: ReportCenterDashboardService,
        private translateService: TranslateService,
    ) {}

    ngOnInit (): void {
        this.headerText = this.admin
            ? this.translateService.getString('All scheduled reports')
            : this.translateService.getString('My scheduled reports');
    }

    editReport (report: EnhancedReport): void {
        if (this.admin) {
            this.eventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
                component: ScheduleReportAdminComponent,
                data: { report },
                dialogOptions: {
                    cb: (reportFromDialog: Report) => {
                        if (reportFromDialog) {
                            this.getPage.emit();
                        }
                    },
                    size: 'fw',
                },
            });
        } else {
            this.eventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
                component: ScheduleReportFormComponent,
                data: { report },
                dialogOptions: {
                    cb: (reportFromDialog: Report) => {
                        if (reportFromDialog) {
                            this.getPage.emit();
                        }
                    },
                    size: 'sm',
                },
            });
        }
    }

    showMore (): void {
        this.eventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, {
            component: ScheduledReportsListComponent,
            data: { reports: this.reports },
            dialogOptions: {
                size: 'sm',
                cb: () => {
                    this.getPage.emit();
                },
            },
        });
    }

}
