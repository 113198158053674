/* eslint-disable max-len */
export type DemoDataDecisionTreeType = {
    id: number,
    title: string,
    description: string,
    check_id: number[],
    questions: {
        id: string,
        title: string,
        text: string,
        positive_action: string | {
            solution_text: string,
        },
        negative_action: string | {
            solution_text: string,
        },
        positive_text: string,
        negative_text: string,
    }[]
};

export const DEMO_DATA_DECISION_TREE: DemoDataDecisionTreeType[] = [
    {
        id: 1,
        title: 'Alt Text Decision Tree',
        description:
                    'Our alt text for images decision tree describes how the alt attribute of the &lt;img&gt; element should be used in a number of common situations.',
        check_id: [1, 2, 3, 4, 5, 6, 7, 8, 11, 16],
        questions: [
            {
                id: 'hash1',
                title: 'Does the image serve a purely decorative purpose',
                text:
                            'Decorative images serve a purely decorative purpose and do not convey any meaningful information. As decorative images should be ignored by assistive technologies, such as screen readers, a null alt text should be provided (alt= ‘‘ ’’).',
                positive_action: {
                    solution_text:
                                'Images that serve a purely decorative purpose and do not convey any meaningful information should have a null Alt attribute ( Alt= ““)',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_8'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title: 'Does the image communicate information or conceptual ideas?',
                text:
                            'Informative images serve to communicate information or conceptual ideas and include illustrations, pictures, and photographs. A text alternative should be provided for such images that conveys the meaning or content of what is being displayed visually. ',
                positive_action: {
                    solution_text:
                                'A short text alternative should be added that conveys the fundamental nature or contents of the image.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash3', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_8'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash3',
                title:
                            'Does the image serve a functional purpose (i.e. is it used as a clickable link or button?)',
                text:
                            'Functional images, such as links or buttons, are used to initiate actions rather than to convey information. A text alternative should be provided for functional images that conveys the action that will be initiated by the image.',
                positive_action: {
                    solution_text:
                                'Functional images should be accompanied by Alt attribute that explains their function, rather than the nature of the image itself.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash4', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_8'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash4',
                title: 'Is the image of text that is intended to be read?',
                text:
                            'Images of text display text that is intended to be read. Unlike genuine text, however, it is not flexible and may, for example, lose clarity if resized. A text alternative should be provided for images of text that contains the same text presented in the image.',
                positive_action: {
                    solution_text:
                                'Unless the text is already present nearby on the page, an Alt attribute should be added that provides the same words that are contained in the image. If the text is present nearby, the image can be treated as decorative (see Question 1) ',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash5', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_8'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash5',
                title: 'Does the image convey complex information?',
                text:
                            'Complex images, such as diagrams and graphs, serve to convey complex information that cannot be conveyed in a short phrase or sentence. Complex images should be accompanied by a two-part alt text alternative. The first part should identify the image and, where appropriate, indicate the location of the long description. The second part should provide a textual description of the fundamental information conveyed by the image.',
                positive_action: {
                    solution_text:
                                'Images that serve to convey complex information, such as diagrams and graphs, should be accompanied by a corresponding Alt attribute that provides a text equivalent that fully describes the information or data communicated by the image.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash6', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_8'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash6',
                title: 'Are there a number of images being used to convey a single message?',
                text:
                            'Groups of images are when a number of images are used together to convey a single piece of information. An single alternative text that serves to describe the entire selection should be applied to one of the images in the group, with the rest having a null (empty) alt attribute.',
                positive_action: {
                    solution_text:
                                'In instances where a number of images are used to convey a single message, the Alt attribute provided for any one of the images should also serve to convey the message for the group as a whole.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash7', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_8'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash7',
                title:
                            'Is the image divided into selectable regions defined by elements (‘‘hotspots’’) that allow user interaction?',
                text:
                            'An image map refers to an image that is divided into selectable regions that allow user interaction. Image maps should have alt text applied to both the &lt;img&gt; element itself to identify the informative context, and to all &lt;area&gt; elements to identify the link destination or the action that will result in the link being followed.',
                positive_action: {
                    solution_text:
                                'Image maps that contain a number of clickable areas should always be accompanied by an Alt attribute that puts the links into context. Furthermore, every clickable area must be accompanied by its own individual Alt attribute that communicates the links destination or purpose. ',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash8', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash8',
                title: 'Is the alt text the same as the filename?',
                text: '...',
                positive_action: {
                    solution_text:
                                'Check with the author to confirm that the alt text is correct and should be the same as the filename. ',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'If your Img is not covered in our decision tree, why not check out W3’s detailed <a href="https://www.w3.org/WAI/tutorials/images/" target="_blank" aria-describedby="linkOpensInNewTab">overview of image concepts</a>?',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 2,
        title: 'Content on Hover or Focus Decision Tree',
        description: '',
        check_id: [318],
        questions: [
            {
                id: 'hash1',
                title:
                            'Does your website include additional content that is triggered to appear and disappear as a result of pointer hover or keyboard focus?',
                text: '',
                positive_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title:
                            'Is the additional content dismissable (i.e. Do users have the ability to dismiss it without moving keyboard focus or pointer hover) ?',
                text: '',
                positive_action: 'hash3', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Ensure that additional content can be dismissed by the user either by positioning the additional content so that it does not obscure any other content including the trigger, or by providing a mechanism that allows users to easily dismiss the additional content, such as pressing Escape.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash3',
                title:
                            'Is the additional content hoverable (i.e. Do users have the ability to move over additional content that is triggered by pointer hover) ?',
                text: '',
                positive_action: 'hash4', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Ensure that additional content is hoverable by users, for example, by moving the mouse pointer directly from the trigger onto the new content once it is triggered.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash4',
                title:
                            'Is the additional content persistent (i.e. Does the content remain visible until it is dismissed by the user, the hover or focus trigger is removed, or the information it communicates is no longer valid) ?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Take steps to ensure that additional content remains visible until the user removes hover or focus from the trigger/additional content, the information conveyed by the content becomes invalid, or the user dismisses it via a mechanism provided.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 3,
        title: 'Content on Hover or Focus Decision Tree',
        description: '',
        check_id: [320],
        questions: [
            {
                id: 'hash1',
                title: 'Does your Website feature motion animation that is triggered by user inaction?',
                text: '',
                positive_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Take steps to ensure that additional content remains visible until the user removes hover or focus from the trigger/additional content, the information conveyed by the content becomes invalid, or the user dismisses it via a mechanism provided.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title:
                            'Is the additional content persistent (i.e. Does the content remain visible until it is dismissed by the user, the hover or focus trigger is removed, or the information it communicates is no longer valid) ?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Ensure that the triggered animation can be disabled by, for example, allowing users to set a preference that prevents animation.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 4,
        title: 'Timeouts Decision Tree',
        description: '',
        check_id: [319],
        questions: [
            {
                id: 'hash1',
                title:
                            'Are visitors to your website warned of the duration of any inactivity that may cause data loss?',
                text: '',
                negative_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_action: {
                    solution_text: 'No actions are required',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title: 'Is the data preserved for more than 20 hours when the user does not take any actions?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Take steps to ensure that user data is stored for a minimum of 20 hours or provide a notification informing users of the duration of inactivity that will result in data loss at the beginning of a process. A session timeout should also be set to take place following at least 20 hours of user inactivity. ',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 5,
        title: 'Motion Actuation Decision Tree',
        description: '',
        check_id: [326],
        questions: [
            {
                id: 'hash1',
                title:
                            'Provide users with an application setting for motion activated input and conventional controls. ',
                text: '',
                positive_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Take steps to ensure that additional content remains visible until the user removes hover or focus from the trigger/additional content, the information conveyed by the content becomes invalid, or the user dismisses it via a mechanism provided.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title:
                            'Do visitors to your website have the option to disable any functionality that is operable through user or device motion?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash3', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash3',
                title:
                            'Is the motion used to operate functionality through an accessibility supported interface or so essential to the function that disabling it would invalidate the activity?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Provide users with the option to disable motion actuation by supporting system level features that allow them to do so.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 6,
        title: 'Non-Text Contrast Decision Tree',
        description: '',
        check_id: [312],
        questions: [
            {
                id: 'hash1',
                title:
                            'Do user interface components and graphical objects on your website have a contrast ratio of at least 3.1 against adjacent color(s)?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title:
                            'Do the graphical objects rely on their particular presentation in order to successfully convey information?',
                text: '',
                positive_action: {
                    solution_text:
                                'If the particular presentation of graphical objects is required to successfully convey information  a contrast ratio of at least 3.1 against adjacent color(s) is not required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash3', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash3',
                title:
                            'Are the appearance of user interface components determined by the user agent and not modified by the author?',
                text: '',
                positive_action: {
                    solution_text:
                                'User interface components with an appearance that is determined by the user agent and not modified by the author do not require a contrast ratio of at least 3.1 against adjacent color(s).',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash4', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash4',
                title: 'Are the user interface components inactive?',
                text: '',
                positive_action: {
                    solution_text:
                                'Inactive user interface components do not require a contrast ratio of at least 3.1 against adjacent color(s).',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Take steps to ensure that active user interface components have a contrast ratio of at least 3.1 against adjacent color(s).',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 7,
        title: 'Error Suggestion Decision Tree',
        description: '',
        check_id: [268],
        questions: [
            {
                id: 'hash1',
                title:
                            'Do form submission error messages on your website provide assistance in correcting the error?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title: 'Is the error automatically detected and are suggestions for correction known?',
                text: '',
                positive_action: 'hash3', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash3',
                title:
                            'Would providing submission error correction suggestions jeopardize the security or purpose of the content?\n',
                text: '',
                positive_action: { solution_text: 'No action is required.' }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Modify form submission error messages so they provide assistance in correcting the error.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 8,
        title: 'Orientation Decision Tree',
        description: '',
        check_id: [313],
        questions: [
            {
                id: 'hash1',
                title:
                            'Does the content on your website restrict its view and operation to a single display orientation, such as portrait or landscape?',
                text: '',
                positive_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title: 'Is the specific display orientation essential to the content?',
                text: '',
                positive_action: { solution_text: 'No actions are required.' }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Provide users with a control that allows access to content in different orientations which are otherwise restricted.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 9,
        title: 'Error Prevention Decision Tree',
        description: '',
        check_id: [269],
        questions: [
            {
                id: 'hash1',
                title: 'Is all form submission data presented to the users before final acceptance?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title: 'Is the form submission data irreversible once it has been submitted?',
                text: '',
                positive_action: {
                    solution_text:
                                'Modify the form submission process so the user is shown the data and allowed to correct it before final acceptance.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: { solution_text: 'No actions are required.' }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
    {
        id: 10,
        title: 'Non-Text Content Decision Tree',
        description: '',
        check_id: [60],
        questions: [
            {
                id: 'hash1',
                title:
                            'Do input elements on your website have an alt attribute value of fewer than 100 characters?',
                text: '',
                positive_action: {
                    solution_text: 'No actions are required.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: 'hash2', // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
            {
                id: 'hash2',
                title: 'Is the alt text provided the shortest possible for the image(s)?',
                text: '',
                positive_action: { solution_text: 'No actions are required.' }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                negative_action: {
                    solution_text:
                                'Update the alt text so that it is as short as possible and under 100 characters.',
                }, // { solution_text: 'Add alt text now!' } || 'some_id_or_key_5'
                positive_text: 'Yes',
                negative_text: 'No',
            },
        ],
    },
];
