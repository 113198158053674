(function () {
    'use strict';

    angular.module('modules.heatmaps').component('heatmapsDashboardTable', {
        templateUrl: 'app/modules/heatmaps/pages/dashboard/table/table.html',
        controller: HeatmapsDashboardController,
        controllerAs: 'vm',
        bindings: {
            rules: '<',
            getPage: '&',
            loading: '<',
            domains: '<',
            onPageChange: '&?',
            onPerPageChange: '&?',
        },
    });

    HeatmapsDashboardController.$inject = ['HeatmapService', 'HeatmapApiService', 'monDialog', 'ng2SessionService', 'gettextCatalog'];

    /* @ngInject */
    function HeatmapsDashboardController (HeatmapService, HeatmapApiService, monDialog, ng2SessionService, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateRule = updateRule;
        vm.removeRule = removeRule;
        vm.addRule = addRule;
        vm.canAddMoreRules = canAddMoreRules;
        vm.onClosePageSearch = onClosePageSearch;
        vm.$onChanges = onChanges;

        function activate () {
            vm.showSearch = false;
            vm.selectedRule = null;
        }

        function onChanges (changes) {
            if (changes.rules) {
                updateDropdowns();
            }
        }

        function updateDropdowns () {
            for (let i = 0; i < vm.rules.length; i++) {
                const rule = vm.rules[i];

                const dropdown = [
                    {
                        label: gettextCatalog.getString('Replace Rule'),
                        leftIcon: 'faRetweet',
                        action: ()=>updateRule(rule),
                    },
                    {
                        label: gettextCatalog.getString('Remove Rule'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>removeRule(rule, i),
                    },
                ];

                vm.rules[i].dropdown = dropdown;
            }
        }

        function removeRule (rule, index) {
            HeatmapService.confirmDelete(rule).then(function () {
                HeatmapApiService.destroy(rule.domain_id, rule.id).then(function () {
                    vm.rules.splice(index, 1);
                }, angular.noop);
            }, angular.noop);
        }

        function updateRule (rule) {
            vm.selectedRule = rule;
            vm.showSearch = true;
        }

        function addRule () {
            vm.selectedRule = null;
            vm.showSearch = true;
        }

        function canAddMoreRules () {
            if (ng2SessionService.customer) {
                return ng2SessionService.customer.plan_traits.heatmaps_tracked_number_of_pages > vm.rules.length;
            }
            return false;
        }

        function onClosePageSearch (rule) {
            if (rule || (rule && vm.selectedRule)) {
                vm.getPage();
            }
            vm.showSearch = false;
            vm.selectedRule = null;
        }
    }
})();
