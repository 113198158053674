import { Injectable } from '@angular/core';
import { PropertiesSumService } from 'app/filters/properties-sum.service';

type StoryType = {
    id: number;
};

export type HistoryType = StoryType[];

type CrawlChangeType = {
    currentCrawl: number;
    lastCrawl: number;
};

export type ChangeTypeType = 'accessibility';

@Injectable({
    providedIn: 'root',
})
export class HistoryHelperService {
    constructor (
        private propertiesSumService: PropertiesSumService,
    ) {}

    getChangeValue (id: number, history: HistoryType, issues: string[], type?: ChangeTypeType): number {
        const change = this.getCrawlChange(id, history, issues, type);

        return change
            ? change.currentCrawl - change.lastCrawl
            : 0;
    }

    getChangePercentageValue (id: number, history: HistoryType, issues: string[], type?: ChangeTypeType): number {
        const change = this.getCrawlChange(id, history, issues, type);

        if (!change) {
            return 0;
        }

        const highestNumber = Math.max(change.currentCrawl, change.lastCrawl);
        const lowestNumber = Math.min(change.currentCrawl, change.lastCrawl);

        return (1 - lowestNumber / highestNumber) * 100 || 0;
    }

    /**
     * @desc Get the icon css class based on the change value between current history entry and last history entry
     * @param {number} value - Change value between current history entry and last history entry
     */
    getChangeIconClass (value: number): Record<string, boolean> {
        return {
            'fas fa-chevron-down': value < 0,
            'fas fa-chevron-up': value > 0,
        };
    }

    /**
     * @desc Get the color css class based on the change value between current history entry and last history entry
     * @param {number} value - Change value between current history entry and last history entry
     */
    getChangeColorClass (value: number): Record<string, boolean> {
        return {
            'text-success': value < 0,
            'text-danger': value > 0,
            'text-grey': value === 0,
        };
    }

    private getCrawlChange (id: number, history: HistoryType, issues: string[], type?: ChangeTypeType): CrawlChangeType | null {
        if (!issues.length) {
            // eslint-disable-next-line no-console
            console.warn('`issues` array cannot be empty.');
            return null;
        }

        const index = history.map(story => story.id).indexOf(id);

        if (index < 1) {
            return null;
        }

        const currentStory = history[index];
        const lastStory = history[index - 1];

        if (type === 'accessibility') {
            const issue = issues[0];

            return {
                currentCrawl: currentStory[issue] || 0,
                lastCrawl: lastStory[issue] || 0,
            };
        }

        return {
            currentCrawl: this.propertiesSumService.sum(currentStory, issues),
            lastCrawl: this.propertiesSumService.sum(lastStory, issues),
        };
    }
}
