import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.inventory.content.links').component('inventoryContentDocumentsNoScan', {
        templateUrl: 'app/pages/inventory/content/documents-no-scan/documents-no-scan.html',
        controller: InventoryContentDocumentNoScanController,
        controllerAs: 'vm',
    });

    InventoryContentDocumentNoScanController.$inject = [
        'ng2LinksRepoService',
        '$controller',
        '$stateParams',
        'ng2SessionService',
        'ng2MonEventsService',
        'gettextCatalog',
        '$filter',
    ];

    function InventoryContentDocumentNoScanController (
        ng2LinksRepoService,
        $controller,
        $stateParams,
        ng2SessionService,
        ng2MonEventsService,
        gettextCatalog,
        $filter,
    ) {
        var vm = this;
        vm.getPage = getPage;
        vm.openDialog = openDialog;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.links = [];
            vm.search = null;
            vm.domain_id = $stateParams.domainId;
            vm.scanDocuments = ng2SessionService.domain.scan.scan_documents;
            vm.activeTab = 'documents';
            vm.subheaderTranslation = '';
            vm.export = [
                {
                    name: gettextCatalog.getString('Internal Export'),
                    data: {
                        category: 'inventory_links',
                        category_ref: 'internal',
                    },
                },
            ];
            setupTabs();
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                type: vm.activeTab || null,
                relation: 'internal',
                page_fixed: false,
                status: 'active',
            };

            vm.promise = ng2LinksRepoService.getAll(params).then(function (data) {
                vm.links = data;
                getSubHeaderDescription();
            }, angular.noop);
        }
        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.links.total, '0,0'),
                '1 content',
                '{{$count}} content',
                {},
            );
        }

        function openDialog (link, type) {
            var params = {
                body: 'pagesFilesPages',
                size: 'lg',
                data: { link: link, domainId: vm.domain_id, active: 'links', icon: 'fas fa-link', type: type },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    value: 'documents',
                    faIcon: 'fas fa-file-alt',
                },
                {
                    name: gettextCatalog.getString('PDF'),
                    value: 'pdf',
                    faIcon: 'fas fa-file-pdf',
                },
                {
                    name: gettextCatalog.getString('Spreadsheets'),
                    value: 'spreadsheet',
                    faIcon: 'fas fa-file-excel',
                },
                {
                    name: gettextCatalog.getString('Text Documents'),
                    value: 'document',
                    faIcon: 'fas fa-file-alt',
                },
            ];
        }
    }
})();
