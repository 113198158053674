import { Injectable } from '@angular/core';
import { BaseRepo } from '@client/app/services/api/base-repo/base-repo';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { ShortcutModel, ShortcutModelInterface } from '@client/app/models/shortcut.model';

@Injectable({
    providedIn: 'root',
})
export class ShortcutsRepoService extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    async getAll (params): Promise<MonTableCollection<ShortcutModel>> {
        params = params || {};
        return this.apiService.get<MonTableCollection<ShortcutModelInterface>>('shortcuts', params).then((res) => this.toModels(res));
    }

    async create (shortcut: Partial<ShortcutModelInterface>): Promise<ShortcutModel> {
        return this.apiService.post<ShortcutModelInterface>('shortcuts', shortcut).then(res => this.toModel(res));
    }

    async update (shortcut: Partial<ShortcutModelInterface>): Promise<ShortcutModel> {
        return this.apiService.patch<ShortcutModelInterface>('shortcuts/' + shortcut.id, shortcut).then(res => this.toModel(res));
    }

    async destroy (id: number): Promise<void> {
        return this.apiService.delete('shortcuts/' + id);
    }

    toModels (models: MonTableCollection<ShortcutModelInterface>): MonTableCollection<ShortcutModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    toModel (model: ShortcutModelInterface): ShortcutModel {
        return new ShortcutModel(model);
    }
}
