<div class="row" *ngIf="monRule">
    <div class="col-md-24 col-lg-9" [attr.aria-label]="'Rule to match' | translate">
        <mon-form-field-select [monName]="'match' + monFieldKey"
                               [(model)]="$any(monRule).match"
                               [monOptions]="monMatchOptions"
                               [monReturnValue]="'value'"
                               [monRequired]="true">
        </mon-form-field-select>
    </div>
    <div class="col-md-24 col-lg-11" [attr.aria-label]="'Constraint' | translate">
        <mon-form-field-select [monName]="'exclude' + monFieldKey"
                               [(model)]="$any(monRule).exclude_as_string"
                               [monOptions]="monMatchExcludeOptions"
                               [monReturnValue]="'value'"
                               [monLabel]="'that' | translate"
                               [monRequired]="true"
                               (changeEvent)="monExcludeChange.emit()">
        </mon-form-field-select>
    </div>
    <div class="col-md-24 col-lg-28" [attr.aria-label]="'Labels' | translate">
        <mon-form-field-select [monName]="'label_ids' + monFieldKey"
                               [monMultiple]="true"
                               [(model)]="monRule.label_ids"
                               [monOptions]="monLabelsOptions"
                               [monReturnValue]="'id'"
                               [monRequired]="true"
                               [monShowColors]="true"
                               >
        </mon-form-field-select>
    </div>
</div>
