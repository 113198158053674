export const POLICY_FORM_HELPER_DB = {
    SUBJECT_OPTIONS: {
        page_document: [
            'page_text',
            'page_title',
            'page_title_length',
            'page_url',
            'link_url',
            'file_size',
            'external_link_count',
            'incoming_link_count',
            'header_text',
            'header_text_length',
            'meta_header',
            'meta_header_length',
        ],
        link: ['link_url', 'file_size', 'image_size'],
        page: [
            'page_html',
            'page_text',
            'page_title',
            'page_title_length',
            'page_url',
            'link_url',
            'link_text',
            'link_text_length',
            'file_size',
            'image_size',
            'image_text',
            'image_text_length',
            'external_link_count',
            'incoming_link_count',
            'header_text',
            'header_text_length',
            'readability_level',
            'meta_header',
            'meta_header_length',
        ],
        page_html: [
            'page_html',
            'page_text',
            'page_title',
            'page_title_length',
            'page_url',
            'link_url',
            'link_text',
            'link_text_length',
            'file_size',
            'image_size',
            'image_text',
            'image_text_length',
            'external_link_count',
            'incoming_link_count',
            'header_text',
            'header_text_length',
            'readability_level',
            'meta_header',
            'meta_header_length',
        ],
    },
    RULE_TYPE_OPTIONS: {
        page_html: [
            'starts_with',
            'contains',
            'contains_words',
            'contains_sentence',
            'ends_with',
            'eq',
            'regex',
            'conforms_with',
            'css_selector',
        ],
        page_text: ['contains_words', 'contains_sentence', 'contains', 'starts_with', 'ends_with', 'eq', 'regex', 'conforms_with'],
        page_title: ['starts_with', 'contains', 'contains_words', 'contains_sentence', 'ends_with', 'eq', 'regex'],
        page_url: ['starts_with', 'contains', 'contains_words', 'ends_with', 'eq', 'regex'],
        link_text: ['starts_with', 'contains', 'contains_words', 'contains_sentence', 'ends_with', 'eq', 'regex'],
        image_text: ['starts_with', 'contains', 'contains_words', 'contains_sentence', 'ends_with', 'eq', 'regex'],
        header_text: ['starts_with', 'contains', 'contains_words', 'contains_sentence', 'ends_with', 'eq', 'regex'],
        page_title_length: ['lt', 'lte', 'gt', 'gte', 'eq'],
        link_text_length: ['lt', 'lte', 'gt', 'gte', 'eq'],
        file_size: ['gt', 'gte', 'lt', 'lte', 'eq'],
        image_size: ['gt', 'gte', 'lt', 'lte', 'eq'],
        image_text_length: ['lt', 'lte', 'gt', 'gte', 'eq'],
        external_link_count: ['lt', 'lte', 'gt', 'gte', 'eq'],
        incoming_link_count: ['lt', 'lte', 'gt', 'gte', 'eq'],
        header_text_length: ['lt', 'lte', 'gt', 'gte', 'eq'],
        readability_level: ['lt', 'gt', 'eq', 'lte', 'gte'],
        link_url: ['starts_with', 'contains', 'contains_words', 'ends_with', 'eq', 'regex'],
        meta_header: [
            'starts_with',
            'contains',
            'contains_words',
            'contains_sentence',
            'ends_with',
            'eq',
            'regex',
            'date_age_gt',
            'date_age_lt',
        ],
        meta_header_length: ['lt', 'lte', 'gt', 'gte', 'eq'],
    },
    ICONS: {
        SUBJECT_OPTIONS: {
            page_html: ['fas fa-id-card fa-3x outer-b-small'],
            page_text: ['fas fa-align-left fa-2x outer-t-small outer-b-small'],
            page_title: ['fas fa-font fa-3x outer-b-small'],
            page_title_length: [
                'fas fa-font fa-2x outer-t-small outer-b-small',
                'fas fa-long-arrow-alt-right fa-2x outer-t-small outer-b-small',
            ],
            page_url: ['fas fa-globe fa-3x outer-b-small'],
            link_url: ['fas fa-link fa-2x outer-t-small outer-b-small', 'fas fa-globe fa-2x outer-t-small outer-b-small'],
            link_text: ['fas fa-link fa-2x outer-t-small outer-b-small', 'fas fa-align-left fa-2x outer-t-small outer-b-small'],
            link_text_length: [
                'fas fa-link fa-2x outer-t-small outer-b-small',
                'fas fa-long-arrow-alt-right fa-2x outer-t-small outer-b-small',
            ],
            file_size: ['fas fa-file-archive fa-3x outer-b-small'],
            image_size: ['fas fa-image fa-3x outer-b-small'],
            image_text: ['fas fa-image fa-2x outer-t-small outer-b-small', 'fas fa-align-left fa-2x outer-t-small outer-b-small'],
            image_text_length: [
                'fas fa-image fa-2x outer-t-small outer-b-small',
                'fas fa-long-arrow-alt-right fa-2x outer-t-small outer-b-small',
            ],
            external_link_count: ['fas fa-external-link-alt fa-3x outer-b-small'],
            incoming_link_count: ['fas fa-external-link-alt fa-3x fa-flip-vertical outer-b-small'],
            header_text: ['fas fa-heading fa-3x outer-b-small'],
            header_text_length: [
                'fas fa-heading fa-2x outer-t-small outer-b-small',
                'fas fa-long-arrow-alt-right fa-2x outer-t-small outer-b-small',
            ],
            readability_level: ['fas fa-eye fa-3x mb-2'],
            meta_header: ['fas fa-heading fa-2x my-2', 'fas fa-search fa-2x my-2'],
            meta_header_length: ['fas fa-search fa-2x my-2', 'fas fa-long-arrow-alt-right fa-2x my-2'],
        },
    },
    RAW_ICONS: {
        SUBJECT_OPTIONS: {
            page_html: ['fas fa-id-card'],
            page_text: ['fas fa-align-left'],
            page_title: ['fas fa-font'],
            page_title_length: ['fas fa-font', 'fas fa-long-arrow-alt-right'],
            page_url: ['fas fa-globe'],
            link_url: ['fas fa-link', 'fas fa-globe'],
            link_text: ['fas fa-link', 'fas fa-align-left'],
            link_text_length: ['fas fa-link', 'fas fa-long-arrow-alt-right'],
            file_size: ['fas fa-file-archive'],
            image_size: ['fas fa-image'],
            image_text: ['fas fa-image', 'fas fa-align-left'],
            image_text_length: ['fas fa-image', 'fas fa-long-arrow-alt-right'],
            external_link_count: ['fas fa-external-link-alt'],
            incoming_link_count: ['fas fa-external-link-alt'],
            header_text: ['fas fa-heading'],
            header_text_length: ['fas fa-heading', 'fas fa-long-arrow-alt-right'],
            readability_level: ['fas fa-eye'],
            meta_header: ['fas fa-heading', 'fas fa-search'],
            meta_header_length: ['fas fa-search', 'fas fa-long-arrow-alt-right'],
        },
    },
    TRANSLATIONS: {
        SUBJECT_OPTIONS: {
            NAME: {
                page_html: 'Page html',
                page_text: 'Text',
                page_title: 'Page title',
                page_title_length: 'Page title length',
                page_url: 'Page url',
                link_url: 'Link',
                link_text: 'Link text',
                link_text_length: 'Link text length',
                file_size: 'File size',
                image_size: 'Image size',
                image_text: 'Image text',
                image_text_length: 'Image text length',
                external_link_count: 'External link count',
                incoming_link_count: 'Incoming link count',
                header_text: 'Heading text',
                header_text_length: 'Header text length',
                readability_level: 'Readability level',
                meta_header: 'Meta header',
                meta_header_length: 'Meta header length',
            },
            DESCRIPTION: {
                page_html: 'Search for text in the entire domains html',
                page_text: 'Search for text excluding css and html tags',
                page_title: 'Search for text in the page title (title is not the header)',
                page_title_length: 'Define when the rule should apply for the title',
                page_url: 'Set a rule for internal page URLs',
                link_url: 'Set a rule for a URL (Both internal and external)',
                link_text: 'Search for pages that contains this text in links',
                link_text_length: 'Define when the rule should apply for link texts',
                file_size: 'Search files across selected domains',
                image_size: 'Search for images across selected domains',
                image_text: 'Search for images with this name',
                image_text_length: 'Define when the rule should apply for image texts',
                external_link_count: 'Define when the rule should apply for external links',
                incoming_link_count: 'Define when the rule should apply for internal links',
                header_text: 'Search for text in headers (h1,h2,h3,h4,h5,h6)',
                header_text_length: 'Define when the rule should apply header texts',
                readability_level: 'Search for text that is in compliance with school degree',
                meta_header: 'Search for meta header tags, that matches query',
                meta_header_length: 'Define when the rule should apply for meta headers',
            },
        },
        RULE_TYPE_OPTIONS: {
            lt: 'Less than',
            lte: 'Less than or equal',
            gt: 'Greater than',
            gte: 'Greater than or Equal',
            eq: 'Equal',
            starts_with: 'Starts with',
            contains: 'Contains',
            contains_words: 'Contains Words',
            contains_sentence: 'Contains sentence',
            ends_with: 'Ends with',
            regex: 'Regex',
            conforms_with: 'Conforms with',
            date_age_lt: 'Date age less than',
            date_age_gt: 'Date age greater than',
            css_selector: 'CSS selector',
        },
        RULE_TYPE_SUB_ENTRIES: {
            ALL: {
                header_text: 'All headings',
            },
            LABEL: {
                header_text: 'Heading type',
            },
        },
        RULE_TYPE_HELP_BLOCKS: {
            contains_words: 'Comma separated list of words',
        },
    },
    RULE_TYPE_SUB_ENTRIES: {
        header_text: [
            {
                name: '<H1>',
                value: 'h1',
            },
            {
                name: '<H2>',
                value: 'h2',
            },
            {
                name: '<H3>',
                value: 'h3',
            },
            {
                name: '<H4>',
                value: 'h4',
            },
            {
                name: '<H5>',
                value: 'h5',
            },
            {
                name: '<H6>',
                value: 'h6',
            },
        ],
    },
    RULE_TYPE_LINKS: ['link_url', 'file_size', 'image_size'],
    RULE_TYPE_IMAGES: ['image_size'],
} as const;
