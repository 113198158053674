(function () {
    'use strict';

    angular.module('modules.page_fix.components').component('pageFixChangesetLink', {
        templateUrl: 'app/modules/page-fix/components/changeset/link/link.html',
        controller: AutofixChangesetLinkController,
        controllerAs: 'vm',
        bindings: {
            data: '<',
        },
    });

    AutofixChangesetLinkController.$inject = ['ng2SessionService'];
    /* @ngInject */
    function AutofixChangesetLinkController (ng2SessionService) {
        var vm = this;
        vm.getDataTargetLink = getDataTargetLink;

        function getDataTargetLink () {
            if (vm.data && vm.data.target) {
                var match = vm.data.target.match(/([a-z]+:\/\/)?[\w!#€%&\/()=\?`´\^\\¨'*-\.]+/g)[2];
                if (!hasProtocol(match)) {
                    return prefixDomainUrl(match);
                }
                return match;
            }
        }

        // PROTECTED
        function hasProtocol (match) {
            return Boolean(match.match(/^([a-z]+:\/\/)/));
        }

        function prefixDomainUrl (match) {
            var domain = ng2SessionService.domain;
            if (domain) {
                var domainUrl = domain.url;
                return domainUrl.replace(/\/$/) + '/' + match.replace(/^\//);
            }
        }
    }
})();
