import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BI_API_CONSTANT } from '@monsido/core/constants/bi-api.constant';

@Injectable(
    { providedIn: 'root' },
)
export class InterceptorHelperService {
    isBIResponse (urlPath: string): boolean {
        return urlPath.indexOf(BI_API_CONSTANT.biEndpoint) > -1;
    }

    isNotificationResponse (urlPath: string): boolean {
        return urlPath.indexOf('notifications') > -1;
    }

    isLogoutResponse (urlPath: string): boolean {
        return urlPath.includes('revoke.json');
    }

    isURLBelongsToIssueView (urlString: string): boolean {
        const url = new URL(urlString);

        // qa-broken-issue, qaIssueRepoService.getPageIssueContent
        if (/\/api\/domains\/[\d]+\/pages\/[\d]+\/page-links\/[\d]+/.test(url.pathname)) {
            return true;
        }

        // qa-broken-issue, qaIssueRepoService.getIssuePages, qaIssueRepoService.getIssueDocuments
        if (/\/api\/domains\/[\d]+\/links\/[\d]+\/pages/.test(url.pathname) && url.search.includes('link_id=')) {
            return true;
        }

        // misspelling-issue
        if (/\/api\/domains\/[\d]+\/pages\/[\d]+\/spelling_errors\/[\d]+/.test(url.pathname)) {
            return true;
        }

        // accessibility-issue
        if (/\/api\/domains\/[\d]+\/accessibility\/fast_track\/[\d]+/.test(url.pathname)) {
            return true;
        }

        // WARNING! This URL is too general!
        // accessibility-issue
        if (/\/api\/domains\/[\d]+\/pages\/[\d]+/.test(url.pathname)) {
            return true;
        }

        return false;
    }

    isStatisticsURL (urlString: string): boolean {
        return urlString.indexOf('/statistics/') > -1;
    }

    isNewReportPageURL (urlString: string): boolean {
        return urlString.indexOf('api/performance/reports?page_id=') > -1;
    }

    isBusinessIntelligenceError (request: HttpRequest<unknown>): boolean {
        return request.url.endsWith('biEndpoint');
    }

    isStatistics404Error (response: HttpErrorResponse): boolean {
        if (!response) {
            return false;
        }
        const url = this.getAnchorFromURLElement(response.url || '');
        return response.status === 404 && this.isStatisticsURL(url.pathname);
    }

    isNewReportPage404Error (response: HttpErrorResponse): boolean {
        if (!response || !response.url) {
            return false;
        }
        return this.isNewReportPageURL(response.url);
    }

    skipRedirectOn403 (response: HttpErrorResponse): boolean {
        if (response && response.headers && response.headers.has('skipRedirectOn403')) {
            return true;
        }
        return false;
    }


    private getAnchorFromURLElement (url: string): HTMLAnchorElement {
        const anchor = document.createElement('a');
        anchor.href = url;
        return anchor;
    }
}
