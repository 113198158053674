/* eslint-disable prettier/prettier */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonVerticalScoreComponent } from './mon-vertical-score/mon-vertical-score.component';
import { MonChartsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [MonVerticalScoreComponent],
    imports: [
        CommonModule,
        MonChartsModule,
        NgbTooltipModule,
    ],
    exports: [MonVerticalScoreComponent],
})
export class MonScoreComponentsModule {}
