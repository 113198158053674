/* eslint-disable camelcase */
import { ConsentManagerSettingsOptionsInterface, ThemeOptions } from './consent-manager-settings-options.interface';
import { CONSENT_MANAGER_CONSTANT } from './consent-manager.constant';

export class ConsentManagerModel {
    add_empty_callback: boolean = false;
    add_empty_event_listeners: boolean = false;
    cookieOverviewLinkEnabled: boolean = false;
    linkToCookieOverview: string = '';
    enabled: boolean = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.enabled;
    direction: string = CONSENT_MANAGER_CONSTANT.DIRECTION.BOTTOMRIGHT;
    privacyRegulation: Array<string> = [CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.privacyRegulation];
    cookie_policy_url: string = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.cookie_policy_url;
    optOutUrl: string = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.optOutUrl;
    per_category_consent: boolean = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.per_category_consent;
    hasOverlay: boolean = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.hasOverlay;
    explicit_reject_option: boolean = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.explicit_reject_option;
    enable_iab_and_consent_fw: boolean = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.enable_iab_and_consent_fw;
    support_google_consent_mode: boolean = CONSENT_MANAGER_CONSTANT.DEFAULT_SETTINGS.support_google_consent_mode;
    icon_picture_url: string = CONSENT_MANAGER_CONSTANT.ICON.COOKIE;
    icon_shape: string = CONSENT_MANAGER_CONSTANT.ICON_SHAPE.CIRCLE;
    included_languages: string[] = [CONSENT_MANAGER_CONSTANT.LANGUAGE.EN_US];
    defaultLanguage: string = CONSENT_MANAGER_CONSTANT.LANGUAGE.EN_US;
    theme: ThemeOptions = {
        buttonColor: CONSENT_MANAGER_CONSTANT.BUTTON_COLOR,
        buttonTextColor: CONSENT_MANAGER_CONSTANT.BUTTON_TEXT_COLOR,
    };

    constructor (options: ConsentManagerSettingsOptionsInterface) {

        Object.keys(options).forEach((k: keyof ConsentManagerSettingsOptionsInterface) => {
            this[k] = options[k] as never;

            if (k === 'theme') {
                Object.keys(this[k]).forEach((subOption: keyof ThemeOptions) => {
                    this[k][subOption] = options[k][subOption] as never;
                });
            }

        });
    }
}
