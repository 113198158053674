import { Component, Input } from '@angular/core';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

export interface exportEntryDataObject {
    category: string;
}

export interface exportEntry {
    name: string;
    data: exportEntryDataObject;
}

@Component({
    selector: 'mon-export-button',
    templateUrl: './export-button.component.html',
})
export class ExportButtonComponent {

    @Input() exports: exportEntry[] = [];
    @Input() disabled: boolean = false;
    enableExport:boolean = true;
    btnClass: string = 'btn';

    constructor (private eventsService: MonEventService) { }

    exportFiles (): void {
        const params = {
            body: 'csvExportDialog',
            size: 'sm',
            classes: 'fade-animation middle center',
            data: {
                exports: this.exports,
            },
        };
        this.eventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
    }
}
