(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormWizardContainer', {
        templateUrl: 'app/modules/page-fix/forms/wizard-container/wizard-container.html',
        controller: PageFixFormHeadingController,
        controllerAs: 'vm',
        transclude: true,
        bindings: {
            close: '&',
            dismiss: '&',
            formValid: '<',
            pageFixApplied: '<',
            pageFix: '<',
        },
    });

    PageFixFormHeadingController.$inject = [
        'ng2MonEventsService',
        'PAGE_FIX_EVENT',
        'PageFormFormWizardContainerViewModelService',
        'PAGE_FIX_FORM_TAB_ICON',
        'ng2PageFixRepoService',
        'ng2AccessibilityChecksMapService',
        'PAGE_FIX_FORM_VIEW_NAME',
        '$timeout',
    ];
    /* @ngInject */
    function PageFixFormHeadingController (
        ng2MonEventsService,
        PAGE_FIX_EVENT,
        PageFormFormWizardContainerViewModelService,
        PAGE_FIX_FORM_TAB_ICON,
        ng2PageFixRepoService,
        ng2AccessibilityChecksMapService,
        PAGE_FIX_FORM_VIEW_NAME,
        $timeout,
    ) {
        var vm = this;
        var listenToUpdateWarning;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.onTabChange = onTabChange;

        function activate () {
            vm.saving = false;
            vm.destroying = false;
            vm.registerViewId = 0;
            vm.unregisterViewId = 0;
            vm.requestDestroyId = 0;
            vm.requestSubmitId = 0;
            vm.updateViewId = 0;
            vm.warningText = '';
            vm.icon = PAGE_FIX_FORM_TAB_ICON;
            vm.registeredViews = [];
            vm.view = null;
            vm.showNotification = false;
            vm.pageFixSaved = false;
            setupListeners();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.updateViewId);
            ng2MonEventsService.remove(vm.registerViewId);
            ng2MonEventsService.remove(vm.unregisterViewId);
            ng2MonEventsService.remove(vm.requestSubmitId);
            ng2MonEventsService.remove(vm.requestDestroyId);
            ng2MonEventsService.remove(listenToUpdateWarning);
        }

        function onTabChange (newView) {
            vm.view = newView;
            setViewVisibility();
        }

        // PROTECTED

        function setupListeners () {
            vm.updateViewId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.FORM_UPDATE_VIEW, function (viewName) {
                var view = vm.registeredViews.find(function (view) {
                    return view.viewName === viewName;
                });

                if (view) {
                    onTabChange(view);
                }
            });

            vm.registerViewId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.REGISTER_VIEW, function (el, params) {
                registerView(el, params);
            });

            vm.unregisterViewId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.UNREGISTER_VIEW, function (el) {
                unregisterView(el);
            });

            vm.requestSubmitId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.REQUEST_SUBMIT, function (payload) {
                vm.saving = true;
                var promise;
                if (vm.pageFix) {
                    promise = ng2PageFixRepoService.update(vm.pageFix, payload);
                } else {
                    promise = ng2PageFixRepoService.create(payload);
                }
                promise
                    .then(function (pageFix) {
                        if (!vm.pageFixApplied) {
                            vm.showNotification = true;
                            setDisableTimerForNotification();
                        }
                        ng2AccessibilityChecksMapService.addAccessibilityCheck({ id: pageFix.change.check_id });
                        ng2AccessibilityChecksMapService.addUpdatedAccessibilityCheck({
                            id: pageFix.change.check_id,
                        });
                        ng2MonEventsService.run(PAGE_FIX_EVENT.UPDATE_PAGEIFX, pageFix);
                        var view = vm.registeredViews.find(function (view) {
                            return view.viewName === PAGE_FIX_FORM_VIEW_NAME.TESTING;
                        });
                        onTabChange(view);
                        vm.pageFixSaved = true;
                    }, angular.noop)
                    .finally(function () {
                        vm.saving = false;
                    });
            });

            vm.requestDestroyId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.REQUEST_DESTROY, function () {
                vm.destroying = true;
                ng2PageFixRepoService.destroy(vm.pageFix.id).then(
                    function () {
                        ng2AccessibilityChecksMapService.addDeletedPageFix(vm.pageFix);
                        ng2AccessibilityChecksMapService.clearAccessibilityCheck(vm.pageFix.change.check_id);
                        ng2AccessibilityChecksMapService.removeUpdatedAccessibilityCheck({
                            id: vm.pageFix.change.check_id,
                        });
                        vm.close({ pageFix: 'destroyed' });
                    },
                    function () {
                        vm.destroying = false;
                    },
                );
            });

            listenToUpdateWarning = ng2MonEventsService.addListener(PAGE_FIX_EVENT.UPDATE_WARNING, function (warningText) {
                vm.warningText = warningText;
            });
        }

        function registerView (el, params) {
            var model = new PageFormFormWizardContainerViewModelService(el, params);
            vm.registeredViews.push(model);
            vm.registeredViews.sort(function (a, b) {
                return a.order - b.order;
            });
            if (model.selected) {
                vm.view = model;
            }
            setViewVisibility();
        }

        function unregisterView (element) {
            for (var i = 0; i < vm.registeredViews.length; i++) {
                var model = vm.registeredViews[i];
                if (model.el === element) {
                    vm.registeredViews.splice(i, 1);
                    break;
                }
            }
        }

        function setViewVisibility () {
            for (var i = 0; i < vm.registeredViews.length; i++) {
                var view = vm.registeredViews[i];
                if (view === vm.view) {
                    view.el.classList.remove('hidden');
                } else {
                    view.el.classList.add('hidden');
                }
            }
        }

        function setDisableTimerForNotification () {
            $timeout(function () {
                vm.showNotification = false;
            }, 300000); // 5 minutes
        }
    }
})();
