(function () {
    'use strict';
    angular.module('modules.light.pages').component('lightUsers', {
        templateUrl: 'app/modules/light/pages/users/users.html',
        controller: LightUsersComponent,
        controllerAs: 'vm',
    });

    LightUsersComponent.$inject = [
        '$timeout',
        'Lodash',
        'CoreUserService',
        'UserRepo',
        '$controller',
        'gettextCatalog',
    ];
    /* @ngInject */
    function LightUsersComponent ($timeout, Lodash, CoreUserService, UserRepo, $controller, gettextCatalog) {
        var vm = this;

        vm.getPage = getPage;
        vm.updateUser = updateUser;
        vm.removeUser = removeUser;
        vm.$onInit = activate;
        vm.onFiltersChanges = onFiltersChanges;
        vm.addUser = addUser;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.users = [];
            vm.search = null;
            vm.selectedRoles = [];
            vm.filters.roles = [];
            vm.subheaderTranslation = '';
            vm.availableFilters.roles = [
                {
                    id: true,
                    name: gettextCatalog.getString('Admin'),
                },
                {
                    id: false,
                    name: gettextCatalog.getString('Normal'),
                },
            ];
            vm.currentSort = {
                by: 'first_name',
                direction: 'asc',
            };
            getPage();
            getSubHeaderDescription();
        }

        function addUser () {
            return CoreUserService.updateUser().finally(getPage);
        }

        function updateUser (user) {
            if (!user || user.id == null) {
                return;
            }
            var index = Lodash.findIndex(vm.users, function (entry) {
                return entry.id === user.id;
            });

            if (index > -1) {
                vm.users[index] = user;
            }
        }

        function removeUser (user) {
            if (vm.users.total && vm.users.total <= vm.pageSize) {
                if (!user || user.id == null) {
                    return;
                }
                var index = Lodash.findIndex(vm.users, function (entry) {
                    return entry.id === user.id;
                });

                if (index > -1) {
                    vm.users.splice(index, 1);
                }
            } else {
                $timeout(function () {
                    getPage();
                }, 500); // Ensure that the request will be performed after DELETE
            }
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };
            if (vm.selectedLabels.length > 0) {
                params.labels = vm.selectedLabels;
            }
            if (vm.selectedRoles.length > 0) {
                params.admin = vm.selectedRoles[0].id;
            }
            vm.promise = UserRepo.getAll(params).then(function (data) {
                vm.users = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                vm.users.total,
                '1 user on your account',
                '{{$count}} users on your account',
                {},
            );
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            if (changes.changes) {
                changes = changes.changes;
            }
            if (changes.roles.length === 1) {
                vm.selectedRoles = changes.roles;
            } else if (changes.roles.length > 1) {
                vm.selectedRoles.splice(0, 1);
            } else {
                vm.selectedRoles = [];
            }
            vm.getPage();
        }
    }
})();
