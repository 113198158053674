import { Injectable } from '@angular/core';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { PrivacyFastTrackModel, PrivacyFastTrackModelOptionsType } from '../../privacy-fast-track-model-factory/privacy-fast-track-model-factory.service';

@Injectable({
    providedIn: 'root',
})
export class DataPrivacyContentRepoService extends BaseRepo {
    constructor (
        private apiService: ApiService,
        protected sessionService: SessionService,
    ) {
        super();
    }

    async getAll (params: BaseRepoRequestType = {}): Promise<MonTableCollection<PrivacyFastTrackModel>> {
        params = this.setGroupId(params);
        return this.apiService.get<MonTableCollection<PrivacyFastTrackModelOptionsType>>(`domains/${this.getDomainId()}/data_protection/contents`, params)
            .then(res => this.toModels(res));
    }

    private toModels (models: MonTableCollection<PrivacyFastTrackModelOptionsType>): MonTableCollection<PrivacyFastTrackModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = new PrivacyFastTrackModel(models[i]);
        }
        return models as MonTableCollection<PrivacyFastTrackModel>;
    }
}
