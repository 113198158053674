(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardDistributionProgressBar', {
        templateUrl: 'app/pages/accessibility/dashboard/distribution/progress-bar/progress-bar.html',
        controller: AccessibilityDashboardDistributionProgressBarController,
        controllerAs: 'vm',
        bindings: {
            checkA: '<',
            checkAa: '<',
            checkAaa: '<',
            total: '<',
            fixed: '<',
        },
    });

    AccessibilityDashboardDistributionProgressBarController.$inject = [];
    /* @ngInject */
    function AccessibilityDashboardDistributionProgressBarController () {
        var vm = this;
        vm.calcWidth = calcWidth;

        function calcWidth (input) {
            return parseInt(input) / vm.total * 100 + '%';
        }
    }
})();
