<div class="color-picker" [ngClass]="{'color-picker-position-top': position === 'top'}">
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="color-preview input-group-text"
                *ngIf="!secondColor"
                (click)="showColorPicker()"
                [ngStyle]="{'background-color': colorValue}"
                role="button">
            </span>
            <span class="color-preview input-group-text button-hover-color"
                *ngIf="secondColor"
                (click)="showColorPicker()"
                [ngStyle]="{'background-color': secondColor}"
                role="button">
                <span class="inner-color"
                    [ngStyle]="{'background-color': colorValue}">
                </span>
            </span>
        </div>
        <input maxlength="7"
                type="text"
                [(ngModel)]="colorValue"
                (change)="onInputChange()"
                (click)="showColorPicker()"
                [ngClass]="{'error': !isColorValid}"
                class="form-control px-sm-1"/>
        <compact-picker *ngIf="colorPickerShown" [control]="compactControl" (clickOutside)="hideColorPicker()" [delayClickOutsideInit]="true"></compact-picker>
    </div>
    <span *ngIf="!isColorValid"
            class="text-danger mt-1 block"
            translate>
        Please enter a valid color code
    </span>
</div>
