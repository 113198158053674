(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageAccessibilityWcag22', {
        templateUrl: 'app/components/pagedetails/details/sections/accessibility/wcag2-2/wcag2-2.html',
        controller: PageAccessibilityWCAG22Controller,
        controllerAs: 'vm',
        bindings: {
            availableFilters: '<',
            filters: '=',
            issues: '<',
            progress: '<',
            loading: '<',
            refreshPage: '&',
            showIgnoredChecks: '<',
            tabChange: '&',
            tab: '<',
            page: '<',
            active: '<',
            refreshChecks: '&',
        },
    });

    PageAccessibilityWCAG22Controller.$inject = [
        'gettextCatalog',
        'ng2PageAccessibilityRepoService',
        '$q',
        '$timeout',
        'ng2SessionService',
        'AccessibilityFiltersService',
        '$filter',
    ];
    function PageAccessibilityWCAG22Controller (
        gettextCatalog,
        PageAccessibilityRepo,
        $q,
        $timeout,
        ng2SessionService,
        AccessibilityFiltersService,
        $filter,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.onTabChange = onTabChange;
        vm.onSelectCheck = onSelectCheck;
        vm.onFiltersChange = onFiltersChange;
        vm.getErrors = getErrors;

        function activate () {
            vm.isFiltersOpen = false;
            setupTabs();
            hasLevelComplianceCheck();
        }

        function onFiltersChange (changes) {
            if (changes.type && changes.type.length > 0) {
                var type = changes.type[changes.type.length - 1];
                vm.filters.type = [type];
            }
            if (
                changes[AccessibilityFiltersService.getReponsibilityTranslation()] &&
                changes[AccessibilityFiltersService.getReponsibilityTranslation()].length > 0
            ) {
                var length = vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()].length;
                vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()] = [
                    vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()][length - 1],
                ];
            }
            vm.refreshChecks();
        }

        function setupTabs () {
            vm.tabOptions = [{ name: gettextCatalog.getString('All levels'), value: null }];

            let accVersion = ng2SessionService.domain.features.accessibility;
            const indexOfVersion = accVersion.includes('mobile') ? accVersion.search(/_mobile_v(\d+)?/) : accVersion.search(/_v(\d+)?/);
            if (indexOfVersion > -1) {
                accVersion = accVersion.slice(0, indexOfVersion);
            }
            switch (accVersion) {
                case 'WCAG22-AAA':
                    vm.tabOptions = vm.tabOptions.concat([
                        { name: gettextCatalog.getString('Level A'), value: 'A' },
                        { name: gettextCatalog.getString('Level AA'), value: 'AA' },
                        { name: gettextCatalog.getString('Level AAA'), value: 'AAA' },
                    ]);
                    break;

                case 'WCAG22-AA':
                    vm.tabOptions = vm.tabOptions.concat([
                        { name: gettextCatalog.getString('Level A'), value: 'A' },
                        { name: gettextCatalog.getString('Level AA'), value: 'AA' },
                    ]);
                    break;
                case 'WCAG22-A':
                    vm.tabOptions = vm.tabOptions.concat([{ name: gettextCatalog.getString('Level A'), value: 'A' }]);
                    break;
            }
        }

        function onTabChange (tab) {
            vm.tabChange({ tab: tab });
            selectAvailableCheck();
        }

        function onSelectCheck (check) {
            vm.check = check;
            vm.check.errors = [];
            getErrors(false); // Shouldn't refresh page when getting errors
        }

        function getErrors (update) {
            // Check if only the errors should be updated, or the check
            if (update) {
                vm.refreshChecks();
                selectAvailableCheck();
            }
            vm.errorLoading = true;
            var params = {
                page_size: 0,
            };
            vm.errorProgress = PageAccessibilityRepo.getAllCheckErrors(vm.page.id, vm.check.id, params)
                .then(function (data) {
                    vm.check.errors = data;
                    vm.check.errors_count = data.filter(function (error) {
                        return !error.ignored;
                    }).length;
                }, angular.noop)
                .finally(function () {
                    vm.errorLoading = false;
                });
        }

        function selectAvailableCheck () {
            $timeout(function () {
                $q.when(vm.progress).then(function () {
                    $timeout(function () {
                        var checks = getChecks().filter(function (check) {
                            if (vm.tab === 'passed_checks') {
                                return check.errors_count === 0 && !check.ignored;
                            } else if (vm.tab === 'ignored_issues') {
                                return check.ignored;
                            } else {
                                return check.errors_count > 0;
                            }
                        }); // Use same filtering methods as the tables component
                        var check;
                        if (typeof vm.check === 'object') {
                            // Look for currently selected check
                            check = checks.find(function (check) {
                                return vm.check.id === check.id;
                            });
                        }
                        if (typeof check === 'undefined' && checks.length > 0) {
                            // If no check is found, select the first available check
                            check = checks[0];
                        }
                        if (typeof check === 'object') {
                            // Only fetch errors if a check was found
                            onSelectCheck(check);
                        }
                    });
                }, angular.noop);
            });
        }

        function getChecks () {
            var collection = [];
            if (vm.issues) {
                if (vm.issues.A) {
                    collection = collection.concat(vm.issues.A);
                }
                if (vm.issues.AA) {
                    collection = collection.concat(vm.issues.AA);
                }
                if (vm.issues.AAA) {
                    collection = collection.concat(vm.issues.AAA);
                }
            }
            return collection;
        }

        function hasLevelComplianceCheck () {
            vm.hasLevelACompliance = $filter('accessibilityHasGuidelineId')('wcag22-a') || $filter('accessibilityHasGuidelineId')('wcag22-a_mobile') || $filter('accessibilityHasGuidelineId')('qualweb-a');
            vm.hasLevelAACompliance = $filter('accessibilityHasGuidelineId')('wcag22-aa') || $filter('accessibilityHasGuidelineId')('wcag22-aa_mobile') || $filter('accessibilityHasGuidelineId')('qualweb-aa');
            vm.hasLevelAAACompliance = $filter('accessibilityHasGuidelineId')('wcag22-aaa') || $filter('accessibilityHasGuidelineId')('wcag22-aaa_mobile');
        }
    }
})();
