(function () {
    'use strict';

    angular.module('modules.business-intelligence.services.api').factory('businessIntelligenceRepo', repo);

    repo.$inject = ['businessIntelligenceApi', 'BI_API_CONSTANT'];
    /* @ngInject */
    function repo (businessIntelligenceApi, BI_API_CONSTANT) {
        return {
            post: post,
        };

        function post (data, params, config) {
            return businessIntelligenceApi.post(BI_API_CONSTANT.biEndpoint, data, params, config);
        }
    }
})();
