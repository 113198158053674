(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTableChangeHistory', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/change-history/change-history.html',
        controller: ChangeHistoryController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
        },
    });

    ChangeHistoryController.$inject = ['ng2HistoryHelperService', 'ng2ActiveFeatureService', 'gettextCatalog'];
    /* @ngInject */
    function ChangeHistoryController (ng2HistoryHelperService, ng2ActiveFeatureService, gettextCatalog) {
        var vm = this;
        vm.getChangePercentageValue = getChangePercentageValue;
        vm.getChangePercentageIconClass = getChangePercentageIconClass;
        vm.getChangeValueColorClass = getChangeValueColorClass;
        vm.$onInit = activate;
        vm.browserService = {
            'browser_service': gettextCatalog.getString('Legacy Browser Service changed'),
            'puppeteer_service': gettextCatalog.getString('Puppeteer Browser Service changed'),
        };
        vm.v20Text = gettextCatalog.getString('Puppeteer 20');
        vm.issuesStack = ['page_count', 'documents_count'];

        function activate () {
            vm.showSEO = ng2ActiveFeatureService.isFeatureActivePure('seo_all');
            vm.showPolicy = ng2ActiveFeatureService.isFeatureActivePure('policies_all');
            vm.showQA = ng2ActiveFeatureService.isFeatureActivePure('qa_module');
        }

        function getChangePercentageValue (id) {
            return ng2HistoryHelperService.getChangePercentageValue(id, vm.history, vm.issuesStack);
        }

        function getChangePercentageIconClass (story) {
            var value = getChangeValue(story.id);
            return ng2HistoryHelperService.getChangeIconClass(value);
        }

        function getChangeValueColorClass (story) {
            var value = getChangeValue(story.id);
            return ng2HistoryHelperService.getChangeColorClass(value);
        }

        // PROTECTED

        function getChangeValue (id) {
            return ng2HistoryHelperService.getChangeValue(id, vm.history, vm.issuesStack);
        }
    }
})();
