import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetUserNamePipe } from './get-user-name.pipe';

@NgModule({
    declarations: [GetUserNamePipe],
    exports: [GetUserNamePipe],
    imports: [
        CommonModule,
    ],
})
export class GetUserNameModule { }
