(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyPreContent', {
        templateUrl: 'app/forms/global-policy/steps/pre-content/pre-content.html',
        controller: PolicyPreContentController,
        controllerAs: 'vm',
        bindings: {
            template: '<',
            monDialogId: '@?',
        },
    });

    PolicyPreContentController.$inject = ['monDialog', '$sce'];
    /* @ngInject */
    function PolicyPreContentController (monDialog, $sce) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;

        function activate () {
            vm.ngModel = null;

            vm.component = $sce.trustAsHtml(
                '<' + vm.template.pre_content_component + '></' + vm.template.pre_content_component + '>',
            );
        }

        function close () {
            monDialog.close(vm.monDialogId, vm.ngModel);
        }
    }
})();
