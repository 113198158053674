<form name="form" #pageAssistSettingsForm="ngForm" (ngSubmit)="submit(pageAssistSettingsForm, $event)">

    <div class="mon-dialog-header">
        <mon-page-header
            header="{{'PageAssist script settings' | translate }}" [panelLeftClass]="'col-48 mt-2'">
        </mon-page-header>
    </div>

    <div #dialogBody *ngIf="dataModel && options" class="mon-dialog-body-with-footer bg-body">
        <div class="card panel-monsido-card mt-4 mx-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-48">
                        <h2 class="font-weight-300 font-size-18 mt-4 mb-1 ml-4" translate>Widget Icon Setup</h2>
                    </div>
                    <hr class="mt-0 mb-4 col"/>

                </div>

                <div class="row">
                    <div class="col-48 col-md-36 mt-3">
                        <div class="form-group row">

                            <label class="col-form-label text-right text-bold col-sm-11" translate>
                                PageAssist position on Page
                            </label>
                            <div class="col-auto">
                                <div class="page-assist-position-board d-flex flex-wrap align-items-stretch">
                                    <ng-container  *ngFor="let direction of options.directions; let i = index">
                                        <div class="col-16 px-0 position-radio-container text-center flex-fill">
                                            <label for="{{i}}_page_assist_direction_value">
                                                <input type="radio"
                                                        name="direction"
                                                        ngModel
                                                        [value]="direction.value"
                                                        (change)="dataModel.direction = direction.value; setSidePixels();"
                                                        class="sr-only"
                                                        id="{{i}}_page_assist_direction_value"
                                                        [attr.checked]="dataModel.direction === direction.value ? true : null">
                                                <span class="position-radio">&nbsp;</span>
                                                <span class="sr-only">{{direction.value}}</span>
                                            </label>
                                        </div>
                                        <div *ngIf="i === 3" class="col-16 px-0">
                                            &nbsp;
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" translate>
                                    Margin from edge in pixels
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                      aria-hidden="true"
                                      ngbTooltip="{{'How far the button is from the edge. Customization dependant on position.' | translate}}"
                                      placement="auto"
                                      container="body">
                                </span>
                            </div>
                            <div class="col-sm-37 d-flex align-items-center mt-lg-1">
                                <label class="col-form-label inline-block pr-2"
                                       for="page_assist_button_pixels_on_top"
                                       translate>
                                    Top
                                </label>
                                <div *ngIf="dataModel.pixelsOnTop === 'unset'">
                                    <input name="pixelsOnTop" class="form-control pixel-input pr-2" disabled/>
                                </div>
                                <div *ngIf="dataModel.pixelsOnTop !== 'unset'">
                                    <input type="number"
                                            name="pixelsOnTop"
                                            [(ngModel)]="dataModel.pixelsOnTop"
                                            class="form-control pixel-input pr-2"
                                            id="page_assist_button_pixels_on_top"/>
                                </div>
                                <label class="col-form-label inline-block px-2"
                                        for="page_assist_button_pixels_on_right"
                                        translate>
                                    Right
                                </label>
                                <div *ngIf="dataModel.pixelsOnRight === 'unset'">
                                    <input name="pixelsOnRight" class="form-control pixel-input pr-2" disabled/>
                                </div>
                                <div *ngIf="dataModel.pixelsOnRight !== 'unset'">
                                    <input type="number"
                                            name="pixelsOnRight"
                                            class="form-control pixel-input"
                                            [(ngModel)]="dataModel.pixelsOnRight"
                                            id="page_assist_button_pixels_on_right"/>
                                </div>
                                <label class="col-form-label inline-block px-2"
                                        for="page_assist_button_pixels_on_bottom"
                                        translate>
                                    Bottom
                                </label>
                                <div *ngIf="dataModel.pixelsOnBottom === 'unset'">
                                    <input name="pixelsOnBottom" class="form-control pixel-input pr-2" disabled/>
                                </div>
                                <div *ngIf="dataModel.pixelsOnBottom !== 'unset'">
                                    <input type="number"
                                            name="pixelsOnBottom"
                                            class="form-control pixel-input"
                                            [(ngModel)]="dataModel.pixelsOnBottom"
                                            id="page_assist_button_pixels_on_bottom"/>
                                </div>
                                <label class="col-form-label inline-block px-2"
                                        for="page_assist_button_pixels_on_left"
                                        translate>
                                    Left
                                </label>
                                <div *ngIf="dataModel.pixelsOnLeft === 'unset'">
                                    <input name="pixelsOnLeft" class="form-control pixel-input pr-2" disabled/>
                                </div>
                                <div *ngIf="dataModel.pixelsOnLeft !== 'unset'">
                                    <input type="number"
                                            name="pixelsOnLeft"
                                            class="form-control pixel-input"
                                            [(ngModel)]="dataModel.pixelsOnLeft"
                                            id="page_assist_button_pixels_on_left"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="page_assist_always_on_top" translate>
                                    Keep PageAssist on top
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                    aria-hidden="true"
                                    ngbTooltip="{{'Ensures that PageAssist is never overlapped by other elements on the page' | translate}}"
                                    placement="auto"
                                    container="body">
                                </span>
                            </div>
                            <div class="col-sm-37 d-flex align-items-center mt-sm-0 mt-lg-1">
                                <input id="page_assist_always_on_top"
                                    name="alwaysOnTop"
                                    type="checkbox"
                                    [(ngModel)]="dataModel.alwaysOnTop"/>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-form-label text-right text-bold col-sm-11"
                                translate>
                                    Choose an icon
                            </label>
                            <div class="col-sm-37">
                                <div class="row icon-container">
                                    <div class="col-auto icon-btn-container d-flex align-items-center" *ngFor="let icon of options.icons; let i = index">
                                        <label class="btn btn-border-radius btn-icon flex"
                                                for="{{i}}_page_assist_icon_picture_url"
                                                type="button"
                                                [ngClass]="{'active': icon.value === dataModel.icon_picture_url}">
                                            <span [ngClass]="icon.icon" aria-hidden="true"></span>
                                            <span class="sr-only">{{icon.value}}</span>
                                            <input type="radio"
                                                    name="icon_picture_url"
                                                    ngModel
                                                    [value]="icon.value"
                                                    (change)="dataModel.icon_picture_url = icon.value"
                                                    class="sr-only"
                                                    id="{{i}}_page_assist_icon_picture_url"
                                                    [attr.checked]="dataModel.icon_picture_url === icon.value ? true : null"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row icon-shape-container">
                            <label class="col-form-label text-right text-bold col-sm-11"
                                    translate>
                                Icon shape
                            </label>
                            <div class="col-sm-37">
                                <mon-form-field-select
                                    [(model)]="dataModel.icon_shape"
                                    monName="icon_shape"
                                    [monOptions]="options.iconShapes"
                                    monReturnValue="value"
                                    monPlaceholder="{{ 'Select a shape' | translate }}"
                                    [monRequired]="true"
                                    [monNoAutoComplete]="true">
                                </mon-form-field-select>
                            </div>
                        </div>

                        <div class="row mr-lg-0 widget-title">
                            <div class="col-48">
                                <h2 class="font-weight-300 font-size-18 mt-4 mb-1 ml-4" translate>Widget Design and Text</h2>
                            </div>
                            <hr class="mt-0 mb-4 col-48"/>
                        </div>

                        <div class="form-group row">
                            <label class="col-form-label text-right text-bold col-sm-11"
                                    for="page_assist_menu_theme"
                                    translate>
                                Menu theme
                            </label>
                            <div class="col-sm-37">
                                <mon-form-field-select
                                    [(model)]="dataModel.theme"
                                    monName="theme"
                                    [monOptions]="options.themeItems"
                                    monReturnValue="value"
                                    monPlaceholder="{{ 'Select a group' | translate }}"
                                    [monRequired]="true"
                                    [monNoAutoComplete]="true">
                                </mon-form-field-select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-28 row">
                                    <div class="col-sm-20 text-right">
                                        <label class="col-form-label text-bold inline-block pr-xl-1" translate>
                                            Main color
                                        </label>
                                    </div>

                                    <div class="col-sm-28 pl-xl-2" *ngIf="dataModel.theme === options.themeItems[0].value">
                                        <div class="mb-3">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateMainColor($event)"
                                                [colorValue]="dataModel.mainColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.mainColor" name="mainColor" />
                                        </div>
                                    </div>

                                    <div class="col-sm-28 pl-xl-2" *ngIf="dataModel.theme === options.themeItems[1].value">
                                        <div class="mb-3">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateMainDarkColor($event)"
                                                [colorValue]="dataModel.mainDarkColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.mainDarkColor" name="mainDarkColor" />
                                        </div>
                                    </div>

                                    <div class="col-sm-20 text-right">
                                        <label class="col-form-label text-bold inline-block pr-xl-1" translate>
                                            Text color
                                        </label>
                                    </div>

                                    <div class="col-sm-28 pl-xl-2" *ngIf="dataModel.theme === options.themeItems[0].value">
                                        <div class="mb-3">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateTextColor($event)"
                                                [colorValue]="dataModel.textColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.textColor" name="textColor" />
                                        </div>
                                    </div>

                                    <div class="col-sm-28 pl-xl-2" *ngIf="dataModel.theme === options.themeItems[1].value">
                                        <div class="mb-3">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateTextDarkColor($event)"
                                                [colorValue]="dataModel.textDarkColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.textDarkColor" name="textDarkColor" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-20">
                                    <div *ngIf="mainColorRatio || mainDarkColorRatio" class="color-contrast-ratio-container">
                                        <p class="pl-2 pt-1" translate>Color contrast ratio</p>
                                        <div class="pl-2" *ngIf="mainColorRatio && dataModel.theme === options.themeItems[0].value">
                                            <span class="text-bold h1">
                                                    {{ mainColorRatio }}
                                            </span>
                                            <span *ngIf="mainColorRatio >= 4"
                                                    class="text-success" translate>
                                                    (Good)
                                            </span>
                                            <span *ngIf="mainColorRatio < 4"
                                                    class="text-danger" translate>
                                                    (Bad)
                                            </span>
                                        </div>
                                        <div class="pl-2" *ngIf="mainDarkColorRatio && dataModel.theme === options.themeItems[1].value">
                                            <span class="text-bold h1">
                                                    {{ mainDarkColorRatio }}
                                            </span>
                                            <span *ngIf="mainDarkColorRatio >= 4"
                                                    class="text-success" translate>
                                                    (Good)
                                            </span>
                                            <span *ngIf="mainDarkColorRatio < 4"
                                                    class="text-danger" translate>
                                                    (Bad)
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-28 row">
                                    <div class="col-sm-20 text-right">
                                        <label class="col-form-label text-bold inline-block pr-xl-1"
                                                translate>
                                                Link color
                                        </label>
                                    </div>
                                    <div class="col-sm-28 pl-xl-2">
                                        <div class="mb-3" *ngIf="dataModel.theme === options.themeItems[0].value">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateLinkColor($event)"
                                                [colorValue]="dataModel.linkColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.linkColor" name="linkColor" />
                                        </div>
                                        <div class="mb-3" *ngIf="dataModel.theme === options.themeItems[1].value">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateLinkColorDark($event)"
                                                [colorValue]="dataModel.linkColorDark"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.linkColorDark" name="linkColorDark" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-20">
                                    <div *ngIf="linkColorRatio || linkColorDarkRatio" class="color-contrast-ratio-container mt-1">
                                        <div class="pl-2" *ngIf="linkColorRatio && dataModel.theme === options.themeItems[0].value">
                                            <span class="text-bold h1">
                                                    {{ linkColorRatio }}
                                            </span>
                                            <span *ngIf="linkColorRatio >= 4"
                                                    class="text-success" translate>
                                                    (Good)
                                            </span>
                                            <span *ngIf="linkColorRatio < 4"
                                                    class="text-danger" translate>
                                                    (Bad)
                                            </span>
                                        </div>
                                        <div class="pl-2" *ngIf="linkColorDarkRatio && dataModel.theme === options.themeItems[1].value">
                                            <span class="text-bold h1">
                                                    {{ linkColorDarkRatio }}
                                            </span>
                                            <span *ngIf="linkColorDarkRatio >= 4"
                                                    class="text-success" translate>
                                                    (Good)
                                            </span>
                                            <span *ngIf="linkColorDarkRatio < 4"
                                                    class="text-danger" translate>
                                                    (Bad)
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-28 row">
                                    <div class="col-sm-20 text-right">
                                        <label class="col-form-label text-bold inline-block pr-xl-1"
                                                translate>
                                                Button hover color
                                        </label>
                                    </div>
                                    <div class="col-sm-28 pl-xl-2">
                                        <div *ngIf="dataModel.theme === options.themeItems[0].value">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateButtonHoverColor($event)"
                                                [secondColor]="PAGE_ASSIST_SCRIPT_CONSTANT.BUTTON_HOVER_BACKGROUND_COLOR.LIGHT"
                                                [colorValue]="dataModel.buttonHoverColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.buttonHoverColor" name="buttonHoverColor" />
                                        </div>
                                        <div *ngIf="dataModel.theme === options.themeItems[1].value">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateButtonHoverDarkColor($event)"
                                                [secondColor]="PAGE_ASSIST_SCRIPT_CONSTANT.BUTTON_HOVER_BACKGROUND_COLOR.DARK"
                                                [colorValue]="dataModel.buttonHoverDarkColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.buttonHoverDarkColor" name="buttonHoverDarkColor" />
                                        </div>
                                        <div class="mt-1 mb-3">
                                            <span *ngIf="dataModel.theme === options.themeItems[0].value && (mainColorRatio && mainColorRatio < 4 ) || (linkColorRatio && linkColorRatio < 4) || (buttonHoverColorRatio && buttonHoverColorRatio < 4)"
                                                class="text-danger"
                                                translate>Your color contrast is below the recommended score</span>
                                            <span *ngIf="dataModel.theme === options.themeItems[1].value && (mainDarkColorRatio && mainDarkColorRatio < 4 ) || (linkColorDarkRatio && linkColorDarkRatio < 4) || (buttonHoverDarkColorRatio && buttonHoverDarkColorRatio < 4)"
                                                class="text-danger"
                                                translate>Your color contrast is below the recommended score</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-20">
                                    <div class="color-contrast-ratio-container mt-1">
                                        <div class="pl-2" *ngIf="buttonHoverColorRatio && dataModel.theme === options.themeItems[0].value">
                                            <span class="text-bold h1">
                                                    {{ buttonHoverColorRatio }}
                                            </span>
                                            <span *ngIf="buttonHoverColorRatio >= 4"
                                                    class="text-success" translate>
                                                    (Good)
                                            </span>
                                            <span *ngIf="buttonHoverColorRatio < 4"
                                                    class="text-danger" translate>
                                                    (Bad)
                                            </span>
                                        </div>
                                        <div class="pl-2" *ngIf="buttonHoverDarkColorRatio && dataModel.theme === options.themeItems[1].value">
                                            <span class="text-bold h1">
                                                    {{ buttonHoverDarkColorRatio }}
                                            </span>
                                            <span *ngIf="buttonHoverDarkColorRatio >= 4"
                                                    class="text-success" translate>
                                                    (Good)
                                            </span>
                                            <span *ngIf="buttonHoverDarkColorRatio < 4"
                                                    class="text-danger" translate>
                                                    (Bad)
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-28 row align-content-end">
                                    <div class="col-sm-20 text-right">
                                        <label class="col-form-label text-bold inline-block pr-1"
                                                translate>
                                            Add icon border
                                        </label>
                                        <span class="fas fa-question-circle py-1 inline-block"
                                            aria-hidden="true"
                                            ngbTooltip="{{'Adds a 3px wide border to the PageAssist widget icon' | translate}}"
                                            placement="auto"
                                            container="body">
                                        </span>
                                    </div>
                                    <div class="col-sm-28 pl-xl-2 d-flex align-items-center">
                                        <input type="checkbox"
                                            name="mainButtonBorderColorEnabled"
                                            [(ngModel)]="dataModel.mainButtonBorderColorEnabled">
                                    </div>
                                    <div class="col-sm-28 pl-xl-2" *ngIf="dataModel.mainButtonBorderColorEnabled">
                                        <div class="mb-3">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateMainButtonBorderColor($event)"
                                                [colorValue]="options.mainButtonBorderColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.mainButtonBorderColor" name="mainButtonBorderColor" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-11 text-right pr-xl-2">&nbsp;</div>
                                <div class="col-sm-37">
                                    <mon-spinner [loading]="loadingLogo">
                                        <div class="logo-thumbnail-container" *ngIf="dataModel.logo_picture_url || dataModel.logo_picture_base64">
                                            <button class="btn-close" type="button" (click)="removeLogoImage()">
                                                <span class="fas fa-times fa-fw" aria-hidden="true"></span>
                                                <span class="sr-only" translate>Remove logo upload</span>
                                            </button>
                                            <img [attr.src]="dataModel.logo_picture_url || dataModel.logo_picture_base64"
                                                    (imageOnload)="loadingLogo = false"
                                                    alt="{{'PageAssist Logo' | translate}}"
                                                    class="img-thumbnail"/>
                                        </div>
                                    </mon-spinner>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-11 text-right pr-xl-2">
                                    <label class="col-form-label text-bold pr-1" for="page_assist_upload_logo" translate>
                                        Upload logo
                                    </label>
                                    <span class="fas fa-question-circle py-1 inline-block"
                                            aria-hidden="true"
                                            ngbTooltip="{{'Press \'Upload link\' to save your URL settings' | translate}}"
                                            placement="auto"
                                            container="body">
                                    </span>
                                </div>
                                <div class="col-sm-37">
                                    <input type="text"
                                            (keyup)="$event.key === 'Enter' && confirmLogoUrl()"
                                            (mouseover)="highlightUpload = true"
                                            (mouseleave)="highlightUpload = false"
                                            (focus)="highlightUpload = true"
                                            (blur)="highlightUpload = false"
                                            class="form-control"
                                            [(ngModel)]="logoUrlCandidate"
                                            (change)="onLogoPictureChange()"
                                            [attr.placeholder]="'Paste your image link (URL) here' | translate"
                                            name="page_assist_upload_logo"
                                            id="page_assist_upload_logo"/>
                                    <input type="file"
                                            #page_assist_upload_logo_file
                                            accept="image/png, image/jpeg, image/bmp, image/gif, image/x-rgb, image/x-xbitmap, image/x-xpixmap"
                                            class="hidden"
                                            (change)="uploadLogoFile($event)"
                                            id="page_assist_upload_logo_file"/>
                                    <input type="hidden" [(ngModel)]="dataModel.logo_picture_base64" name="logo_picture_base64" />
                                    <p class="mt-1 text-danger"
                                        *ngIf="!isLogoURLValid"
                                        translate>
                                        Insert a valid URL
                                    </p>
                                    <div class="mt-1">
                                        <button [ngClass]="{'btn-secondary': !logoUrlCandidate || !isLogoURLValid, 'emphasised-btn': logoUrlCandidate && isLogoURLValid }"
                                            class="btn"
                                            (mouseover)="highlightUpload = true"
                                            (mouseleave)="highlightUpload = false"
                                            (focus)="highlightUpload = true"
                                            (blur)="highlightUpload = false"
                                            type="button"
                                            (click)="confirmLogoUrl()"
                                            [disabled]="!logoUrlCandidate || !isLogoURLValid"
                                            translate>
                                        Upload link
                                        </button>
                                        <button class="btn btn-border-radius btn-secondary"
                                                type="button"
                                                (click)="logoUploadBrowse($event)"
                                                (mouseover)="highlightUpload = true"
                                                (mouseleave)="highlightUpload = false"
                                                (focus)="highlightUpload = true"
                                                (blur)="highlightUpload = false"
                                                translate>
                                            Browse
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-11 text-right pr-xl-2">&nbsp;</div>
                                <div class="col-sm-37">
                                    <p translate class="mb-0 mt-2">
                                        <strong>NOTE</strong>: Changing the text below, will override the text in all your included languages
                                    </p>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-11 text-right pr-xl-2">
                                    <label class="col-form-label text-bold inline-block pr-1" for="page_assist_button_hover_message" translate>
                                        Button hover message
                                    </label>
                                    <span class="fas fa-question-circle py-1 inline-block"
                                            aria-hidden="true"
                                            ngbTooltip="{{'This message will be displayed when the user hovers over the PageAssist button' | translate}}"
                                            placement="auto"
                                            container="body">
                                    </span>
                                </div>
                                <div class="col-sm-37">
                                    <input id="page_assist_button_hover_message"
                                            (focus)="highlightMessage = true"
                                            (blur)="highlightMessage = false"
                                            (mouseover)="highlightMessage = true"
                                            (mouseleave)="highlightMessage = false"
                                            placeholder="{{'Add text for when the button is hovered' | translate}}"
                                            maxlength="50"
                                            ng-maxlength="50"
                                            type="text"
                                            [(ngModel)]="dataModel.greeting"
                                            name="greeting"
                                            ng-model-options="{debounce: 200}"
                                            class="form-control"/>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-form-label text-right text-bold col-sm-11"
                                        for="page_assist_title"
                                        translate>
                                    Title
                                </label>
                                <div class="col-sm-37">
                                    <input id="page_assist_title"
                                            (focus)="highlightTitle = true"
                                            (blur)="highlightTitle = false"
                                            (mouseover)="highlightTitle = true"
                                            (mouseleave)="highlightTitle = false"
                                            placeholder="{{'Add title' | translate}}"
                                            type="text"
                                            [(ngModel)]="dataModel.title"
                                            name="title"
                                            maxlength="70"
                                            class="form-control"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-form-label text-right text-bold col-sm-11"
                                        for="page_assist_title_text"
                                        translate>
                                    Title Text
                                </label>
                                <div class="col-sm-37">
                                <textarea id="page_assist_title_text"
                                            (focus)="highlightText = true"
                                            (blur)="highlightText = false"
                                            (mouseover)="highlightText = true"
                                            (mouseleave)="highlightText = false"
                                            placeholder="{{'Add a description of the widget to your users' | translate}}"
                                            [(ngModel)]="dataModel.title_text"
                                            name="title_text"
                                            maxlength="250"
                                            rows="5"
                                            class="form-control"></textarea>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="align-self-end pl-lg-0 col-lg-12 pb-3 d-none d-lg-block">
                        <sample-image *ngIf="dataModel.theme === options.themeItems[0].value"
                                        [highlightMessage]="highlightMessage"
                                        [highlightUpload]="highlightUpload"
                                        [highlightTitle]="highlightTitle"
                                        [highlightText]="highlightText"
                                        [direction]="dataModel.direction"
                                        [widgetSchemeColor]="dataModel.mainColor"
                                        [drawLineColor]="dataModel.textColor"
                                        [iconShape]="dataModel.icon_shape">
                        </sample-image>
                        <sample-image *ngIf="dataModel.theme === options.themeItems[1].value"
                                        [highlightMessage]="highlightMessage"
                                        [highlightUpload]="highlightUpload"
                                        [highlightTitle]="highlightTitle"
                                        [highlightText]="highlightText"
                                        [direction]="dataModel.direction"
                                        [widgetSchemeColor]="dataModel.mainDarkColor"
                                        [drawLineColor]="dataModel.textDarkColor"
                                        [iconShape]="dataModel.icon_shape">
                        </sample-image>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="ml-auto col-auto">
                        <button class="btn btn-border-radius btn-secondary" [disabled]="!designTouched" type="button" (click)="resetDesignProperties()" translate>
                            Reset design
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-48 col-md-36 mt-3">

                        <div class="row">
                            <hr class="col"/>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" translate>
                                    Include Accessibility Statement
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                        aria-hidden="true"
                                        ngbTooltip="{{'Includes a button with a link to your accessibility statement' | translate}}"
                                        placement="auto"
                                        container="body">
                                </span>
                            </div>
                            <div class="col-sm-37">
                                <mon-switch name="switch_accessibility_statement"
                                    monLabel="switch_accessibility_statement"
                                    [(ngModel)]="dataModel.switchAccessibilityStatementURL">
                                </mon-switch>
                            </div>
                        </div>

                        <div *ngIf="dataModel.switchAccessibilityStatementURL" class="form-group row">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block" translate>
                                    URL
                                </label>
                            </div>
                            <div class="col-sm-37">
                                <mon-form-field-input
                                        required="true"
                                        name="accessibilityStatementURL"
                                        [(ngModel)]="dataModel.accessibilityStatementURL"
                                        (change)="validAccessibilityStatementURL = isValidUrl(dataModel.accessibilityStatementURL)"
                                        monPlaceholder="{{ 'URL' | translate }}"
                                        monType="text">
                                </mon-form-field-input>
                            </div>
                        </div>

                        <div *ngIf="!validAccessibilityStatementURL" class="form-group row">
                            <div class="col-sm-11 text-right pr-xl-2">&nbsp;</div>
                            <div class="col-sm-37">
                                <span class="text-danger pl-2 pt-1" translate>URL needs to be valid, include http:// or https:// and be under 100 characters</span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" translate>
                                    Include custom info text
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                        aria-hidden="true"
                                        ngbTooltip="{{'Customise the Information page' | translate}}"
                                        placement="auto"
                                        container="body">
                                </span>
                            </div>
                            <div class="col-sm-37">
                                <mon-switch name="pageassist_custom_info_text"
                                            monLabel="pageassist_custom_info_text"
                                            [(ngModel)]="dataModel.customInformationTextEnabled">
                                </mon-switch>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf="dataModel.customInformationTextEnabled">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="page_assist_custom_text" translate>
                                    Custom text
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                        aria-hidden="true"
                                        ngbTooltip="{{'Customise the Information page' | translate}}"
                                        placement="auto"
                                        container="body">
                                </span>
                            </div>
                            <div class="col-sm-37">
                                <span class="btn btn-secondary custom-text-link" ngbTooltip="Insert link" tooltip-placement="top" (click)="addLink()">
                                    <span class="fas fa-link" aria-hidden="true"></span>
                                </span>
                                <textarea id="page_assist_custom_text"
                                        [(ngModel)]="dataModel.customInformationText"
                                        name="customInformationText"
                                        (blur)="trimCustomInfo()"
                                        maxlength="1001"
                                        rows="5"
                                        class="form-control"></textarea>
                                <span *ngIf="dataModel && dataModel.customInformationText.length > 1000" class="text-danger pt-2" translate>This text must be under 1000 characters</span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block" translate>
                                    Included languages*
                                </label>
                            </div>
                            <div class="col-sm-37">
                                <mon-form-field-select
                                    [monMultiple]="true"
                                    [(model)]="dataModel.included_languages"
                                    monName="included_languages"
                                    [monOptions]="options.languages"
                                    monReturnValue="value"
                                    monPlaceholder="{{ 'Select languages' | translate }}"
                                    [monRequired]="true"
                                    (changeEvent)="onIncludedLanguagesChange()"
                                    [ngClass]="{'required-outline': !dataModel.included_languages.length}"
                                    [monNoAutoComplete]="true">
                                </mon-form-field-select>
                                <span *ngIf="!dataModel.included_languages.length"
                                        class="text-danger"
                                        translate>
                                    Please add at least one language
                                </span>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf="dataModel.included_languages.length">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block" translate>
                                    Default language*
                                </label>
                            </div>
                            <div class="col-sm-37">
                                <mon-form-field-select
                                    [(model)]="dataModel.language"
                                    monName="language"
                                    [monOptions]="availableDefaultLanguages"
                                    monReturnValue="value"
                                    monPlaceholder="{{ 'Select the default language' | translate }}"
                                    [ngClass]="{'required-outline': dataModel.included_languages.length && !dataModel.language}"
                                    [monRequired]="true"
                                    [monNoAutoComplete]="true">
                                </mon-form-field-select>
                                <span *ngIf="!dataModel.language"
                                        class="text-danger"
                                        translate>
                                    Please set the default language
                                </span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="pageassist_skip_to" translate>
                                    Add Skip to PageAssist
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                        aria-hidden="true"
                                        ngbTooltip="{{'Hitting the tab button will turn on Skip to PageAssist' | translate}}"
                                        placement="auto"
                                        container="body">
                                </span>
                            </div>
                            <div class="col-sm-37">
                                <mon-switch name="pageassist_skip_to"
                                            monLabel="pageassist_skip_to"
                                            [(ngModel)]="dataModel.skip_to">
                                </mon-switch>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="mon-dialog-footer">
        <div class="text-right">
            <button type="submit"
                    class="btn btn-border-radius btn-secondary">
                    <span translate>OK</span>
            </button>
        </div>
    </div>

</form>