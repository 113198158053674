(function () {
    'use strict';

    angular.module('modules.cache').run([
        'ng2MonEventsService',
        'ng2CacheService',
        function (ng2MonEventsService, ng2CacheService) {
            ng2MonEventsService.addListener('logout', function () {
                ng2CacheService.clearCache('users');
            });
        },
    ]);
})();
