(function () {
    'use strict';

    angular.module('modules.export').filter('hasExtension', hasExtension);

    hasExtension.$inject = ['ng2ExtensionService'];
    /* @ngInject */
    function hasExtension (ng2ExtensionService) {
        return filter;

        // //////////

        function filter () {
            ng2ExtensionService.isInstalled().then(function (res) {
                return res;
            });
        }
    }
})();
