(function () {
    'use strict';

    angular.module('modules.page-performance.page-details').component('pagePerformancePageDetailsLoadSequence', {
        templateUrl: 'app/modules/page-performance/page-details/dashboard/load-sequence/load-sequence.html',
        controller: PagePerformancePageDetailsLoadSequenceController,
        controllerAs: 'vm',
        bindings: {
            report: '<',
        },
    });

    PagePerformancePageDetailsLoadSequenceController.$inject = [
        '$filter',
        '$element',
        '$timeout',
        'gettextCatalog',
        'PAGE_PERFORMANCE_METRICS',
    ];
    /* @ngInject */
    function PagePerformancePageDetailsLoadSequenceController ($filter, $element, $timeout, gettextCatalog, PAGE_PERFORMANCE_METRICS) {
        const vm = this;
        vm.$onInit = activate;
        vm.$onChanges = onChanges;
        vm.getMetricIndicatorClass = getMetricIndicatorClass;
        vm.metricIndicatorStyle = metricIndicatorStyle;
        vm.metricIndicatorContainerClass = metricIndicatorContainerClass;
        vm.getMetricTooltip = getMetricTooltip;
        vm.showNewMetrics = false;

        function activate () {
            vm.lowValue = 2000;
            vm.highValue = 8000;
            vm.lowPercentage = '0%';
            vm.mediumPercentage = '0%';
            vm.highPercentage = '0%';
            vm.largestContentfulPaint = 0;
            vm.firstContentfulPaint = 0;
            vm.firstMeaningfulPaint = 0;
            vm.speedIndex = 0;
            vm.totalBlockingTime = 0;
            vm.calculatedScreenshots = [];
            vm.metricStyleCache = {};
            vm.iteratedObj = {};
            if (vm.report) {
                vm.showNewMetrics = vm.report.version === 'v2' || vm.report.version === 'v3';
                drawSequenceGraph();
            }
        }

        function onChanges (changes) {
            if (changes.report && changes.report.currentValue) {
                drawSequenceGraph();
            }
        }

        function drawSequenceGraph () {
            vm.screenshots = vm.report.audits['screenshot-thumbnails'].details;
            vm.metrics = vm.report.audits.metrics.details.items[0];
            vm.firstContentfulPaint = vm.metrics.firstContentfulPaint;
            vm.firstMeaningfulPaint = vm.metrics.firstMeaningfulPaint;
            vm.largestContentfulPaint = vm.metrics.largestContentfulPaint;
            vm.speedIndex = vm.metrics.speedIndex;
            vm.totalBlockingTime = vm.metrics.totalBlockingTime;
            vm.observedFirstPaint = vm.metrics.observedFirstPaint;
            vm.frameTime = vm.screenshots.scale / vm.screenshots.items.length;
            calculateTimeline();

            $timeout(getStyleOfOverlapingIndicator);
        }

        function getMetricIndicatorClass (value) {
            if (value < vm.lowValue) {
                return 'load-sequence-low';
            } else if (value < vm.highValue && value > vm.lowValue) {
                return 'load-sequence-medium';
            } else {
                return 'load-sequence-high';
            }
        }

        function metricIndicatorStyle (value) {
            // TODO replace method by static styles https://zube.io/monsido/monsido/c/29539
            if (!vm.maxValue) {
                return {
                    left: 0,
                    display: 'none',
                };
            } else {
                return {
                    left: 'calc(' + $filter('monNumber')(value / vm.maxValue, '0.[00]%') + ' - 35px)',
                };
            }
        }

        function getMetricTooltip (metric) {
            let translation = '';
            const metricIndicatorClass = metricIndicatorContainerClass(metric);

            if (
                metricIndicatorClass === 'metric-indicator-third' ||
                metricIndicatorClass === 'metric-indicator-second' ||
                metric === 'fcp'
            ) {
                translation = 'First Contentful Paint';
            }
            if (translation.length > 0) {
                translation = translation + '<br>';
            }

            if (vm.report.version === 'v1') {
                if (metricIndicatorClass === 'metric-indicator-third' || metric === 'fmp') {
                    translation = translation + 'First Meaningful Paint 5';
                }
            }

            if (metric === PAGE_PERFORMANCE_METRICS.LCP.toLowerCase()) {
                translation = translation + 'Largest Contentful Paint ';
            }

            if (metric === PAGE_PERFORMANCE_METRICS.SI.toLowerCase()) {
                translation = translation + 'Speed Index';
            }

            if (metric === PAGE_PERFORMANCE_METRICS.TBT.toLowerCase()) {
                translation = translation + 'Total Blocking Time';
            }

            return gettextCatalog.getString(translation, { newline: '<br>' });
        }

        function getStyleOfOverlapingIndicator () {
            var verticalSpacing = 10;
            var blocksArray = $element
                .find('.metric-indicator-text')
                .toArray()
                .map(function (el) {
                    var bounds = el.getBoundingClientRect();
                    return {
                        el: el,
                        left: bounds.left,
                        right: bounds.right,
                    };
                })
                .reverse();
            var entry;
            var elBounds;
            var collision;

            while (blocksArray.length) {
                entry = blocksArray.pop();
                elBounds = entry.el.getBoundingClientRect();

                collision = blocksArray.some(function (_entry) {
                    return (
                        (entry.left >= _entry.left && entry.left <= _entry.right) ||
                        (_entry.left >= entry.left && _entry.left <= entry.right)
                    );
                });

                if (collision) {
                    verticalSpacing += 15;
                    entry.el.style.setProperty('margin-top', verticalSpacing + 'px');
                }
            }
        }

        /**
         * @desc Calculate if the provided metric checkpoint is overlapping with other checkpoints and return corresponding css class so that the checkpoint's text position is put lower
         * @param {String} metric - metric checkpoints: 'fcp', 'fmp'
         * @return {String}
         */
        function metricIndicatorContainerClass (metric) {
            var containerWidth = $element[0].querySelector('.load-sequence-bar-container').clientWidth;
            var deltaFcpFmp = Math.abs(((vm.firstMeaningfulPaint - vm.firstContentfulPaint) / vm.maxValue) * 100 * containerWidth);
            if (metric === 'fmp') {
                if (deltaFcpFmp < 30) {
                    return 'metric-indicator-second';
                }
            }
            return '';
        }

        function calculateTimeline () {
            vm.maxValue = Math.max(
                vm.firstContentfulPaint,
                vm.firstMeaningfulPaint,
                vm.largestContentfulPaint,
                vm.speedIndex,
                vm.totalBlockingTime,
                vm.observedFirstPaint,
            );

            if (vm.lowValue >= vm.maxValue) {
                // Fill the whole bar with low value's color if the largest metric is less than the lowValue
                vm.lowPercentage = '100%';
                vm.mediumPercentage = '0%';
                vm.highPercentage = '0%';
            } else if (vm.lowValue < vm.maxValue && vm.maxValue < vm.highValue) {
                // If the time to interactive is smaller than highValue, calculate medium percentage by the largest metric
                vm.lowPercentage = $filter('monNumber')(vm.lowValue / vm.maxValue, '0.[00]%');
                vm.mediumPercentage = $filter('monNumber')((vm.maxValue - vm.lowValue) / vm.maxValue, '0.[00]%');
                vm.highPercentage = '0%';
            } else {
                vm.lowPercentage = $filter('monNumber')(vm.lowValue / vm.maxValue, '0.[00]%');
                vm.mediumPercentage = $filter('monNumber')((vm.highValue - vm.lowValue) / vm.maxValue, '0.[00]%');
                vm.highPercentage = $filter('monNumber')((vm.maxValue - vm.highValue) / vm.maxValue, '0.[00]%');
            }

            vm.firstVisualLoadedFrameIndex = Math.floor(vm.observedFirstPaint / vm.frameTime);
            vm.lastEmptyVisualFrameIndex = Math.floor((vm.firstContentfulPaint * 10) / vm.maxValue);
            vm.firstMeaningfulPaintFrameIndex = Math.floor((vm.firstMeaningfulPaint * 10) / vm.maxValue);
            vm.calculatedScreenshots = [];
            for (var i = 0, j = 0; i < 10; i++) {
                // Push empty screenshots
                if (i < vm.lastEmptyVisualFrameIndex) {
                    vm.calculatedScreenshots.push(vm.screenshots.items[0]);
                } else {
                    if (vm.screenshots.items[vm.firstVisualLoadedFrameIndex + j]) {
                        vm.calculatedScreenshots.push(vm.screenshots.items[vm.firstVisualLoadedFrameIndex + j]);
                        j++;
                    } else {
                        vm.calculatedScreenshots.push(vm.screenshots.items[vm.screenshots.items.length - 1]);
                    }
                }
            }
        }
    }
})();
