import { Injectable } from '@angular/core';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { MonUIRouterTransitionService } from '@monsido/ng2/services/ui-router-transition-service/ui-router-transition-service';
import { StateService } from '@uirouter/core';

@Injectable({
    providedIn: 'root',
})
export class LightDomainViewService {
    constructor (
        private monUIRouterTransitionService: MonUIRouterTransitionService,
        private state: StateService,
    ) {}

    goToDashboard (domain: Domain): void {
        const params = {
            domainId: domain.id,
        };

        this.monUIRouterTransitionService.onTransitionFinished(
            () => {
                this.state.go('base.customer.light.domain.page_fix.home', params);
            },
        );
    }
}
