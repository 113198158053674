(function () {
    'use strict';

    angular.module('modules.wizard').controller('BaseWizardController', BaseWizardController);

    BaseWizardController.$inject = ['vm', '$timeout', 'WizardHandlerService'];
    /* @ngInject */
    function BaseWizardController (vm, $timeout, WizardHandlerService) {
        activate();

        function activate () {
            vm.wizardStepIndex = vm.wizardStepIndex || 0;
            vm.wizardName = vm.wizardName || ''; // Wizard name is required to watch the correct Wizard Entity
            vm.isWizardFirstStep = true;
            vm.isWizardLastStep = true;
            $timeout(function () {
                WizardHandlerService.getObservableStep(vm.wizardName).subscribe(function () {
                    vm.isWizardFirstStep = WizardHandlerService.isFirstStep(vm.wizardName);
                    vm.isWizardLastStep = WizardHandlerService.isLastStep(vm.wizardName);
                });
            });
        }
    }
})();
