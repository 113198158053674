(function () {
    'use strict';
    angular.module('pages.accessibility').component('accessibilityDashboardNotifications', {
        templateUrl: 'app/pages/accessibility/dashboard/summary/notifications/notifications.html',
        controller: AccessibilityDashboardSummaryNotificationsController,
        controllerAs: 'vm',
        bindings: {
            summary: '<',
            selectedObs: '<',
            history: '<',
            accessibilityChecks: '<',
        },
    });

    AccessibilityDashboardSummaryNotificationsController.$inject = ['ng2SessionService', '$filter'];
    /* @ngInject */
    function AccessibilityDashboardSummaryNotificationsController (ng2SessionService, $filter) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.selectedObs.subscribe(function (resultType) {
                vm.resultType = resultType;
                vm.pagesWithIssuesResultType = getPagesWithIssuesByType();
                vm.checksWithIssuesResultType = getChecksWithIssuesByType();
                vm.checksCountResultType = getChecksCountBySelectedResultType();
                vm.failingChecksDifference = getFailingChecksDifference();
                vm.failingChecksPercentage = getFailingChecksPercentage();
            });
        }

        function getPagesWithIssuesByType () {
            if (!vm.summary) {
                return 0;
            }
            switch (vm.resultType) {
                case 'errors':
                    return vm.summary.pages_by_result_type.error || 0;
                case 'errors_and_warnings':
                    return vm.summary.pages_by_result_type.error_and_warning || 0;
                default:
                    return vm.summary.pages_with_issues || 0;
            }
        }

        function getChecksWithIssuesByType () {
            switch (vm.resultType) {
                case 'errors':
                    return getChecksCountByResultType(['error'], true);
                case 'errors_and_warnings':
                    return getChecksCountByResultType(['error', 'warning'], true);
                default:
                    return getChecksCountByResultType([], true);
            }
        }

        function getChecksCountBySelectedResultType () {
            switch (vm.resultType) {
                case 'errors':
                    return getChecksCountByResultType(['error']);
                case 'errors_and_warnings':
                    return getChecksCountByResultType(['error', 'warning']);
                default:
                    return getChecksCountByResultType();
            }
        }

        function getFailingChecksDifference () {
            if (vm.history.length < 1) {
                return 0;
            }

            return getChecksWithIssuesByType() - getChecksWithIssuesByTypeInHistory(vm.history[vm.history.length - 1]);
        }

        function getFailingChecksPercentage () {
            if (vm.history.length < 1) {
                return 0;
            }
            var current = getChecksWithIssuesByType();
            var last = getChecksWithIssuesByTypeInHistory(vm.history[vm.history.length - 1]);
            var min = Math.min(last, current);
            var max = Math.max(last, current);
            if (min === 0 && max > 0) {
                return 100;
            } else if (min === max) {
                return 0;
            } else {
                return (min / max) * 100;
            }
        }

        function getChecksWithIssuesByTypeInHistory (crawl) {
            var result = 0;
            var accessibilityParameters = getAccessibilityChecksParameters();

            for (var i = 0; i < accessibilityParameters.length; i++) {
                result += crawl.accessibility_checks[accessibilityParameters[i]] || 0;
            }

            return result;
        }

        function getAccessibilityChecksParameters () {
            var type;
            if (['errors', 'errors_and_warnings'].indexOf(vm.resultType) > -1) {
                type = vm.resultType;
            } else {
                type = ng2SessionService.domain.features.accessibility;
            }

            switch (type) {
                case 'errors':
                    return ['error'];
                case 'errors_and_warnings':
                    return ['error', 'warning'];
                case 'WCAG2-A':
                    return ['A'];
                case 'WCAG2-AA':
                    return ['A', 'AA'];
                case 'WCAG2-AAA':
                    return ['A', 'AA', 'AAA'];
                case 'WCAG21-A':
                    return ['A'];
                case 'WCAG21-AA':
                    return ['A', 'AA'];
                case 'WCAG21-AAA':
                    return ['A', 'AA', 'AAA'];
                default:
                    return [];
            }
        }

        function getChecksCountByResultType (resultTypes, onlyErrors) {
            var params = {
                result_types: resultTypes,
                only_errors: onlyErrors,
            };
            var checks = $filter('accessibilityChecksByResultTypes')(vm.accessibilityChecks, params);
            return checks.length;
        }
    }
})();
