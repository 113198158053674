import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RequestHeadersType, RequestOptionsType } from './options';

export type CancelerType = Subject<void>;

@Injectable()
export class MonHttpClientService extends HttpClient {
    private urlPrefix: string = '';
    private cancelAllRequests$ = new Subject<void>();
    private headers = new HttpHeaders();

    constructor (handler: HttpHandler) {
        super(handler);
    }

    cancelRequests (): void {
        this.cancelAllRequests$.next();
    }

    setUrlPrefix (prefix: string): void {
        this.urlPrefix = prefix;
    }

    patchObservable<T = unknown> (
        url: string,
        body: unknown,
        options?: RequestOptionsType,
        canceler$?: CancelerType,
    ): Observable<T> {
        const combinedOptions = this.combineOptions(options);
        const combinedCanceler$ = this.combineCancelers(canceler$);

        return super.patch(this.urlPrefix + url, body, combinedOptions)
            .pipe(takeUntil(combinedCanceler$)) as Observable<T>;
    }

    /**
     * @deprecated Use `patchObservable`.
     */
    patchPromise<T = unknown> (
        url: string,
        body: unknown,
        options?: RequestOptionsType,
    ): Promise<T> {
        return this.patchObservable<T>(url, body, options)
            .toPromise()
            .catch(err => Promise.reject(err.error || 'Server error'));
    }

    getObservable<T = unknown> (
        url: string,
        options?: RequestOptionsType,
        canceler$?: CancelerType,
    ): Observable<T> {
        const params = options?.params;

        if (params) {
            Object.keys(params).forEach(k => {
                if (params[k] == null) {
                    delete params[k];
                }
            });
        }

        const combinedOptions = this.combineOptions(options);
        const combinedCanceler$ = this.combineCancelers(canceler$);

        return super.get(this.urlPrefix + url, combinedOptions)
            .pipe(takeUntil(combinedCanceler$)) as Observable<T>;
    }

    /**
     * @deprecated Use `getObservable`.
     */
    getPromise<T = unknown> (url: string, options?: RequestOptionsType): Promise<T> {
        return this.getObservable<T>(url, options)
            .toPromise()
            .catch(err => Promise.reject(err || 'Server error'));
    }

    postObservable<T = unknown> (
        url: string,
        body?: unknown,
        options?: RequestOptionsType,
        canceler$?: CancelerType,
    ): Observable<T> {
        const combinedOptions = this.combineOptions(options);
        const combinedCanceler$ = this.combineCancelers(canceler$);

        return super.post(this.urlPrefix + url, body, combinedOptions)
            .pipe(takeUntil(combinedCanceler$)) as Observable<T>;
    }

    /**
     * @deprecated Use `postObservable`.
     */
    postPromise<T = unknown> (
        url: string,
        body?: unknown,
        options?: RequestOptionsType,
    ): Promise<T> {
        return this.postObservable<T>(url, body, options)
            .toPromise()
            .catch(err => {
                return Promise.reject(err || 'Server error');
            });
    }

    deleteObservable<T = unknown> (
        url: string,
        options?: RequestOptionsType,
        canceler$?: CancelerType,
    ): Observable<T> {
        const combinedOptions = this.combineOptions(options);
        const combinedCanceler$ = this.combineCancelers(canceler$);

        return super.delete(this.urlPrefix + url, combinedOptions)
            .pipe(takeUntil(combinedCanceler$)) as Observable<T>;
    }

    /**
     * @deprecated Use `deleteObservable`.
     */
    deletePromise<T = unknown> (url: string, options?: RequestOptionsType): Promise<T> {
        return this.deleteObservable<T>(url, options)
            .toPromise()
            .catch(err => {
                return Promise.reject(err.error || 'Server error');
            });
    }

    setHeader (name: string, value: string): void {
        this.headers = this.headers.set(name, String(value));
    }

    removeHeader (name: string): void {
        this.headers = this.headers.delete(name);
    }

    private combineCancelers (canceler$?: Subject<void>): Observable<[void, void]> | Observable<void> {
        return canceler$
            ? combineLatest([this.cancelAllRequests$, canceler$])
            : this.cancelAllRequests$.asObservable();
    }

    private combineOptions (options?: RequestOptionsType): RequestOptionsType {
        options = options || {};

        return {
            ...options,
            headers: this.combineHeaders(options.headers),
        };
    }

    private combineHeaders (headers?: RequestHeadersType): HttpHeaders {
        if (!headers) {
            return this.headers;
        }

        if (headers instanceof HttpHeaders) {
            return headers.keys().reduce(
                (prevHeaders, key) => {
                    const value = headers.get(key);

                    return value !== null
                        ? prevHeaders.set(key, value)
                        : prevHeaders;
                },
                this.headers,
            );
        }

        return Object.keys(headers).reduce(
            (prevHeaders, key) => {
                let value = headers[key];

                if (Array.isArray(value)) {
                    value = value.map(item => String(item));
                } else {
                    value = String(value);
                }

                return prevHeaders.set(key, value);
            },
            this.headers,
        );
    }
}
