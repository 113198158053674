import { ChartFactory } from './base-chart/chart-factory';
(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name PolicyLineChartFactory
     * @description
     * Create the necessary objects to shape the policy line chart
     */

    angular.module('blocks.service').factory('PolicyLineChartService', PolicyLineChartFactory);

    PolicyLineChartFactory.$inject = ['gettextCatalog', 'LineChart', 'ChartsDataHelper'];

    function PolicyLineChartFactory (gettextCatalog, LineChart, ChartsDataHelper) {
        let policyLineChartService;
        function init () {
            policyLineChartService = new ChartFactory(new LineChart());
            policyLineChartService.setOptions([gettextCatalog.getString('Issues'), gettextCatalog.getString('Content')]);
            policyLineChartService.setColors(['#da5c5b', '#f49956', '#ff9d4e', '#a9dad6']);
            policyLineChartService.setOverrides([2, 2]);
            policyLineChartService.setSeries([
                gettextCatalog.getString('Unwanted'),
                gettextCatalog.getString('Required'),
                gettextCatalog.getString('Content with issues'),
                gettextCatalog.getString('Scanned content'),
            ]);
            policyLineChartService.setData([
                'policy_with_violations_count',
                'policy_with_required_count',
                'pages_with_policy_matches_count',
                ['documents_count', 'page_count']],
            new ChartsDataHelper('time'));
            return policyLineChartService;
        }
        return init;
    }
})();
