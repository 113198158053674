(function () {
    'use strict';

    angular.module('pages.qa.nin').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.qa.nin',
                config: {
                    url: '/ni_numbers',
                    template: '<nin></nin>',
                    data: {
                        sidebar: 'quality',
                        title: gettextCatalog.getString('Acquia Optimize - National Identification No.'),
                    },
                    resolve: {
                        validateDomain: [
                            '$q',
                            'ng2SessionService',
                            '$state',
                            '$timeout',
                            '$stateParams',
                            'ng2MonUIRouterTransitionService',
                            function ($q, ng2SessionService, $state, $timeout, $stateParams, ng2MonUIRouterTransitionService) {
                                var deferred = $q.defer();
                                if (ng2SessionService.domain !== null && ng2SessionService.domain.scan.scan_cpr === true) {
                                    deferred.resolve(true);
                                } else {
                                    $timeout(function () {
                                        ng2MonUIRouterTransitionService.onTransitionFinished(
                                            () => $state.go('base.customer.domain.qa.spellcheck.pages', $stateParams),
                                        );
                                    });
                                    deferred.resolve(false);
                                }
                                return deferred.promise;
                            },
                        ],
                    },
                },
            },
        ];
    }
})();
