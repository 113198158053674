export const MON_ICONS: Record<string, string | Record<string, string | Record<string, string | Record<string, string>>>> = {
    STANDARD: {
        ALL: ' fa fa-all-icon', // Can't find any reference to this on fontawesome..
        DOMAIN: ' fas fa-globe ',
        WARNING: ' fas fa-exclamation-triangle',
    },
    NOTIFICATIONS: ' fas fa-bell',
    MENUES: {
        DASHBOARD: ' fas fa-th-large ',
        PRIORITIZED: ' fas fa-bell ',
        PAGE_FIX: ' fas fa-shield-check ',
        PAGE_PERFORMANCE: ' fas fa-tachometer-alt-average',
        POLICIES: ' fas fa-gavel ',
        QA: ' fas fa-check-square ',
        ACCESSIBILITY: ' fas fa-universal-access ',
        SEO: ' fas fa-chart-line ',
        HEARTBEAT: ' fas fa-heartbeat',
        STATISTICS: ' fas fa-chart-pie ',
        INVENTORY: ' fas fa-books ',
        REPORT_CENTER: ' fas fa-file-invoice',
        TASK: ' fas fa-check-circle',
        PROJECT: ' fas fa-folder',
        HEATMAPS: ' fas fa-fire ',
        DATA_PRIVACY: ' fas fa-lock-alt ',
        PLUS: ' fas fa-plus',
        HOME: ' fas fa-globe',
        DOMAIN_OVERVIEW: ' fas fa-globe',
        USERS: ' fas fa-users',
        COG: ' fas fa-cog',
        INFO: ' fas fa-question-circle',
        TRAINING: ' fas fa-graduation-cap',
        IDEAS: ' fas fa-lightbulb-on',
        EXPORTS: ' fas fa-file-upload',
        CLASSIC: ' fas fa-monument',
        SWITCH_ACCOUNT: ' fas fa-exchange-alt',
        KEYBOARD: ' fas fa-keyboard',
        LOG_OUT: ' fas fa-door-open',
    },
    TABLES: {
        PAGEDETAILS: ' fas fa-window',
        REDIRECT: ' fas fa-search',
    },
    SIDEMENUES: {
        ADMIN: {
            DOMAINS: ' fas fa-globe',
            USERS: ' fas fa-users',
            LABELS: ' fas fa-tags',
            AUDIT_LOG: ' fas fa-clock',
            SOURCE_CODE_EXCLUDES: ' fas fa-code',
            CUSTOMER_INFO: ' fas fa-building',
            ACCOUNT_SETTINGS: ' fas fa-cog',
            AGREEMENT: ' fas fa-handshake',
            SHORTCUTS: ' fas fa-th ',
            ROLES: ' fas fa-user-lock',
            PAGE_PERFORMANCE: {
                PROFILES: ' fas fa-tachometer-alt-average ',
            },
        },
        POLICIES: {
            SUMMARY: ' fas fa-home',
            PAGES: ' fas fa-copy',
            POLICIES: ' fas fa-gavel',
            IGNORED_PAGES: 'fas fa-eye-slash',
        },
        QA: {
            SUMMARY: ' fas fa-home',
            CONTENT: ' fas fa-copy',
            LINKS: ' fas fa-unlink',
            CONTENT_BROKEN_LINKS: ' fas fa-copy',
            BROKEN_LINKS: ' fas fa-unlink',
            BROKEN_IMAGES: ' fas fa-image',
            SPELLCHECK: ' fas fa-language',
            SPELLCHECK_SUMMARY: ' fas fa-home',
            SPELLCHECK_PAGES: ' fas fa-copy',
            MISSPELLINGS: ' fas fa-language',
            POTENTIAL_MISSPELLINGS: ' fas fa-language',
            IGNORED_MISSPELLING: 'fas fa-eye-slash',
            DICTIONARY: ' fas fa-book',
            NATIONAL_ID_NO: ' fas fa-id-card',
            READABILITY: ' fas fa-eye',
        },
        SEO: {
            SUMMARY: ' fas fa-home',
            PAGES: ' fas fa-copy',
            CHECKPOINTS: ' fas fa-check-circle',
        },
        DATA_PRIVACY: {
            SUMMARY: ' fas fa-home ',
            FASTTRACK: ' fas fa-road',
            LIST: ' fas fa-copy ',
            ISSUES: ' fas fa-lock-alt ',
            CHECKLIST: ' fas fa-bug ',
            IGNORED_CHECKS: ' fas fa-eye-slash ',
            GUIDELINES: ' fas fa-list ',
            COOKIE_STATISTICS: {
                COOKIE: ' fas fa-cookie ',
                COOKIE_BITE: ' fas fa-cookie-bite ',
                SUMMARY: ' fas fa-home ',
                CONSENT_LOG: ' fas fa-map-marker-alt  ',
                USER_ADDED_COOKIE: ' fas fa-user-check  ',
            },
            CONSENT_OVERVIEW: { // TODO dupplicate of COOKIE_STATISTICS remove after upgrade
                COOKIE: ' fas fa-cookie ',
                COOKIE_BITE: ' fas fa-cookie-bite ',
                SUMMARY: ' fas fa-home ',
                CONSENT_LOG: ' fas fa-map-marker-alt  ',
                USER_ADDED_COOKIE: ' fas fa-user-check  ',
            },
        },
        INVENTORY: {
            SUMMARY: ' fas fa-home',
            CONTENT: ' fas fa-copy',
            CONTENT_HTML: ' fas fa-window-maximize',
            CONTENT_DOCUMENTS: ' fas fa-file-alt',
            CONTENT_IMAGES: ' fas fa-file-image',
            CONTENT_INLINE_IMAGES: ' fas fa-file-image',
            CONTENT_LINKS: ' fas fa-link',
            CONTENT_PDF: ' fas fa-file-pdf',
            TECHNICAL: ' fas fa-wrench',
            TECHNICAL_FORM: ' fas fa-align-left',
            TECHNICAL_HEADLINK: ' fas fa-link',
            TECHNICAL_IFRAME: ' fas fa-code',
            TECHNICAL_FRAME: ' fas fa-code',
            TECHNICAL_CSS: ' fab fa-css3',
            TECHNICAL_JS: ' fab fa-js-square',
            TECHNICAL_SPREADSHEET: ' fas fa-file-spreadsheet',
            PERSONAL: ' fas fa-user',
            PERSONAL_EMAIL: ' fas fa-envelope',
        },
        STATISTICS: {
            SUMMARY: ' fas fa-home-lg',
            VISITORS: ' fas fa-users',
            VISITORS_SUMMARY: ' fas fa-home-lg',
            VISITORS_LOCATIONS: ' fas fa-globe-americas',
            VISITORS_LANGUAGE: ' fas fa-language',
            VISITORS_ORGANIZATIONS: ' fas fa-building',
            VISITORS_DEVICES: ' fas fa-laptop',
            VISITORS_BROWSERS: ' fab fa-chrome',
            VISITORS_OS: ' fab fa-windows',
            VISITORS_SCREEN: ' fas fa-desktop-alt',
            VISITORS_LOG: 'fas fa-file-alt',
            CONTENT: ' fas fa-pen-alt ',
            CONTENT_MOST_PAGES: ' fas fa-thumbs-up',
            CONTENT_LEAST_PAGES: ' fas fa-thumbs-down',
            CONTENT_MOST_DOWNLOADS: ' fas fa-download',
            CONTENT_WITHOUT_VISITS: ' fas fa-copy',
            CONTENT_ENTRY: ' fas fa-level-down-alt',
            CONTENT_EXIT: ' fas fa-level-up-alt',
            CONTENT_ENTRY_EXIT: ' fas fa-exchange-alt',
            CONTENT_BOUNCE_RATE: ' fas fa-arrow-alt-from-right',
            DOCUMENTS_WITHOUT_DOWNLOADS: ' fas fa-file-word',
            EVENTS: 'fas fa-mouse-pointer',
            TRAFFIC: ' fas fa-exchange-alt',
            TRAFFIC_REF: ' fas fa-external-link-alt',
            TRAFFIC_SEARCH: ' fas fa-search',
            CAMPAIGNS: ' fas fa-newspaper',
            SITE_SEARCH: ' fas fa-search-plus',
            SOURCES_SEARCH_ENGINES: ' fas fa-search',
            SOURCES_SOCIAL: ' fas fa-network-wired',
            SOURCES_REFERRERS: ' fas fa-external-link-alt',
            SOURCES_SUMMARY: 'fas fa-home-lg',
            SOURCES: 'fas fa-traffic-light',
        },
        PAGE_PERFORMANCE: {
            DASHBOARD: ' fas fa-home ',
            PAGES: ' fas fa-copy ',
        },
    },
    TABS: {
        PAGES: {
            ALL: ' fas fa-copy',
            HTML: ' fas fa-window-maximize',
            PDF: ' fas fa-file-pdf',
            DOCUMENT: ' fas fa-file-alt',
            SLIDE: 'fas fa-file-powerpoint',
        },
    },
    PAGE_FIX: {
        ACCESSIBILITY_CHECKS: ' fas fa-universal-access ',
        WEBSITE_STRUCTURE: ' fas fa-browser ',
        DESIGN: ' fas fa-palette ',
        LINKS: ' fas fa-link ',
        MEDIA: ' fas fa-video ',
        CUSTOM: ' fas fa-low-vision',
    },
    LINKS: {
        IMAGE: ' fas fa-image',
        LINK: ' fas fa-link',
    },
    LIGHT: {
        DASHBOARD: ' fas fa-th-large ',
        DOMAINS: '  fas fa-globe',
        PINNED: '  fas fa-star',
        USERS: ' fas fa-users',
        ACCOUNT_SETTINGS: ' fas fa-cog',
        EMAIL: ' fas fa-envelope',
        EDIT: ' fas fa-edit',
        PHONE: ' fas fa-phone',
        PAGE_FIX: {
            ICON: ' fas fa-shield-check ',
            HOME: ' fas fa-home ',
            ACCESSIBILITY: ' fas fa-universal-access',
        },
        USERS_VIEW: {
            ACTION: ' fas fa-chevron-down',
            EDIT_USER: ' fas fa-cog',
            DELETE: ' fas fa-trash-alt',
        },
        CONSENT_MANAGER: {
            HOME: ' fas fa-cookie',
        },
    },
    ACCESSIBILITY: {
        SUMMARY: ' fas fa-home',
        PAGES: ' fas fa-copy',
        ISSUES: ' fas fa-bug',
        GUIDELINES: ' fas fa-list',
        FASTTRACK: ' fas fa-road',
        CHECK: ' fas fa-check',
        PAUSE: ' fas fa-pause',
        TIMES: ' fas fa-times',
        PDF: ' fas fa-file-pdf',
        PDF_INTERNAL: ' fas fa-sign-in',
        PDF_EXTERNAL: ' fas fa-sign-out',
        FASTTRACK_OPEN_EXAMPLE: ' fas fa-search',
        MARK_AS_FIXED: ' fas fa-check',
        ISSUES_CHECKS: {
            REVIEW: ' fas fa-eye',
            ERROR: ' fas fa-bug',
            WARNING: ' fas fa-bell',
            MOBILE: ' fas fa-mobile',
        },
        IGNORED_PAGES: ' fas fa-eye-slash',
        PAGE_DETAILS: {
            IGNORED_CHECKS: ' fas fa-eye-slash',
            PASSED_CHECKS: ' fas fa-check',
        },
        DOCUMENTDETAILS: {
            NOT_TAGGED: ' fas fa-exclamation-triangle',
            NOT_SCANNED: ' fas fa-exclamation-circle',
        },
        TOOLS: {
            TOOLS: ' fas fa-tools',
            CONTRAST: ' fas fa-adjust',
            PAGE_DETAILS: {
                IGNORED_CHECKS: ' fas fa-eye-slash',
                PASSED_CHECKS: ' fas fa-check',
            },
        },
    },
    PRIORITIZED: {
        SUMMARY: ' fas fa-bell',
        EXCLAMATION: ' fas fa-exclamation-triangle',
        QUESTION_CIRCLE: '  fas fa-question-circle',
    },
    GLOBAL_POLICY_OVERVIEW: {
        POLICY_EXCHANGE_CENTER: {
            PAGE: ' fas fa-globe',
            PAGE_HTML: ' fas fa-copy',
            PAGE_DOCUMENT: ' fas fa-file-alt',
            LINK: ' fas fa-link',
        },
        POLICY_INFORMATION: {
            INFO: ' fas fa-info-circle',
            CLOCK: ' fas fa-clock',
            BAN: ' fas fa-ban',
            SYNC: ' fas fa-sync',
            COMMENT: ' fas fa-comment',
        },
        POLICY_TYPE: {
            UNWANTED: ' fas fa-ban',
            REQUIRED: ' fas fa-exclamation-triangle',
            SEARCH: ' fas fa-search',
            TEXT: ' fas fa-bug',
            SEO: ' fas fa-exclamation',
            ERROR: ' fas fa-bug',
            WARNING: ' fas fa-bell',
            REVIEW: ' fas fa-eye',
            CONTENT: ' fas fa-align-justify',
        },
    },
    POLICIES: {
        DASHBOARD: ' fas fa-gavel',
        PAGES: ' fas fa-copy',
        POLICIES: ' fas fa-gavel',
        LIST: ' fas fa-list-ul',
        EXCHANGE: ' fas fa-exchange-alt',
        QUEUE: ' fas fa-sync-alt',
        EXCLAMATION: ' fas fa-exclamation-triangle',
        QUESTION_CIRCLE: '  fas fa-question-circle',
        TAGS: {
            QA: ' fas fa-check-square',
            ACCESSIBILITY: ' fas fa-universal-access',
            SEO: ' fas fa-chart-line',
        },
        MODES: {
            violation: ' fas fa-ban',
            search: ' fas fa-search',
            required: ' fas fa-exclamation-triangle',
            archived: ' fas fa-archive',
        },
    },
    QA: {
        SUMMARY: ' fas fa-check-square',
        CONTENT: ' fas fa-copy',
        CONTENT_BROKEN_LINKS: ' fas fa-copy',
        BROKEN_LINKS: ' fas fa-unlink',
        BROKEN_IMAGES: ' fas fa-image',
        SPELLCHECK_SUMMARY: ' fas fa-home',
        SPELLCHECK_PAGES: ' fas fa-copy',
        MISSPELLINGS: ' fas fa-language',
        POTENTIAL_MISSPELLINGS: ' fas fa-language',
        DICTIONARY: ' fas fa-book',
        NATIONAL_ID_NO: ' fas fa-id-card',
        READABILITY: ' fas fa-eye',
        EXCLAMATION: ' fas fa-exclamation-triangle',
        QUESTION_CIRCLE: '  fas fa-question-circle',
    },
    SEO: {
        SUMMARY: ' fas fa-chart-line',
        PAGES: ' fas fa-copy',
        CHECKPOINTS: ' fas fa-check-circle',
    },
    CHANGES_HISTORY: {
        SUMMARY: ' fas fa-chart-bar',
    },
    STATISTICS: {
        SOURCES: {
            BROWSERS: {
                chrome: ' fab fa-chrome',
                'chrome mobile': ' fab fa-chrome',
                'chrome mobile ios': ' fab fa-chrome',
                safari: ' fab fa-safari',
                'mobile safari': ' fab fa-safari',
                firefox: ' fab fa-firefox',
                'firefox mobile': ' fab fa-firefox',
                'internet explorer': ' fab fa-chrome',
                'microsoft edge': ' fab fa-edge',
                'samsung browser': ' fab fa-browser', // No Samsung browser icon is currently available on fontawesome
            },
            DEVICES: {
                desktop: ' fas fa-desktop',
                tablet: ' fas fa-tablet-alt',
                smartphone: ' fas fa-mobile-alt',
            },
            OPERATION_SYSTEMS: {
                windows: ' fab fa-windows',
                mac: ' fab fa-apple',
                android: ' fab fa-android',
                ios: ' fab fa-apple',
                chrome: ' fab fa-chrome',
                linux: ' fab fa-linux',
                'gnu/linux': ' fab fa-linux',
            },
            UNKNOWN: ' fas fa-question',
            FROM: ' fas fa-users',
            LANGUAGE: ' fas fa-language',
            RESOLUTION: ' fas fa-window-maximize',
        },
    },
    ADMIN: {
        DOMAINS: {
            DOMAIN: ' fas fa-globe',
            WARNING: ' fas fa-exclamation-triangle',
            ACCESSIBILITY: ' fas fa-universal-access',
            STATISTICS_PIE: ' fas ga-chart-pie',
            READABILITY: ' fas fa-eye',
            DOCUMENT_SCAN: ' fas fa-file-alt',
            CODE: ' fas fa-code',
            PAGECORRECT: ' fas fa-shield-check',
            NOT_CRAWLING_AUTOMATICALLY: ' fas fa-clock',
            CRAWLING: ' fas fa-circle-notch',
            CHECK: ' fas fa-check',
            HOME: ' fas fa-home',
            EDIT: ' fas fa-cog',
            STATISTICS: ' fas fa-chart-line',
            REDO: ' fas fa-sync-alt',
            BOLT: ' fas fa-sync-alt',
            CLONE: ' fas fa-clone',
            TRASH: ' fas fa-trash-alt',
            STOP: ' fas fa-stop-circle',
            DATAPRIVACY: ' fas fa-lock-alt',
            PIN: 'far fa-star',
            UNPIN: 'fas fa-star',
            SCAN_DOMAIN_GROUPS: ' fas fa-redo-alt ',
        },
        LABELS: {
            LABELS: ' fas fa-tags',
            TAG: ' fas fa-tag',
            DELETE: ' fas fa-trash-alt',
            EDIT: ' fas fa-edit',
        },
        USERS: {
            MAIL: ' fas fa-envelope',
            ACTION: ' fas fa-chevron-down',
            EDIT_USER: ' fas fa-cog',
            DELETE: ' fas fa-trash-alt',
        },
    },
    DOMAIN: {
        MISSPELLINGS: ' fas fa-language',
        POTENTIAL_MISSPELLINGS: ' fas fa-info',
        BROKEN_LINKS: ' fas fa-unlink',
        BROKEN_IMAGES: ' fas fa-image',
        SEO_CHECKPOINTS: ' fas fa-exclamation',
        PAGES_WITHOUT_VISITS: ' fas fa-copy',
        COMMON_POLICY_VIOLATIONS: ' fas fa-gavel',
        QA: {
            QA: ' fas fa-check-square',
            RIGHT: ' fas fa-chevron-right',
            BROKEN_LINKS: ' fas fa-unlink',
            BROKEN_IMAGES: ' fas fa-file-image',
            MISSPELLINGS: ' fas fa-language',
        },
    },
    GLOBAL: {
        HELPCENTER: ' fas fa-graduation-cap',
        POLICIES: {
            RUNNING: ' fas fa-spinner',
            CIRCLE: ' fal fa-circle',
            ARCHIVED: ' fas fa-archive',
            PLAY: ' fas fa-play',
            EDIT: ' fas fa-edit',
            CLONE: ' fas fa-clone',
            DELETE: ' fas fa-trash-alt',
            CREATE: ' fas fa-check-circle',
        },
    },
    INVENTORY: {
        DASHBOARD: {
            RUNNING: ' fas fa-spinner',
            MISSPELLINGS: ' fas fa-language',
            LANGUAGE: ' fas fa-flag',
            CODE: ' fas fa-code',
            SPREADSHEET: ' fas fa-file-spreadsheet',
            WORD: ' fas fa-file-word',
        },
        DIALOGS: {
            LINKS: ' fas fa-link',
        },
    },
    DOCUMENTDETAILS: {
        DASHBOARD: ' fas fa-file-alt',
    },
    CSV_EXPORTS: {
        PAGES: ' fas fa-download',
    },
    ACTIONS: {
        ICON: ' fas fa-chevron-down',
        ADD: ' fas fa-plus',
        REMOVE: ' fas fa-trash-alt',
        EDIT: ' fas fa-edit ',
        REVIEW: ' fas fa-eye ',
        VIEW: ' fas fa-eye ',
    },
    SPINNER: {
        ICON: ' fas fa-spinner',
    },
    INTERCOM: {
        ICON: ' fas fa-comments',
    },
    PAGE_PERFORMANCE: {
        BEST_PRACTICE: ' fas fa-code ',
        PERFORMANCE: {
            ICON: 'fas fa-tachometer-alt-average',
            OPPORTUNITIES: ' fas fa-tachometer-fastest ',
            DIAGNOSTICS: ' fas fa-stethoscope ',
            HISTORY: ' fas fa-chart-bar',
        },
        PWA: ' fas fa-browser ',
        PROFILE: {
            ICON: ' fas fa-tachometer-alt-average',
            DEVICE: ' fas fa-laptop ',
            DEFAULT: ' fas fa-home-lg ',
        },
        PAGE: {
            FREQUENCY: ' fas fa-history ',
        },
    },
    REPORT_CENTER: {
        SCHEDULED: ' fas fa-calendar-alt ',
        RECEIVED: ' fas fa-paper-plane',
        NOT_SCHEDULED: ' fas fa-calendar-minus',
    },
};
