<div class="mon-dialog-header">
    <div class="outer-t-smurf">
        <mon-page-header header="{{'Scheduled Reports' | translate}}"
                         panelLeftClass="col-48 outer-t-mini">
        </mon-page-header>
    </div>
</div>
<div class="mon-dialog-body">
    <div class="page-details-view scheduled-reports-list">
        <div class="card panel-monsido-card mt-4 mx-4">
            <div class="card-body">
                <table class="table table-hover table-fixed">
                    <tbody>
                        <tr *ngFor="let report of reports; index as i">
                            <td class="col-42">
                                <div class="d-flex align-items-center" [attr.aria-label]="'Title & description' | translate">
                                    <img class="ml-2 report-img"
                                         alt="{{'Document' | translate}}"
                                         src="/images/report-center/report-icon1.jpg"/>
                                    <div class="ml-2">
                                        <p class="font-size-1-5 text-link mb-0">
                                            {{report.template.title | translate}}
                                        </p>
                                        <div class="text-grey">
                                            <ng-container *ngFor="let target of report.targets; index as i">
                                                <span class="outer-r-smurf"
                                                      *ngIf="i < maxTargets">
                                                    {{target.title}}
                                                    <span *ngIf="i < (maxTargets - 1) && i < report.targets.length -1">
                                                        <b>&middot;</b>
                                                    </span>
                                                </span>
                                            </ng-container>
                                            <span *ngIf="report.targets.length > maxTargets">
                                                 {{'+ ' + (report.targets.length - maxTargets) + ' more' | translate}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="col-6 text-v-middle text-right">
                                <button class="text-link mr-2 edit-icon"
                                      [attr.aria-label]="'Edit Report' | translate"
                                      (click)="editReport(report, i)">
                                    <mon-icon [icon]="['ACTIONS', 'EDIT']" class="fa-2x"></mon-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
