(function () {
    'use strict';

    angular.module('services.api').factory('PageNinRepo', PageNinRepo);

    PageNinRepo.$inject = ['ng2ApiService', 'ng2SessionService'];
    /* @ngInject */
    function PageNinRepo (ng2ApiService, ng2SessionService) {
        var service = {
            get: get,
            update: update,
        };

        return service;

        // /////////////

        function get (params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + params.page_id + '/ni_numbers', params, config);
        }

        function update (page_id, ni_number_id, data, params) {
            data = data || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            data.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/ni_numbers/' + ni_number_id,
                data,
                params,
            );
        }
    }
})();
