(function () {
    'use strict';

    angular.module('modules.export').factory('exporterPolicyParser', exporterPolicyParser);

    exporterPolicyParser.$inject = ['ng2HistoryHelperService', '$filter', 'gettextCatalog', 'complianceService'];
    /* @ngInject */
    function exporterPolicyParser (ng2HistoryHelperService, $filter, gettextCatalog, complianceService) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines () {
            return [
                gettextCatalog.getString('Crawled at'),
                gettextCatalog.getString('Pages crawled'),
                gettextCatalog.getString('Documents crawled'),
                gettextCatalog.getString('Domain compliance'),
                gettextCatalog.getString('Policies - violations'),
                gettextCatalog.getString('Policies - required'),
                gettextCatalog.getString('Changes since previous crawl'),
            ];
        }

        function parse (data) {
            var csv = [];
            var entry = [];
            var issuesStack = ['policy_with_required_count', 'policy_with_violations_count'];
            csv.push(getHeadlines());

            for (var i = 0; i < data.length; i++) {
                entry = [];
                entry.push($filter('userDate')(data[i].post_processing_done_at || data[i].date, 'LL LT'));
                entry.push(data[i].page_count);
                entry.push(data[i].documents_count);
                entry.push(getCompliance(data[i]));
                entry.push(data[i].policy_with_violations_count);
                entry.push(data[i].policy_with_required_count);
                entry.push(
                    getCaret(data[i].id, data, issuesStack) +
                        ' ' +
                        $filter('monNumber')(
                            ng2HistoryHelperService.getChangePercentageValue(data[i].id, data, issuesStack) / 100,
                            '0.[00]%',
                        ),
                );
                csv.push(entry);
            }
            return csv;
        }

        function getCompliance (history) {
            if (!history.policyComplianceData) {
                history.policyComplianceData = complianceService.policyHistoryCompliance(history);
            }
            if (history.policyComplianceData && history.policyComplianceData[0]) {
                return $filter('monNumber')(history.policyComplianceData[0] / 100, '0.[00]%');
            }
            return '';
        }

        function getCaret (id, data, issuesStack) {
            const value = ng2HistoryHelperService.getChangeValue(id, data, issuesStack);
            if (value > 0) {
                return String.fromCharCode(94);
            } else if (value < 0) {
                return 'ˇ';
            }
            return '';
        }
    }
})();
