import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonActionMenuComponent } from './action-menu.component';
import { MonButtonNextModule } from '../button/button-next.module';
import { MonActionMenuPanelModule } from '../action-menu-panel/action-menu-panel.module';

@NgModule({
    declarations: [
        MonActionMenuComponent,
    ],
    imports: [
        CommonModule,
        MonButtonNextModule,
        MonActionMenuPanelModule,
    ],
    exports: [MonActionMenuComponent],
})
class ActionMenuModule { }
export { ActionMenuModule as MonActionMenuModule };
