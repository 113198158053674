(function () {
    'use strict';
    /**
     * @memberof services
     * @ngdoc service
     * @name dateRangeService
     * @description Service to handle setting and updating date ranges across components
     */
    angular.module('services').service('dateRangeService', dateRangeService);

    dateRangeService.$inject = ['rx', 'moment', 'ParamService'];
    /* @ngInject*/
    function dateRangeService (rx, moment, ParamService) {
        var dateRangeOptions = {};

        return {
            setRangeGroup: setRangeGroup,
            setRangeDates: setRangeDates,
        };

        function setRangeGroup (name, initValue) {
            if (!dateRangeOptions[name]) {
                dateRangeOptions[name] = new rx.BehaviorSubject(initValue);
            }
            return dateRangeOptions[name];
        }

        function setRangeDates (options) {
            options = options || {};
            var date = {
                startDate: '',
                endDate: '',
            };
            var dateParams = ParamService.returnPrevParam();
            if (dateParams && dateParams.from) {
                date.startDate = moment(dateParams.from);
                if (dateParams.to && moment(dateParams.to).isBefore(moment().endOf('day'))) {
                    date.endDate = moment(dateParams.to);
                } else {
                    date.endDate = moment()
                        .subtract(options.todayAsDefaultEnd ? 0 : 1, 'days')
                        .endOf('day');
                }
            } else {
                date.endDate = moment()
                    .subtract(options.todayAsDefaultEnd ? 0 : 1, 'days')
                    .endOf('day');
                date.startDate = moment().subtract(7, 'days');
            }

            return date;
        }
    }
})();
