(function () {
    'use strict';

    angular.module('modules.roles').factory('RoleValidationService', RoleValidationService);

    RoleValidationService.$inject = ['RolesService'];

    /* @ngInject */
    function RoleValidationService (RolesService) {
        var vm = this;
        vm.roleSections = null;
        function init () {
            RolesService.userRoleSections.subscribe(function (roleSections) {
                vm.roleSections = roleSections;
            });
            return {
                hasAccessToRoleSection: hasAccessToRoleSection,
            };
        }
        return init();

        // /////////////

        function hasAccessToRoleSection (section) {
            if (section === null || !Array.isArray(vm.roleSections) || vm.roleSections.length === 0) {
                return true;
            }

            return vm.roleSections.some(function (roleSection) {
                return roleSection.value === section;
            });
        }
    }
})();
