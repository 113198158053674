export const ISSUE_TYPES = {
    BrokenLinks: {
        id: 'blnk',
        name: 'Broken link',
        route: 'base.customer.domain.qa.broken.links',
    },
    BrokenImages: {
        id: 'bimg',
        name: 'Broken image',
        route: 'base.customer.domain.qa.broken.images',
    },
    Misspelling: {
        id: 'mspel',
        name: 'Misspelling',
        route: 'base.customer.domain.qa.spellcheck.confirmed',
    },
    PotentialMisspelling: {
        id: 'pmspel',
        name: 'Potential Misspelling',
        route: 'base.customer.domain.qa.spellcheck.potential',
    },
    SeoIssue: {
        id: 'seo',
        name: 'SEO issue',
        route: 'base.customer.domain.seo.issues',
    },
    PolicyIssue: {
        id: 'pol',
        name: 'Policy',
        route: 'base.customer.domain.policy.policies',
    },
    AccessibilityIssue: {
        id: 'acc',
        name: 'Accessibility check',
        route: 'base.customer.domain.accessibility.issues',
    },
    GlobalPolicyIssue: {
        id: 'gpol',
        name: 'Global policy',
        route: 'base.customer.global.policy.policies',
    },
    PageDetails: {
        // This will account for all the modules above, that exists within pagedetails
        id: 'pgdtls',
        name: 'Page Details',
        route: 'base.customer.domain.dashboard',
    },
};
