import { Injectable } from '@angular/core';
import { ApiService } from '../../api-service/api.service';
import { StringShortISODateType } from '@monsido/ng2/types/date-string.type';

type PagePerformanceResultsRequestType = {
    limit: number,
    page_id: number,
    profile_id: number,
    to: StringShortISODateType,
    from: StringShortISODateType
};
type PagePerformanceResultType = {
    cumulative_layout_shift: number,
    first_contentful_paint: number,
    first_meaningful_paint: number,
    largest_contentful_paint: number,
    page_id: number,
    performance: number,
    profile_id: number,
    scanned_at: string,
    speed_index: number,
    total_blocking_time: number,
    version: string
}

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceResultsRepoService {

    constructor (private apiService: ApiService) { }

    getAll (params: PagePerformanceResultsRequestType, config): Promise<PagePerformanceResultType[]> {
        return this.apiService.get('performance/results', params, config);
    }
}
