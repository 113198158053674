import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { RequestParamsType } from '@monsido/ng2/external/http/options';

@Injectable({
    providedIn: 'root',
})
export class SupportRequestRepoService {

    constructor (private apiService: ApiService) { }

    create (params: RequestParamsType): Promise<void> {
        params = params || {};
        return this.apiService.post('support/request', params);
    }
}
