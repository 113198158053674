import { Injectable } from '@angular/core';
import { EssentialDomainDataModel } from '@client/app/services/total-domains/essential-domain-data.model';
import { EssentialDomainGroupDataModel } from '@client/app/services/total-domains/essential-domain-group-data.model';
import { TextSearchService } from '@monsido/ng2/services/text-search/text-search.service';

@Injectable({
    providedIn: 'root',
})
export class SearchDomainsService {
    constructor (private textSearchService: TextSearchService) { }

    searchInList (domains: Array<EssentialDomainDataModel | EssentialDomainGroupDataModel>, search, maxItems): unknown {
        if (!domains || !search) {
            return domains;
        }

        const directHits: unknown[] = [];
        const fuzzyHits: unknown[] = [];
        for (const domain of domains) {
            if (directHits.length == maxItems) {
                break;
            }

            if (domain.type === 'Domain') {
                if (this.hasDomainGroups((domain as EssentialDomainDataModel).domainGroups, search) || this.hasDomain(domain, search)) {
                    directHits.push(domain);
                } else if (this.hasDomainGroups((domain as EssentialDomainDataModel).domainGroups, search, true) || this.hasDomain(domain, search, true)) {
                    fuzzyHits.push(domain);
                }
            } else {
                if (this.hasDomainGroup(domain, search)) {
                    directHits.push(domain);
                } else if (this.hasDomainGroup(domain, search, true)) {
                    fuzzyHits.push(domain);
                }
            }
        }
        return [...directHits, ...fuzzyHits].slice(0, maxItems);
    }

    private hasDomain (domain, search, isFuzzy = false): boolean {
        if (isFuzzy) {
            return this.textSearchService.fuzzySearch(search, domain.title) || this.textSearchService.fuzzySearch(search, domain.url);
        }
        return this.textSearchService.contains(search, domain.title) || this.textSearchService.contains(search, domain.url);
    }

    private hasDomainGroups (groups, search, isFuzzy = false): boolean {
        if (!groups) {
            return false;
        }
        return groups.some((domainGroup) => {
            return this.hasDomainGroup(domainGroup, search, isFuzzy);
        });
    }

    private hasDomainGroup (domainGroup, search, isFuzzy = false): boolean {
        if (isFuzzy) {
            return this.textSearchService.fuzzySearch(search, domainGroup.title);
        }
        return this.textSearchService.contains(search, domainGroup.title);
    }
}
