(function () {
    'use strict';

    angular.module('pages.introduction').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.introduction',
                config: {
                    url: '/introduction',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { anonymous_access: false },
                },
            },
            {
                state: 'base.introduction.index',
                config: {
                    url: '',
                    template: '<pages-introduction></pages-introduction>',
                },
            },
        ];
    }
})();
