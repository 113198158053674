(function () {
    'use strict';

    angular.module('modules.statistics').component('statisticsHasSampleMsg', {
        templateUrl: 'app/modules/statistics/components/has-sample-msg/has-sample-msg.html',
        controller: HasSampleDataController,
        controllerAs: 'vm',
        bindings: {},
    });

    HasSampleDataController.$inject = ['ng2SessionService'];
    /* @ngInject */
    function HasSampleDataController (ng2SessionService) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.domain = ng2SessionService.domain;
        }
    }
})();
