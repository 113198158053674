import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { ScoresInterface } from '../../interfaces/scores.interface';
import { SessionService } from '@monsido/core/session/session.service';
import { TranslateService } from '@client/app/services/translate/translate.service';

type AverageDataType = { name: string; score: number };

@Injectable({
    providedIn: 'root',
})
export class ScoresService {
    private cachedScores?: ScoresInterface;
    private cacheAlive = 3600000;

    private verticalNames: Record<string, string>;
    private defaultTitle: string;
    private defaultTooltip: string;

    constructor (private client: ApiClient, private translateService: TranslateService, private sessionService: SessionService) {
        this.verticalNames = {
            'financial-services': this.translateService.getString('Financial services'),
            'retail-wholesale': this.translateService.getString('Retail/wholesale'),
            'construction-industrial': this.translateService.getString('Construction/industrial'),
            'business-services': this.translateService.getString('Business services'),
            'government': this.translateService.getString('Government'),
            'non-profit': this.translateService.getString('Non-profit'),
            'education': this.translateService.getString('Education'),
            'law-enforcement': this.translateService.getString('Law enforcement'),
            'consumer-services': this.translateService.getString('Consumer services'),
            'entertainment-leisure': this.translateService.getString('Entertainment/Leisure'),
            'healthcare': this.translateService.getString('Healthcare'),
            'legal': this.translateService.getString('Legal'),
            'media': this.translateService.getString('Media'),
            'real-estate': this.translateService.getString('Real-estate'),
            'technology': this.translateService.getString('Technology'),
            'utilities-natural-resources': this.translateService.getString('Utilities/Natural resources'),
            'professional-services': this.translateService.getString('Professional services'),
            'other': this.translateService.getString('Other'),
        };
        this.defaultTitle = this.translateService.getString('Industry average');
        this.defaultTooltip = this.translateService.getString('The average compliance score for this module for domains from other organizations within your industry:');
    }

    async getAverageData (moduleName: string): Promise<AverageDataType> {
        const data = (await this.get()) || { vertical_averages: [], vertical: moduleName };
        const result = data.vertical_averages.find((vertical) => vertical.module === moduleName) || { score: 0 };

        return {
            name: this.getVerticalName(data.vertical),
            score: result.score,
        };
    }

    getAccessibilityDemoData (): AverageDataType {
        return {
            name: this.getVerticalName('Accessibility demo score'),
            score: 91.70,
        };
    }

    getQADemoData (): AverageDataType {
        return {
            name: this.getVerticalName('Quality assurance demo score'),
            score: 93.52,
        };
    }

    getSEODemoData (): AverageDataType {
        return {
            name: this.getVerticalName('SEO Demo score'),
            score: 94.16,
        };
    }

    getDefaultTitle (): string {
        return this.defaultTitle || '';
    }

    getDefaultTooltip (): string {
        return this.defaultTooltip || '';
    }

    getVerticalName (verticalName: string): string {
        return this.verticalNames[verticalName] || verticalName;
    }

    private async get (): Promise<ScoresInterface | undefined> {
        const { customer } = this.sessionService;
        if (!this.cachedScores && customer && customer.plan_traits?.monsido_score && customer.vertical) {
            this.cachedScores = await this.client.getPromise('scores');

            setTimeout(() => {
                this.cachedScores = undefined;
            }, this.cacheAlive);
        }

        return Promise.resolve(this.cachedScores);
    }
}
