(function () {
    'use strict';

    angular.module('modules.page_fix', [
        'modules.page_fix.components',
        'modules.page_fix.services',
        'modules.page_fix.forms',
        'modules.page_fix.pages',
    ]);
})();
