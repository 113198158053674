import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';
import { PageFixModel } from './page-fix-model';

type PageFixFixableType = {
    fixable_type: 'AccessibilityCheck' | 'AccessibilitySourceCode' | 'Link' | 'SpellingError' | 'Custom'
};
type PageFixChangeType = {
    change: {
        value: string,
        attribute: string
    }
    change_type: string,
}

export type PageFixType = PageFixCreateType & {
    custom_selector: string | string[] | null,
    created_at: string,
    customer_id: number,
    id: number,
    updated_at: string
}

type PageFixCreateType = PageFixChangeType & PageFixFixableType & {
    selector: string,
    fixable_id: number,
}

@Injectable({
    providedIn: 'root',
})
export class PageFixRepoService extends BaseRepo {

    constructor (protected sessionService: SessionService, private apiService: ApiService) {
        super();
    }

    getAll (params: BaseRepoRequestType & PageFixFixableType): Promise<MonTableCollection<PageFixModel>> {
        params = params || {};
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/page_fixes', params)
            .then((res: MonTableCollection<PageFixType>) => this.toModels(res));
    }

    create (params: BaseRepoRequestType & PageFixCreateType , contextToken): Promise<PageFixModel> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.post('domains/' + this.getDomainId(params.domain_id) + '/page_fixes', params, contextToken)
            .then((res: PageFixType) => this.toModel(res));
    }

    update (pageFix: PageFixType, data: PageFixChangeType, params: BaseRepoRequestType): Promise<PageFixModel> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.patch('domains/' + this.getDomainId(params.group_id) + '/page_fixes/' + pageFix.id, data, params)
            .then((res: PageFixType) => this.toModel(res));
    }

    destroy (id: number): Promise<void> {
        return this.apiService.delete('domains/' + this.getDomainId() + '/page_fixes/' + id);
    }

    deleteAll (params: BaseRepoRequestType & PageFixFixableType): Promise<void> {
        params = params || {};
        return this.apiService.delete('domains/' + this.sessionService.getDomainId(params) + '/page_fixes/', params);
    }

    private toModels (models: MonTableCollection<PageFixType>): MonTableCollection<PageFixModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models as unknown as MonTableCollection<PageFixModel>;
    }

    private toModel (model: PageFixType): PageFixModel {
        return new PageFixModel(model);
    }
}
