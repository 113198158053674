import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    AngularSharedComponentsModule,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import { SendMeReportFormComponent } from './send-me-report-form.component';
import {
    ReportCenterPipesModule,
} from '@monsido/modules/report-center/pipes/report-center-pipes.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        SendMeReportFormComponent,
    ],
    imports: [
        CommonModule,
        AngularSharedComponentsModule,
        TranslateModule,
        FormsModule,
        ReportCenterPipesModule,
    ],
    exports: [
        SendMeReportFormComponent,
    ],
})
export class SendMeReportFormModule {}
