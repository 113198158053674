(function () {
    'use strict';

    angular.module('pages.admin.auditlog').component('adminAuditlogChanges', {
        templateUrl: 'app/pages/admin/auditlog/changes/changes.html',
        controller: AuditlogChangesController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '<',
            identifier: '<',
            created: '<',
        },
    });

    AuditlogChangesController.$inject = ['ng2GlobalHelperService', 'Lodash'];
    /* @ngInject */
    function AuditlogChangesController (ng2GlobalHelperService, Lodash) {
        var vm = this;

        vm.$onInit = activate;
        vm.isBlacklisted = isBlacklisted;
        vm.isSimilar = isSimilar;

        function activate () {
            vm.changes = vm.ngModel.changes;
            vm.relationshipChanges = {};
            vm.ng2GlobalHelperService = ng2GlobalHelperService;

            for (var key in vm.ngModel.relationship_changes) {
                if (vm.ngModel.relationship_changes.hasOwnProperty(key)) {
                    if (
                        !vm.isSimilar(vm.ngModel.relationship_changes[key][0], vm.ngModel.relationship_changes[key][1])
                    ) {
                        vm.relationshipChanges[key] = vm.ngModel.relationship_changes[key];
                    }
                }
            }
        }

        function isBlacklisted (attr) {
            return ['updated_at'].indexOf(attr) !== -1;
        }

        function isSimilar (obj1, obj2) {
            return Lodash.isEqual(obj1, obj2);
        }
    }
})();
