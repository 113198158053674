(function () {
    'use strict';

    angular.module('base.product-updates').component('productUpdatesModules', {
        templateUrl: 'app/pages/base/product-updates/modules.html',
        controller: ProductUpdatesModulesController,
        controllerAs: 'vm',
        bindings: {
            onChange: '&',
        },
    });

    ProductUpdatesModulesController.$inject = ['gettextCatalog'];
    /* @ngInject */
    function ProductUpdatesModulesController (gettextCatalog) {
        var vm = this;
        vm.onCheck = onCheck;

        vm.$onInit = activate;

        function activate () {
            vm.modules = [
                {
                    name: gettextCatalog.getString('Quality Assurance'),
                    value: 'qa',
                    selected: false,
                },
                {
                    name: gettextCatalog.getString('Accessibility'),
                    value: 'accessibility',
                    selected: false,
                },
                {
                    name: gettextCatalog.getString('Policies'),
                    value: 'policy',
                    selected: false,
                },
                {
                    name: gettextCatalog.getString('SEO'),
                    value: 'seo',
                    selected: false,
                },
                {
                    name: gettextCatalog.getString('Heartbeat'),
                    value: 'uptime',
                    selected: false,
                },
                {
                    name: gettextCatalog.getString('Analytics'),
                    value: 'statistics',
                    selected: false,
                },
                {
                    name: gettextCatalog.getString('Inventory'),
                    value: 'inventory',
                    selected: false,
                },
                {
                    name: gettextCatalog.getString('Settings'),
                    value: 'settings',
                    selected: false,
                },
            ];
        }

        function onCheck () {
            var selectedModules = vm.modules
                .filter(function (mod) {
                    return mod.selected;
                })
                .map(function (mod) {
                    return mod.value;
                });

            vm.onChange({ modules: selectedModules });
        }
    }
})();
