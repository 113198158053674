(function () {
    'use strict';

    angular
        .module('modules.business-intelligence.services.api')
        .factory('businessIntelligenceApi', businessIntelligenceApi);

    businessIntelligenceApi.$inject = ['Client', 'BI'];
    /* @ngInject */
    function businessIntelligenceApi (Client, BI) {
        var api = new Client({
            url: BI.path,
            headers: {},
        });
        api.defaultSuccessCallback = function (response) {
            return response && response.data;
        };
        return api;
    }
})();
