import { Component, Input } from '@angular/core';
import { PAGE_ASSIST_SCRIPT_CONSTANT } from 'app/forms/domain-script-guide/features/pa-script-settings/pa-script.constant';

@Component({
    selector: 'sample-image',
    templateUrl: './sample-image.component.html',
    styleUrls: ['./sample-image.component.scss'],
})
export class SampleImageComponent {

    @Input() highlightMessage: boolean = false;
    @Input() highlightUpload: boolean = false;
    @Input() highlightTitle: boolean = false;
    @Input() highlightText: boolean = false;
    @Input() direction: string = '';
    @Input() iconShape: string = '';
    @Input() widgetSchemeColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.COLOR.LIGHT;
    @Input() drawLineColor: string = PAGE_ASSIST_SCRIPT_CONSTANT.TEXT_COLOR;
}
