(function () {
    'use strict';
    angular.module('app.components.pageDetails').component('pageDetailsSectionsTabs', {
        transclude: true,
        templateUrl: 'app/components/pagedetails/tabs/tabs.html',
        controller: TabsController,
        controllerAs: 'vm',
        bindings: {
            options: '<',
            onTabChange: '&',
            tab: '<',
        },
    });

    TabsController.$inject = [];
    /* @ngInject */
    function TabsController () {
        var vm = this;
        vm.$onInit = activate;
        vm.changeTab = changeTab;

        function activate () {
            vm.options = vm.options || [];
        }

        function changeTab (tab) {
            vm.onTabChange({ tab: tab });
        }
    }
})();
