import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageDetailsSectionAccessibilityImageComponent } from './components/page-details-section-accessibility-image/page-details-section-accessibility-image.component';
import { SharedModule } from 'ng2/shared/shared.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    declarations: [
        PageDetailsSectionAccessibilityImageComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
    ],
    exports: [
        PageDetailsSectionAccessibilityImageComponent,
    ],
})
export class PageDetailsModule { }
