(function () {
    'use strict';

    angular.module('modules.notifications').filter('notificationActionFilter', notificationActionFilter);

    notificationActionFilter.$inject = ['gettextCatalog', 'NOTIFICATION_CONSTANT'];

    /* @ngInject */
    function notificationActionFilter (gettextCatalog, NOTIFICATION_CONSTANT) {
        return actionFilter;

        // //////////

        function actionFilter (args) {
            const action = args[0];
            const target = args[1];
            const stage = args[2];

            if (stage === NOTIFICATION_CONSTANT.STAGE.DONE) {
                switch (action) {
                    case NOTIFICATION_CONSTANT.ACTION.COOKIES_FOUND:
                        return gettextCatalog.getString('Discovered ');
                    case NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_ERRORS_IGNORED:
                    case NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_CHECK_IGNORED:
                    case NOTIFICATION_CONSTANT.ACTION.IGNORED:
                    case NOTIFICATION_CONSTANT.ACTION.HIDDEN:
                        return gettextCatalog.getString('Ignored ');
                    case NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_CHECK_REVIEWED:
                    case NOTIFICATION_CONSTANT.ACTION.REVIEWED:
                        return gettextCatalog.getString('Reviewed ');
                    case NOTIFICATION_CONSTANT.ACTION.DEAD:
                        return gettextCatalog.getString('Dead');
                    case NOTIFICATION_CONSTANT.ACTION.REQUEST_SCAN:
                        return gettextCatalog.getString('Scan');
                    case NOTIFICATION_CONSTANT.ACTION.CRAWL_DONE:
                        return gettextCatalog.getString('Crawl done on');
                    case NOTIFICATION_CONSTANT.ACTION.CRAWL_FINALIZED:
                        return gettextCatalog.getString('Crawl finalized on');
                    case NOTIFICATION_CONSTANT.ACTION.FIXED:
                        return gettextCatalog.getString('Fixed ');
                    case NOTIFICATION_CONSTANT.ACTION.UPDATE:
                        return gettextCatalog.getString('Updated ');
                    case NOTIFICATION_CONSTANT.ACTION.CREATE:
                        switch (target) {
                            case NOTIFICATION_CONSTANT.TARGET.SPELLING_IGNORED:
                                return gettextCatalog.getString('Ignored ');
                            case NOTIFICATION_CONSTANT.TARGET.SPELLING_CONFIRMED:
                                return gettextCatalog.getString('Confirmed ');
                            case NOTIFICATION_CONSTANT.TARGET.SPELLING_DICTIONARY:
                                return gettextCatalog.getString('Added ');
                            case NOTIFICATION_CONSTANT.TARGET.EXPORT:
                                return gettextCatalog.getString('Export is ready');
                        }
                }
            } else {
                switch (action) {
                    case NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_ERRORS_IGNORED:
                    case NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_CHECK_IGNORED:
                    case NOTIFICATION_CONSTANT.ACTION.IGNORED:
                    case NOTIFICATION_CONSTANT.ACTION.HIDDEN:
                        return gettextCatalog.getString('Ignoring ');
                    case NOTIFICATION_CONSTANT.ACTION.ACCESSIBILITY_CHECK_REVIEWED:
                    case NOTIFICATION_CONSTANT.ACTION.REVIEWED:
                        return gettextCatalog.getString('Reviewing ');
                    case NOTIFICATION_CONSTANT.ACTION.DEAD:
                        return gettextCatalog.getString('Dead');
                    case NOTIFICATION_CONSTANT.ACTION.CRAWL_DONE:
                        return gettextCatalog.getString('Crawl is getting done on');
                    case NOTIFICATION_CONSTANT.ACTION.CRAWL_FINALIZED:
                        return gettextCatalog.getString('Crawl is finalizing on');
                    case NOTIFICATION_CONSTANT.ACTION.FIXED:
                        return gettextCatalog.getString('Fixing ');
                    case NOTIFICATION_CONSTANT.ACTION.UPDATE:
                        return gettextCatalog.getString('Updating ');
                    case NOTIFICATION_CONSTANT.ACTION.CREATE:
                        switch (target) {
                            case NOTIFICATION_CONSTANT.TARGET.SPELLING_IGNORED:
                                return gettextCatalog.getString('Ignoring ');
                            case NOTIFICATION_CONSTANT.TARGET.SPELLING_CONFIRMED:
                                return gettextCatalog.getString('Confirming ');
                            case NOTIFICATION_CONSTANT.TARGET.SPELLING_DICTIONARY:
                                return gettextCatalog.getString('Adding ');
                            case NOTIFICATION_CONSTANT.TARGET.EXPORT:
                                return gettextCatalog.getString('Export is in progress');
                        }
                }
            }

            return action;
        }
    }
})();
