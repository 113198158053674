(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageAccessibilityWcag20Table', {
        templateUrl: 'app/components/pagedetails/details/sections/accessibility/wcag2-0/table/table.html',
        controller: SectionAccessibilityTableController,
        controllerAs: 'vm',
        bindings: {
            issues: '<',
            iconColor: '@',
            header: '@',
            headerIcon: '@',
            selectCheck: '&',
            check: '<',
            tab: '<',
            active: '<',
            progress: '<',
        },
    });

    SectionAccessibilityTableController.$inject = ['$timeout', '$q'];
    function SectionAccessibilityTableController ($timeout, $q) {
        var vm = this;
        vm.$onInit = activate;
        vm.getChecksWithIssuesCount = getChecksWithIssuesCount;
        vm.getChecks = getChecks;

        function activate () {
            vm.issues = vm.issues || [];
            $q.when(vm.progress).finally(function () {
                $timeout(function () {
                    if (vm.active !== null) {
                        var check = vm.issues.find(function (check) {
                            return check.id === vm.active;
                        });
                        if (check) {
                            vm.selectCheck({ check: check });
                        }
                    }
                    vm.passedChecks = vm.issues.length - getChecksWithIssuesCount();
                    vm.errorChecks = getChecksWithIssuesCount();
                });
            });
        }

        function getChecksWithIssuesCount () {
            if (!Array.isArray(vm.issues)) {
                return 0;
            }

            return vm.issues.reduce(function (num, issue) {
                if (issue.errors_count > 0 && !issue.ignored && !issue.ignored_on_page) {
                    num++;
                }
                return num;
            }, 0);
        }

        function getChecks () {
            if (!Array.isArray(vm.issues)) {
                return 0;
            }
            return vm.issues.filter(function (data) {
                if (vm.tab === 'passed_checks') {
                    return data.errors_count === 0 && !data.ignored;
                } else if (vm.tab === 'ignored_issues') {
                    return data.ignored;
                } else {
                    return data.errors_count > 0;
                }
            });
        }
    }
})();
