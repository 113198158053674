(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsSummary', {
        templateUrl: 'app/pages/statistics/summary/summary.html',
        controller: StatisticsSummaryController,
        controllerAs: 'vm',
    });

    StatisticsSummaryController.$inject = ['ParamService', 'ng2SessionService', '$q', '$filter', 'moment', 'dateRangeService'];
    /* @ngInject */
    function StatisticsSummaryController (ParamService, ng2SessionService, $q, $filter, moment, dateRangeService) {
        var vm = this;
        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.date = dateRangeService.setRangeDates();
            updateDateRange();
        }

        function updateDateRange () {
            ParamService.setParams({
                from: moment(vm.date.startDate),
                to: moment(vm.date.endDate),
            });
        }
    }
})();
