(function () {
    'use strict';

    angular.module('modules.page-performance.pages.domain').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.page_performance',
                config: {
                    url: '/page-performance',
                    abstract: true,
                    template: '<div ui-view></div>',
                    // data: {
                    //     sidebar: "page-performance",
                    // },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
            {
                state: 'base.customer.domain.page_performance.pages',
                config: {
                    url: '/pages?showPage',
                    template: '<page-performance-pages></page-performance-pages>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Page Performance Pages') },
                },
            },
        ];
    }
    var hasAccess = [
        'ng2ActiveFeatureService',
        '$state',
        '$q',
        '$stateParams',
        'MON_MODULES',
        '$filter',
        'ng2SessionService',
        'ng2MonUIRouterTransitionService',
        function (
            ng2ActiveFeatureService,
            $state,
            $q,
            $stateParams,
            MON_MODULES,
            $filter,
            ng2SessionService,
            ng2MonUIRouterTransitionService,
        ) {
            if (
                ng2ActiveFeatureService.isFeatureActive('page_performance') &&
                $filter('hasAccess')(ng2SessionService.me, MON_MODULES.performance)
            ) {
                return $q.resolve(true);
            }

            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];
})();
