(function () {
    'use strict';
    angular.module('blocks.helpers').service('PolicyHelper', PolicyHelper);

    PolicyHelper.$inject = ['gettextCatalog', 'ng2SessionService', 'POLICY_FORM_HELPER_DB', 'Lodash'];

    /* @ngInject*/
    function PolicyHelper (gettextCatalog, ng2SessionService, POLICY_FORM_HELPER_DB, Lodash) {
        var tags = {
            accessibility: gettextCatalog.getString('Accessibility'),
            qa: gettextCatalog.getString('Quality Assurance'),
            seo: gettextCatalog.getString('SEO (Search Engine Optimization)'),
        };

        return {
            getTag: getTag,
            getType: getType,
            getTooltipMessageForType: getTooltipMessageForType,
            isMatchLinkable: isMatchLinkable,
            isMatchAnImage: isMatchAnImage,
            getTargetDomain: getTargetDomain,
        };

        function getTag (tag) {
            return tags[tag] || gettextCatalog.getString('NA');
        }

        function getType (policy) {
            var type = null;

            switch (policy.mode) {
                case 'violation':
                    type = 'unwanted';
                    break;
                case 'search':
                    type = 'search';
                    break;
                case 'required':
                    type = 'required';
                    break;
            }
            return type;
        }

        function getTooltipMessageForType (policy) {
            var message = null;
            switch (policy.mode) {
                case 'violation':
                    message = gettextCatalog.getString('Unwanted content');
                    break;
                case 'search':
                    message = gettextCatalog.getString('Matches');
                    break;
                case 'required':
                    message = gettextCatalog.getString('Required content');
                    break;
            }
            return message;
        }

        function getTargetDomain (targets) {
            var domain = ng2SessionService.domain;
            var domainGroup = ng2SessionService.domainGroup;

            if (domainGroup !== null) {
                var domainGroupTarget = Lodash.find(targets, { type: 'DomainGroup', id: domainGroup.id });
                if (domainGroupTarget) {
                    return domainGroupTarget;
                }
            }

            if (domain !== null) {
                return Lodash.find(targets, { type: 'Domain', id: domain.id });
            }

            return null;
        }

        function isMatchLinkable (type) {
            return POLICY_FORM_HELPER_DB.RULE_TYPE_LINKS.indexOf(type) !== -1;
        }

        function isMatchAnImage (type) {
            return POLICY_FORM_HELPER_DB.RULE_TYPE_IMAGES.indexOf(type) !== -1;
        }
    }
})();
