(function () {
    'use strict';
    angular.module('pages.qa.broken').component('linkContent', {
        templateUrl: 'app/pages/qa/broken-links/links/link-content/link-content.html',
        controller: LinkContentController,
        controllerAs: 'vm',
        bindings: {
            link: '<',
            type: '<?',
        },
    });

    LinkContentController.$inject = [
        'ng2LinkPagesRepoService',
        'gettextCatalog',
        '$controller',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2CheckForDuplicatesService',
        'ng2ActiveFeatureService',
    ];

    function LinkContentController (
        ng2LinkPagesRepoService,
        gettextCatalog,
        $controller,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2CheckForDuplicatesService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;

        vm.onFiltersChanged = onFiltersChanged;
        vm.onSearchChanged = onSearchChanged;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );

            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.pages = [];
            vm.tabs = [];
            vm.type = vm.type || 'html';
            vm.search = null;
            vm.isFiltersOpen = false;
            vm.currentSort = { by: 'title', direction: 'desc' };
            vm.extensionType = '';
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            if (vm.type === 'document' && vm.link.type !== 'image') {
                vm.header = gettextCatalog.getString('Documents with Broken Link');
            } else if (vm.type === 'document' && vm.link.type === 'image') {
                vm.header = gettextCatalog.getString('Documents with Broken Image');
            } else if (vm.link.type !== 'image') {
                vm.header = gettextCatalog.getString('Content with Broken Link');
            } else {
                vm.header = gettextCatalog.getString('Content with Broken Image');
            }
            if (vm.link.type !== 'image') {
                vm.extensionType = 'blnk';
            } else {
                vm.extensionType = 'bimg';
            }
            vm.subTarget = vm.link.type !== 'image' ? 'brokenlinks' : 'brokenimages';
            getPage();
            vm.subheader = '<a ng-href="' + vm.link.url + '" target="_blank" aria-describedby="linkOpensInNewTab">' + vm.link.url + '</a>';
            vm.getLabels();

            vm.exports = [
                {
                    data: {
                        category: 'pages_with_specific_link',
                        category_ref: vm.link.id,
                    },
                },
            ];
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                link_id: vm.link.id,
                'types[]': ['html'],
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.type === 'document') {
                params['types[]'] = ['document', 'pdf'];
            }

            if (vm.type === 'all') {
                params['types[]'] = undefined;
            }

            if (vm.search && vm.search !== '') {
                params.search = vm.search;
            }

            params = vm.updateParamsWithFilters(params);

            vm.progress = ng2LinkPagesRepoService.get(params).then(function (data) {
                ng2CheckForDuplicatesService.checkForDuplicatePages(data);
                vm.pages = data;
            }, angular.noop);
        }

        function onFiltersChanged (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }

        function onSearchChanged (search) {
            vm.search = search;
            vm.getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
