(function () {
    'use strict';

    angular.module('modules.data_privacy.components').component('pageDetailsSectionDataPrivacy', {
        templateUrl: 'app/modules/data-privacy/components/pagedetails/data-privacy.html',
        controller: SectionInventoryController,
        controllerAs: 'vm',
        bindings: {
            pageObj: '<',
            active: '<', // subTarget attribute from page-details button
            refreshPage: '&',
        },
    });

    SectionInventoryController.$inject = [
        'DataPrivacyViolationRepo',
        'gettextCatalog',
        '$deepmerge',
        'DataPrivacyService',
        'ng2HelpCenterRepoService',
        '$controller',
        'ng2DataPrivacyRulesetRepo',
        '$q',
        '$filter',
        'ng2SessionService',
        'ng2DirectNavigationService',
        'ng2MonEventsService',
        'MON_EVENTS',
        'ng2MonModalService',
    ];
    /* @ngInject */
    function SectionInventoryController (
        DataPrivacyViolationRepo,
        gettextCatalog,
        $deepmerge,
        DataPrivacyService,
        ng2HelpCenterRepoService,
        $controller,
        ng2DataPrivacyRulesetRepo,
        $q,
        $filter,
        ng2SessionService,
        ng2DirectNavigationService,
        ng2MonEventsService,
        MON_EVENTS,
        ng2MonModalService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.setSelectedInfoType = setSelectedInfoType;
        vm.update = update;
        vm.getPage = getPage;
        vm.formatText = DataPrivacyService.formatText;
        vm.openIssueModal = openIssueModal;
        vm.update = update;
        vm.$onDestroy = onDestroy;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.isDemo = $filter('monCustomerHasPlan')('demo');
            vm.showDataProtectionIssues = ng2SessionService.domain.features.data_protection;
            vm.tabs = [
                {
                    name: gettextCatalog.getString('All'),
                    value: 'all',
                },
                {
                    name: gettextCatalog.getString('High'),
                    value: 'high',
                },
                {
                    name: gettextCatalog.getString('Medium'),
                    value: 'medium',
                },
                {
                    name: gettextCatalog.getString('Low'),
                    value: 'low',
                },
                {
                    name: gettextCatalog.getString('Ignored'),
                    value: 'ignored',
                },
                {
                    name: gettextCatalog.getString('Fixed'),
                    value: 'fixed',
                },
            ];
            vm.detailTabs = [
                {
                    name: gettextCatalog.getString('Information'),
                    value: 'info',
                },
                {
                    name: gettextCatalog.getString('Quick help'),
                    value: 'help',
                },
            ];
            vm.currentSort = { by: 'identifier', direction: 'asc' };
            vm.activeDetailTab = vm.detailTabs[0].value;
            if (vm.active) {
                vm.activeTab = vm.active.priority;
            } else {
                vm.activeTab = 'all';
            }
            vm.active = selectDataPrivacySection();
            vm.complianceData = [0, 100];
            vm.description = '';
            vm.quickHelpLoading = false;
            vm.violations = [];
            vm.infoTypes = [];
            vm.selectedInfoType = null;

            vm.reloadDataPrivacyEventId = ng2MonEventsService.addListener(MON_EVENTS.RELOAD_DATA_PRIVACY, function () {
                vm.getPage();
            });

            getPage();
        }

        function selectDataPrivacySection () {
            var dataPrivacySections = ['data-issues', 'cookie-acceptance-rate'];
            var index = dataPrivacySections.indexOf(vm.active);
            if (vm.isDemo && !vm.showDataProtectionIssues) {
                return 'cookie-acceptance-rate';
            }
            if (index === -1) {
                return 'data-issues';
            }
            return dataPrivacySections[index];
        }

        function getPage () {
            var getPageParams = {
                page: vm.page,
                page_size: 0,
                sort_dir: vm.currentSort.direction,
                sort_by: vm.currentSort.by,
                status: vm.activeTab,
            };
            if (vm.activeTab !== 'ignored' && vm.activeTab !== 'fixed') {
                if (vm.activeTab !== 'all') {
                    getPageParams.priority = vm.activeTab;
                }
                getPageParams.status = 'active';
            } else {
                getPageParams.status = vm.activeTab;
            }
            vm.quickHelpLoading = true;
            vm.progress = ng2DataPrivacyRulesetRepo.getAllForPage(vm.pageObj.id, getPageParams).then(function (infoTypes) {
                vm.infoTypes = infoTypes;
                vm.complianceData = [
                    vm.pageObj.data_protection_violations_counts.violations_count -
                        vm.pageObj.data_protection_violations_counts.active_violations,
                    vm.pageObj.data_protection_violations_counts.active_violations,
                ];

                if (vm.infoTypes.length > 0) {
                    setSelectedInfoType(vm.infoTypes[0]);
                }
            }, angular.noop);
        }

        function setSelectedInfoType (violation) {
            /**
             * #setActive
             * @description Shows the selected violation and gets the help center's description of the infotype
             * @helpCenterParams Consists of the violation's identifier
             * @violationParams The violation's id and status which is set from the page's activeTab via #getPage() above.
             */
            vm.selectedInfoType = violation;
            if (violation) {
                vm.quickHelpLoading = true;
                vm.description = '';
                var helpCenterParams = {
                    identifier: violation.identifier,
                };
                var violationParams = {
                    info_type: violation.id,
                    status: vm.activeTab,
                };
                if (vm.activeTab === 'ignored' || vm.activeTab === 'fixed') {
                    violationParams.status = vm.activeTab;
                } else {
                    violationParams.status = 'active';
                }
                var promises = [
                    ng2HelpCenterRepoService.get(helpCenterParams),
                    DataPrivacyViolationRepo.getAll(vm.pageObj.id, violationParams),
                ];

                $q.all(promises)
                    .then(function (data) {
                        if (data[0].length > 0) {
                            vm.description = data[0][0].description;
                        }
                        vm.violations = data[1];
                        updateDropdowns();
                    }, angular.noop)
                    .finally(function () {
                        vm.quickHelpLoading = false;
                    });
            }
        }

        async function update (status, violation, confirmMessage) {
            try {
                await ng2MonModalService.confirm(confirmMessage);
            } catch (e) {
                return;
            }

            setSelectedInfoType(null);
            const violationToUpdate = $deepmerge({}, violation);
            violationToUpdate.status = status;
            vm.active = selectDataPrivacySection();

            try {
                vm.progress = DataPrivacyViolationRepo.updatePageViolation(vm.pageObj.id, violationToUpdate);
                await vm.progress;
            } catch (e) {
                return;
            }

            getPage();
            vm.refreshPage({ target: 'page-details-section-data-privacy' });
        }

        function openIssueModal (violation) {

            ng2DirectNavigationService.navigateToIssueOverlay({
                issueOverlayIssueId: violation.id,
                issueOverlayContentId: violation.data_protection_content_id,
                issueOverlayIssueType: 'dataPrivacy',
                issueOverlayPageId: vm.pageObj.id,
            });

        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.reloadDataPrivacyEventId);
        }
    }
})();
