import { PageFixModelHelper } from '../../services/model-helper/page-fix-model-helper';

(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormNewWindow', {
        templateUrl: 'app/modules/page-fix/forms/new-window/new-window.html',
        controller: PageFixFormNewWindowController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    PageFixFormNewWindowController.$inject = [
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        'ng2MonEventsService',
        'PAGE_FIX_EVENT',
        'PAGE_FIX_FORM_VIEW_NAME',
        'PAGE_FIX_FORM_VIEW_TYPE',
    ];
    /* @ngInject */
    function PageFixFormNewWindowController (
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        ng2MonEventsService,
        PAGE_FIX_EVENT,
        PAGE_FIX_FORM_VIEW_NAME,
        PAGE_FIX_FORM_VIEW_TYPE,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.closeModal = closeModal;

        function activate () {
            vm.goToViewId = 0;
            vm.updatePageFixId = 0;
            vm.pageFix = vm.resolve.pageFix;
            vm.pageFixId = 18;
            vm.pageFixSelector = JSON.stringify([
                PageFixModelHelper.getSelectorValue(PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[vm.pageFixId].snippet),
            ]);
            vm.infoViewType = PAGE_FIX_FORM_VIEW_TYPE.INFO;
            vm.optionsViewType = PAGE_FIX_FORM_VIEW_TYPE.OPTIONS;
            vm.testingViewType = PAGE_FIX_FORM_VIEW_TYPE.TESTING;

            setupListeners();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.goToViewId);
            ng2MonEventsService.remove(vm.updatePageFixId);
        }

        function setupListeners () {
            vm.goToViewId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.FORM_REQUEST_GO_TO_VIEW, function () {
                setView(PAGE_FIX_FORM_VIEW_NAME.OPTIONS);
            });

            vm.updatePageFixId = ng2MonEventsService.addListener(PAGE_FIX_EVENT.UPDATE_PAGEIFX, function (pageFix) {
                vm.pageFix = pageFix;
            });
        }

        function setView (viewName) {
            ng2MonEventsService.run(PAGE_FIX_EVENT.FORM_UPDATE_VIEW, viewName);
        }

        function closeModal (pageFix) {
            vm.close({ $value: pageFix });
        }
    }
})();
