(function () {
    'use strict';

    angular.module('pages.base.terms').run(appRun);

    appRun.$inject = ['routerHelper'];

    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'base.terms',
                config: {
                    url: '/terms',
                    abstract: true,
                    template: '<div ui-view></div>',
                },
            },
            {
                state: 'base.terms.closed-caption',
                config: {
                    url: '/closed-caption',
                    abstract: true,
                    template: '<div ui-view></div>',
                },
            },
            {
                state: 'base.terms.closed-caption.index',
                config: {
                    url: '',
                    template: '<base-terms-closed-caption></base-terms-closed-caption>',
                },
            },
        ];
    }
})();
