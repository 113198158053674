(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogRuleMetaHeaderLength', {
        templateUrl: 'app/forms/global-policy/dialogs/rule/blocks/meta-header-length/meta-header-length.html',
        controller: GlobalPolicyMetaHeaderLengthRuleController,
        controllerAs: 'vm',
        bindings: {
            cRule: '=',
            monDialogId: '<',
            policyRuleForm: '=',
        },
    });

    GlobalPolicyMetaHeaderLengthRuleController.$inject = ['PolicyFormHelper', 'monDialog'];
    /* @ngInject */
    function GlobalPolicyMetaHeaderLengthRuleController (PolicyFormHelper, monDialog) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;
        vm.chooseType = chooseOperator;
        vm.checkSelectedSubEntries = checkSelectedSubEntries;

        function activate () {
            PolicyFormHelper.setRuleType(vm.cRule.type);
            setupTypeOptions();
            vm.cRule.query = parseInt(vm.cRule.query);
            if (!vm.cRule.name) {
                chooseOperator(vm.typeOptions.length > 0 ? vm.typeOptions[0] : {});
            } else {
                chooseOperator(
                    vm.typeOptions.find(function (type) {
                        return type.type === vm.cRule.operator;
                    }),
                );
            }
        }

        function setupTypeOptions () {
            vm.typeOptions = PolicyFormHelper.getOptionsByRuleType();
            vm.dropdownOptions = vm.typeOptions.map( option => {
                return {
                    ...option,
                    action: () => vm.chooseType(option),
                    label: option.translation,
                };
            });
        }

        function chooseOperator (option) {
            vm.selectedOption = option;
            vm.cRule.operator = option.type;
        }

        function checkSelectedSubEntries () {
            vm.areAllSubEntriesSelected =
                vm.subEntry.options.length > 0 && vm.subEntry.options.length === vm.cRule.sub_entry.length;
        }

        function close () {
            var newRule = angular.copy(vm.cRule);
            newRule.query = newRule.query === '' ? 0 : newRule.query;
            if (vm.policyRuleForm) {
                vm.policyRuleForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, newRule);
        }
    }
})();
