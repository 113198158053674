<div class="mon-show-more-avatars" *ngIf="users && users.length > 0">
        <div *ngFor="let user of uniqueUsers; let i = index"
             tabindex="0"
             [attr.aria-label]="user | getUserName"
             class="user-avatar"
             [ngbTooltip]="user | getUserName"
             placement="top"
             container="body"
             [class]="{'min-element': i >= minElements && !expanded}">
            <ngx-avatar [name]="user | getUserName"
                        size="20"
                        [textSizeRatio]="2"
            >
            </ngx-avatar>
        </div>
        <button
            class="show-button"
            (click)="toggleExpanded()"
            *ngIf="showButton">
            {{expanded ? 'Less' : 'More' | translate}}
        </button>
</div>
