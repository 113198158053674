import { StatisticsPage } from '@client/app/modules/statistics/interfaces/statistics-vistor.interface';

export class StatisticsEntryPageModel implements StatisticsPage {

    title: string;
    date?: string | null;
    visits: string | null;
    url: string | null;
    visitors: string | null;
    uniq_visitors: string | null;
    label: string | null;
    bounce_rate: string | null;
    bounces: string | null;
    actions: string | null;
    avg_time: string | null;
    pageviews: string | null;
    time?: string | null;
    entry_visits?: string | null;
    exit_visits?: string | null;
    exit_rate?: string | null;

    constructor (page: StatisticsPage) {
        this.title = page.title;
        this.date = page.date;
        this.visits = page.visits;
        this.url = page.url;
        this.visitors = page.visitors;
        this.uniq_visitors = page.uniq_visitors;
        this.label = page.label;
        this.bounce_rate = page.bounce_rate;
        this.bounces = page.bounces;
        this.actions = page.actions;
        this.avg_time = page.avg_time;
        this.pageviews = page.pageviews;
        this.time = page.time;
        this.entry_visits = page.entry_visits;
        this.exit_visits = page.exit_visits;
        this.exit_rate = page.exit_rate;
    }

    getBounceRateFloat (): number | null {
        if (!this.bounce_rate) {
            return null;
        }
        return parseFloat(this.bounce_rate) / 100;
    }
}
