(function () {
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixSummary', {
        templateUrl: 'app/modules/page-fix/pages/summary/summary.html',
        controller: AutofixSummaryController,
        controllerAs: 'vm',
    });

    AutofixSummaryController.$inject = ['ng2PageFixRepoService', 'PAGE_FIX_FIXABLE_TYPES', '$q'];
    /* @ngInject */
    function AutofixSummaryController (ng2PageFixRepoService, PAGE_FIX_FIXABLE_TYPES, $q) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.totalFixes = 0;
            vm.spellingFixes = 0;
            vm.linkFixes = 0;
            vm.accessibilitySourceCodeFixes = 0;
            vm.accessibilityCheckFixes = 0;
            vm.loading = true;
            $q.all([
                getPageFixes(PAGE_FIX_FIXABLE_TYPES.misspelling),
                getPageFixes(PAGE_FIX_FIXABLE_TYPES.link),
                getPageFixes(PAGE_FIX_FIXABLE_TYPES.accessibility_source_code),
                getPageFixes(PAGE_FIX_FIXABLE_TYPES.accessibility_check),
                getPageFixes(PAGE_FIX_FIXABLE_TYPES.custom, 1, null, null),
                getPageFixes(null, 3, 'updated_at', 'desc'),
            ])
                .then(function (values) {
                    vm.spellingFixes = parseInt(values[0].total, 10);
                    vm.linkFixes = parseInt(values[1].total, 10);
                    vm.accessibilitySourceCodeFixes = parseInt(values[2].total, 10);
                    vm.accessibilityCheckFixes = parseInt(values[3].total, 10);
                    vm.customFixes = parseInt(values[4].total, 10);
                    vm.latestPageFixes = values[5];
                    vm.totalFixes =
                        vm.spellingFixes +
                        vm.linkFixes +
                        vm.accessibilityCheckFixes +
                        vm.accessibilitySourceCodeFixes +
                        vm.customFixes;
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getPageFixes (type, pageSize, sortBy, sortDir) {
            var params = {
                page: 1,
                page_size: pageSize || 1,
                fixable_type: type,
                sort_by: sortBy,
                sort_dir: sortDir,
            };

            return ng2PageFixRepoService.getAll(params).then(
                function (data) {
                    return data;
                },
                function () {
                    var res = [];
                    res.total = 0;
                    return res;
                },
            );
        }
    }
})();
