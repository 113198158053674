(function () {
    'use strict';
    /**
     * @memberof modules.support.services.entities
     * @ngdoc factory
     * @name SupportAccessibilityCheckService
     * @description Service to handle Support Center Accessibility Check requests
     */
    angular
        .module('modules.support.services.entities')
        .factory('SupportAccessibilityCheckService', SupportAccessibilityCheckService);

    SupportAccessibilityCheckService.$inject = ['ng2SupportAccessibilityChecksRepoService'];
    /* @ngInject*/
    function SupportAccessibilityCheckService (ng2SupportAccessibilityChecksRepoService) {
        return {
            get: get,
            getAll: getAll,
            getDecisionTree: getDecisionTree,
        };

        /**
         * @memberOf SupportAccessibilityCheckService
         * @desc Get article
         * @param {Number} id - Id of the Support Center Accessibility Check ID, not to be confused with the actual Accessibility Check ID
         * @param {Object} params - Query Params
         * @returns {Promise} Promise containing the article
         */
        function get (id, params) {
            return ng2SupportAccessibilityChecksRepoService.get(id, params);
        }

        /**
         * @memberOf SupportAccessibilityCheckService
         * @desc Get a Support Center Accessibility Check
         * @param {Object} params - Query Params
         */
        function getAll (params) {
            return ng2SupportAccessibilityChecksRepoService.getAll(params);
        }

        /**
         * @memberOf SupportAccessibilityCheckService
         * @desc Get the Decision Tree Collection related to the specified Accessibility Check
         * @param check - Accessibility Check
         */
        function getDecisionTree (check) {
            return ng2SupportAccessibilityChecksRepoService.getDecisionTree(check);
        }
    }
})();
