import { Component, ElementRef, EventEmitter, Inject, Input, Output, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActionMenuItemType, NavigationKeysEnum } from './action-menu-panel.type';

const navigationKeys = [
    NavigationKeysEnum.Escape,
    NavigationKeysEnum.Tab,
    NavigationKeysEnum.ArrowUp,
    NavigationKeysEnum.ArrowDown,
];

@Component({
    selector: 'mon-action-menu-panel',
    templateUrl: './action-menu-panel.component.html',
    styleUrls: ['./action-menu-panel.component.scss'],
})
class ActionMenuPanelComponent {
    @Input() monActionPanelItems: ActionMenuItemType[] = [];
    @Input() monId?: string;
    @Input() monLabelledBy?: string;
    @Input() monProjectedTemplate?: TemplateRef<unknown>;
    @Output() show: EventEmitter<void> = new EventEmitter();
    @Output() hide: EventEmitter<void> = new EventEmitter();

    public isOpen = false;

    private documentClickListener: () => void;
    private documentKeydownListener: (event: KeyboardEvent) => void;

    constructor (
        private element: ElementRef,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.documentClickListener = (): void => {
            if (this.isOpen) {
                this.close();
            }
        };

        this.documentKeydownListener = (event: KeyboardEvent): void => {
            if (this.isOpen && navigationKeys.includes(event.key as NavigationKeysEnum)) {
                event.stopImmediatePropagation();
                event.preventDefault();
                switch (event.key) {
                    case NavigationKeysEnum.Escape:
                    case NavigationKeysEnum.Tab:
                        this.close();
                        break;
                    case NavigationKeysEnum.ArrowUp:
                        this.focusDecrease();
                        break;
                    case NavigationKeysEnum.ArrowDown:
                        this.focusIncrease();
                        break;
                }
            }
        };
    }

    onActionPress (action?: () => unknown): void {
        if (typeof action === 'function') {
            action();
        }
    }

    toggle (): void {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    setFocus (): void {
        const firstItem = this.element.nativeElement.querySelector('[role="menuitem"]');
        firstItem?.focus();
    }

    private open (): void {
        this.isOpen = true;
        this.show.emit();
        setTimeout(() => {
            this.removeListeners();
            this.setListeners();
        });
    }

    private close (): void {
        this.isOpen = false;
        this.removeListeners();
        this.hide.emit();
    }

    private focusChange (step: number): void {
        const items: Array<HTMLInputElement> = Array.from(this.element.nativeElement.querySelectorAll('[role="menuitem"]'));

        if (items.length) {
            const currentElement = this.getFocusedItem();
            if (!currentElement) {
                items[0].focus();
            } else {
                const index = items.indexOf(currentElement);
                items[index + step]?.focus();
            }
        }
    }

    private focusIncrease (): void {
        this.focusChange(1);
    }
    private focusDecrease (): void {
        this.focusChange(-1);
    }

    private getFocusedItem (): HTMLInputElement | undefined {
        return this.element.nativeElement.querySelector(':focus');
    }

    private setListeners (): void {
        this.document.addEventListener('click', this.documentClickListener);
        this.document.addEventListener('keydown', this.documentKeydownListener);
    }

    private removeListeners (): void {
        this.document.removeEventListener('click', this.documentClickListener);
        this.document.removeEventListener('keydown', this.documentKeydownListener);
    }

}

export { ActionMenuPanelComponent as MonActionMenuPanelComponent };
