(function () {
    'use strict';

    angular.module('pages.qa.broken').run(appRun);

    appRun.$inject = ['routerHelper', 'RouteHelperService'];
    /* @ngInject */
    function appRun (routerHelper, RouteHelperService) {
        routerHelper.configureStates(getStates());
        function getStates () {
            return [
                RouteHelperService.makeRouteWithHasAccess({
                    name1: 'domain',
                    name2: 'qa.broken',
                    url: '/broken',
                    featureName: 'qa_links',
                    redirectURI: 'base.customer.dashboard',
                    abstract: true,
                    sidebar: 'quality',
                }),
                ...['links', 'images', 'pages'].map((type) => RouteHelperService.makeRoute({
                    name1: 'domain',
                    name2: `qa.broken.${type}`,
                    template: `<broken-${type}-index></broken-${type}-index>`,
                    url: `/${type}`,
                })),
            ];
        }
    }
})();
