(function () {
    'use strict';

    angular.module('pages.admin.shortcuts').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.admin.shortcuts',
                config: {
                    url: '/shortcuts',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Acquia Optimize - Shortcuts') },
                    resolve: {
                        validateAccess: validateAccess,
                    },
                },
            },
            {
                state: 'base.customer.admin.shortcuts.index',
                config: {
                    url: '',
                    template: '<admin-shortcuts></admin-shortcuts>',
                    data: {},
                },
            },
        ];
    }

    var validateAccess = [
        '$q',
        'ng2ActiveFeatureService',
        '$state',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function ($q, ng2ActiveFeatureService, $state, $stateParams, ng2MonUIRouterTransitionService) {
            // Check if plan allows access to shortcuts
            var deferred = $q.defer();
            if (ng2ActiveFeatureService.isFeatureActivePure('general_shortcuts')) {
                deferred.resolve(true);
            } else {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.admin.domains.index', $stateParams),
                );
            }
            return deferred.promise;
        },
    ];
})();
