(function () {
    'use strict';

    angular.module('modules.page-performance').component('formPagePerformanceConfirmArchive', {
        templateUrl: 'app/modules/page-performance/forms/confirm-archive/confirm-archive.html',
        controller: FormPagePerformancePagesController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    FormPagePerformancePagesController.$inject = [
        'PagePerformanceAssignedProfile',
        'PagePerformanceProfile',
        '$controller',
        'PagePerformanceProfileObsService',
    ];

    /* @ngInject */
    function FormPagePerformancePagesController (
        PagePerformanceAssignedProfile,
        PagePerformanceProfile,
        $controller,
        PagePerformanceProfileObsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.cancel = cancel;
        vm.closeModal = closeModal;
        vm.archive = archive;
        vm.hasLastProfile = hasLastProfile;
        vm.showTable = showTable;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.profile = vm.resolve.profile;
            vm.assignedProfiles = [];
            vm.promise = null;
            vm.loading = false;
            vm.saving = false;
            getPage();
        }

        function getPage () {
            var params = {
                profile_id: vm.profile.id,
                page: vm.page,
                page_size: vm.pageSize,
            };

            vm.loading = true;
            vm.promise = PagePerformanceAssignedProfile.getAll(params)
                .then(function (assignedProfiles) {
                    vm.assignedProfiles = assignedProfiles;
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function hasLastProfile (assignedProfile) {
            return assignedProfile.profile_ids.length <= 1;
        }

        function showTable () {
            return vm.loading || (vm.assignedProfiles && vm.assignedProfiles.length > 0);
        }

        function archive () {
            vm.profile.archive = true;
            vm.saving = true;
            PagePerformanceProfile.archive(vm.profile)
                .then(function () {
                    PagePerformanceProfileObsService.removeProfile(vm.profile);
                    closeModal();
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }

        function cancel () {
            vm.dismiss();
        }

        function closeModal () {
            vm.close({ $value: vm.profile });
        }
    }
})();
