(function () {
    'use strict';

    angular.module('directives.monsido').directive('monEventTrigger', monEventTrigger);

    monEventTrigger.$inject = ['$compile', '$parse', '$interpolate', '$rootScope', 'ng2MonEventsService'];

    /* @ngInject */
    function monEventTrigger ($compile, $parse, $interpolate, $rootScope, monEventsService) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var monEventTrigger = attrs.monEventTrigger || '';
                var monEventParams = null;
                if (attrs.monEventParams) {
                    monEventParams = $parse(attrs.monEventParams);
                }
                if (element.is('input') || element.is('form-field-input')) {
                    $(element).on('change', function (e) {
                        if (monEventParams) {
                            var params = monEventParams(scope);
                            params.change = e.target.value;
                            monEventsService.run(monEventTrigger, params);
                        } else {
                            monEventsService.run(monEventTrigger);
                        }
                    });
                } else {
                    $(element).on('click', function (e) {
                        if (monEventParams) {
                            monEventsService.run(monEventTrigger, monEventParams(scope));
                        } else {
                            monEventsService.run(monEventTrigger);
                        }
                    });
                }
            },
        };
    }
})();
