import { PAGE_FIX_FIXABLE_TYPES } from '@client/app/modules/page-fix/constants/page-fix-fixable-types';
import { PageFixType } from './page-fix-repo.service';


// eslint-disable-next-line
export interface PageFixModel extends PageFixType {}

export class PageFixModel {
    constructor (attributes: PageFixType) {
        Object.assign(this, attributes);
        this.custom_selector = this.getSelectorValue();
    }

    normalizeFixableType (): string {
        switch (this.fixable_type) {
            case 'SpellingError':
                return PAGE_FIX_FIXABLE_TYPES.misspelling;
            case 'AccessibilitySourceCode':
                return PAGE_FIX_FIXABLE_TYPES.accessibility_source_code;
            case 'AccessibilityCheck':
                return PAGE_FIX_FIXABLE_TYPES.accessibility_check;
            case 'Link':
                return PAGE_FIX_FIXABLE_TYPES.link;
            default:
                return PAGE_FIX_FIXABLE_TYPES.custom;
        }
    }

    private getSelectorValue (): string | null {
        if (this.selector) {
            if (this.normalizeFixableType() === PAGE_FIX_FIXABLE_TYPES.link) {
                const match = this.selector.match(';(.*)');
                try {
                    return match ? JSON.parse( match[1]) : null;
                } catch (error) {
                    return null;
                }
            } else {
                const match = this.selector.match(';(.*)');
                return match ? match[1] : null;
            }
        }
        return '';
    }

}
