import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { IssueStatusEnum } from 'app/modules/common/enum/issue-status.enum';
import { RollbarErrorHandlerService } from 'ng2/external/rollbar/rollbar-error-handler.service';
import { QaDocumentInterface } from '../interfaces/qa-document.interface';
import { QaPageInterface } from '../interfaces/qa-page.interface';
import { QaMisspellingInterface } from '../interfaces/qa-misspelling.interface';
import { GetPagesParamsInterface } from './qa-issue-repo.interface';
import { QaPageLinkRequestBody } from '../interfaces/qa-broken-snippet.interface';
import { stringifyRequestParams } from 'ng2/shared/utils';
import { BaseRepo } from '@client/app/services/api/base-repo/base-repo';
import { LinkType, PageLinkType } from '@client/app/models/pages/page-link.model';


@Injectable()
export class QaIssueRepoService extends BaseRepo {

    constructor (private client: ApiClient, protected sessionService: SessionService, private rollbar: RollbarErrorHandlerService) {
        super();
    }

    getIssueContent (issueId: number): Promise<LinkType | void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve();
        }

        return this.client.getPromise(`domains/${domainId}/links/${issueId}`);
    }

    getPageIssueContent (pageId: number, pageLinkId: number): Promise<PageLinkType | void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(`domains/${domainId}/pages/${pageId}/page-links/${pageLinkId}`);
    }

    getIssuePages (issueId: number, params?: GetPagesParamsInterface): Promise<Array<QaPageInterface> | void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(`domains/${domainId}/links/${issueId}/pages`, { params: {
            ...stringifyRequestParams(params as unknown as Record<string, unknown>),
            link_id: issueId.toString(),
            type: 'html',
        } });
    }

    getIssueDocuments (issueId: number, params?: GetPagesParamsInterface): Promise<Array<QaDocumentInterface> | void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(`domains/${domainId}/links/${issueId}/pages`, { params: {
            ...stringifyRequestParams(params as unknown as Record<string, unknown>),
            link_id: issueId.toString(),
            'types[]': ['document', 'pdf'],
        } });
    }

    getMisspellingIssueContent (pageId: number, issueId: number): Promise<QaMisspellingInterface | void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(`domains/${domainId}/pages/${pageId}/spelling_errors/${issueId}`);
    }

    getMisspellingIssuePages (issueId: number, params?: GetPagesParamsInterface): Promise<Array<QaPageInterface> | void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.getPromise(`domains/${domainId}/spelling/errors/${issueId}/pages`, {
            params: {
                ...stringifyRequestParams(params as unknown as Record<string, unknown>),
                id: issueId.toString(),
                type: 'html',
            } });
    }

    addWordToDictionary (params: Record<string, unknown>): Promise<void> {
        const domainId = this.getDomainId();
        params = params || {};

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.postPromise(`domains/${domainId}/spelling/dictionary`, params);
    }

    destroySpellingError (pageId: number, issueId: number): Promise<void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }

        return this.client.deletePromise(`domains/${domainId}/pages/${pageId}/spelling_errors/${issueId}`);
    }

    ignoreSpellingError (pageId: number, issueId: number): Promise<void> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('ignoreSpellingError - No domain id was found!');
            return Promise.resolve(undefined);
        }
        return this.client.postPromise(`domains/${domainId}/pages/${pageId}/spelling_errors/${issueId}/ignore`, {});
    }

    confirmWord (wordId: number, params?): Promise<unknown> {
        const domainId = this.getDomainId();

        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve(undefined);
        }
        params = params || {};
        if (!params.group_id) {
            params.group_id = this.sessionService.domainGroup ? this.sessionService.domainGroup.id : null;
        }
        return this.client.patchPromise(`domains/${domainId}/spelling/errors/${wordId}`, params);
    }

    markAsFixed (pageId: number, pageLinkId: number): Promise<PageLinkType | void> {
        return this.updatePageIssueStatus(pageId, pageLinkId, { status: IssueStatusEnum.fixed });
    }

    markAsIgnored (pageId: number, pageLinkId: number): Promise<PageLinkType | void> {
        return this.updatePageIssueStatus(pageId, pageLinkId, { status: IssueStatusEnum.ignored });
    }

    markAsDead (pageId: number, pageLinkId: number): Promise<PageLinkType | void> {
        return this.updatePageIssueStatus(pageId, pageLinkId, { status: IssueStatusEnum.dead });
    }

    private updatePageIssueStatus (
        pageId: number,
        pageLinkId: number,
        body: QaPageLinkRequestBody,
    ): Promise<PageLinkType | void> {
        const domainId = this.getDomainId();
        if (!domainId) {
            this.rollbar.warn('No domain id was found!');
            return Promise.resolve();
        }
        const params = this.setGroupId();
        return this.client.patchPromise( `domains/${domainId}/pages/${pageId}/page-links/${pageLinkId}`, body, { params });
    }
}
