(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuPagePerformance', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', '$filter'];
    function SideMenuController (gettextCatalog, $filter) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Pages'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'PAGE_PERFORMANCE', 'PAGES']),
                    href: 'base.customer.domain.page_performance.pages',
                },
            ];
        }
    }
})();
