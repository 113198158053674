import { Injectable } from '@angular/core';
import { BaseRepo, BaseRepoRequestType } from '@client/app/services/api/base-repo/base-repo';
import { ApiService } from '@client/app/services/api/api-service/api.service';
import { SessionService } from '@monsido/core/session/session.service';
import { RequestParamsType } from '@monsido/http/options';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { Page } from '@monsido/modules/page-details/models';

export type BrokenPageRepoGetParams = RequestParamsType & BaseRepoRequestType & {
    page?: number,
    page_size?: number,
    search?: string,
    sort_by?: string,
    sort_dir?: string
    'types[]'?: string[]
};

@Injectable({
    providedIn: 'root',
})
export class BrokenPageRepoService extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    get (params: BrokenPageRepoGetParams = {}): Promise<MonTableCollection<Page>> {
        if (params.search) {
            params.search = `%${params.search}%`;
        }
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/broken/pages`, params);
    }
}
