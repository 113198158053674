(function () {
    'use strict';
    angular.module('app.components').component('language', {
        transclude: true,
        template: '{{ vm.translation }}',
        controller: LanguageController,
        controllerAs: 'vm',
        bindings: {
            language: '@',
        },
    });

    LanguageController.$inject = ['$filter'];

    function LanguageController ($filter) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.translation = $filter('translateLanguage')(vm.language);
        }
    }
})();
