export const PAGE_ASSIST_SCRIPT_CONSTANT = {
    DIRECTION: {
        TOPLEFT: 'lefttop',
        TOPRIGHT: 'righttop',
        LEFT: 'left',
        RIGHT: 'right',
        TOPCENTER: 'centertop',
        BOTTOMCENTER: 'centerbottom',
        BOTTOMLEFT: 'leftbottom',
        BOTTOMRIGHT: 'rightbottom',
    },
    THEME: {
        LIGHT: 'light',
        DARK: 'dark',
    },
    ICON_SHAPE: {
        CIRCLE: 'circle',
        ROUNDED_BOX: 'rounded_box',
    },
    LANGUAGE: {
        ENUS: 'en-US',
        ESMX: 'es-MX',
        DADK: 'da-DK',
        DEDE: 'de-DE',
        PTPT: 'pt-PT',
        ZHCN: 'zh-CN',
        RURU: 'ru-RU',
        SVSE: 'sv-SE',
        NBNO: 'nb-NO',
        NNNO: 'nn-NO',
        ETEE: 'et-EE',
        FRFR: 'fr-FR',
        NLNL: 'nl-NL',
    },
    ICON: {
        DEFAULT: 'logo',
        WHEELCHAIR: 'wheelchair',
        HEART: 'heart',
        BRUSH: 'brush',
    },
    COLOR: {
        LIGHT: '#783CE2',
        DARK: '#052942',
    },
    TEXT_COLOR: '#ffffff',
    BUTTON_HOVER_COLOR: {
        LIGHT: '#783CE2',
        DARK: '#FFCF4B',
    },
    LINK: {
        LIGHT: '#783CE2',
        LIGHT_BACKGROUND: '#ffffff',
        DARK: '#FFCF4B',
        DARK_BACKGROUND: '#224662',
    },
    BUTTON_HOVER_BACKGROUND_COLOR: {
        LIGHT: '#f5fafd',
        DARK: '#274E6E',
    },
    BUTTON_BORDER_COLOR: '#7beaf3',
    COORDINATES: {
        LEFTTOP: '3 unset unset 20',
        RIGHTTOP: '3 20 unset unset',
        LEFT: 'unset unset unset 20',
        RIGHT: 'unset 20 unset unset',
        CENTERTOP: '3 unset unset unset',
        CENTERBOTTOM: 'unset unset 3 unset',
        LEFTBOTTOM: 'unset unset 3 20',
        RIGHTBOTTOM: 'unset 20 3 unset',
    },
    DEFAULT_SETTINGS: {
        mainColor: '#783CE2',
        textColor: '#ffffff',
        linkColor: '#783CE2',
        buttonHoverColor: '#783CE2',
        mainDarkColor: '#052942',
        textDarkColor: '#ffffff',
        linkColorDark: '#FFCF4B',
        buttonHoverDarkColor: '#FFCF4B',
        greeting: 'Discover your personalization options',
        title: 'Personalization Options',
        title_text: 'Welcome to PageAssist™ toolbar! Adjust the options below to cater the website to your accessibility needs.',
        alwaysOnTop: false,
        direction: 'leftbottom',
        coordinates: 'unset unset 3 20',
        icon_shape: 'circle',
        icon_picture_url: 'logo',
        skip_to: false,
        logo_picture_base64: '',
        logo_picture_url: '',
        mainButtonBorderColorEnabled: false,
        mainButtonBorderColor: '#7beaf3',
    },
};
