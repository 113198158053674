(function () {
    'use strict';

    angular.module('filters.monsido').filter('wcagClass', wcagClass);

    wcagClass.$inject = [];
    /* @ngInject */
    function wcagClass () {
        return wcagClass;

        // //////////////////

        function wcagClass (input) {
            if (!input) {
                return;
            }
            var result;
            if (input === 'A') {
                result = 'wcag-a';
            } else if (input === 'AA') {
                result = 'wcag-aa';
            } else if (input === 'AAA') {
                result = 'wcag-aaa';
            }
            return result;
        }
    }
})();
