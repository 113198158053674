(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('spellingPlay', {
        templateUrl: 'app/pages/qa/spellcheck/play/play.html',
        controller: SpellingPlayController,
        controllerAs: 'vm',
        bindings: {
            words: '<?',
            language: '<?',
            search: '<?',
            type: '<?',
        },
    });

    SpellingPlayController.$inject = [
        'Dialog',
        'gettextCatalog',
        'ng2ToastService',
        'ng2SpellingRepoService',
        '$stateParams',
        'hotkeys',
        '$scope',
        'spellService',
    ];
    /* @ngInject */
    function SpellingPlayController (Dialog, gettextCatalog, toastService, ng2SpellingRepoService, $stateParams, hotkeys, $scope, spellService) {
        var vm = this;

        vm.$onInit = activate;
        vm.spellingClass = spellingClass;
        vm.nextWord = nextWord;
        vm.prevWord = prevWord;
        vm.confirmMisspelling = confirmMisspelling;
        vm.addToDictionary = addToDictionary;
        vm.addToAllDictionaries = addToAllDictionaries;

        function activate () {
            vm.currentWord = vm.words[0] ? vm.words[0] : {};
            vm.currentIndex = 0;
            vm.totalPage = Math.ceil(vm.words.total / vm.words.perPage);
            vm.domainId = $stateParams.domainId;
            hotkeysInit();
        }

        function hotkeysInit () {
            hotkeys
                .bindTo($scope)
                .add({
                    combo: 'right',
                    description: 'Show next word',
                    callback: function () {
                        nextWord();
                    },
                })
                .add({
                    combo: 'left',
                    description: 'Show previous word',
                    callback: function () {
                        prevWord();
                    },
                });
        }

        function nextWord (splice) {
            if (vm.loading) {
                return;
            }

            if (splice) {
                vm.words.splice(vm.currentIndex, 1);
            }

            if (vm.currentIndex >= vm.words.length - 1) {
                if (vm.words.currentPage < vm.totalPage) {
                    // Get next page
                    getPages(parseInt(vm.words.currentPage) + 1, true);
                } else {
                    // Get first page
                    getPages(1, true);
                }
            } else {
                vm.currentIndex++;
                vm.currentWord = vm.words[vm.currentIndex];
            }
        }

        function prevWord () {
            if (vm.loading) {
                return;
            }
            if (vm.currentIndex === 0) {
                if (vm.words.currentPage > 1) {
                    getPages(parseInt(vm.words.currentPage) - 1, false);
                } else {
                    getPages(vm.totalPage, false);
                }
            } else {
                vm.currentIndex--;
                vm.currentWord = vm.words[vm.currentIndex];
            }
        }

        function getPages (page, next) {
            var params = {
                page: page,
                page_size: vm.words.perPage,
                language: vm.language,
                search: vm.search,
                domainId: vm.domainId,
                classification: vm.type,
                sort_by: 'html_pages_count',
                sort_dir: 'desc',
                page_fixed: false,
            };

            vm.loading = true;

            ng2SpellingRepoService.getAllErrors(params).then(function (data) {
                vm.loading = false;
                vm.words = data;
                if (vm.words.length === 0) {
                    close();
                }
                if (next) {
                    vm.currentWord = vm.words[0];
                    vm.currentIndex = 0;
                } else {
                    vm.currentWord = vm.words[vm.words.length - 1];
                    vm.currentIndex = vm.words.length - 1;
                }
            }, angular.noop);
        }

        function close () {
            Dialog.close('spellingPlayDialog');
        }

        function spellingClass (classification) {
            return { 'label-info': classification === 'potential', 'label-warning': classification !== 'potential' };
        }

        function confirmMisspelling (word) {
            spellService.confirmWord(word.id).then(function () {
                toastService.success(gettextCatalog.getString('Word is confirmed as misspelling'));
                nextWord(true);
            }, angular.noop);
        }

        function addToDictionary () {
            var params = {
                word: vm.currentWord.word,
                language: vm.currentWord.language,
            };
            ng2SpellingRepoService.addToDictionary(params).then(function () {
                toastService.success(gettextCatalog.getString('Word is added to dictionary'));
                nextWord(true);
            }, angular.noop);
        }

        function addToAllDictionaries () {
            var params = {
                word: vm.currentWord.word,
            };
            ng2SpellingRepoService.addToDictionary(params).then(function () {
                toastService.success(gettextCatalog.getString('Word is added to dictionary for all languages'));
                nextWord(true);
            }, angular.noop);
        }
    }
})();
