(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceScoreDoughnutChart', {
        templateUrl: 'app/modules/page-performance/components/charts/score-doughnut/score-doughnut.html',
        controller: PagePerformanceScoreDoughnutChartController,
        controllerAs: 'vm',
        bindings: {
            score: '<',
        },
    });

    PagePerformanceScoreDoughnutChartController.$inject = ['gettextCatalog', 'PagePerformanceChartsService', '$filter', 'DoughnutChart'];
    /* @ngInject */
    function PagePerformanceScoreDoughnutChartController (gettextCatalog, PagePerformanceChartsService, $filter, DoughnutChart) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.doughnutChart = new DoughnutChart();
            vm.score = vm.score || 0;
            vm.labels = [gettextCatalog.getString('Performance Score'), gettextCatalog.getString('Performance Errors')];
            vm.data = [$filter('monNumber')(vm.score), $filter('monNumber')(100 - vm.score)];
            vm.doughnutChart.setCutOutPercentage(80);
            vm.doughnutChart.setCenterText($filter('monNumber')(vm.score));
            vm.doughnutChart.setPercentageFontSize(60);
            vm.doughnutChart.setPercentageFontWeight(600);
            vm.doughnutChart.setTooltipSettings({
                enabled: true,
            });
            vm.doughnutChart.setTooltipCustom({});
            vm.options = vm.doughnutChart.options;
            vm.colors = [PagePerformanceChartsService.getColorByScore(vm.score), '#d2d1d1'];
        }
    }
})();
