(function () {
    'use strict';

    angular.module('pages.uptimes').component('incidentList', {
        templateUrl: 'app/pages/uptimes/uptime-show/incident-list/incident-list.html',
        controller: IncidentListController,
        controllerAs: 'vm',
        bindings: {
            uptime: '<',
            startDate: '<',
            endDate: '<',
        },
    });

    IncidentListController.$inject = ['ng2UptimeRepoService', '$controller', 'ng2DateTimeService'];
    /* @ngInject */
    function IncidentListController (ng2UptimeRepoService, $controller, ng2DateTimeService) {
        var vm = this;

        vm.duration = duration;
        vm.$onInit = activate;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.downtimes = [];
            vm.progress = null;
            getPage();
        }

        function duration (start, end) {
            if (!end) {
                return ng2DateTimeService.durationHumanized(undefined, start);
            }
            return ng2DateTimeService.durationHumanized(start, end);
        }

        function getPage () {
            const params = {
                start_date: vm.startDate,
                end_date: vm.endDate,
                page_size: vm.pageSize,
                page: vm.page,
            };

            vm.progress = ng2UptimeRepoService.getDowntimes(vm.uptime.id, params).then(function (downTimesData) {
                vm.downtimes = downTimesData;
            }, angular.noop);
            return vm.progress;
        }
    }
})();
