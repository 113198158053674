(function() {
    "use strict";
    angular.module("modules.translations.services").factory("momentCountries", momentCountriesController);

    momentCountriesController.$inject = ["$q", "timeZone"];
    /* @ngInject*/
    function momentCountriesController($q, timeZone) {
        var defered = $q.defer();
        return {
            get: get,
        };

        /////////////////

        function get() {
            defered.resolve(timeZone);
            return defered.promise;
        }
    }
})();
