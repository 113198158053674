(function () {
    'use strict';

    /**
     * Chart
     *
     * @memberof blocks.chart
     * @ngdoc factory
     * @name Chart
     * @description
     * Creates options, colors and override objects for the chart
     */

    angular.module('modules.charts.models').factory('Chart', Chart);

    Chart.$inject = ['$deepmerge', '$document', '$rootScope'];

    /* @ngInject */
    function Chart ($deepmerge, $document, $rootScope) {
        function Model (attributes) {
            if (attributes) {
                angular.extend(this, attributes);
            }
            this.options = {
                layout: {
                    padding: {
                        bottom: 3,
                        top: 3,
                    },
                },
            };
            this.colors = [];
            this.override = [];
            $document.on('keyup', hideTooltip);
            $rootScope.$on('destroy', function () {
                $document.off('keyup', hideTooltip);
            });
        }

        Model.prototype.setHoverCursor = setHoverCursor;
        Model.prototype.setTitle = setTitle;

        Model.prototype.setTooltipSettings = setTooltipSettings;
        Model.prototype.setTooltipCallbacks = setTooltipCallbacks;
        Model.prototype.setTooltipCustom = setTooltipCustom;

        Model.prototype.setLegendSettings = setLegendSettings;
        Model.prototype.setLegendSettingsOverride = setLegendSettingsOverride;

        Model.prototype.setScalesSettings = setScalesSettings;
        Model.prototype.setHoverSettings = setHoverSettings;

        Model.prototype.addColor = addColor;
        Model.prototype.addColors = addColors;

        Model.prototype.setOverride = setOverride;
        Model.prototype.addOverride = addOverride;
        Model.prototype.addOverrides = addOverrides;

        // /////////

        function setHoverCursor () {
            this.options.onHover = function (e, el) {
                var section = el[0];
                var currentStyle = e.currentTarget.style;
                currentStyle.cursor = section ? 'pointer' : 'normal';
            };
        }

        function setTitle (title) {
            this.options.title = { display: true, text: title };
        }

        /**
         * @memberOf Chart
         * @description Set scale settings on the options object
         * @see http://www.chartjs.org/docs/latest/axes/
         *
         * @param {Object} scales
         */
        function setScalesSettings (scales) {
            checkObject(scales);
            this.options.scales = scales;
        }

        /**
         * @memberOf Chart
         * @description Set tooltip settings for options
         * @see http://www.chartjs.org/docs/latest/configuration/tooltip.html
         *
         * @param {Object} tooltip
         */
        function setTooltipSettings (tooltip) {
            checkObject(tooltip);
            this.options.tooltips = tooltip;
        }

        /**
         * @memberOf Chart
         * @description Add callback functions to the tooltips callbacks object. Options are (beforeTitle, title, afterTitle, beforeBody, beforeLabel, label, labelColor, labelTextColor, afterLabel, afterBody, beforeFooter, footer, afterFooter)
         * @see http://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-callbacks
         *
         * @param {Object} callbacks
         */
        function setTooltipCallbacks (callbacks) {
            checkObject(callbacks);
            if (!angular.isObject(this.options.tooltips)) {
                this.setTooltipSettings({});
            }

            this.options.tooltips.callbacks = callbacks;
        }

        /**
         * @memberOf Chart
         * @description Add callback functions to the tooltips callbacks object. Options are (beforeTitle, title, afterTitle, beforeBody, beforeLabel, label, labelColor, labelTextColor, afterLabel, afterBody, beforeFooter, footer, afterFooter)
         * @see https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
         *
         * @param {Object} tooltips
         */
        function setTooltipCustom (tooltips) {
            checkObject(tooltips);
            if (!angular.isObject(this.options.tooltips)) {
                this.setTooltipSettings({});
            }
            this.options.tooltips = $deepmerge(this.options.tooltips, tooltips);
            this.options.tooltips.enabled = false;
            this.options.tooltips.custom = function (tooltipModel) {
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody (bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

                    var innerHtml = '<thead>';

                    titleLines.forEach(function (title) {
                        innerHtml += '<tr><th style="padding-bottom: 3px;">' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';
                    bodyLines.forEach(function (body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style +=
                            '; border-width: 1px; border-style: solid; width: 12px; height: 12px; position: absolute; top: 0; left: 0; display: inline-block;pointer-events: none;';
                        var span = '<span style="' + style + '"></span>';
                        var bgSpan =
                            '<span style="position: absolute; top: 0; left: 0; width: 12px; height: 12px; background: white; display: inline-block; z-index: -1;"></span>';
                        innerHtml +=
                            '<tr><td style="position: relative; padding-left: 18px;">' +
                            bgSpan +
                            span +
                            body +
                            '</td></tr>';
                    });
                    innerHtml += '</tbody>';
                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;

                    // `this` will be the overall tooltip
                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.backgroundColor = '#000';
                    tooltipEl.style.color = '#fff';
                    tooltipEl.style.opacity = 0.8;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.borderRadius = '5px';
                    tooltipEl.style.pointerEvents = 'none';
                    tooltipEl.style.zIndex = 99999;
                }
            };
        }

        /**
         * @memberOf Chart
         * @description Set legend's display and position parameters in options
         *
         * @param {Boolean} display
         * @param {String} position
         */
        function setLegendSettings (display, position) {
            checkBoolean(display);
            checkString(position);
            if (!angular.isObject(this.options.legend)) {
                this.options.legend = {};
            }

            this.options.legend.display = display;
            this.options.legend.position = position;
        }

        /**
         * @memberOf Chart
         * @description Set hover settings for the options object
         * @see https://www.chartjs.org/docs/latest/general/interactions/
         *
         * @param {Object} hover
         */
        function setHoverSettings (hover) {
            checkObject(hover);
            if (!angular.isObject(this.options.hover)) {
                this.options.hover = {};
            }

            this.options.hover = $deepmerge(this.options.hover, hover);
        }

        /**
         * @memberOf Chart
         * @description Set legend settings for the options object
         * @see http://www.chartjs.org/docs/latest/configuration/legend.html
         *
         * @param {Object} legend
         */
        function setLegendSettingsOverride (legend) {
            checkObject(legend);
            if (!angular.isObject(this.options.legend)) {
                this.options.legend = {};
            }

            this.options.legend = $deepmerge(this.options.legend, legend);
        }

        /**
         * @memberOf Chart
         * @description Add color to the colors array
         *
         * @param {String|Array} color
         */
        function addColor (color) {
            if (angular.isArray(color)) {
                this.addColors(color);
            } else {
                this.colors.push(color);
            }
        }

        /**
         * @memberOf Chart
         * @description Add colors to the colors array
         *
         * @param {Array} colors
         */
        function addColors (colors) {
            checkArray(colors);
            this.colors = this.colors.concat(colors);
        }

        /**
         * @memberOf Chart
         * @description Set override rules to the override array
         *
         * @param {Array} override set overwrite the override array
         */
        function setOverride (override) {
            checkArray(override);
            this.override = override;
        }

        /**
         * @memberOf Chart
         * @description Add override rule to the override chart
         *
         * @param {Object|Array} override Add override rule to the overrides array
         */
        function addOverride (override) {
            if (angular.isArray(override)) {
                this.addOverrides(override);
            } else {
                checkObject(override);
                this.override.push(override);
            }
        }

        /**
         * @memberOf Chart
         * @description Add override rules to the override chart
         *
         * @param {Array} overrides Set override rules to the overrides array
         */
        function addOverrides (overrides) {
            checkArray(overrides);
            this.override = this.override.concat(overrides);
        }

        return Model;

        /**
         * @memberOf Chart
         * @description Check if parameter is of type array
         *
         * @param {Array} input
         */
        function checkArray (input) {
            if (!angular.isArray(input)) {
                throw 'Input must be of type array';
            }
        }

        /**
         * @memberOf Chart
         * @description Check if parameter is of type object
         *
         * @param {Object} input
         */
        function checkObject (input) {
            if (!angular.isObject(input)) {
                throw 'Input must be of type object';
            }
        }

        /**
         * @memberOf Chart
         * @description Check if parameter is of type string
         *
         * @param {String} input
         */
        function checkString (input) {
            if (!angular.isString(input)) {
                throw 'Input must be of type string';
            }
        }

        /**
         * @memberOf Chart
         * @description Check if parameter is of type bool
         *
         * @param {Boolean} input
         */
        function checkBoolean (input) {
            if (typeof input !== 'boolean') {
                throw 'Input must be of type boolean';
            }
        }

        function hideTooltip () {
            var tooltipEl = $document[0].querySelector('#chartjs-tooltip');
            if (tooltipEl) {
                tooltipEl.style.top = '-9999px';
            }
        }
    }
})();
