<mon-spinner [loading]="loading">
    <mon-issue [id]="issueId"
               [title]="issueTitle"
               [issueNotFound]="!loading && !violationData"
               [status]="violationData?.status || ''"
               class="mon-data-privacy-issue-container">
        <div slot="issue-action-button" *ngIf="violationData">
            <button class="btn btn-border-radius btn-secondary mr-1"
                    ngbTooltip="{{'Inspect page with browser extension' | translate}}"
                    (click)="openInExtension(currentPage)"
                    [disabled]="currentPage.type === 'document' || currentPage.type === 'pdf'"
                    [container]="'body'"
                    *ngIf="violationData?.status !== 'fixed'">
                    <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
                    <span class="sr-only" translate>Open browser extension</span>
            </button>
            <mon-action-menu class="mr-1" [monActionMenuItems]="dropdownOptions">
                <span translate>Action</span>
            </mon-action-menu>
        </div>
        <div slot="issue-overview">
            <h3 class="font-weight-300 mt-3 mx-4" translate>Issue details</h3>
            <hr>
            <div class="row ml-1 mt-4">
                <div class="col-12">
                    <b translate>Effect on overall compliance level</b><br>
                    <span>{{ effectOnCompliance / 100 | percent:'1.0-2' }}</span>
                </div>
                <div class="col-12">
                    <b translate>Date found</b><br>
                    <span>{{ issueData?.created_at }}</span>
                </div>
            </div>
            <div class="row ml-1 mr-4 mt-4">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <b translate>Issue</b>
                        </div>
                    </div>
                    <div class="ml-0 issue-overview-text">
                        <div class="p-2 mt-1 bg-danger">
                            {{ issueData?.content }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ml-1 mr-4 mt-4 mb-2">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <b translate>Found on page</b>
                        </div>
                    </div>
                    <div class="ml-0">
                        <div class="row">
                            <div class="col-48">
                                <mon-url-title [title]="currentPage && currentPage.title" [url]="currentPage && currentPage.url" [active]="false"></mon-url-title>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="m-4" *ngIf="violation">
                <table class="table table-hover table-fixed table-with-labels">
                    <thead>
                    <tr>
                        <th translate>Check</th>
                        <th class="col-8 text-center" translate>Help center</th>
                        <th class="col-8 text-right" translate>Likelihood</th>
                        <th class="col-8 text-right" translate>Severity</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="text-v-middle">
                            <div class="d-flex align-items-center">
                                <mon-data-privacy-info-type-icon
                                                        [monGroup]="violation.info_type.group"
                                                        [monPriority]="violation.info_type.priority"
                                                        [monTooltipText]="violation.groupText">
                                </mon-data-privacy-info-type-icon>
                                <p class="my-0 pl-2">{{ violation.info_type.name }}</p>
                            </div>
                        </td>
                        <td class="text-v-middle text-center">
                            <button type="button"
                                    class="btn btn-border-radius btn-link"
                                    [disabled]="openingHelpCenter"
                                    (click)="openHelpCenter(violation.info_type)">
                                <span class="fas fa-graduation-cap" aria-hidden="true"></span>
                                <span class="sr-only" translate>Open help center</span>
                            </button>
                        </td>
                        <td class="text-v-middle text-right">
                            {{ violation.likelihoodText }}
                        </td>
                        <td class="text-v-middle text-right">
                            <mon-data-privacy-severity-level [monPriority]="violation.info_type.priority"></mon-data-privacy-severity-level>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div slot="issue-content" *ngIf="violations">
            <h3 class="font-weight-300 mt-3 mx-4" translate>Other checks for this snippet</h3>
            <hr>
            <div class="m-4">
                <mon-table-container [collection]="violations"
                                     class="table-vertial-align"
                                     [loading]="loading">
                    <div class="table-container-body">
                        <table class="table table-hover table-fixed table-with-labels">
                            <thead>
                            <tr>
                                <th translate>Check</th>
                                <th class="col-8 text-center" translate>Help center</th>
                                <th class="col-8 text-right" translate>Likelihood</th>
                                <th class="col-8 text-right" translate>Severity</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let violation of violations">
                                <td class="text-v-middle">
                                    <div class="d-flex align-items-center">
                                        <mon-data-privacy-info-type-icon
                                                                [monGroup]="violation.info_type.group"
                                                                [monPriority]="violation.info_type.priority"
                                                                [monTooltipText]="violation.groupText">
                                        </mon-data-privacy-info-type-icon>
                                        <p class="my-0 pl-2">{{ violation.info_type.name }}</p>
                                    </div>
                                </td>
                                <td class="text-v-middle text-center">
                                    <button type="button"
                                            class="btn btn-border-radius btn-link"
                                            [disabled]="openingHelpCenter"
                                            (click)="openHelpCenter(violation.info_type)">
                                        <span class="fas fa-graduation-cap" aria-hidden="true"></span>
                                        <span class="sr-only" translate>Open help center</span>
                                    </button>
                                </td>
                                <td class="text-v-middle text-right">
                                    {{ violation.likelihoodText }}
                                </td>
                                <td class="text-v-middle text-right">
                                    <mon-data-privacy-severity-level [monPriority]="violation.info_type.priority"></mon-data-privacy-severity-level>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mon-table-container>
            </div>
        </div>
        <div slot="issue-documents" *ngIf="pages">
            <h3 class="font-weight-300 mt-3 mx-4" translate>All pages with this data privacy issue</h3>
            <hr>
            <div class="m-4">
                <mon-table-container [collection]="pages"
                                     [loading]="loading"
                                     (pageChangeEvent)="onContentPageChange($event, getPagesParams)"
                                     (perPageChange)="onContentPerPageChange($event, getPagesParams)">
                    <div class="table-container-body">
                        <table class="table table-hover table-fixed table-with-labels">
                            <thead>
                            <tr>
                                <th
                                    monTableSort
                                    [monSortBy]="'title'"
                                    [monSortDirection]="getPagesParams.value.sort_dir"
                                    [monCurrentlySortedBy]="getPagesParams.value.sort_by"
                                    (monOnSorted)="onSortContent($event, getPagesParams)">
                                    {{ 'Title and URL' | translate }}
                                </th>
                                <th class="col-6 text-right"
                                    *ngIf="viewsColumIsAvailable"
                                    monTableSort
                                    [monSortBy]="'hits'"
                                    [monSortDirection]="getPagesParams.value.sort_dir"
                                    [monCurrentlySortedBy]="getPagesParams.value.sort_by"
                                    (monOnSorted)="onSortContent($event, getPagesParams)">
                                    {{ 'Views' | translate }}
                                </th>
                                <th class="col-20 col-lg-8 col-md-12 col-sm-16 col-xl-6">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let page of pages">
                                <td>
                                    <div class="text-ellipsis">
                                        <mon-url-title [title]="page.title" [url]="page.url" [active]="false"></mon-url-title>
                                    </div>
                                </td>
                                <td *ngIf="viewsColumIsAvailable" class="text-v-middle text-right">
                                    <mon-visits [title]="page.title" [visits]="page.hits" [totalVisits]="pages.pageHits ?? 0"></mon-visits>
                                </td>
                                <td class="text-v-middle">
                                    <div class="d-flex align-items-center flex-wrap justify-content-end">
                                        <button class="btn btn-border-radius btn-secondary"
                                                ngbTooltip="{{'Open page details' | translate}}"
                                                (click)="goToPageDetails(page)"
                                                [container]="'body'">
                                            <mon-icon [icon]="['TABLES', 'PAGEDETAILS']"></mon-icon>
                                            <span class="sr-only" translate>Open page details</span>
                                        </button>
                                        <button class="btn btn-border-radius btn-secondary ml-1"
                                                ngbTooltip="{{'Inspect page with browser extension' | translate}}"
                                                (click)="openInExtension(page)"
                                                [disabled]="page.type === 'document' || page.type === 'pdf'"
                                                [container]="'body'">
                                            <mon-icon [icon]="['SEARCH', 'ICON']"></mon-icon>
                                            <span class="sr-only" translate>Open browser extension</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </mon-table-container>
            </div>
        </div>
    </mon-issue>
</mon-spinner>
