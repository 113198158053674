(function () {
    'use strict';

    angular.module('pages.domain').component('pageDetailsDashboardChartsPagePerformance', {
        templateUrl:
            'app/components/pagedetails/details/sections/dashboard/charts/page-performance/page-performance.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onClickHandler: '&',
        },
    });

    DashboardController.$inject = [
        'Lodash',
        'PagePerformanceAssginProfileService',
        'PagePerformanceAssignedProfile',
        'PAGE_DETAILS_SECTION_CONSTANT',
    ];

    /* @ngInject */
    function DashboardController (
        Lodash,
        PagePerformanceAssginProfileService,
        PagePerformanceAssignedProfile,
        PAGE_DETAILS_SECTION_CONSTANT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.assignProfile = assignProfile;

        function activate () {
            vm.firstContentfulPaint = 0;
            vm.firstMeaningfulPaint = 0;
            vm.timeToInteractive = 0;
            vm.loading = true;
            vm.rescanProfileStarted = PagePerformanceAssginProfileService.isRescanProfileStarted();
            vm.pageHasProfile = false;
            vm.pageDetailsSectionPagePerformance = PAGE_DETAILS_SECTION_CONSTANT.PERFORMANCE;

            PagePerformanceAssignedProfile.getAll({ page_id: vm.page.id })
                .then(function (assignedProfiles) {
                    var assignedProfile = Lodash.find(assignedProfiles, function (assignedProfile) {
                        return assignedProfile.page.id === vm.page.id;
                    });

                    if (assignedProfile) {
                        vm.pageHasProfile = true;
                        vm.score = assignedProfile.scores.performance * 100;
                        vm.firstContentfulPaint = assignedProfile.scores.first_contentful_paint;
                        vm.firstMeaningfulPaint = assignedProfile.scores.first_meaningful_paint;
                        vm.timeToInteractive = assignedProfile.scores.time_to_interactive;
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function assignProfile () {
            PagePerformanceAssginProfileService.assignProfile(vm.page).then(function () {
                vm.rescanProfileStarted = PagePerformanceAssginProfileService.isRescanProfileStarted();
            }, angular.noop);
        }
    }
})();
