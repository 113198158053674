(function () {
    'use strict';

    angular.module('pages.accessibility').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    const hasAccessibilitySummary = [
        'ng2ActiveFeatureService',
        '$q',
        '$state',
        '$stateParams',
        function (ng2ActiveFeatureService, $q, $state, $stateParams) {
            if (!ng2ActiveFeatureService.isFeatureActive('accessibility_summary')) {
                return $q.resolve(true);
            }
            // TODO: using UITransitionService does not work in this case
            $state.go('base.customer.domain.accessibility.summary', $stateParams);
        },
    ];

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.accessibility',
                config: {
                    url: '/accessibility',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        features: ['accessibility'],
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.summary',
                config: {
                    url: '/summary',
                    template: '<accessibility-dashboard></accessibility-dashboard>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Accessibility'),
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.pages',
                config: {
                    url: '/pages',
                    template: '<accessibility-pages></accessibility-pages>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Pages With Failing Checks'),
                    },
                    resolve: {
                        hasAccessibilitySummary,
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.issues',
                config: {
                    url: '/issues?abbr',
                    template: '<accessibility-issues></accessibility-issues>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Accessibility Check List'),
                    },
                    resolve: {
                        hasAccessibilitySummary,
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.guidelines',
                config: {
                    url: '/guidelines',
                    template: '<accessibility-guidelines></accessibility-guidelines>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Accessibility Guidelines'),
                    },
                    resolve: {
                        hasAccessibilitySummary,
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.fasttrack',
                config: {
                    url: '/fast-track',
                    template: '<accessibility-fast-track></accessibility-fast-track>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Fast Track'),
                    },
                    resolve: {
                        hasAccessibilitySummary,
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.internal_pdf',
                config: {
                    url: '/pdf_internal',
                    template:
                        '<accessibility-pdf-internal-clarity ng-if=\'vm.clarity\'></accessibility-pdf-internal-clarity><accessibility-pdf-internal ng-if=\'!vm.clarity\'></accessibility-pdf-internal>',
                    controllerAs: 'vm',
                    controller: [
                        'clarity',
                        function (clarity) {
                            this.clarity = clarity;
                        },
                    ],
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Internal PDF Compliance'),
                    },
                    resolve: {
                        clarity: domainClarityFeature,
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.external_pdf',
                config: {
                    url: '/pdf_external',
                    template: '<accessibility-pdf-external></accessibility-pdf-external>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - External PDF Compliance'),
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.ignored_pages',
                config: {
                    url: '/pages_with_ignored_checks',
                    template: '<accessibility-ignored-pages></accessibility-ignored-pages>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Pages With Ignored Checks'),
                    },
                    resolve: {
                        hasAccessibilitySummary,
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.tools',
                config: {
                    url: '/tools',
                    template: '<accessibility-tools></accessibility-tools>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Tools'),
                    },
                },
            },
            {
                state: 'base.customer.domain.accessibility.color_contrast_tool',
                config: {
                    url: '/color_contrast_tool',
                    template: '<color-contrast-tool></color-contrast-tool>',
                    data: {
                        sidebar: 'accessibility',
                        title: gettextCatalog.getString('Acquia Optimize - Color Contrast Tool'),
                    },
                    resolve: {
                        activeFeature: colorContrastValidation,
                    },
                },
            },
        ];
    }

    var domainClarityFeature = [
        'ng2SessionService',
        '$q',
        'ng2DemoHasAccessService',
        function (ng2SessionService, $q, ng2DemoHasAccessService) {
            var deferred = $q.defer();
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                deferred.resolve(true);
            }
            if (ng2SessionService.customer) {
                deferred.resolve(ng2SessionService.customer.plan_traits.clarity_pdf_accessibility);
            } else {
                deferred.resolve(false);
            }
            return deferred.promise;
        },
    ];

    var colorContrastValidation = [
        '$q',
        '$filter',
        '$state',
        'ng2MonUIRouterTransitionService',
        function ($q, $filter, $state, ng2MonUIRouterTransitionService) {
            var validate = $filter('activeFeature')('color_contrast');
            if (!validate) {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard'),
                );
            }
            return $q.resolve(true);
        },
    ];
})();
