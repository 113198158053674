import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule, MonTableSortModule, TableModule, IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MonIssueModule } from '../issue/issue.module';
import { DataPrivacyComponent } from './components/data-privacy-issue/data-privacy-issue.component';
import { MonDataPrivacyInfoTypeIconModule } from './components/info-type-icon/info-type-icon.module';
import { MonDataPrivacySeverityLevelComponent } from './components/severity-level/severity-level.component';
import { DataPrivacyIssueRepoService } from './repos/data-privacy-issue.repo';
import { DataPrivacyLikelihoodService } from './services/data-privacy-likelihood.service';
import { SharedModule } from '@monsido/ng2/shared/shared.module';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MonIssueModule,
        TableModule,
        TranslateModule,
        IconsModule,
        LayoutModule,
        MonTableSortModule,
        NgbTooltipModule,
        MonDataPrivacyInfoTypeIconModule,
        SharedModule,
    ],
    declarations: [DataPrivacyComponent, MonDataPrivacySeverityLevelComponent],
    exports: [DataPrivacyComponent, MonDataPrivacySeverityLevelComponent],
    providers: [DataPrivacyIssueRepoService, DataPrivacyLikelihoodService],
})
export class DataPrivacyModule {}
