(function () {
    'use strict';

    angular.module('app.forms').component('formLiteDomainUsers', {
        templateUrl: 'app/forms/lite-domain/steps/users/user.html',
        controller: FormLiteDomainUsersController,
        controllerAs: 'vm',
        bindings: {
            domain: '<',
            users: '<',
            isFiltersOpen: '<',
            getUsers: '&',
        },
    });

    FormLiteDomainUsersController.$inject = ['Lodash', '$controller', 'activeFeatures', 'ng2LabelRepo', 'CoreUserService'];
    /* @ngInject */
    function FormLiteDomainUsersController (Lodash, $controller, activeFeatures, ng2LabelRepo, CoreUserService) {
        var vm = this;
        vm.$onInit = activate;
        vm.isVisible = isVisible;
        vm.toggleVisible = toggleVisible;
        vm.getDomainUserIndex = getDomainUserIndex;
        vm.onFiltersChanges = onFiltersChanges;
        vm.getLabels = getLabels;
        vm.updateUserLabels = updateUserLabels;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.showLabels = activeFeatures.labels;
            getLabels();
            vm.domain.domain_users = vm.domain.domain_users || [];
        }

        function getDomainUserIndex (user) {
            var index = Lodash.findIndex(vm.domain.domain_users, function (domainUser) {
                return domainUser.user_id === user.id;
            });

            if (index === -1) {
                setDomainUser(user);
                return vm.domain.domain_users.length - 1;
            } else {
                return index;
            }
        }

        function setDomainUser (user) {
            vm.domain.domain_users.push({
                user_id: user.id,
                visible: Boolean(user.customer_admin),
            });
        }

        function isVisible (user) {
            var index = getDomainUserIndex(user);
            return user.customer_admin || Boolean(vm.domain.domain_users[index].visible);
        }

        function toggleVisible (user) {
            var index = getDomainUserIndex(user);
            vm.domain.domain_users[index].visible = !vm.domain.domain_users[index].visible;
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            if (vm.selectedLabels.length) {
                vm.users = filterUsers(vm.users, vm.selectedLabels);
            } else {
                vm.getUsers().then(function (data) {
                    vm.users = data;
                }, angular.noop);
            }
        }

        function filterUsers (users, selectedLabels) {
            return users.filter(function (user) {
                if (user.labels.length >= selectedLabels.length) {
                    return user.labels.some(function (label) {
                        return selectedLabels.indexOf(label.id) > -1;
                    });
                }
            });
        }

        function updateUserLabels (user) {
            CoreUserService.updateUserLabels(user);
        }
    }
})();
