<div class="vertical-score-component">
    <span *ngIf="monTitle" class="vertical-score-component_title">{{monTitle}} <span *ngIf="monTooltipText"
        class="fas fa-question-circle hidden-print" aria-hidden="true" tooltip-trigger="'focus mouseenter'" [ngbTooltip]="monTooltipText"></span></span>
    <mon-doughnut-chart
        [data]="chartData"
        [colors]="colors"
        [labels]="monLabels"
        [options]="options"
        [size]="monSize"
    ></mon-doughnut-chart>
</div>
