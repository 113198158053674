import { Injectable } from '@angular/core';
import { POLICY_TYPES } from '@client/app/modules/policies/constants/policy-types';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { Page } from '@monsido/ng2/modules/page-details/models';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';
import { PolicyModel } from './policy-model';
import { PolicyRepoAuthorType, PolicyRepoLinkType, PolicyRepoType } from './policy.types';

type PolicyRepoGetAllParamsType = BaseRepoRequestType & { archived: boolean }
type PolicyRepoGetAllDomainsParamsType = BaseRepoRequestType & { mode?: string, scheduled?: boolean }

@Injectable({
    providedIn: 'root',
})
export class PolicyRepoService extends BaseRepo {

    constructor (protected sessionService: SessionService, private apiService: ApiService, private monEventsService: MonEventService) {
        super();
    }

    getAll (params:PolicyRepoGetAllParamsType): Promise<MonTableCollection<PolicyModel>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('policies/', params).then((res: MonTableCollection<PolicyRepoType>) => this.toModels(res));
    }

    create (params: Partial<PolicyRepoType>): Promise<PolicyModel> {
        return this.apiService.post('policies', params).then((policy: PolicyRepoType) => {
            this.monEventsService.run('policyAdded', policy);
            return this.toModel(policy);
        });
    }

    update (data: PolicyRepoType): Promise<PolicyModel> {
        const params = this.setGroupId({});
        return this.apiService.patch('policies/' + data.id, data, params).then((policy: PolicyRepoType) => {
            this.monEventsService.run('policyUpdated', policy);
            return this.toModel(policy);
        });
    }

    destroy (id: number): Promise<void> {
        return this.apiService.delete('policies/' + id);
    }

    run (id: number): Promise<void> {
        return this.apiService.post('policies/' + id + '/run');
    }

    domainGetAll (params: PolicyRepoGetAllDomainsParamsType): Promise<MonTableCollection<PolicyModel>> {
        params = params || {};
        const domainId = this.getDomainId(params.domain_id);
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + domainId + '/policies', params).then((res: MonTableCollection<PolicyRepoType>) => this.toModels(res));
    }

    domainGetPages (params: BaseRepoRequestType): Promise<MonTableCollection<Page>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/policies/pages', params);
    }

    domainGetPolicyPages (policyId: number, params: BaseRepoRequestType): Promise<MonTableCollection<Page>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/policies/' + policyId + '/pages', params);
    }

    domainGetPolicyLinks (policyId: number, params: BaseRepoRequestType): Promise<MonTableCollection<PolicyRepoLinkType>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/policies/' + policyId + '/links', params);
    }

    getPagesWithPolicyIgnoredChecks (params: BaseRepoRequestType): Promise<MonTableCollection<Page>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId() + '/policies/ignored_policies/pages', params);
    }

    getPolicyAuthors (params: BaseRepoRequestType): Promise<PolicyRepoAuthorType[]> {
        params = params || {};
        return this.apiService.get('policies/authors', params);
    }

    toModels (models: MonTableCollection<PolicyRepoType>): MonTableCollection<PolicyModel> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models as unknown as MonTableCollection<PolicyModel>;
    }

    toModel (model: PolicyRepoType): PolicyModel {
        const targetType = (this.sessionService.domainGroup !== null) ? POLICY_TYPES.domain_group : POLICY_TYPES.domain;
        return new PolicyModel(model, targetType);
    }
}
