(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormWizardContainerFooterGoToOptions', {
        templateUrl: 'app/modules/page-fix/forms/wizard-container/footers/go-to-options/go-to-options.html',
        controller: PageFixFormFooterOptionsController,
        controllerAs: 'vm',
        bindings: {
            pageFixApplied: '<',
            view: '<',
        },
    });

    PageFixFormFooterOptionsController.$inject = ['PAGE_FIX_EVENT', 'ng2MonEventsService'];
    /* @ngInject */
    function PageFixFormFooterOptionsController (PAGE_FIX_EVENT, ng2MonEventsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.goToView = goToView;

        function activate () {}

        function goToView () {
            ng2MonEventsService.run(PAGE_FIX_EVENT.FORM_REQUEST_GO_TO_VIEW, vm.view);
        }
    }
})();
