(function () {
    'use strict';
    /**
     * @memberof blocks.service
     * @ngdoc factory
     * @name ActiveRegionService
     * @description Get region details of current active region
     */
    angular.module('blocks.service').factory('ActiveRegionService', ActiveRegionService);

    ActiveRegionService.$inject = ['ng2OauthService', 'environment'];
    /* @ngInject*/
    function ActiveRegionService (oauthService, environment) {
        return {
            isInRegion: isInRegion,
        };

        /**
         * @memberOf ActiveRegionService
         * @desc Check if sought region aligns with active region
         * @param {string} region
         * @return {boolean}
         */
        function isInRegion (region) {
            if (typeof region !== 'string') {
                return false;
            }

            if (['staging', 'development', 'test'].indexOf(environment) > -1) {
                return true;
            }

            return getRegion() === region.toUpperCase();
        }

        // PROTECTED

        /**
         * @memberOf ActiveRegionService
         * @desc Get the region name from the api path
         * @return {string}
         */
        function getRegion () {
            var apiPath = oauthService.getApiPath();
            var splitUrl = apiPath.split('.');
            return splitUrl[1].toUpperCase();
        }
    }
})();
