(function () {
    'use strict';

    angular.module('modules.roles').filter('hasAccessToRoleSection', HasAccessToRoleSectionFilter);

    HasAccessToRoleSectionFilter.$inject = ['RoleValidationService', 'ROLES_ROLE_SECTIONS'];
    /* @ngInject */
    function HasAccessToRoleSectionFilter (RoleValidationService, ROLES_ROLE_SECTIONS) {
        return filter;

        // //////////

        function filter (roleSection) {
            var section = ROLES_ROLE_SECTIONS[roleSection];
            if (section) {
                return RoleValidationService.hasAccessToRoleSection(section);
            }
            return true;
        }
    }
})();
