import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('services.core-user').service('CoreUserService', CoreUserService);

    CoreUserService.$inject = [
        'userService',
        'gettextCatalog',
        'ng2MonEventsService',
        'ng2MonModalService',
        '$q',
        '$deepmerge',
        'ng2SessionService',
        'FORM_CONSTANT',
        'LoadingService',
    ];

    /* @ngInject*/
    function CoreUserService (
        userService,
        gettextCatalog,
        ng2MonEventsService,
        ng2MonModalService,
        $q,
        $deepmerge,
        ng2SessionService,
        FORM_CONSTANT,
        LoadingService,
    ) {
        var service = {
            deleteUserPrompt: function (user) {
                var confirmText =
                    '<span>' +
                    gettextCatalog.getString('You are about to delete') +
                    '<span class=\'text-link\'> ' +
                    user.first_name +
                    ' ' +
                    user.last_name +
                    '</span> ' +
                    '<span>' +
                    gettextCatalog.getString('please type') +
                    ' <code>Delete</code> ' +
                    gettextCatalog.getString('to confirm this action') +
                    '</span>';

                var deferred = $q.defer();
                ng2MonModalService.prompt(confirmText).then(function (input) {
                    var deleteDialog = LoadingService();
                    if ('delete' !== input.toLowerCase().trim()) {
                        deferred.reject();
                    } else {
                        userService
                            .destroy(user)
                            .then(function () {
                                deferred.resolve();
                            }, angular.noop)
                            .finally(function () {
                                deleteDialog.close();
                            });
                    }
                }, deferred.reject);

                return deferred.promise;
            },
            updateUserLabels: function (user, params) {
                params = params || {};
                params.first_name = user.first_name;
                params.email = user.email;
                params.labels = user.labels.map(function (label) {
                    return label.id;
                });
                params.id = user.id;
                return userService.update(params);
            },
            updateUser: function (user) {
                var deferred = $q.defer();
                user = $deepmerge({}, user || {});
                var params = {
                    size: 'md',
                    data: { user: user },
                };
                if (ng2SessionService.isLiteCustomer()) {
                    params.body = FORM_CONSTANT.FORM_LITE_USER;
                } else {
                    params.body = FORM_CONSTANT.FORM_USER;
                }

                const callback = function (user) {
                    deferred.resolve(user);
                };

                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
                return deferred.promise;
            },
        };

        return service;
    }
})();
