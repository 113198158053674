(function () {
    'use strict';

    angular.module('services.api').factory('AccessibilitySourceCodeExcludeRepo', AccessibilitySourceCodeExcludeRepo);

    AccessibilitySourceCodeExcludeRepo.$inject = ['ng2ApiService'];

    function AccessibilitySourceCodeExcludeRepo (ng2ApiService) {
        return {
            getAll: getAll,
            get: get,
            create: create,
            update: update,
            destroy: destroy,
        };

        // //////////////////////////////

        function get (excludeId, params, headers) {
            params = params || {};
            return ng2ApiService.get('accessibility/source_code_excludes/' + excludeId, params, headers);
        }

        function getAll (params, headers) {
            params = params || {};
            return ng2ApiService.get('accessibility/source_code_excludes', params, headers);
        }

        function create (model, params, headers) {
            params = params || {};
            return ng2ApiService.post('accessibility/source_code_excludes', model, params, headers);
        }

        function update (sourceExclude, model, params, headers) {
            return ng2ApiService.patch('accessibility/source_code_excludes/' + sourceExclude.id, model, params, headers);
        }

        function destroy (excludeId, params, headers) {
            params = params || {};
            return ng2ApiService.delete('accessibility/source_code_excludes/' + excludeId, params, headers);
        }
    }
})();
