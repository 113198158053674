(function () {
    'use strict';
    angular.module('modules.light.pages.domain.page_fix').component('lightDomainPageFixAccessibilityChecks', {
        templateUrl:
            'app/modules/light/pages/domain/page-fix/lightDomainPageFixAccessibilityChecks/lightDomainPageFixAccessibilityChecks.html',
        controller: LightDomainPageFixAccessibilityChecksController,
        controllerAs: 'vm',
        bindings: {
            category: '@?',
        },
    });

    LightDomainPageFixAccessibilityChecksController.$inject = ['gettextCatalog', 'ng2LightPageFixService'];
    /* @ngInject */
    function LightDomainPageFixAccessibilityChecksController (gettextCatalog, ng2LightPageFixService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.title = gettextCatalog.getString(ng2LightPageFixService.getTitle(vm.category));
            vm.icon = ng2LightPageFixService.getIcon(vm.category);
        }
    }
})();
