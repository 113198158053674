(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyPreContentRuleMissingTrademark', {
        templateUrl: 'app/forms/global-policy/steps/pre-content/rules/missing-trademark.html',
        controller: PolicyPreContentRuleMissingTrademarkController,
        controllerAs: 'vm',
        require: {
            contentController: '^formGlobalPolicyPreContent',
        },
    });

    PolicyPreContentRuleMissingTrademarkController.$inject = [];

    /* @ngInject */
    function PolicyPreContentRuleMissingTrademarkController () {
        var vm = this;

        vm.onModelChange = onModelChange;
        vm.$onInit = activate;
        vm.addRow = addRow;
        vm.removeRow = removeRow;

        function activate () {
            vm.ngModel = [];
            addRow();
            vm.tradeMarkOptions = [
                {
                    name: 'BRAND(TM)',
                    value: 'BRAND\\(TM\\)',
                },
                {
                    name: 'BRAND (TM)',
                    value: 'BRAND \\(TM\\)',
                },
                {
                    name: 'BRAND™',
                    value: 'BRAND™',
                },
                {
                    name: 'BRAND ™',
                    value: 'BRAND ™',
                },
            ];
        }

        function onModelChange () {
            var rules = [];
            angular.forEach(vm.ngModel, function (row, index) {
                rules.push({
                    name: 'Missing trademark for: ' + row.content,
                    case_sensitive: true,
                    inverse: false,
                    operator: 'conforms_with',
                    query: row.content + '\\s*(?:\\(TM\\)|™)?',
                    type: 'page_text',
                    sub_entry: row.sub_entry.replace('BRAND', row.content),
                });
            });

            vm.contentController.ngModel = rules;
        }

        function addRow () {
            vm.ngModel.push({ content: '', sub_entry: 'BRAND\\(TM\\)' });
        }

        function removeRow (row) {
            var index = vm.ngModel.indexOf(row);
            if (index !== -1) {
                vm.ngModel.splice(index, 1);
            }
        }
    }
})();
