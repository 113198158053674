(function () {
    'use strict';

    /**
     * @memberof filters.monsido
     * @ngdoc filter
     * @name accessibilityHasGuideline
     * @description Check if domain uses selected guideline
     */

    angular.module('filters.monsido').filter('accessibilityHasGuideline', AccessibilityHasGuidelineFilter);

    AccessibilityHasGuidelineFilter.$inject = ['ng2AccessibilityAuxService'];

    /* @ngInject */
    function AccessibilityHasGuidelineFilter (ng2AccessibilityAuxService) {
        var guidelines = {
            QUALWEB: ['QUALWEB-A', 'QUALWEB-AA'],
            WCAG2: ['WCAG2-A', 'WCAG2-AA', 'WCAG2-AAA'],
            WCAG21: ['WCAG21-A', 'WCAG21-AA', 'WCAG21-AAA', 'WCAG21-A_MOBILE', 'WCAG21-AA_MOBILE', 'WCAG21-AAA_MOBILE'],
            WCAG22: ['WCAG22-A', 'WCAG22-AA', 'WCAG22-AAA', 'WCAG22-A_MOBILE', 'WCAG22-AA_MOBILE', 'WCAG22-AAA_MOBILE'],
        };

        return filter;

        /**
         * @param {String} guideline
         * @param {Object} [domain] - Selected domain
         * @return {Boolean}
         */
        function filter (guideline, domain) {
            return ng2AccessibilityAuxService.hasGuideline(guideline, domain);
        }
    }
})();
