(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationQuickHelp', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/quick-help/quick-help.html',
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });
})();
