(function () {
    'use strict';
    /**
     * Chart
     *
     * @memberof blocks.service
     * @ngdoc factory
     * @name AccessibilityResultTypeService
     * @description Manage accessibility result type number from summaries, crawls and checks list
     */
    angular.module('blocks.service').factory('AccessibilityResultTypeService', AccessibilityResultTypeService);

    AccessibilityResultTypeService.$inject = ['$filter', 'gettextCatalog', 'ng2AccessibilityGuidelinesInfoService'];

    /* @ngInject*/
    function AccessibilityResultTypeService ($filter, gettextCatalog, ng2AccessibilityGuidelinesInfoService) {
        return {
            getCrawlIssueCount: getCrawlIssueCount,
            getResultTypeOptions: getResultTypeOptions,
            getCheckCountByResultType: getCheckCountByResultType,
            getChecksParameters: getChecksParameters,
            getChecksCount: getChecksCount,
            getAccessibilityChecksExcludeIgnored: getAccessibilityChecksExcludeIgnored,
        };

        // ///////////////

        /**
         * @memberOf AccessibilityResultTypeService
         * @description Return the result type options array
         * @return {Array}
         */
        function getResultTypeOptions () {
            return [
                {
                    label: gettextCatalog.getString('All'),
                    value: null,
                },
                {
                    label: gettextCatalog.getString('Only Errors'),
                    leftIcon: 'faBug',
                    value: 'errors',
                },
                {
                    label: gettextCatalog.getString('Errors and Warnings'),
                    leftIcon: 'faBug',
                    menuItemButtonIcons: [{ monIconDirect: 'faBell' }],
                    value: 'errors_and_warnings',
                },
            ];
        }

        /**
         * @memberOf AccessibilityResultTypeService
         * @description Return the sum of selected resultType per abbreviation
         * @param {Object} crawl - Crawl data object
         * @param {String} abbr - Accessibility abbreviation to analyze
         * @param {String} resultType - Selected result type (total, error, review, warning)
         * @return {number}
         */
        function getCrawlIssueCount (crawl, abbr, resultType) {
            var checksInError = crawl.accessibility_checks_by_result_type_and_abbr;
            if (checksInError === null && ['errors', 'errors_and_warnings'].indexOf(resultType) > -1) {
                return 0;
            }

            switch (resultType) {
                case 'errors':
                    return checksInError.error[abbr] || 0;
                case 'errors_and_warnings':
                    return (checksInError.error[abbr] || 0) + (checksInError.warning[abbr] || 0);
                default:
                    return crawl.accessibility_checks[abbr] || 0;
            }
        }

        /**
         * @memberOf AccessibilityResultTypeService
         * @description Return the Total count of checks available for selected resultType
         * @param {String} resultType - Selected resultType (total, error, review, warning)
         * @param {String} accGuideline - Accessibility guideline that is supported by the selected domain
         * @return {number}
         */
        function getCheckCountByResultType (resultType, accGuideline) {
            switch (resultType) {
                case 'errors':
                    return getResultTypeCount('error', accGuideline);
                case 'errors_and_warnings':
                    return getResultTypeCount('error', accGuideline) + getResultTypeCount('warning', accGuideline);
                default:
                    return getResultTypeCount('total', accGuideline);
            }
        }

        /**
         * @memberOf AccessibilityLineChartService
         * @param {String} resultType - Selected resultType (total, error, review, warning)
         * @param {String} accGuideline - Accessibility guideline that is supported by the selected domain
         * @description Return array of parameters that should be fetched from the accessibility_checks parameter in the crawl object
         * @return {string[]}
         */
        function getChecksParameters (resultType, accGuideline) {
            var type;
            if (['errors', 'errors_and_warnings'].indexOf(resultType) > -1) {
                type = resultType;
            } else {
                type = accGuideline.endsWith('_v2') ? accGuideline.slice(0, -3) : accGuideline;
            }

            switch (type) {
                case 'errors':
                    return ['error'];
                case 'errors_and_warnings':
                    return ['error', 'warning'];
                case 'WCAG2-A':
                    return ['A'];
                case 'WCAG2-AA':
                    return ['A', 'AA'];
                case 'WCAG2-AAA':
                    return ['A', 'AA', 'AAA'];
                case 'WCAG21-A':
                    return ['A'];
                case 'WCAG21-AA':
                    return ['A', 'AA'];
                case 'WCAG21-AAA':
                    return ['A', 'AA', 'AAA'];
                case 'WCAG22-A':
                    return ['A'];
                case 'WCAG22-AA':
                    return ['A', 'AA'];
                case 'WCAG22-AAA':
                    return ['A', 'AA', 'AAA'];
                default:
                    return [];
            }
        }

        /**
         * @memberOf AccessibilityResultTypeService
         * @desc Get the count of checks filtered by parameters
         * @param {Array} checks - Accessibility checks list
         * @param {String} resultType - Selected resultType (total, error, review, warning)
         * @param {Array<string>} abbreviations - Accessibility abbreviations
         * @param {Boolean} onlyErrors - Search for checks that contains only error
         * @return {*}
         */
        function getChecksCount (checks, resultType, abbreviations, onlyErrors) {
            var params = {
                only_errors: onlyErrors,
                abbreviations: abbreviations,
            };
            if (resultType === 'errors') {
                params.result_types = ['error'];
            } else if (resultType === 'errors_and_warnings') {
                params.result_types = ['error', 'warning'];
            }

            return $filter('accessibilityChecksByResultTypes')(checks, params).length;
        }

        /**
         * @memberOf AccessibilityResultTypeService
         * @desc Get the count of checks exclude ignored checks
         * @param {Array} checks - Accessibility checks list
         * @return {number}
         */
        function getAccessibilityChecksExcludeIgnored (checks) {
            return checks.reduce(function (accumulator, check) {
                if (!check.ignored) {
                    accumulator++;
                }
                return accumulator;
            }, 0);
        }

        // //////// PROTECTED

        /**
         * @memberOf AccessibilityResultTypeService
         * @param {String} resultType - Selected resultType (total, error, review, warning)
         * @param {String} accGuideline - Accessibility guideline that is supported by the selected domain
         * @return {number}
         */
        function getResultTypeCount (resultType, accGuideline) {
            if (typeof accGuideline === 'undefined' || typeof accGuideline !== 'string') {
                return 0;
            }

            var result = 0;
            var abbreviations = getChecksParameters(resultType, accGuideline);
            var accessibilityGuidelines = ng2AccessibilityGuidelinesInfoService.retrieveSynchronousData();
            var guidelineLevels = accessibilityGuidelines[accGuideline];

            var levelData;
            var value;
            for (var i = 0; i < abbreviations.length; i++) {
                levelData = guidelineLevels[abbreviations[i]];
                if (levelData) {
                    value = levelData[resultType];
                }
                if (!isNaN(value)) {
                    result += value;
                }
            }

            return result;
        }
    }
})();
