import { NgModule } from '@angular/core';
import { ColorHelperService } from './services/color-contrast/color-helper.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorHelperService,
    ]
})
export class ToolsHelperModule {}
