(function () {
    'use strict';

    angular.module('app.forms').component('formLabel', {
        templateUrl: 'app/forms/label/label.html',
        controller: FormLabelController,
        controllerAs: 'vm',
        bindings: {
            label: '<?',
            monDialogId: '@?',
        },
    });

    FormLabelController.$inject = ['monDialog', 'labelService', 'gettextCatalog', 'ng2ToastService', 'MON_COLORS', 'LabelColorInverter'];
    /* @ngInject */
    function FormLabelController (monDialog, labelService, gettextCatalog, toastService, MON_COLORS, LabelColorInverter) {
        var vm = this;
        vm.submit = submit;
        vm.$onInit = activate;

        function activate () {
            vm.label = vm.label || { name: '', color: '' };
            if (vm.label.id === undefined || vm.label.id === null) {
                vm.title = gettextCatalog.getString('Create label');
                vm.label.color = MON_COLORS.DEFAULT;
            } else {
                vm.title = gettextCatalog.getString('Edit label');
            }
        }

        function submit () {
            vm.onSave = true;
            var params = {
                name: vm.label.name,
                color: vm.label.color,
            };
            var promise;
            if (vm.label.id === undefined || vm.label.id === null) {
                promise = labelService.createLabel(params).then(function (data) {
                    toastService.success(gettextCatalog.getString('Label created'));
                    close(data);
                }, angular.noop);
            } else {
                params.label_id = vm.label.id;
                promise = labelService.updateLabel(params).then(function (data) {
                    toastService.success(gettextCatalog.getString('Label updated'));
                    close(data);
                }, angular.noop);
            }

            promise.catch(function () {
                vm.onSave = false;
            });
        }

        function close (data) {
            monDialog.close(vm.monDialogId, data);
        }
    }
})();
