(function () {
    'use strict';

    angular.module('filters.monsido').filter('role', role);

    role.$inject = ['ng2SessionService'];
    /* @ngInject */
    function role (ng2SessionService) {
        return roleFilter;

        // //////////

        function roleFilter (roleTypes) {
            for (var i = 0; i < roleTypes.length; i++) {
                if (ng2SessionService.roles.indexOf(roleTypes[i]) !== -1) {
                    return true;
                }
            }
            return false;
        }
    }
})();
