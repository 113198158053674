(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('spellingPagesWithError', {
        templateUrl: 'app/pages/qa/spellcheck/pages-with-error/index.html',
        controller: SpellingPagesIndex,
        controllerAs: 'vm',
    });

    SpellingPagesIndex.$inject = ['ng2SpellingRepoService', '$controller', '$scope', '$stateParams', '$filter', 'gettextCatalog'];
    /* @ngInject */
    function SpellingPagesIndex (ng2SpellingRepoService, $controller, $scope, $stateParams, $filter, gettextCatalog) {
        var vm = this;

        vm.getPage = getPage;
        vm.onFiltersChanges = onFiltersChanges;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.search = null;
            vm.pages = [];
            vm.domainId = $stateParams.domainId;
            vm.progress = null;
            vm.subheaderTranslation = '';

            vm.currentSort = { by: 'priority_score', direction: 'desc' };

            vm.exports = [
                {
                    name: gettextCatalog.getString('Pages with Misspellings'),
                    data: {
                        category: 'pages_with_misspelling',
                    },
                },
            ];

            vm.tabs = [
                {
                    name: 'All',
                    faIcon: 'fas fa-language',
                    value: null,
                },
                {
                    name: gettextCatalog.getString('Misspellings'),
                    faIcon: 'fas fa-language',
                    value: 'confirmed',
                },
                {
                    name: gettextCatalog.getString('Potential misspellings'),
                    faIcon: 'fas fa-language',
                    value: 'potential',
                },
            ];
            vm.activeTab = null;
            $scope.$watch('vm.activeTab', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    getPage();
                }
            });

            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                classification: vm.activeTab,
                type: vm.type,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            params = vm.updateParamsWithFilters(params);

            vm.progress = ng2SpellingRepoService.getAllPages(params).then(function (data) {
                vm.pages = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.pages.total, '0,0'),
                '1 page',
                '{{$count}} pages',
                {},
            );
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            getPage();
        }
    }
})();
