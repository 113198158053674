import { Injectable } from '@angular/core';
import { FormLabelSubmitPayload } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { LabelRepo } from '@monsido/modules/endpoints/api/label.repo';

@Injectable({
    providedIn: 'root',
})
export class LabelService {

    constructor (private labelRepo: LabelRepo) { }

    createOrUpdateLabel (label: FormLabelSubmitPayload): Promise<void> {
        const params: { id?: number, name: string, color: string } = {
            name: label?.name,
            color: label?.color,
        };

        if (label?.id) {
            params.id = label.id;
            return this.labelRepo.updateLabel(params);
        } else {
            return this.labelRepo.createLabel(params);
        }
    }
}
