import { rollBarId, environment } from './general.constant';
import { Configuration } from 'rollbar';
import packageJson from '../../../../../package.json';

export const ROLLBAR_CONFIG: Configuration = {
    accessToken: rollBarId,
    captureUncaught: true,
    captureUnhandledRejections: true,
    maxItems: 10,
    itemsPerMinute: 5,
    payload: {
        environment: environment,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: packageJson.version,
                guess_uncaught_frames: true,
            },
        },
    },
};
