(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name PagePerformanceProfileService
     * @description Service to handle Page Performance profiles
     */
    angular.module('services.entities').factory('PagePerformanceProfile', PagePerformanceProfileService);

    PagePerformanceProfileService.$inject = ['ng2ProfilesRepoService'];
    /* @ngInject*/
    function PagePerformanceProfileService (ng2ProfilesRepoService) {
        return {
            getAll: getAll,
            create: create,
            update: update,
            destroy: destroy,
            archive: archive,
        };

        function getAll (params, contextToken) {
            return ng2ProfilesRepoService.getAll(params, contextToken);
        }

        function create (profile, params, config) {
            return ng2ProfilesRepoService.create(profile, params, config);
        }

        function destroy (profile) {
            return ng2ProfilesRepoService.destroy(profile);
        }

        function update (profile, params, config) {
            return ng2ProfilesRepoService.update(profile, params, config);
        }

        function archive (profile, params, config) {
            return ng2ProfilesRepoService.archiveProfile(profile, params, config);
        }
    }
})();
