(function () {
    'use strict';
    angular
        .module('modules.data_privacy.services')
        .factory('complianceDataPrivacyService', complianceDataPrivacyService);

    complianceDataPrivacyService.$inject = [];

    /* @ngInject*/
    function complianceDataPrivacyService () {
        return {
            getTotalPercentageCount: getTotalPercentageCount,
            getPercentageCountByPriority: getPercentageCountByPriority,
            dataPrivacyCompliance: dataPrivacyCompliance,
            dataPrivacyCompliancePercentage: dataPrivacyCompliancePercentage,
        };

        /**
         *
         * @param infoTypes
         * @param totalPages
         * @returns {*}
         */
        function getTotalPercentageCount (infoTypes, totalPages) {
            return (
                infoTypes.reduce(function (num, type) {
                    return num + (parseInt(type.pages_count) / totalPages) * 100;
                }, 0) / infoTypes.length
            );
        }

        /**
         *
         * @param infoTypes
         * @param totalPages
         * @param priority
         * @returns {*}
         */
        function getPercentageCountByPriority (infoTypes, totalPages, priority) {
            var types = filterTypes(infoTypes, priority);
            return (
                types.reduce(function (num, type) {
                    return num + (parseInt(type.pages_count) / totalPages) * 100;
                }, 0) / types.length
            );
        }

        function filterTypes (infoTypes, priority) {
            return infoTypes.filter(function (c) {
                return c.priority === priority;
            });
        }

        function dataPrivacyCompliance (summary) {
            var percentage = dataPrivacyCompliancePercentage(summary);
            return [percentage, 100 - percentage];
        }

        function dataPrivacyCompliancePercentage (summary) {
            return summary.compliance_percentage;
        }
    }
})();
