(function () {
    'use strict';

    angular.module('pages.policy').component('policyLastScan', {
        templateUrl: 'app/pages/policy/policy/last-scan/last-scan.html',
        controller: LastScanController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            pagesCount: '<',
            pagesErrorsCount: '<',
            linksCount: '<',
            policies: '<',
        },
    });

    LastScanController.$inject = ['$filter', 'gettextCatalog', 'ng2SessionService', 'PolicyLineChartService', 'complianceService'];

    function LastScanController ($filter, gettextCatalog, ng2SessionService, PolicyLineChartService, complianceService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.domain = ng2SessionService.domain;
            vm.latestCrawl = getLatestHistory();
            vm.cardsList = [];

            setupLineChart();
            setupDoughnutChart();
            setupNotifications();
        }

        function getLatestHistory () {
            if (vm.history.length > 0) {
                return vm.history.sort(function (a, b) {
                    // Get the latest history id
                    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                })[0];
            } else {
                return {};
            }
        }

        function setupLineChart () {
            var lineChartService = new PolicyLineChartService();
            vm.lineChartData = lineChartService.getData(vm.history);
            vm.lineChartLabels = lineChartService.getLabels(vm.history);
            vm.lineOverride = lineChartService.getOverride();
            vm.lineChartSeries = lineChartService.getSeries();
            vm.lineColors = lineChartService.getColors();
            vm.lineOptions = lineChartService.getOption();
        }

        function setupDoughnutChart () {
            vm.doughnutLabels = ['', ''];
            vm.doughnutColors = ['#4dc0bd', '#DCDCDC'];

            vm.doughnutData = complianceService.policyCompliance(
                vm.latestCrawl,
                vm.policies,
                vm.pagesErrorsCount,
                vm.pagesCount,
                vm.linksCount,
            );

            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: $filter('monNumber')(
                        complianceService.policyCompliancePercentage(
                            vm.latestCrawl,
                            vm.policies,
                            vm.pagesErrorsCount,
                            vm.pagesCount,
                            vm.linksCount,
                        ) / 100,
                        '0.[00] %',
                    ),
                    totalLabel: gettextCatalog.getString('Policy'),
                    subLabel: gettextCatalog.getString('Compliance'),
                    subFontSize: 170,
                    totalFontSize: 170,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            if (tooltipItem.index === 0) {
                                return gettextCatalog.getString('Succeeds');
                            }
                            return gettextCatalog.getString('Errors');
                        },
                    },
                },
                hover: {
                    mode: null,
                },
            };
        }

        function setupNotifications () {
            var policiesInError = vm.policies.filter(function (policy) {
                return policy.matches_count > 0;
            });
            vm.cardsList = [
                {
                    title: gettextCatalog.getString('Policies with violations'),
                    issues: policiesInError.length,
                },
                {
                    title: gettextCatalog.getString('Content with policy violations'),
                    tooltipTitle: gettextCatalog.getString('Includes both documents and pages'),
                    issues: vm.pagesErrorsCount,
                },
            ];
        }
    }
})();
