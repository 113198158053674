import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.api-users').component('apiUsersDashboard', {
        templateUrl: 'app/modules/api-users/pages/dashboard/dashboard.html',
        controller: ApiUsersDashboardController,
        controllerAs: 'vm',
    });

    ApiUsersDashboardController.$inject = [
        'ParamService',
        '$controller',
        'ApiUsersRepo',
        'ng2MonEventsService',
        'gettextCatalog',
        'ng2MonModalService',
        'ng2OauthService',
        'ng2SessionService',
        'ApiUsersService',
        'copyDialogService',
        'FORM_CONSTANT',
    ];

    /* @ngInject */
    function ApiUsersDashboardController (
        ParamService,
        $controller,
        ApiUsersRepo,
        ng2MonEventsService,
        gettextCatalog,
        ng2MonModalService,
        oauthService,
        ng2SessionService,
        ApiUsersService,
        copyDialogService,
        FORM_CONSTANT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.openDialog = openDialog;
        vm.removeApiUser = removeApiUser;
        vm.revokeToken = revokeToken;
        vm.getScopeTranslation = getScopeTranslation;
        vm.copyApiKey = copyApiKey;

        function activate () {
            vm.apiPath = oauthService.getApiPath();
            vm.accountId = ng2SessionService.agreement.account.id;
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.apiUsers = [];
            getPage();
        }

        function getScopeTranslation (scopeValue) {
            return ApiUsersService.getScopeTranslation(scopeValue);
        }

        function getPage () {
            ParamService.setParams({
                page_size: vm.pageSize,
                page: vm.page,
            });

            getApiUsers();
        }

        function getApiUsers () {
            vm.loading = true;
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
            };

            vm.promise = ApiUsersRepo.getAll(params)
                .then(function (apiUsers) {
                    vm.apiUsers = apiUsers;
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                    updateDropdowns();
                });
        }

        function updateDropdowns () {
            for (const apiUser of vm.apiUsers) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('See API Token'),
                        leftIcon: 'faCog',
                        action: ()=>copyApiKey(apiUser.token),
                    },
                    {
                        label: gettextCatalog.getString('Revoke token'),
                        leftIcon: 'faRedoAlt',
                        action: ()=>revokeToken(apiUser),
                    },
                    {
                        label: gettextCatalog.getString('Delete'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>removeApiUser(apiUser),
                    },
                ];

                apiUser.dropdown = dropdown;
            }
        }

        function copyApiKey (api_key) {
            copyDialogService.open(api_key, gettextCatalog.getString('API Token'));
        }

        function openDialog (apiUser) {
            var params = {
                body: FORM_CONSTANT.FORM_API_USERS,
                size: 'lg',
                data: {
                    apiUser: apiUser,
                },
            };

            /* monDialog.create(
                params,
                function (apiUserUpdate) {
                    if (typeof apiUserUpdate === 'object') {
                        getPage();
                    }
                },
            ); */
            const callback = function (apiUserUpdate) {
                if (typeof apiUserUpdate === 'object') {
                    getPage();
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function revokeToken (apiUser) {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString(
                        'Are you sure you want to revoke the token for API User {{apiUserId}}<br><strong>Note</strong> A new token will be created in it\'s place',
                        {
                            strongBegin: '<strong>',
                            strongEnd: '</strong>',
                            newLine: '<br>',
                            apiUserId: apiUser.id,
                        },
                    ),
                )
                .then(function () {
                    vm.loading = true;
                    vm.promise = ApiUsersRepo.revokeToken(apiUser)
                        .then(function () {
                            getPage();
                        }, angular.noop)
                        .finally(function () {
                            vm.loading = false;
                        });
                }, angular.noop);
        }

        function removeApiUser (apiUser) {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString('Are you sure you want to delete the API User {{apiUserId}}', {
                        apiUserId: apiUser.id,
                    }),
                )
                .then(function () {
                    vm.loading = true;
                    vm.promise = ApiUsersRepo.destroy(apiUser)
                        .then(function () {
                            getPage();
                        }, angular.noop)
                        .finally(function () {
                            vm.loading = false;
                        });
                }, angular.noop);
        }
    }
})();
