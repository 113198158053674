export const PAGE_PERFORMANCE_DISPLAY_MODE_DETAIL_HEADING_TYPE = {
    URL: 'url',
    NODE: 'node',
    TEXT: 'text',
    CODE: 'code',
    NUMERIC: 'numeric',
    THUMBNAIL: 'thumbnail',
    MS: 'ms',
    TIMESPAN_MS: 'timespanMs',
    BYTES: 'bytes',
    LINK: 'link',
} as const;
