(function () {
    'use strict';
    angular.module('modules.heatmaps').factory('HeatmapService', HeatmapService);

    HeatmapService.$inject = ['HEATMAPS_HEATMAP_TYPES', 'ng2MonModalService', 'gettextCatalog'];
    /* @ngInject*/
    function HeatmapService (HEATMAPS_HEATMAP_TYPES, ng2MonModalService, gettextCatalog) {
        return {
            getHeatmapTypeTranslation: getHeatmapTypeTranslation,
            confirmDelete: confirmDelete,
        };

        // ///////////////

        function getHeatmapTypeTranslation (type) {
            switch (type) {
                case HEATMAPS_HEATMAP_TYPES.movement:
                    return gettextCatalog.getString('Movement');
                case HEATMAPS_HEATMAP_TYPES.click:
                    return gettextCatalog.getString('Click');
                case HEATMAPS_HEATMAP_TYPES.scroll:
                    return gettextCatalog.getString('Scroll');
                default:
                    return gettextCatalog.getString('NA');
            }
        }

        function confirmDelete (rule) {
            var translateParams = {
                pageUrl: rule.page ? rule.page.url : gettextCatalog.getString('(No title found)'),
            };
            return ng2MonModalService.confirm(
                gettextCatalog.getString(
                    'Are you sure you want to remove Heatmap Rule from <strong>{{pageUrl}}</strong>',
                    translateParams,
                ),
            );
        }
    }
})();
