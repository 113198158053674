import { User } from '@monsido/ng2/modules/models/api/user';

/*
    This class is only required to output a user state.
    Do not pass it outside of the Users component,
    use the original User model instead
*/
export class UserToOutputModel extends User {

    name = '';
    isVisible = false;
    hasSendReport = false;

}
