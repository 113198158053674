(function () {
    'use strict';

    angular.module('directives.monsido').directive('imageOnload', ImageOnLoadDirective);

    ImageOnLoadDirective.$inject = [];

    /* @ngInject */
    function ImageOnLoadDirective () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.bind('load', function () {
                    // call the function that was passed
                    scope.$apply(attrs.imageOnload);
                });
            },
        };
    }
})();
