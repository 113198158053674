import { Injectable } from '@angular/core';
import { RequestOptionsType } from '@monsido/ng2/external/http/options';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { switchMap, skip, take } from 'rxjs/operators';
import { DefaultLinkExcludeModel } from './default-constraints-and-excludes-models/link-exclude.model';
import { DefaultPathConstraintsModel } from './default-constraints-and-excludes-models/path-constraint.model';
import { DefaultLinkExcludedInterface, DefaultPathConstraintInterface } from './default-constraints-and-excludes.interface';

type RequestType = {
    url: string,
    options?: RequestOptionsType,
}
type DataForCmsType = {
    expires: number;
    pathConstraints?: BehaviorSubject<DefaultPathConstraintInterface[]>;
    linkExcludes?: BehaviorSubject<DefaultLinkExcludedInterface[]>;
}

interface CollectionInterface<T> extends Array<T> {
    currentPage: number;
    perPage: number;
    total: number;
}


@Injectable({
    providedIn: 'root',
})
export class DefaultConstraintsAndExcludesService {
    private cacheLifetime = 43200000; // 12 hours
    private pageSize = 1000;
    private dataForCms: Record<string, DataForCmsType> = {};

    private readonly _loadingPathConstraitsProgress = new Subject<boolean>();
    private readonly _loadingLinksExcludedProgress = new Subject<boolean>();
    private readonly defaultPathConstraintsBs: BehaviorSubject<DefaultPathConstraintInterface[]> = new BehaviorSubject([]);
    private readonly defaultLinkExcludesBs: BehaviorSubject<DefaultLinkExcludedInterface[]> = new BehaviorSubject([]);
    private readonly defaultCms = new BehaviorSubject<string | undefined>(undefined);

    readonly loadingPathConstraitsProgress$ = this._loadingPathConstraitsProgress.asObservable();
    readonly loadingLinksExcludedProgress$ = this._loadingLinksExcludedProgress.asObservable();
    readonly defaultPathConstraints$ = this.defaultPathConstraintsBs.asObservable();
    readonly defaultLinkExcludes$ = this.defaultLinkExcludesBs.asObservable();
    readonly defaultCms$ = this.defaultCms.asObservable();

    constructor (
        private apiClient: ApiClient,
    ) { }

    updateCurrentCms (cms: string): void {
        if (cms) {
            this.getPathConstraintsForCms(cms);
            this.getLinkExcludesForCms(cms);
            this.defaultCms.next(cms);
        } else {
            this.resetValues();
        }
    }

    private getPathConstraintsForCms (cms: string): void {
        const amount = this.dataForCms[cms]?.pathConstraints ? 0 : 1; // Avoid initial value
        this.getAllDataForCms<DefaultPathConstraintInterface>(cms, 'pathConstraints')
            .pipe(
                skip(amount),
                take(1),
            )
            .subscribe(data => {
                this.defaultPathConstraintsBs.next(data);
            });
    }

    private getLinkExcludesForCms (cms: string): void {
        const amount = this.dataForCms[cms]?.linkExcludes ? 0 : 1; // Avoid initial value
        this.getAllDataForCms<DefaultLinkExcludedInterface>(cms, 'linkExcludes')
            .pipe(
                skip(amount),
                take(1),
            )
            .subscribe(data => {
                this.defaultLinkExcludesBs.next(data);
            });
    }

    private resetValues (): void {
        this.defaultPathConstraintsBs.next([]);
        this.defaultLinkExcludesBs.next([]);
    }

    private getAllDataForCms<T> (
        cms: string,
        dataType: 'pathConstraints' | 'linkExcludes',
    ): Observable<T[]> {
        let shouldUpdate = false;
        if (!this.dataForCms[cms]) {
            this.dataForCms[cms] = {
                expires: Date.now() + this.cacheLifetime,
            };
            shouldUpdate = true;
        }
        const entry = this.dataForCms[cms];

        if (!entry[dataType]) {
            shouldUpdate = true;
            entry[dataType] = new BehaviorSubject([]);
        }

        const behavior = entry[dataType] as unknown as BehaviorSubject<T[]>;

        if (!shouldUpdate && entry.expires < Date.now()) {
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            const url = dataType === 'pathConstraints' ? 'path_constraints' : 'link_excludes';
            this.requestAllData(behavior, cms, url, dataType);
        }
        return behavior.asObservable();
    }
    private requestAllData (
        output: BehaviorSubject<unknown[]>,
        cms: string,
        url: string,
        dataType: string,
    ): void {
        let completeData: unknown[] = [];
        const request$ = new Subject<RequestType>();
        const response$ = request$.pipe(
            switchMap((request) => this.apiClient.getObservable(request.url, request.options) as Observable<CollectionInterface<unknown>>),
        );

        if (dataType === 'pathConstraints') {
            this._loadingPathConstraitsProgress.next(true);
        } else {
            this._loadingLinksExcludedProgress.next(true);
        }

        response$.subscribe(data => {
            completeData = completeData.concat(data);
            if (completeData.length < data.total) {
                request$.next({
                    url: url,
                    options: {
                        params: {
                            page_size: String(this.pageSize),
                            page: String(data.currentPage + 1),
                            default_for_cms: cms,
                        },
                    },
                });
            } else {
                if (dataType === 'pathConstraints') {
                    output.next(completeData.map(entry => new DefaultPathConstraintsModel(entry as DefaultPathConstraintInterface, cms)));
                    this._loadingPathConstraitsProgress.next(false);
                } else {
                    output.next(completeData.map(entry => new DefaultLinkExcludeModel(entry as DefaultLinkExcludedInterface, cms)));
                    this._loadingLinksExcludedProgress.next(false);
                }
            }
        });

        request$.next({
            url: url,
            options: {
                params: {
                    page_size: String(this.pageSize),
                    default_for_cms: cms,
                },
            },
        });
    }
}
