import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';
import { PolicyRepoType } from '../policy-repo/policy.types';

@Injectable({
    providedIn: 'root',
})
export class PagePolicyRepoService extends BaseRepo {

    constructor (protected sessionService: SessionService,private apiService: ApiService) {
        super();
    }

    getAll (pageId: number, params): Promise<MonTableCollection<PolicyRepoType>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/pages/' + pageId + '/policies/', params);
    }

    update (pageId: number, params: PolicyRepoType & BaseRepoRequestType): Promise<PolicyRepoType> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.patch(
            'domains/' + this.getDomainId(params.domain_id) + '/pages/' + pageId + '/policies/' + params.id,
            params,
        );
    }

    destroy (pageId: number, id: number): Promise<void> {
        return this.apiService.delete('domains/' + this.getDomainId() + '/pages/' + pageId + '/policies/' + id);
    }
}
