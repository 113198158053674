(function () {
    'use strict';
    /**
     * @memberof modules.cookie_banner.services.api
     * @ngdoc factory
     * @name CookieBannerEntitiesConsentService
     * @description Service to handle Consent Manager Consent Data
     */
    angular.module('modules.cookie_banner.services.api').factory('CookieBannerEntitiesConsentService', CookieBannerEntitiesConsentService);

    CookieBannerEntitiesConsentService.$inject = ['ng2CookieBannerRepoService'];
    /* @ngInject*/
    function CookieBannerEntitiesConsentService (ng2CookieBannerRepoService) {
        return {
            getAll: getAll,
        };

        /**
         * @memberOf CookieBannerEntitiesConsentService
         * @desc Get Consent Data
         * @param {Object} params - Query Parameters
         * @param {Number} config - $http provider configuration
         */
        function getAll (params, config) {
            return ng2CookieBannerRepoService.getConsents(params, config);
        }
    }
})();
