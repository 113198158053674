(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityIssuesPages', {
        templateUrl: 'app/pages/accessibility/issues/pages/pages.html',
        controller: AccessibilityIssuesPagesController,
        controllerAs: 'vm',
        bindings: {
            issue: '<',
            totalPagesCount: '<',
        },
    });

    AccessibilityIssuesPagesController.$inject = [
        'AccessibilityChecksRepo',
        '$controller',
        'monDialog',
        '$filter',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2LabelService',
        'ng2ActiveFeatureService',
    ];
    /* @ngInject */
    function AccessibilityIssuesPagesController (
        AccessibilityChecksRepo,
        $controller,
        monDialog,
        $filter,
        ng2MonEventsService,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2LabelService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.onFiltersChanged = onFiltersChanged;
        vm.onSearchChanged = onSearchChanged;
        vm.$onDestroy = onDestroy;
        vm.createOrUpdateLabel = createOrUpdateLabel;

        function activate () {
            vm.description = null;
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.onPageDetailsClose = angular.noop; // Do nothing when closing page details
            vm.isFiltersOpen = false;
            vm.pages = [];
            vm.totalPageIssues = 0;
            vm.search = null;
            vm.currentSort = { by: 'priority_score', direction: 'desc' };
            vm.errorPercentage = 0;
            vm.compliancePercentage = 0;
            setDescription();
            getPage();
            vm.getLabels();
            vm.exports = [
                {
                    data: {
                        category: 'pages_with_specific_checklist_error',
                        category_ref: vm.issue.id,
                    },
                },
            ];
        }

        function setDescription () {
            AccessibilityChecksRepo.getHelp(vm.issue.id).then(function (data) {
                vm.description = data.short_description;
            }, angular.noop);
        }

        function getPage () {
            vm.loading = true;
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.search && vm.search !== '') {
                params.search = vm.search;
            }

            params = vm.updateParamsWithFilters(params);

            vm.progress = AccessibilityChecksRepo.getAllPages(vm.issue.id, params).then(function (data) {
                vm.pages = data;
                vm.totalPageIssues = parseInt(data.total, 10);
                vm.pagesDone = vm.totalPagesCount - vm.totalPageIssues;
                calculatePercentage();
            }, angular.noop);
        }

        function calculatePercentage () {
            var dividedByPagesDone = (vm.pagesDone / (vm.totalPagesCount || 0)) * 100;
            if (dividedByPagesDone < 0.01 && vm.totalPageIssues) {
                dividedByPagesDone = 0.01;
            } else if (dividedByPagesDone > 99.99 && vm.totalPageIssues > 0) {
                dividedByPagesDone = 99.99;
            }
            vm.compliancePercentage = $filter('monNumber')(dividedByPagesDone, '0.[00]');
            vm.errorPercentage = $filter('monNumber')(100 - dividedByPagesDone, '0.[00]');
        }

        function onFiltersChanged (changes) {
            vm.updateFilters(changes);
            vm.getPage();
        }

        function onSearchChanged (search) {
            vm.search = search;
            vm.getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }

        function createOrUpdateLabel (label) {
            ng2LabelService.createOrUpdateLabel(label);
        }
    }
})();
