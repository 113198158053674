(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc factory
     * @name PagePerformanceScanService
     * @description Page Performance scan function
     */
    angular.module('modules.page-performance').service('PagePerformanceScanService', PagePerformanceScanService);

    PagePerformanceScanService.$inject = ['ng2PagePerformanceRescanRepoService', 'ng2MonModalService', 'gettextCatalog'];

    /* @ngInject*/
    function PagePerformanceScanService (ng2PagePerformanceRescanRepoService, ng2MonModalService, gettextCatalog) {
        var vm = this;
        return {
            performanceRescan: performanceRescan,
        };

        /**
         * @memberOf PagePerformanceScanService
         * @desc Start a page performance scan
         * @param page - Page Object
         * @param profile - ID of the Profile
         */
        function performanceRescan (page, profile) {
            var translation = gettextCatalog.getString(
                'You are about to perform a scan of {{titleTag}}{{pageTitle}}{{titleTagEnd}} using the profile {{titleTag}}{{profileName}}{{titleTagEnd}}.<br>The scan can take a few minutes to finalise, depending on the size of the page.<br><br>Do you want to continue?',
                {
                    titleTag: '<span class=\'text-link\'>',
                    titleTagEnd: '</span>',
                    pageTitle: page.title,
                    profileName: profile.name,
                },
            );
            return ng2MonModalService.confirm(translation).then(function () {
                var params = {
                    page_id: page.id,
                    profile_id: profile.id,
                };
                return ng2PagePerformanceRescanRepoService.create(params);
            });
        }
    }
})();
