(function () {
    'use strict';

    angular.module('modules.export').service('exporterPerformanceParser', exporterPerformanceParser);

    exporterPerformanceParser.$inject = ['$filter', 'gettextCatalog'];
    /* @ngInject */
    function exporterPerformanceParser ($filter, gettextCatalog) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines () {
            var headlines = [
                gettextCatalog.getString('Crawled at'),
                gettextCatalog.getString('Performance Pages'),
                gettextCatalog.getString('Average Performance Score'),
                gettextCatalog.getString('Average Best Practice Score'),
                gettextCatalog.getString('Average SEO Score'),
                gettextCatalog.getString('Average First Contentful Paint'),
                gettextCatalog.getString('Average First Meaningful Paint'),
                gettextCatalog.getString('Average Time to Interactive'),
            ];

            return headlines;
        }

        function parse (data) {
            var csv = [];
            var entry = [];
            if (!data || data.length === 0) {
                return csv;
            }
            csv.push(getHeadlines());

            var currentData;
            for (var i = 0; i < data.length; i++) {
                currentData = data[i];
                entry = [];
                entry.push($filter('userDate')(currentData.post_processing_done_at || currentData.date, 'LL LT'));
                entry.push(currentData.performance_score_average * 100);
                entry.push(currentData.performance_best_practices_score_average * 100);
                entry.push(currentData.performance_seo_score_average * 100);
                entry.push(currentData.performance_first_contentful_paint_average);
                entry.push(currentData.performance_first_meaningful_paint_average);
                entry.push(currentData.performance_time_to_interactive_average);

                csv.push(entry);
            }
            return csv;
        }
    }
})();
