(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.dataGatherer').factory('analyticsService', analyticsService);

    analyticsService.$inject = [];
    /* @ngInject*/
    function analyticsService () {
        return {
            trackPage: trackPage,
        };

        /**
         *
         * @param url
         */
        function trackPage (url) {
            // if (analytics.ga_id) {
            //     Analytics.trackPage(url);
            // }
        }
    }
})();
