(function () {
    'use strict';

    /**
     * @memberof modules.accessibility
     * @ngdoc factory
     * @name AccessibilityDialogsNewHelpCenterDecisionTreeService
     * @description Service that contains all the Logic for the Decision Tree Component.
     */
    angular
        .module('modules.accessibility')
        .service(
            'AccessibilityDialogsNewHelpCenterDecisionTreeService',
            AccessibilityDialogsNewHelpCenterDecisionTreeService,
        );

    AccessibilityDialogsNewHelpCenterDecisionTreeService.$inject = ['Lodash', '$deepmerge', 'ng2GlobalHelperService'];
    /* @ngInject*/
    function AccessibilityDialogsNewHelpCenterDecisionTreeService (Lodash, $deepmerge, ng2GlobalHelperService) {
        var vm = this;
        vm.decisionTree = {
            check_ids: [],
            questions: [],
            results: [],
        };

        return {
            setQuestions: setQuestions,
            addResult: addResult,
            getLatestResult: getLatestResult,
            getAllResults: getAllResults,
            getActiveQuestion: getActiveQuestion,
            resetToResult: resetToResult,
        };

        /**
         * @memberOf AccessibilityDialogsNewHelpCenterDecisionTreeService
         * @desc Replace the questions stack with questions provided from argument
         * @param questions - Questions Collection to use in the Decision Tree
         */
        function setQuestions (questions) {
            vm.decisionTree.questions = questions;
            vm.decisionTree.results = [];
        }

        /**
         * @memberOf AccessibilityDialogsNewHelpCenterDecisionTreeService
         * @desc Add question to the Result stack, will also contain the answer property the User selected
         * @param question - Question Object
         * @param answerText - Text of the answer
         * @param {String|Object} answer - Selected Answer from the User.
         */
        function addResult (question, answer, answerText) {
            var copy = $deepmerge({}, question);
            copy.answer = answer;
            copy.answerText = answerText;
            vm.decisionTree.results.push(copy);
        }

        /**
         * @memberOf AccessibilityDialogsNewHelpCenterDecisionTreeService
         * @desc Get entire Results stack from the Decision Tree
         */
        function getAllResults () {
            return vm.decisionTree.results;
        }

        /**
         * @memberOf AccessibilityDialogsNewHelpCenterDecisionTreeService
         * @desc Return the Latest Result item from the Result Stack
         */
        function getLatestResult () {
            return Lodash.last(vm.decisionTree.results);
        }

        /**
         * @memberOf AccessibilityDialogsNewHelpCenterDecisionTreeService
         * @param result - Result Object
         * @desc Remove all Result items from specified result to end of result stack
         */
        function resetToResult (result) {
            var stringifiedResultItem = ng2GlobalHelperService.stringify(result);
            var index = Lodash.findIndex(vm.decisionTree.results, function (resultItem) {
                return ng2GlobalHelperService.stringify(resultItem) === stringifiedResultItem;
            });

            if (index > -1) {
                vm.decisionTree.results.splice(index, vm.decisionTree.results.length - index);
            }
        }

        /**
         * @memberOf AccessibilityDialogsNewHelpCenterDecisionTreeService
         * @desc Return the Question that should be answered in the Decision Tree
         */
        function getActiveQuestion () {
            var result = getLatestResult();
            if (typeof result === 'undefined') {
                return vm.decisionTree.questions[0];
            }

            if (typeof result.answer !== 'string') {
                return null;
            }

            return Lodash.find(vm.decisionTree.questions, { id: result.answer });
        }
    }
})();
