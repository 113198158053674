(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.business-intelligence.services').factory('virtualUrlService', virtualUrlService);

    virtualUrlService.$inject = ['VIRTUAL_URLS'];
    /* @ngInject*/
    function virtualUrlService (VIRTUAL_URLS) {
        return {
            get: get,
            getParams: getParams,
            getTitle: getTitle,
            getRef: getRef,
        };

        function get (url, params) {
            var virUrl = VIRTUAL_URLS[url];
            if (!virUrl) {
                throw 'Virtual url is missing: ' + url;
            }
            var newUrl = virUrl.url;
            if (newUrl) {
                return newUrl + getSubUrl(url, params);
            }
        }

        function getSubUrl (url, params) {
            if (url === 'pageDetails') {
                return params.data.page.url + ' ( ' + params.data.page.title + ' )';
            }

            return '';
        }

        function getRef (params) {
            if (params.data && params.data.ref) {
                return params.data.ref;
            }
            if (params.ref) {
                return params.ref;
            }
        }

        function getParams () {}

        function getTitle () {}
    }
})();
