(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityIssuesTable', {
        templateUrl: 'app/pages/accessibility/issues/table/table.html',
        controller: AccessibilityIssuesTableController,
        controllerAs: 'vm',
        bindings: {
            abbr: '@',
            labels: '<',
            selectedLabels: '<',
            selectedResponsibility: '<',
            getLabels: '&',
            theme: '@?',
            resultTypes: '<',
            header: '@?',
            faIcon: '@?',
            activeTab: '<',
            search: '<',
            pageCount: '<',
        },
    });

    AccessibilityIssuesTableController.$inject = [
        'ng2SessionService',
        'accessibilityService',
        'AccessibilityChecksRepo',
        '$q',
        'ng2MonModalService',
        'gettextCatalog',
        '$scope',
        'ng2AuditRepoService',
        'ng2GlobalAccessibilityChecksRepoService',
        'ng2MonEventsService',
        'MON_EVENTS',
    ];

    function AccessibilityIssuesTableController (
        ng2SessionService,
        accessibilityService,
        AccessibilityChecksRepo,
        $q,
        ng2MonModalService,
        gettextCatalog,
        $scope,
        ng2AuditRepoService,
        ng2GlobalAccessibilityChecksRepoService,
        ng2MonEventsService,
        MON_EVENTS,
    ) {
        var vm = this;
        var promiseContinue;
        vm.$onInit = activate;
        vm.ignoreThisIssue = ignoreThisIssue;
        vm.goToPages = goToPages;
        vm.getPage = getPage;
        vm.isActive = isActive;
        vm.$onDestroy = onDestroy;
        vm.hasIgnoredNote = hasIgnoredNote;
        vm.updateLabels = updateLabels;

        function activate () {
            vm.loading = false;
            vm.issues = [];
            promiseContinue = true;
            vm.resultTypes = vm.resultTypes || [];
            vm.pageCount = vm.pageCount || 0;
            vm.groupId = ng2SessionService.getGroupId();
            getPage();
            setupWatchers();
            setNoResultMessage();
        }

        function setNoResultMessage () {
            switch (vm.activeTab) {
                case 'ignored':
                    vm.noResultMessage = gettextCatalog.getString('No ignored checks in this accessibility category');
                    break;
                case 'passed':
                    vm.noResultMessage = gettextCatalog.getString('No passed checks in this accessibility category');
                    break;
                default:
                    vm.noResultMessage = gettextCatalog.getString('No checks found for this Accessibility category');
                    break;
            }
        }

        function setupWatchers () {
            $scope.$watch('vm.resultTypes', onExecuteWatch);
            $scope.$watch('vm.activeTab', onExecuteWatch);
        }

        function onExecuteWatch (newVal, oldVal) {
            if (newVal !== oldVal) {
                setNoResultMessage();
                getPage();
            }
        }

        function onDestroy () {
            promiseContinue = false;
        }

        function getPage () {
            if (!isActive()) {
                return;
            }
            var params = {
                abbr: vm.abbr,
            };

            params['labels[]'] = vm.selectedLabels;
            if (vm.selectedResponsibility) {
                params.responsibility = vm.selectedResponsibility;
            }

            vm.loading = true;
            vm.progress = AccessibilityChecksRepo.getAll(params)
                .then(function (checks) {
                    var chainedChecks = $q.when();
                    var chainedAuditLogs = $q.when();
                    vm.issues = filterIssues(checks);
                    vm.issues.forEach(function (check, index) {
                        if (check.pages_count === null) {
                            chainedChecks = chainedChecks.then(function () {
                                return getPageCount(index);
                            });
                        }
                        if (check.ignored) {
                            chainedAuditLogs = chainedAuditLogs.then(function () {
                                return getAuditLog(check);
                            });
                        }
                    });
                    updateDropdowns();
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getPageCount (index) {
            var issue = vm.issues[index];
            var params = {
                page_size: 1,
            };

            if (angular.isUndefined(issue)) {
                // If a new search been started, there's a possibility issue is undefined
                return;
            }

            if (issue.ignored || issue.errors_count === 0 || !promiseContinue) {
                vm.issues[index].pages_count = 0;
                updateDropdown(index);
                return;
            }

            return AccessibilityChecksRepo.getAllPages(issue.id, params).then(function (pages) {
                if (issue === vm.issues[index]) {
                    // Make sure that a new search hasn't been started in the meantime
                    vm.issues[index].pages_count = parseInt(pages.total) || 0;
                    updateDropdown(index);
                }
            }, angular.noop);
        }

        function ignoreThisIssue (issue, index) {
            const ignored = !issue.ignored;

            let confirmText = `<strong>${issue.name}</strong>`;
            confirmText += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');

            ng2MonModalService.prompt(confirmText).then(function (comment) {
                const params = { ignored, comment };

                if (vm.groupId != null) {
                    params.group_id = vm.groupId;
                }
                vm.loading = true;

                accessibilityService.updateCheck(issue.id, params)
                    .then(
                        function () {
                            vm.issues[index].ignored = ignored;
                            vm.issues = filterIssues(vm.issues);
                            updateDropdowns();
                        },
                        angular.noop,
                    )
                    .finally(() => vm.loading = false);
            }, angular.noop);
        }

        function goToPages (issue) {
            var params = {
                size: 'lg',
                body: 'accessibilityIssuesPages',
                data: { issue: issue, totalPagesCount: vm.pageCount },
            };

            const options = {
                params,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, options, function () {
                var issueIndex = vm.issues.indexOf(issue);
                if (issueIndex !== -1) {
                    getPageCount(issueIndex);
                }
            });
        }

        function isActive () {
            return [vm.abbr, 'ignored', 'passed'].indexOf(vm.activeTab) > -1;
        }

        function updateDropdown (index) {
            const issue = vm.issues[index];

            const dropdown = [
                {
                    label: gettextCatalog.getString('Unignore this check'),
                    leftIcon: 'faEye',
                    action: () => ignoreThisIssue(issue, index),
                    tooltip: vm.groupId == null
                        ? undefined
                        : gettextCatalog.getString(
                            'Accessibility checks cannot be unignored for domain groups. To unignore this check you must do it for the entire domain.',
                        ),
                    shouldShow: issue.ignored,
                },
                {
                    label: gettextCatalog.getString('Ignore this check'),
                    leftIcon: 'faEyeSlash',
                    action: () => ignoreThisIssue(issue, index),
                    tooltip: vm.groupId == null
                        ? undefined
                        : gettextCatalog.getString(
                            'Accessibility checks cannot be ignored for domain groups. To ignore this check you must do it for the entire domain.',
                        ),
                    shouldShow: !issue.ignored,
                },
            ];

            vm.issues[index].dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item =>{
                return {
                    label: item.label,
                    leftIcon: item.leftIcon,
                    action: item.action,
                    disabled: vm.groupId != null,
                    tooltip: item.tooltip,
                };
            });
        }

        function updateDropdowns () {
            for (let i = 0; i < vm.issues.length; i++) {
                updateDropdown(i);
            }
        }

        function filterIssues (issues) {
            var isFilteringMobileIssues = vm.resultTypes.indexOf('mobile') > -1;
            var selectedResultTypes = vm.resultTypes.filter(function (type) {
                return type !== 'mobile';
            });

            return issues
                .filter(function (issue) {
                    var isMatchMobileFilterCriteria = !isFilteringMobileIssues || (isFilteringMobileIssues && issue.mobile === true);
                    var isPartOfResultType = selectedResultTypes.length === 0 || selectedResultTypes.indexOf(issue.result_type) > -1;

                    return isPartOfResultType && isPartOfTab(issue) && isMatchMobileFilterCriteria;
                })
                .sort(function (a, b) {
                    if (a.errors_count !== b.errors_count) {
                        return a.errors_count > b.errors_count ? -1 : 1;
                    }
                    if (a.issueNumberEscaped > b.issueNumberEscaped) {
                        return -1;
                    } else if (a.issueNumberEscaped < b.issueNumberEscaped) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
        }

        function isPartOfTab (check) {
            switch (vm.activeTab) {
                case 'ignored':
                    return check.ignored;
                case 'passed':
                    return !check.ignored && (check.errors_count === 0 || check.pages_count === 0);
                default:
                    return !check.ignored && (check.errors_count > 0 || check.pages_count > 0);
            }
        }

        function hasIgnoredNote (check) {
            if (check.auditLog) {
                return (
                    check.auditLog.action === 'ignored' &&
                    check.auditLog.data &&
                    check.auditLog.data.comment &&
                    check.auditLog.data.comment.trim().length > 0
                );
            } else {
                return false;
            }
        }

        function getAuditLog (check) {
            var params = {
                target: 'AccessibilityCheck:' + check.id,
                domain_id: ng2SessionService.domain.id,
                page: 1,
                page_size: 5,
            };
            return ng2AuditRepoService.getAuditLogs(params).then(function (logs) {
                if (check && logs.length > 0) {
                    var log = logs.find(function (log) {
                        return log.action === 'ignored';
                    });
                    if (log) {
                        check.auditLog = log;
                    }
                }
            }, angular.noop);
        }

        function updateLabels (accessibilityCheck) {
            var params = {
                id: accessibilityCheck.id,
                labels: accessibilityCheck.labels.map(function (label) {
                    return label.id;
                }),
            };

            ng2GlobalAccessibilityChecksRepoService.update(params)
                .then(angular.noop, angular.noop);
        }
    }
})();
