(function () {
    'use strict';
    angular.module('pages.global.changesHistory').service('ChangesHistoryService', ChangesHistoryService);

    ChangesHistoryService.$inject = [
        'gettextCatalog',
        'ng2SessionService',
        '$filter',
        'MON_MODULES',
        'Lodash',
        'rx',
        'ng2MonEventsService',
        '$timeout',
        'ng2ActiveFeatureService',
        'ng2DomainsService',
        'ng2TotalDomainsService',
    ];
    /* @ngInject*/
    function ChangesHistoryService (
        gettextCatalog,
        ng2SessionService,
        $filter,
        MON_MODULES,
        Lodash,
        rx,
        ng2MonEventsService,
        $timeout,
        ng2ActiveFeatureService,
        ng2DomainsService,
        ng2TotalDomainsService,
    ) {
        var vm = this;
        vm.modules = [];
        vm.activeModule = 'all';
        vm.domains = null;
        vm.domain = null;
        vm.domainGroup = null;
        vm.pagesStack = [];

        var service = {
            getDomainFromSelectorId: getDomainFromSelectorId,
            setModule: setModule,
            setHistory: setHistory,
            getModules: getModules,
            getModule: getModule,
            getModuleTranslation: getModuleTranslation,
            setDomain: setDomain,
            setDate: setDate,
            getHistoryCenterParameters: getHistoryCenterParameters,
            domainsObs: new rx.BehaviorSubject(null),
        };

        function init () {
            ng2TotalDomainsService.domainsEssential.subscribe(function (domains) {
                $timeout(function () {
                    var setupDomainsCollection = setupDomains(domains);
                    vm.domains = setupDomainsCollection;
                    service.domainsObs.onNext(setupDomainsCollection);
                }, 750);
            });
            return service;
        }

        return init();

        // ///////////////

        function setDate (date) {
            ng2MonEventsService.run('hcSetDate', date);
        }

        function setDomain (domain, domainGroup) {
            vm.domain = domain;
            vm.domainGroup = domainGroup;
            ng2MonEventsService.run('hcSetDomain', domain, domainGroup);
            setupModules();
        }

        function setModule (module) {
            vm.activeModule = module || 'all';
            ng2MonEventsService.run('hcSetModule', vm.activeModule);
            setPagesStack();
        }

        function setHistory (history) {
            ng2MonEventsService.run('hcSetHistory', history);
        }

        function getModules () {
            return Lodash.filter(vm.modules, 'canAccess');
        }

        function getModule () {
            var canUseModule = Lodash.find(vm.modules, { value: vm.activeModule, canAccess: true });
            if (typeof canUseModule !== 'undefined') {
                return vm.activeModule;
            }
            return 'all';
        }

        function getModuleTranslation () {
            var module = Lodash.find(vm.modules, { value: vm.activeModule });
            if (typeof module !== 'undefined') {
                return module.name;
            }
        }

        function getDomainFromSelectorId (selectorId) {
            if (vm.domains.length === 1) {
                return vm.domains[0];
            } else if (selectorId !== null) {
                return Lodash.find(vm.domains, { selectorId: selectorId });
            }

            return null;
        }

        // PROTECTED

        function setupModules () {
            var user = ng2SessionService.me;
            var hasAccessibility = false;
            var hasReadability = false;
            var hasDataPrivacy = false;
            var hasPerformance = false;
            if (vm.domain) {
                hasAccessibility = Boolean(vm.domain.features.accessibility);
                hasReadability = Boolean(vm.domain.features.readabilityTest);
                hasPerformance = ng2ActiveFeatureService.isFeatureActive('page_performance', vm.domain);
                hasDataPrivacy = Boolean(vm.domain.features.dataProtection);
            }
            vm.modules = [
                {
                    name: gettextCatalog.getString('All modules - Content with Issues'),
                    value: 'all',
                    canAccess: true,
                },
                {
                    name: gettextCatalog.getString('SEO'),
                    value: 'seo',
                    icon: $filter('monIcons')(['MENUES', 'SEO']),
                    canAccess: $filter('hasAccess')(user, MON_MODULES.seo) && ng2ActiveFeatureService.isFeatureActivePure('seo_all'),
                },
                {
                    name: gettextCatalog.getString('Quality Assurance'),
                    value: 'qa',
                    icon: $filter('monIcons')(['MENUES', 'QA']),
                    canAccess: $filter('hasAccess')(user, MON_MODULES.qa) && ng2ActiveFeatureService.isFeatureActivePure('qa_module'),
                },
                {
                    name: gettextCatalog.getString('Policies'),
                    value: 'policy',
                    icon: $filter('monIcons')(['MENUES', 'POLICIES']),
                    canAccess: $filter('hasAccess')(user, MON_MODULES.policies) && ng2ActiveFeatureService.isFeatureActivePure('policies_all'),
                },
                {
                    name: gettextCatalog.getString('Accessibility'),
                    value: 'accessibility',
                    icon: $filter('monIcons')(['MENUES', 'ACCESSIBILITY']),
                    canAccess: hasAccessibility && $filter('hasAccess')(user, MON_MODULES.accessibility),
                },
                {
                    name: gettextCatalog.getString('Readability'),
                    value: 'readability',
                    icon: $filter('monIcons')(['QA', 'READABILITY']),
                    canAccess: hasReadability && ng2ActiveFeatureService.isFeatureActivePure('admin_readability'),
                },
                {
                    name: gettextCatalog.getString('Data Privacy'),
                    value: 'data-privacy',
                    icon: $filter('monIcons')(['MENUES', 'DATA_PRIVACY']),
                    canAccess: hasDataPrivacy && ng2ActiveFeatureService.isFeatureActive(MON_MODULES.data_privacy, vm.domain),
                },
                {
                    name: gettextCatalog.getString('Performance'),
                    value: 'performance',
                    icon: $filter('monIcons')(['MENUES', 'PAGE_PERFORMANCE']),
                    canAccess: hasPerformance && $filter('hasAccess')(user, MON_MODULES.performance),
                },
            ];
        }

        function setupDomains (domains) {
            if (Array.isArray(domains) && domains.length > 0) {
                return ng2DomainsService.flattenEssentialDomainData(domains).map(function (domain) {
                    domain.selectorId = 'domain_' + domain.id;
                    if (domain.type === 'DomainGroup') {
                        domain.selectorId = 'domain_group_' + domain.id;
                    }
                    return domain;
                });
            } else {
                return [];
            }
        }

        function setPagesStack () {
            var stack = ['page_count'];

            if (vm.activeModule !== 'seo' && vm.activeModule !== 'accessibility') {
                stack = ['page_count', 'documents_count'];
            }

            if (vm.activeModule === 'performance') {
                stack = ['performance_page_count'];
            }

            ng2MonEventsService.run('hcSetPagesStack', stack);
        }

        function getHistoryCenterParameters (module) {
            var params = {
                module: module,
            };
            var domainId = ng2SessionService.getDomainId();
            var domainGroup = ng2SessionService.domainGroup;

            if (Number.isInteger(domainId)) {
                params.domain_id = domainId;
            }

            if (domainGroup !== null) {
                params.group_id = domainGroup.id;
            }

            return params;
        }
    }
})();
