(function () {
    'use strict';

    angular.module('filters.monsido').filter('responsetime', responsetime);

    responsetime.$inject = ['RESPONSE_TIME'];
    /* @ngInject */
    function responsetime (RESPONSE_TIME) {
        return responsetime;

        // //////////

        function responsetime (responsetime) {
            if (parseFloat(responsetime) <= RESPONSE_TIME.SECONDS.OK.TO) {
                return RESPONSE_TIME.SECONDS.OK.COLORCLASS;
            }

            if (parseFloat(responsetime) <= RESPONSE_TIME.SECONDS.WARNING.TO) {
                return RESPONSE_TIME.SECONDS.WARNING.COLORCLASS;
            }
            return RESPONSE_TIME.SECONDS.ERROR.COLORCLASS;
        }
    }
})();
