(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardComplianceDoughnutProgressBar', {
        templateUrl: 'app/pages/accessibility/dashboard/compliance/doughnut/progressbar/progressbar.html',
        controller: AccessibilityProgressbarController,
        controllerAs: 'vm',
        bindings: {
            max: '<',
            value: '<',
        },
    });

    AccessibilityProgressbarController.$inject = [];
    /* @ngInject */
    function AccessibilityProgressbarController () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {}
    }
})();
