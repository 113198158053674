(function () {
    'use strict';

    angular.module('pages.seo').run(appRun);

    appRun.$inject = ['routerHelper', 'RouteHelperService'];
    /* @ngInject */
    function appRun (routerHelper, RouteHelperService) {
        routerHelper.configureStates(getStates());
        function getStates () {
            return [
                RouteHelperService.makeRouteWithHasAccess({
                    name1: 'domain',
                    name2: 'seo',
                    url: '/seo',
                    featureName: 'seo_all',
                    redirectURI: 'base.customer.domain.dashboard',
                    abstract: true,
                    sidebar: 'seo',
                }),
                ...[
                    ['pages', '<seo-pages></seo-pages>', '/pages-with-opportunities'],
                    ['summary', '<seo-summary></seo-summary>', '/'],
                    ['issues', '<seo-dashboard-issues></seo-dashboard-issues>', '/checkpoints'],
                ].map((type) => RouteHelperService.makeRoute({
                    name1: 'domain',
                    name2: `seo.${type[0]}`,
                    template: `${type[1]}`,
                    url: `${type[2]}`,
                })),
            ];
        }
    }
})();
