(function () {
    'use strict';

    angular.module('app.components.documentDetails', [
        'components.templates',
        'ui.bootstrap.tpls',
        'modules.modals',
        'services.api',
        'app.components.table',
        'app.components.tabs',
        'app.components.formBuilder',
        'app.components.layout',
        'app.components.pages',
        'app.components.charts',
        'app.components.panels',
    ]);
})();
