type Window = {
    MonConsentMessageEncoder: {
        ConsentMessage: unknown;
        ConsentTcMessage: unknown;
        MessageEncoder: {
            tcStringDecode: unknown;
            tcStringEncode: unknown;
        }
    }
};

export const MON_CONSENT_MESSAGE_ENCODER = (window as unknown as Window).MonConsentMessageEncoder;
