export const RISKS = [
    {
        name: 'High',
        value: 'high',
    },
    {
        name: 'Medium',
        value: 'medium',
    },
    {
        name: 'Low',
        value: 'low',
    },
];

