import { Component, Input, OnInit } from '@angular/core';
import { IssueStatusEnum } from 'app/modules/common/enum/issue-status.enum';
import { TranslateService } from '@client/app/services/translate/translate.service';

interface StatusOptions {
    icon: string[];
    show: boolean;
    text: string;
    reason?: string | null;
}

@Component({
    selector: 'mon-issue',
    templateUrl: './issue.component.html',
    styleUrls: ['./issue.component.scss'],
})
export class IssueComponent implements OnInit {
    @Input() id?: number;
    @Input() title: string = '';
    @Input() issueNotFound?: boolean;
    @Input() reason: string = '';
    @Input() set status (newStatus: string) {
        this.currentStatus = newStatus;
        switch (newStatus) {
            case IssueStatusEnum.fixed:
                this.statusOptions = {
                    icon: ['PLANS', 'ACTIVE'],
                    show: true,
                    text: this.translateService.getString('This issue is marked as fixed.'),
                    reason: null,
                };
                break;

            case IssueStatusEnum.ignored:
                this.statusOptions = {
                    icon: ['ACTIONS', 'IGNORE'],
                    show: true,
                    text: this.translateService.getString('This issue is marked as ignored.'),
                    reason: this.reason,
                };
                break;

            case 'in-dictionary':
                this.statusOptions = {
                    icon: ['SUPPORT_CENTER', 'ARTICLES', 'TITLE'],
                    show: true,
                    text: this.translateService.getString('This word has been added to your dictionary'),
                };
                break;
            case 'reviewed':
                this.statusOptions = {
                    icon: ['DOMAINS', 'FEATURES', 'READABILITY_TEST'],
                    show: true,
                    text: this.translateService.getString('This issue is marked as reviewed.'),
                    reason: this.reason,
                };
                break;
            default:
                this.statusOptions = {
                    icon: [''],
                    show: false,
                    text: '',
                };
                break;
        }
    }
    get status (): string {
        return this.currentStatus;
    }

    subHeader: string = '';
    currentUrl: string = window.location.href;
    currentStatus: string = '';
    statusOptions: StatusOptions = {
        icon: [''],
        show: false,
        text: '',
    };
    issueNotFoundTitle: string;

    constructor (private translateService: TranslateService) {
        this.issueNotFoundTitle = this.translateService.getString('Issue not found');
    }

    ngOnInit (): void {
        this.subHeader = 'ID: ' + this.id;
    }
}
