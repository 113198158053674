(function () {
    'use strict';

    angular.module('pages.base').component('customerPicker', {
        templateUrl: 'app/pages/base/customer-picker/customer-picker.html',
        controller: CustomerPickerController,
        controllerAs: 'vm',
    });

    CustomerPickerController.$inject = [
        '$state',
        'ng2SessionService',
        'ng2AgreementRepoService',
        'ng2MonLoading',
        'Lodash',
        'ng2CoreLoginService',
        'ng2CoreAgreementService',
        'ng2MonEventsService',
        'ng2MonUIRouterTransitionService',
    ];

    /* @ngInject */
    function CustomerPickerController (
        $state,
        ng2SessionService,
        ng2AgreementRepoService,
        monLoading,
        Lodash,
        ng2CoreLoginService,
        coreAgreementService,
        ng2MonEventsService,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;

        vm.selectAgreement = selectAgreement;
        vm.filterAgreements = filterAgreements;
        vm.onSearch = onSearch;
        vm.$onInit = activate;

        function activate () {
            vm.showAgreements = false;
            monLoading.show();
            ng2AgreementRepoService.getAll().then(function (agreements) {
                if (agreements.length === 1) {
                    ng2CoreLoginService.getUser().then(function () {
                        coreAgreementService.setAgreement(agreements[0]).then(function () {
                            coreAgreementService.setAgreements(agreements);
                            ng2MonUIRouterTransitionService.onTransitionFinished(
                                () => $state.go('base.customer.dashboard', { customerId: agreements[0].account.id }),
                            );
                        }, angular.noop);
                    });
                } else {
                    monLoading.hide();
                    vm.showAgreements = true;
                }
                vm.agreements = agreements.filter(function (agreement) {
                    return agreement.support === false;
                });
            });
        }

        function onSearch (search) {
            vm.search = search;
        }

        function filterAgreements () {
            return vm.agreements.filter(function (row) {
                if (vm.search) {
                    return Lodash.includes(row.account.name.toLowerCase(), vm.search.toLowerCase());
                }
                return true;
            });
        }

        function selectAgreement (agreement) {
            ng2CoreLoginService.getUser().then(function () {
                coreAgreementService.setAgreement(agreement).then(
                    function () {
                        coreAgreementService.setAgreements(vm.agreements);
                        ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.dashboard', { customerId: agreement.account.id }).finally(function () {
                                ng2MonEventsService.run('setAgreementNavigationCompleted');
                            }),
                        );
                    },
                    function () {},
                );
            });
        }
    }
})();
