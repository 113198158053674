(function () {
    'use strict';

    angular.module('modules.statistics').component('statisticsDistribution', {
        templateUrl: 'app/modules/statistics/components/statistics-distribution/statistics-distribution.html',
        controller: VisitsComponent,
        controllerAs: 'vm',
        bindings: {
            visits: '<',
            totalVisits: '<',
            removeNumber: '<?',
        },
    });

    VisitsComponent.$inject = [];
    /* @ngInject */
    function VisitsComponent () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.visits = parseInt(vm.visits);
            vm.totalVisits = parseInt(vm.totalVisits);
        }
    }
})();
