import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.source-code-excludes').component('adminSourceCodeExcludes', {
        templateUrl: 'app/modules/source-code-excludes/pages/dashboard/dashboard.html',
        controller: DomainsController,
        controllerAs: 'vm',
    });

    DomainsController.$inject = [
        'ParamService',
        '$controller',
        'SourceCodeExcludesApiService',
        'ng2MonEventsService',
        'SourceCodeExcludesService',
        'FORM_CONSTANT',
        'gettextCatalog',
        'ng2MonModalService',
    ];

    /* @ngInject */
    function DomainsController (
        ParamService,
        $controller,
        SourceCodeExcludesApiService,
        ng2MonEventsService,
        SourceCodeExcludesService,
        FORM_CONSTANT,
        gettextCatalog,
        ng2MonModalService,
    ) {
        var vm = this;
        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.openDialog = openDialog;
        vm.deleteSourceCodeExclude = deleteSourceCodeExclude;
        vm.isAccessibility = SourceCodeExcludesService.isAccessibility;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.sourceCodeExcludes = [];
            vm.tabs = SourceCodeExcludesService.getTabs();
            vm.activeTab = SourceCodeExcludesService.getDefaultTab();
            getPage();
        }

        function getPage () {
            ParamService.setParams({
                page_size: vm.pageSize,
                page: vm.page,
            });

            getSourceCodeExcludes();
        }

        function getSourceCodeExcludes () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
            };
            vm.promise = SourceCodeExcludesApiService.getAll(vm.activeTab, params).then(function (excludes) {
                vm.sourceCodeExcludes = excludes;
                updateDropdowns();
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const sourceCodeExclude of vm.sourceCodeExcludes) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Edit'),
                        leftIcon: 'faCog',
                        action: ()=>openDialog(sourceCodeExclude),
                        eventParams: { action: 'source-code-exclude-edit' },
                    },
                    {
                        label: gettextCatalog.getString('Delete'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>{
                            const message = gettextCatalog.getString('Are you sure you want to delete this source exclude?');
                            ng2MonModalService.confirm(message).then(()=>deleteSourceCodeExclude(sourceCodeExclude));
                        },
                        eventParams: { action: 'source-code-exclude-delete' },
                    },
                ];

                sourceCodeExclude.dropdown = dropdown.map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);

                            item.action();
                        },
                    };
                });
            }
        }

        function openDialog (sourceCodeExclude) {
            var params = {
                body: FORM_CONSTANT.FORM_SOURCE_CODE_EXCLUDE,
                size: 'lg',
                data: {
                    sourceCodeExclude: sourceCodeExclude,
                    module: vm.activeTab,
                },
            };
            const callback = function (updatedSourceCodeExclude) {
                if (typeof updatedSourceCodeExclude === 'object') {
                    getPage();
                }
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function deleteSourceCodeExclude (sourceCodeExclude) {
            SourceCodeExcludesApiService.destroy(vm.activeTab, sourceCodeExclude).then(function () {
                getPage();
            }, angular.noop);
        }
    }
})();
