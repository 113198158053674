(function () {
    'use strict';

    angular.module('modules.light.pages.domain').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog', 'ng2MonEventsService'];

    /* @ngInject */
    function appRun (routerHelper, gettextCatalog, ng2MonEventsService) {
        routerHelper.configureStates(getStates(gettextCatalog, ng2MonEventsService));
    }

    function getStates (gettextCatalog, ng2MonEventsService) {
        return [
            {
                state: 'base.customer.light.domain',
                config: {
                    url: '/{domainId}',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                    resolve: {
                        validate: validateDomain,
                    },
                    onExit: function () {
                        return ng2MonEventsService.run('exitDomain');
                    },
                    onEnter: function () {
                        return ng2MonEventsService.run('afterDomain').then(function () {
                            return ng2MonEventsService.run('finishDomain');
                        });
                    },
                },
            },
        ];
    }

    var validateDomain = [
        '$q',
        'ng2SessionService',
        '$state',
        '$stateParams',
        'ng2DomainRepoService',
        'ng2MonEventsService',
        'ng2CoreDomainService',
        'ng2MonUIRouterTransitionService',
        function (
            $q,
            ng2SessionService,
            $state,
            $stateParams,
            ng2DomainRepoService,
            ng2MonEventsService,
            ng2CoreDomainService,
            ng2MonUIRouterTransitionService,
        ) {
            return ng2MonEventsService.run('beforeDomain').then(function () {
                var deferred = $q.defer();
                if (ng2SessionService.domain === null || ng2SessionService.domain.id !== $stateParams.domainId) {
                    deferred.resolve(
                        ng2DomainRepoService.get($stateParams.domainId).then(
                            function (domain) {
                                ng2CoreDomainService.setDomain(domain);
                                ng2MonEventsService.run('changed-domain');
                            },
                            function () {
                                return ng2MonUIRouterTransitionService.onTransitionFinished(
                                    () => $state.go('base.customer.light.domains'),
                                );
                            },
                        ),
                    );
                } else {
                    deferred.resolve(true);
                }
                return deferred.promise;
            });
        },
    ];
})();
