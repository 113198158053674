(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSeoTable', {
        templateUrl: 'app/components/pagedetails/details/sections/seo/table/table.html',
        controller: SectionSeoTableController,
        controllerAs: 'vm',
        bindings: {
            onChangePageSeoIssue: '&',
            pageSeoIssues: '<',
            selectedSeoIssue: '<',
            progress: '<',
        },
    });

    SectionSeoTableController.$inject = ['gettextCatalog'];

    function SectionSeoTableController (gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.onTabChange = onTabChange;
        vm.setTitleClass = setTitleClass;
        vm.getSeoIssues = getSeoIssues;

        function activate () {
            vm.target = 'seo';
            vm.classificationColors = {
                1: 'danger',
                2: 'warning',
                3: 'link',
                4: 'primary',
                ignored: 'grey',
            };
            setupTabs();
        }

        function setupTabs () {
            vm.tabOptions = [
                {
                    name: gettextCatalog.getString('All'),
                    value: null,
                },
                {
                    name: gettextCatalog.getString('High'),
                    value: 1,
                },
                {
                    name: gettextCatalog.getString('Medium'),
                    value: 2,
                },
                {
                    name: gettextCatalog.getString('Low'),
                    value: 3,
                },
                {
                    name: gettextCatalog.getString('Technical'),
                    value: 4,
                },
            ];

            vm.tab = vm.tabOptions[0].value;
        }

        function setTitleClass (seo) {
            return {
                'text-link': !seo.ignored_on_page && !seo.fixed,
                'text-grey': seo.ignored_on_page || seo.fixed,
            };
        }

        function onTabChange (tab) {
            vm.tab = tab;

            var seoIssues = getSeoIssues();
            var seoIssue = seoIssues[0];
            if (angular.isObject(vm.selectedSeoIssue)) {
                seoIssue = seoIssues.find(function (seo) {
                    return seo.id === vm.selectedSeoIssue.id;
                });
                seoIssue = seoIssue || seoIssues[0];
            }
            vm.onChangePageSeoIssue({ pageSeoIssue: seoIssue });
        }

        function getSeoIssues () {
            return vm.pageSeoIssues.filter(function (data) {
                return data.classification === vm.tab || vm.tab === null;
            });
        }
    }
})();
