import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
/**
 * Service to contain helpers that can be used anywhere in the App
 */
export class GlobalHelperService {
    constructor (
    ) {}

    /**
     * @desc Fuzzy string matching function. Sample taken from https://gist.github.com/dtjm/3961452
     * @link https://gist.github.com/dtjm/3961452
     * @param obj Object to stringify.
     * @param pretty If set to true, the JSON output will contain newlines and whitespace.
     *    If set to an integer, the JSON output will contain that many spaces per indentation.
     * @returns JSON-ified string representing `obj`.
     */
    stringify (obj: unknown, pretty?: boolean | number): string | undefined {
        if (typeof obj === 'undefined') {
            return undefined;
        }

        if (typeof pretty !== 'number') {
            pretty = pretty ? 2 : undefined;
        }

        return JSON.stringify(obj, this.toJsonReplacer.bind(this), pretty);
    }

    isObject (val: unknown): val is object {
        return val !== null && typeof val === 'object';
    }

    /**
     * Checks if an url is valid
     *
     * @deprecated Unused.
     * @param url Url to check.
     */
    isValidUrl (url: string): boolean {
        try {
            const checkUrl = new URL(url);

            return (checkUrl.origin === url || checkUrl.href === url) && url.indexOf('.') > -1;
        } catch (error) {
            return false;
        }
    }

    /**
     * Check if a hostname is valid
     *
     * The code comes from https://github.com/miguelmota/is-valid-hostname
     */
    isValidHostname (hostname: string): boolean {
        if (typeof hostname !== 'string') {
            return false;
        }

        const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;

        if (!validHostnameChars.test(hostname)) {
            return false;
        }

        if (hostname.endsWith('.')) {
            hostname = hostname.slice(0, hostname.length - 1);
        }

        if (hostname.length > 253) {
            return false;
        }

        const labels = hostname.split('.');

        return labels.every(function (label) {
            const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

            return validLabelChars.test(label) &&
                label.length < 64 &&
                !label.startsWith('-') &&
                !label.endsWith('-');
        });
    }

    getCurrentModule (): string {
        return window.location.pathname.split('/')[4];
    }

    getSubject (): Subject<void> {
        return new Subject<void>();
    }

    private toJsonReplacer (key: string, value: unknown): string | unknown {
        if (typeof key === 'string' && key.startsWith('$$')) {
            return undefined;
        }

        if (this.isWindow(value)) {
            return '$WINDOW';
        }

        if (value && window.document === value) {
            return '$DOCUMENT';
        }

        if (this.isScope(value)) {
            return '$SCOPE';
        }

        return value;
    }

    /**
     * Checks if `obj` is a window object.
     */
    private isWindow (obj: unknown): boolean {
        return this.isObject(obj) &&
            'window' in obj &&
            obj.window === obj;
    }

    private isScope (obj: unknown): boolean {
        return this.isObject(obj) &&
            '$evalAsync' in obj &&
            '$watch' in obj &&
            !!obj.$evalAsync &&
            !!obj.$watch;
    }
}
