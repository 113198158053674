(function () {
    'use strict';

    angular.module('directives.monsido').directive('monTabControl', monTabControl);

    function inputOrButton (el) {
        return el instanceof HTMLInputElement || el instanceof HTMLButtonElement;
    }

    function tabListener (e, ng2MonLogService) {
        var el = e.target;
        var focusPool;
        var focusReceiver;
        var direction = +1;
        var index;
        var poolParent;
        if (e.key === 'Tab' && el) {
            if (!(inputOrButton(el) && el.hasAttribute('mon-tab-control'))) {
                el = angular.element(el).closest('[mon-tab-control]')[0];
                if (!el) {
                    ng2MonLogService.warn('No element at monTabControl directive!');
                }
            }

            index = Number(el.getAttribute('mon-tab-control'));

            if (!isNaN(index)) {
                poolParent = angular.element(el).closest('[mon-tab-control-parent]');
                focusPool = poolParent
                    .find('[mon-tab-control="' + index + '"]')
                    .toArray()
                    .filter(function (elem) {
                        return !elem.disabled;
                    });

                if (e.shiftKey) {
                    direction = -1;
                }

                focusReceiver = ensureFocusReceiver(focusPool[focusPool.indexOf(el) + direction]);

                if (focusReceiver) {
                    stopAndFocus(focusReceiver, e);
                } else {
                    focusAnotherRow(poolParent, index, direction, e);
                }
            }
        }
    }

    function ensureFocusReceiver (focusReceiver) {
        if (focusReceiver && !inputOrButton(focusReceiver)) {
            return focusReceiver.querySelector('input, button');
        }
        return focusReceiver;
    }

    function focusAnotherRow (poolParent, index, direction, e) {
        var newRow = poolParent
            .find('[mon-tab-control="' + (index + direction) + '"]')
            .toArray()
            .filter(function (elem) {
                return !elem.disabled;
            });
        var newFocusReceiver;

        if (direction > 0) {
            newFocusReceiver = ensureFocusReceiver(newRow.shift());
        } else {
            newFocusReceiver = ensureFocusReceiver(newRow.pop());
        }

        if (newFocusReceiver) {
            stopAndFocus(newFocusReceiver, e);
        }
    }

    function stopAndFocus (el, e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        el.focus();
    }

    function setTabListener (el, ng2MonLogService) {
        angular.element(el).on('keydown', function (e) {
            tabListener(e, ng2MonLogService);
        });
    }

    monTabControl.$inject = ['$timeout', 'ng2MonLogService'];

    /* @ngInject */
    function monTabControl ($timeout, ng2MonLogService) {
        return {
            restrict: 'A',
            link: function (_scope, element) {
                var inputElement;
                var el = element[0];

                if (inputOrButton(el)) {
                    setTabListener(el, ng2MonLogService);
                } else {
                    // Give time to render inner inputs
                    $timeout(function () {
                        inputElement = el.querySelector('input, button');
                        if (inputElement) {
                            setTabListener(inputElement, ng2MonLogService);
                        }
                    });
                }
            },
        };
    }
})();
