(function () {

    'use strict';

    angular.module('modules.chat').run([
        'ng2MonEventsService',
        'ng2SessionService',
        'activeChatService',
        function (ng2MonEventsService, ng2SessionService, activeChatService) {
            ng2MonEventsService.addListener('exitCustomer', function () {
                ng2SessionService.resetChatSystems();
            });
            ng2MonEventsService.addListener('setAccount', function () {
                const chat = activeChatService.getActiveChat();
                ng2SessionService.setChatSystem(chat.system, chat.options);
            });
        }]);
})();
