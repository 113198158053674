/* MOST NOT BE USED! causes timeouts in angular */

(function () {
    'use strict';

    angular.module('directives.monsido').directive('fillTableCell', fillTableCell);

    fillTableCell.$inject = ['$timeout'];

    /* @ngInject */
    function fillTableCell ($timeout) {
        var lastHeight = 0;
        return {
            restrict: 'A',
            link: function (scope, element) {
                function parentHeight () {
                    var currentHeight = element.parent().height();
                    if (lastHeight !== currentHeight) {
                        element.height(currentHeight);
                        lastHeight = element.parent().height();
                    }
                    $timeout(parentHeight, 300);
                }
                parentHeight();
            },
        };
    }
})();
