import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('services.customer').service('CustomerService', CustomerService);

    CustomerService.$inject = ['ng2MonEventsService', '$deepmerge', 'FORM_CONSTANT'];

    /* @ngInject*/
    function CustomerService (ng2MonEventsService, $deepmerge, FORM_CONSTANT) {
        return {
            editCustomer: function (customer, cb) {
                var params = {
                    body: FORM_CONSTANT.FORM_CUSTOMER_INFO,
                    size: 'md',
                    data: {
                        customer: $deepmerge({}, customer),
                        useLegacy: false,
                    },
                };
                const callback = function (customer) {
                    if (customer) {
                        cb(customer);
                    }
                };
                return ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
            },
        };
    }
})();
