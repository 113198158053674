(function () {
    'use strict';

    angular.module('app.core', [
        'core.config',
        'core.constant',
        'ui.router.upgrade',
        'services',
        'blocks.router',
        'filters.monsido',
        'blocks.dialog',
        'blocks.base',
        'ngCsv',
        'gettext',
        'mon.helpers',
        'mon.dialog',
        'core.run',
        'ngAria',
        'directives.monsido',
        'app.components',
        'app.layout',
        'app.pages',
        'app.forms',
        'msMomentCountries',
        'blocks.service',
        'services.api',
        'core.services',
        'angular-inview',
        'ngStorage',
    ]);
})();
