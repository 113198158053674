import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('modules.shortcut').component('monShortcuts', {
        templateUrl: 'app/modules/shortcut/shortcuts/shortcuts.html',
        controller: ShortcutsController,
        controllerAs: 'vm',
    });

    ShortcutsController.$inject = ['$state', 'ng2SessionService', 'ng2MonEventsService', 'ShortcutService', 'FORM_CONSTANT'];

    function ShortcutsController ($state, ng2SessionService, ng2MonEventsService, ShortcutService, FORM_CONSTANT) {
        var vm = this;
        vm.$onInit = activate;
        vm.getHistoryCenterParameters = getHistoryCenterParameters;
        vm.openForm = openForm;
        vm.getIcon = getIcon;

        function activate () {
            vm.shortcuts = [];
            vm.shortcutCustomerCount = 0;
            vm.shortcutCompanyCount = 0;
            subscriptions();
        }

        function subscriptions () {
            ShortcutService.shortcuts.subscribe(function (shortcuts) {
                if (shortcuts !== null) {
                    vm.shortcuts = shortcuts;
                } else {
                    vm.shortcuts = [];
                }
                vm.shortcutCustomerCount = vm.shortcuts.filter(function (shortcut) {
                    return shortcut.private;
                }).length;
                vm.shortcutCompanyCount = vm.shortcuts.filter(function (shortcut) {
                    return !shortcut.private;
                }).length;
            });
        }

        function getHistoryCenterParameters () {
            var params = {
                module: 'all',
            };
            var domainId = ng2SessionService.getDomainId();
            var domainGroup = ng2SessionService.domainGroup;

            if (Number.isInteger(domainId)) {
                params.domain_id = domainId;
            }

            if (domainGroup !== null) {
                params.group_id = domainGroup.id;
            }

            if ($state.current.name.startsWith('base.customer.domain.seo')) {
                params.module = 'seo';
            } else if ($state.current.name.startsWith('base.customer.domain.qa')) {
                params.module = 'qa';
            } else if ($state.current.name.startsWith('base.customer.domain.policy')) {
                params.module = 'policy';
            } else if ($state.current.name.startsWith('base.customer.domain.accessibility')) {
                params.module = 'accessibility';
            }

            return params;
        }

        function openForm (shortcut, event) {
            if (event) {
                event.preventDefault();
            }

            var params = {
                body: FORM_CONSTANT.FORM_SHORTCUT,
                data: {
                    shortcut: shortcut,
                },
            };
            const callback = function (shortcut) {
                if (shortcut) {
                    ShortcutService.getShortcuts();
                }
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function getIcon (shortcut) {
            var icon = ShortcutService.getPreSelectedIcons().find(function (icon) {
                return shortcut.icon_uri === icon.value;
            });
            if (typeof icon !== 'undefined') {
                return icon.icon;
            }
            return '';
        }
    }
})();
