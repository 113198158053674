(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuPageSelector', {
        templateUrl: 'app/layout/menu/side-menu-search/side-menu-search.html',
        controller: SideMenuSearchController,
        controllerAs: 'vm',
        bindings: {
            search: '<',
            startSwitchDomain: '&',
            hideDomains: '<',
        },
    });

    SideMenuSearchController.$inject = [
        'ng2TotalDomainsService',
        'hotkeys',
        'gettextCatalog',
        '$scope',
        'ng2SearchDomainsService',
        'ng2DomainsService',
    ];

    function SideMenuSearchController (
        ng2TotalDomainsService,
        hotkeys,
        gettextCatalog,
        $scope,
        ng2SearchDomainsService,
        ng2DomainsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.displayDomains = false;
        vm.loadingDomains = false;
        vm.searching = false;
        vm.searchString = '';

        const MAX_SEARCH_ITEMS = 10;
        let domainsChangedSinceFlattened = false;
        let domains$;
        let domainsFetching$;
        let search$;

        function activate () {
            update();
        }

        function update () {
            vm.workspaces = [];
            vm.projects = [];
            vm.domains = [];
            vm.flattenedDomains = [];
            vm.filterDomains = [];
            vm.pinnedDomains = [];
            vm.collectionIndex = -1;
            setupHotkeys();
            domainsFetching$ = ng2TotalDomainsService.fetchingDomains.subscribe((isFetching) => {
                vm.loadingDomains = isFetching;
            });
            domains$ = ng2TotalDomainsService.domainsEssential.subscribe((domains) => {
                vm.domains = domains;
                domainsChangedSinceFlattened = true;
                hideDomains();
                filterDomains();
            });
            search$ = vm.search.subscribe((search) => {
                vm.searchString = search;
                hideDomains();
                filterDomains();
            });
        }

        function onDestroy () {
            domains$.unsubscribe();
            domainsFetching$.unsubscribe();
            search$.dispose();
        }

        function setupHotkeys () {
            hotkeys
                .bindTo($scope)
                .add({
                    combo: 'up',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Move focus up on domain picker'),
                    callback: function () {
                        if (vm.displayDomains && vm.filteredDomains.length > 0) {
                            vm.collectionIndex <= 0 ? vm.collectionIndex = 0 : vm.collectionIndex--;
                            scrollTo('#domain_' + vm.collectionIndex);
                        }
                    },
                })
                .add({
                    combo: 'down',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Move focus down on domain picker'),
                    callback: function () {
                        if (vm.displayDomains && vm.collectionIndex < vm.filteredDomains.length - 1) {
                            vm.collectionIndex++;
                            scrollTo('#domain_' + vm.collectionIndex);
                        }
                    },
                })
                .add({
                    combo: 'enter',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Select Domain on domain picker'),
                    callback: function () {
                        if (vm.displayDomains && vm.collectionIndex >= 0) {
                            angular
                                .element('#domain_' + vm.collectionIndex)
                                .find('div')[0]
                                .click();
                        }

                        // TODO: Remove this key-handler and to add it through ng-click: https://zube.io/monsido/monsido/c/28240
                    },
                });
        }

        // PROTECTED
        function flattenDomains () {
            if (vm.domains.length > 0) {
                vm.flattenedDomains = ng2DomainsService.flattenEssentialDomainData(vm.domains) || [];
            }
            domainsChangedSinceFlattened = false;
        }

        function hideDomains () {
            if (vm.hideDomains && vm.searchString === '') {
                vm.displayDomains = false;
            } else {
                vm.displayDomains = true;
            }
        }

        function filterDomains () {
            if (domainsChangedSinceFlattened) {
                flattenDomains();
            }

            if (vm.searchString !== '') {
                vm.searching = true;
                vm.filteredDomains = ng2SearchDomainsService.searchInList(vm.flattenedDomains, vm.searchString, MAX_SEARCH_ITEMS);
            } else {
                vm.searching = false;
                vm.pinnedDomains = vm.flattenedDomains.filter((domain) => domain.favorite);
                vm.filteredDomains = vm.flattenedDomains;
            }
        }

        function scrollTo (id) {
            const el = angular
                .element(id)
                .find('div')[0];
            el.scrollIntoView({ behavior: 'instant', block: 'start' });
        }
    }
})();
