import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.heatmaps').component('heatmapsDashboard', {
        templateUrl: 'app/modules/heatmaps/pages/dashboard/dashboard.html',
        controller: HeatmapsDashboardController,
        controllerAs: 'vm',
    });

    HeatmapsDashboardController.$inject = [
        '$controller',
        'gettextCatalog',
        'HeatmapApiService',
        'ng2MonEventsService',
        'ng2SessionService',
        'ng2TotalDomainsService',
    ];

    /* @ngInject */
    function HeatmapsDashboardController ($controller, gettextCatalog, HeatmapApiService, ng2MonEventsService, ng2SessionService, ng2TotalDomainsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getPage = getPage;
        vm.addPage = addPage;
        vm.canAddMoreRules = canAddMoreRules;
        vm.setView = setView;
        vm.fethingDomains$;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.subHeader = gettextCatalog.getString('Monitor how visitors interact with your pages. Acquia Optimize tracks up to a maximum of 1000 heatmaps sessions per page per month.');
            vm.loading = true;
            vm.rules = [];
            vm.search = '';
            vm.view = 'grid';
            vm.domains = [];
            vm.page = 1;
            vm.pageSize = 100;
            vm.viewOptions = [
                gettextCatalog.getString('Table View'),
                gettextCatalog.getString('Grid View'),
            ];

            vm.fethingDomains$ = ng2TotalDomainsService.fetchingDomains
                .subscribe((isFetching) => {
                    if (!isFetching) {
                        getPage();
                    }
                });
        }

        function getPage () {
            vm.loading = true;
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
            };
            HeatmapApiService.getAllFromCustomer(params)
                .then(function (rules) {
                    vm.rules = rules;
                    if (vm.rules.total > 100) {
                        vm.view = 'table';
                    }
                    mapDomains(rules);
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function addPage () {
            openPageForm(undefined, function () {
                getPage();
            });
        }

        function canAddMoreRules () {
            if (ng2SessionService.customer) {
                return ng2SessionService.customer.heatmaps_tracked_number_of_pages > vm.rules.total;
            }
            return false;
        }

        function setView (viewNumber) {
            if (viewNumber === 0) {
                vm.view = 'table';
            } else {
                vm.view = 'grid';
            }
        }

        function onDestroy () {
            if (vm.fethingDomains$) {
                vm.fethingDomains$.unsubscribe();
            }
        }

        // PROTECTED

        /**
         *
         * @param rule
         * @param fn
         */
        function openPageForm (rule, fn) {
            var params = {
                body: 'heatmapsDialogsPages',
                size: 'md',
                data: {
                    rules: vm.rules,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: fn });
        }

        function mapDomains (rules) {
            rules.map(function (rule) {
                var target = ng2TotalDomainsService.domainsEssential.value.find(function (domain) {
                    return domain.id === rule.domain_id;
                });

                if (target) {
                    vm.domains[rule.id] = target;
                }
            });
        }
    }
})();
