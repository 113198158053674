import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EditAltTextService {
    private snippet = '';
    private match: string[] = [];

    constructor () { }

    setHTMLSnippet (snippet: string): void {
        this.snippet = snippet || '';
        this.match = this.snippet.match(/\ssrc="([\w-;:\/.,%#?+^=()&@'*]*)"/) || [];
    }

    canReadSource (): boolean {
        return Array.isArray(this.match) && this.match.length > 0;
    }

    getSource (): string {
        return this.match[1];
    }
}
