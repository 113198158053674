import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { Customer } from '@monsido/modules/models/api/customer';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { BehaviorSubject } from 'rxjs';
import { AccountRepoService } from '@client/app/services/api/account-repo/account-repo.service';

@Injectable()
export class CoreAgreementService {
    public hasMultipleAgreements$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor (private sessionService: SessionService, private monEventService: MonEventService, private accountRepo: AccountRepoService) {}

    initAgreements (): void {
        if (this.sessionService.agreements === null) {
            this.sessionService.resetAgreements();
        }
    }

    setAgreements (agreements: Agreement[]): void {
        this.initAgreements();
        this.hasMultipleAgreements$.next(agreements.length > 1);
        this.sessionService.agreements?.next(Object.assign([], agreements));
    }

    clearAgreement (): Promise<void | void[]> {
        this.setAgreements([]);
        this.sessionService.customer = null;
        this.sessionService.agreement = null;
        return this.monEventService.run('clearAgreement');
    }

    setAgreement (agreement: Agreement): Promise<Customer> {
        this.sessionService.agreement = agreement;
        return this.monEventService.run('setAgreement', agreement, this.sessionService.me, this.sessionService.isSudo).then(() => {
            return this.getAccount();
        });
    }

    getAccount (): Promise<Customer> {
        return this.accountRepo.get().then((customer: Customer) => {
            this.sessionService.customer = customer;
            return this.monEventService
                .run('setAccount', customer, this.sessionService.agreement, this.sessionService.me, this.sessionService.isSudo)
                .then(() => {
                    return customer;
                });
        });
    }
}
