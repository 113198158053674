import { NgModule } from '@angular/core';
import { IconsService } from '@layout/icons/icons.service';
import { IconsPipe } from '@layout/icons/icons.pipe';

@NgModule({
    providers: [IconsService],
    declarations: [IconsPipe],
    exports: [IconsPipe],
})
export class IconsModule {}
