(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationMissingSubHeadlines', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/missing-subheadlines/missing-subheadlines.html',
        controller: SeoTranslationsSubHeadlinesController,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTranslationsSubHeadlinesController.$inject = [];
    /* @ngInject */
    function SeoTranslationsSubHeadlinesController () {
        var vm = this;
        vm.count = count;

        function count (content) {
            if (Array.isArray(content)) {
                return content.length;
            } else if (content === null || content === undefined) {
                return 0;
            }

            return 1;
        }
    }
})();
