import { PaScriptSettingsOptionsInterface } from './pa-script-settings-options.interface';
import { PaScriptSettingsModel } from './pa-script-settings.model';
import { PAGE_ASSIST_SCRIPT_CONSTANT } from './pa-script.constant';

const colorKeys: Array<keyof PaScriptSettingsModel> = [
    'mainColor',
    'textColor',
    'linkColor',
    'buttonHoverColor',
];

const darkColorKeys: Array<keyof PaScriptSettingsModel> = [
    'mainDarkColor',
    'textDarkColor',
    'linkColorDark',
    'buttonHoverDarkColor',
];

const otherProperties: Array<keyof PaScriptSettingsModel> = [
    'direction',
    'greeting',
    'title',
    'title_text',
    'icon_picture_url',
    'icon_shape',
    'logo_picture_url',
    'logo_picture_base64',
];

const isDifferent = (
    obj1: Partial<PaScriptSettingsModel>,
    obj2: Partial<PaScriptSettingsModel>,
    keys: Array<keyof PaScriptSettingsModel>,
): boolean => {
    for (const key of keys) {
        if (obj1[key] !== obj2[key]) {
            return true;
        }
    }
    return false;
};

const getSubset = (original: PaScriptSettingsModel): Partial<PaScriptSettingsModel> => {
    return [
        'theme',
        'mainButtonBorderColorEnabled',
        ...colorKeys,
        ...darkColorKeys,
        ...otherProperties,
    ]
        .filter(key => original.hasOwnProperty(key))
        .reduce((newObject, key) => {
            newObject[key] = original[key];
            return newObject;
        }, {});
};

const getRestorationSubset = (
    defaultValues: Partial<PaScriptSettingsModel>,
    colors: Array<keyof PaScriptSettingsModel>,
): Partial<PaScriptSettingsModel> => {
    return [
        'mainButtonBorderColorEnabled',
        ...colors,
        ...otherProperties,
    ]
        .filter(key => defaultValues.hasOwnProperty(key))
        .reduce((newObject, key) => {
            newObject[key] = defaultValues[key];
            return newObject;
        }, {});
};

const defaultDataModel = getSubset(new PaScriptSettingsModel({} as PaScriptSettingsOptionsInterface));

export const isDesignTouched = (dataModel: PaScriptSettingsModel): boolean => {

    const designData = getSubset(dataModel);

    if (defaultDataModel.mainButtonBorderColorEnabled !== dataModel.mainButtonBorderColorEnabled) {
        return true;
    }

    if (isDifferent(defaultDataModel, designData, otherProperties)) {
        return true;
    }

    if (designData.theme === PAGE_ASSIST_SCRIPT_CONSTANT.THEME.LIGHT) {
        if (isDifferent(defaultDataModel, designData, colorKeys)) {
            return true;
        }
    } else {
        if (isDifferent(defaultDataModel, designData, darkColorKeys)) {
            return true;
        }
    }

    return false;
};

export const restoreDesignSettings = (dataModel: PaScriptSettingsModel): PaScriptSettingsModel => {
    let restoredValues: Partial<PaScriptSettingsModel>;

    if (dataModel.theme === PAGE_ASSIST_SCRIPT_CONSTANT.THEME.LIGHT) {
        restoredValues = getRestorationSubset(defaultDataModel, colorKeys);
    } else {
        restoredValues = getRestorationSubset(defaultDataModel, darkColorKeys);
    }

    return Object.assign(dataModel, restoredValues);
};
