import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.admin.labels').component('adminLabels', {
        templateUrl: 'app/pages/admin/labels/labels.html',
        controller: LabelsController,
        controllerAs: 'vm',
    });

    LabelsController.$inject = [
        'ng2MonEventsService',
        'ng2LabelRepo',
        '$controller',
        'activeFeatures',
        '$state',
        'labelService',
        'FORM_CONSTANT',
        'ng2MonModalService',
        'gettextCatalog',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject */
    function LabelsController (
        ng2MonEventsService,
        ng2LabelRepo,
        $controller,
        activeFeatures,
        $state,
        labelService,
        FORM_CONSTANT,
        ng2MonModalService,
        gettextCatalog,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;
        vm.addLabel = addLabel;
        vm.getPage = getPage;
        vm.editLabel = editLabel;
        vm.onDelete = onDelete;
        vm.onConfirmDelete = onConfirmDelete;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            if (!activeFeatures.labels) {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard'),
                );
            }
            vm.search = null;
            getPage();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search || null,
            };

            vm.promise = ng2LabelRepo.getLabels(params).then(function (result) {
                vm.labels = result;
            }, angular.noop);
        }

        function addLabel () {
            var params = {
                body: FORM_CONSTANT.FORM_LABEL,
                size: 'xs',
                classes: 'fade-animation middle center label-dialog',
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: vm.getPage });
        }

        function editLabel (label) {
            var params = {
                body: FORM_CONSTANT.FORM_LABEL,
                size: 'xs',
                classes: 'fade-animation middle center label-dialog',
                data: {
                    label: label,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: vm.getPage });
        }

        function onDelete (label) {
            var confirmDeleteText = gettextCatalog.getString('Are you sure you want to delete label: {{label}} ?', { label: label.name });
            return ng2LabelRepo.getDomainGroupsUsingLabel(label.id)
                .then(function (res) {
                    if (res && res.length > 0) {
                        const params = {
                            body: 'labelBlockedByDomainGroups',
                            size: 'sm',
                            classes: 'fade-animation mon-dialog-label-blocked-by-domain-groups',
                            data: {
                                label: label,
                                domains: res,
                            },
                        };
                        ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
                    } else {
                        return ng2MonModalService.confirm(confirmDeleteText)
                            .then(function () {
                                onConfirmDelete(label);
                            });
                    }
                }, angular.noop);
        }

        function onConfirmDelete (label) {
            var params = {
                label_id: label.id,
            };
            labelService
                .destroy(params)
                .then(function () {
                    vm.getPage();
                }, angular.noop);
        }
    }
})();
