import { Injectable } from '@angular/core';
import { AccessibilityCheckNameType } from '@client/app/models/types/accessibility-check-name.type';
import { GlobalAccessibilityChecksRepoService } from '@client/app/services/accessibility-checks-repo/GlobalAccessibilityChecksRepo.service';


@Injectable({
    providedIn: 'root',
})
export class AccessibilityCheckNameService {
    private checks: AccessibilityCheckNameType[] = [];
    constructor (private globalAccessibilityChecksRepoService: GlobalAccessibilityChecksRepoService) {}

    getNameSync (id: number): string | null {
        return this.findName(id);
    }

    async getName (id: number): Promise<string | null> {
        if (this.checks.length === 0) {
            await this.getAllChecks();
        }
        return this.findName(id);
    }

    getAllChecks (): Promise<void> {
        return this.globalAccessibilityChecksRepoService.getAll({ page_size: 300 }).then( (data) => {
            this.checks = data;
        });
    }

    private findName (id: number): string | null {
        for (let i = 0; i < this.checks.length; i++) {
            if (this.checks[i].id === id) {
                return this.checks[i].name;
            }
        }
        return null;
    }

}
