import { Injectable } from '@angular/core';
import { MonPromptService, PromptOptions } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { isStringContainsLineBreak } from '@monsido/ng2/shared/utils';

type MonPromptButtonType = {
    label: string;
    className?: string;
    callback: (...args: unknown[]) => void;
    value?: unknown;
};

@Injectable({
    providedIn: 'root',
})
export class MonModalService {

    private defaultOptions: Record<string, string> & { backdrop: 'static' } = {
        parentSelector: '#common-dialog-wrapper',
        title: '',
        titleHTML: '',
        message: '',
        messageHTML: '',
        backdrop: 'static',
    };

    constructor (
        private monPromptService: MonPromptService,
    ) { }

    alert (msg: string, options?: Record<string, string>): Promise<string> {
        options = options || {};

        const adaptedOptions = this.monPromptServiceMessageHTMLAdapter(msg, options);

        return this.monPromptService.alert(adaptedOptions.msg, this.mergeDefaultOptions(adaptedOptions.options));
    }

    confirm (param: Record<string, string>, options?: Record<string, string>): Promise<string> {
        options = options || {};
        let msg = '';

        if (typeof param === 'string') {
            msg = param;
        } else {
            msg = param.message;
            options.title = param.title;
        }

        const adaptedOptions = this.monPromptServiceMessageHTMLAdapter(msg, options);

        return this.monPromptService.confirm(adaptedOptions.msg, this.mergeDefaultOptions(adaptedOptions.options));
    }

    customDialog (params: {
        title: string;
        message: string;
        buttons: MonPromptButtonType[];
    }, options): Promise<string> {
        options = options || {};

        const adaptedOptions = this.monPromptServiceMessageHTMLAdapter(params.message, options);
        if (params.buttons) {
            params.buttons = params.buttons.map((button) => {
                if (!button.callback) {
                    button.callback = (): void => {};
                }
                return button;
            });
        }
        if (params.title) {
            adaptedOptions.options.title = params.title;
        }

        return this.monPromptService.custom(params, this.mergeDefaultOptions(adaptedOptions.options));
    }

    prompt (msg, options): Promise<string> {
        options = options || {};

        const adaptedOptions = this.monPromptServiceMessageHTMLAdapter(msg, options);

        return this.monPromptService.prompt(adaptedOptions.msg, this.mergeDefaultOptions(adaptedOptions.options));
    }

    private mergeDefaultOptions (options: Record<string, string>): PromptOptions {
        options = options || {};
        return {
            ...this.defaultOptions,
            ...options,
        };
    }

    private monPromptServiceMessageHTMLAdapter (msg: string, options: Record<string, string>): {
        msg: string;
        options: Record<string, string>;
    } {
        const newOptions = Object.assign({}, options);

        if (!options.title) {
            // Render message at title slot
            if (isStringContainsLineBreak(msg)) {
                newOptions.title = msg;
                newOptions.titleHTML = '';
            } else {
                newOptions.titleHTML = msg;
                newOptions.title = '';
                msg = '';
            }
            return {
                msg, options: newOptions,
            };
        }

        // Render message at body slot
        if (isStringContainsLineBreak(msg)) {
            newOptions.message = msg;
            newOptions.messageHTML = '';
        } else {
            newOptions.messageHTML = msg;
            newOptions.message = '';
            msg = '';
        }
        return {
            msg, options: newOptions,
        };
    }
}
