import { MON_MODULES } from '@monsido/ng2/core/constants/mon-modules.constant';

(function () {
    'use strict';

    angular.module('blocks.router').service('RouteHelperService', RouteHelperService);

    const hasAccess = (featureName, redirectURI, monModule) => [
        'ng2ActiveFeatureService',
        '$state',
        '$q',
        '$stateParams',
        '$filter',
        'ng2SessionService',
        'ng2MonUIRouterTransitionService',
        function (
            ng2ActiveFeatureService,
            $state,
            $q,
            $stateParams,
            $filter,
            ng2SessionService,
            ng2MonUIRouterTransitionService,
        ) {
            if (
                ng2ActiveFeatureService.isFeatureActive(featureName) &&
                (!monModule || !$filter('hasAccess')(ng2SessionService.me, monModule))
            ) {
                return $q.resolve(true);
            }

            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go(redirectURI, $stateParams),
            );
        },
    ];

    const activeFeature = (features, redirectURI) => [
        '$filter',
        '$state',
        'ng2ActiveFeatureService',
        'ng2MonUIRouterTransitionService',
        function ($filter, $state, ng2ActiveFeatureService, ng2MonUIRouterTransitionService) {
            var validate = $filter('activeFeature')(features[0]);
            if (!ng2ActiveFeatureService.isFeatureActivePure(features[1]) || !validate) {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go(redirectURI),
                );
            }
            return Promise.resolve(true);
        },
    ];

    RouteHelperService.$inject = [
        'gettextCatalog',
    ];

    function RouteHelperService (
        gettextCatalog,
    ) {

        const titles = {
            admin: {
                auditlog: gettextCatalog.getString('Acquia Optimize - Audit Log'),
                users: gettextCatalog.getString('Acquia Optimize - User List'),
                labels: gettextCatalog.getString('Acquia Optimize - Labels'),
                'page_performance.dashboard': gettextCatalog.getString('Acquia Optimize - Admin Page Performance Profiles'),
            },
            domain: {
                'page_performance.pages': gettextCatalog.getString('Acquia Optimize - Page Performance Pages'),
                'qa.broken.links': gettextCatalog.getString('Acquia Optimize - Broken Links'),
                'qa.broken.images': gettextCatalog.getString('Acquia Optimize - Broken Images'),
                'qa.broken.pages': gettextCatalog.getString('Acquia Optimize - Content With Broken Links'),
                'qa.pages': gettextCatalog.getString('Acquia Optimize - Content With QA Errors'),
                'qa.summary': gettextCatalog.getString('Acquia Optimize - Quality Assurance'),
                'seo.summary': gettextCatalog.getString('Acquia Optimize - Search Engine Optimization'),
                'seo.issues': gettextCatalog.getString('Acquia Optimize - SEO Checkpoints'),
                'seo.pages': gettextCatalog.getString('Acquia Optimize - Pages With Opportunity'),
            },
        };

        return {
            makeRoute,
            makeRouteWithHasAccess,
            makeRouteWithActiveFeatures,
            makeIndexRoute,
        };

        function makeRoute ({ state, name1, name2, abstract, url, template, sidebar, title, resolve }) {

            if (!title && titles[name1]) {
                title = titles[name1][name2];
            }
            const result = {
                state: state || `base.customer.${name1}.${name2}`,
                config: {
                    url,
                    template: template ?? '<div ui-view></div>',
                    data: {
                        title,
                    },
                },
            };

            if (resolve) {
                result.config.resolve = resolve;
            }

            if (sidebar) {
                result.config.data.sidebar = sidebar;
            }

            if (typeof abstract === 'boolean') {
                result.config.abstract = abstract;
            }
            return result;
        }

        function makeRouteWithHasAccess ({ name1, name2, url, featureName, redirectURI, abstract, sidebar, monModule }) {
            const result = makeRoute({
                name1,
                name2,
                abstract,
                url,
                sidebar,
            });
            result.config.resolve = {
                hasAccess: hasAccess(featureName, redirectURI, monModule),
            };
            return result;
        }

        function makeRouteWithActiveFeatures ({ name1, name2, url, features, redirectURI, abstract, sidebar }) {
            const result = makeRoute({ name1, name2, abstract, url, sidebar });
            result.config.resolve = { hasAccess: activeFeature(features, redirectURI) };
            return result;
        }

        function makeIndexRoute ({ name1, name2, url, template }) {
            return {
                state: `base.customer.${name1}.${name2}.index`,
                config: {
                    url,
                    template,
                },
            };
        }
    }
})();
