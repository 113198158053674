export const EXTENSION_DEEPLINK_MODULE = {
    BrokenLinks: {
        id: 'blnk',
        name: 'Broken link',
    },
    BrokenImages: {
        id: 'bimg',
        name: 'Broken image',
    },
    Misspelling: {
        id: 'mspel',
        name: 'Misspelling',
    },
    PotentialMisspelling: {
        id: 'pmspel',
        name: 'Potential Misspelling',
    },
    SeoIssue: {
        id: 'seo',
        name: 'SEO issue',
    },
    PolicyIssue: {
        id: 'pol',
        name: 'Policy',
    },
    AccessibilityIssue: {
        id: 'acc',
        name: 'Accessibility check',
    },
    Readability: {
        id: 'rdblty',
        name: 'Readability',
    },
    Heatmaps: {
        id: 'heatmaps',
        name: 'Heatmaps',
    },
};
