<div class="mon-action-menu-panel" [hidden]="!isOpen">
    <ul #menupanel 
        role="menu" 
        [attr.id]="monId"
        [attr.aria-labelledby]="monLabelledBy ? monLabelledBy : null">

        <ng-template #defaultTemplate>
            <li *ngFor="let item of monActionPanelItems"  [attr.role]="item.divider ? 'separator' : 'presentation'" [ngClass]="{'divider': item.divider}">            
                <mon-button-next
                    (click)="onActionPress(item.action)"
                    [monType]="'button'" 
                    [monLeftIcon]="item.leftIcon" 
                    [monSpinLeftIcon]="item.spinLeftIcon"
                    [monRightIcon]="item.rightIcon"
                    [monSpinRightIcon]="item.spinRightIcon"
                    [monCustomButtonImage]="item.customIcon"
                    [monDisabled]="item.disabled"
                    [monRole]="'menuitem'"
                    [monTooltip]="item.tooltip"
                    [attr.data-test]="item.dataTestAttribute"
                    *ngIf="!item.divider">
                        <ng-container *ngIf="item.menuItemButtonIcons">
                            <mon-icon *ngFor="let icon of item.menuItemButtonIcons"
                                [monIconDirect]="icon.monIconDirect ? icon.monIconDirect : ''"
                            ></mon-icon>
                        </ng-container>{{item.label}}</mon-button-next>            
            </li>
        </ng-template>
        
        <ng-container
            [ngTemplateOutlet]="monProjectedTemplate || defaultTemplate"
        ></ng-container>
    </ul>
</div>
