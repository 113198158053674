(function () {
    'use strict';

    angular.module('pages.global.changesHistory').component('changesHistoryDashboardTablePerformance', {
        templateUrl: 'app/pages/global/changes-history/dashboard/tables/performance/performance.html',
        controller: PerformanceController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
            domain: '<',
            domainGroup: '<',
        },
    });

    PerformanceController.$inject = [
        '$filter',
        'complianceService',
        'ng2HistoryHelperService',
        'gettextCatalog',
        'PagePerformanceVersionService',
        '$deepmerge',
    ];
    /* @ngInject */
    function PerformanceController ($filter, complianceService, ng2HistoryHelperService, gettextCatalog, PagePerformanceVersionService, $deepmerge) {
        const vm = this;
        vm.$onInit = activate;
        vm.$onChanges = update;
        vm.getChangeValue = getChangeValue;
        vm.getChangePercentageValue = getChangePercentageValue;
        vm.changesColorClass = changesColorClass;
        vm.changesIconClass = changesIconClass;
        vm.formatFieldValue = formatFieldValue;

        function activate () {
            vm.fields = [
                'performance_first_contentful_paint_average',
                'performance_largest_contentful_paint_average',
                'performance_time_to_interactive_average',
                'performance_speed_index_average',
                'performance_total_blocking_time_average',
                'performance_cumulative_layout_shift_average',
            ];
            vm.scoreFields = ['performance_score_average'];
            vm.decimalsFields = ['performance_cumulative_layout_shift_average'];
            vm.versions = [];
            vm.seriesByVersion = {};
            vm.shouldDisplayUpdateIndicator = [];
            computeHistory();
        }

        function update () {
            if (!vm.scoreFields) {
                return;
            }
            computeHistory();
        }

        function computeHistory () {
            const performanceHistory = vm.history.filter(item=>Boolean(item['performance_version']));
            const seriesByVersion = PagePerformanceVersionService.getSeriesByVersion(performanceHistory, 'performance_version');
            vm.versions = seriesByVersion.versions;
            vm.seriesByVersion = seriesByVersion.series;
            vm.shouldDisplayUpdateIndicator = [];
            const versions = $deepmerge([], vm.versions);
            versions.reverse();
            for (let i = 0; i < versions.length; i++) {
                const version = versions[i];
                if (!version) {
                    continue;
                }

                const originalSeries = $deepmerge([], vm.seriesByVersion[version.id]);
                const currentSeries = vm.seriesByVersion[version.id];

                vm.seriesByVersion[version.id] = currentSeries.map(function (entry) {
                    const computedEntry = $deepmerge({}, entry);
                    computedEntry.changesColorClass = {};
                    computedEntry.changePercentageValue = {};
                    computedEntry.changeIconClass = {};
                    computedEntry.formattedFieldValue = {};

                    version.avgMetrics.forEach(function (metric) {
                        const metricId = metric.id;
                        computedEntry.formattedFieldValue[metricId] = formatFieldValue(entry[metricId], metricId);
                        computedEntry.changesColorClass[metricId] = changesColorClass(entry, metricId, originalSeries);
                        computedEntry.changePercentageValue[metricId] = getChangePercentageValue(entry.id, metricId, originalSeries);
                        computedEntry.changeIconClass[metricId] = changesIconClass(entry, metricId, originalSeries);
                    });

                    return computedEntry;
                });

                const previousVersion = versions[i - 1];
                if (!previousVersion) {
                    vm.shouldDisplayUpdateIndicator.push(false);
                } else {
                    const previousVersionIsTransitionVersion = previousVersion.id.indexOf('-') > -1;
                    if (previousVersionIsTransitionVersion) {
                        const transitionVersions = previousVersion.id.split('-');
                        if (transitionVersions[1] === version.id) {
                            vm.shouldDisplayUpdateIndicator.push(false);
                            return;
                        }
                    }
                    vm.shouldDisplayUpdateIndicator.push(true);
                }
            }
            vm.shouldDisplayUpdateIndicator.reverse();
        }

        function getChangeValue (id, field, series) {
            return ng2HistoryHelperService.getChangeValue(id, series, [field]);
        }

        function getChangePercentageValue (id, field, series) {
            return ng2HistoryHelperService.getChangePercentageValue(id, series, [field]);
        }

        function changesColorClass (story, field, series) {
            let value = vm.getChangeValue(story.id, field, series);

            if (vm.scoreFields.indexOf(field) !== -1) {
                value = -value;
            }

            return ng2HistoryHelperService.getChangeColorClass(value);
        }

        function changesIconClass (story, field, series) {
            let value = vm.getChangeValue(story.id, field, series);

            if (vm.scoreFields.indexOf(field) === -1) {
                value = -value;
            }

            return ng2HistoryHelperService.getChangeIconClass(value);
        }

        function formatFieldValue (value, key) {
            if (!value || !vm.scoreFields) {
                return 0;
            }
            if (vm.scoreFields.indexOf(key) !== -1) {
                return $filter('monNumber')(value * 100, '0.00');
            } else if (vm.decimalsFields.indexOf(key) !== -1) {
                return $filter('monNumber')(value, '0.00');
            } else {
                return $filter('monNumber')(value) + ' ms';
            }
        }
    }
})();
