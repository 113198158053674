(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('tableDocumentDetails', {
        controller: PageDetailsBtnsController,
        templateUrl: 'app/components/documentdetails/document-details.html',
        controllerAs: 'vm',
        bindings: {
            document: '<',
            callBack: '&?',
            target: '@?',
            subTarget: '<?',
        },
    });

    PageDetailsBtnsController.$inject = ['gettextCatalog', 'ComponentDetailsView', 'ng2ActiveFeatureService'];

    /* @ngInject */
    function PageDetailsBtnsController (gettextCatalog, ComponentDetailsView, ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;
        vm.gotoDocumentDetails = gotoDocumentDetails;
        vm.hasAccessToDetailsOverlay = ng2ActiveFeatureService.isFeatureActive('page_details');

        function activate () {
            vm.labelMsg = gettextCatalog.getString('Open Document details', 'Aria label when clicking on open Document details');
        }

        function gotoDocumentDetails () {
            var params = {
                target: vm.target,
                subTarget: vm.subTarget,
            };
            ComponentDetailsView.goToDocumentDetails(vm.document, params, vm.callBack);
        }
    }
})();
