(function () {
    'use strict';

    angular.module('app.forms').component('formUserAccess', {
        templateUrl: 'app/forms/user/steps/access/access.html',
        controller: AccessController,
        controllerAs: 'vm',
        bindings: {
            user: '=',
        },
    });

    AccessController.$inject = ['gettextCatalog', '$timeout', 'MON_MODULES', 'ng2SessionService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function AccessController (gettextCatalog, $timeout, MON_MODULES, ng2SessionService, ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;
        vm.onCheck = onCheck;
        vm.setAllChecked = setAllChecked;

        function activate () {
            vm.modules = [
                {
                    name: gettextCatalog.getString('Policies'),
                    module: MON_MODULES.policies,
                },
                {
                    name: gettextCatalog.getString('Quality Assurance'),
                    module: MON_MODULES.qa,
                },
                {
                    name: gettextCatalog.getString('Accessibility'),
                    module: MON_MODULES.accessibility,
                },
                {
                    name: gettextCatalog.getString('SEO'),
                    module: MON_MODULES.seo,
                },
                {
                    name: gettextCatalog.getString('Inventory'),
                    module: MON_MODULES.inventory,
                },
                {
                    name: gettextCatalog.getString('Statistics'),
                    module: MON_MODULES.statistics,
                },
                {
                    name: gettextCatalog.getString('Prioritized Content'),
                    module: MON_MODULES.content_pages,
                },
                {
                    name: gettextCatalog.getString('Performance'),
                    module: MON_MODULES.performance,
                },
            ];

            if (ng2SessionService.customer.plan_traits.heartbeat) {
                vm.modules.splice(4, 0, {
                    name: gettextCatalog.getString('Heartbeat'),
                    module: MON_MODULES.uptime,
                });
            }

            if (ng2ActiveFeatureService.isFeatureActive('admin_page_fix')) {
                vm.modules.splice(4, 0, {
                    name: gettextCatalog.getString('PageCorrect'),
                    module: MON_MODULES.pagecorrect,
                });
            }

            if (ng2ActiveFeatureService.isFeatureActive('admin_data_privacy')) {
                vm.modules.push({
                    name: gettextCatalog.getString('Data Privacy'),
                    module: MON_MODULES.data_privacy,
                });
            }

            vm.user.settings = vm.user.settings || { modules: [] };
            areAllChecked();

            angular.forEach(vm.modules, function (module) {
                module.value = vm.user.settings.modules.indexOf(module.module) === -1;
            });
        }

        function onCheck () {
            $timeout(function () {
                vm.user.settings.modules = vm.modules
                    .filter(function (module) {
                        return !module.value;
                    })
                    .map(function (module) {
                        return module.module;
                    });
                areAllChecked();
            });
        }

        function areAllChecked () {
            vm.allChecked = vm.user.settings.modules.length === 0;
        }

        function setAllChecked () {
            $timeout(function () {
                vm.modules.map(function (module) {
                    module.value = vm.allChecked;
                    return module;
                });
                onCheck();
            });
        }
    }
})();
