(function () {
    'use strict';
    angular.module('modules.policies').factory('PolicyTableService', PolicyTableService);

    PolicyTableService.$inject = ['Lodash', 'PolicyHelper', 'ng2SessionService', 'ng2DateTimeService', 'gettextCatalog', 'POLICY_MODES', 'MON_ICONS'];
    /* @ngInject*/
    function PolicyTableService (Lodash, PolicyHelper, ng2SessionService, ng2DateTimeService, gettextCatalog, POLICY_MODES, MON_ICONS) {
        var vm = this;
        vm.archivedPolicyTab = 'archived';
        vm.policyTab = null;

        return {
            areAnyPoliciesRunning: areAnyPoliciesRunning,
            targetHasRun: targetHasRun,
            policyScanValid: policyScanValid,
            getTabs: getTabs,
            getShowArchivedPolicies: getShowArchivedPolicies,
            getTab: getTab,
            setTab: setTab,
        };

        function getTabs (globalPolicy) {
            if (typeof globalPolicy !== 'boolean') {
                globalPolicy = true;
            }

            return Lodash.filter(
                [
                    {
                        name: gettextCatalog.getString('All'),
                        value: null,
                    },
                    {
                        name: gettextCatalog.getString('Unwanted'),
                        value: POLICY_MODES.violation,
                        faIcon: MON_ICONS.POLICIES.MODES.violation,
                    },
                    {
                        name: gettextCatalog.getString('Required'),
                        value: POLICY_MODES.required,
                        faIcon: MON_ICONS.POLICIES.MODES.required,
                    },
                    {
                        name: gettextCatalog.getString('Matches'),
                        value: POLICY_MODES.search,
                        faIcon: MON_ICONS.POLICIES.MODES.search,
                    },
                    {
                        name: gettextCatalog.getString('Archived'),
                        value: vm.archivedPolicyTab,
                        faIcon: MON_ICONS.POLICIES.MODES.archived,
                    },
                ],
                function (tab) {
                    if (globalPolicy) {
                        return true;
                    }
                    return [vm.archivedPolicyTab].indexOf(tab.value) === -1;
                },
            );
        }

        function areAnyPoliciesRunning (policies) {
            return Lodash.some(policies, { state: 'running' });
        }

        function targetHasRun (policy) {
            var target = PolicyHelper.getTargetDomain(policy.targets);
            return Boolean(target && target.latest_run_at);
        }

        function policyScanValid (policy) {
            if (ng2SessionService.domain !== null && policy.state !== 'running' && targetHasRun(policy)) {
                var target = PolicyHelper.getTargetDomain(policy.targets);
                return ng2DateTimeService.diff(ng2SessionService.domain.last_scan, target.latest_run_at, 'hours') < 24;
            }
            return false;
        }

        function setTab (tab) {
            vm.policyTab = tab;
        }

        function getTab () {
            if (vm.policyTab === vm.archivedPolicyTab) {
                return null;
            }
            return vm.policyTab;
        }

        function getShowArchivedPolicies () {
            if (vm.policyTab === POLICY_MODES.search || vm.policyTab === POLICY_MODES.violation || vm.policyTab === POLICY_MODES.required) {
                return false;
            } else if (vm.policyTab === vm.archivedPolicyTab) {
                return true;
            }
            return null;
        }
    }
})();
