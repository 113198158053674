(function () {
    'use strict';

    angular.module('modules.flags').component('monFlagIcon', {
        controller: FlagsIconController,
        controllerAs: 'vm',
        template: '<span class=\'flag-icon\' ng-class=\'vm.flagClass()\'></span>',
        bindings: {
            flag: '@',
            type: '@?',
        },
    });

    FlagsIconController.$inject = [];
    /* @ngInject */
    function FlagsIconController () {
        var vm = this;
        vm.$onInit = activate;
        vm.flagClass = flagClass;

        function activate () {
            vm.type = vm.type || '';
        }

        function flagClass () {
            var flagClassType = '';
            if (vm.type) {
                flagClassType += 'flag-icon-' + vm.type + ' ';
            }
            return flagClassType + 'flag-icon-' + vm.flag;
        }
    }
})();
