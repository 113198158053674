import { DomainGroupsEntity } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';

export class EssentialDomainGroupDataModel {

    id: number;
    title: string;

    type?: string;

    constructor (data: DomainGroupsEntity) {
        this.id = data.id;
        this.title = data.title;
    }

}
