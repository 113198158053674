import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'mon-simple-doughnut-chart',
    templateUrl: './simple-doughnut-chart.component.html',
    styleUrls: ['./simple-doughnut-chart.component.scss'],
})
export class SimpleDoughnutChartComponent implements OnInit {

    @Input() percentage: number = 0;
    doughnutstyle: string = '';

    constructor () { }

    ngOnInit (): void {
        this.doughnutstyle = this.getDoughnutStyle();
    }

    getDoughnutStyle (): string {
        const doughnut_conf = 263.89378290154264; // 2 * Math.PI * 42
        const doughnutstyle = (doughnut_conf * this.percentage) + ',' + (doughnut_conf * (1 - this.percentage));
        return 'stroke-dasharray:' + doughnutstyle;
    }

}
