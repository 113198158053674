(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScanLoginTypesOffice365', {
        templateUrl: 'app/forms/domain/steps/scan/login-types/office-365/office-365.html',
        controller: DomainScanLoginTypesFormController,
        controllerAs: 'vm',
        bindings: {
            login: '=',
        },
    });

    DomainScanLoginTypesFormController.$inject = [];
    /* @ngInject */
    function DomainScanLoginTypesFormController () {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.login = vm.login || {};
            if (vm.login.office365 === null || typeof vm.login.office365 !== 'object') {
                vm.login.office365 = {};
            }
        }
    }
})();
