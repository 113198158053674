(function () {
    'use strict';
    angular.module('pages.accessibility').component('accessibilityDashboardDoughnut', {
        templateUrl: 'app/pages/accessibility/dashboard/summary/doughnut/doughnut.html',
        controller: AccessibilityDashboardDoughnutController,
        controllerAs: 'vm',
        bindings: {
            data: '<',
            options: '<',
        },
    });

    AccessibilityDashboardDoughnutController.$inject = ['AccessibilityDoughnutChartService'];
    /* @ngInject */
    function AccessibilityDashboardDoughnutController (AccessibilityDoughnutChartService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            var chartService = new AccessibilityDoughnutChartService(null);
            vm.labels = chartService.getLabels();
            vm.colors = chartService.getColors();
        }
    }
})();
