import { COOKIE_BANNER_I18N } from '@monsido/modules/cookie-banner/constants/cookie-banner-i18n.constant';
import { COOKIE_BANNER_SETTINGS } from '@client/app/modules/cookie-banner/constants/cookie-banner-settings';

export const CONSENT_MANAGER_CONSTANT = {
    DIRECTION: {
        TOPLEFT: 'top-left',
        TOPRIGHT: 'top-right',
        LEFT: 'left',
        RIGHT: 'right',
        TOPCENTER: 'top-center',
        BOTTOMCENTER: 'bottom-center',
        BOTTOMLEFT: 'bottom-left',
        BOTTOMRIGHT: 'bottom-right',
    },
    ICON_SHAPE: {
        CIRCLE: 'circle',
        ROUNDED_BOX: 'rounded_box',
    },
    LANGUAGE: {
        EN_US: 'en_US',
        DA_DK: 'da_DK',
        DE_DE: 'de_DE',
        ES_ES: 'es_ES',
        NB_NO: 'nb_NO',
        NL_NL: 'nl_NL',
    },
    ICON: {
        COOKIE: 'cookie',
        COOKIE_BITE: 'cookie_bite',
        BADGE: 'badge',
        STROOPWAFEL: 'stroopwafel',
    },
    BUTTON_TEXT_COLOR: '#ffffff',
    BUTTON_COLOR: '#783CE2',
    DEFAULT_SETTINGS: {
        enabled: true,
        direction: 'bottom-right',
        privacyRegulation: 'ccpa',
        cookie_policy_url: '',
        optOutUrl: '',
        cookies_overview: [],
        per_category_consent: true,
        hasOverlay: false,
        explicit_reject_option: false,
        enable_iab_and_consent_fw: false,
        support_google_consent_mode: false,
        icon_picture_url: 'cookie',
        icon_shape: 'circle',
        included_languages: [COOKIE_BANNER_I18N.EN_US],
        defaultLanguage: COOKIE_BANNER_I18N.EN_US,
        theme: {
            buttonColor: '#783CE2',
            buttonTextColor: '#ffffff',
        },
    },
    PRIVACY_REGULATIONS: {
        CCPA: 'ccpa',
        GDPR: 'gdpr',
    },
};

export const CONSENT_MANAGER_PRIVACY_REGULATION_OPTIONS = [
    {
        name: 'CCPA',
        value: CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.CCPA,
        tooltip: 'California Consumer Privacy Act',
    },
    {
        name: 'GDPR',
        value: CONSENT_MANAGER_CONSTANT.PRIVACY_REGULATIONS.GDPR,
        tooltip: 'General Data Protection Regulation',
    },
    {
        name: 'Both CCPA & GDPR',
        value: [COOKIE_BANNER_SETTINGS.PRIVACY_REGULATIONS.CCPA, COOKIE_BANNER_SETTINGS.PRIVACY_REGULATIONS.GDPR],
        tooltip: '',
    },
];
