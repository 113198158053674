(function () {
    'use strict';

    angular.module('modules.cookie_banner.pages').component('cookieAcceptanceRateOverview', {
        templateUrl: 'app/modules/cookie-banner/pages/cookie-acceptance-rate-overview/cookie-acceptance-rate-overview.html',
        controller: CookieAcceptanceRateOverviewController,
        controllerAs: 'vm',
    });

    CookieAcceptanceRateOverviewController.$inject = [
        'ng2CookieBannerRepoService',
        'moment',
        'ng2DateTimeService',
        'COOKIE_CATEGORIES',
        'gettextCatalog',
        '$filter',
        'Lodash',
        'ParamService',
        'ng2DemoHasAccessService',
        'dateRangeService',
        'CookieCategoriesLocalizeService',
        'MONSIDO_COLOR',
    ];

    /* @ngInject */
    function CookieAcceptanceRateOverviewController (
        ng2CookieBannerRepoService,
        moment,
        ng2DateTimeService,
        COOKIE_CATEGORIES,
        gettextCatalog,
        $filter,
        Lodash,
        ParamService,
        ng2DemoHasAccessService,
        dateRangeService,
        CookieCategoriesLocalizeService,
        MONSIDO_COLOR,
    ) {
        const vm = this;
        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;
        vm.loading = true;
        vm.subheaderTranslation = gettextCatalog.getString('An overview of your customer\'s behavior with cookies on your website');
        vm.regulationsTitle = '';
        const basicString = gettextCatalog.getString('Basic');
        const marketingString = gettextCatalog.getString('Marketing');
        const preferencesString = gettextCatalog.getString('Preferences');
        const statisticsString = gettextCatalog.getString('Statistics');
        const visitorString = gettextCatalog.getString('Visitor percentage');

        vm.lineChartSeries = [basicString, preferencesString, marketingString, statisticsString];

        function activate () {
            setupLineChart();
            vm.date = dateRangeService.setRangeDates({
                todayAsDefaultEnd: true,
            });
            updateDateRange();
            getStatisticsData();
        }

        function updateDateRange () {
            const dateParams = ParamService.returnPrevParam();
            if (!ng2DateTimeService.isSame(dateParams.from, vm.date.startDate) || !ng2DateTimeService.isSame(dateParams.to, vm.date.endDate)) {
                vm.loading = true;
                getStatisticsData();
            }
            ParamService.setParams({ from: moment(vm.date.startDate), to: moment(vm.date.endDate) });
        }

        function getStatisticsData () {
            var request = {
                from: vm.date.startDate,
                to: vm.date.endDate,
            };
            vm.isDemo = ng2DemoHasAccessService.isDemoConsentManager();
            if (vm.isDemo) {
                updateStatisticsData(generateData());
                vm.loading = false;
            } else {
                ng2CookieBannerRepoService.getStatistics(request)
                    .then(function (statistics) {
                        updateStatisticsData(statistics);
                    }, angular.noop)
                    .finally(function () {
                        vm.loading = false;
                    });
            }
        }

        function updateStatisticsData (statistics) {
            vm.totalCount = statistics.total_count || 0;
            vm.essentialCount = 0;
            const categoriesMap = getCategoriesMap(statistics.daily_consent_counts);
            const dailyCountMap = getDailyCountsMap(statistics.daily_consent_counts);
            vm.categoriesArr = getCategoriesArr(categoriesMap)
                .filter((category) => {
                    return category.name !== COOKIE_CATEGORIES.essential;
                })
                .map(updateNameTranslations);
            vm.categoriesArr = sortCategories(vm.categoriesArr);
            setupLineChartData(dailyCountMap);
        }

        function getCategoriesArr (categoryMap) {
            const categoriesArr = [];
            vm.essentialCount = categoryMap.get('essential').count;
            if (categoryMap.size) {
                categoryMap.forEach((categoryObj) => {
                    if (categoryObj) {
                        categoriesArr.push({
                            name: categoryObj.category,
                            percentage: vm.essentialCount === 0 ? 0 : (categoryObj.count / vm.essentialCount) * 100,
                            order: categoryObj.order,
                        });
                    }
                });
            }
            return categoriesArr;
        }

        function sortCategories (categoriesArr) {
            return categoriesArr.slice().sort((a, b) => {
                const aOrder = a.order || 100;
                const bOrder = b.order || 100;

                if (aOrder < bOrder) {
                    return -1;
                }

                if (aOrder > bOrder) {
                    return 1;
                }

                return 0;
            });
        }

        function setupLineChartData (dailyCountMap) {
            setupLineChart();
            const sortedMap = getSortedMapByKey(dailyCountMap);
            const basicEntries = [];
            const marketingEntries = [];
            const preferencesEntries = [];
            const statisticsEntries = [];

            if (sortedMap.size) {
                sortedMap.forEach((dailyCountsArr, key, map) => {
                    const dailyTotalCount = Lodash.find(dailyCountsArr, (obj) => {
                        return obj.consent === COOKIE_CATEGORIES.essential;
                    });

                    const newArr = dailyCountsArr
                        .map((obj) => {
                            if (obj.consent && obj.consent !== COOKIE_CATEGORIES.essential) {
                                let percentage = 0;
                                if (dailyTotalCount && dailyTotalCount.count !== 0) {
                                    percentage = (obj.count * 100) / dailyTotalCount.count;
                                }

                                const updatedObj = angular.copy(obj);
                                updatedObj.percentage = percentage.toFixed(2);

                                return updatedObj;
                            }

                            return null;
                        })
                        .filter((elem) => !!elem)
                        .sort((a, b) => {
                            if (a.order < b.order) {
                                return -1;
                            }
                            if (a.order > b.order) {
                                return 1;
                            }

                            return 0;
                        });

                    map.set(key, newArr);
                });

                sortedMap.forEach((item, key) => {
                    const basicItem = Lodash.find(item, (el) => {
                        return el.consent === COOKIE_CATEGORIES.basic;
                    });

                    basicEntries.push(basicItem ? basicItem.percentage : '0');

                    const prefItem = Lodash.find(item, (el) => {
                        return el.consent === COOKIE_CATEGORIES.personalisation;
                    });

                    preferencesEntries.push(prefItem ? prefItem.percentage : '0');

                    const markItem = Lodash.find(item, (el) => {
                        return el.consent === COOKIE_CATEGORIES.marketing;
                    });

                    marketingEntries.push(markItem ? markItem.percentage : '0');

                    const statItem = Lodash.find(item, (el) => {
                        return el.consent === COOKIE_CATEGORIES.analytics;
                    });

                    statisticsEntries.push(statItem ? statItem.percentage : '0');

                    vm.lineChartLabels.push(ng2DateTimeService.format(key,'MMM DD'));
                });

                vm.lineChartData[0] = basicEntries;
                vm.lineChartData[1] = preferencesEntries;
                vm.lineChartData[2] = marketingEntries;
                vm.lineChartData[3] = statisticsEntries;
            }
        }

        function getSortedMapByKey (map) {
            const mapKeys = [];
            const sortedMap = new Map();
            map.forEach(function (value, key) {
                mapKeys.push(key);
            });

            mapKeys.sort((a, b) => {
                if (new Date(a).getTime() < new Date(b).getTime()) {
                    return -1;
                }

                if (new Date(a).getTime() > new Date(b).getTime()) {
                    return 1;
                }

                return 0;
            });

            mapKeys.forEach((key) => {
                sortedMap.set(key, map.get(key));
            });

            return sortedMap;
        }

        function setupLineChart () {
            vm.lineChartData = [[], [], [], []];
            vm.lineChartLabels = [];
            vm.lineChartColors = [MONSIDO_COLOR['branding-6'], MONSIDO_COLOR['branding-4'], '#FDC50D', '#5E3EA8'];
            vm.lineChartOptions = {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            grid: {
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: visitorString,
                                fontStyle: 400,
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: (value) => {
                                    return parseInt(value) + '%';
                                },
                                stepSize: 25,
                                min: 0,
                                max: 100,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 11,
                        boxWidth: 8,
                        boxHeight: 8,
                    },
                },
                tooltips: {
                    callbacks: {
                        label: (item, data) => {
                            const res = data.datasets[item.datasetIndex];
                            return res.label + ': ' + res.data[item.index] + '%';
                        },
                    },
                },
            };

            vm.lineChartOverride = [
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 4,
                    borderWidth: 1,
                    borderColor: MONSIDO_COLOR['branding-6'],
                    backgroundColor: MONSIDO_COLOR['branding-6'],
                    pointHitRadius: 10,
                },
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 4,
                    borderWidth: 1,
                    borderColor: MONSIDO_COLOR['branding-4'],
                    backgroundColor: MONSIDO_COLOR['branding-4'],
                    pointHitRadius: 10,
                },
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 4,
                    borderWidth: 1,
                    borderColor: '#FDC50D',
                    backgroundColor: '#FDC50D',
                    pointHitRadius: 10,
                },
                {
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 4,
                    borderWidth: 1,
                    borderColor: '#5E3EA8',
                    backgroundColor: '#5E3EA8',
                    pointHitRadius: 10,
                },
            ];
        }

        function getCategoriesMap (dailyConsentCounts) {
            const categoriesOrderMap = getCategoriesOrderMap();
            if (dailyConsentCounts && dailyConsentCounts.length) {
                return dailyConsentCounts.reduce((acc, el) => {
                    // only handle cases where consent is !== null (i.e 'essential')
                    if (el.consent !== null) {
                        if (!acc.has(el.consent)) {
                            acc.set(el.consent, {
                                count: el.count,
                                category: el.consent,
                                order: categoriesOrderMap.get(el.consent).order,
                            });
                        } else {
                            const count = acc.get(el.consent).count;
                            acc.set(el.consent, {
                                count: count + el.count,
                                category: el.consent,
                                order: categoriesOrderMap.get(el.consent).order,
                            });
                        }
                    }
                    return acc;
                }, new Map());
            }
            return new Map();
        }

        // getting daily counts for calculating acceptance rate over time excluding essential cookies
        function getDailyCountsMap (dailyConsentCounts) {
            if (Array.isArray(dailyConsentCounts)) {
                return dailyConsentCounts.reduce((acc, el) => {
                    // if (el.consent) {
                    if (!acc.has(el.day)) {
                        acc.set(el.day, [{ count: el.count, day: el.day, consent: el.consent, order: getOrder(el.consent) }]);
                    } else {
                        const elem = acc.get(el.day);
                        elem.push({
                            count: el.count,
                            day: el.day,
                            consent: el.consent,
                            order: getOrder(el.consent),
                        });
                        acc.set(el.day, elem);
                    }
                    // }
                    return acc;
                }, new Map());
            }

            return new Map();
        }

        function getOrder (consent) {
            switch (consent) {
                case COOKIE_CATEGORIES.basic:
                    return 1;
                case COOKIE_CATEGORIES.personalisation:
                    return 2;
                case COOKIE_CATEGORIES.marketing:
                    return 3;
                case COOKIE_CATEGORIES.analytics:
                    return 4;
                default:
                    return 5;
            }
        }

        function getCategoriesOrderMap () {
            const map = new Map();
            map.set(COOKIE_CATEGORIES.basic, {
                order: 1,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.basic],
            });
            map.set(COOKIE_CATEGORIES.personalisation, {
                order: 2,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.personalisation],
            });
            map.set(COOKIE_CATEGORIES.marketing, {
                order: 3,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.marketing],
            });
            map.set(COOKIE_CATEGORIES.analytics, {
                order: 4,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.analytics],
            });
            map.set(COOKIE_CATEGORIES.essential, {
                order: 5,
                name: CookieCategoriesLocalizeService[COOKIE_CATEGORIES.essential],
            });

            return map;
        }

        function generateData () {
            return {
                daily_total_counts: [
                    { day: '2021-07-26', count: 210 },
                    { day: '2021-07-27', count: 75 },
                    { day: '2021-10-26', count: 210 },
                    { day: '2021-11-27', count: 75 },
                    { day: '2021-07-22', count: 210 },
                    { day: '2021-12-27', count: 75 },
                ],
                daily_consent_counts: [
                    { day: '2021-09-26', consent: 'marketing', count: 163 },
                    { day: '2021-10-26', consent: 'personalisation', count: 163 },
                    { day: '2021-11-26', consent: 'analytics', count: 163 },
                    { day: '2021-07-26', consent: null, count: 163 },
                    { day: '2021-07-26', consent: 'analytics', count: 18 },
                    { day: '2021-07-26', consent: 'essential', count: 47 },
                    { day: '2021-07-26', consent: 'marketing', count: 19 },
                    { day: '2021-07-26', consent: 'personalisation', count: 19 },
                    { day: '2021-07-27', consent: null, count: 20 },
                    { day: '2021-07-27', consent: 'analytics', count: 3 },
                    { day: '2021-07-27', consent: 'essential', count: 55 },
                    { day: '2021-07-27', consent: 'marketing', count: 3 },
                    { day: '2021-07-27', consent: 'personalisation', count: 3 },
                ],
                total_count: 285,
            };
        }

        function updateNameTranslations (entry) {
            entry.name = CookieCategoriesLocalizeService[entry.name] || entry.name;
            return entry;
        }
    }
})();
