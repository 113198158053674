(function () {
    'use strict';

    angular.module('pages.login').component('loginReset', {
        templateUrl: 'app/pages/login/reset-password/password-reset.html',
        controller: PasswordResetController,
        controllerAs: 'vm',
    });

    PasswordResetController.$inject = [];
    /* @ngInject */
    function PasswordResetController () {
        var vm = this;

        vm.sendResetRequest = sendResetRequest;
        vm.email = '';

        vm.$onInit = activate;

        function activate () {
            vm.errors = {};
        }

        function sendResetRequest () {}
    }
})();
