export const LOCALE_LANGUAGE_MAP = {
  ar_AE: 'Arabic (United Arab Emirates)',
  ar_BH: 'Arabic (Bahrain)',
  ar_DZ: 'Arabic (Algeria)',
  ar_EG: 'Arabic (Egypt)',
  ar_IQ: 'Arabic (Iraq)',
  ar_JO: 'Arabic (Jordan)',
  ar_KW: 'Arabic (Kuwait)',
  ar_LB: 'Arabic (Lebanon)',
  ar_LY: 'Arabic (Libya)',
  ar_MA: 'Arabic (Morocco)',
  ar_OM: 'Arabic (Oman)',
  ar_QA: 'Arabic (Qatar)',
  ar_SA: 'Arabic (Saudi Arabia)',
  ar_SY: 'Arabic (Syrian Arab Republic)',
  ar_TN: 'Arabic (Tunisia)',
  ar_YE: 'Arabic (Yemen)',
  as_IN: 'Assamese (India)',
  az_Latn_AZ: 'Azerbaijani (Latn-AZ)',
  be_BY: 'Belarusian',
  bg_BG: 'Bulgarian (Bulgaria)',
  bn_BD: 'Bengali (Bangladesh)',
  bn_IN: 'Bengali (India)',
  bs_Latn_BA: 'Bosnian (Latn-BA)',
  ca_ES: 'Catalan',
  ca_ES_valencia: 'Catalan; Valencian (ES-valencia)',
  cs_CZ: 'Czech (Czechia)',
  cy_GB: 'Welsh (United Kingdom)',
  da_DK: 'Danish',
  de_AT: 'German (Austria)',
  de_CH: 'German (Swiss)',
  de_DE: 'German (Germany)',
  de_LI: 'German (Liechtenstein)',
  de_LU: 'German (Luxembourg)',
  el_GR: 'Greek',
  en_029: 'English (029)',
  en_AU: 'English (Australian)',
  en_BZ: 'English (Belize)',
  en_CA: 'English (Canadian)',
  en_GB: 'English (GB)',
  en_HK: 'English (Hong Kong)',
  en_ID: 'English (Indonesia)',
  en_IE: 'English (Ireland)',
  en_IN: 'English (India)',
  en_JM: 'English (Jamaica)',
  en_LR: 'English (Liberia)',
  en_MY: 'English (Malaysia)',
  en_NZ: 'English (New Zealand)',
  en_PH: 'English (Philippines)',
  en_SG: 'English (Singapore)',
  en_TT: 'English (Trinidad and Tobago)',
  en_US: 'English (US)',
  en_ZA: 'English (South African)',
  en_ZW: 'English (Zimbabwe)',
  es_419: 'Spanish; Castilian (419)',
  es_AR: 'Spanish; Castilian (Argentina)',
  es_BO: 'Spanish; Castilian (Bolivia)',
  es_CL: 'Spanish; Castilian (Chile)',
  es_CO: 'Spanish; Castilian (Colombia)',
  es_CR: 'Spanish; Castilian (Costa Rica)',
  es_DO: 'Spanish; Castilian (Dominican Republic)',
  es_EC: 'Spanish; Castilian (Ecuador)',
  es_ES: 'Spanish (Spain)',
  es_ES_tradnl: 'Spanish; Castilian (ES_tradnl)',
  es_GT: 'Spanish; Castilian (Guatemala)',
  es_HN: 'Spanish; Castilian (Honduras)',
  es_MX: 'Spanish (Mexico)',
  es_NI: 'Spanish; Castilian (Nicaragua)',
  es_PA: 'Spanish; Castilian (Panama)',
  es_PE: 'Spanish; Castilian (Peru)',
  es_PR: 'Spanish; Castilian (Puerto Rico)',
  es_PY: 'Spanish; Castilian (Paraguay)',
  es_SV: 'Spanish; Castilian (El Salvador)',
  es_US: 'Spanish; Castilian (United States)',
  es_UY: 'Spanish; Castilian (Uruguay)',
  es_VE: 'Spanish; Castilian (Venezuela)',
  et_EE: 'Estonian (Estonia)',
  eu_ES: 'Basque (Spain)',
  fa_IR: 'Persian (Iran: Islamic Republic of)',
  fi_FI: 'Finnish',
  fr_015: 'French (015)',
  fr_029: 'French (029)',
  fr_BE: 'French (Belgium)',
  fr_CA: 'French (Canada)',
  fr_CD: 'French (Congo:  The Democratic Republic of the)',
  fr_CH: 'French (Switzerland)',
  fr_CI: 'French (Côte d\'Ivoire)',
  fr_CM: 'French (Cameroon)',
  fr_FR: 'French',
  fr_HT: 'French (Haiti)',
  fr_LU: 'French (Luxembourg)',
  fr_MA: 'French (Morocco)',
  fr_MC: 'French (Monaco)',
  fr_ML: 'French (Mali)',
  fr_RE: 'French (Réunion)',
  fr_SN: 'French (Senegal)',
  ga_IE: 'Irish (Ireland)',
  gd_GB: 'Gaelic; Scottish Gaelic (United Kingdom)',
  gl_ES: 'Galician',
  gu_IN: 'Gujarati (India)',
  ha_Latn_NG: 'Hausa (Latn-NG)',
  he_IL: 'Hebrew (Israel)',
  hi_IN: 'Hindi (India)',
  hr_BA: 'Croatian (Bosnia and Herzegovina)',
  hr_HR: 'Croatian (Croatia)',
  hu_HU: 'Hungarian (Hungary)',
  hy_AM: 'Armenian (Armenia)',
  id_ID: 'Indonesian (Indonesia)',
  ig_NG: 'Igbo (Nigeria)',
  is_IS: 'Icelandic',
  it_CH: 'Italian (Switzerland)',
  it_IT: 'Italian',
  ja_JP: 'Japanese',
  ja_Latn_JP_x_ext: 'Japanese (Latn-JP-x-ext)',
  ka_GE: 'Georgian (Georgia)',
  kk_KZ: 'Kazakh (Kazakhstan)',
  km_KH: 'Khmer',
  kn_IN: 'Kannada (India)',
  kok_Deva_IN: 'kok (Deva-IN)',
  ky_KG: 'Kirghiz; Kyrgyz (Kyrgyzstan)',
  lb_LU: 'Luxembourgish; Letzeburgesch (Luxembourg)',
  lt_LT: 'Lithuanian',
  lv_LV: 'Latvian (Latvia)',
  mi_NZ: 'Maori (New Zealand)',
  mk_MK: 'Macedonian (Macedonia: Republic of)',
  ml_IN: 'Malayalam',
  mr_IN: 'Marathi (India)',
  ms_BN: 'Malay (Brunei Darussalam)',
  ms_MY: 'Malay (Malaysia)',
  mt_MT: 'Maltese (Malta)',
  nb_NO: 'Norwegian Bokmål',
  ne_IN: 'Nepali (India)',
  ne_NP: 'Nepali (Nepal)',
  nl_BE: 'Dutch; Flemish (Belgium)',
  nl_NL: 'Dutch',
  nn_NO: 'Norwegian Nynorsk',
  nso_ZA: 'nso (South Africa)',
  or_IN: 'Oriya (India)',
  pa_IN: 'Panjabi; Punjabi (India)',
  pl_PL: 'Polish',
  ps_AF: 'Pushto; Pashto (Afghanistan)',
  pt_BR: 'Portuguese (Brazil)',
  pt_PT: 'Portuguese (Portugal)',
  rm_CH: 'Romansh (Switzerland)',
  ro_MO: 'Romanian; Moldavian; Moldovan (Macao)',
  ro_RO: 'Romanian',
  ru_MO: 'Russian (Macao)',
  ru_RU: 'Russian',
  rw_RW: 'Kinyarwanda (Rwanda)',
  si_LK: 'Sinhala; Sinhalese (Sri Lanka)',
  sk_SK: 'Slovak',
  sl_SI: 'Slovenian',
  sq_AL: 'Albanian (Albania)',
  sr_Cyrl_BA: 'Serbian (Cyrl-BA)',
  sr_Cyrl_CS: 'Serbian (Cyrl-CS)',
  sr_Cyrl_ME: 'Serbian (Cyrl-ME)',
  sr_Cyrl_RS: 'Serbian (Cyrl-RS)',
  sr_Latn_BA: 'Serbian (Latn-BA)',
  sr_Latn_CS: 'Serbian (Latn-CS)',
  sr_Latn_ME: 'Serbian (Latn-ME)',
  sr_Latn_RS: 'Serbian (Latn-RS)',
  sv_FI: 'Swedish (Finland)',
  sv_SE: 'Swedish',
  sw_KE: 'Swahili (Kenya)',
  ta_IN: 'Tamil',
  ta_LK: 'Tamil (Sri Lanka)',
  te_IN: 'Telugu (India)',
  tl_PH: 'Tagalog',
  tn_BW: 'Tswana (Botswana)',
  tn_ZA: 'Tswana (South Africa)',
  tr_TR: 'Turkish (Turkey)',
  tt_RU: 'Tatar (Russian Federation)',
  uk_UA: 'Ukrainian',
  ur_IN: 'Urdu (India)',
  ur_PK: 'Urdu (Pakistan)',
  uz_Latn_UZ: 'Uzbek (Latn-UZ)',
  vi_VN: 'Vietnamese (Vietnam)',
  wo_SN: 'Wolof (Senegal)',
  xh_ZA: 'Xhosa (South Africa)',
  yo_NG: 'Yoruba (Nigeria)',
  zh_CN: 'Chinese',
  zh_Latn_CN_x_ext: 'Chinese (Latn-CN-x-ext)',
  zu_ZA: 'Zulu (South Africa)',
} as const;

export type LOCALE_LANGUAGE_MAP_TYPE = keyof typeof LOCALE_LANGUAGE_MAP;
export type LOCALE_LANGUAGE = typeof LOCALE_LANGUAGE_MAP[LOCALE_LANGUAGE_MAP_TYPE]

