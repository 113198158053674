import { Injectable } from '@angular/core';

@Injectable()
export class MonBrowserService {
    constructor () {}

    isIe11 (): boolean {
        return !!(window.document as unknown as {documentMode: unknown}).documentMode;
    }

    isEdgeLegacy (): boolean {
        return navigator.userAgent.indexOf('Edge/') > -1;
    }
}
