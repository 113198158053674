import { Injectable } from '@angular/core';

export type ElementOptionType = {
    name: string;
    value: string;
};

@Injectable({
    providedIn: 'root',
})
export class PageFixFormHtmlElementOptionsService {
    elementOptions: ElementOptionType[] = [];

    constructor () {
        const elements: string[] = ['em', 'p', 'strong', 'mark'];

        this.elementOptions = elements.map(element => ({
            name: `<${element}>`,
            value: element,
        }));
    }

    getOptions (): ElementOptionType[] {
        return this.elementOptions;
    }
}
