(function () {
    'use strict';
    angular.module('modules.light.pages').component('domainsTableView', {
        templateUrl: 'app/modules/light/components/domainsTableView/domainsTableView.template.html',
        controller: DomainsTableViewComponent,
        controllerAs: 'vm',
        bindings: {
            domains: '<',
            isTableView: '<',
        },
    });

    DomainsTableViewComponent.$inject = [
        'LITE_DOMAIN_EVENTS',
        'activeFeatures',
        'ng2DomainService',
        'ng2MonEventsService',
        '$controller',
        'ng2LightDomainViewService',
    ];

    function DomainsTableViewComponent (
        LITE_DOMAIN_EVENTS,
        activeFeatures,
        ng2DomainService,
        ng2MonEventsService,
        $controller,
        ng2LightDomainViewService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.goToDashboard = goToDashboard;
        vm.updateDomainByLabel = updateDomainByLabel;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.showPin = {};
            vm.showLabels = activeFeatures.labels;
            vm.currentSort = { by: 'favorite', direction: 'asc' };
        }

        function updateDomainByLabel (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });
            return ng2DomainService.update(domain.id, params);
        }

        function getPage () {
            var paramsOptions = {
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };
            ng2MonEventsService.run(LITE_DOMAIN_EVENTS.LITE_DOMAINS_LIST_UPDATE_REQUIRED, paramsOptions);
        }

        function goToDashboard (domain) {
            ng2LightDomainViewService.goToDashboard(domain);
        }
    }
})();
