import { PAGE_FIX_FIXABLE_TYPES } from '@client/app/modules/page-fix/constants/page-fix-fixable-types';

type DropDownOption = {
    action: () => void
    disabled: boolean
    label: string
    leftIcon: string
}

export type SpellingModelType = {
    classification: string
    created_at: string
    documents_count: number
    domain_id: number
    groups: number[]
    html_pages_count: number
    id: number
    language: string
    updated_at: string
    word: string
    labels: string[]
    page_fixed: boolean
}

export class SpellingModel implements SpellingModelType {
    classification: string;
    created_at: string;
    documents_count: number;
    domain_id: number;
    dropdown: DropDownOption[] = [];
    groups: number[];
    html_pages_count: number;
    id: number;
    language: string;
    updated_at: string;
    word: string;
    labels: string[];
    page_fixed: boolean;

    constructor (data: SpellingModelType) {
        this.classification = data.classification;
        this.created_at = data.created_at;
        this.documents_count = data.documents_count;
        this.domain_id = data.domain_id;
        this.groups = [...data.groups];
        this.html_pages_count = data.html_pages_count;
        this.id = data.id;
        this.language = data.language;
        this.updated_at = data.updated_at;
        this.word = data.word;
        this.labels = data.labels;
        this.page_fixed = data.page_fixed;
    }

    getPageFixType (): string {
        return PAGE_FIX_FIXABLE_TYPES.misspelling;
    }
}
