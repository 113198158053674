import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';
    /**
     * @memberof services.entities.user
     * @ngdoc factory
     * @name userService
     * @description Service to handle users
     */
    angular.module('services.entities.user').factory('userService', userService);

    userService.$inject = ['UserRepo', 'ng2CacheService', '$q', 'Lodash'];
    /* @ngInject*/
    function userService (UserRepo, ng2CacheService, $q, Lodash) {
        return {
            create: create,
            update: update,
            get: get,
            getAll: getAll,
            destroy: destroy,
            tryGetUsersFromCache: tryGetUsersFromCache,
        };

        /**
         * @memberOf userService
         * @desc Create user
         * @param {object} user The user to create
         * @returns {Promise} Promise containing the user that is created
         */
        function create (user) {
            return UserRepo.create(user).then(async function (returnUser) {
                const users = await tryGetUsersFromCache();
                delete returnUser.domain_users;
                const updatedUsers = {
                    ...users,
                    [returnUser.id]: returnUser,
                };
                ng2CacheService.set('users', updatedUsers);
                return returnUser;
            }, angular.noop);
        }

        /**
         * @memberOf userService
         * @desc Update user
         * @param {object} user The user to update
         * @returns {Promise} Promise containing the user that is updated
         */
        function update (user) {
            return UserRepo.update(user).then(async function (returnUser) {
                const users = await tryGetUsersFromCache();
                delete returnUser.domain_users;
                const updatedUsers = {
                    ...users,
                    [returnUser.id]: returnUser,
                };
                ng2CacheService.set('users', updatedUsers);
                return returnUser;
            }, angular.noop);
        }

        /**
         * @memberOf userService
         * @desc Get user
         * @param {object} userId The userId to use
         * @param {boolean} byPassCache The userId to use
         * @returns {Promise} Promise containing the user that is updated
         */
        async function get (userId, byPassCache) {
            const deferred = $q.defer();
            let user;
            if (byPassCache !== true) {
                const users = await tryGetUsersFromCache();
                if (users) {
                    user = users[String(userId)];
                }
            }
            if (user === undefined && userId !== undefined) {
                UserRepo.get(userId, {}, contextTokens.NO_GLOBAL).then(
                    async (returnUser) => {
                        const tempUser = Lodash.cloneDeep(returnUser);
                        const users = await tryGetUsersFromCache();
                        delete returnUser.domain_users;
                        const updatedUsers = {
                            ...users,
                            [returnUser.id]: returnUser,
                        };
                        ng2CacheService.set('users', updatedUsers);
                        deferred.resolve(tempUser);
                    },
                    function () {
                        deferred.reject('Can\'t find the user');
                    },
                );
            } else {
                deferred.resolve(user);
            }
            return deferred.promise;
        }

        function getAll (params, config) {
            return UserRepo.getAll(params);
        }

        function destroy (user, config) {
            return UserRepo.destroy(user, config);
        }

        async function tryGetUsersFromCache (params = { page: 1, page_size: 50 }) {
            let users = ng2CacheService.get('users');
            if (!users) {
                const fetchedUsers = [];
                users = await getUsers(params, fetchedUsers);
            }
            return users;
        }

        function getUsers (params, fetchedUsers) {
            return getAll(params).then(async (users) => {
                fetchedUsers = [...fetchedUsers, ...users];
                if (users.length === params.page_size) {
                    params.page++;
                    return getUsers(params, fetchedUsers);
                }
                const mappedUsers = fetchedUsers.map((user) => {
                    delete user.domain_users;
                    return user;
                });
                const cacheUsers = mappedUsers.reduce((array, user) => ({ ...array, [user.id]: user }), {});
                ng2CacheService.set('users', cacheUsers);
                return cacheUsers;
            }, angular.noop);
        }
    }
})();
