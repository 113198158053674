(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionSeo', {
        templateUrl: 'app/components/pagedetails/details/sections/seo/seo.html',
        controller: SectionSeoController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            refreshPage: '&',
            active: '<',
        },
    });

    SectionSeoController.$inject = ['ng2PageRepoService', '$q'];
    /* @ngInject */
    function SectionSeoController (ng2PageRepoService, $q) {
        var vm = this;
        vm.$onInit = activate;
        vm.refreshPageSeoIssues = refreshIssues;
        vm.setPageSeoIssue = setPageSeoIssue;

        function activate () {
            vm.page = vm.page || {};
            if (vm.active !== null && typeof vm.active === 'object' && !Array.isArray(vm.active)) {
                vm.pageSeoIssue = vm.active;
                vm.pageSeoIssue.translation_name = vm.active.name;
            } else {
                vm.pageSeoIssue = null;
            }
            vm.pageSeoIssues = [];
            refreshIssues();
        }

        function refreshIssues (refreshPage) {
            vm.progress = getPageSeoIssues().then(function (seoIssues) {
                vm.pageSeoIssues = sortPageSeoIssues(seoIssues);

                if (angular.isObject(vm.pageSeoIssue)) {
                    vm.pageSeoIssue = vm.pageSeoIssues.find(function (issue) {
                        return issue.translation_name === vm.pageSeoIssue.translation_name;
                    });
                } else {
                    vm.pageSeoIssue = vm.pageSeoIssues[0];
                }
                setPageSeoIssue(vm.pageSeoIssue);
            }, angular.noop);

            if (typeof refreshPage === 'boolean' && refreshPage === true) {
                vm.refreshPage({ target: 'page-details-section-seo' });
            }
        }

        function getPageSeoIssues () {
            var params = {
                page: 1,
                page_size: 10000,
            };

            return ng2PageRepoService.getSeoIssues(vm.page.id, params).then(function (data) {
                vm.pageSeoIssuesNotInComplianceCount = data.filter(function (issue) {
                    return issue.issues.length > 0;
                }).length;
                return data;
            }, $q.reject);
        }

        function sortPageSeoIssues (issues) {
            return issues
                .map(function (issue) {
                    issue.ignored_on_page = isSeoCheckpointIsIgnored(issue);
                    issue.fixed = checkIfAllAreFixed(issue);
                    return issue;
                })
                .sort(function (a, b) {
                    if (a.ignored_on_page || b.ignored_on_page) {
                        // Order by ignored
                        if (a.ignored_on_page && !b.ignored_on_page) {
                            return 1;
                        } else if (b.ignored_on_page && !a.ignored_on_page) {
                            return -1;
                        }
                    }

                    if (b.fixed || a.fixed) {
                        // Order by fixed
                        if (a.fixed && !b.fixed) {
                            return 1;
                        } else if (b.fixed && !a.fixed) {
                            return -1;
                        }
                    }

                    // Order by classification
                    return a.classification > b.classification ? 1 : a.classification < b.classification ? -1 : 0;
                });
        }

        function isSeoCheckpointIsIgnored (seoCheckpoint) {
            if (seoCheckpoint.ignored) {
                return true;
            } else {
                var issues = seoCheckpoint.issues;
                if (issues.length > 0) {
                    return issues[0].ignored_at !== null;
                }
            }

            return false;
        }

        function checkIfAllAreFixed (seoCheckpoint) {
            if (seoCheckpoint.ignored) {
                return false;
            }

            if (seoCheckpoint.issues.length === 0) {
                return true;
            }

            var issue = seoCheckpoint.issues[0];
            return issue.marked_as_fixed_at !== null;
        }

        function setPageSeoIssue (seoIssue) {
            vm.pageSeoIssue = seoIssue;
            if (angular.isObject(seoIssue) && !seoIssue.ignored && !seoIssue.fixed) {
                ng2PageRepoService.getSeoIssueContent(vm.page.id, vm.pageSeoIssue.issues[0].id).then(function (data) {
                    vm.pageSeoIssue.content = data;
                }, angular.noop);
            }
        }
    }
})();
