(function () {
    'use strict';
    /**
     * @memberof modules.source-code-excludes
     * @ngdoc factory
     * @name SourceCodeExcludesApiService
     * @description Source Code Excludes Service
     */
    angular
        .module('modules.source-code-excludes')
        .factory('SourceCodeExcludesApiService', SourceCodeExcludesApiService);

    SourceCodeExcludesApiService.$inject = [
        'AccessibilitySourceCodeExcludesEntity',
        'AccessibilitySourceCodeExcludeRepo',
        'SourceCodeExcludesEntity',
        'SourceCodeExcludeRepo',
    ];
    /* @ngInject*/
    function SourceCodeExcludesApiService (
        AccessibilitySourceCodeExcludesEntity,
        AccessibilitySourceCodeExcludeRepo,
        SourceCodeExcludesEntity,
        SourceCodeExcludeRepo,
    ) {
        return {
            getAll: getAll,
            get: get,
            create: create,
            update: update,
            destroy: destroy,
        };

        function getAll (module, params, config) {
            if (module === 'accessibility') {
                return getAccessibilitySourceCodeExcludes(params, config);
            } else {
                return getSourceCodeExcludes(module, params, config);
            }
        }

        function get (module, sourceCodeExclude, params, config) {
            if (module === 'accessibility') {
                return getAccessibilitySourceCodeExclude(sourceCodeExclude, params, config);
            } else {
                return getSourceCodeExclude(sourceCodeExclude, params, config);
            }
        }

        function create (module, sourceCodeExclude, params, config) {
            if (module === 'accessibility') {
                return createAccessibilitySourceCodeExclude(sourceCodeExclude, params, config);
            } else {
                return createSourceCodeExclude(sourceCodeExclude, params, config);
            }
        }

        function update (module, sourceCodeExclude, updatedSourceCodeExclude, params, config) {
            if (module === 'accessibility') {
                return updateAccessibilitySourceCodeExclude(
                    sourceCodeExclude,
                    updatedSourceCodeExclude,
                    params,
                    config,
                );
            } else {
                return updateSourceCodeExclude(sourceCodeExclude, updatedSourceCodeExclude, params, config);
            }
        }

        function destroy (module, sourceCodeExclude, params, config) {
            if (module === 'accessibility') {
                return destroyAccessibilitySourceCodeExcludes(sourceCodeExclude, params, config);
            } else {
                return destroySourceCodeExcludes(sourceCodeExclude, params, config);
            }
        }

        // PROTECTED

        function getAccessibilitySourceCodeExcludes (params, config) {
            return AccessibilitySourceCodeExcludeRepo.getAll(params, config);
        }

        function getSourceCodeExcludes (module, params, config) {
            params = params || {};
            params.module = module;
            return SourceCodeExcludeRepo.getAll(params, config);
        }

        function getAccessibilitySourceCodeExclude (sourceCodeExclude, params, config) {
            return AccessibilitySourceCodeExcludeRepo.get(sourceCodeExclude.id, params, config);
        }

        function getSourceCodeExclude (sourceCodeExclude, params, config) {
            params = params || {};
            params.module = module;
            return SourceCodeExcludeRepo.get(sourceCodeExclude.id, params, config);
        }

        function createSourceCodeExclude (sourceCodeExclude, params, config) {
            return SourceCodeExcludesEntity.create(sourceCodeExclude, params, config);
        }

        function createAccessibilitySourceCodeExclude (sourceCodeExclude, params, config) {
            return AccessibilitySourceCodeExcludeRepo.create(sourceCodeExclude, params, config);
        }

        function updateSourceCodeExclude (sourceCodeExclude, updatedSourceCodeExclude, params, config) {
            return SourceCodeExcludesEntity.update(sourceCodeExclude, updatedSourceCodeExclude, params, config);
        }

        function updateAccessibilitySourceCodeExclude (sourceCodeExclude, updatedSourceCodeExclude, params, config) {
            return AccessibilitySourceCodeExcludesEntity.update(
                sourceCodeExclude,
                updatedSourceCodeExclude,
                params,
                config,
            );
        }

        function destroySourceCodeExcludes (sourceCodeExclude, params, config) {
            return SourceCodeExcludesEntity.destroy(sourceCodeExclude, params, config);
        }

        function destroyAccessibilitySourceCodeExcludes (sourceCodeExclude, params, config) {
            return AccessibilitySourceCodeExcludesEntity.destroy(sourceCodeExclude, params, config);
        }
    }
})();
