(function () {
    'use strict';

    angular.module('modules.data_privacy.services.api').factory('DataPrivacyViolationRepo', DataPrivacyViolationRepo);

    DataPrivacyViolationRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'DataPrivacyViolationModel'];
    /* @ngInject */
    function DataPrivacyViolationRepo (ng2ApiService, ng2SessionService, DataPrivacyViolationModel) {
        var service = {
            getAll: getAll,
            get: get,
            update: update,
            updatePageViolation: updatePageViolation,
        };

        return service;

        // /////////////

        function getAll (page_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            if (page_id) {
                return ng2ApiService.get(
                    'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/data_protection/violations',
                    params,
                    config,
                ).then(toModels);
            } else {
                return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/data_protection/violations', params, config).then(
                    toModels,
                );
            }
        }

        function update (violation, params) {
            params = params || {};

            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/data_protection/violations/' + violation.id,
                violation,
                params,
            ).then(toModel);
        }

        function get (page_id, violation_id, params, config) {
            config = config || {};
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/data_protection/violations/' + violation_id,
                params,
                config,
            ).then(toModel);
        }

        function updatePageViolation (page_id, violation, params) {
            params = params || {};
            params.group_id = ng2SessionService.getGroupId(params);

            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/data_protection/violations/' + violation.id,
                violation,
                params,
            ).then(toModel);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new DataPrivacyViolationModel(model);
        }
    }
})();
