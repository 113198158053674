(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name ClarityService
     * @description Service to handle Clarity Accessibility requests
     */
    angular.module('services.entities').factory('ClarityService', ClarityService);

    ClarityService.$inject = ['ng2ClarityRepoService', 'ng2DemoHasAccessService', 'ng2DemoPdfClarityRepoService'];
    /* @ngInject*/
    function ClarityService (ng2ClarityRepoService, ng2DemoHasAccessService, ng2DemoPdfClarityRepoService) {
        return {
            getSummary: getSummary,
        };

        /**
         * @memberOf ClarityService
         * @desc Summary of Accessibility Clarity information
         * @param {object} params - The parameters to inject to the get request
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the updated PDF Document
         */
        function getSummary (params, config) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return ng2DemoPdfClarityRepoService.getSummary(params, config);
            } else {
                return ng2ClarityRepoService.getSummary(params, config);
            }
        }
    }
})();
