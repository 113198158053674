(function () {
    'use strict';

    angular.module('modules.export').filter('exportName', exportName);

    exportName.$inject = ['gettextCatalog'];
    /* @ngInject */
    function exportName (gettextCatalog) {
        return filter;

        // //////////

        function filter (input) {
            switch (input) {
                case 'pages_documents':
                    return gettextCatalog.getString('Documents extended');

                case 'inventory_document_pages':
                    return gettextCatalog.getString('Documents basic');

                case 'dead_page_links':
                    return gettextCatalog.getString('Broken Page Link');
                case 'scan_history_overview':
                    return gettextCatalog.getString('Compound Report');
                case 'site_search_keywords':
                    return gettextCatalog.getString('Site Search - Search Terms');
                case 'site_search_no_result_keywords':
                    return gettextCatalog.getString('Site Search No Results Search Terms');
                default:
                    return input.replace(/_/g, ' ');
            }
        }
    }
})();
