(function () {
    'use strict';

    angular.module('filters.monsido').filter('monConfigActive', ConfigActiveFilter);

    ConfigActiveFilter.$inject = ['chatSystems', 'activeChatService'];

    /* @ngInject */
    function ConfigActiveFilter (chatSystems, activeChatService) {
        return isActive;

        // //////////

        function isActive (config) {
            switch (config) {
                case 'intercom':
                    return Intercom();
                case 'velaro':
                    return Velaro();
                case 'zendesk':
                    return Zendesk();
                default:
                    return false;
            }
        }

        /**
         * @return {boolean}
         */
        function Intercom () {
            return activeChatService.getActiveChat().system === 'intercom';
        }

        /**
         * @return {boolean}
         */
        function Velaro () {
            return activeChatService.getActiveChat().system === 'velaro';
        }

        /**
         * @return {boolean}
         */
        function Zendesk () {
            return activeChatService.getActiveChat().system === 'zendesk';
        }
    }
})();
