<form name="form" #consentManagerForm="ngForm" (ngSubmit)="submit(consentManagerForm, $event)"
      class="form-domain-script-guide-features-cookie-banner-settings">

    <div class="mon-dialog-header">
        <mon-page-header
            header="{{'Consent Manager script settings' | translate }}" [panelLeftClass]="'col-48 mt-2'">
        </mon-page-header>
    </div>

    <div #dialogBody *ngIf="dataModel && options" class="mon-dialog-body-with-footer bg-body">
        <div class="card panel-monsido-card mt-4 mx-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-48">
                        <h2 class="font-weight-300 font-size-18 mt-4 mb-1 ml-4" translate>Consent Manager pop-up setup</h2>
                    </div>
                    <hr class="mt-0 mb-4 col"/>

                </div>

                <div class="row">
                    <div class="col-48 col-md-36 mt-3">
                        <div class="form-group row">

                            <label class="col-form-label text-right text-bold col-sm-11" translate>
                                Consent Manager pop-up position on Page
                            </label>
                            <div class="col-auto">
                                <div class="consent-manager-position-board d-flex flex-wrap align-items-stretch">
                                    <ng-container  *ngFor="let direction of options.directions; let i = index">
                                        <div class="col-16 px-0 position-radio-container text-center flex-fill">
                                            <label for="{{i}}_page_assist_direction_value">
                                                <input type="radio"
                                                        name="direction"
                                                        ngModel
                                                        [value]="direction.value"
                                                        (change)="dataModel.direction = direction.value;"
                                                        class="sr-only"
                                                        id="{{i}}_page_assist_direction_value"
                                                        [attr.checked]="dataModel.direction === direction.value ? true : null">
                                                <span class="position-radio">&nbsp;</span>
                                                <span class="sr-only">{{direction.value}}</span>
                                            </label>
                                        </div>
                                        <div *ngIf="i === 3" class="col-16 px-0">
                                            &nbsp;
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="row mr-lg-0 widget-title">
                            <div class="col-48">
                                <h2 class="font-weight-300 font-size-18 mt-4 mb-1 ml-4" translate>Consent Manager configuration</h2>
                            </div>
                            <hr class="mt-0 mb-4 col-48"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-48 col-md-36 mt-3">

                        <div class="form-group row">

                                <label class="col-sm-11 text-right align-self-center mt-1 pr-xl-2"
                                       translate>
                                    Privacy regulation
                                </label>
                                <div class="col-sm-37 d-flex align-items-center mt-sm-0 mt-lg-1">
                                    <ng-container  *ngFor="let regulation of privacyRegulations; let i = index">
                                        <label class="radio-inline mr-2 privacy-regulation" for="{{i}}_consent_manager_privacy_regulation">
                                            <input class="text-v-middle"
                                                   type="radio"
                                                   name="privacyRegulation"
                                                   [(ngModel)]="selectedRegulationAsString"
                                                   [checked]="selectedRegulationAsString === regulation.value"
                                                   [value]="regulation.value"
                                                   (change)="setPrivacyRegulation(regulation.value)"
                                                   id="{{i}}_consent_manager_privacy_regulation">
                                            <span class="privacy-regulation-name pr-1">{{regulation.name | translate}}</span>
                                            <span *ngIf="regulation.tooltip" class="fas fa-question-circle py-1 inline-block"
                                                  aria-hidden="true"
                                                  ngbTooltip="{{regulation.tooltip | translate}}"
                                                  placement="auto"
                                                  container="body">
                                        </span>
                                        </label>
                                    </ng-container>

                                </div>
                        </div>

                        <ng-container *ngIf="showGDPROptions">
                            <div class="form-group row">
                                <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                    <label class="col-form-label text-bold inline-block pr-1"
                                           for="consent_manager_per_category_consent" translate>
                                        Per category consent
                                    </label>
                                    <span class="fas fa-question-circle py-1 inline-block"
                                          aria-hidden="true"
                                          ngbTooltip="{{'Give users more control over which categories of cookies to consent to' | translate}}"
                                          placement="auto"
                                          container="body">
                                </span>
                                </div>
                                <div class="col-sm-37 d-flex align-items-center mt-sm-0 mt-lg-1">
                                    <input id="consent_manager_per_category_consent"
                                           name="per_category_consent"
                                           type="checkbox"
                                           [(ngModel)]="dataModel.per_category_consent" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                    <label class="col-form-label text-bold inline-block pr-1" for="explicit_reject_option"
                                           translate>
                                        Explicit reject option
                                    </label>
                                    <span class="fas fa-question-circle py-1 inline-block"
                                          aria-hidden="true"
                                          ngbTooltip="{{'Changes wording from only essential cookies to explicit reject' | translate}}"
                                          placement="auto"
                                          container="body">
                                </span>
                                </div>
                                <div class="col-sm-37 d-flex align-items-center mt-sm-0 mt-lg-1">
                                    <input id="explicit_reject_option"
                                           name="explicit_reject_option"
                                           type="checkbox"
                                           [(ngModel)]="dataModel.explicit_reject_option" />
                                </div>
                            </div>
                        </ng-container>

                        <div class="form-group row" *ngIf="showCCPAOptions">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="opt_out_url" translate>
                                    Opt out link
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                      aria-hidden="true"
                                      ngbTooltip="{{'Link where customers can opt out of sale of personal data. Mandatory for websites under the CCPA regulation.' | translate}}"
                                      placement="auto"
                                      container="body">
                                </span>
                            </div>
                            <div class="col-sm-37">
                                <input id="opt_out_url"
                                       (focus)="highlightTitle = true"
                                       (blur)="highlightTitle = false"
                                       required
                                       monValidateUrl
                                       #optOutUrlInput="ngModel"
                                       (mouseover)="highlightTitle = true"
                                       (mouseleave)="highlightTitle = false"
                                       placeholder="{{'Link to your opt out page' | translate}}"
                                       type="text"
                                       [(ngModel)]="dataModel.optOutUrl"
                                       name="opt_out_url"
                                       maxlength="70"
                                       class="form-control"/>
                                <mon-form-field-errors-ac
                                    [offset]="-8"
                                    [formElementControl]="optOutUrlInput.control"
                                ></mon-form-field-errors-ac>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="cookie_policy_url" translate>
                                    Link to Privacy Policy
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                      aria-hidden="true"
                                      ngbTooltip="{{'Include a link to your webpage\'s privacy policy' | translate}}"
                                      placement="auto"
                                      container="body">
                                </span>
                            </div>
                            <div class="col-sm-37">
                                <input id="cookie_policy_url"
                                       (focus)="highlightTitle = true"
                                       (blur)="highlightTitle = false"
                                       (mouseover)="highlightTitle = true"
                                       (mouseleave)="highlightTitle = false"
                                       placeholder="{{'Link to your Privacy Policy' | translate}}"
                                       type="text"
                                       required
                                       monValidateUrl
                                       #privacyPolicyUrlInput="ngModel"
                                       [(ngModel)]="dataModel.cookie_policy_url"
                                       name="cookie_policy_url"
                                       maxlength="70"
                                       class="form-control"/>
                                <mon-form-field-errors-ac
                                    [offset]="-8"
                                    [formElementControl]="privacyPolicyUrlInput.control"
                                ></mon-form-field-errors-ac>
                            </div>
                        </div>

                        <div class="form-group row cookieOverview">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" translate>
                                    Cookie Overview
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                      aria-hidden="true"
                                      ngbTooltip="{{'GDPR requires that companies provide descriptions on their website for all cookies that are used.' | translate}}"
                                      placement="auto"
                                      container="body">
                                </span>
                            </div>
                            <div class="col-37 d-flex">
                                <div class="flex-grow-1 pr-0 mr-0 cookie-overview-selection" *ngIf="dataModel.cookieOverviewLinkEnabled; else cookieSetupInfo">
                                    <input id="cookie_banner_cookie_overview_url"
                                           placeholder="{{'Link to your Cookie Overview' | translate}}"
                                           type="text"
                                           name="linkToCookieOverview"
                                           [(ngModel)]="dataModel.linkToCookieOverview"
                                           (change)="isCookieOverviewLinkValid = isValidUrl(dataModel.linkToCookieOverview, 'linkToCookieOverview')"
                                           class="form-control"/>
                                    <p *ngIf="!isCookieOverviewLinkValid" class="mt-1 text-danger"
                                       translate>
                                        URL needs to be valid, include http:// or https:// and be under 100 characters
                                    </p>
                                </div>
                                <ng-template #cookieSetupInfo>
                                    <div  class="flex-grow-1 pr-0 mr-0 cookie-overview-selection" >
                                        <p translate>
                                            For Cookie setup please go to your <b>Data Privacy</b> module.<br> Manual cookies can be set up or edited any time and are not related to script setup.
                                        </p>
                                    </div>
                                </ng-template>
                                <div class="cookie-overview-buttons-wrapper">
                                    <div class="btn-group cookie-overview-buttons" role="group" [attr.aria-label]="'Cookie Overview buttons' | translate">

                                        <button class="btn btn-secondary" type="button"  (click)="setCookieOverview(false)"
                                                mon-event-trigger="actionTrigger" mon-event-params="{action: 'manage-cookie-overview'}" [attr.aria-pressed]="!dataModel.cookieOverviewLinkEnabled"
                                                [attr.aria-label]="'Manage Cookie ' | translate" translate>Manage</button>
                                        <button class="btn btn-secondary" type="button" (click)="setCookieOverview(true)"
                                                mon-event-trigger="actionTrigger" mon-event-params="{action: 'cookie-overview-link'}" [attr.aria-pressed]="dataModel.cookieOverviewLinkEnabled"
                                                [attr.aria-label]="'Link to your Cookie Overview' | translate">Link</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="consent_manager_empty_callbacks" translate>
                                    Add empty callbacks
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                      aria-hidden="true"
                                      ngbTooltip="{{'Add callback functions boilerplate' | translate}}"
                                      placement="auto"
                                      container="body">
                                </span>
                            </div>
                            <div class="col-sm-37 d-flex align-items-center mt-sm-0 mt-lg-1">
                                <input id="consent_manager_empty_callbacks"
                                       name="consent_manager_empty_callbacks"
                                       type="checkbox"
                                       [(ngModel)]="dataModel.add_empty_callback"/>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="consent_manager_empty_event_listeners" translate>
                                    Add empty EventListeners
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                      aria-hidden="true"
                                      ngbTooltip="{{'Add event listeners boilerplate' | translate}}"
                                      placement="auto"
                                      container="body">
                                </span>
                            </div>
                            <div class="col-sm-37 d-flex align-items-center mt-sm-0 mt-lg-1">
                                <input id="consent_manager_empty_event_listeners"
                                       name="consent_manager_empty_event_listeners"
                                       type="checkbox"
                                       [(ngModel)]="dataModel.add_empty_event_listeners"/>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right mt-1 pr-xl-2">
                                <label class="col-form-label text-bold inline-block pr-1" for="consent_manager_hasOverlay" translate>
                                    Add web overlay lock
                                </label>
                                <span class="fas fa-question-circle py-1 inline-block"
                                      aria-hidden="true"
                                      ngbTooltip="{{'Disables interactions on website until the visitor makes a consent choice. Be aware that locking interactions prior to consent may not be compliant with certain regional privacy regulations.' | translate}}"
                                      placement="auto"
                                      container="body">
                                </span>
                            </div>
                            <div class="col-sm-37 d-flex align-items-center mt-sm-0 mt-lg-1">
                                <input id="consent_manager_hasOverlay"
                                       name="hasOverlay"
                                       type="checkbox"
                                       [(ngModel)]="dataModel.hasOverlay"/>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block" translate>
                                    Consent Manager language*
                                </label>
                            </div>
                            <div class="col-sm-37">
                                <mon-form-field-select
                                    [monMultiple]="true"
                                    [(model)]="dataModel.included_languages"
                                    monName="included_languages"
                                    [monOptions]="options.languages"
                                    monReturnValue="value"
                                    monPlaceholder="{{ 'Select languages' | translate }}"
                                    [monRequired]="true"
                                    (changeEvent)="onIncludedLanguagesChange()"
                                    [ngClass]="{'required-outline': !dataModel.included_languages.length}"
                                    [monNoAutoComplete]="true">
                                </mon-form-field-select>
                                <span *ngIf="!dataModel.included_languages.length"
                                        class="text-danger"
                                        translate>
                                    Please add at least one language
                                </span>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf="dataModel.included_languages.length">
                            <div class="col-sm-11 text-right pr-xl-2">
                                <label class="col-form-label text-bold inline-block" translate>
                                    Default language*
                                </label>
                            </div>
                            <div class="col-sm-37">
                                <mon-form-field-select
                                    [(model)]="dataModel.defaultLanguage"
                                    monName="language"
                                    [monOptions]="availableDefaultLanguages"
                                    monReturnValue="value"
                                    monPlaceholder="{{ 'Select the default language' | translate }}"
                                    [ngClass]="{'required-outline': dataModel.included_languages.length && !dataModel.defaultLanguage}"
                                    [monRequired]="true"
                                    [monNoAutoComplete]="true">
                                </mon-form-field-select>
                                <span *ngIf="!dataModel.defaultLanguage"
                                        class="text-danger"
                                        translate>
                                    Please set the default language
                                </span>
                            </div>
                        </div>

                        <div class="row mr-lg-0 widget-title">
                            <div class="col-48">
                                <h2 class="font-weight-300 font-size-18 mt-4 mb-1 ml-4" translate>Widget Design</h2>
                            </div>
                            <hr class="mt-0 mb-4 col-48"/>
                        </div>

                        <div class="form-group row">
                            <label class="col-form-label text-right text-bold col-sm-11"
                                   translate>
                                Choose an icon
                            </label>
                            <div class="col-sm-37">
                                <div class="row icon-container">
                                    <div class="col-auto icon-btn-container d-flex align-items-center" *ngFor="let icon of options.icons; let i = index">
                                        <label class="btn btn-border-radius btn-icon flex"
                                               for="{{i}}_page_assist_icon_picture_url"
                                               type="button"
                                               [ngClass]="{'active': icon.value === dataModel.icon_picture_url}">
                                            <span [ngClass]="icon.icon" aria-hidden="true"></span>
                                            <span class="sr-only">{{icon.value}}</span>
                                            <input type="radio"
                                                   name="icon_picture_url"
                                                   ngModel
                                                   [value]="icon.value"
                                                   (change)="dataModel.icon_picture_url = icon.value"
                                                   class="sr-only"
                                                   id="{{i}}_page_assist_icon_picture_url"
                                                   [attr.checked]="dataModel.icon_picture_url === icon.value ? true : null"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row icon-shape-container">
                            <label class="col-form-label text-right text-bold col-sm-11"
                                   translate>
                                Icon shape
                            </label>
                            <div class="col-sm-37">
                                <mon-form-field-select
                                    [(model)]="dataModel.icon_shape"
                                    monName="icon_shape"
                                    [monOptions]="options.iconShapes"
                                    monReturnValue="value"
                                    monPlaceholder="{{ 'Select a shape' | translate }}"
                                    [monRequired]="true"
                                    [monNoAutoComplete]="true">
                                </mon-form-field-select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-28 row">
                                    <div class="col-sm-20 text-right">
                                        <label class="col-form-label text-bold inline-block pr-xl-1" translate>
                                            Button color
                                        </label>
                                    </div>

                                    <div class="col-sm-28 pl-xl-2">
                                        <div class="mb-3">
                                            <mon-color-simple-picker
                                                (onValueChange)="updateButtonColor($event)"
                                                [colorValue]="dataModel.theme.buttonColor"
                                                [position]="'top'"
                                                ></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.theme.buttonColor" name="buttonColor" />
                                        </div>
                                    </div>

                                    <div class="col-sm-20 text-right">
                                        <label class="col-form-label text-bold inline-block pr-xl-1" translate>
                                            Button text color
                                        </label>
                                    </div>

                                    <div class="col-sm-28 pl-xl-2">
                                        <div class="mb-3">
                                            <mon-color-simple-picker
                                                [position]="'top'"
                                                (onValueChange)="updateButtonTextColor($event)"
                                                [colorValue]="dataModel.theme.buttonTextColor"></mon-color-simple-picker>
                                            <input type="hidden" [(ngModel)]="dataModel.theme.buttonTextColor" name="buttonTextColor" />
                                        </div>
                                        <div class="mt-1 mb-3">
                                            <span *ngIf="buttonColorRation && buttonColorRation < 4"
                                                  class="text-danger"
                                                  translate>Your color contrast is below the recommended score</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-20">
                                    <div *ngIf="buttonColorRation" class="color-contrast-ratio-container">
                                        <p class="pl-2 pt-1" translate>Color contrast ratio</p>
                                        <div class="pl-2">
                                            <span class="text-bold h1">
                                                    {{ buttonColorRation }}
                                            </span>
                                            <span *ngIf="buttonColorRation >= 4"
                                                  class="text-success" translate>
                                                    (Good)
                                            </span>
                                            <span *ngIf="buttonColorRation < 4"
                                                  class="text-danger" translate>
                                                    (Bad)
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mon-dialog-footer">
        <div class="text-right">
            <button type="submit"
                    class="btn btn-border-radius btn-secondary">
                    <span translate>OK</span>
            </button>
        </div>
    </div>

</form>
