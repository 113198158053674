(function () {
    'use strict';
    angular.module('modules.page_fix.services').factory('PageFixListService', PageFixListService);

    PageFixListService.$inject = ['PAGE_FIX_FIXABLE_TYPES'];
    /* @ngInject*/
    function PageFixListService (PAGE_FIX_FIXABLE_TYPES) {
        return {
            getError: getError,
        };

        // ///////////////

        function getError (pageFix) {
            var selectorQueries = pageFix.selector.match(/;(.*)/)[1];
            var fixableType = pageFix.normalizeFixableType();
            if (PAGE_FIX_FIXABLE_TYPES.custom === fixableType || PAGE_FIX_FIXABLE_TYPES.misspelling === fixableType) {
                return [selectorQueries];
            } else if (PAGE_FIX_FIXABLE_TYPES.accessibility_check === fixableType) {
                return JSON.parse(selectorQueries);
            } else {
                return JSON.parse(selectorQueries).map(function (selector) {
                    var res = selector.match(/.*="(.*)"]/);
                    if (res) {
                        return res[1];
                    }
                    return selector;
                });
            }
        }
    }
})();
