(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name uptimeService
     * @description Service to handle uptimes
     */
    angular.module('services.entities').factory('uptimeService', uptimeService);

    uptimeService.$inject = ['ng2UptimeRepoService'];
    /* @ngInject*/
    function uptimeService (ng2UptimeRepoService) {
        return {
            create,
            update,
            destroy,
            createScheduledDowntime,
            updateScheduledDowntime,
            destroyScheduledDowntime,
        };

        function destroy (id) {
            return ng2UptimeRepoService.destroy(id);
        }

        function create (data) {
            return ng2UptimeRepoService.create(data);
        }

        function update (data) {
            return ng2UptimeRepoService.update(data);
        }

        function createScheduledDowntime (uptimeId, data) {
            return ng2UptimeRepoService.createScheduledDowntime(uptimeId, data);
        }

        function updateScheduledDowntime (uptimeId, id, data) {
            return ng2UptimeRepoService.updateScheduledDowntime(uptimeId, id, data);
        }

        function destroyScheduledDowntime (uptimeId, id) {
            return ng2UptimeRepoService.destroyScheduledDowntime(uptimeId, id);
        }
    }
})();
