(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuAccessibility', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', '$filter', 'ng2SessionService', 'ng2ActiveFeatureService'];
    function SideMenuController (gettextCatalog, $filter, ng2SessionService, ng2ActiveFeatureService) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['ACCESSIBILITY', 'SUMMARY']),
                    href: 'base.customer.domain.accessibility.summary',
                },
            ];

            if (!ng2ActiveFeatureService.isFeatureActive('accessibility_summary')) {
                vm.menu.push(
                    {
                        title: gettextCatalog.getString('Fast Track'),
                        icon: $filter('monIcons')(['ACCESSIBILITY', 'FASTTRACK']),
                        dialogParams: {
                            body: 'accessibilityFastTrack',
                            size: 'fw',
                            title: gettextCatalog.getString('Accessibility Fast Track'),
                        },
                    },
                    {
                        title: gettextCatalog.getString('Pages with Failing Checks'),
                        icon: $filter('monIcons')(['ACCESSIBILITY', 'PAGES']),
                        href: 'base.customer.domain.accessibility.pages',
                    },
                    {
                        title: gettextCatalog.getString('Checklist'),
                        icon: $filter('monIcons')(['ACCESSIBILITY', 'ISSUES']),
                        href: 'base.customer.domain.accessibility.issues',
                    },
                    {
                        title: gettextCatalog.getString('Guidelines'),
                        icon: $filter('monIcons')(['ACCESSIBILITY', 'GUIDELINES']),
                        href: 'base.customer.domain.accessibility.guidelines',
                    },
                    {
                        title: gettextCatalog.getString('Pages with Ignored Checks'),
                        icon: $filter('monIcons')(['ACCESSIBILITY', 'IGNORED_PAGES']),
                        href: 'base.customer.domain.accessibility.ignored_pages',
                    },
                );
            }

            accPdf();
            if (ng2ActiveFeatureService.isFeatureActive('color_contrast')) {
                accTools();
            }
        }

        function accPdf () {
            if (ng2SessionService.domain.scan.scan_documents) {
                vm.menu.push({
                    title: gettextCatalog.getString('PDFs'),
                    icon: $filter('monIcons')(['ACCESSIBILITY', 'PDF']),
                    href: 'base.customer.domain.accessibility.internal_pdf',
                    activeRoutes: ['base.customer.domain.accessibility.internal_pdf', 'base.customer.domain.accessibility.external_pdf'],
                    subs: [
                        {
                            title: gettextCatalog.getString('Internal PDFs'),
                            icon: $filter('monIcons')(['ACCESSIBILITY', 'PDF_INTERNAL']),
                            href: 'base.customer.domain.accessibility.internal_pdf',
                        },
                        {
                            title: gettextCatalog.getString('External PDFs'),
                            icon: $filter('monIcons')(['ACCESSIBILITY', 'PDF_EXTERNAL']),
                            href: 'base.customer.domain.accessibility.external_pdf',
                        },
                    ],
                });
            }
        }

        function accTools () {
            vm.menu.push({
                title: gettextCatalog.getString('Tools'),
                icon: $filter('monIcons')(['ACCESSIBILITY', 'TOOLS', 'TOOLS']),
                href: 'base.customer.domain.accessibility.color_contrast',
                activeRoutes: ['base.customer.domain.accessibility.color_contrast'],
                subs: [
                    {
                        title: gettextCatalog.getString('Color Contrast Tool'),
                        icon: $filter('monIcons')(['ACCESSIBILITY', 'TOOLS', 'CONTRAST']),
                        href: 'base.customer.domain.accessibility.color_contrast_tool',
                    },
                ],
            });
        }
    }
})();
