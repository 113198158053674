import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatar-2';
import {
    ShowMoreAvatarsComponent,
} from '@monsido/modules/report-center/components/show-more-avatars/show-more-avatars.component';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@client/app/modules/translate.module';


@NgModule({
    declarations: [
        ShowMoreAvatarsComponent,
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        AvatarModule,
        SharedModule,
        TranslateModule,
    ],
    exports: [
        ShowMoreAvatarsComponent,
    ],
})
export class ShowMoreAvatarsModule { }
