(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogRuleCountRule', {
        templateUrl: 'app/forms/global-policy/dialogs/rule/blocks/count-rule/count-rule.html',
        controller: StandardController,
        controllerAs: 'vm',
        bindings: {
            cRule: '=',
            monDialogId: '<',
            policyRuleForm: '=',
        },
    });

    StandardController.$inject = ['PolicyFormHelper', 'monDialog', 'gettextCatalog'];
    /* @ngInject */
    function StandardController (PolicyFormHelper, monDialog, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;
        vm.close = close;
        vm.chooseType = chooseOperator;
        vm.selectAllSubEntries = selectAllSubEntries;
        vm.checkSelectedSubEntries = checkSelectedSubEntries;

        function activate () {
            vm.areAllSubEntriesSelected = false;
            PolicyFormHelper.setRuleType(vm.cRule.type);
            setupTypeOptions();
            setupRuleSubEntries();
            setupLabelQueryText();
            vm.cRule.query = parseInt(vm.cRule.query);
            if (!vm.cRule.name) {
                chooseOperator(vm.typeOptions.length > 0 ? vm.typeOptions[0] : {});
            } else {
                chooseOperator(
                    vm.typeOptions.find(function (type) {
                        return type.type === vm.cRule.operator;
                    }),
                );
            }
        }

        function setupLabelQueryText () {
            var label;
            vm.label = gettextCatalog.getString('Characters');
            switch (vm.cRule.type) {
                case 'external_link_count':
                    label = gettextCatalog.getString('Number of external links');
                    vm.label = gettextCatalog.getString('Links');
                    break;
                case 'incoming_link_count':
                    label = gettextCatalog.getString('Number of incoming links');
                    vm.label = gettextCatalog.getString('Links');
                    break;
                case 'page_title_length':
                    label = gettextCatalog.getString('Number of characters in page title');
                    break;
                case 'link_text_length':
                    label = gettextCatalog.getString('Number of characters in link text');
                    break;
                case 'image_text_length':
                    label = gettextCatalog.getString('Number of characters in image text');
                    break;
                case 'header_text_length':
                    label = gettextCatalog.getString('Number of characters in header text');
                    break;
            }

            vm.CharacterLabel = label;
        }

        function setupTypeOptions () {
            vm.typeOptions = PolicyFormHelper.getOptionsByRuleType();
            vm.dropdownOptions = vm.typeOptions.map( option => {
                return {
                    ...option,
                    action: () => vm.chooseType(option),
                    label: option.translation,
                };
            });
        }

        function setupRuleSubEntries () {
            vm.subEntry = PolicyFormHelper.getRuleSubEntries();
            checkSelectedSubEntries();
        }

        function chooseOperator (option) {
            vm.selectedOption = option;
            vm.cRule.operator = option.type;
        }

        function selectAllSubEntries () {
            if (vm.cRule.sub_entry.length === vm.subEntry.options.length) {
                vm.cRule.sub_entry = [];
                vm.areAllSubEntriesSelected = false;
            } else {
                vm.cRule.sub_entry = vm.subEntry.options.map(function (entry) {
                    return entry.value;
                });
                vm.areAllSubEntriesSelected = true;
            }
        }

        function checkSelectedSubEntries () {
            vm.areAllSubEntriesSelected =
                vm.subEntry.options.length > 0 && vm.subEntry.options.length === vm.cRule.sub_entry.length;
        }

        function close () {
            var newRule = angular.copy(vm.cRule);
            newRule.sub_entry = newRule.sub_entry.join(',');
            newRule.query = newRule.query === '' ? 0 : newRule.query;
            if (vm.policyRuleForm) {
                vm.policyRuleForm.$setPristine();
            }
            monDialog.close(vm.monDialogId, newRule);
        }
    }
})();
