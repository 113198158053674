import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.accessibility').component('accessibilityDialogsNewHelpCenter', {
        templateUrl: 'app/modules/accessibility/dialogs/new-help-center/new-help-center.html',
        controller: AccessibilityDialogsNewHelpCenterController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
        },
    });

    AccessibilityDialogsNewHelpCenterController.$inject = [
        'SupportAccessibilityCheckService',
        'AccessibilityDialogsHelpCenterService',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function AccessibilityDialogsNewHelpCenterController (
        SupportAccessibilityCheckService,
        AccessibilityDialogsHelpCenterService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.setTypeColor = setTypeColor;
        vm.getImpactTranslation = AccessibilityDialogsHelpCenterService.getImpactTranslation;
        vm.getImpactClass = AccessibilityDialogsHelpCenterService.getImpactClass;
        vm.getGuidelineTranslation = AccessibilityDialogsHelpCenterService.getGuidelineTranslation;
        vm.getTagTranslation = AccessibilityDialogsHelpCenterService.getTagTranslation;
        vm.getTagClass = AccessibilityDialogsHelpCenterService.getTagClass;
        vm.getDifficultyTranslation = AccessibilityDialogsHelpCenterService.getDifficultyTranslation;
        vm.openDecisionTree = openDecisionTree;
        vm.canUseDecisionTree = canUseDecisionTree;
        vm.showSection = showSection;

        function activate () {
            vm.supportCenterCheck = null;
            vm.decisionTree = null;
            getDecisionTree();
            getPage();
        }

        function getPage () {
            var params = {
                check_id: vm.check.id,
            };
            vm.loading = true;
            SupportAccessibilityCheckService.getAll(params)
                .then(function (supportCenterAccessibilityCheck) {
                    if (supportCenterAccessibilityCheck.length > 0) {
                        vm.supportCenterCheck = supportCenterAccessibilityCheck[0];
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function setTypeColor () {
            var result = 'info';
            if (vm.check.issue_abbr === 'A') {
                result = 'wcag-a';
            } else if (vm.check.issue_abbr === 'AA') {
                result = 'wcag-aa';
            } else if (vm.check.issue_abbr === 'AAA') {
                result = 'wcag-aaa';
            }
            return result;
        }

        function openDecisionTree () {
            var params = {
                body: 'accessibilityDialogsNewHelpCenterDecisionTree',
                size: 'xs',
                data: {
                    decisionTree: vm.decisionTree,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getDecisionTree () {
            SupportAccessibilityCheckService.getDecisionTree(vm.check).then(function (decisionTree) {
                vm.decisionTree = decisionTree;
            }, angular.noop);
        }

        function showSection (str) {
            return typeof str === 'string' && str.length > 0 && !str.includes('N/A');
        }

        // Use a API Endpoint in the future to use as validation for the Decision Tree #18418
        function canUseDecisionTree () {
            return vm.decisionTree !== null;
        }
    }
})();
