(function () {
    'use strict';

    angular.module('modules.chat.intercom').provider('Intercom', function () {
        getter.$inject = ['$window', '$timeout', 'chatSystems'];

        function getter ($window, $timeout, chatSystems) {
            var loaded = false;
            function addScript () {
                if (shouldStartIntercom()) {
                    initScript();
                }
            }

            return {
                addScript: addScript,
            };

            function shouldStartIntercom () {
                return !loaded;
            }

            function initScript () {
                var appId = chatSystems.intercom.intercomId;
                var script = document.createElement('script');
                script.nonce = '3413c45d3a';
                script.innerHTML =
                    '(function() {' +
                    '    var w = window;' +
                    '    var ic = w.Intercom;' +
                    '    if (typeof ic === "function") {' +
                    '        ic(\'reattach_activator\');' +
                    '        ic(\'update\', w.intercomSettings);' +
                    '    } else {' +
                    '        var d = document;' +
                    '        var i = function() {' +
                    '            i.c(arguments);' +
                    '        };' +
                    '        i.q = [];' +
                    '        i.c = function(args) {' +
                    '            i.q.push(args);' +
                    '        };' +
                    '        w.Intercom = i;' +
                    '        var l = function() {' +
                    '            var s = d.createElement(\'script\');' +
                    '            s.type = \'text/javascript\';' +
                    '            s.async = true;' +
                    '            s.src = \'https://widget.intercom.io/widget/' +
                    appId +
                    '\';' +
                    '            var x = d.getElementsByTagName(\'script\')[0];' +
                    '            x.parentNode.insertBefore(s, x);' +
                    '        };' +
                    '        l();' +
                    '    }' +
                    '})();';
                loaded = true;
                document.body.appendChild(script);
            }
        }
        this.$get = getter;
    });
})();
