(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('seoInformationMissingH1', {
        templateUrl:
            'app/components/pagedetails/details/sections/seo/seo-information/sections/information/issues/missing-h1/missing-h1.html',
        controller: SeoTranslationsMissingH1Controller,
        controllerAs: 'vm',
        bindings: {
            seo: '<',
        },
    });

    SeoTranslationsMissingH1Controller.$inject = [];
    /* @ngInject */
    function SeoTranslationsMissingH1Controller () {
        var vm = this;
    }
})();
