(function () {
    'use strict';

    angular.module('app.forms').component('formRulesImportSetup', {
        templateUrl: 'app/forms/domain/steps/domain_groups/steps/rules/import/setup/setup.html',
        controller: ImportRulesSetupController,
        controllerAs: 'vm',
        bindings: {
            step: '=',
            data: '=',
            selected: '=',
        },
    });

    ImportRulesSetupController.$inject = [];
    /* @ngInject */
    function ImportRulesSetupController () {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            vm.options = [];
            vm.selected.match = null;
            vm.selected.match_rule = null;
            vm.selected.exclude = null;
            vm.selected.text = null;
            setupOptions();
            setupSelected();
        }

        function setupOptions () {
            for (var i = 0; i < vm.data.data[0].length; i++) {
                vm.options.push({ name: vm.data.data[0][i], value: i.toString() });
            }
        }

        function setupSelected () {
            for (var i = 0; i < vm.options.length; i++) {
                switch (vm.options[i].name.toLowerCase()) {
                    case 'match':
                        vm.selected.match = vm.options[i].value;
                        break;

                    case 'match_rule':
                        vm.selected.match_rule = vm.options[i].value;
                        break;

                    case 'text':
                        vm.selected.text = vm.options[i].value;
                        break;

                    case 'exclude':
                        vm.selected.exclude = vm.options[i].value;
                        break;
                }
            }
        }
    }
})();
