(function () {
    'use strict';

    angular.module('app.forms').component('formDomainGroupsUsers', {
        templateUrl: 'app/forms/domain/steps/domain_groups/steps/users/users.html',
        controller: DomainGroupsUsersController,
        controllerAs: 'vm',
        bindings: {
            ngModel: '=',
            users: '<',
        },
    });

    DomainGroupsUsersController.$inject = ['$scope'];
    /* @ngInject */
    function DomainGroupsUsersController ($scope) {
        var vm = this;
        vm.users = [];

        vm.$onInit = activate;

        function activate () {
            vm.ngModel.domain_group_members = vm.ngModel.domain_group_members || [];

            $scope.$watch('vm.users', function () {
                merge();
            });
        }

        function merge () {
            for (var i = 0; i < vm.users.length; i++) {
                var userIndex = findUser(vm.ngModel.domain_group_members, vm.users[i]);
                if (userIndex > -1) {
                    if (!vm.ngModel.domain_group_members[userIndex].hasOwnProperty('user')) {
                        vm.ngModel.domain_group_members[userIndex].user = vm.users[i];
                    }
                    if (vm.ngModel.domain_group_members[userIndex].user.customer_admin) {
                        vm.ngModel.domain_group_members[userIndex].visible = true;
                    }

                    continue;
                }

                vm.ngModel.domain_group_members.push({
                    domain_group_id: vm.ngModel.id,
                    user_id: vm.users[i].id,
                    visible: vm.users[i].customer_admin === true,
                    send_report: false,
                    user: vm.users[i],
                });
            }
        }

        function findUser (domainUsers, user) {
            for (var i = 0; i < domainUsers.length; i++) {
                if (user.id === domainUsers[i].user_id) {
                    return i;
                }
            }
            return -1;
        }
    }
})();
