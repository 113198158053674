import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { MonImgMetadataOverlayComponent } from '@monsido/ng2/modules/inventory/components/img-metadata-overlay/img-metadata-overlay.component';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionInventoryLinks', {
        templateUrl: 'app/components/pagedetails/details/sections/inventory/subsections/links/links.html',
        controller: LinksController,
        controllerAs: 'vm',
        bindings: {
            type: '@',
            headline: '@',
            icon: '@',
            pageObj: '<',
        },
    });

    LinksController.$inject = ['$controller', 'ng2PagePageLinksRepoService', 'gettextCatalog', 'ng2ActiveFeatureService', 'ng2MonEventsService'];
    /* @ngInject */
    function LinksController ($controller, ng2PagePageLinksRepoService, gettextCatalog, ng2ActiveFeatureService, ng2MonEventsService) {
        var vm = this;

        vm.getPage = getPage;

        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.pageObj = vm.pageObj || {};
            vm.links = [];
            vm.search = null;
            vm.hasAccessToMetaDataOverlay = ng2ActiveFeatureService.isFeatureActive('image_metadata');
            vm.openMetadataModal = openMetadataModal;

            getPage();

            setExports(vm.pageObj.id);
        }

        function getPage () {
            vm.selected = [];
            var params = {
                page_size: vm.pageSize,
                page: vm.page,
                type: vm.type,
                search: vm.search,
            };

            vm.promise = ng2PagePageLinksRepoService.getAll(vm.pageObj.id, params).then(function (data) {
                vm.links = data;
            }, angular.noop);
        }

        function setExports (page_id) {
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'page_links_for_page',
                        category_ref: page_id,
                    },
                },
            ];
        }

        function openMetadataModal (link) {
            var params = {
                component: MonImgMetadataOverlayComponent,
                data: { linkUrl: link.link.url, linkId: link.link.id },
                dialogOptions: {
                    size: 'md',
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
        }
    }
})();
