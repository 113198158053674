(function () {
    'use strict';

    angular.module('modules.light.components').component('sideMenuLightPageFix', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = [
        'gettextCatalog',
        '$filter',
        'PAGE_FIX_ACCESSIBILITY_CHECK_TAB',
        'ng2LightPageFixService',
    ];

    function SideMenuController (gettextCatalog, $filter, PAGE_FIX_ACCESSIBILITY_CHECK_TAB, ng2LightPageFixService) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['LIGHT', 'PAGE_FIX', 'HOME']),
                    href: 'base.customer.light.domain.page_fix.home',
                },
            ];
            var accessibilityFixesLink;
            if (ng2LightPageFixService.canAccessSubPages()) {
                accessibilityFixesLink = {
                    title: gettextCatalog.getString('Accessibility fixes'),
                    icon: $filter('monIcons')(['LIGHT', 'PAGE_FIX', 'ACCESSIBILITY']),
                    href: 'base.customer.light.domain.page_fix.accessibility_checks',
                    activeRoutes: [],
                    subs: [],
                };
                var categoryKeys = Object.keys(PAGE_FIX_ACCESSIBILITY_CHECK_TAB).filter(function (categoryKey) {
                    var category = PAGE_FIX_ACCESSIBILITY_CHECK_TAB[categoryKey];
                    return ng2LightPageFixService.getCategoryCount(category);
                });
                for (var i = 0; i < categoryKeys.length; i++) {
                    var categoryValue = PAGE_FIX_ACCESSIBILITY_CHECK_TAB[categoryKeys[i]];
                    var state = 'base.customer.light.domain.page_fix.accessibility_checks.' + categoryValue;
                    accessibilityFixesLink.activeRoutes.push(state);
                    accessibilityFixesLink.subs.push({
                        title: gettextCatalog.getString(ng2LightPageFixService.getTitle(categoryValue)),
                        icon: ng2LightPageFixService.getIcon(categoryValue),
                        href: state,
                    });
                }
            } else {
                accessibilityFixesLink = {
                    title: gettextCatalog.getString('Accessibility fixes'),
                    icon: $filter('monIcons')(['LIGHT', 'PAGE_FIX', 'ACCESSIBILITY']),
                    href: 'base.customer.light.domain.page_fix.checks',
                };
            }

            vm.menu.push(accessibilityFixesLink);
        }
    }
})();
