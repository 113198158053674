import { cloneDeep } from 'lodash';

type VersionType = 'v1'|'v2';

export type SettingType = {
    alwaysOnTop?: boolean,
    buttonHoverColor?: string,
    buttonHoverDarkColor?: string,
    canSavePaSetting?: boolean,
    coordinates?: string,
    customInformationTextEnabled?: boolean,
    direction: string,
    enabled: boolean,
    greeting: string,
    icon_picture_url: string,
    icon_shape: string,
    included_languages: string[],
    language: string,
    linkColor?: string,
    linkColorDark?: string,
    logo_picture_base64: string,
    logo_picture_url: string,
    mainButtonBorderColor?: string,
    mainButtonBorderColorEnabled?: boolean,
    mainColor: string,
    mainDarkColor?: string,
    main_color: string | null,
    skip_to: boolean,
    switchAccessibilityStatementURL?: boolean,
    textColor?: string,
    textDarkColor?: string,
    text_color: string,
    theme: string,
    title: string,
    title_text: string,
}

export type LegacySettingsType = {
    direction: string | null,
    enabled: boolean,
    manual_startup: boolean,
    skip_to: boolean | null,
    theme: string | null,
}

export class FormDomainScriptPageAssistModel {
    versionState: VersionType;
    legacySettings: LegacySettingsType;
    settings: SettingType;
    originalLegacySettings: LegacySettingsType;
    originalSettings: SettingType;

    constructor (settings: SettingType, legacySettings: LegacySettingsType) {
        this.originalSettings = cloneDeep(settings);
        this.originalLegacySettings = cloneDeep(legacySettings);
        this.settings = cloneDeep(settings);
        this.legacySettings = legacySettings;
        this.versionState = this.legacySettings && this.legacySettings.enabled ? 'v1' : 'v2';
    }

    isDirty (): boolean {
        if (
            this.originalSettings.enabled !== this.settings.enabled ||
                this.originalSettings.direction !== this.settings.direction ||
                this.originalSettings.theme !== this.settings.theme ||
                this.originalSettings.language !== this.settings.language ||
                this.originalSettings.included_languages.length !== this.settings.included_languages.length ||
                this.originalSettings.icon_shape !== this.settings.icon_shape ||
                this.originalSettings.title !== this.settings.title ||
                this.originalSettings.title_text !== this.settings.title_text ||
                this.originalSettings.logo_picture_base64 !== this.settings.logo_picture_base64 ||
                this.originalSettings.logo_picture_url !== this.settings.logo_picture_url ||
                this.originalSettings.skip_to !== this.settings.skip_to ||
                this.originalSettings.alwaysOnTop !== this.settings.alwaysOnTop
        ) {
            return true;
        }

        const isDirty = this.originalSettings.included_languages.some((langVal) => {
            return this.settings.included_languages.indexOf(langVal) === -1;
        });

        return (
            isDirty ||
                this.legacySettings.enabled !== this.originalLegacySettings.enabled ||
                this.legacySettings.direction !== this.originalLegacySettings.direction ||
                this.legacySettings.theme !== this.originalLegacySettings.theme ||
                this.legacySettings.skip_to !== this.originalLegacySettings.skip_to
        );
    }

    getIsEnabled (): boolean {
        return Boolean(this.settings.enabled || this.legacySettings.enabled);
    }

    getVersionState (): string {
        return this.versionState;
    }

    setVersionState (version: VersionType): void {
        this.versionState = version === 'v2' ? 'v2' : 'v1';
    }

    setSettings (settings): void {
        if (settings != null) {
            this.originalSettings = cloneDeep(settings);
            this.settings = settings;
            this.setVersionState(this.legacySettings.enabled ? 'v1' : 'v2');
        }
    }

    setLegacySettings (legacySettings): void {
        this.originalLegacySettings = cloneDeep(legacySettings);
        this.legacySettings = legacySettings;
    }
}
