import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';

export type ClarityDataType = {
    checks: number;
    checks_with_issues: number;
    checks_with_issues_by_result_type: Record<string, number>;
    pdfs: number;
    pdfs_with_issues: number;
    pdfs_with_issues_by_result_type: Record<string, number>;
    queued_count: number;
    tokens: number;
};

@Injectable({
    providedIn: 'root',
})
export class ClarityRepoService {

    constructor (
        private apiService: ApiService,
        private sessionService: SessionService,
    ) { }

    getSummary (): Promise<ClarityDataType> {
        return this.apiService.get('domains/' + this.sessionService.getDomainId() + '/clarity/summary');
    }
}
