import {
    AccessibilityChecksByResultTypeAndAbbrOrAccessibilityChecksByResultTypeAndAbbrCounters,
    AccessibilityChecksCounters,
    AccessibilityPagesByResultTypeAndAbbrOrAccessibilityPagesByResultTypeAndAbbrCounters,
    AccessibilityPagesOrAccessibilityPagesCounters, DomainGroupMembersEntity,
    DomainGroupsEntity,
    RulesEntity,
} from '@monsido/modules/models/api/interfaces/domain.interface';
import { User } from './user';

export class DomainGroup implements DomainGroupsEntity {
    constructor (group: DomainGroupsEntity) {
        this.id = group.id;
        this.title = group.title;
        this.description = group.description;
        this.rules = group.rules;
        this.updating = group.updating;
        this.created_at = group.created_at;
        this.updated_at = group.updated_at;
        this.last_updated_at = group.last_updated_at;
        this.page_count = group.page_count;
        this.accessibility_checks_counters = group.accessibility_checks_counters;
        this.accessibility_checks_by_result_type_and_abbr_counters = group.accessibility_checks_by_result_type_and_abbr_counters;
        this.accessibility_pages_counters = group.accessibility_pages_counters;
        this.accessibility_pages_by_result_type_and_abbr_counters = group.accessibility_pages_by_result_type_and_abbr_counters;
        this.documents_count = group.documents_count;
        this.dead_links_count = group.dead_links_count;
        this.dead_images_count = group.dead_images_count;
        this.policy_with_required_count = group.policy_with_required_count;
        this.policy_with_search_hits_count = group.policy_with_search_hits_count;
        this.policy_with_violations_count = group.policy_with_violations_count;
        this.spelling_errors_confirmed_count = group.spelling_errors_confirmed_count;
        this.seo_errors_alerts_count = group.seo_errors_alerts_count;
        this.seo_errors_infos_count = group.seo_errors_infos_count;
        this.seo_errors_technical_count = group.seo_errors_technical_count;
        this.seo_errors_warnings_count = group.seo_errors_warnings_count;
        if (group.domain_group_members) {
            this.domain_group_members = group.domain_group_members;
        }

    }
    updating: boolean;
    last_updated_at: string;
    documents_count: number;
    dead_links_count: number;
    dead_images_count: number;
    policy_with_required_count: number;
    policy_with_search_hits_count: number;
    policy_with_violations_count: number;
    spelling_errors_confirmed_count: number;
    seo_errors_alerts_count: number;
    seo_errors_infos_count: number;
    seo_errors_technical_count: number;
    seo_errors_warnings_count: number;
    id: number;
    title: string;
    description?: null;
    rules?: RulesEntity[] | null;
    created_at: string;
    updated_at: string;
    page_count: number;
    users: User[] = [];
    accessibility_checks_counters: AccessibilityChecksCounters;
    // eslint-disable-next-line max-len
    accessibility_checks_by_result_type_and_abbr_counters?: AccessibilityChecksByResultTypeAndAbbrOrAccessibilityChecksByResultTypeAndAbbrCounters | null;
    accessibility_pages_counters: AccessibilityPagesOrAccessibilityPagesCounters;
    // eslint-disable-next-line max-len
    accessibility_pages_by_result_type_and_abbr_counters?: AccessibilityPagesByResultTypeAndAbbrOrAccessibilityPagesByResultTypeAndAbbrCounters | null;
    domain_group_members?: DomainGroupMembersEntity[];
}
