(function () {
    'use strict';

    angular.module('modules.page_fix.pages').component('pageFixSummaryDiagnostics', {
        templateUrl: 'app/modules/page-fix/pages/summary/diagnostics/diagnostics.html',
        controller: PFSummaryDiagnostics,
        controllerAs: 'vm',
        bindings: {
            totalPageFixes: '<',
            spellingFixes: '<',
        },
    });

    PFSummaryDiagnostics.$inject = ['$filter', 'gettextCatalog', 'page_fix_settings'];
    /* @ngInject */
    function PFSummaryDiagnostics ($filter, gettextCatalog, page_fix_settings) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.colors = ['#526CFC', '#dcdcdc'];
            vm.labels = [gettextCatalog.getString('PageFixes'), gettextCatalog.getString('PageFixes Left')];
            setupSpellingFixesGraph();
            setupTotalPageFixesGraph();
        }

        // PROTECTED

        function setupTotalPageFixesGraph () {
            vm.totalPageFixData = [
                vm.totalPageFixes,
                getProperValue(page_fix_settings.page_fix_total_limit - vm.totalPageFixes),
            ];
            vm.totalPageFixOptions = getOptions(vm.totalPageFixes, page_fix_settings.page_fix_total_limit);
        }

        function setupSpellingFixesGraph () {
            vm.spellingFixData = [
                vm.spellingFixes,
                getProperValue(page_fix_settings.spelling_limit - vm.spellingFixes),
            ];
            vm.spellingFixOptions = getOptions(vm.spellingFixes, page_fix_settings.spelling_limit);
        }

        function getOptions (fixes, limit) {
            return {
                centerText: {
                    percentageLabel: $filter('monNumber')(fixes / limit || 0, '0.[00] %'),
                    totalLabel: fixes + '/' + limit,
                    totalFontSize: 150,
                },
                cutoutPercentage: 80,
            };
        }

        function getProperValue (value, minValue) {
            minValue = minValue || 0;
            if (value < minValue) {
                return 0;
            } else {
                return value;
            }
        }
    }
})();
