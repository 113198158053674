import { Injectable } from '@angular/core';
import { LOCALE_LANGUAGE, LOCALE_LANGUAGE_MAP, LOCALE_LANGUAGE_MAP_TYPE } from './constants/locale-language-map.constant';
import { LOCALE_MAP, LOCALE_MAP_TYPE } from './constants/locale-map.constant';


@Injectable()
export class LanguagesService {

    getLanguage (localeCode: LOCALE_LANGUAGE_MAP_TYPE | LOCALE_MAP_TYPE): LOCALE_LANGUAGE | undefined {
        let languageCode = '';
        if (!localeCode) {
            return;
        }

        if (localeCode.length === 2) {
            languageCode = this.getLocaleName(localeCode as LOCALE_MAP_TYPE);
        }

        if (localeCode.length >= 5) {
            languageCode = localeCode;
        }

        return LOCALE_LANGUAGE_MAP[languageCode];
    }

    getLocaleName (localeCode: LOCALE_MAP_TYPE): string {
        return LOCALE_MAP[localeCode];
    }

}
