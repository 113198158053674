import { Injectable } from '@angular/core';
import { FormDomainScriptPageAssistModel, SettingType, LegacySettingsType } from './form-domain-script-page-assist-model';


@Injectable({
    providedIn: 'root',
})
export class FormDomainScriptPageAssistService {
    formDomainScriptPageAssistModel (settings: SettingType, legacySettings: LegacySettingsType): FormDomainScriptPageAssistModel {
        return new FormDomainScriptPageAssistModel(settings, legacySettings);
    }
}
