(function () {
    'use strict';

    angular.module('modules.deeplink').run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'deeplink',
                config: {
                    url: '',
                    abstract: true,
                    data: {},
                },
            },
            {
                state: 'deeplink.domain',
                config: {
                    url: '/:customer_id/:domain_id/:issue_type_id/:issue_id',
                    data: { anonymous_access: false },
                    resolve: {
                        redirect: redirect,
                    },
                },
            },
        ];
    }

    var redirect = [
        'ng2DeeplinkService',
        '$stateParams',
        function (ng2DeeplinkService, $stateParams) {
            return ng2DeeplinkService.redirect(
                $stateParams.customer_id,
                $stateParams.domain_id,
                $stateParams.issue_type_id,
                $stateParams.issue_id,
                $stateParams,
            );
        },
    ];
})();
