import { Injectable } from '@angular/core';
import { default as PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG } from 'app/modules/page-fix/constants/page-fix-accessibility-check-config.json';
import { PAGE_FIX_ACCESSIBILITY_CHECK_TAB } from 'app/modules/page-fix/constants/page-fix-accessibility-check-tab';
import { PAGE_FIX_FIXABLE_TYPES } from 'app/modules/page-fix/constants/page-fix-fixable-types';

@Injectable({
    providedIn: 'root',
})
export class LightPageFixService {

    constructor (
    ) {}

    getTitle (category?: string): string {
        switch (category) {
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE:
                return 'Website Structure';
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN:
                return 'Design';
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS:
                return 'Links';
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA:
                return 'Media';
            default:
                return 'Accessibility Fixes';
        }
    }

    canAccessSubPages (): boolean {
        return false;
    }

    getIcon (category?: string): string {
        switch (category) {
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE:
                return ' fas fa-browser ';
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN:
                return ' fas fa-palette ';
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS:
                return ' fas fa-link ';
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA:
                return ' fas fa-video ';
            default:
                return ' fas fa-universal-access';
        }
    }

    getCategoryCount (category?: string): number {
        const configs = Object.keys(PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG)
            .filter(function (configKey) {
                const config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[configKey];
                return PAGE_FIX_FIXABLE_TYPES.accessibility_check === config.type;
            })
            .map(function (configKey) {
                return PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[configKey];
            });
        return configs.reduce(function (accumulator, config) {
            if (config.work_types.indexOf(category) > -1) {
                accumulator++;
            }
            return accumulator;
        }, 0);
    }
}
