import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonButtonNextComponent } from './button-next.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '@monsido/angular-shared-components/dist/angular-shared-components';

@NgModule({
    declarations: [
        MonButtonNextComponent,
    ],
    imports: [
        CommonModule,
        IconsModule,
        NgbTooltipModule,
    ],
    exports: [MonButtonNextComponent],
})
class ButtonNextModule { }

export { ButtonNextModule as MonButtonNextModule };
