(function () {
    'use strict';

    angular.module('modules.roles').run([
        'ng2MonEventsService',
        'RolesService',
        'activeFeatures',
        'RolesDemoDataService',
        'ng2CoreLoginService',
        function (ng2MonEventsService, RolesService, activeFeatures, RolesDemoDataService, ng2CoreLoginService) {
            if (activeFeatures.roles) {
                ng2MonEventsService.addListener('finishCustomer', function () {
                    if (true) {
                        // is Demo data...
                        RolesDemoDataService.setupData();
                    }
                    return ng2CoreLoginService.getUser().then(function (user) {
                        return RolesService.init(user);
                    });
                });
                ng2MonEventsService.addListener('exitCustomer', function () {
                    RolesService.destroy();
                });

                ng2MonEventsService.addListener('logout', function () {
                    RolesService.destroy();
                });
            }
        },
    ]);
})();
