(function () {
    'use strict';

    angular.module('modules.page-performance').component('pagePerformanceScoreIndicators', {
        templateUrl: 'app/modules/page-performance/components/score-indicators/score-indicators.html',
        controller: PagePerformanceScoreIndicatorsController,
        controllerAs: 'vm',
        bindings: {
            audits: '<',
            goToSection: '&',
        },
    });

    PagePerformanceScoreIndicatorsController.$inject = ['PagePerformanceChartsService'];
    /* @ngInject */
    function PagePerformanceScoreIndicatorsController (PagePerformanceChartsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getFailedColor = PagePerformanceChartsService.getFailedColor;
        vm.getAverageColor = PagePerformanceChartsService.getAverageColor;
        vm.getPassedColor = PagePerformanceChartsService.getPassedColor;

        function activate () {}
    }
})();
