(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name domainGroupService
     * @description Service to handle domain groups
     */
    angular.module('services.entities').factory('domainGroupService', domainGroupService);

    domainGroupService.$inject = ['ng2DomainGroupRepoService', 'ng2DomainRepoService'];
    /* @ngInject*/
    function domainGroupService (ng2DomainGroupRepoService, ng2DomainRepoService) {
        return {
            update,
            destroy,
            create,
            updateDomainGroupRules,
            updateSingleDomainGroupRules,
            cleanupRuleProperties,
            cleanupRules,
            getDomainGroupLabels,
        };

        function create (domain, params, headers) {
            return ng2DomainGroupRepoService.create(domain, params, headers);
        }

        function update (domain, group, headers) {
            return ng2DomainGroupRepoService.update(domain, group, headers);
        }

        function destroy (domainId, id) {
            return ng2DomainGroupRepoService.destroy(domainId, id);
        }

        async function updateDomainGroupRules (domainId) {
            return ng2DomainRepoService.updateDomainGroupRules(domainId);
        }

        async function updateSingleDomainGroupRules (domainId, groupId) {
            return ng2DomainRepoService.updateSingleDomainGroupRules(domainId, groupId);
        }

        function cleanupRules (rules) {
            if (Array.isArray(rules)) {
                return rules
                    .filter(function (rule) {
                        if (rule.match === 'labels') {
                            return rule.label_ids.length !== 0;
                        }
                        return !!(rule.text && rule.match_rule && rule.match && rule.hasOwnProperty('exclude'));
                    })
                    .map(function (rule) {
                        return cleanupRuleProperties(rule);
                    });
            }

            return rules;
        }

        function cleanupRuleProperties (rule) {
            if (rule.match === 'labels') {
                return {
                    match: rule.match,
                    label_ids: rule.label_ids,
                    exclude: rule.exclude,
                };
            } else {
                return {
                    match: rule.match,
                    match_rule: rule.match_rule,
                    exclude: rule.exclude,
                    key: rule.key,
                    text: rule.text,
                };
            }
        }

        function getDomainGroupLabels (domainGroup, labelsMap) {
            return domainGroup.rules.map((rule) => {
                return rule.label_ids.map(id => {
                    return {
                        ...labelsMap[id],
                        excluded: rule.exclude,
                    };
                });
            }).flat();
        }
    }
})();
