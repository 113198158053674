(function () {
    'use strict';
    /**
     * @memberof modules.languages
     * @ngdoc service
     * @name LanguagesService
     * @description Service that will help get the Correct language localization
     */
    angular.module('modules.languages').service('LanguagesService', LanguagesService);

    LanguagesService.$inject = ['LOCALE_MAP', 'LOCALE_LANGUAGE_MAP'];

    function LanguagesService (LOCALE_MAP, LOCALE_LANGUAGE_MAP) {
        return {
            getLanguageName: getLanguageName,
        };

        /**
         * @memberOf LanguagesService
         * @desc Get language by localization code
         * @param code - Localization code
         */
        function getLanguageName (code) {
            if (code && code.length === 2) {
                code = getLocaleName(code);
            }

            if (!code || code.length < 5) {
                return;
            }

            return LOCALE_LANGUAGE_MAP[code];
        }

        // PROTECTED

        /**
         * @memberOf LanguagesService
         * @desc Get ISO Locale by language key
         * @param code - Language Key
         */
        function getLocaleName (code) {
            return LOCALE_MAP[code];
        }
    }
})();
