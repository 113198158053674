export interface ShortcutModelInterface {
    id: number,
    title: string,
    url: string,
    icon_uri: string,
    icon_type: string,
    position: number,
    url_target: string,
    on_dashboard: boolean,
    private: boolean
}
export class ShortcutModel implements ShortcutModelInterface {
    id: number;
    title: string;
    url: string;
    icon_uri: string;
    icon_type: string;
    position: number;
    url_target: string;
    on_dashboard: boolean;
    private: boolean;

    constructor (data: ShortcutModelInterface) {
        this.id = data.id;
        this.title = data.title;
        this.url = data.url;
        this.icon_uri = data.icon_uri;
        this.icon_type = data.icon_type;
        this.position = data.position;
        this.url_target = data.url_target || '_blank';
        this.on_dashboard = Boolean(data.on_dashboard);
        this.private = data.private;
    }
}
