export const ACCESSIBILITY_HELP_CENTER_IMPACT = {
    minor: {
        translation: 'Minor',
        styleClass: 'label-info',
    },
    moderate: {
        translation: 'Moderate',
        styleClass: 'label-warning',
    },
    serious: {
        translation: 'Serious',
        styleClass: 'label-danger',
    },
    critical: {
        translation: 'Critical',
        styleClass: 'label-danger',
    },
} as const;
