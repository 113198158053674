(function () {
    'use strict';

    angular.module('modules.cookie_banner.components').component('cookieBannerTrafficLight', {
        templateUrl: 'app/modules/cookie-banner/components/cookie-banner-traffic-light/cookie-banner-traffic-light.component.html',
        controller: CookieBannerTrafficLightController,
        controllerAs: 'vm',
        bindings: {
            type: '<',
        },
    });

    CookieBannerTrafficLightController.$inject = ['TRAFFIC_LIGHT_COLORS'];

    function CookieBannerTrafficLightController (TRAFFIC_LIGHT_COLORS) {
        var vm = this;
        vm.icon = '';
        vm.$onChanges = onChanges;

        function onChanges () {
            switch (vm.type) {
                case TRAFFIC_LIGHT_COLORS.red:
                    vm.icon = 'fas fa-exclamation red';
                    break;
                case TRAFFIC_LIGHT_COLORS.yellow:
                    vm.icon = 'fas fa-question yellow';
                    break;
                default:
                    vm.icon = 'fas fa-check green';
                    break;
            }
        }
    }
})();
