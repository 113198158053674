(function () {
    'use strict';

    angular.module('modules.moments.run').run([
        'translation',
        'ng2MonEventsService',
        'ng2DateTimeService',
        function (translation, ng2MonEventsService, ng2DateTimeService) {
            ng2MonEventsService.addListener('afterLoadUser', function (user) {
                translation.setDateLocale(user.locale);
                ng2DateTimeService.setLocale(user.locale);
            });
        },
    ]);
})();
