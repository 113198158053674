export const PAGE_FIX_EVENT = {
    REGISTER_VIEW: 'PageFixFormWizardContainerRegisterView',
    UNREGISTER_VIEW: 'PageFixFormWizardContainerUnregisterView',
    REQUEST_SUBMIT: 'PageFixFormWizardContainerRequestSubmit',
    REQUEST_DESTROY: 'PageFixFormWizardContainerRequestDestroy',
    REQUEST_PREPARE_PAYLOAD: 'PageFixFormWizardContainerRequestPreparePayload',
    FORM_REQUEST_GO_TO_VIEW: 'PageFixFormGoToView',
    FORM_UPDATE_VIEW: 'PageFixFormUpdateView',
    UPDATE_WARNING: 'PageFixFormUpdateWarning',
    UPDATE_PAGEFIX: 'PageFixFormUpdatePageFix',
    CUSTOM_VALIDATION: 'PageFixFormCustomValidation',
} as const;
