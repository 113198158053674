(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityDashboardComplianceDoughnut', {
        templateUrl: 'app/pages/accessibility/dashboard/compliance/doughnut/doughnut.html',
        controller: AccessibilityDashboardComplianceDoughnutController,
        controllerAs: 'vm',
        bindings: {
            type: '<',
            checks: '<',
            selectedObs: '<',
        },
    });

    AccessibilityDashboardComplianceDoughnutController.$inject = ['AccessibilityResultTypeService'];
    /* @ngInject */
    function AccessibilityDashboardComplianceDoughnutController (AccessibilityResultTypeService) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            // Make sure that the summary is properly setup
            vm.selectedObs.subscribe(function (resultType) {
                vm.totalChecks = AccessibilityResultTypeService.getChecksCount(vm.checks, resultType, [vm.type]);
                vm.checksWithErrors = AccessibilityResultTypeService.getChecksCount(
                    vm.checks,
                    resultType,
                    [vm.type],
                    true,
                );
            });
        }
    }
})();
