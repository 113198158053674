(function () {
    'use strict';

    angular.module('filters.monsido').filter('isNumber', isNumber);
    isNumber.$inject = [];

    /* @ngInject */
    function isNumber () {
        return function (input) {
            return angular.isNumber(input);
        };
    }
})();
