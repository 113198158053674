<div class="report-center-dashboard">
    <mon-page-header
        class="report-center-header"
        monIconDirect="faFileInvoice"
        [header]="'Report Center' | translate"
        [subHeader]="subHeader"
    >
        <div class="header-actions">
            <mon-link [monHref]="monHref" [monLinkText]="'Help Center' | translate" ></mon-link>
        </div>
    </mon-page-header>

    <mon-spinner [loading]="loading">
        <div class="row">
            <div class="col-32 no-padding-right no-padding-left">
                <mon-report-list [reports]="reports" (getPage)="getPage()"></mon-report-list>
            </div>
            <div class="col-16 no-padding-left">
                <div class="card panel-monsido-card">
                    <mon-scheduled-report
                            [reports]="scheduledReports"
                            (getPage)="getPage()">
                    </mon-scheduled-report>
                </div>
                <div class="card panel-monsido-card" *ngIf="(['customer_admin'] | monRole)">
                    <mon-scheduled-report
                            [reports]="reports"
                            [admin]="true"
                            (getPage)="getPage()">
                    </mon-scheduled-report>
                </div>
                <div class="panel panel-monsido-card">
                    <mon-received-reports></mon-received-reports>
                </div>
            </div>
        </div>
    </mon-spinner>
</div>
