import { Component, Input } from '@angular/core';

@Component({
    selector: 'mon-label-blocked-by-domain-groups',
    templateUrl: './label-blocked-by-domain-groups.html',
    styleUrls: ['./label-blocked-by-domain-groups.scss'],
})
export class MonLabelBlockedByDomainGroupsComponent {
    @Input() monLabel: { color: string } = { color: '' };
    @Input() monDomains: { title: string, url: string, active: boolean, domain_groups: { title: string }[] }[] = [];

    constructor () {}
}
