import { Ng2StateDeclaration } from '@uirouter/angular';
import { ReportCenterDashboardComponent } from './pages/report-center-dashboard/report-center-dashboard.component';

export const reportCenterState: Ng2StateDeclaration = {
    name: 'base.customer.global.reportcenter',
    url: '/report-center?search',
    component: ReportCenterDashboardComponent,
    params: {
        search: {
            dynamic: true,
            value: '',
        },
    },
    data: {
        title: 'Acquia Optimize - Report Center',
    },
};

export const REPORT_CENTER_STATES = [reportCenterState];

