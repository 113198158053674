import { TranslateService } from '@client/app/services/translate/translate.service';

export type GuidelineInfoType = Record<string, {
    title: string,
    name: string,
}>;
export const getWCAGGuidelineInfoData = (translateService: TranslateService): GuidelineInfoType => ({
    'WCAG2-A': {
        title: 'WCAG 2.0 A',
        name: translateService.getString('WCAG 2.0 (Level A)'),
    },
    'WCAG2-AA': {
        title: 'WCAG 2.0 AA',
        name: translateService.getString('WCAG 2.0 (Level AA)'),
    },
    'WCAG2-AAA': {
        title: 'WCAG 2.0 AAA',
        name: translateService.getString('WCAG 2.0 (Level AAA)'),
    },
    'WCAG21-A': {
        title: 'WCAG 2.1 A',
        name: translateService.getString('WCAG 2.1 (Level A)'),
    },
    'WCAG21-AA': {
        title: 'WCAG 2.1 AA',
        name: translateService.getString('WCAG 2.1 (Level AA)'),
    },
    'WCAG21-AAA': {
        title: 'WCAG 2.1 AAA',
        name: translateService.getString('WCAG 2.1 (Level AAA)'),
    },
    'WCAG21-A_mobile': {
        title: 'WCAG 2.1 A Mobile',
        name: translateService.getString('WCAG 2.1 (Level A) Mobile'),
    },
    'WCAG21-AA_mobile': {
        title: 'WCAG 2.1 AA Mobile',
        name: translateService.getString('WCAG 2.1 (Level AA) Mobile'),
    },
    'WCAG21-AAA_mobile': {
        title: 'WCAG 2.1 AAA Mobile',
        name: translateService.getString('WCAG 2.1 (Level AAA) Mobile'),
    },
    'WCAG22-A': {
        title: 'WCAG 2.2 A',
        name: translateService.getString('WCAG 2.2 (Level A)'),
    },
    'WCAG22-AA': {
        title: 'WCAG 2.2 AA',
        name: translateService.getString('WCAG 2.2 (Level AA)'),
    },
    'WCAG22-AAA': {
        title: 'WCAG 2.2 AAA',
        name: translateService.getString('WCAG 2.2 (Level AAA)'),
    },
    'WCAG22-A_mobile': {
        title: 'WCAG 2.2 A Mobile',
        name: translateService.getString('WCAG 2.2 (Level A) Mobile'),
    },
    'WCAG22-AA_mobile': {
        title: 'WCAG 2.2 AA Mobile',
        name: translateService.getString('WCAG 2.2 (Level AA) Mobile'),
    },
    'WCAG22-AAA_mobile': {
        title: 'WCAG 2.2 AAA Mobile',
        name: translateService.getString('WCAG 2.2 (Level AAA) Mobile'),
    },
});

export const getQUALWEBGuidelineInfoData = (translateService: TranslateService): GuidelineInfoType => ({
    'QUALWEB-A': {
        title: 'QualWeb A',
        name: translateService.getString('QualWeb (Level A)'),
    },
    'QUALWEB-AA': {
        title: 'QualWeb AA',
        name: translateService.getString('QualWeb (Level AA)'),
    },
});
