(function () {
    'use strict';
    /**
     * @memberof modules.cc
     * @ngdoc factory
     * @name AccessibilityCCService
     * @description Get region details of current active region
     */
    angular.module('modules.cc').factory('AccessibilityCCService', AccessibilityCCService);

    AccessibilityCCService.$inject = ['accessibilityService', 'CheckTypeService', 'gettextCatalog'];
    /* @ngInject*/
    function AccessibilityCCService (accessibilityService, CheckTypeService, gettextCatalog) {
        var vm = this;
        vm.price = '$5';
        return {
            closeCaption: closeCaption,
            getTitle: getTitle,
            getConfirmationMessage: getConfirmationMessage,
            getShortenedConfirmationMessage: getShortenedConfirmationMessage,
        };

        /**
         * @memberOf CheckTypeService
         * @desc Check if input is of type function
         * @param {integer} snippetId - Accessibility Snippet
         * @param {Function} success - Function call on success
         * @param {Function} error - Function call on error
         * @param {Function} after - function call on finally
         * @return {void}
         */
        function closeCaption (snippetId, success, error, after) {
            accessibilityService
                .fastTrackCloseCaption(snippetId)
                .then(
                    function (caption) {
                        if (CheckTypeService.isFunction(success)) {
                            success({ caption: caption });
                        }
                    },
                    function (response) {
                        if (CheckTypeService.isFunction(error)) {
                            error({ response: response });
                        }
                    },
                )
                .finally(function () {
                    if (CheckTypeService.isFunction(after)) {
                        after();
                    }
                });
        }

        /**
         * @memberOf CheckTypeService
         * @desc Return title and aria label message for Closed Captioning
         * @return {string}
         */
        function getTitle () {
            return gettextCatalog.getString('Add captions to your video');
        }

        /**
         * @memberOf CheckTypeService
         * @desc Return confirmation message for Closed Captioning
         * @return {string}
         */
        function getConfirmationMessage () {
            var params = {
                cost: vm.price,
            };

            var confirmation = gettextCatalog
                .getString(
                    'All captioning requests include a transcript with a minimum of 98% accuracy, an open captioned video, SRT and WebVTT captioning files. An Open Caption Video permanently burns the captions into each frame of the video file ensuring maximum accessibility regardless of media player. Additional captioning file formats are available upon request. \n\n' +
                        'Cost: {{cost}} per minute with a five-minute minimum.\n\n' +
                        'Turnaround time: Within two to four business days from approval.\n' +
                        'Click below to proceed.\n' +
                        'Learn more about our process and what you will be receiving.\n' +
                        'Captioning Process:\n' +
                        'Step 1: Human Transcription- A quality closed captioning file starts with a highly accurate transcript created by a professional, U.S. based transcriber.\n\n' +
                        'Step 2: Human Alignment- Combining technology with the human touch, our specially trained aligners know how to create the perfect captions.\n\n' +
                        'Step 3: Human Quality Review- A quality review of the transcript and captions with a fresh set of human eyes makes for an excellent final result. \n\n' +
                        'Pricing:\n' +
                        'Pricing is {{cost}} per minute of audio with a five-minute minimum\n' +
                        'Let your Acquia Optimize account executive know if you have a large project or ongoing work.',
                    params,
                )
                .replace(new RegExp('\n', 'g'), '<br/>');
            confirmation +=
                '<br/><br/><br/><a href="/terms/closed-caption" target="_blank" aria-describedby="linkOpensInNewTab">' +
                gettextCatalog.getString('Terms of agreement') +
                '</a>';
            return confirmation;
        }

        /**
         * @memberOf CheckTypeService
         * @desc Return accept confirmation message for Closed Captioning
         * @return {string}
         */
        function getShortenedConfirmationMessage () {
            var params = {
                cost: vm.price,
            };
            return gettextCatalog.getString(
                'Thank you for the request. Please click "confirm" to submit your video for closed captioning services at {{cost}}/minute.',
                params,
            );
        }
    }
})();
