import { Injectable } from '@angular/core';
import { DomainInterface } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { ReportInterface } from '@monsido/ng2/modules/models/api/interfaces/report.interface';

@Injectable({
    providedIn: 'root',
})
export class ReportCenterService {

    constructor () { }

    filterDomainsByReport (report: ReportInterface, domains: DomainInterface[]): DomainInterface[] {
        const result = domains.filter((domain) => {
            const name = report.template.name;
            if (name.includes('accessibility')) {
                const acc = domain.features.accessibility;
                return typeof acc === 'string' && acc.length > 0;
            }

            if (name.includes('readability')) {
                const readability = domain.features.readability_test;
                return typeof readability === 'string' && readability.length > 0;
            }

            if (name.includes('stats')) {
                return domain.features.statistics;
            }

            return true;
        });

        if (report.template.domain_only) {
            return result.map(domain => ({ ...domain, domain_groups: [] }));
        }

        return result;
    }
}
