import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomainGroupRule } from 'ng2/models/domain-group-rule.interface';

@Component({
    selector: 'mon-form-domain-groups-match-rule-label',
    templateUrl: 'label.html',
})
export class FormDomainGroupsMatchRuleLabelComponent {
    @Input() monRule?: DomainGroupRule;
    @Input() monMatchOptions = [];
    @Input() monMatchExcludeOptions = [];
    @Input() monLabelsOptions = [];
    @Input() monFieldKey = '';

    @Output() monExcludeChange: EventEmitter<void> = new EventEmitter();

    constructor () {}

}
