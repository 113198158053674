(function () {
    'use strict';

    angular.module('blocks.service', [
        'blocks.service.users',
        'rx',
        'mon.dialog',
        'plugins.lodash',
        'modules.chat.intercom',
    ]);
})();
