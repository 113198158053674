(function () {
    'use strict';
    angular.module('directives.monsido').directive('complianceGraph', [
        function () {
            return {
                bindToController: true,
                restrict: 'E',
                controller: ComplianceGraphController,
                controllerAs: 'vm',
                templateUrl: 'app/directives/compliance-graph/compliance-graph.html',
                replace: true,
                transclude: false,
                scope: {
                    data: '<',
                    colors: '<?',
                    indexNumber: '<?',
                    changesIndex: '<?',
                    labels: '<',
                },
            };
        },
    ]);

    ComplianceGraphController.$inject = ['$filter'];
    /* @ngInject */
    function ComplianceGraphController ($filter) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.indexNumber = vm.indexNumber || 0;
            vm.changesIndex = vm.changesIndex || null;
            vm.colors = vm.colors || ['#4dc0bd', '#bfe3e1', '#DCDCDC'];
            setupDoughnut();
            evaluate();
        }

        function setupDoughnut () {
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: evaluate() + ' %',
                },
                cutoutPercentage: 75,
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return $filter('numeraljs', '0,0')(data.datasets[0].data[tooltipItem.index]);
                        },
                    },
                },
            };
        }

        function evaluate () {
            var number = 0;
                var collectedNumber = 0;
            vm.data.forEach(function (data, index) {
                if (index === vm.indexNumber || (vm.changesIndex !== null && vm.changesIndex === index)) {
                    number += data;
                }
                collectedNumber += data;
            });

            return $filter('monNumber')((number / collectedNumber) * 100 || 0, '0.[00]');
        }
    }
})();
