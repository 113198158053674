(function () {
    'use strict';

    angular.module('filters.monsido').filter('isArray', isArray);
    isArray.$inject = [];

    /* @ngInject */
    function isArray () {
        return function (input) {
            return angular.isArray(input);
        };
    }
})();
