import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.export').component('csvExportButton', {
        templateUrl: 'app/modules/export/components/button/button.html',
        controller: CsvExportsController,
        controllerAs: 'vm',
        bindings: {
            category: '@?',
            categoryName: '@?',
            exports: '<?',
            clsSize: '@?',
            clsMargin: '@?',
            clsPR: '<?',
            monDisabled: '<?',
        },
    });

    CsvExportsController.$inject = ['ng2MonEventsService', 'gettextCatalog', '$filter', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function CsvExportsController (ng2MonEventsService, gettextCatalog, $filter, ng2ActiveFeatureService) {
        var vm = this;

        vm.$onInit = activate;
        vm.exportFiles = exportFiles;
        vm.getCls = getCls;

        function activate () {
            vm.enableExport = ng2ActiveFeatureService.isFeatureActivePure('data_export');
            vm.clsPR = vm.clsPR || false;
            vm.monDisabled = vm.monDisabled || false;
            vm.exports = vm.exports || [];
            if (vm.category) {
                var categories = vm.category.split(',');
                categories.forEach(function (category) {
                    vm.exports.push({
                        name: vm.categoryName || gettextCatalog.getString('Start export') + ' ' + $filter('exportName')(category),
                        data: {
                            category: category,
                        },
                    });
                });
            }
        }

        function getCls () {
            var cls = '';
            if (vm.clsSize) {
                cls += ' ' + vm.clsSize;
            } else {
                cls += ' py-2 px-3';
            }

            if (vm.clsMargin) {
                cls += ' ' + vm.clsMargin;
            } else {
                cls += '  mr-2';
            }

            if (vm.clsPR) {
                cls += ' pull-right';
            }

            return cls;
        }

        function exportFiles () {
            var params = {
                body: 'csvExportDialog',
                size: 'sm',
                classes: 'fade-animation middle center',
                data: {
                    exports: vm.exports,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
