(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name ColorService
     * @description Service to handle color related functions
     */
    angular.module('modules.tools').factory('ColorService', ColorService);

    ColorService.$inject = [];
    /* @ngInject*/
    function ColorService () {
        return {
            ratioCheck: ratioCheck,
            hexToRgb: hexToRgb,
            rgbToHex: rgbToHex,
            contrast: contrast,
        };

        /**
         * @memberOf ColorService
         * @desc Checks if ratio is bigger than number, and if so returns true
         * @param {object} ratio - ratio to check against
         * @param {object} number - number to check against
         * @returns {object} - returns either true or false
         */
        function ratioCheck (ratio, number) {
            return ratio > number;
        }

        /**
         * @memberOf ColorService
         * @desc Convert hexidecimal color value to RGB
         * @param {object} hex - hexidecimal value to convert to rgb
         * @returns {object} - object containing red green and blue values
         */
        function hexToRgb (hex) {
            // If # is input, then it is removed
            hex = hex.replace('#', '');

            return {
                red: parseInt(hex.substring(0, 2), 16),
                green: parseInt(hex.substring(2, 4), 16),
                blue: parseInt(hex.substring(4), 16),
            };
        }

        /**
         * @memberOf ColorService
         * @desc Convert RGB to hexidecimal color value
         * @param {object} rgb - object containing red, green and blue values, to be converted
         * @returns {object} - hexidecemal color value
         */
        function rgbToHex (rgb) {
            var hex = '';
            Object.keys(rgb).forEach(function (key) {
                if (rgb[key] === null) {
                    return;
                }
                var color = rgb[key].toString(16);
                if (color.length < 2) {
                    color = 0 + color;
                }
                hex += color;
            });
            return '#' + hex;
        }

        /**
         * @memberOf ColorService
         * @desc Calculates contrast level using 2 colors
         * @param {object} foregroundColor - object containing red, green and blue values
         * @param {object} backgroundColor - object containing red, green and blue values
         * @returns {object} - contrast level
         */
        function contrast (foregroundColor, backgroundColor) {
            // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef

            var l1 = luminance(foregroundColor) + 0.05;
                var l2 = luminance(backgroundColor) + 0.05;
                var ratio = l1 / l2;

            if (l2 > l1) {
                ratio = 1 / ratio;
            }

            ratio = ratio.toFixed(2);

            return isNaN(ratio) ? '' : ratio;
        }

        // ////////////

        function luminance (rgbInput) {
            // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef

            var rgb = [];
            var i = 0;

            angular.forEach(rgbInput, function (channel) {
                channel /= 255;

                channel = channel < 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);

                rgb[i++] = channel;
            });

            return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
        }
    }
})();
