import { routesHelper } from '@client/app/services/routes-helper/routes.helper';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { Ng1ApiUsersDashboardComponent } from 'app/modules/api-users/pages/dashboard/ng1-dashboard.component';

export const ADMIN_API_USERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.customer.admin.api_users',
        url: '/api_users',
        abstract: true,
    },
    {
        name: 'base.customer.admin.api_users.index',
        url: '?page_size&page',
        views: {
            '^.^.^.^.$default': {
                component: Ng1ApiUsersDashboardComponent,
            },
        },
        resolve: [
            routesHelper.makeActiveFeatureResolver('apiUsersResolveFn', 'api_users'),
        ],

    },
];
