(function () {
    'use strict';

    angular.module('modules.data_privacy.components').component('privacyHistoryLineChart', {
        templateUrl: 'app/modules/data-privacy/components/history-line-chart/history-line-chart.html',
        controller: DataPrivacyHistoryLineChartController,
        controllerAs: 'vm',
        bindings: {
            history: '<',
        },
    });

    DataPrivacyHistoryLineChartController.$inject = ['gettextCatalog', '$filter'];

    /* @ngInject */
    function DataPrivacyHistoryLineChartController (gettextCatalog, $filter) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            setupChart();
        }

        function setupChart () {
            vm.options = {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        fontSize: 11,
                        boxWidth: 10,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                fontSize: 11,
                                min: 0,
                                beginAtZero: true,
                                suggestedMax: 5,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                            },
                            gridLines: {
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Data Violations'),
                                fontSize: 11,
                            },
                        },
                        {
                            id: 'y-axis-1',
                            type: 'linear',
                            position: 'right',
                            ticks: {
                                fontSize: 11,
                                min: 0,
                                beginAtZero: true,
                                suggestedMax: 5,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                            },
                            gridLines: {
                                drawBorder: false,
                                display: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: gettextCatalog.getString('Content'),
                                fontSize: 11,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            id: 'x-axis-0',
                            type: 'time',
                            gridLines: {
                                offsetGridLines: true,
                                drawBorder: false,
                                display: false,
                            },
                            ticks: {
                                fontSize: 11,
                                maxTicksLimit: 30,
                            },
                            time: {
                                minUnit: 'day',
                                displayFormats: {
                                    millisecond: 'MMM D',
                                    second: 'MMM D',
                                    minute: 'MMM D',
                                    hour: 'MMM D',
                                    day: 'MMM D',
                                    week: 'MMM D',
                                    month: 'MMM D',
                                    quarter: 'MMM D',
                                    year: 'MMM D',
                                },
                            },
                        },
                    ],
                },
            };
            vm.labels = [];
            vm.colors = ['#da5c5b', '#ff9d4e', '#a9dad6'];
            vm.series = [
                gettextCatalog.getString('Data violations'),
                gettextCatalog.getString('Content with violations'),
                gettextCatalog.getString('Scanned content'),
            ];
            vm.data = [[], [], []];
            vm.override = [
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                },
                {
                    yAxisID: 'y-axis-1',
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: true,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    borderDash: [5, 5],
                    pointHitRadius: 34,
                },
                {
                    yAxisID: 'y-axis-1',
                    xAxisID: 'x-axis-0',
                    type: 'line',
                    fill: true,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    borderDash: [5, 5],
                    pointHitRadius: 34,
                },
            ];
            vm.history.forEach(function (entry) {
                var entryTime = new Date(entry.date).getTime();
                vm.labels.push(entryTime);
                vm.data[0].push({
                    x: entryTime,
                    y: entry.data_protection_violations_count || 0,
                });
                vm.data[1].push({
                    x: entryTime,
                    y: entry.data_protection_pages_with_violations_count || 0,
                });
                vm.data[2].push({
                    x: entryTime,
                    y: (entry.documents_count || 0) + (entry.page_count || 0),
                });
            });
        }
    }
})();
