(function () {
    'use strict';

    angular.module('app.forms').component('formUserDomains', {
        templateUrl: 'app/forms/user/steps/domains/domains.html',
        controller: UserDomainController,
        controllerAs: 'vm',
        bindings: {
            user: '=',
        },
    });

    UserDomainController.$inject = ['ng2DomainRepoService', '$controller'];
    /* @ngInject */
    function UserDomainController (ng2DomainRepoService, $controller) {
        var vm = this;
        vm.$onInit = activate;
        vm.domainIsVisible = domainIsVisible;
        vm.domainGroupIsVisible = domainGroupIsVisible;
        vm.domainHasSendReport = domainHasSendReport;
        vm.domainGroupHasSendReport = domainGroupHasSendReport;
        vm.setVisible = setVisible;
        vm.setSendReport = setSendReport;
        vm.setDomain = setDomain;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.user.domain_users = vm.user.domain_users || [];
            vm.user.domain_group_members = vm.user.domain_group_members || [];
            vm.domainGroups = [];
            vm.domains = [];
            vm.total = 0;
            vm.getPage();
        }

        function getPage () {
            vm.progress = ng2DomainRepoService.getAll({ page_size: vm.pageSize, page: vm.page, mini: true }).then(domains => {
                vm.domains = domains;
                vm.total = domains.total;
            });
        }

        function setDomain (domain, userParam, domainParam) {
            var index = getIndexOfUserDomain(domain, userParam, domainParam);
            if (index === -1) {
                var params = {
                    visible: false,
                    send_report: false,
                };
                params[domainParam] = domain.id;
                vm.user[userParam].push(params);
            }
        }

        function domainIsVisible (domain) {
            return isVisible(vm.user.domain_users, domain, 'domain_id');
        }

        function domainGroupIsVisible (domain) {
            return isVisible(vm.user.domain_group_members, domain, 'domain_group_id');
        }

        function isVisible (domains, domain, attr) {
            if (Boolean(vm.user.customer_admin) === false) {
                for (var i = 0; i < domains.length; i++) {
                    if (domains[i][attr] === domain.id) {
                        return domains[i].visible;
                    }
                }
            }
            return Boolean(vm.user.customer_admin);
        }

        function domainHasSendReport (domain) {
            return hasSendReport(vm.user.domain_users, domain, 'domain_id');
        }

        function domainGroupHasSendReport (domain) {
            return hasSendReport(vm.user.domain_group_members, domain, 'domain_group_id');
        }

        function hasSendReport (domains, domain, attr) {
            for (var i = 0; i < domains.length; i++) {
                if (domains[i][attr] === domain.id) {
                    return domains[i].visible && domains[i].send_report;
                }
            }
            return false;
        }

        function setVisible (domain, userParam, domainParam) {
            var index = getIndexOfUserDomain(domain, userParam, domainParam);
            if (index === -1) {
                setDomain(domain, userParam, domainParam);
                index = vm.user[userParam].length - 1;
            }
            vm.user[userParam][index].visible = !isVisible(vm.user[userParam], domain, domainParam);
        }

        function setSendReport (domain, userParam, domainParam) {
            var index = getIndexOfUserDomain(domain, userParam, domainParam);
            if (index === -1) {
                setDomain(domain, userParam, domainParam);
                index = vm.user[userParam].length - 1;
            }
            vm.user[userParam][index].send_report = !hasSendReport(vm.user[userParam], domain, domainParam);
        }

        function getIndexOfUserDomain (domain, userParam, domainParam) {
            for (var i = 0; i < vm.user[userParam].length; i++) {
                if (vm.user[userParam][i][domainParam] === domain.id) {
                    return i;
                }
            }

            return -1;
        }
    }
})();
