import { Pipe, PipeTransform } from '@angular/core';
import { IconsService } from '@layout/icons/icons.service';
import type { MON_ICONS } from './icons.constants';

@Pipe({ name: 'monIcons' })
export class IconsPipe implements PipeTransform {
    constructor (private iconsService: IconsService) {}

    transform (path: string[]): string | typeof MON_ICONS {
        return this.iconsService.getIcon(path);
    }
}
