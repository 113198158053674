import { ActiveFeatureService } from '@monsido/ng2/services/active-feature/active-feature.service';
import { MonUIRouterTransitionService } from '@monsido/ng2/services/ui-router-transition-service/ui-router-transition-service';
import { ResolvableLiteral, StateService } from '@uirouter/core';


export const routesHelper = {

    makeActiveFeatureResolver (token: string, feature: string): ResolvableLiteral {
        return {
            token: token,
            deps: [
                ActiveFeatureService, MonUIRouterTransitionService, StateService,
            ],
            resolveFn: async (
                activeFeatureService: ActiveFeatureService,
                transitionService: MonUIRouterTransitionService,
                state: StateService,
            ): Promise<void> => {
                if (!activeFeatureService.isFeatureActive(feature)) {
                    await (new Promise((resolve) => {
                        transitionService.onTransitionFinished(
                            () => {
                                state.go('base.dashboard');
                                resolve(undefined);
                            },
                        );
                    }));
                }
            },
        };
    },
};
