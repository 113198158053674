(function () {
    'use strict';

    angular.module('modules.page_fix').filter('pageFixSettings', PageFixSettingsFilter);

    PageFixSettingsFilter.$inject = ['page_fix_settings'];
    /* @ngInject */
    function PageFixSettingsFilter (page_fix_settings) {
        return filter;

        // //////////

        function filter (setting) {
            return page_fix_settings[setting];
        }
    }
})();
