(function () {
    'use strict';

    angular.module('modules.cookie_banner').service('CookieModelService', CookieModelService);

    CookieModelService.$inject = ['COOKIE_SOURCE', 'COOKIE_CATEGORIES', 'ng2MonLogService'];
    /* @ngInject*/
    function CookieModelService (COOKIE_SOURCE, COOKIE_CATEGORIES, ng2MonLogService) {
        var cookieTypes = [
            COOKIE_CATEGORIES.analytics,
            COOKIE_CATEGORIES.basic,
            COOKIE_CATEGORIES.essential,
            COOKIE_CATEGORIES.marketing,
            COOKIE_CATEGORIES.personalisation,
            COOKIE_CATEGORIES.unclassified,
        ];

        var cookieSources = [COOKIE_SOURCE.manual, COOKIE_SOURCE.scanned, COOKIE_SOURCE.scanned_now_absent];

        var atCookieModelService = ' at CookieModelService!';

        return {
            createCookie: function (options) {
                return new CookieModel(options);
            },
        };

        // ///////////////

        function getCookieType (type) {
            if (type && cookieTypes.indexOf(type) > -1) {
                return type;
            } else {
                ng2MonLogService.warn('Wrong cookie type' + atCookieModelService);
                return COOKIE_CATEGORIES.unclassified;
            }
        }

        function getCookieSource (source) {
            if (source && cookieSources.indexOf(source) > -1) {
                return source;
            } else {
                ng2MonLogService.warn('Wrong cookie source' + atCookieModelService);
                return COOKIE_SOURCE.manual;
            }
        }

        function CookieModel (options) {
            if (options) {
                if (options.id != null) {
                    this.id = options.id;
                }

                this.type = getCookieType(options.type);
                this.source = getCookieSource(options.source);

                if (options.name != null) {
                    this.name = String(options.name);
                } else {
                    ng2MonLogService.warn('Missing cookie name' + atCookieModelService);
                }
                if (options.domain != null) {
                    this.domain = String(options.domain);
                } else {
                    ng2MonLogService.warn('Missing cookie domain' + atCookieModelService);
                }

                if (options.platform != null) {
                    this.platform = String(options.platform);
                }

                if (options.company != null) {
                    this.company = String(options.company);
                }
                if (options.path != null) {
                    this.path = String(options.path);
                }

                if (Array.isArray(options.descriptions) && options.descriptions.length > 0) {
                    this.descriptions = options.descriptions;
                    if (this.descriptions.length === 0) {
                        delete this.descriptions;
                    } else {
                        // Remove deprecated field from model
                        delete this.description;
                    }
                }
                if (Array.isArray(options.expirations) && options.expirations.length > 0) {
                    this.expirations = options.expirations;
                    if (this.expirations.length === 0) {
                        delete this.expirations;
                    } else {
                        // Remove deprecated field from model
                        delete this.expires;
                    }
                }
            } else {
                ng2MonLogService.warn('Cannot create cookie' + atCookieModelService);
            }
        }
    }
})();
