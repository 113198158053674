(function () {
    'use strict';

    angular.module('modules.accessibility').component('accGuidelineDescription', {
        templateUrl: 'app/modules/accessibility/components/guideline-description/guideline-description.html',
        controller: AccGuidelineDescriptionController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
        },
    });

    AccGuidelineDescriptionController.$inject = ['ng2SessionService', 'gettextCatalog'];
    /* @ngInject */
    function AccGuidelineDescriptionController (ng2SessionService, gettextCatalog) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.guideline = getAccessibilityGuideline();
            vm.guidelineDescription = getHelpCenterGuidelineDescription();
        }

        function getAccessibilityGuideline () {
            return ng2SessionService.domain && ng2SessionService.domain.features.accessibility;
        }

        function getHelpCenterGuidelineDescription () {
            return gettextCatalog.getString('Part of success criteria {{checkIssueNumber}}', {
                checkIssueNumber: vm.check.issue_number,
            });
        }
    }
})();
