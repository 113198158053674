(function () {
    'use strict';

    angular.module('blocks.base').factory('BaseEntityService', BaseEntityService);

    BaseEntityService.$inject = ['Lodash'];

    function BaseEntityService (Lodash) {
        return {
            updateEntryInArray: updateEntryInArray,
        };

        function updateEntryInArray (vm, vmKey, idKey) {
            return function (entry) {
                var index;
                var updatedRows;

                if (entry && !isNaN(entry[idKey]) && vm[vmKey].length) {
                    index = Lodash.findIndex(vm[vmKey], function (p) {
                        return p[idKey] === entry[idKey];
                    });
                    if (index != null) {
                        updatedRows = JSON.parse(JSON.stringify(vm[vmKey]));
                        updatedRows.splice(index, 1, entry);

                        Object.keys(vm[vmKey]).forEach(function (key) {
                            // Some arrays have assigned properties. That should be fixed in future.
                            if (isNaN(key)) {
                                updatedRows[key] = vm[vmKey][key];
                            }
                        });

                        vm[vmKey] = updatedRows;
                    }
                }
            };
        }
    }
})();
