(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuComplianceShield', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = [
        'gettextCatalog',
        'ng2SessionService',
        '$filter',
        'ng2ActiveFeatureService',
        'PAGE_CORRECT_DOMAIN_SETTINGS_MODULE',
    ];
    function SideMenuController (gettextCatalog, ng2SessionService, $filter, ng2ActiveFeatureService, PAGE_CORRECT_DOMAIN_SETTINGS_MODULE) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'SUMMARY']),
                    href: 'base.customer.domain.page_fix.summary',
                },
                {
                    title: gettextCatalog.getString('List'),
                    icon: $filter('monIcons')(['MENUES', 'PAGE_FIX']),
                    href: 'base.customer.domain.page_fix.index',
                },
            ];
            if (ng2ActiveFeatureService.isFeatureActive('accessibility')) {
                var domain = ng2SessionService.domain;
                if (
                    domain &&
                    domain.settings &&
                    domain.settings.scripts.page_correct.enabled_checks.indexOf(PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK) >
                        -1
                ) {
                    vm.menu.push(
                        {
                            title: gettextCatalog.getString('Accessibility Fixes'),
                            icon: $filter('monIcons')(['PAGE_FIX', 'ACCESSIBILITY_CHECKS']),
                            href: 'base.customer.domain.page_fix.checks',
                        },
                        // TODO: Reinstate Accessibility Fixes https://zube.io/monsido/monsido/c/21815
                        // {
                        //     title: gettextCatalog.getString("Accessibility Fixes"),
                        //     icon: $filter("monIcons")(["PAGE_FIX", "ACCESSIBILITY_CHECKS"]),
                        //     href: "base.customer.domain.page_fix.accessibility_checks",
                        //     activeRoutes: [
                        //         "base.customer.domain.page_fix.accessibility_checks.website_structure",
                        //         "base.customer.domain.page_fix.accessibility_checks.design",
                        //         "base.customer.domain.page_fix.accessibility_checks.links",
                        //         "base.customer.domain.page_fix.accessibility_checks.media",
                        //     ],
                        //     subs: [
                        //         {
                        //             title: gettextCatalog.getString("Website structure"),
                        //             icon: $filter("monIcons")(["PAGE_FIX", "WEBSITE_STRUCTURE"]),
                        //             href: "base.customer.domain.page_fix.accessibility_checks.website_structure",
                        //         },
                        //         {
                        //             title: gettextCatalog.getString("Design"),
                        //             icon: $filter("monIcons")(["PAGE_FIX", "DESIGN"]),
                        //             href: "base.customer.domain.page_fix.accessibility_checks.design",
                        //         },
                        //         {
                        //             title: gettextCatalog.getString("Links"),
                        //             icon: $filter("monIcons")(["PAGE_FIX", "LINKS"]),
                        //             href: "base.customer.domain.page_fix.accessibility_checks.links",
                        //         },
                        //     ],
                        // },
                    );
                }
            }
        }
    }
})();
