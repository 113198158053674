import { NgModule } from '@angular/core';
import { FormsModule as AngularFormModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FormBuilderInputModule, FormBuilderSelectModule, FormBuilderValidationModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { FormDomainGroupsMatchRuleLabelComponent } from './domain/steps/domain-groups/steps/rules/match-rule/templates/label/label.component';
import { TranslateModule } from '@client/app/modules/translate.module';

@NgModule({
    imports: [
        CommonModule,
        AngularFormModule,
        TranslateModule,
        FormBuilderValidationModule,
        FormBuilderInputModule,
        FormBuilderSelectModule,
    ],
    declarations: [
        FormDomainGroupsMatchRuleLabelComponent,
    ],
    exports: [
        FormDomainGroupsMatchRuleLabelComponent,
    ],
})
export class FormsModule {}
