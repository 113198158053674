export const MON_COLORS = {
    DEFAULT: '#57b2b7',
} as const;

export const MODULES_COLORS_AND_ICONS = {
    qa: {
        color: 'green',
        icon: 'fas fa-check-square',
    },
    accessibility: {
        color: 'purple',
        icon: 'fas fa-universal-access',
    },
    seo: {
        color: 'orange',
        icon: 'fas fa-search',
    },
    uptime: {
        color: 'yellow',
        icon: 'fas fa-heartbeat',
    },
    statistics: {
        color: 'blue',
        icon: 'fas chart-bar',
    },
    policy: {
        color: 'pink',
        icon: 'fas fa-gavel',
    },
    inventory: {
        color: 'red',
        icon: 'fas fa-file',
    },
    settings: {
        color: 'grey',
        icon: 'fas fa-cogs',
    },
} as const;
