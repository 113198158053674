(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('sopButton', {
        templateUrl: 'app/components/pagedetails/details/sop/sop.html',
        controller: SopBtnController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
        },
    });

    SopBtnController.$inject = ['ng2SessionService', 'ng2CoreLoginService'];
    /* @ngInject */
    function SopBtnController (ng2SessionService, ng2CoreLoginService) {
        var vm = this;

        vm.getUrl = getUrl;
        vm.canAccessDomain = canAccessDomain;
        vm.canSeeClassicShowOnPage = canSeeClassicShowOnPage;

        function canAccessDomain () {
            return ng2SessionService.domain;
        }

        function canSeeClassicShowOnPage () {
            return ng2SessionService.customer && ng2SessionService.customer.plan_traits.classic_show_on_page;
        }

        function canAccessPage () {
            return vm.page && vm.page.id > 0;
        }

        function getUrl () {
            if (!canAccessDomain() || !canAccessPage()) {
                return;
            }

            var basePath = ng2CoreLoginService.getApiPath();
            // An HTTP iframe can't be loaded on a HTTPS, so we need to load the Acquia Optimize Show On Page over HTTP
            if (vm.page.url.match(/^http:/)) {
                basePath = basePath.replace('https://', 'http://');
            }

            return (
                basePath +
                '/domains/' +
                ng2SessionService.getDomainId() +
                '/pages/' +
                vm.page.id +
                '/show_on_page?customer_id=' +
                ng2SessionService.getCustomerId()
            );
        }

        // http://staging.monsido.com/domains/123/pages/606989/show_on_page
    }
})();
