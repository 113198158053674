(function () {
    'use strict';
    angular.module('directives.monsido').directive('seoIssuesBtnGroup', [
        '$timeout',
        function ($timeout) {
            return {
                bindToController: true,
                restrict: 'E',
                controller: SeoIssuesBtnGroupController,
                controllerAs: 'vm',
                templateUrl: 'app/directives/seo-issues-btn-group/seo-issues-btn-group.html',
                replace: true,
                transclude: true,
                scope: {
                    count: '<',
                    highestNo: '<',
                },
            };
        },
    ]);

    SeoIssuesBtnGroupController.$inject = [];

    function SeoIssuesBtnGroupController () {
        var vm = this;
        vm.$onInit = activate;
        vm.btnGroupWidth = btnGroupWidth;

        function activate () {}

        function btnGroupWidth () {
            return vm.count / vm.highestNo * 100 + '%';
        }
    }
})();
