import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsStatsDemoDataPipe } from './is-stats-demo-data/is-stats-demo-data.pipe';

@NgModule({
    declarations: [IsStatsDemoDataPipe],
    imports: [CommonModule],
    exports: [IsStatsDemoDataPipe],
})
export class StatisticsPipesModule { }
